import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'datePipe' })
export class DatePipe implements PipeTransform {
  transform(value: string): string {
    const newStr = '';
    const myDate = new Date(Date.parse(value));
    return myDate.toLocaleString();
  }
}
