import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../model/user.service';
import { PlanService } from '../../model/plan';
import { EventBus } from '../../shared/EventBus/EventBus';

@Component({
    templateUrl: './upgrade_account.component.html'
})

export class AgencyUpdatePlanComponent implements OnInit {
    private _planservice: PlanService;
    AgencyId: number;
    constructor(private userService: UserService, private _router: Router,
        private _EventBus: EventBus) {
        this._planservice = new PlanService();
    }
    SubscribedPlan: any;
    PlanToBeSubscribe: any;
    DefaultCard: any;
    CardLoading: boolean;
    UpgradingPlan: boolean;
    StripeCustomerId: string;
    ngOnInit() {
        // get agency/user detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.StripeCustomerId = UserModel.user.StripeCustomerId;
            this.AgencyId = UserModel.user.AgencyId;
        }
        this.SubscribedPlan = PlanService.SubscribedPlan;
        this.PlanToBeSubscribe = PlanService.PlanToBeSubscribe;
        this.DefaultCard = PlanService.DefaultCard;
        if (this.SubscribedPlan && this.PlanToBeSubscribe && this.DefaultCard) {
        } else {
            this._router.navigate(['/agency/subscriptions']);
        }

        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'cardupdated') {
                this.GetDefaultCard();
            }
        });
    }
    // get default card
    public GetDefaultCard() {
        this.CardLoading = true;
        this.userService.GetDefaultCard(this.StripeCustomerId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.DefaultCard = result.Data;
                    } else {
                        alert(result.Message);
                    }
                    this.CardLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.CardLoading = false;
                }
            );
    }
    // upgrade plan
    public UpgradePlan() {
        this.UpgradingPlan = true;
        this.userService.UpgradePlan(this.SubscribedPlan.SubscriptionId, this.PlanToBeSubscribe.StripePlanId,
            this.SubscribedPlan.PlanTypeId, this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this._router.navigate(['/agency/subscriptions']);
                    } else {
                        alert(result.Message);
                    }
                    this.UpgradingPlan = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.UpgradingPlan = false;
                }
            );
    }
}
