import { NgModule } from '@angular/core';

@NgModule({
    imports: [
    ],
    declarations: [
    ]
})
export class AgentSignModule {
}
