import { Injectable } from '@angular/core';
import { Headers, Response, Http } from '@angular/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { UserService } from './user.service';
import { User } from './user';


@Injectable()
export class UserDataService {

    constructor(private _globalService: GlobalService,
        private _userService: UserService,
        private _authHttp: Http) {
    }

    private getHeaders(): Headers {
        return new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._userService.getToken(),
        });
    }

    getMe(): Observable<User> {
        const headers = this.getHeaders();
        return this._authHttp.get(
            this._globalService.apiHost + '/user/me',
            {
                headers: headers
            }
        ).map(response => response.json())
            .map((response) => {
                return <User>response.data;
            }).catch(this.handleError);
    }

    updateUser(userData): Observable<any> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json; charset=UTF-8');

        return this._authHttp
            .post(
                this._globalService.apiHost + '/user/me',
                JSON.stringify({
                    'UserEditForm': userData
                }),
                { headers: headers }
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                } else {
                }
                return response;
            }).catch(this.handleError);
    }

    private handleError(error: Response | any) {

        let errorMessage: any = {};
        // Connection error
        if (error.status === 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: 'Sorry, there was a connection error occurred. Please try again.',
            };
        } else {
            errorMessage = error.json();
        }
        return Observable.throw(errorMessage);
    }
}
