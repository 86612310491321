import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { UserService } from '../model/user.service';
import { toast } from 'angular2-materialize';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventBus } from '../shared/EventBus/EventBus';
import { Intercom } from 'ng-intercom';
declare var myExtObject: any;

@Component({
  selector: 'app-agency-matrix-register',
  templateUrl: './agency-matrix-register.component.html',
  styleUrls: ['./agency-matrix-register.component.css']
})
export class AgencyMatrixRegisterComponent implements OnInit, AfterViewInit {
  agencyMatrixLink: boolean;
  ShowLoader: boolean;
  agencyAdminData: any;
  AgencyId: number;
  UserModel: any;
  AgencyLogo: string;
  AgencyName: string;
  LoggedInUserName: string;
  LoggedInUserRoleId: string;
  LoggedInUserRoleName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserPhone: string;
  LoggedInUserEmail: string;
  LoggedInUserId: string;
  modalActions3 = new EventEmitter<string | MaterializeAction>();
  modelAgencyMatix1Params = [
    {
      dismissible: false
    }
  ];
  VideoUrl = '';
  AgencyOwner = '';
  VideoOpen = true;
  @ViewChild('agencymatrixaccountexist') agencymatrixaccountexist: any;
  @ViewChild('agencymatrixaccountnotexist') agencymatrixaccountnotexist: any;
  @ViewChild('thankyou') thankyou: any;
  constructor(public intercom: Intercom, private _userService: UserService, private santizer: DomSanitizer,
    private _router: Router, private _eventBus: EventBus) {
    //  get agency/user detail from token
    this.UserModel = this._userService.decodeToken();
    if (this.UserModel && this.UserModel.user) {
      this.AgencyId = this.UserModel.user.AgencyId;
      this.AgencyOwner = `${this.UserModel.user.FirstName} ${this.UserModel.user.LastName}`;
      this.AgencyLogo = this.UserModel.user.AgencyLogo;
      this.AgencyName = this.UserModel.user.AgencyName;
      this.LoggedInUserName = this.UserModel.user.Name;
      this.LoggedInUserId = this.UserModel.user.UserId;
      this.LoggedInUserRoleId = this.UserModel.user.RoleId;
      this.LoggedInUserImageUrl = this.UserModel.user.LoggedInUserImageUrl;
    } else {
      localStorage.removeItem('frontend-token');
      localStorage.removeItem('StripeCust');
      this._router.navigate(['/login']);
    }
    this._eventBus.emit({ Type: 'show-authorized-header', data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }
  /**
   * show success/error message toast
   */
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  /**
   * open agency matrix informational modal
   * @param ShowAdd boolean
   */
  openAgencyMatrix(ShowAdd) {
    this.modalActions3.emit({ action: 'modal', params: ['open'] });
    this.VideoOpen = true;

  }
  /**
   * Agency martix link exist account
   */
  openExist() {
    this.VideoOpen = false;
    this.modalActions3.emit({ action: 'modal', params: ['close'] });
    this.agencymatrixaccountexist.openAgencyMatrixExist();
  }
  /**
   * open agency matrix register modal
   */
  openNotExist() {
    this.VideoOpen = false;
    this.modalActions3.emit({ action: 'modal', params: ['close'] });
    this.agencymatrixaccountnotexist.openAgencyMatrixNotExist();
  }
  /**
   * open parent modal
   * @param $event event data close/open
   */
  openParentModel($event) {
    if ($event.close) {
      this.VideoOpen = true;
      this.modalActions3.emit({ action: 'modal', params: ['open'] });
    } else if ($event.openNewAccount) {
      this.agencymatrixaccountexist.closeExistModel();
      this.agencymatrixaccountnotexist.openAgencyMatrixNotExist();
    } else if ($event.openAleadyExist) {
      this.agencymatrixaccountnotexist.closeNotExistModel();
      this.agencymatrixaccountexist.openAgencyMatrixExist();
    } else if ($event.thankyou) {
      this.thankyou.openthankYou();
    }
  }
  /**
   * close modal to update agency matrix status
   */
  closeModel() {
    const requestObj: any = {};
    requestObj.AgencyId = this.AgencyId;
    requestObj.Status = 1;
    this.ShowLoader = true;

    this._userService.updateAgencyMatrixPopupStatus(requestObj).subscribe(
      (response) => {
        if (response.Status) {
          this.modalActions3.emit({ action: 'modal', params: ['close'] });
        } else {
          this.toasting(response.Message, 2000, 'red');
          this.modalActions3.emit({ action: 'modal', params: ['close'] });
        }
        this.ShowLoader = false;
        this.VideoOpen = false;
      }, (error) => {
        this.ShowLoader = false;
        this.VideoOpen = false;
        this.toasting(error.data, 2000, 'red');
        this.modalActions3.emit({ action: 'modal', params: ['close'] });
      }
    );

  }

  public LogoutTokbox() {
    myExtObject.logout(true);
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/']);
    this.intercom.shutdown();
  }
}
