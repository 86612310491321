import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
import { Intercom } from 'ng-intercom';
import { EventBus } from '../EventBus/EventBus';
import { ClipboardService } from 'ng2-clipboard/ng2-clipboard';
import { TranslateService } from 'ng2-translate';
import { UserService } from '../../model/user.service';
import { toast } from 'angular2-materialize';
import '../../../assets/tokbox/js/app.js';
import { Activity } from '../../model/activity';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../../model/global.service';
declare var $: any;

declare var myExtObject: any;

@Component({
  selector: 'app-ax-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
  innerWidth: any;
  ShareUrl: string;
  FiveDigitCode: string;
  Count: number;
  LanguageFlag: string;
  AgencyLogo: string;
  AgencyId: number;
  AgencyName: string;
  LoggedInUserName: string;
  LoggedInUserRoleId: string;
  LoggedInUserRoleName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserPhone: string;
  LoggedInUserEmail: string;
  LoginUserId: number;
  LocationId: number;
  _locations: any[];
  model1Params: any;
  Activity: Activity[];
  ActivityData: Activity;
  AllActivityCount = 0;
  SeenActivityCount = 0;
  UnSeenActivityCount = 0;
  ShouldWellRing: boolean;
  modalActions1 = new EventEmitter<string | MaterializeAction>();
  LoadMoreActivityCounter = 0;
  ShowInSeenCounter: boolean;
  SelectedLocationId: Number;
  public config = {
      displayKey: 'name',
      search: false,
      limitTo: 15
  };
  public singleSelect: any = [];
  options: any = [];
  toggleTitle(linkUrl) {
    linkUrl = linkUrl + '' + this.ShareUrl;
    const w = 900;
    const h = 500;
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ?
      document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ?
      document.documentElement.clientHeight : screen.height;

    const left = ((width / 2) - (width / 2)) + dualScreenLeft;
    const top = ((height / 2) - (height / 2)) + dualScreenTop;

    const newWindow = open(linkUrl, 'example', `toolbar=no, directories=no,location=no, status=yes, menubar=no,
     resizable=no, scrollbars=yes,width=` + w + ', height=' + h + ', top=' + top + ', left=' + left);
    newWindow.onload = function () {
    };
  }

  constructor(public intercom: Intercom, private _userService: UserService,
    private userService: UserService, public translate: TranslateService,
    private _EventBus: EventBus, private clipboard: ClipboardService,
    private globalService: GlobalService,
    private _router: Router, private _domSanitizer: DomSanitizer) {
    this.ChangeLanguage(this.translate.getDefaultLang());
    this.Activity = [];
    this.innerWidth = window.innerWidth;
    this.Count = 1;
    // get agency/user activities
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.AgencyLogo = UserModel.user.AgencyLogo;
      this.AgencyId = UserModel.user.AgencyId;
      this.AgencyName = UserModel.user.AgencyName;
      this.LoggedInUserName = UserModel.user.Name;
      this.LoginUserId = UserModel.user.UserId;
      this.LocationId = UserModel.user.LocationId;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
      this.GetUserImage();
      this.GetAgencyActivities(true);
      this.GetLocations();
      this.GetUnssenActivityCount();
    }
    this.GetAgencyUrlAndCode(this.AgencyId, this.LocationId);
    this.GetAwsSeetingKeys();

  }

  ngOnInit() {
    myExtObject.initializeTokbox();
    this._EventBus.on().subscribe(m => {
      if (m.filterBy.Type === 'header-left-statics') {
        const userModel = m.filterBy.data;
        if (userModel) {
          if (userModel.AgencyLogo && this.AgencyLogo !== userModel.AgencyLogo) {
            this.AgencyLogo = userModel.AgencyLogo;
          }
          if (userModel.AgencyId && this.AgencyId !== userModel.AgencyId) {
            this.AgencyId = userModel.AgencyId;
          }
          if (userModel.AgencyName && this.AgencyName !== userModel.AgencyName) {
            this.AgencyName = userModel.AgencyName;
          }
          if (userModel.LoggedInUserName && this.LoggedInUserName !== userModel.LoggedInUserName) {
            this.LoggedInUserName = userModel.LoggedInUserName;
          }
          if (userModel.LoginUserId && this.LoginUserId !== userModel.LoginUserId) {
            this.LoginUserId = userModel.LoginUserId;
          }
          if (userModel.LoggedInUserImageUrl && this.LoggedInUserImageUrl !== userModel.LoggedInUserImageUrl) {
            this.LoggedInUserImageUrl = userModel.LoggedInUserImageUrl;
          }
          if (userModel.LoggedInUserRoleId && this.LoggedInUserRoleId !== userModel.LoggedInUserRoleId) {
            this.LoggedInUserRoleId = userModel.LoggedInUserRoleId;
          }
          this._EventBus.emit({ Type: 'header-app-component', data: userModel });
        }
      } else if (m.filterBy.Type === 'Get-User-Profile-Event') {
        this.GetUserImage();
      } else if (m.filterBy.Type === 'update-banner') {
        if (m.filterBy.data && m.filterBy.data.logoUrl) {
          this.AgencyLogo = m.filterBy.data.logoUrl;
        }
      } else if (m.filterBy.Type === 'MARKED_AS_READ_ACTIVITIES') {
        this.MarkedActivitiesAsRead(m.filterBy.data);
      } else if (m.filterBy.Type === 'UPDATE_ACTIVITY_TRIGGERED') {
        const activityData = m.filterBy.data.activityData;
        this.ActivityData = JSON.parse(activityData);
        this.GetAgencyActivities(true);
        this.ShouldWellRing = true;
        window.setTimeout(() => { this.ShouldWellRing = false; }, 30000);
      }
    });

    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#share_company_url').modal({ dismissible: false });
    }, 400);
    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'getAgencyData') {
        this.AgencyId = m.filterBy.data.AgencyId;
      }
    });

    this.userService.getuserprofile(this.LoginUserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.LoggedInUserRoleName = result.Data.RoleName;
            this.LoggedInUserEmail = result.Data.Email;
            this.LoggedInUserPhone = result.Data.Phone;

            const UserName = this.LoggedInUserName;
            const UserEmail = this.LoggedInUserEmail;
            const Phone = this.LoggedInUserPhone;
            const AgencyName = this.AgencyName;
            const RoleName = this.LoggedInUserRoleName;

            this.intercom.update({
              app_id: 'qesi6r1a',
              name: UserName, // Full name
              email: UserEmail, // Email address
              phone: Phone, // current_user_id
              agency_name: AgencyName, // current_user_id
              role: RoleName, // current_user_id
              // Supports all optional configuration.
              widget: {
                'activator': '#intercom',
              }
            });
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
          }
        }
      );
  }
  // get agency share code and url for user register
  private GetAgencyUrlAndCode(AgencyId, LocationId) {
    this._userService.GetAgencyUrlAndCode(AgencyId, LocationId)
    .subscribe(
      result => {
        if (result.Status) {
          this.ShareUrl = result.Data.AgencyUrl;
          this.FiveDigitCode = result.Data.FiveDigitCode;
        } else {

        }
      },
      error => {
        // Validation error
        if (error.status === 422) {
          // this._errorMessage = 'There was an error on submission. Please check again.';
          const errorFields = JSON.parse(error.data.message);

        } else {
        }
      }
    );
  }
  // get aws keys for upload files
  private GetAwsSeetingKeys() {
    this._userService.GetAWSSettingKeys().subscribe((response) => {
      if (response.Status && response.Data) {
        const data = this.globalService.DecryptSetASEAWSKeys(response.Data);
        const globleDataSourceState = this.globalService.globleDataSource;
        globleDataSourceState['amazon_bucket_name'] = data.AWS_Bucket_Name;
        globleDataSourceState['amazon_secret_access_key'] = data.AWS_Secret;
        globleDataSourceState['amazon_access_key'] = data.AWS_Key;
        this.globalService.changeGlobleDataSourceState(globleDataSourceState);
      }

    }, error => {
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }
  public GetUnssenActivityCount() {
    this._userService.GetUnseenActivityCount(this.LoginUserId, this.LoggedInUserRoleId, this.LocationId, this.AgencyId)
        .subscribe((response) => {
            if (response.Status) {
                this.UnSeenActivityCount = response.Data.UnSeenCount;
            }
        }, (error) => {});
  }
  // get user image
  public GetUserImage() {
    this._userService.getuserprofile(this.LoginUserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.LoggedInUserImageUrl = result.Data.Picture;
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // copy code to clipboard
  copyToClipboard = (fivegigtcode) => { this.clipboard.copy(fivegigtcode); this.toasting('Copied Successfully', 2000, 'green'); };
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  shareCompanyUrl() {

    this.modalActions1.emit({ action: 'modal', params: ['open'] });
  }
  public LogoutTokbox() {
    myExtObject.logout(true);
  }

  public ChangeLanguage(value) {
    if (value === 'English') {
      this.LanguageFlag = 'assets/flags/US.png';
    } else {
      this.LanguageFlag = 'assets/flags/BL.png';
    }
    this.translate.use(value);
  }
  // toggle left sidebar
  public OpenSidebar() {
    this._EventBus.emit({ Type: 'toggleressidebar', data: { sidebarStatus: true } });
  }
  // open call panel
  public OpenCallPanel() {
    this._EventBus.emit({ Type: 'toggleCallPanel', data: { callPanelStatus: true } });
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this.intercom.shutdown();
    this._router.navigate(['/']);
  }
  // get agency activities
  GetAgencyActivities(isResetArray = false, offset = 0, activityModuleId = 0, activityType = 0) {
    const postData = {
      UserId: this.LoginUserId,
      OffSet: offset,
      ActivityModuleId: activityModuleId,
      ActivityType: activityType,
      AgencyId: this.AgencyId,
      LocationId: this.LocationId
    };
    this._userService.GetUserActivitiesForAgent(postData).subscribe((respo) => {
      if (isResetArray) {
        this.Activity = [];
      }
      const activityData = respo && respo.Data ? respo.Data : [];
      activityData.forEach((element: Activity) => {
        this.Activity.push(element);
      });
      this.RefreshActivityCount();
      this._EventBus.emit({
        Type: 'GET_UPDATED_ACTIVITY_TRIGGERED', data:
        {
          data: this.Activity,
          AllActivityCount: this.AllActivityCount,
          SeenActivityCount: this.SeenActivityCount,
          UnSeenActivityCount: this.UnSeenActivityCount
        }
      });
    }, error => {
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }

  GetFormatedDate(date) {
    return new Date(date).toLocaleString();
  }
  public RefreshActivityCount() {
    if (this.Activity && this.Activity.length > 0) {
      const acivity = this.Activity[0];
      this.AllActivityCount = acivity.TotalActivityCount;
      this.SeenActivityCount = acivity.SeenCount;
      // this.UnSeenActivityCount = acivity.UnSeenCount;
    } else {
      this.AllActivityCount = 0;
      this.SeenActivityCount = 0;
      this.UnSeenActivityCount = 0;
    }
  }

  private UpdateActivityArray(activity: Activity) {
    if (activity) {
      activity.Id = Number(activity.Id);
      const index = this.Activity.findIndex(x => x.Id === activity.Id);
      if (index > -1) {
        this.Activity[index] = activity;
      } else {
        if (this.Activity) {
          this.Activity.unshift(activity);
        } else {
          this.Activity = [activity];
        }
        this.AllActivityCount = this.AllActivityCount + 1;
        this.UnSeenActivityCount = this.UnSeenActivityCount + 1;
      }
      this._EventBus.emit({
        Type: 'GET_UPDATED_ACTIVITY_TRIGGERED',
        data: {
          data: this.Activity,
          AllActivityCount: this.AllActivityCount,
          SeenActivityCount: this.SeenActivityCount,
          UnSeenActivityCount: this.UnSeenActivityCount
        }
      });
    }
  }
  // mark activities read
  MarkedActivitiesAsRead(shouldRedirect = false) {
    const UnReadActivitiesIds = [];
    this.Activity.forEach((element: Activity) => {
      if (!element.ActivitySeenStatus) {
        UnReadActivitiesIds.push(element.Id);
      }
    });
    // if (UnReadActivitiesIds.length > 0) {
    this._userService.MarkedActivitiesAsRead({
      UserId: this.LoginUserId,
      ActivitiesId: UnReadActivitiesIds
    })
      .subscribe((apiResponse) => {
        if (!shouldRedirect) {
          this.GetAgencyActivities(true);
        }
        this.UnSeenActivityCount = 0;
      },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        });
    // }
    if (shouldRedirect) {
      this._router.navigate(['/agent/notifications']);
    }
  }
  // on scroll load more activities
  public onScrollDownActivities() {
    this.LoadMoreActivityCounter = this.LoadMoreActivityCounter + 50;
    this.GetAgencyActivities(false, this.LoadMoreActivityCounter);
  }
  // convert payment amount to two decimal
  GetFormatedDollor(dollor) {
    return Number(dollor).toFixed(2);
  }

  identifyActivity(index: number, item: Activity) {
    return item.Id;
  }
  // get locations of agency
  public GetLocations() {
    this._userService.GetAgentAllLocations( this.AgencyId, this.LoginUserId)
      .subscribe(result => {
          if (result.Status) {
            if (result.Data) {
                this._locations = result.Data;
                const LocationsShown = [];
                if (this._locations && this._locations.length > 0) {
                    this._locations.forEach((item, key) => {
                        LocationsShown.push({_id: item.LocationId, name: item.LocationName, index: key});
                    });
                }
                this.options = LocationsShown;
                setTimeout(() => {
                  this.LocationId = Number(localStorage.getItem('SelectedLocation'));
                  const SelectedIndex = this.options.findIndex((loc) => loc._id === this.LocationId);
                  this.singleSelect = this.options[SelectedIndex];
                }, 100);
            }
          }
      }
    );
  }
  // change loaction active location
  public ChangeLocation(event) {
    const that = this;
    localStorage.setItem('SelectedLocation', event.value._id);
    this.GetAgencyUrlAndCode(this.AgencyId, event.value._id);
    this.LocationId = Number(event.value._id);
    this.GetAgencyActivities(true);
    that._EventBus.emit({ Type: 'locationChanged', data: { LocationId: event.value._id } });
  }
}
