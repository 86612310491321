import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../model/user.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { CallDetail } from '../../model/callsdetail';
import { ChatHistory } from '../../model/chathistory';
import { MaterializeAction, toast } from 'angular2-materialize';
import { DocumentToSave } from '../../model/documenttosave';
import { AmazonS3 } from '../../model/amazon.upload';
import { DomSanitizer } from '@angular/platform-browser';
import { EventBus } from '../../shared/EventBus/EventBus';

@Component({
    templateUrl: './usercall.component.html'
})

export class UserCallComponent implements OnInit {
    @ViewChild('scrollMe') public myScrollContainer: ElementRef;
    @ViewChild('videoPlayer') public videoplayer: ElementRef;
    @ViewChild('iframeelement') public iframe: ElementRef;
    key = 'InitiatedDateTime';
    reverse = true;
    UserDetailLoading: boolean;
    previewimagemodalparam: any;
    uploaddocmodal = new EventEmitter<string | MaterializeAction>();
    params = [];
    uploaddocmodalparam = [
        {
            dismissible: false,
            complete: function () { }
        }
    ];

    previewimagemodal = new EventEmitter<string | MaterializeAction>();
    UserLoading: boolean;
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    TotalUsers: string;
    Count: number;
    LoginUserId: number;
    AgencyId: number;
    LocationId: number;
    LanguageFlag: string;
    LoadMoreLoader: boolean;
    LoadMoreCounter: number;
    extens: String[];
    IsLoaded: boolean;
    IsVideo: boolean;
    IsDoc: boolean;
    validupload: boolean;
    lastMessageDate: string;
    DetailedUser: User;
    ShowLoader = false;
    filter: any;
    NoPaymentsFound: boolean;
    NotDocumentFound: boolean;
    NotCallsFound: boolean;
    NotChatFound: boolean;
    Folders: any[];
    Documents: Document[];
    Calls: CallDetail[];
    payments: any[];
    ChatHistory: ChatHistory[];
    CallCount: number;
    IsCalls: boolean;
    IsPayments: boolean;
    IsChatHistory: boolean;
    SelectedFolderId: string;
    LoggedInUserId: number;
    UserID: number;
    CurrentUserId: number;
    _amazon: AmazonS3;
    filesTosaveDB: Array<DocumentToSave> = [];
    HasFilesToUpload: boolean;
    ShowDocTable: boolean;
    ShowCallTable: boolean;
    ShowChatTable: boolean;
    ShowPaymentTable: boolean;
    previewImageURL: any;
    callCount: string;
    previousUrl: string;
    opensearch: boolean;
    searchText = '';
    SortKey = 'DateTimeString';
    SortOrder = 'DESC';
    LoadMoreLoaderEnable: boolean;
    LoadMoreCalls = true;
    ngOnInit() {
        if (this.userService.decodeToken().user.RoleId !== 2) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
        }

        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });

        this.opensearch = false;

    }


    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _router: Router,
        public sanitizer: DomSanitizer,
        private _EventBus: EventBus
    ) {
        this.LoadMoreCounter = 0;
        // get agency/user detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoginUserId = UserModel.user.UserId;
            this.AgencyId = UserModel.user.AgencyId;
            if (this.userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId === 2) {
                this.LocationId = Number(this.userService.GetSelectedLocation());
            } else {
                this.LocationId = UserModel.user.LocationId;
            }
        } else {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
        }
        const UserId = 0;
        this.UserID = UserId;
        this.CurrentUserId = UserId;
        this.UserID = UserId;
        this.Count = 1;
        this.getAgenctCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'locationChanged') {
                this.LocationId = Number(m.filterBy.data.LocationId);
                this.getAgenctCallsDetails(0, this.searchText, this.SortKey, this.SortOrder);
            }
        });
    }
    // on scroll down load more users
    public onScrollDown() {
        if (this.Calls.length % 50 === 0 && this.LoadMoreCalls === true) {
            this.LoadMoreLoaderEnable = true;
            this.LoadMoreCounter = this.LoadMoreCounter + 50;
            this.getAgenctCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
        }
    }
    // get agency calls details
    public getAgenctCallsDetails(Offset, Search, SortKey, SortOrder) {
        if (this.LoadMoreCalls === true || this.searchText !== Search) {
            this.LoadMoreCalls = false;
            const that = this;
            if (Offset === 0) { that.UserLoading = true; }
            this.userService.getAgentCallsDetails(this.LoginUserId, this.LocationId, Offset, Search, SortKey, SortOrder)
                .subscribe(
                    result => {
                        if (result.Status) {
                            if (Offset === 0) {
                                this.Calls = [];
                                this.Calls = result.Data;
                            } else {
                                result.Data.forEach(function (item) {
                                    that.Calls.push(item);
                                });
                            }
                            that.Calls.forEach(function (item) {
                                item.CallerFirstName = item.CallerFirstName ?
                                    `${item.CallerFirstName[0].toUpperCase()}${item.CallerFirstName.slice(1)}` : '';
                                item.CallerLastName = item.CallerLastName ?
                                    item.CallerLastName[0].toUpperCase() + item.CallerLastName.slice(1) : '';
                                item.CallerName = `${item.CallerFirstName} ${item.CallerLastName}`;
                                item.CallerInitial = that.GetInitials(item.CallerName);
                                item.CCssClass = item.CCssClass ? item.CCssClass : that.GetClass();
                                item.ResponderFirstName = item.ResponderFirstName ?
                                    item.ResponderFirstName[0].toUpperCase() + item.ResponderFirstName.slice(1) : '';
                                item.ResponderLastName = item.ResponderLastName ?
                                    item.ResponderLastName[0].toUpperCase() + item.ResponderLastName.slice(1) : '';
                                item.ResponderName = `${item.ResponderFirstName} ${item.CallerLastName}`;
                                item.ReponderInitial = that.GetInitials(item.ResponderName);
                                item.RCssClass = item.RCssClass ? item.RCssClass : that.GetClass();
                                if (item.Url !== null) {
                                    const recordedUrl = item.Url.split('?');
                                    item.Url = recordedUrl[0];
                                    item.VideoExtention = 'mp4';
                                }
                                const dateTime = new Date(item.InitiatedDateTime).toLocaleString();
                                item.DateTimeString = dateTime;
                                let minutes = Math.floor(item.Duration / 60).toString();
                                if (minutes.toString().length === 1) { minutes = '0' + minutes; }
                                let seconds = (item.Duration % 60).toString();
                                if (seconds.toString().length === 1) { seconds = '0' + seconds; }
                                item.DurationInMinutes = minutes + ':' + seconds;
                                if (item.CallStatusId === 1) {
                                    item.CallType = 'Missed';
                                    item.TextClass = 'red-text';
                                } else if (item.callerId === that.LoggedInUserId) {
                                    item.CallType = 'Incoming';
                                    item.TextClass = 'green-text';
                                } else {
                                    item.CallType = 'Outgoing';
                                    item.TextClass = '';
                                }
                            });
                        } else {
                            if (Offset === 0) { this.NotCallsFound = true; }
                            this.Calls = [];
                        }
                        this.UserLoading = false;
                        that.LoadMoreLoader = false;
                        if (that.Calls.length > 0) {
                            that.callCount = that.Calls[0].CallCounts.toString();
                            this.NotCallsFound = false;
                        } else {
                            that.callCount = '0';
                            this.NotCallsFound = true;
                        }
                        this.LoadMoreLoaderEnable = false;
                        this.LoadMoreCalls = true;
                    },
                    error => {
                        if (error.Message === 'Token expired') {
                            localStorage.removeItem('frontend-token');
                            localStorage.removeItem('StripeCust');
                            this._router.navigate(['/login']);
                        }
                        this.LoadMoreCalls = true;
                    });
        }
    }
    // preview audio file
    public previewFile(url, Extention, index) {
        const that = this;
        if (Extention.toLowerCase() === 'jpg' || Extention.toLowerCase() === 'png' || Extention.toLowerCase() === 'jpeg') {
            if (this.previewImageURL !== url) { this.IsLoaded = false; }
            this.previewImageURL = url;
            this.IsDoc = false;
            this.IsVideo = false;
            this.openPreviewModal();
        } else if (Extention.toLowerCase() === 'mp4') {
            if (this.previewImageURL !== url) { this.IsLoaded = false; }
            this.IsLoaded = true;
            this.previewImageURL = url;
            this.IsDoc = false;
            this.IsVideo = true;
            const millisecondsToWait = 1;
            setTimeout(function () {
                that.openPreviewModal();
                that.videoplayer.nativeElement.load();
            }, millisecondsToWait);
        } else {
            this.IsDoc = true;
            this.IsVideo = false;
            const DocUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + url);
            this.previewImageURL = DocUrl;
            this.iframe.nativeElement.src = DocUrl;
            this.openPreviewModal();
        }
    }
    // open preview modal
    public openPreviewModal() {
        this.previewimagemodal.emit({ action: 'modal', params: ['open'] });
    }
    // close preview modal
    public closepreviewimageModal() {
        this.previewimagemodal.emit({ action: 'modal', params: ['close'] });
    }
    // user detail
    private userDetail(ResponderId, UserId) {
        this.UserDetailLoading = true;
        this.userService.userDetail(ResponderId, UserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.DetailedUser = result.Data;
                        this.CallCount = this.DetailedUser[0].TotalCalls;
                        this.DetailedUser[0].Initials = this.DetailedUser ?
                            this.DetailedUser[0].FirstName : ''; // this.GetInitials(this.DetailedUser[0].Name);
                    } else {
                        alert(result.Message);
                    }
                    this.UserDetailLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }

    public ActiveInactiveFolders(FolderId) {
        this.IsCalls = false;
        this.IsChatHistory = false;
        this.IsPayments = false;
        this.ShowCallTable = false;
        this.ShowChatTable = false;
        this.ShowPaymentTable = false;
        this.ShowDocTable = true;
        this.Folders.forEach(function (item) {
            if (item.Id === 5) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large der';
            }
            if (item.Id === 6) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large grn';
            }
            if (item.Id === 7) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large blu';
            }
            if (item.Id === 8) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large pnk';
            }
            if (item.Id === 12) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large prpl';
            }
        });
        this.Folders.forEach(function (item) {
            if (item.Id === FolderId && FolderId === 5) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large der active';
            } else if (item.Id === FolderId && FolderId === 6) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large grn active';
            } else if (item.Id === FolderId && FolderId === 7) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large blu active';
            } else if (item.Id === FolderId && FolderId === 8) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large pnk active';
            } else if (item.Id === FolderId && FolderId === 12) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large prpl active';
            }
        });
    }

    public showuserdetail(UserId) {
        this._router.navigate(['agency/dashboard']);
    }
    // get initial user by name
    public GetInitials(Name) {
        if (this.Count >= 3) {
            this.Count = 1;
        } else {
            this.Count = this.Count + 1;
        }
        const splittedName = Name.split(' ', 3);
        if (splittedName.length > 1) {
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
        } else {
            return splittedName[0].charAt(0);
        }
    }
    // get active class user
    public GetClass() {
        if (this.Count === 1) {
            return 'iconUser noImg cyan lighten-3';
        } else if (this.Count === 2) {
            return 'iconUser noImg cyan lighten-5';
        } else if (this.Count === 3) {
            return 'iconUser noImg pink lighten-4';
        }
    }
    // show success/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // show video in progress
    showVideoInProgressMsg(archiveId: any) {
        this.toasting('Video is being proccessed. Please try after some time.', 3000, 'red');
        this.userService.GetVideoByArchiveId(archiveId).subscribe(result => {
            if (result.Status && result.Data) {
                const video = result.Data[0];
                if (video) {
                    const index: number = this.Calls.findIndex(x => x.ArchiveId === archiveId);
                    if (index >= 0) {
                        if (video.Url) {
                            const recordedUrl = video.Url.split('?');
                            this.Calls[index].Url = recordedUrl[0];
                            this.Calls[index].VideoExtention = 'mp4';
                        }
                    }
                }
            } else {
                this.toasting(result.Message, 3000, 'red');
            }
        },
            error => {
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this._router.navigate(['/login']);
                }
            });
    }
    // serach call records
    SearchCalls() {
        if (this.searchText.length > 0) {
            this.LoadMoreCounter = 0;
        }
        this.getAgenctCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
    }
    // sort data from database
    ServerSort(sortKey: string) {
        this.LoadMoreCounter = 0;
        if (this.SortKey === sortKey) {
            this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
            this.SortKey = sortKey;
        } else {
            this.SortOrder = 'ASC';
            this.SortKey = sortKey;
        }
        this.getAgenctCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
    }
}
