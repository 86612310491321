import { AfterViewChecked, ElementRef, ViewChild, Component, OnInit, Renderer, NgZone, HostListener } from '@angular/core';
import { UserService } from '../../model/user.service';
import { User } from '../../model/user';
import { Router, ActivatedRoute } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import '../../../assets/tokbox/js/app.js';
import { ChatHistory } from '../../model/chathistory';
import { EventBus } from '../../shared/EventBus/EventBus';
import { TokboxService } from '../../model/tokbox.service';
import { toast } from 'angular2-materialize';
declare var $: any;
declare var myExtObject: any;
@Component({
    templateUrl: './usermessage.component.html'
})
export class UserMessageComponent implements OnInit, AfterViewChecked {
    users: User[] = [];
    public ArrayOnlineTokboxuser = [];
    activeUserObject: any = {};
    Conversastion: any[];
    UserLoading: boolean;
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    TotalUsers: string;
    Count: number;
    LoggedInUserId: number;
    AgencyId: number;
    LocationId: number;
    LanguageFlag: string;
    LoadMoreLoader: boolean;
    LoadMoreCounter: number;
    ShowMessageLoader: boolean;
    NotConversarionFound: boolean;
    TotalConersatstion: string;
    NotCallsFound: boolean;
    NotChatFound: boolean;
    innerWidth: any;
    filter: any;
    ChatFilter: string;
    openchat: any;
    ChatHistory: ChatHistory[];
    ShowChatTable: boolean;
    IsChatHistory: boolean;
    ShowLoader: boolean;
    lastMessageDate: string;
    currentReceiverId: number;
    currentSenderId: number;
    currentUserName: string;
    selectedIdx = 0;
    conversationlistloading: boolean;
    textareaMessage = '';
    SendButton: boolean;
    ActiveUserId = 0;
    ScrollToBottom = true;
    @ViewChild('scrollMe') public myScrollContainer: ElementRef;
    @ViewChild('userChatDiv') public myUserChatDiv: ElementRef;
    @ViewChild('ulMessages') public d1: ElementRef;
    previousUrl: string;
    ngOnInit() {
        // this.scrollToBottom();
        this.clickFirst();
        if (this.userService.decodeToken().user.RoleId !== 2) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
        }

        this.userService.GetInitialSettings(this.LoggedInUserId).subscribe((response) => {
            if (response && response.Data) {
                this._eventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });

    }
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }

    ngAfterViewChecked() {
        // this.scrollToBottom();
        this.clickFirst();
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) { }
    }
    clickFirst(): void {
        try {
            const that = this;
            that.myUserChatDiv.nativeElement.getElementsByTagName('ul')[0].focus();
        } catch (err) { }
    }

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _router: Router,
        private _eventBus: EventBus,
        private tokboxService: TokboxService,
        private renderer: Renderer,
        private _ngZone: NgZone
    ) {
        this.LoadMoreCounter = 0;
        // get agency/user detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoggedInUserId = UserModel.user.UserId;
            this.LocationId = UserModel.user.LocationId;
            this.AgencyId = UserModel.user.AgencyId;
        }
        window['userComponentRef'] = {
            zone: this._ngZone,
            componentFn: (userId, connectionId, isConnected) => this.RefreshList(userId, connectionId, isConnected)
        };
        window['angularComponentRefUserChat'] = {
            zone: this._ngZone,
            MessageReceived: (value) => this.MessageReceived(value),
            RefreshConverstation: (value) => this.RefreshConverstation(value)
        };
        this.route.params.subscribe((params) => {
            if (params['UserId']) {
                this.ActiveUserId = Number(params['UserId']);
            }
        });
        this.selectedIdx = 0;
        this.Count = 1;
        this.ShowLoader = false;
        this.GetConversastion(this.LoadMoreCounter);
    }
    // refresh online/ofline user
    public RefreshList(UserId, ConnectionId, IsConnected) {
        const that = this;
        if (ConnectionId && IsConnected) {
            const isExists = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId) > -1;
            if (!isExists) {
                this.ArrayOnlineTokboxuser.push({ UserId: UserId, ConnectionId: ConnectionId });
            }

        } else {
            const index = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId);
            if (index > -1) {
                this.ArrayOnlineTokboxuser.splice(index, 1);
            }
        }
        if (this.activeUserObject && UserId == this.activeUserObject.UserId) {
            this.activeUserObject.ConnectionId = ConnectionId;
        }
        that.Conversastion.forEach((item) => {
            const isAgentStillConnected = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == item.UserId) > -1;
            if (isAgentStillConnected) {// If USER IS ONLINE
                item.ConnectionId = ConnectionId;
            } else {
                item.ConnectionId = '';
            }

        });
        that.Conversastion.sort(function(a, b) {
            if ( a.ConnectionId > b.ConnectionId ) {
                return -1;
            }
            if ( a.ConnectionId < b.ConnectionId ) {
                return 1;
            }
            return 0;
        });
    }
    // get coverstation of user
    public GetConversastion(OffSet) {
        const that = this;
        if (OffSet === 0) { that.ShowLoader = true; }
        that.UserLoading = true;
        that.conversationlistloading = true;
        this.userService.GetAgentConversations(this.LoggedInUserId, OffSet, this.LocationId)
            .subscribe(
                result => {
                    that.LoadMoreLoader = true;
                    if (result.Status && result.Data) {
                        if (OffSet === 0) {
                            this.Conversastion = result.Data;
                            this.ActiveUserId = (this.ActiveUserId) ? this.ActiveUserId : (result.Data[0].UserId);
                            this.GetChatHistrory(this.ActiveUserId, that.LoggedInUserId, result.Data[0].Name, 0, result.Data[0]);
                        } else {
                            if (result.Data) {
                                this.ActiveUserId = (this.ActiveUserId) ? this.ActiveUserId : result.Data[0].UserId;
                                this.GetChatHistrory(this.ActiveUserId, that.LoggedInUserId, result.Data[0].Name, 0, result.Data[0]);
                                result.Data.forEach(function (item) {
                                    that.Conversastion.push(item);
                                });
                            }
                        }
                        this.Conversastion.forEach(function (item) {
                            item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
                            item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
                            item.Name = `${item.FirstName} ${item.LastName}`;
                            item.Initials = that.GetInitials(item.Name);
                            item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                            item.SenderId = (item.SenderId) ? item.SenderId : item.UserId;
                            item.ReceiverId = that.LoggedInUserId;
                            item.IsRead = (item.IsRead === null) ? true : item.IsRead;
                            item.IsRead = (item.IsRead) ? 'none' : 'block';
                            item.ConnectionId = (item.ConnectionId) ? item.ConnectionId : '';
                        });
                        this.TotalConersatstion = this.Conversastion.length.toString();
                    } else {
                        if (OffSet === 0) { that.NotChatFound = true; }
                        that.ShowLoader = false;
                        // alert(result.Message);
                    }
                    that.conversationlistloading = false;
                    that.UserLoading = false;
                    that.ShowLoader = false;
                    that.LoadMoreLoader = false;
                },
                error => {
                    that.ShowLoader = false;
                    that.UserLoading = false;
                    that.NotChatFound = true;
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }
    public trackByFn(index, item) {
        if (index === 0) { this.lastMessageDate = ''; }
        if (this.lastMessageDate === item.MessageDate) {
            item.ShowParentDate = false;
        } else {
            item.ShowParentDate = true;
        }
        this.lastMessageDate = item.MessageDate;
    }
    // on scroll up load message
    public onScrollUp() {
        if (this.ChatHistory.length % 50 === 0) {
            this.ScrollToBottom = false;
            this.LoadMoreCounter = this.LoadMoreCounter + 50;
            this.GetChatHistrory(this.currentSenderId, this.currentReceiverId,
                this.currentUserName, this.selectedIdx, this.activeUserObject);
        }
    }
    // refresh conversation message
    public RefreshConverstation(Data) {
        if (Data) {
           const UserIndex =  this.Conversastion.findIndex( (userConver) => userConver.UserId == Data.caller_userID);
           if (UserIndex !== -1) {
                this.Conversastion[UserIndex].Message = Data.textMessage;
                this.Conversastion[UserIndex].MessageDateTime = new Date().toISOString();
                if (Data.caller_userID != this.ActiveUserId) {
                    this.Conversastion[UserIndex].IsRead = 'block';
                }
                if (Data.caller_userID != this.ActiveUserId) {
                    this._eventBus.emit({
                        Type: 'MessageReceivedData',
                        data: {}
                    });
                }
           }
        }
    }
    // message received scroll down
    public MessageReceived(data) {
        if (data) {
            try {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            } catch (err) { }
        }
    }
    // get chat history with specific client
    public GetChatHistrory(SenderId, ReceiverId, Name, index, selectedObject, shouldResetChathistory?: boolean) {
        this.ShowLoader = true;
        if (shouldResetChathistory) {
            this.ChatHistory = [];
            this.LoadMoreCounter = 0;
        }
        if (Number(this.currentReceiverId) && this.currentReceiverId !== SenderId) {
            this.MarkasRead(this.currentSenderId, this.currentReceiverId);
        }
        this.currentReceiverId = ReceiverId;
        this.currentSenderId = SenderId;
        this.selectedIdx = index;
        this.currentUserName = Name;
        this.LoadMoreCounter = this.LoadMoreCounter;
        this.NotCallsFound = false;
        this.NotChatFound = false;
        this.ShowChatTable = true;
        this.IsChatHistory = true;
        this.activeUserObject = selectedObject;
        setTimeout(() => {
            $('.user-msg-' + SenderId).css('display', 'none');
        }, 2000);
        localStorage.setItem('activeChatUserId', SenderId);
        this.ActiveUserId = SenderId;
        if (this.Conversastion) {
            const indexCon = this.Conversastion.findIndex(x => x.UserId === this.ActiveUserId);
            if (indexCon > -1) {
                this.activeUserObject = this.Conversastion[indexCon];
            }
        }
        const that = this;
        that.userService.getChatHistory(SenderId.toString(), ReceiverId.toString(), that.LoadMoreCounter).subscribe(
            result => {
                if (result.Status) {

                    if (that.LoadMoreCounter === 0) {
                        this.textareaMessage = '';
                        if (result.Data != null) {
                            that.ChatHistory = result.Data.reverse();
                        } else {
                            that.NotChatFound = true;
                            that.ChatHistory = [];
                            that.ShowChatTable = false;
                        }
                    } else {
                        if (result.Data != null) {
                            result.Data.forEach(function (item) {
                                that.ChatHistory.unshift(item);
                            });
                        }
                    }
                    that.ChatHistory.forEach(function (item) {
                        const dateTime = new Date(item.MessageDateTime).toLocaleString();
                        const date = dateTime.split(',');
                        item.MessageDate = date[0];
                        item.MessageTime = date[1];
                        item.MessageDateTime = dateTime;
                    });
                    if (this.ScrollToBottom) {
                        setTimeout(() => {
                            that.scrollToBottom();
                        }, 200);
                    }
                } else {
                    that.NotChatFound = true;
                    that.ChatHistory = [];
                    that.ShowChatTable = false;
                }
                that.ShowLoader = false;
                that.ScrollToBottom = true;
                if (that.ChatHistory.length > 0 && that.LoadMoreCounter === 0) {
                    this._eventBus.emit({
                        Type: 'MessageReceivedData',
                        data: {count: that.ChatHistory[0].UnreadCount}
                    });
                }
            },
            error => {
                that.ShowLoader = false;
                that.ScrollToBottom = true;
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this._router.navigate(['/login']);
                }
            });
    }

    public showuserdetail(UserId) {
        this._router.navigate(['agency/dashboard']);
    }

    // intial from username
    public GetInitials(Name) {
        if (this.Count >= 3) {
            this.Count = 1;
        } else {
            this.Count = this.Count + 1;
        }
        const splittedName = Name.split(' ', 3);
        if (splittedName.length > 1) {
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
        } else {
            return splittedName[0].charAt(0);
        }
    }

    public GetClass() {
        if (this.Count === 1) {
            return 'iconUser noImg cyan lighten-3';
        } else if (this.Count === 2) {
            return 'iconUser noImg cyan lighten-5';
        } else if (this.Count === 3) {
            return 'iconUser noImg pink lighten-4';
        }
    }
    // send message
    public SendMessage() {
        let UserOnline = false;
        UserOnline = this.activeUserObject.ConnectionId ? true : false;
        if (this.textareaMessage.length > 0) {
                const UserIndex =  this.Conversastion.findIndex( (userConver) => userConver.UserId === this.currentSenderId);
                if (UserIndex !== -1) {
                        this.Conversastion[UserIndex].Message = 'You:' + this.textareaMessage;
                        this.Conversastion[UserIndex].MessageDateTime = new Date().toISOString();
                }
                const currentdate = new Date();
                const messageHtml = '<li class="clearfix"><div class="sent"><p>' + this.textareaMessage +
                '</p><span class="timeLine">' + this.formatAMPM(currentdate) + '</span></div></li>';
                this.renderer.invokeElementMethod(this.d1.nativeElement, 'insertAdjacentHTML', ['beforeend', messageHtml]);
                this.SendButton = false;
                myExtObject.startConversation(this.currentReceiverId, this.currentSenderId, 'chatonly',
                this.textareaMessage, this.AgencyId, this.LocationId, this.LoggedInUserName);
                // save message to database
                this.tokboxService.SaveMessage(this.currentReceiverId, this.currentSenderId, 'chatonly',
                 this.textareaMessage, this.AgencyId, this.LocationId)
                    .subscribe((response) => {},
                    (error) => {}
                 );
                this.scrollToBottom();
                this.textareaMessage = '';
         } else if (this.textareaMessage.length === 0) {
            this.SendButton = false;
        }
    }
    // disable/enable send button
    public messageEnter() {
        if (this.textareaMessage) {
            this.SendButton = true;
        } else {
            this.SendButton = false;
        }
    }
    // show date time
    public formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        const strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
        return strTime;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;

        this.openchat = false;
    }

    public MarkasRead(SenderId, ReceiverId) {
        this.userService.MarkasReadMessage(SenderId, ReceiverId)
        .subscribe((response) => {}, (error) => {});
    }
}
