import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { toast, MaterializeAction } from 'angular2-materialize';
import { UserService } from '../../model/user.service';

declare var $: any;

@Component({
  selector: 'app-user-sign-header',
  templateUrl: './user-sign-header.component.html'
})

export class UserSignHeaderComponent implements OnInit {
  // model1Params: any;
  model1Params = [
    {
        dismissible: false
    }
  ];
  modalActions = new EventEmitter<string | MaterializeAction>();
  toggleNav: any;
  checkboxValue: Boolean;
  annotationsPending: any = 1;
  Count: number;
  SendClickNotif: EventEmitter<boolean>;
  LanguageFlag: string;
  LoggedInUserName: string;
  LoginUserId: number;
  userId: number;
  documentId: number;
  IpAddress: any;
  errorMessage: any;
  documentKey: string;
  annotationNull = 1;
  SubmitButtonShoulddisable: boolean;
  ShouldDisableCheckbox: boolean;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private _router: Router,
  ) {
      this.SubmitButtonShoulddisable = true;
      // get agency/user detail from server
      const UserModel = this.userService.decodeToken();
      if (UserModel && UserModel.user) {
        this.LoggedInUserName = UserModel.user.Name;
        this.LoginUserId = UserModel.user.UserId;
      } else {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
      this.route.params.forEach((params: Params) => {
      this.documentId = parseInt(params['docId'], null);
    });
    this.route.params.forEach((params: Params) => {
      this.documentKey = params['id'];
      this.documentKey = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + this.documentKey + '.pdf';
      this.getIP();
    });


    const DocumentKey = this.documentKey;
    const DocumentAnnotation = JSON.parse(localStorage.getItem(DocumentKey + '/annotations'));


    setTimeout(function () {
      $('#submitPDFToAgent').attr('disabled', true);
    }, 200);
  }

  AcceptUETACheck(event) {

    const checkedValue = $('.AcceptUETACheck').prop('checked');
    if (checkedValue) {
      this.SubmitButtonShoulddisable = false;
      $('head').append('<base href="/">');
    } else {
      this.SubmitButtonShoulddisable = true;
    }
  }

  public CheckAcceptUETACheck() {
  }

  public sendSignedPdf() {
    const checkedValue = $('.AcceptUETACheck').prop('checked');

    if (checkedValue === true) {
      this.SubmitButtonShoulddisable = true;
      this.ShouldDisableCheckbox = true;
      $('.loader-outer').css('display', 'inline-flex');
      const agentDetail = [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor
      ];
      const deviceInfo = JSON.stringify(agentDetail);
      this.userService.SaveUserSignature(this.documentId, this.documentKey, this.IpAddress, deviceInfo)
        .subscribe(
          result => {
            if (result.Status) {
              $('#uploadDoc').modal();
              $('#uploadDoc').modal('close');
              $('.loader-outer').css('display', 'none');
              $('body').removeAttr('style');
              this.SubmitButtonShoulddisable = false;
              this.ShouldDisableCheckbox = false;
              this._router.navigate(['/user/document']);
            } else {
              this.toasting(result.Message, 2000, 'red');
            }
          },
          error => {
            $('.loader-outer').css('display', 'none');
            this.SubmitButtonShoulddisable = false;
            this.ShouldDisableCheckbox = false;
            if (error.Message === 'Token expired') {
              this._router.navigate(['/login']);
            }
          }
        );

    } else {
      this.modalActions.emit({ action: 'modal', params: ['open'] });
    }
  }
  // get ip address user
  public getIP() {
    this.userService.getIP().subscribe(
      IPDetails => this.IpAddress = IPDetails.ip,
      error => this.errorMessage = <any>error
    );
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  ngOnInit() {
    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#uploadDoc').modal({ dismissible: false });
      $('.SignatureUploadModal').modal({ dismissible: false });
    }, 1000);
  }

  cancelSignedPdf() {

    $('.AcceptUETACheck').prop('checked', false);
    this.modalActions.emit({ action: 'modal', params: ['close'] });
    $('#submitPDFToAgentUETA').attr('disabled', true);
  }

  redirectToPendingDoc() {
    this._router.navigate(['/user/pendingdoc']);
    $('head').append('<base href="/">');
  }
}
