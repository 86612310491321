import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { toast } from 'angular2-materialize';
import { UserService } from '../../model/user.service';
import { MaterializeAction } from 'angular2-materialize';
import { EventBus } from '../../shared/EventBus/EventBus';

const CountryCodes = require('../../helper/CountryCode.json');
declare var $: any;

@Component({
    templateUrl: './landing.component.html'
})

export class AgencyLandingComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('cardInfo') cardInfo: ElementRef;
    card: any;
    showCPassword: boolean;
    showPassword: boolean;
    planOb: any[];
    model1Params: any;
    password: any;
    cardHandler = this.onChange.bind(this);
    error: string;
    IsValidLength: boolean;
    IsFullNameValid: boolean;
    IsCompNameValid: boolean;
    IsEmailValid: boolean;
    IsStrong: boolean;
    IsMatch: boolean;
    PlanDetail: any;
    _isCardValid: boolean;
    _signupForm: FormGroup;
    _addForm: FormGroup;
    private _formErrors: any;
    ShowLoader: boolean;
    ShowCouponLoader: boolean;
    PlanPrice: string;
    PlanName: string;
    Plan_img: string;
    Plan_for: string;
    VideoHour: string;
    RecordingHour: string;
    Agent: string;
    Location: string;
    ChatWithUsers: string;
    EmailSupport: string;
    PromoCode: string;
    discountamount: string;
    showsucesscoupon: boolean;
    neweffectiveamount: any;
    showinvalidcoupon: boolean;
    PapValue: string;
    public SelectedCountryFlag: string;
    StripePlanId: string;
    public hasTrialDays: boolean;
    public trialDays: number;
    _countryCode: any = [];
    private trialDayKey = 'LandingPageTrialDays';
    public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    modalActions1 = new EventEmitter<string | MaterializeAction>();

    public city: string;
    public state: string;
    public zipcode: string;
    public lat: any;
    public long: any;
    public street_name: string;
    public stroute_flag: any;
    public stroute: string;
    public AddressDescription: any;
    public AgencyCity: any;
    public AgencyZipCode: any;
    public AgencyState: any;
    Address1latitude: any;
    Address1longitude: any;

    strouteComponent = {
        'sublocality_level_1': 'long_name'
    };
    stateComponent = {
        administrative_area_level_1: 'long_name'
    };
    postalComponent = {
        postal_code: 'short_name'
    };
    cityComponent = {
        locality: 'long_name'
    };

    ngAfterViewInit() {
        const style = {
            base: {
                lineHeight: '24px',
                fontFamily: 'monospace',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#ddd'
                }
            }
        };
        this.card = elements.create('card', { style });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }

    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }

    onChange({ error }) {
        if (error) {
            this.error = error.message;
            this._isCardValid = false;
        } else {
            this.error = null;
            this._isCardValid = true;
        }
        this.cd.detectChanges();
    }

    constructor(private _router: Router,
        private userService: UserService,
        private _formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        private _userService: UserService, private route: ActivatedRoute, private _eventBus: EventBus) {
        this._eventBus.emit({ Type: `show-authorized-header`, data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
        const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
        const passwordConfirm = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));
        this._isCardValid = false;
        // signup form agency
        this._signupForm = this._formBuilder.group({
            companyname: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(200)])],
            FirstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(50)])],
            LastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(50)])],
            email: ['', Validators.compose([Validators.required, CustomValidators.email])],
            phone: ['', Validators.compose([Validators.required, Validators.maxLength(15),
            Validators.minLength(8)])],
            password: password,
            confirmpassword: passwordConfirm,
            isagreed: [false, Validators.required],
            SelectedCountryCode: ['US'],
            Address1: new FormControl(''),
            City: new FormControl(''),
            State: new FormControl(''),
            ZipCode: new FormControl(''),
            Address1latitude: new FormControl(''),
            Address1longitude: new FormControl(''),
            Address1PlaceId: new FormControl('')
        });

        this._signupForm.controls['SelectedCountryCode'].setValue('US', { onlySelf: true });
        this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
        this.IsFullNameValid = true;
        this.IsCompNameValid = true;
        this.IsEmailValid = true;
        this._countryCode = CountryCodes;
        this.SelectedCountryFlag = 'US.png';
    }

    ngOnInit() {
        const PlanObj = localStorage.getItem('PlanObj');
        if (!PlanObj) {

            this._router.navigate(['/pricing']);

        } else {
            this.planOb = JSON.parse(PlanObj);
            this.PlanPrice = this.planOb['Price'];
            this.PlanName = this.planOb['PlanName'];
            this.Plan_for = this.planOb['PlanDuration'];
            this.Plan_img = this.planOb['PlanPicture'];
            this.VideoHour = this.planOb['VideoHour'];
            this.RecordingHour = this.planOb['RecordingHour'];
            this.Agent = this.planOb['Agent'];
            this.Location = this.planOb['Location'];
            this.ChatWithUsers = this.planOb['ChatWithUsers'];
            this.EmailSupport = this.planOb['EmailSupport'];
            this.StripePlanId = this.planOb['StripePlanId'];
            // this.PlanPrice=this.planOb['Price'];
            localStorage.setItem('PlanPrice', JSON.stringify(this.PlanPrice));
            localStorage.setItem('PlanName', JSON.stringify(this.PlanName));
            localStorage.removeItem('PlanObj');
            this.IsValidLength = false;
            this.IsStrong = false;

            this.GetTrialDays(this.trialDayKey);
        }
    }
    // signup agency
    submitRegister(checkApp) {
        if (checkApp !== 1) {
            this.ShowLoader = true;
            if (this.card._complete === false) {
                this._isCardValid = false;
                this.ShowLoader = false;
                this._isCardValid = false;
                return false;
            } else {
                stripeNew.createToken(this.card).then((result) => {
                    if (result.error) {
                        this.toasting(result.message, 2000, 'red');
                        this.ShowLoader = false;
                    } else {
                        let Domain;
                        if (this._userService.IsLocalHost()) {
                            Domain = 'innovativeinsuranceprofessional.com';
                        } else {
                            Domain = this._userService.getDomain();
                        }
                        this._signupForm.controls['Address1'].setValue($('#search_places').val());
                        if ((<HTMLInputElement>document.getElementById('search_places')).value !== this.AddressDescription) {
                            this._signupForm.controls['Address1latitude'].setValue('');
                            this._signupForm.controls['Address1longitude'].setValue('');
                        } else {
                            this._signupForm.controls['Address1latitude'].setValue(this.Address1latitude);
                            this._signupForm.controls['Address1longitude'].setValue(this.Address1longitude);
                        }

                        this._userService.RegisterAgency(this._signupForm.value.companyname,
                            this._signupForm.value.FirstName,
                            this._signupForm.value.LastName,
                            this._signupForm.value.email,
                            this._signupForm.value.password,
                            result.token.id,
                            this.StripePlanId,
                            this.PlanName,
                            1,
                            this.PromoCode,
                            Domain,
                            this.PapValue,
                            0,
                            this.trialDays,
                            2,
                            this._signupForm.value.phone,
                            this._signupForm.value.SelectedCountryCode,
                            this._signupForm.value.Address1,
                            this._signupForm.value.City,
                            this._signupForm.value.State,
                            this._signupForm.value.ZipCode,
                            this._signupForm.value.Address1latitude,
                            this._signupForm.value.Address1longitude,
                            this._signupForm.value.Address1PlaceId
                        ).subscribe(
                            dbresult => {
                                if (dbresult.Status) {
                                    this._router.navigate(['agency/step']);
                                    this.ShowLoader = false;
                                } else {
                                    this.toasting(dbresult.Message, 2000, 'red');
                                    this.ShowLoader = false;
                                }
                            },
                            error => {
                                // Validation error
                                if (error.status === 422) {
                                    this._resetFormErrors();
                                    this.ShowLoader = false;
                                    const errorFields = JSON.parse(error.data.message);
                                    this._setFormErrors(errorFields);
                                } else {
                                    this.ShowLoader = false;
                                }

                            }
                        );
                    }
                });
            }
        } else {
            this.toasting('You cannot register with this company name!', 2000, 'red');
        }
    }

    onSubmit(elementValues: any) {
        this._userService.appcheckname().subscribe(data => {
            const appCheckNames = data;
            const that = this;
            let checkApp = 0;
            $.each(appCheckNames, (i1: any, v1: any) => {
                const SubDomainValue = that._userService.getSubdomain();
                if (v1.Name === this._signupForm.value.companyname) {
                    checkApp = 1;
                }
            });
            this.submitRegister(checkApp);
        }, error => error);
    }
    // set defalut promo
    public setpromodefault() {
        this.showsucesscoupon = false;
        this.showinvalidcoupon = false;
    }
    // check promocode validation
    public CheckPromoCode(promocode) {
        this.PromoCode = promocode;
        if (this.PromoCode) {
            this.ShowCouponLoader = true;
            this.userService.CheckPromoCode(this.PromoCode)
                .subscribe(
                    result => {
                        this.ShowCouponLoader = false;
                        if (result.Status) {
                            this.discountamount = result.Data.percent_off;
                            this.showsucesscoupon = true;
                            this.showinvalidcoupon = false;
                            this.neweffectiveamount = (Number(this.PlanPrice) - (Number(result.Data.percent_off_precise) / 100) *
                                Math.ceil(Number(this.PlanPrice))).toFixed();
                        } else {
                            this.showinvalidcoupon = true;
                            this.showsucesscoupon = false;
                        }
                    },
                    error => {
                        if (error.Message === 'Token expired') {
                            localStorage.removeItem('frontend-token');
                            localStorage.removeItem('StripeCust');
                            this._router.navigate(['/login']);
                        }
                    }
                );
        } else { this.toasting('Please enter promo code', 3000, 'red'); }
    }
    // check passwords matched
    CheckConform(Pass, ConfirmPass) {
        if (ConfirmPass.length > 0) {
            this.IsMatch = true;
            if (Pass !== ConfirmPass) {
                this.IsMatch = true;
            } else {
                this.IsMatch = false;
            }
        } else {
            this.IsMatch = false;
        }
    }
    // on chnage country update Image
    public onChangeCode(event) {
        this.SelectedCountryFlag = event.value + '.png';
    }
    // check password validation regarlar expression
    CheckPass(data) {
        if (data.value.length > 0) {
            if (data.value.length < 7) {
                this.IsValidLength = true;
            } else {
                this.IsValidLength = false;
                const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
                if (mediumRegex.test(data.value)) {
                    this.IsStrong = false;
                } else {
                    this.IsStrong = true;
                }
            }
        } else {
            this.IsValidLength = false;
            this.IsStrong = false;
        }

    }
    // check company name regex pattern
    CheckCompName(data) {
        if (data.value.length > 1) {
            const regex = /[!@#$%^&*(),~`'.?":{}|;<>]/g;
            if (regex.exec(data.value) != null) {
                this.IsCompNameValid = false;
                return;
            }

            if (data.value.length > 50) {
                this.IsCompNameValid = false;
            } else {
                this.IsCompNameValid = true;
            }
        } else {
            this.IsCompNameValid = false;
        }
    }
    // check form field valid or not
    public _isValid(field): boolean {
        let isValid: boolean;
        if (this._signupForm.controls[field].touched === false) {
            isValid = true;
        } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
            isValid = true;
        }
        return isValid;
    }
    // form field error after response
    private _setFormErrors(errorFields: any): void {
        for (const key in errorFields) {
            if (!errorFields.hasOwnProperty(key)) { continue; }
            const message = errorFields[key];
            this._formErrors[key].valid = false;
            this._formErrors[key].message = message;
        }
    }
    // reset form errors
    private _resetFormErrors(): void {
        this._formErrors = {
            companyname: { valid: true, message: '' },
            name: { valid: true, message: '' },
            email: { valid: true, message: '' },
            password: { valid: true, message: '' },
            confirmpassword: { valid: true, message: '' }
        };
    }
    // show success/error message toast
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // on value change clear validation and error message
    public onValueChanged(data?: any) {
        if (!this._signupForm) { return; }
        const form = this._signupForm;
        for (const field in this._formErrors) {
            // clear previous error message (if any)
            if (this._formErrors.hasOwnProperty(field)) {
                const control = form.get(field);
                if (control && control.dirty) {
                    this._formErrors[field].valid = true;
                    this._formErrors[field].message = '';
                }
            }
        }
    }
    scroll(el) {
        el.scrollIntoView({
            behavior: 'smooth'
        });
    }
    // open plan list modal
    viewPlanDetails() {
        this.modalActions1.emit({ action: 'modal', params: ['open'] });
    }
    // get trial days database
    private GetTrialDays(key) {
        this.userService.GetTrialDays(key)
            .subscribe(
                result => {
                    if (result.Status && result.Data) {
                        const trialDays = result.Data[0].Value ? Number(result.Data[0].Value) : 0;
                        this.trialDays = trialDays;
                        this.hasTrialDays = trialDays > 0 ? true : false;
                    } else {
                        this.toasting(result.Message, 3000, 'red');
                        this.trialDays = 0;
                        this.hasTrialDays = false;
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.trialDays = 0;
                    this.hasTrialDays = false;
                }
            );
    }
    // google auto complete address
    autoCompleteCallback1(selectedData: any) {
        if (selectedData.data) {
            const place = selectedData.data;
            const name = place.name;
            let stroute_flag = 0;
            let stroute = '';
            let city_flag = 0;
            let state_flag = 0;
            let zip_flag = 0;

            this.AddressDescription = place.description;
            for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0];
                if (this.strouteComponent[addressType]) {
                    stroute_flag = 1;
                    stroute = place.address_components[i][this.strouteComponent[addressType]];
                }
                if (this.cityComponent[addressType]) {
                    city_flag = 1;
                    this.city = place.address_components[i][this.cityComponent[addressType]];
                }
                if (this.stateComponent[addressType]) {
                    state_flag = 1;
                    this.state = place.address_components[i][this.stateComponent[addressType]];
                }
                if (this.postalComponent[addressType]) {
                    zip_flag = 1;
                    this.zipcode = place.address_components[i][this.postalComponent[addressType]];
                }
            }

            if (city_flag) {
                this.AgencyCity = this.city;
            } else {
                this.AgencyCity = '';
            }
            if (state_flag) { this.AgencyState = this.state; } else { this.AgencyState = ''; }
            if (zip_flag) { this.AgencyZipCode = this.zipcode; } else { this.AgencyZipCode = ''; }
            this.street_name = name;
            this.stroute_flag = stroute_flag;
            this.stroute = stroute;

            this._signupForm.controls['City'].setValue(this.city);
            this._signupForm.controls['State'].setValue(this.state);
            this._signupForm.controls['ZipCode'].setValue(this.zipcode);

            if (place && place.geometry && place.geometry.location) {
                this.lat = Number(place.geometry.location.lat);
                this.long = Number(place.geometry.location.lng);

                this.Address1latitude = this.lat.toFixed(3);
                this.Address1longitude = this.long.toFixed(3);
            }
        } else {
            this.Address1latitude = '';
            this.Address1longitude = '';
        }
    }

}

