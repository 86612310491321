import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction, toast } from 'angular2-materialize';
import { UserService } from '../../model/user.service';
import '../../../assets/tokbox/js/app.js';
import { Router } from '@angular/router';
import { EventBus } from '../EventBus/EventBus';

declare var myExtObject: any;
declare var $: any;
declare function getStripePublishableKey(token: string): void;
import { User } from '../..//model/user';
import { AddBankAccountComponent } from '../add-bank-account/add-bank-account.component';

@Component({
  moduleId: module.id,
  selector: 'app-stripe-custom-addcardform',
  templateUrl: 'add-card.component.html'
})

export class CustomFormComponent implements OnInit {
  UserEmail: string;
  AgencyEmail: string;
  DetailedUser: User;
  _chargeCustomer: FormGroup;
  ShowConfirmDeleteLoader: boolean;
  private _formErrors: any;
  private _errorMessage: string;
  SelectedCustomerId: string;
  SelectedCustomerCardId: string;
  model1Params: any;
  paymentMethodSetup: boolean;
  paymentMethodMessage: any;
  modalActions = new EventEmitter<string | MaterializeAction>();
  deleteconfirmaction = new EventEmitter<string | MaterializeAction>();
  params = [];
  rowIndex: number;
  uploaddocmodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  AgencyName: string;
  ShowMe: boolean;
  UserId: number;
  AgencyId: number;
  LocationId: number;
  Name: string;
  StripeCustomerId: string;
  SelectedCardId: string;
  PaymentMethodList: any[];
  PaymentMethodListCard: any[];
  PaymentMethodListBankAccount: any[];
  IsLoadingPaymentMethodList: boolean;
  LoadMoreCounter: number;
  ShowLoader: boolean;
  ShowButtonLoader: boolean;
  ShowCreateChargeDiv: boolean;
  IsRefreshingCard: boolean;
  HasPaymentMethodList: boolean;
  message: string;
  PaymentMethodType: any;
  IsAddPaymentmathodShow: boolean;
  @ViewChild(AddBankAccountComponent) AddBankAccountComponentViewChild: AddBankAccountComponent;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private _router: Router,
    private _eventBus: EventBus
  ) {
    // get agency user detail from modal
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.UserId = UserModel.user.UserId;
      this.AgencyName = UserModel.user.AgencyName;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.Name = UserModel.user.Name;
      this.GetAgencyDetail();
    }
    this.rowIndex = 0;
    this.StripeCustomerId = this.userService.getCustId();
    this.ShowButtonLoader = false;
    this.SelectedCardId = '';
    this.ShowCreateChargeDiv = false;
    this.LoadMoreCounter = 0;
    // charge form
    this._chargeCustomer = _formBuilder.group({
      amount: ['', Validators.compose([Validators.required])],
      SelectedCardId: ['', Validators.compose([Validators.required])],
      description: ['']
    });
    this._chargeCustomer.valueChanges.subscribe(data => this.onValueChanged(data));

    this._eventBus.on().subscribe(m => {
      if (m.filterBy.Type === 'Payment-Card-Added') {
        if (m.filterBy.data) {
          this.RefreshList();
        }
      } else if (m.filterBy.Type === 'Load-Bank-Account-Event') {
        $('#paycontainer').show();
        this.modalActions.emit({ action: 'modal', params: ['open'] });
        this.getPaymentMethod();
      }
    });

    this.userDetail(0, this.UserId);
  }
  // on value change form reset validation/error message
  public onValueChanged(data?: any) {
    if (!this._chargeCustomer) { return; }
    const form = this._chargeCustomer;
    for (const field in this._formErrors) {
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }

  ngOnInit() {
    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#uploadDoc').modal({ dismissible: false });
    }, 400);
  }

  private _chargeCustomercheckisValid(field): boolean {
    let isValid: boolean;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._chargeCustomer.controls[field].touched === false) {
      isValid = true;
    } else if (this._chargeCustomer.controls[field].touched === true && this._chargeCustomer.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // make payment customer
  public MakePayment(elementValues: any) {
    this.ShowButtonLoader = true;
    const that = this;
    if (elementValues.description == null) { elementValues.description = ''; }
    this.userService.MakePayment(this.UserId, elementValues.amount, this.SelectedCardId, this.StripeCustomerId,
      elementValues.description, this.AgencyId, this.LocationId, this.UserEmail, this.Name, this.AgencyName, this.AgencyEmail)
      .subscribe(
        result => {
          if (result.Status) {
            this.toasting('Payment done successfully!', 2000, 'green');
            that.closeAddCardModal();
            this.ShowButtonLoader = false;
            that._eventBus.emit({ Type: 'Load-User-Dashboard-Event' });
            that._eventBus.emit({ Type: 'Get-User-Payment-Event', data: { offSet: that.LoadMoreCounter } });
          } else {
            this.ShowButtonLoader = false;
            this.toasting(result.Message, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // on selection card payment
  public onCardSelectionChange(selectedValue) {
    this.SelectedCardId = selectedValue;
    this._chargeCustomer.controls['SelectedCardId'].setValue(selectedValue);
  }
  // get payment methods customers
  public getPaymentMethod() {
    const that = this;
    let count = 0;
    this.rowIndex = 0;
    this.IsLoadingPaymentMethodList = true;
    this.userService.getAllPaymentMethod(this.UserId)
      .subscribe(
        result => {
          if (result.Status && result.Data) {
            this.IsLoadingPaymentMethodList = false;
            that.PaymentMethodListCard = result.Data.CardList ? result.Data.CardList : [];
            that.PaymentMethodListBankAccount = result.Data.BankAccountList ? result.Data.BankAccountList : [];
            that.IsRefreshingCard = false;
            that.PaymentMethodList = [];
            that.PaymentMethodListCard.forEach(function (item) {
              if (count === 0) { that.SelectedCardId = item.StripeCardId; }
              item['MethodType'] = 'Card';
              item['ShowDeleteLoader'] = false;
              that.PaymentMethodList.push(item);
              count++;
            });

            that.PaymentMethodListBankAccount.forEach(function (item) {
              if (count === 0 && item.IsVerified) { that.SelectedCardId = item.StripeBankAccountId; }
              item['MethodType'] = 'BankAccount';
              item['ShowDeleteLoader'] = false;
              that.PaymentMethodList.push(item);
              count++;
            });
            if (that.PaymentMethodList.length === 0) { that.SelectedCardId = ''; }
            this._chargeCustomer.controls['SelectedCardId'].setValue(that.SelectedCardId);

            if (that.PaymentMethodList.length === 0) {
              that.SelectedCardId = '';
              that._chargeCustomer.controls['SelectedCardId'].setValue('');
              that.HasPaymentMethodList = false;
            } else {
              that.HasPaymentMethodList = true;
            }
            count = 0;
          } else {
            that.SelectedCardId = '';
            this._chargeCustomer.controls['SelectedCardId'].setValue('');
            that.PaymentMethodList = [];
            this.IsLoadingPaymentMethodList = false;
          }
        },
        error => {
          this.IsLoadingPaymentMethodList = false;
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }

  paymentMethodTrackBy(index: any, paymentMethod: any) {
    this.rowIndex = this.rowIndex + 1;
    if (paymentMethod.StripeCardId) {
      return paymentMethod.StripeCardId;
    } else if (paymentMethod.StripeBankAccountId) {
      return paymentMethod.StripeBankAccountId;
    }

  }
  // manage create card customer
  public ManageAddCard(value) {
    this.IsAddPaymentmathodShow = false;
    if (value) {
      const millisecondsToWait = 1;
      setTimeout(function () {
        myExtObject.CreateCardInstance();
      }, millisecondsToWait);
      myExtObject.ShowAddCard();
    } else { myExtObject.HideAddCard(); }
  }

  public ToggleCreateCharge() {
    this.ShowCreateChargeDiv = !this.ShowCreateChargeDiv;
  }
  // remove card customer
  public RemoveCard() {
    const that = this;
    this.ShowConfirmDeleteLoader = true;
    this.PaymentMethodList.forEach(function (item) {
      if (that.SelectedCustomerCardId === item.StripeCardId) {
        item['ShowDeleteLoader'] = true;
      }
    });
    if (this.PaymentMethodType === 'Card') {
      this.userService.RemovePayMethod(this.SelectedCustomerId, this.SelectedCustomerCardId, this.AgencyId)
        .subscribe(
          result => {
            that.closedeleteConfirmModal();
            that.ShowConfirmDeleteLoader = false;
            if (result.Status) {
              that.toasting('Card removed successfully!', 2000, 'green');
              that.getPaymentMethod();
            } else {
              that.toasting(result.Message, 5000, 'red');
            }
            that._eventBus.emit({ Type: 'Load-Pyament-Method-Event' });
          },
          error => {
            that.closedeleteConfirmModal();
            that.ShowConfirmDeleteLoader = false;
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
          }
        );
    } else if (this.PaymentMethodType === 'BankAccount') {
      this.userService.RemoveBankAccount(this.SelectedCustomerId, this.SelectedCustomerCardId, this.AgencyId)
        .subscribe(
          result => {
            that.ShowConfirmDeleteLoader = false;
            that.closedeleteConfirmModal();
            that.getPaymentMethod();
            if (result.Status) {
              that._eventBus.emit({ Type: 'Load-Pyament-Method-Event' });
              that.toasting('Bank Account removed successfully!', 2000, 'green');
            } else {
              that.toasting(result.Message, 5000, 'red');
            }
          },
          error => {
            that.ShowConfirmDeleteLoader = false;
            that.closedeleteConfirmModal();
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
          }
        );
    }
  }
  // show success/error message customer
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // refresh payment methods
  public RefreshList() {
    const that = this;
    const millisecondsToWait = 9000;
    that.getPaymentMethod();
    that._eventBus.emit({ Type: 'Load-User-Dashboard-Event' });
  }
  // Event Emitter When Payment Added
  public isCardAdded(data) {
    this._eventBus.emit({ Type: 'Load-User-Dashboard-Event' });
  }
  // open add card modal
  public openAddCardModal(ShowAdd?: boolean) {
    this.ShowLoader = true;
    this._chargeCustomer.reset();
    // check if company setup thier payment method
    this.userService.CheckStripeAccountStatus(this.AgencyId, this.UserId)
      .subscribe(
        result => {
          // if company setup payment method
          if (result.Status === true) {
            getStripePublishableKey(result.Data.account_detail.PublishableKey);
            localStorage.removeItem('StripeCust');
            localStorage.setItem('StripeCust', result.Data.user_detail.StripeCustomerId);
            this.StripeCustomerId = result.Data.user_detail.StripeCustomerId;
            const that = this;
            this.ShowMe = true;
            const millisecondsToWait = 1;
            if (!ShowAdd) {
              this.ShowLoader = false;
              setTimeout(function () {
                that.ManageAddCard(false);
                that.getPaymentMethod();
                that.modalActions.emit({ action: 'modal', params: ['open'] });
              }, millisecondsToWait);
            } else {
              this.ShowLoader = false;
              setTimeout(function () {
                that.ManageAddCard(true);
                that.getPaymentMethod();
                that.modalActions.emit({ action: 'modal', params: ['open'] });
              }, millisecondsToWait);
            }
          } else {
            this.ShowLoader = false;
            this.toasting(result.Message, 3000, 'red');
            $('#NoPaymentMothodSetup').modal('open');
          }
        },
        error => {
          // Validation error
          if (error.status === 422) {
            this.ShowLoader = false;
          } else {
            this.ShowLoader = false;
          }
        }
      );
    return false;
  }
  // close add card modal
  public closeAddCardModal() {
    const that = this;
    this.ShowMe = false;
    const millisecondsToWait = 1;
    setTimeout(function () {
      that.ShowCreateChargeDiv = false;
      that._chargeCustomer.reset();
      that.modalActions.emit({ action: 'modal', params: ['close'] });
    }, millisecondsToWait);
  }
  // set account delete
  public SetAccountValuesToDelete(StripeCustomerId, StripeCardId, PaymentMethodType) {
    this.SelectedCustomerId = StripeCustomerId;
    this.SelectedCustomerCardId = StripeCardId;
    this.PaymentMethodType = PaymentMethodType;
  }

  public RemoveAccountValuesToDelete() {
    this.SelectedCustomerId = '';
    this.SelectedCustomerCardId = '';
    this.PaymentMethodType = '';
  }
  // open delete confirm modal
  public opendeleteConfirmModal(paymethod) {
    if (paymethod.MethodType === 'Card') {
      this.SetAccountValuesToDelete(paymethod.StripeCustomerId, paymethod.StripeCardId, paymethod.MethodType);
    } else if (paymethod.MethodType === 'BankAccount') {
      this.SetAccountValuesToDelete(paymethod.StripeCustomerId, paymethod.StripeBankAccountId, paymethod.MethodType);
    } this.deleteconfirmaction.emit({ action: 'modal', params: ['open'] });
  }
  // close detail confirm modal
  public closedeleteConfirmModal() {
    this.RemoveAccountValuesToDelete();
    this.deleteconfirmaction.emit({ action: 'modal', params: ['close'] });
  }
  // user detail
  private userDetail(ResponderId, UserId) {
    this.userService.userDetail(ResponderId, UserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.DetailedUser = result.Data;
            const userName = this.DetailedUser ? `${this.DetailedUser[0].FirstName} ${this.DetailedUser[0].LastName}` : '';
            this.UserEmail = this.DetailedUser[0].Email;

          } else {
            this.toasting(result.Message, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // get agency detail
  GetAgencyDetail() {
    this.userService.GetAgencyDetail(this.UserId)
      .subscribe(
        result => {
          if (result.Status) {
            const add1 = result.Data[0].AddressOne;
            $('#search_places').val(add1);
            this.AgencyEmail = result.Data[0].AgencyEmail;
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
            this.ShowLoader = false;
          }
        }
      );
  }
  // verify bank account
  public VerifyBankAccount(paymethod) {
    this.modalActions.emit({ action: 'modal', params: ['close'] });
    this.AddBankAccountComponentViewChild.openbankverifyModal(paymethod.StripeBankAccountId);
  }
  // add new payment method
  public AddNewPaymentMethodClickHabndler() {
    $('#paycontainer').hide();
    this.IsAddPaymentmathodShow = true;
  }
  // add new bank account modal
  public AddNewBankAccount() {
    this.modalActions.emit({ action: 'modal', params: ['close'] });
    this.IsAddPaymentmathodShow = false;
    this.AddBankAccountComponentViewChild.openAddBankAccountModal('', true, true);
  }
}
