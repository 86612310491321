import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { EventBus } from '../EventBus/EventBus';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-pending-notification',
    templateUrl: './user-pending-doc-notification.component.html'
})

export class UserPendingNotificationComponent implements OnInit {
    public DocCount: number;
    public PaymentCount: number;
    public UnSignedDocCount: number;
    public LoginUserId: number;
    public ShowDashLoader: boolean;
    constructor(private userService: UserService, private _EventBus: EventBus, private _router: Router) {
        // get agency/user detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.LoginUserId = UserModel.user.UserId;
        } else {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
        }
        this.DocCount = 0;
        this.PaymentCount = 0;
        this.UnSignedDocCount = 0;
    }
    ngOnInit() {
        this.ShowDashLoader = true;
        this.userService.getdashdata(this.LoginUserId).subscribe(result => {
            if (result.Status && result.Data) {
                const data = result.Data[0];
                this.DocCount = data.DocCount;
                this.PaymentCount = data.PaymentCount;
                this.UnSignedDocCount = data.UnsignedDoc;
            } else {
                alert(result.Message);
            }
            this.ShowDashLoader = false;
        },
            error => {
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this._router.navigate(['/login']);
                }
                this.ShowDashLoader = false;
            });
    }
}
