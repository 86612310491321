import { Component, OnInit } from '@angular/core';
import { EventBus } from '../EventBus/EventBus';

@Component({
  selector: 'app-ax-noactivecalls',
  templateUrl: './noactivecalls.component.html'
})

export class NoActiveCallsComponent implements OnInit {

  callPanelStatus: boolean;

  constructor(private _EventBus: EventBus) {
  }

  // Toggle call panel on mobile
  ngOnInit() {
    this._EventBus.on().subscribe((m: any) => {
      // Toggle left bar on mobile
      if (m.filterBy.Type === 'toggleCallPanel') {
        if (m.filterBy.data.callPanelStatus) {
          this.callPanelStatus = true;
        }
      }
    });
  }
}
