import { Component, OnInit, EventEmitter, ElementRef, NgZone } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-agent-signature',
  templateUrl: './agent-signature.component.html',
  styleUrls: ['./agent-signature.component.css']
})
export class AgentSignatureComponent implements OnInit {
  public typedSignature = '';
  public typedInitials = '';
  public signature = 'draw';
  public singleSelectionSettings: any = {};
  public singleSelectionselectedItems: any = [];
  public singleIniSelectionselectedItems: any = [];
  public singleSelectionList: any = {};
  public signatureFonts: any = [];
  public fontSelected: any = [];
  public selectFontClass = 'font1';
  public selectFontIntiClass = 'font1';
  public signatureImage = '';
  public signatueError = false;
  SignatureType = 'signature';
  OpenAnother = false;
  modalAgentSignatureParams = [
    {
      dismissible: false
    }
  ];
  modalAgentSignatureAction = new EventEmitter<string | MaterializeAction>();
  public config = {
      displayKey: 'itemName',
      search: false,
      limitTo: 25
  };
  public singleSelect: any = [];
  options: any = [];
  constructor(private elementRef: ElementRef, private _ngZone: NgZone, private router: Router) {
    window['angularComponentRef'] = {
      zone: this._ngZone,
      UpdateTypeSign: (value) => this.UpdateTypeSign(value)
    };
   }

  ngOnInit() {
    this.singleSelectionSettings = {
      singleSelection: true,
      enableSearchFilter: false
    };

    this.singleSelectionList = [

      { id: 1, itemName: 'Great Vibes', class: 'font1', index: 0 },
      { id: 2, itemName: 'Eagle Lake', class: 'font2', index: 1 },
      { id: 3, itemName: 'Petit Formal Script', class: 'font3', index: 2 },
      { id: 4, itemName: 'Berkshire Swash', class: 'font4', index: 3 },
      { id: 5, itemName: 'Aladin', class: 'font5', index: 5 },
    ];


    const that = this;
    setTimeout(function () {
      $('.initials_select input').addClass(that.singleSelectionList[0].class);
      $('.signature_select input').addClass(that.singleSelectionList[0].class);

      // $('.initials_select ul li:nth-child(1)').addClass(that.singleSelectionList[0].class);
      // $('.initials_select ul li:nth-child(2)').addClass(that.singleSelectionList[1].class);
      // $('.initials_select ul li:nth-child(3)').addClass(that.singleSelectionList[2].class);
      // $('.initials_select ul li:nth-child(4)').addClass(that.singleSelectionList[3].class);
      // $('.initials_select ul li:nth-child(5)').addClass(that.singleSelectionList[4].class);
      // $('.initials_select ul li:nth-child(6)').addClass(that.singleSelectionList[5].class);

      // $('.signature_select ul li:nth-child(1)').addClass(that.singleSelectionList[0].class);
      // $('.signature_select ul li:nth-child(2)').addClass(that.singleSelectionList[1].class);
      // $('.signature_select ul li:nth-child(3)').addClass(that.singleSelectionList[2].class);
      // $('.signature_select ul li:nth-child(4)').addClass(that.singleSelectionList[3].class);
      // $('.signature_select ul li:nth-child(5)').addClass(that.singleSelectionList[4].class);
      // $('.signature_select ul li:nth-child(6)').addClass(that.singleSelectionList[5].class);
    }, 100);

    $('#fontNameSign').val('Great Vibes');
    $('#fontNameIni').val('Great Vibes');

    this.singleSelectionselectedItems = [
      { id: 1, itemName: 'Great Vibes', class: 'font1', index: 0 }
    ];

    this.singleIniSelectionselectedItems = [
      { id: 1, itemName: 'Great Vibes', class: 'font1', index: 0 }
    ];
  }
  // open signature modal agent/agency admin
  openAgentSignModal(Version, Type, OpenAnother) {
    this.typedSignature = '';
    this.typedInitials = '';
    this.SignatureType = Type;
    this.OpenAnother = (OpenAnother) ? false : true;
    if (Version > 1) {
      const fileURL = '/assets/signature_pad/agentsignature.js?v=' + (Version - 1);
      $('script[src="' + fileURL + '"]').remove();
    }
    const e = document.createElement('script');
    e.type = 'text/javascript';
    e.src = '/assets/signature_pad/agentsignature.js?v=' + Version;
    this.elementRef.nativeElement.appendChild(e);
    this.modalAgentSignatureAction.emit({ action: 'modal', params: ['open'] });
  }
  public UpdateTypeSign(type) {
    this.SignatureType = type;
    this.typedSignature = '';
    this.typedInitials = '';
  }
  closeModal() {
    $('#UserSignModal').modal('close');
    $('#user-role-annotation').val('user');
    const LastActiveAnnotation = localStorage.getItem('LastActiveAnnotation');
    if (LastActiveAnnotation) {
      $('.rectangle-stamp[data-tooltype="' + LastActiveAnnotation + '"]').trigger('click');
    }
  }

  putCusrsor() {
    setTimeout(function () {
      $('#type_signature').focus();
      $('#type_initials').focus();
      $('#typeInti').css('display', 'block');
    }, 20);
  }
  checkTypedSig() {
    if (this.typedSignature !== '') {
      this.signatueError = false;
    }
  }
  fontChangedSign(event: any) {
    const selectFontClass = this.singleSelectionselectedItems.class;
    this.selectFontClass = this.singleSelectionselectedItems.class;
    // $('#fontNameSign').val($('.signature_select input').val());
    const fontName = this.singleSelectionselectedItems.itemName;
    $('#fontNameSign').val(fontName);
    $('#signature_area').addClass(selectFontClass);
    const that = this;
    // setTimeout(function () {
    //   $('.signature_select input').addClass(selectFontClass);

    //   $('.signature_select ul li:nth-child(1)').addClass(that.singleSelectionList[0].class);
    //   $('.signature_select ul li:nth-child(2)').addClass(that.singleSelectionList[1].class);
    //   $('.signature_select ul li:nth-child(3)').addClass(that.singleSelectionList[2].class);
    //   $('.signature_select ul li:nth-child(4)').addClass(that.singleSelectionList[3].class);
    //   $('.signature_select ul li:nth-child(5)').addClass(that.singleSelectionList[4].class);
    //   $('.signature_select ul li:nth-child(6)').addClass(that.singleSelectionList[5].class);
    // }, 100);
  }
  hideModal() {
    $('#UserSignModal').modal('close');
    $('#user-role-annotation').val('user');
    const LastActiveAnnotation = localStorage.getItem('LastActiveAnnotation');
    if (LastActiveAnnotation) {
      $('.rectangle-stamp[data-tooltype="' + LastActiveAnnotation + '"]').trigger('click');
    }
  }
  public openSign() {
    const that = this;
    setTimeout(() => {
      $('.signature_select ul.selected-items li:nth-child(1)').addClass(this.singleSelectionselectedItems.class);
      $('.signature_select ul.available-items li').each(function()
        {
          const otherFontItem = $(this).text().trim();
          const indexClass = that.singleSelectionList.findIndex((itmFont) => itmFont.itemName === otherFontItem);
          const indexClassElement = indexClass;
          $(this).addClass(that.singleSelectionList[indexClass].class);
       });
    }, 10);
  }
}
