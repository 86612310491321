import { Component, EventEmitter, OnInit } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-integration-popup',
  templateUrl: './integration-popup.component.html',
  styleUrls: ['./integration-popup.component.css']
})
export class IntegrationPopupComponent implements OnInit {

  modalActions3 = new EventEmitter<string | MaterializeAction>();

  constructor() { }

  ngOnInit() {
  }


}
