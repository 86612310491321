import { Component, OnInit, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { toast } from 'angular2-materialize';
import { UserService } from '../../model/user.service';

declare var $: any;
@Component({
  selector: 'app-agent-sign-header',
  templateUrl: './agent-sign-header.component.html'
})

export class AgentSignHeaderComponent implements OnInit {
  @ViewChild('agentsignature') agentsignature: any;
  SignatureCounter = 1;
  Count: number;
  toggleNav: any;
  SendClickNotif: EventEmitter<boolean>;
  LanguageFlag: string;
  LoggedInUserName: string;
  LoginUserId: number;
  userId: number;
  documentId: number;
  IpAddress: any;
  errorMessage: any;
  documentKey: string;
  annotationNull = 1;
  public innerWidth: any;
  SelectedUserRole = 'user';
  InitailImage = '';
  SignatureImage = '';
  LocationId: number;
  ngOnInit() {
    // Get device width
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private _router: Router,
  ) {
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    this.LoggedInUserName = UserModel.user.Name;
    this.LoginUserId = UserModel.user.UserId;
    if (this.userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId === 6) {
      this.LocationId = Number(this.userService.GetSelectedLocation());
    } else {
        this.LocationId = UserModel.user.LocationId;
    }
    this.route.params.forEach((params: Params) => {
      this.userId = params['id2'];
      this.documentId = params['id3'];
    });
    this.route.params.forEach((params: Params) => {
      this.documentKey = params['id'];
      this.documentKey = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + this.documentKey + '.pdf';
      this.getIP();
    });


    const DocumentKey = this.documentKey;
    const DocumentAnnotation = JSON.parse(localStorage.getItem(DocumentKey + '/annotations'));
    if (DocumentAnnotation == null) {
      setTimeout(function () {
        $('#submitAnnotation').attr('disabled', true);
      }, 200);
    }
  }
  // save annotation of signature document
  public SaveAnnotation() {
    $('.loader-outer').css('display', 'inline-flex');
    const AgentId: any = this.LoginUserId;
    const UserID: any = this.userId;
    const DocumentId: any = this.documentId;
    const DocumentKey: any = this.documentKey;
    const IpAddress = this.IpAddress;
    const BrowserInfo = 'Chrome';
    let IsAgent = false;
    let IsAgentDate = false;
    let DocumentAnnotation = JSON.parse(localStorage.getItem(DocumentKey + '/annotations'));
    $.each(DocumentAnnotation, function (i, obj) {
      if (obj.user_role === 2 ) {
        IsAgent = true;
      }
      if (obj.type === 'date_button' ) {
        IsAgentDate = true;
      }
    });
    DocumentAnnotation = JSON.stringify(DocumentAnnotation);
    if (DocumentAnnotation == null) {
      this.toasting('Please select any annotation', 2000, 'red');
    } else {
      $('#submitAnnotation').attr('disabled', true);
      if (IsAgentDate) {
        // Create a canvas element
         const canvas = document.createElement('canvas');
         canvas.width = 500;
         canvas.height = 400;
         // Get the drawing context
         const tCtx = canvas.getContext('2d');
         tCtx.font = '60px Arial';
         const currentdate = new Date();
         const datetime = (currentdate.getMonth() + 1) + '-' +
         currentdate.getDate() + '-' +
         currentdate.getFullYear() + '  ' +
         currentdate.getHours() + ':' +
         currentdate.getMinutes();
         tCtx.fillText(datetime, 0, 50);
         const dataURL = tCtx.canvas.toDataURL('image/png', 1.0);
         const time = new Date().valueOf();
         const Key = time + '_date_' + this.LoginUserId + '.png';

          const png = dataURL.split(',')[1];

          const filename = 'Signature.png';
          const arr = dataURL.split(','),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
          }
          const the_file = new File([u8arr], filename, {
              type: mime
          });
         this.userService.UploadDateImageAgent(dataURL, the_file, Key)
         .subscribe(
          resultImage => {
            if (resultImage.status === 200) {
              const DocumentAnnotationParse = JSON.parse(DocumentAnnotation);
              const FinalAnnotations = [];
              $.each(DocumentAnnotationParse, function (i, obj) {
                if (obj.user_role === 2 && obj.type === 'date_button') {
                  obj.ImageUrl = Key;
                }
                FinalAnnotations.push(obj);
              });
              this.userService.SaveAnnotaion(JSON.stringify(FinalAnnotations), parseInt(DocumentId, null), parseInt(AgentId, null),
                parseInt(UserID, null), IpAddress, BrowserInfo, IsAgent, this.LocationId)
                .subscribe(
                  result => {
                    if (result.Status) {
                        $('.loader-outer').css('display', 'none');
                        $('#pdfAnnotateCheck').val(1);
                        this.toasting('Document sent sucessfully', 2000, 'green');
                        this._router.navigate(['/user/detail', UserID]);
                      } else {
                        this.toasting(result.Message, 2000, 'red');
                      }
                      $('#submitAnnotation').attr('disabled', false);
                    },
                    error => {
                      if (error.Message === 'Token expired') {
                        this._router.navigate(['/login']);
                      }
                      $('#submitAnnotation').attr('disabled', false);
                    }
                  );
            } else {
              this.toasting('Oops! Some Error Occured.', 2000, 'red');
            }
          },
          error => {
            this.toasting('Oops! Some Error Occured.', 2000, 'red');
          }
        );
       } else {
        this.userService.SaveAnnotaion(DocumentAnnotation, parseInt(DocumentId, null), parseInt(AgentId, null),
        parseInt(UserID, null), IpAddress, BrowserInfo, IsAgent,  this.LocationId)
        .subscribe(
          result => {
            if (result.Status) {
              $('.loader-outer').css('display', 'none');
              $('#pdfAnnotateCheck').val(1);
              this.toasting('Document sent sucessfully', 2000, 'green');
              this._router.navigate(['/user/detail', UserID]);
            } else {
              this.toasting(result.Message, 2000, 'red');
            }
            $('#submitAnnotation').attr('disabled', false);
          },
          error => {
            if (error.Message === 'Token expired') {
              this._router.navigate(['/login']);
            }
            $('#submitAnnotation').attr('disabled', false);
          }
        );
       }
    }

  }
  // get ip address of user
  public getIP() {
    this.userService.getIP().subscribe(
      IPDetails => this.IpAddress = IPDetails.ip,
      error => this.errorMessage = <any>error
    );
  }

  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // user type
  public UserType(obj) {
    const UserModel = this.userService.decodeToken();
    if (obj === 'agent' && UserModel && UserModel.user && (!UserModel.user.SignatureImage || !UserModel.user.IntialImage)) {
      $('.loader-outer').css('display', 'inline-flex');
      this.userService.UserSignatureIntialStatus(UserModel.user.UserId, UserModel.user.LocationId).subscribe(
        result => {
          if (result.Status) {
            localStorage.setItem('SignatureImage', '0');
            localStorage.setItem('InitialImage', '0');
            localStorage.setItem('SignatureImageName', null);
            localStorage.setItem('InitialImageName', null);
            const UserData = result.Data;
            localStorage.setItem('LastActiveAnnotation', $('.rectangle-stamp.active').attr('data-tooltype'));
            if (UserData.SignatureImage) {
              localStorage.setItem('SignatureImage', '1');
              localStorage.setItem('SignatureImageName', UserData.SignatureImage);
              this.SignatureImage = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + UserData.SignatureImage;
            }
            if (UserData.InitialImage) {
              localStorage.setItem('InitialImage', '1');
              localStorage.setItem('InitialImageName', UserData.InitialImage);
              this.InitailImage = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + UserData.InitialImage;
            }
            if (UserData.SignatureImage === null) {
              $('#deactive-annotation-draw').trigger('click');
              this.openAgentSignature('signature');
            } else if (UserData.InitialImage === null) {
              $('#deactive-annotation-draw').trigger('click');
              this.openAgentSignature('initial');
            }
            $('.loader-outer').css('display', 'none');
          } else {
            this.toasting(result.Message, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }
  // open signature modal
  public openAgentSignature(type) {
      this.agentsignature.openAgentSignModal(this.SignatureCounter, type);
      this.SignatureCounter++;
  }
  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

}







