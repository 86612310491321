import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AnonymousGuard implements CanActivate, CanActivateChild {
    constructor(private _userService: UserService, private _router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const userModel = this._userService.decodeToken();
        if (userModel && userModel.user && userModel.user.RoleId) {
            if (userModel.user.RoleId !== 7) {
                this._userService.RedirectAccToRole(userModel.user.RoleId);
                return false;
            }
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
