import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-agency-signed-doc-data',
  templateUrl: './agencySignedDocData.component.html'
})

export class AgencySignedDocDataComponent implements OnInit, OnChanges {

  @Input() AgencySignedDocData: any = [];
  @Input() GetFilterType: string;

  public lineChartLegend: boolean;
  public lineChartType = 'line';
  // lineChart
  public lineChartData: Array<any> = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: '' }
  ];

  public lineChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions: any = {
    responsive: false
  };

  public lineChartColors: Array<any> = [
    {
      backgroundColor: '#f0f6f9',
      borderColor: '#53a2dc',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#53a2dc',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public randomize(): void {
    const _lineChartData: Array<any> = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i] = { data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label };
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        _lineChartData[i].data[j] = Math.floor((Math.random() * 100) + 1);
      }
    }
    this.lineChartData = _lineChartData;
  }

  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {

  }

  ngOnChanges(): void {
    const DocCountArr = [];
    const SignDocDateCountsArr = [];
    const that = this;
    this.AgencySignedDocData.forEach(function (item) {
      const DocDateCounts = item.Date.split('T')[0];
      const dateString = DocDateCounts;

      if (that.GetFilterType === 'week') {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const d = new Date(dateString);
        const dayName = days[d.getDay()];
        SignDocDateCountsArr.push(dayName);
      } else {
        SignDocDateCountsArr.push(dateString);
      }
      DocCountArr.push(item.DocCount);
    });

    setTimeout(() => {
      this.lineChartData = [
        { data: DocCountArr, label: 'Total Signed Document(s)' }
      ];
    }, 50);

    setTimeout(() => {
      this.lineChartLabels = SignDocDateCountsArr;
    }, 50);
  }

  ngOnInit(): void {
  }

  callFromMainComponent() {
  }
}
