import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { User } from '../../model/user';
import { Router } from '@angular/router';
import { toast } from 'angular2-materialize';
import { TokboxService } from '../../model/tokbox.service';
import { EventBus } from '../../shared/EventBus/EventBus';

@Component({
  templateUrl: './userproduct.component.html'
})

export class UserProductComponent implements OnInit {
  Products: any[];
  LoginUserId: number;
  AgencyId: number;
  LocationId: number;
  ShowProductsLoader: boolean;
  users: User[] = [];
  ShowAvailAgentsLoader: boolean;
  ngOnInit() {
    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._eventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });

    if (this.userService.decodeToken().user.RoleId !== 2) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);

    }
  }

  constructor(private userService: UserService, private _router: Router,
    private _eventBus: EventBus) {
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoginUserId = UserModel.user.UserId;
      this.LocationId = UserModel.user.LocationId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.loadAllUsers(UserModel.user.RoleId, 0);
    }
    this.GetProducts();
  }
  // get products
  public GetProducts() {
    this.ShowProductsLoader = true;
    this.userService.getproducts(this.AgencyId, false)
      .subscribe(
        result => {
          if (result.Status) {
            this.Products = result.Data;
          }
          this.ShowProductsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowProductsLoader = false;
        }
      );
  }
  // get product detail
  public GetProductDetail(product) {
    this._router.navigate(['userproductdetail/detail', product]);
  }


  getSubDescription(d) {
    return d.substring(0, 50);
  }

  public loadAllUsers(RoleId, OffSet) {
    this.ShowAvailAgentsLoader = true;
    this.userService.getAll(RoleId, OffSet, this.AgencyId, this.LocationId, false, '', '', '')
      .subscribe(
        result => {
          if (result.Status) {
            this.users = result.Data;
          } else {
            this.toasting(result.Message, 2000, 'red');
            // this._errorMessage = 'Username or password is incorrect.';
            // this._errorMessage = result.Message;
          }
          this.ShowAvailAgentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAvailAgentsLoader = false;
        }
      );
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
}
