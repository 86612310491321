import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../model/user.service';
import { User } from '../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterializeAction, toast } from 'angular2-materialize';
import { environment } from '../../environments/environment';
import { EventBus } from '../shared/EventBus/EventBus';
import { CustomValidators } from 'ng2-validation';

const CountryCodes = require('../helper/CountryCode.json');
declare var $: any;
@Component({
  templateUrl: './UserProfile.component.html'
})

export class UserProfileComponent implements OnInit {
  ShowPaymentsLoader = false;
  ShowAccountLoader = false;
  IsDeleteCard = false;
  ShowConfirmDeleteLoader = false;
  SelectedCustomerId = '';
  SelectedCustomerAccountId = '';
  SelectedCustomerCardId = '';
  ImageLoader = false;
  SelectedCountryFlag: string;
  _changePassword: FormGroup;
  AgencyId: Number;
  _editProfile: FormGroup;
  private _formErrors: any;
  userModel: User = new User();
  paymentmethodModel: any[];
  bankAccountModel: any[];
  AmoutType: string;
  ReferalAmount: number;
  ShowButtonLoader: any;

  AgencyReferalSetingExist: boolean;
  modalActions = new EventEmitter<string | MaterializeAction>();
  editprofilemodalActions = new EventEmitter<string | MaterializeAction>();
  deleteconfirmaction = new EventEmitter<string | MaterializeAction>();

  public _countryCode: any[] = CountryCodes;
  paymentMethodMessage: string;
  model1Params: any;
  paymentMethodSetup = false;
  UserId: any;
  LocationUser: string;
  ChangePasswordBtn = true;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _eventBus: EventBus
  ) {
    this.SelectedCountryFlag = 'AF.png';

    const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
    const passwordConfirm = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));

    this._changePassword = _formBuilder.group({
      oldpassword: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      newpassword: password,
      confirmpassword: passwordConfirm
    });
    this._changePassword.valueChanges.subscribe(data => this.onValueChanged(data));
    // edit profile form
    this._editProfile = _formBuilder.group({
      userid: ['', Validators.required],
      FirstName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      LastName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      email: ['', Validators.compose([Validators.required, CustomValidators.email])],
      SelectedCountryCode: [''],
      phone: ['', Validators.required],
      picture: [''],
      Description: ['', Validators.compose([Validators.maxLength(500)])],
    });
    this._editProfile.valueChanges.subscribe(data => this.onValueChanged(data));
    const UserModel = this.userService.decodeToken();
    this.userModel.UserId = UserModel.user.UserId;
    this.UserId = UserModel.user.UserId;
    this.AgencyId = UserModel.user.AgencyId;
    this.userModel.FirstName = UserModel.user.FirstName;
    this.userModel.LastName = UserModel.user.LastName;
    this.userModel.Name = `${this.userModel.FirstName} ${UserModel.user.LastName}`;
    this.userModel.Picture = UserModel.user.Picture;
    this.userModel.ReferalCode = UserModel.user.ReferalCode;
    this.userModel.StripeCustomerId = this.userService.getCustId();
    this.userModel.Description = (UserModel.user.Description) ? UserModel.user.Description : '';
    this.LoadUserProfile();
    this.LoadPaymentMethod();
    this.LoadBankAccount();
    this._eventBus.on().subscribe(m => {
      if (m.filterBy.Type === 'Load-Pyament-Method-Event') {
        this.LoadPaymentMethod();
      } else if (m.filterBy.Type === 'Load-Bank-Account-Event') {
        this.LoadBankAccount();
      }
    });
  }

  ngOnInit() {

    this.userService.GetInitialSettings(this.UserId).subscribe((response) => {
      if (response && response.Data) {
        this._eventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });

    this.GetAgencyReferalDetail();
    this.userService.CheckStripeAccountStatus(this.AgencyId, this.UserId)
      .subscribe(
        result => {
          // if company setup payment method
          if (result.Status === true) {
            this.paymentMethodSetup = true;
          } else {
            this.paymentMethodSetup = false;
            this.paymentMethodMessage = result.Message;
            $('#NoPaymentMothodSetup').modal('open');
            this.toasting(result.Message, 3000, 'red');
          }

        },
        error => {}
      );
  }
  // get agency referal setting
  public GetAgencyReferalDetail() {
    this.userService.GetAgencyReferalSettingDetail(this.AgencyId)
      .subscribe(
        result => {
          if (result.Status) {
            this.AgencyReferalSetingExist = true;
            this.AmoutType = result.Data.AmoutType;
            this.ReferalAmount = result.Data.Value;
          } else {
            this.AgencyReferalSetingExist = false;
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // check change password form validation
  _changePasswordcheckisValid(field): boolean {
    let isValid = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._changePassword.controls[field].touched === false) {
      isValid = true;
    } else if (this._changePassword.controls[field].touched === true && this._changePassword.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // check edit profile validation
  _editProfilecheckisValid(field): boolean {
    let isValid = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._editProfile.controls[field].touched === false) {
      isValid = true;
    } else if (this._editProfile.controls[field].touched === true && this._editProfile.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // on value change reset
  public onValueChanged(data?: any) {
    if (!this._changePassword) { return; }
    const form = this._changePassword;
    for (const field in this._formErrors) {
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // remove card
  public RemoveCard() {
    const that = this;
    that.ShowConfirmDeleteLoader = true;
    this.paymentmethodModel.forEach(function (item) {
      if (that.SelectedCustomerCardId === item.StripeCardId) {
        item['ShowDeleteLoader'] = true;
      }
    });
    this.userService.RemovePayMethod(that.SelectedCustomerId, that.SelectedCustomerCardId, this.AgencyId)
      .subscribe(
        result => {
          that.ShowConfirmDeleteLoader = false;
          that.closedeleteConfirmModal();
          if (result.Status) {
            that.toasting('Card removed successfully!', 2000, 'green');
            that.LoadPaymentMethod();

          } else {
            that.toasting(result.Message, 5000, 'red');
          }
        },
        error => {
          that.ShowConfirmDeleteLoader = false;
          that.closedeleteConfirmModal();
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // remove bank account
  public RemoveBankAccount() {
    const that = this;
    this.ShowConfirmDeleteLoader = true;
    this.bankAccountModel.forEach(function (item) {
      if (that.SelectedCustomerCardId === item.StripeCardId) {
        item['ShowDeleteLoader'] = true;
      }
    });
    this.userService.RemoveBankAccount(this.SelectedCustomerId, this.SelectedCustomerAccountId, this.AgencyId)
      .subscribe(
        result => {
          if (result.Status) {
            that.toasting('Bank Account removed successfully!', 2000, 'green');
            that.LoadBankAccount();
            that.ShowConfirmDeleteLoader = false;
            that.closedeleteConfirmModal();
          } else {
            that.toasting(result.Message, 5000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }

  public SetAccountValuesToDelete(StripeCustomerId, StripeBankAccountId) {
    this.SelectedCustomerId = StripeCustomerId;
    this.SelectedCustomerAccountId = StripeBankAccountId;
  }

  public RemoveAccountValuesToDelete() {
    this.SelectedCustomerId = '';
    this.SelectedCustomerAccountId = '';
  }

  public SetCardValuesToDelete(StripeCustomerId, StripeCardId) {
    this.SelectedCustomerId = StripeCustomerId;
    this.SelectedCustomerCardId = StripeCardId;
  }

  public RemoveCardValuesToDelete() {
    this.SelectedCustomerId = '';
    this.SelectedCustomerCardId = '';
  }

  public LoadUserProfile() {
    this.userService.getuserprofile(this.userModel.UserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.userModel = result.Data;
            if (result.Data.LocationUser) {
              this.LocationUser = result.Data.LocationUser;
            }
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // load payment method client
  public LoadPaymentMethod() {
    this.ShowPaymentsLoader = true;
    this.userService.getAllPaymentMethod(this.userModel.UserId)
      .subscribe(
        result => {
          if (result.Status && result.Data) {
            this.paymentmethodModel = result.Data.CardList;
            this.paymentmethodModel.forEach(function (item) {
              item['ShowDeleteLoader'] = false;
            });
          } else {
            this.paymentmethodModel = result.Data;
          }
          this.ShowPaymentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowPaymentsLoader = false;
        }
      );
  }

  public editBankAccount(accountid) {
  }

  public LoadBankAccount() {
    this.ShowAccountLoader = true;
    this.userService.getAllBankAccount(this.userModel.UserId, false)
      .subscribe(
        result => {
          if (result.Status) {
            this.bankAccountModel = result.Data;
            this.bankAccountModel.forEach(function (item) {
              item['ShowDeleteLoader'] = false;
            });
          } else {
            this.bankAccountModel = result.Data;
          }
          this.ShowAccountLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAccountLoader = false;
        }
      );
  }

  public onChangeCode(event) {
    this._countryCode.forEach(function (item) {
      if (item.dial_code === event.value) {
        event = item.code;
      }
    });
    this.SelectedCountryFlag = event + '.png';
  }

  public OpenEditProfile() {
    const that = this;
    this._editProfile.reset();
    this._editProfile.setValue({
      userid: this.userModel.UserId,
      FirstName: this.userModel.FirstName ? this.userModel.FirstName : '',
      LastName: this.userModel.LastName ? this.userModel.LastName : '',
      email: this.userModel.Email ? this.userModel.Email : '',
      SelectedCountryCode: this.userModel.CountryCode,
      phone: this.userModel.Phone ? this.userModel.Phone : '',
      picture: this.userModel.Picture ? this.userModel.Picture : '',
      Description: this.userModel.Description ? this.userModel.Description : '',
    });
    this._countryCode.forEach(function (item) {
      if (item.dial_code === that.userModel.CountryCode) {
        that.SelectedCountryFlag = item.code + '.png';
      }
    });
    this.editprofilemodalActions.emit({ action: 'modal', params: ['open'] });
  }


  public ChangePassword(elementValues: any) {
    this.ChangePasswordBtn = false;
    this.userService.ChangePassword(this.userModel.UserId, elementValues.oldpassword, elementValues.newpassword)
      .subscribe(
        result => {
          if (result.Status) {
            this.toasting(result.Message, 2000, 'green');
            this.closeChangePasswordModal();
          } else {
            this.toasting(result.Message, 2000, 'red');
          }
          this.ChangePasswordBtn = true;
        },
        error => {
          this.ChangePasswordBtn = true;
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }

  public fileEvent(event) {
    this.ImageLoader = true;
    const files = event.target.files;
    if (files.length > 0) {
      const that = this;
      this.userService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
            const AWSService = window.AWS;
            // AWSService.config.accessKeyId = environment.amazon_access_key;
            // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
            AWSService.config.update({
              region: 'us-west-2',
              credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
            });
            const unix = Math.round(+new Date() / 1000);
            let fileName = files[0].name;
            fileName = fileName ? fileName.replace(/\s/g, '') : fileName;
            files[0].Key = unix.toString() + '_' + fileName;
            files[0].Progress = 0;
            const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
            const params = { Key: files[0].Key, Body: files[0], ACL: 'public-read' };
            bucket.upload(params, (err, data) => {
              console.log(err, data);
              if (data !== undefined) {
                that.userModel.Picture = data.Location;
                that.ImageLoader = false;
              } else if (err !== undefined) {
                that.ImageLoader = false;
                that.toasting('Error in picture uploading', 2000, 'red');
              }
            }).on('httpUploadProgress', function (progress) {
            });
        }
      }, (error) => {
        this.toasting('Unable to upload file. Try again after sometime', 2000, 'red');
      });
    } else { this.ImageLoader = false; }
  }

  public EditProfile(elementValues: any) {
    this._countryCode.forEach(function (item) {
      if (item.code === elementValues.SelectedCountryCode) {
        elementValues.SelectedCountryCode = item.dial_code;
      }
    });
    elementValues.Picture = this.userModel.Picture;
    this.userService.UpdateProfile(this.userModel.UserId, elementValues.FirstName, elementValues.LastName, elementValues.email,
      elementValues.SelectedCountryCode, elementValues.phone, elementValues.Picture, elementValues.Description)
      .subscribe(
        result => {
          if (result.Status) {
            this._eventBus.emit({ Type: 'Get-User-Detail-Event' });
            this.toasting('Profile updated successfully!', 2000, 'green');
            this.closeEditProfileModal();
          } else {
            this.toasting(result.Message, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }

  public openChangePasswordModal() {
    this._changePassword.reset();
    this.modalActions.emit({ action: 'modal', params: ['open'] });
  }

  public closeChangePasswordModal() {
    this.modalActions.emit({ action: 'modal', params: ['close'] });
  }

  public openEditProfileModal() {
    this.editprofilemodalActions.emit({ action: 'modal', params: ['open'] });
  }

  closeEditProfileModal() {
    this.LoadUserProfile();
    this.editprofilemodalActions.emit({ action: 'modal', params: ['close'] });
  }

  public opendeleteAccountConfirmModal(StripeCustomerId, StripeBankAccountId) {
    this.SetAccountValuesToDelete(StripeCustomerId, StripeBankAccountId);
    this.deleteconfirmaction.emit({ action: 'modal', params: ['open'] });
  }

  public opendeleteCardConfirmModal(StripeCustomerId, StripeBankAccountId) {
    this.IsDeleteCard = true;
    this.SetCardValuesToDelete(StripeCustomerId, StripeBankAccountId);
    this.deleteconfirmaction.emit({ action: 'modal', params: ['open'] });
  }

  closedeleteConfirmModal() {
    this.IsDeleteCard = false;
    this.RemoveAccountValuesToDelete();
    this.RemoveCardValuesToDelete();
    this.deleteconfirmaction.emit({ action: 'modal', params: ['close'] });
  }
}
