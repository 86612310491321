import { ElementRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../model/user.service';
import { EventBus } from '../../shared/EventBus/EventBus';
declare var $: any;
declare function pdfAnnotateIni(): void;

@Component({
selector: 'app-agent-pdf',
templateUrl: './agentsign.component.html'
})

export class AgentSignComponent implements OnInit, AfterViewInit {
   documentKey: string;
   documentKeyArg: string;
   constructor(private route: ActivatedRoute, private userService: UserService,
               private elementRef: ElementRef, private _eventBus: EventBus) {
        this._eventBus.emit({ Type: `show-authorized-header`, data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
        this.route.params.forEach((params: Params) => {
            this.documentKey = params['id'];
            setTimeout(() => {
                const pdfFile: any = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + this.documentKey + '.pdf';
                this.documentKeyArg = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + this.documentKey + '.pdf';
                $('#pdfUrl').val(pdfFile);
            }, 2500);
        });
    }
    ngOnInit() {
       setTimeout(() => {
            localStorage.setItem('signatureEditMode', 'true');
            pdfAnnotateIni();
            localStorage.setItem(this.documentKeyArg + '/tooltype', 'cursor');
        }, 7000);
        if ($('#ongoingCall').hasClass('active') === true) {
            $('.pdfViewer').addClass('changePdfPos');
        } else {
            $('.pdfViewer').removeClass('changePdfPos');
        }
    }
    ngAfterViewInit() {
        const a = document.createElement('script');
        a.type = 'text/javascript';
        a.src = '/assets/pdf/shared/pdf.js';
        this.elementRef.nativeElement.appendChild(a);

        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.src = '/assets/pdf/shared/pdf_viewer.js';
        this.elementRef.nativeElement.appendChild(b);

        const c = document.createElement('script');
        c.type = 'text/javascript';
        c.src = '/assets/pdf/index.js';
        this.elementRef.nativeElement.appendChild(c);
    }
}
