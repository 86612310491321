import { Component } from '@angular/core';
import { toast } from 'angular2-materialize';
import { UserService } from '../../model/user.service';
import { LinkStatus } from '../../model/agencyMatrix';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agency-matrix-setting',
  templateUrl: './agency-matrix-setting.component.html',
  styleUrls: ['./agency-matrix-setting.component.css']
})
export class AgencyMatrixSettingComponent {
  accountlinked: boolean;
  UserModel: any;
  AgencyId: number;
  UserEmail: string;
  ShowLoader = false;
  PageLoader: boolean;
  constructor(private _userService: UserService, private _router: Router) {
    this.UserModel = this._userService.decodeToken();
    // set link account detail
    if (this.UserModel && this.UserModel.user) {
      this.AgencyId = this.UserModel.user.AgencyId;
      this.UserEmail = this.UserModel.user.Email;
    }
    const requestData = new LinkStatus();
    requestData.AgencyId = this.AgencyId;
    this.ShowLoader = true;
    this._userService.LinkAgencyMatrixStatus(requestData).subscribe(
      (response) => {
        if (response.Status && response.Data.ResStatus) {
          this.accountlinked = true;
        } else {
          this.accountlinked = false;
        }
        this.ShowLoader = false;
      }, (error) => {
        this.accountlinked = false;
        this.ShowLoader = false;
        this.toasting('Network Error.', 2000, 'red');
      });
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
}
