import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-agency-call-data',
  templateUrl: './agencyCallsData.component.html'
})

export class AgencyCallsDataComponent implements OnInit, OnChanges {

  @Input() AgencyCallsData: any = [];
  @Input() GetFilterType = 'week';

  public lineChartLegend: boolean;
  public lineChartType = 'line';
  public CallsDateCountsArr: Array<any> = [];

  // lineChart
  public lineChartData: Array<any> = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: '' }
  ];


  public lineChartLabels: Array<any> = [];

  public lineChartOptions: any = {
    responsive: false
  };
  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: '#f0f6f9',
      borderColor: '#53a2dc',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#53a2dc',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public randomize(): void {

    const _lineChartData: Array<any> = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i] = { data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label };
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        _lineChartData[i].data[j] = Math.floor((Math.random() * 100) + 1);
      }
    }
    this.lineChartData = _lineChartData;
  }

  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }


  ngOnChanges(): void {
    const CallsCountArr = [];
    const that = this;
    that.CallsDateCountsArr = [];
    if (this.AgencyCallsData.length > 0) {
      this.AgencyCallsData.forEach(function (item) {
        const CallsDateCounts = item.Date.split('T')[0];
        const dateString = CallsDateCounts;
        if (that.GetFilterType === 'week') {
          const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          const d = new Date(dateString);
          const dayName = days[d.getDay()];
          that.CallsDateCountsArr.push(dayName);
        } else {
          that.CallsDateCountsArr.push(dateString);
        }
        CallsCountArr.push(item.CallCount);
      });

      setTimeout(() => {
        this.lineChartData = [
          { data: CallsCountArr, label: 'Total Calls' }
        ];
      }, 70);

      setTimeout(() => {
        this.lineChartLabels = that.CallsDateCountsArr;
      }, 70);
    }
  }
  ngOnInit(): void {
  }


  callFromMainComponent() {
  }
}
