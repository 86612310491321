import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import * as FileSaver from 'file-saver';

const EXCEL_EXTENSION = 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
@Injectable()
export class GlobalService {
    public apiHost: string;
    // public AgencyId: number;
    // public LocationId: number;
    // public redirectCount = 0;
    // public AgencyName: string;
    // public AgencyLogo: string;
    // public isAgencyExist: boolean;
    // public SiteAddress: string;
    // public IpAddress: any;
    // public AWS_Key: string;
    // public AWS_Secret: string;
    // public AWS_Bucket_Name: string;

    // public globleDataSource = new BehaviorSubject({});
    // currentMessage = this.globleDataSource.asObservable();
    public globleDataSource: object = {
        AgencyId: 0,
        LocationId: 0,
        redirectCount: 0,
        AgencyName: '',
        AgencyLogo: '',
        isAgencyExist: false,
        SiteAddress: '',
        IpAddress: '',
        amazon_access_key: '',
        amazon_secret_access_key: '',
        amazon_bucket_name: '',
        amazon_thumbnail_bucket_name: 'lowrate-insurance-thumbnails'
    };

    public setting: any = {};
    loadGlobalSettingsFromSessionStorage(): void {
        if (sessionStorage.getItem('frontend-setting') != null) {
            this.setting = JSON.parse(sessionStorage.getItem('frontend-setting'));
        }
    }

    constructor() { }
    changeGlobleDataSourceState(nextState: object) {
        this.globleDataSource = Object.assign({}, nextState);
    }

    public DecryptSetASEAWSKeys(decryptedMessage) {
        const _key = CryptoJS.enc.Utf8.parse(environment.AWSASE_KEY);
        const _iv = CryptoJS.enc.Utf8.parse(environment.AWSASE_KEY_IV);
        let data: any = CryptoJS.AES.decrypt(decryptedMessage, _key, {
            keySize: 16,
            iv: _iv,
        }).toString(CryptoJS.enc.Utf8);
        data = JSON.parse(data);
        this.globleDataSource['amazon_access_key'] = data.AWS_Key;
        this.globleDataSource['amazon_secret_access_key'] = data.AWS_Secret;
        this.globleDataSource['amazon_bucket_name'] = data.AWS_Bucket_Name;
        return data;
    }

    public DecryptZohoKeys(decryptedMessage) {
        const _key = CryptoJS.enc.Utf8.parse(environment.AWSASE_KEY);
        const _iv = CryptoJS.enc.Utf8.parse(environment.AWSASE_KEY_IV);
        let data: any = CryptoJS.AES.decrypt(decryptedMessage, _key, {
            keySize: 16,
            iv: _iv,
        }).toString(CryptoJS.enc.Utf8);
        data = JSON.parse(data);
        return data;
    }

    /**

 * Saves a file by opening file-save-as dialog in the browser

 * using file-save library.

 * @paramblobContent file content as a Blob

 * @paramfileName name file should be saved as

 */

public saveReportFile(blobContent:any, fileName:string) {

	const data: Blob= new Blob([new Uint8Array(blobContent)], {

		type:EXCEL_TYPE

	});

	FileSaver.saveAs(data, `${fileName}_export_${new Date().getTime()}`);

 }
}
