import { Component, OnInit, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { FormGroup, FormControl, Validators, Form, FormBuilder } from '@angular/forms';
import { UserService } from '../../model/user.service';
import { toast } from 'angular2-materialize';
import { AgencyMatrixLinkData } from '../../model/agencyMatrix';
import { Router } from '@angular/router';
import { EventBus } from '../../shared/EventBus/EventBus';
@Component({
  selector: 'app-account-exist',
  templateUrl: './account-exist.component.html',
  styleUrls: ['./account-exist.component.css']
})
export class AccountExistComponent {
  ShowLoader = false;
  AgencyId: number;
  UserId: number;
  AgencyName: string;
  modelAgencyMatix2Params = [
    {
      dismissible: false
    }
  ];
  modalActions4 = new EventEmitter<string | MaterializeAction>();
  accountExistForm: FormGroup;
  error = '';
  UserModel: any;
  ExistEmail: string;
  AgencyGuid: string;
  hasError: boolean;
  errorMsg: string;
  @Output() openParentModel = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private _userService: UserService,
              private _router: Router, private _eventBus: EventBus) {
    // get agency/user detail from token
    this.UserModel = this._userService.decodeToken();
    if (this.UserModel && this.UserModel.user) {
      this.AgencyId = this.UserModel.user.AgencyId;
      this.UserId = this.UserModel.user.UserId;
      this.AgencyGuid = this.UserModel.user.AgencyGuid;
      this.AgencyName = this.UserModel.user.AgencyName;
      this.accountExistForm = this.formBuilder.group({
        'owner_email': [this.UserModel.user.Email, [Validators.required, Validators.email]]
      });
    } else {
      localStorage.removeItem('frontend-token');
      localStorage.removeItem('StripeCust');
      this._router.navigate(['/login']);
    }
  }

  openAgencyMatrixExist(ShowAdd) {
    this.accountExistForm.reset();
    // already exists agency matrix form
    this.accountExistForm = this.formBuilder.group({
      'owner_email': [this.UserModel.user.Email, [Validators.required, Validators.email]]
    });
    this.modalActions4.emit({ action: 'modal', params: ['open'] });
  }
  // open agency matris register popup
  openParentModelClick() {
    this.modalActions4.emit({ action: 'modal', params: ['close'] });
    this.openParentModel.emit({ 'close': true, 'openNewAccount': false, 'openAleadyExist': false });
  }
  // open agency matrix new account popup
  agencyMatrixNewAccount() {
    this.modalActions4.emit({ action: 'modal', params: ['close'] });
    this.openParentModel.emit({ 'close': false, 'openNewAccount': true, 'openAleadyExist': false });
  }
  // close exists agency matrix popup
  closeExistModel() {
    this.modalActions4.emit({ action: 'modal', params: ['close'] });
  }
  // show success/error toast message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // link existing account agency matrix by email
  integrationDone(formValues) {
    this.ShowLoader = true;
    const requestData = new AgencyMatrixLinkData();
    requestData.AgencyId = this.AgencyId;
    requestData.UserId = this.UserId;
    requestData.Email = formValues.owner_email;
    requestData.AgencyGuid = this.AgencyGuid;
    requestData.AgencyName = this.AgencyName;
    this._userService.linkAgencyMatrixAccount(requestData).subscribe(
      (responseMatrix) => {
        if (responseMatrix.Status) {
          this.modalActions4.emit({ action: 'modal', params: ['close'] });
          this.toasting('Your account is linked successfully', 2000, 'green');
          if (this.UserModel.user.RoleId === 6) {
            if (!this.UserModel.user.IsPageSet) {
              localStorage.setItem('custome-setting', JSON.stringify(true));
              this._router.navigate(['agency/page-setting']);
            } else {
              this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
              this._router.navigate(['agency/dashboard']);
            }
          } else {
            this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
            this._router.navigate(['agent/dasboard']);
          }
        } else {
          this.hasError = true;
          this.errorMsg = responseMatrix.Message;
          if (responseMatrix.Message === 'Error occured during execution') {
            this.modalActions4.emit({ action: 'modal', params: ['close'] });
            if (this.UserModel.user.RoleId === 6) {
              if (!this.UserModel.user.IsPageSet) {
                localStorage.setItem('custome-setting', JSON.stringify(true));
                this._router.navigate(['agency/page-setting']);
              } else {
                this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
                this._router.navigate(['agency/dashboard']);
              }
            } else {
              this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
              this._router.navigate(['agent/dasboard']);
            }
          }
        }
        this.ShowLoader = false;
      }, (errorMatrix) => {
        if (errorMatrix.Data) {
          const arrayError = [];
          errorMatrix.Data.forEach((element) => {
              if (element && element.length) {
                for (const propName in element) {
                  if (errorMatrix.Data.hasOwnProperty(propName)) {
                    arrayError.push(element[propName]);
                  }
                }
              }
          });
          if (arrayError) {
            this.hasError = true;
            this.errorMsg = arrayError.join(', ').replace(/"|_/g, '');
          } else {
            this.hasError = true;
            this.errorMsg = errorMatrix.Message;
          }

        } else {
          this.toasting(errorMatrix.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      }
    );
  }
  // value change remove error message and class
  valuechange(event) {
    this.hasError = false;
    this.errorMsg = '';
  }
}
