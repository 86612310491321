import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../../model/user.service';
import { toast } from 'angular2-materialize';
import { AgencyMatrixSaveData } from '../../../model/agencyMatrix';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-not-exist',
  templateUrl: './account-not-exist.component.html',
  styleUrls: ['./account-not-exist.component.css']
})
export class AccountNotExistSettingComponent implements OnInit {
  ShowLoader = false;
  AgencyId: number;
  UserId: number;
  UserModel: any;
  AgencyMatrixToken: string;
  registerNewAccountMatrix: FormGroup;
  AgencyGuid: string;
  // output event to parent component
  @Output() openParentModel = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private _userService: UserService, private router: Router) {
    this.UserModel = this._userService.decodeToken();
    // set agency admin detail from token
    if (this.UserModel && this.UserModel.user) {
      this.AgencyId = this.UserModel.user.AgencyId;
      this.UserId = this.UserModel.user.UserId;
      this.AgencyGuid = this.UserModel.user.AgencyGuid;
    } else {
      localStorage.removeItem('frontend-token');
      localStorage.removeItem('StripeCust');
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    // register new agency matrix account form
    this.registerNewAccountMatrix = this.formBuilder.group({
        'AgencyName': [this.UserModel.user.AgencyName, [Validators.required]],
        'Name': [this.UserModel.user.FirstName + ' ' + this.UserModel.user.LastName, [Validators.required]],
        'Email': [this.UserModel.user.Email, [Validators.required, Validators.email]],
        'Phone': [this.UserModel.user.Phone, [Validators.required]],
        'Password': ['', [Validators.required]],
        'ConfirmationPassword': ['', [Validators.required]]
      }, {
        validator: this.passwordConfirming
     });
  }

  // show sucess/error message toasts
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // register Agency matrix account
  integrationDone(formValues) {
    const RequestData = new AgencyMatrixSaveData();
    RequestData.AgencyName = formValues.AgencyName;
    RequestData.Name = formValues.Name;
    RequestData.Email = formValues.Email;
    RequestData.Phone = formValues.Phone;
    RequestData.Password = formValues.Password;
    RequestData.ConfirmPassword = formValues.ConfirmationPassword;
    RequestData.AgencyId = this.AgencyId;
    RequestData.UserId = this.UserId;
    RequestData.AgencyGuid = this.AgencyGuid;
    this.ShowLoader = true;
    this._userService.addAgencyMatrixAccount(RequestData).subscribe(
      (responseMatrix) => {
        if (responseMatrix.Status) {
          this.toasting('Your account is linked successfully', 2000, 'green');
          this.router.navigate(['/agency/agency-matrix-setting']);
        } else {
          this.toasting(responseMatrix.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      }, (errorMatrix) => {
        if (errorMatrix.Data) {
          const arrayError = [];
          errorMatrix.Data.forEach((element) => {
              if (element && element.length) {
                for (const propName in element) {
                  if (errorMatrix.Data.hasOwnProperty(propName)) {
                    arrayError.push(element[propName]);
                  }
                }
              }
          });
          if (arrayError) {
            this.toasting(arrayError.join(', ').replace(/"|_/g, ''), 2000, 'red');
          } else {
            this.toasting(errorMatrix.Message, 2000, 'red');
          }
        } else {
          this.toasting(errorMatrix.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      }
    );
  }
  // custom validation for confirming password;
  passwordConfirming(c: AbstractControl): { ConfirmPassword: boolean } {
    if (c.get('Password').value !== c.get('ConfirmationPassword').value) {
      c.get('ConfirmationPassword').setErrors( {ConfirmPassword: true} );
    } else {
        return null;
    }
  }
}
