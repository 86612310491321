import { Component, EventEmitter, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction, toast } from 'angular2-materialize';
import { UserService } from '../../model/user.service';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../EventBus/EventBus';
import { Router } from '@angular/router';

declare var myExtObject: any;
declare var $: any;
declare function getStripePublishableKey(token: string): void;
declare function CreateStripeSuperAdminInstance(token: string): void;

@Component({
  moduleId: module.id,
  selector: 'app-resubscription-agency-card',
  templateUrl: 'resubsription-agency-card.component.html'
})
export class ResubscriptionAgencyCardComponent implements OnInit {
  stripetoken: any;
  _chargeCustomer: FormGroup;
  model1Params: any;
  ShowConfirmDeleteLoader: boolean;
  private _formErrors: any;
  private _errorMessage: string;
  SelectedCustomerId: string;
  SelectedCustomerCardId: string;
  UpdatingCard: boolean;

  modalActions = new EventEmitter<string | MaterializeAction>();
  deleteconfirmaction = new EventEmitter<string | MaterializeAction>();
  params = [];
  uploaddocmodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  ShowMe: boolean;
  UserId: number;
  AgencyId: number;
  LocationId: number;
  Name: string;
  StripeCustomerId: string;
  SelectedCardId: string;
  PaymentMethodList: any[];
  LoadMoreCounter: number;
  ShowLoader: boolean;
  ShowButtonLoader: boolean;
  ShowCreateChargeDiv: boolean;
  message: string;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private _router: Router,
    private _ngZone: NgZone,
    private _EventBus: EventBus
  ) {
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.UserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.Name = UserModel.user.Name;
      this.StripeCustomerId = UserModel.user.StripeCustomerId;
    } else {
      localStorage.removeItem('frontend-token');
      localStorage.removeItem('StripeCust');
      this._router.navigate(['/login']);
    }
    this.ShowButtonLoader = false;
    this.SelectedCardId = '';
    this.ShowCreateChargeDiv = false;
    this.LoadMoreCounter = 0;
    this._chargeCustomer = _formBuilder.group({
      amount: ['', Validators.compose([Validators.required])],
      description: ['']
    });
    this._chargeCustomer.valueChanges.subscribe(data => this.onValueChanged(data));
    window['resubscribecardref'] = {
      zone: this._ngZone,
      receivetoken: (value) => this.ReceiveTokenFromStripe(this.stripetoken),
      component: this,
    };
  }

  public Updatecard() {
    // this.UpdatingCard = true;
  }
  // receive token from stripe
  public ReceiveTokenFromStripe(token) {
    this._EventBus.emit(
      {
        Type: 'resubscribeagencyplan',
        data: { 'token': token.id }
      });
    this.closeAddCardModal();
  }
  // on value change set valiadtions
  public onValueChanged(data?: any) {
    if (!this._chargeCustomer) { return; }
    const form = this._chargeCustomer;
    for (const field in this._formErrors) {
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }

  ngOnInit() {
    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#updatecard').modal({ dismissible: false });
    }, 400);
  }

  private _chargeCustomercheckisValid(field): boolean {
    let isValid: boolean;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._chargeCustomer.controls[field].touched === false) {
      isValid = true;
    } else if (this._chargeCustomer.controls[field].touched === true && this._chargeCustomer.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }

  public ManageAddCard(value) {
    myExtObject.CreateCardInstance();
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // open add stripe card modal
  public openAddCardModal(ShowAdd) {
    CreateStripeSuperAdminInstance('pk_test_K39xdSVefTwlDZqsQV5y1PbF');
    const that = this;
    setTimeout(function () {
      that.ManageAddCard(true);
      that.modalActions.emit({ action: 'modal', params: ['open'] });
    }, 1);
    this.ShowLoader = true;
    return false;
  }
  // close add card modal
  public closeAddCardModal() {
    this._EventBus.emit(
      {
        Type: 'resubscribecardclose'
      });
    this.modalActions.emit({ action: 'modal', params: ['close'] });
  }
}
