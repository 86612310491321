import { Component, OnInit, EventEmitter, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../model/user.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';
import { MaterializeAction, toast } from 'angular2-materialize';
import { CustomValidators } from '../../helper/custom-validators';
import { TokboxService } from '../../model/tokbox.service';
declare var $: any;
declare var myExtObject: any;

@Component({
    templateUrl: './agencyadmindashboarduser.component.html'
})

export class AgencyAdminDashboardUserComponent implements OnInit {
    SubdomainName: string;
    filter: any;
    model3Params: any;
    model1Params: any;
    private _formErrors: any;
    users: User[] = [];
    SaveEmailArray: any[] = [];
    reOccurArray: any[];
    UserLoading: boolean;
    deleteUserId: number;
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    LoggedInUserDescription: string;
    TotalUsers: string;
    Count: number;
    selectedOptionReOccure: any;
    LoginUserId: number;
    AgencyId: number;
    LocationId: number;
    LanguageFlag: string;
    LoadMoreLoader: boolean;
    LoadMoreCounter: number;
    NotUserFound: boolean;
    ShowLoader: boolean;
    ShowLoaderForm: boolean;
    IsEmailExist: boolean;
    opensearch = false;
    modalActions1 = new EventEmitter<string | MaterializeAction>();
    previousUrl: string;
    key: string;
    modalActions3 = new EventEmitter<string | MaterializeAction>();
    reverse: boolean;
    refereshUserId: any;
    refereshConnectionId: any;
    searchText = '';
    SortKey = 'FirstName';
    SortOrder = 'ASC';
    ArrayOnlineTokboxuser = [];
    LoadMoreLoaderEnable: boolean;
    LoadMoreUser = true;
    ngOnInit() {
        window['userComponentRef'] = {
            zone: this._ngZone,
            componentFn: (userId, connectionId, isConnected) => this.RefreshList(userId, connectionId, isConnected),
            component: this,
        };
        if (this.userService.decodeToken().user.RoleId !== 6) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);

            this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
                if (response && response.Data) {
                    this._EventBus.emit({
                        Type: 'header-left-statics',
                        data: response.Data
                    });
                }
            }, (error) => {
            });
        }
        this.SubdomainName = this.userService.getSubdomain();
        setTimeout(function () {
            $(document).ready(function () { $('ul.tabs').tabs(); });
            // disable outside close modal
            $('#inviteAgent').modal({ dismissible: false });
            $('#editAgent').modal({ dismissible: false });
            $('#inviteAgent').modal({ dismissible: false });
        }, 400);

        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });

        this.selectedOptionReOccure = 0;
    }

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _EventBus: EventBus,
        private tokboxService: TokboxService,
        private _ngZone: NgZone
    ) {
        this.reOccurArray = [{ 'Name': 'Active', 'Value': 0 }, { 'Name': 'Deleted', 'Value': 1 }];
        this.key = 'Name';
        this.LoadMoreCounter = 0;
        // get agency/admin detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoginUserId = UserModel.user.UserId;
            this.LoggedInUserDescription = UserModel.user.Description ? UserModel.user.Description : '';
            this.AgencyId = UserModel.user.AgencyId;
            this.LocationId = UserModel.user.LocationId;
            if (this.userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId === 6) {
                this.LocationId = Number(this.userService.GetSelectedLocation());
            } else {
                this.LocationId = UserModel.user.LocationId;
            }
        }
        this.Count = 1;
        this.NotUserFound = false;
        this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'locationChanged') {
                this.LocationId = Number(m.filterBy.data.LocationId);
                this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
            }
        });
    }
    // refresh user list token signal online/offline status
    public RefreshList(UserId, ConnectionId, IsConnected) {
        this.refereshUserId = UserId;
        this.refereshConnectionId = ConnectionId;
        if (ConnectionId && IsConnected) {
            const isExists = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId) > -1;
            if (!isExists) {
                this.ArrayOnlineTokboxuser.push({ UserId: UserId, ConnectionId: ConnectionId });
            }
        } else {
            const index = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId);
            if (index > -1) {
                this.ArrayOnlineTokboxuser.splice(index, 1);
            }
        }

        this.users.forEach((item) => {
            const isAgentStillConnected = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == item.UserId) > -1;
            if (isAgentStillConnected) {// If USER IS ONLINE
                item.ConnectionId = ConnectionId;
            } else {
                item.ConnectionId = '';
            }
        });
    }
    // get user by swich Active/deleted User
    public Changefilter(selectedOptionReOccure) {
        if (selectedOptionReOccure === 0) {
            this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
        } else {
            const that = this;
            if (this.LoadMoreCounter === 0) { that.UserLoading = true; }
            this.userService.GetAllDeletedUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.AgencyId, this.LocationId, true)
                .subscribe(
                    result => {
                        that.LoadMoreLoader = true;
                        if (result.Status) {
                            if (this.LoadMoreCounter === 0) {
                                this.users = result.Data;
                            } else {
                                if (result.Data) {
                                    result.Data.forEach(function (item) {
                                        that.users.push(item);
                                    });
                                }
                            }
                            this.users.forEach(function (item) {
                                item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
                                item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
                                item.Name = `${item.FirstName} ${item.LastName}`;
                                item.Initials = that.GetInitials(item.Name);
                                item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                                item.IsDeleted = 1;
                            });
                            this.TotalUsers = this.users.length.toString();
                            this.TotalUsers = this.users && this.users.length ? this.users[0].UserCounts.toString() : '0';
                            if (this.users.length === 0) {
                                this.NotUserFound = true;
                            }
                        } else {
                            if (this.LoadMoreCounter === 0) { this.NotUserFound = true; }
                        }
                        this.UserLoading = false;
                        that.LoadMoreLoader = false;
                    },
                    error => {
                        if (error.Message === 'Token expired') {
                            localStorage.removeItem('frontend-token');
                            localStorage.removeItem('StripeCust');
                            this._router.navigate(['/login']);
                        }
                    }
                );

        }
    }
    // on scroll down load user list
    public onScrollDown() {
        if (this.users.length % 50 === 0 && this.LoadMoreUser === true) {
            this.LoadMoreLoaderEnable = true;
            this.LoadMoreCounter = this.LoadMoreCounter + 50;
            this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
        }
    }
    // show success/error message toast
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // start call to user from listing
    public StartCall(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio) {
        $('.loader-outer').css('display', 'inline-flex');
        // Generate new session and Token
        this.GenerateSessionIdAndTokenId(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio);
    }
    // for send message redirect admin to message page
    public SendMessage(ToUserId) {
        this._router.navigate(['/agency/messages', ToUserId]);
    }
    // connect call to user
    public GenerateSessionIdAndTokenId(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio) {
        this.tokboxService.createsessionandtoken(this.LoginUserId, ToUserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2, ToUserId,
                            result.currentSessionId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio);
                    } else {
                        $('.loader-outer').css('display', 'none');
                        alert(result.Message);
                    }
                },
                error => {
                }
            );
    }
    // load all user listing
    public loadAllUsers(RoleId, OffSet, Search, sortKey, sortOrder) {
        const that = this;
        if (this.LoadMoreUser === true || this.searchText !== Search) {
            this.LoadMoreUser = false;
            if (OffSet === 0) { that.UserLoading = true; that.NotUserFound = false; }
            this.userService.getAll(RoleId, OffSet, this.AgencyId, this.LocationId, true, Search, sortKey, sortOrder)
                .subscribe(
                    result => {
                        that.LoadMoreLoader = true;
                        if (result.Status) {
                            if (OffSet === 0) {
                                this.users = [];
                                this.users = result.Data;
                            } else {
                                if (result.Data) {
                                    result.Data.forEach(function (item) {
                                        that.users.push(item);
                                    });
                                }
                            }
                            this.users.forEach((item) => {
                                item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
                                item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
                                item.Name = `${item.FirstName} ${item.LastName}`;
                                item.Initials = that.GetInitials(item.Name);
                                item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                                item.IsDeleted = 0;
                                item.Description = this.LoggedInUserDescription;
                            });
                            this.TotalUsers = this.users.length.toString();
                            this.TotalUsers = this.users && this.users.length ? this.users[0].UserCounts.toString() : '0';
                            if (this.users.length === 0) {
                                this.NotUserFound = true;
                            }
                        } else {
                            if (OffSet === 0) { this.NotUserFound = true; }
                        }
                        this.UserLoading = false;
                        that.LoadMoreLoader = false;
                        this.LoadMoreLoaderEnable = false;
                        this.LoadMoreUser = true;
                    },
                    error => {
                        if (error.Message === 'Token expired') {
                            localStorage.removeItem('frontend-token');
                            localStorage.removeItem('StripeCust');
                            this._router.navigate(['/login']);
                        }
                        this.LoadMoreUser = true;
                    }
            );
        }
    }
    // show user detail page
    public showuserdetail(UserId) {
        this._router.navigate(['agency/userdetail', UserId]);
    }
    // get intial user by name
    public GetInitials(Name) {
        if (this.Count >= 3) {
            this.Count = 1;
        } else {
            this.Count = this.Count + 1;
        }
        const splittedName = Name.split(' ', 3);
        if (splittedName.length > 1) {
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
        } else {
            return splittedName[0].charAt(0);
        }
    }
    // get class active different color
    public GetClass() {
        if (this.Count === 1) {
            return 'iconUser noImg cyan lighten-3';
        } else if (this.Count === 2) {
            return 'iconUser noImg cyan lighten-5';
        } else if (this.Count === 3) {
            return 'iconUser noImg pink lighten-4';
        }
    }
    // search user from database
    SearchUsers() {
        if (this.searchText.length > 0) {
            this.LoadMoreCounter = 0;
        }
        this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
    }
    // sord user listing from database
    ServerSort(sortKey: string) {
        this.LoadMoreCounter = 0;
        if (this.SortKey === sortKey) {
            this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
            this.SortKey = sortKey;
        } else {
            this.SortOrder = 'ASC';
            this.SortKey = sortKey;
        }
        this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
    }
}
