import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { UserService } from '../model/user.service';
import { TokboxService } from '../model/tokbox.service';
import { User } from '../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Referral } from '../model/referral';
import '../../assets/tokbox/js/app.js';
import { EventBus } from '../shared/EventBus/EventBus';
import { toast, MaterializeAction } from 'angular2-materialize';
import { Document } from '../model/document';
import { DomSanitizer } from '@angular/platform-browser';
import { Activity } from '../model/activity';

declare var myExtObject: any;
enum StatusMaster {
  Claim = 1,
  Pending = 2,
  Paid = 3
}

@Component({
  templateUrl: './agent.component.html'
})

export class AgentComponent implements OnInit {
  previewimagemodal = new EventEmitter<string | MaterializeAction>();
  previewimagemodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];

  @ViewChild('iframeelement') iframe: ElementRef;
  IsDoc: boolean;
  IsLoaded: boolean;
  previewImageURL: any;
  ShowDocumentLoader: boolean;
  documents: Document[] = [];
  ShowDocTable: boolean;
  NotDocumentFound: boolean;
  ShowPenRefLoader: boolean;
  ShowDashLoader: boolean;
  ShowAvailAgentsLoader: boolean;
  NoReferral: boolean;
  StripeCustomerId: string;
  users: User[] = [];
  pendingReferrals: Referral[] = [];
  DetailedUser: User;
  showdetail: boolean;
  showlisting: boolean;
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  DashBoardData: any;
  Count: number;
  AmoutType: string;
  ReferalAmount: number;
  LoggedInUserRoleId: number;
  AgencyId: number;
  AgencyReferalSetingExist: boolean;
  IsAgencyReferalSetingLoding: boolean;
  LocationId: number;
  AgencyName: string;
  wasClicked = false;
  LoadMoreCounter: number;
  UserId: Number;
  documentkey: string;
  documentreverse: boolean;
  key: string;
  reverse: boolean;
  Activity: Activity[];
  ActivityData: Activity;
  AllActivityCount = 0;
  SeenActivityCount = 0;
  UnSeenActivityCount = 0;
  LoadMoreActivityCounter = 0;
  ShowActivityLoader: boolean;

  constructor(private _domSanitizer: DomSanitizer, private userService: UserService,
    private tokboxService: TokboxService,
    private route: ActivatedRoute, private _router: Router, private _EventBus: EventBus) {
    this.Activity = [];
    this.AllActivityCount = 0;
    this.SeenActivityCount = 0;
    this.UnSeenActivityCount = 0;
    this.documentkey = 'UploadedDate';
    this.key = 'name';
    this.documentreverse = true;
    this.ShowDocTable = true;
    this.NoReferral = true;
    this.Count = 1;
    this.LoadMoreCounter = 0;
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.AgencyName = UserModel.user.LocationName;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
      this.StripeCustomerId = this.userService.getCustId();
      this.loadUserDashboard();
      this.UserId = UserModel.user.UserId;
      this.loadPendingReferral(UserModel.user.UserId, this.LoadMoreCounter);
      this.getDocuments(UserModel.user.UserId, 5);
      this._EventBus.on().subscribe((m: any) => {
        if (m.filterBy.Type === 'claimreferral') {
          if (this.StripeCustomerId) {
            this.ClaimReferral(m.filterBy.data.ReferralIds, m.filterBy.data.BankAccountId);
            this.loadPendingReferral(UserModel.user.UserId, this.LoadMoreCounter);
          }
        } else if (m.filterBy.Type === 'Load-User-Dashboard-Event') {
          this.loadUserDashboard();
        } else if (m.filterBy.Type === 'GET_UPDATED_ACTIVITY_TRIGGERED') {
          this.Activity = m.filterBy.data.data;
          this.AllActivityCount = m.filterBy.data.AllActivityCount;
          this.SeenActivityCount = m.filterBy.data.SeenActivityCount;
          this.UnSeenActivityCount = m.filterBy.data.UnSeenActivityCount;
        }
      });
    }
    this.GetUserActivities(true);
  }

  ngOnInit() {
    this.GetAgencyReferalDetail();
    if (this.userService.decodeToken().user.RoleId !== 3) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }
    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });

  }
  // refresh activity count
  public RefreshActivityCount() {
    if (this.Activity && this.Activity.length > 0) {
      const acivity = this.Activity[0];
      this.AllActivityCount = acivity.TotalActivityCount;
      this.UnSeenActivityCount = acivity.UnSeenCount;
      this.SeenActivityCount = acivity.SeenCount;

    } else {
      this.AllActivityCount = 0;
      this.SeenActivityCount = 0;
      this.UnSeenActivityCount = 0;
    }
  }
  // get user activities
  GetUserActivities(isResetArray = false, offset = 0, activityModuleId = 0, activityType = 0) {
    const postData = {
      UserId: this.LoginUserId,
      OffSet: offset,
      ActivityModuleId: activityModuleId,
      ActivityType: activityType
    };
    if (!this.ShowActivityLoader) {
      this.ShowActivityLoader = true;
      this.userService.GetUserActivitiesForUser(postData).subscribe((respo: any) => {
        this.ShowActivityLoader = false;
        if (isResetArray) {
          this.Activity = [];
        }
        const activityData = respo && respo.Data ? respo.Data : [];
        activityData.forEach((element: Activity) => {
          this.Activity.push(element);
        });
        this.RefreshActivityCount();
      }, error => {
        this.ShowActivityLoader = false;
        if (error.Message === 'Token expired') {
          localStorage.removeItem('frontend-token');
          localStorage.removeItem('StripeCust');
          this._router.navigate(['/login']);
        }
      });
    }

  }
  // convert date to local date
  GetFormatedDate(date) {
    return new Date(date).toLocaleString();
  }
  // mar activities as read
  MarkedActivitiesAsRead(shouldRedirect = false) {
    this._EventBus.emit({ Type: 'MARKED_AS_READ_ACTIVITIES', data: shouldRedirect });
  }
  // get agency referal detail
  public GetAgencyReferalDetail() {
    this.IsAgencyReferalSetingLoding = true;
    this.userService.GetAgencyReferalSettingDetail(this.AgencyId)
      .subscribe(
        result => {
          this.IsAgencyReferalSetingLoding = false;
          if (result.Status) {
            this.AgencyReferalSetingExist = true;
            this.AmoutType = result.Data.AmoutType;
            this.ReferalAmount = result.Data.Value;
          } else {
            this.AgencyReferalSetingExist = false;
          }
        },
        error => {
          this.IsAgencyReferalSetingLoding = false;
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // get file correct size
  public GetCorrectSize(FileSize) {
    if (FileSize < 1000) {
      return FileSize + ' Bytes';
    } else if (FileSize > 1000 && FileSize < 1000000) {
      return (FileSize / 1000).toFixed(2).toString() + ' KB';
    } else if (FileSize > 1000000 && FileSize < 1000000000) {
      return (FileSize / 1000000).toFixed(2).toString() + ' MB';
    } else if (FileSize > 1000000000) {
      { return (FileSize / 1000000000).toFixed(2).toString() + ' GB'; }
    }
  }
   // open preview image modal
  public openPreviewModal() {
    this.previewimagemodal.emit({ action: 'modal', params: ['open'] });
  }
  // close preview modal file
  public closepreviewimageModal() {
    this.iframe.nativeElement.innerHtml = '';
    this.previewimagemodal.emit({ action: 'modal', params: ['close'] });
  }
  // pre file modal by file type
  public previewFile(url, Extention, index) {
    // this.previewImageURL = '';
    if (Extention.toLowerCase() === 'jpg' || Extention.toLowerCase() === 'png' || Extention.toLowerCase() === 'jpeg') {
      if (this.previewImageURL !== url) { this.IsLoaded = false; }
      this.previewImageURL = url;
      this.IsDoc = false;
      this.openPreviewModal();
    } else {
      this.IsLoaded = true;
      this.IsDoc = true;
      const DocUrl = this._domSanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + url);
      this.previewImageURL = DocUrl;
      this.openPreviewModal();
    }
  }

  public OnLoadComplete() {
    this.IsLoaded = true;
  }
  // download document file
  public downloadFile(fileName, extention, url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = fileName + '.' + extention;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // get icon by file type
  public GetIcon(extention) {
    if (extention.toLowerCase().match('pdf')) {
      return 'assets/images/pdf.png';
    } else if (extention.toLowerCase().match('doc') || extention.match('docx')) {
      return 'assets/images/do.png';
    } else if (extention.toLowerCase().match('jpg') || extention.match('png') || extention.match('jpeg')) {
      return 'assets/images/jpg.png';
    } else if (extention.toLowerCase().match('xlsx') || extention.match('xls')) {
      return 'assets/images/excelr_ic.png';
    } else if (extention.toLowerCase().match('pptx')) {
      return 'assets/images/powerpoint_ic.png';
    }
  }
  // get documents
  public getDocuments(UserId, FolderId) {
    this.ShowDocumentLoader = true;
    const that = this;
    this.NotDocumentFound = false;
    this.userService.getDocuments(UserId, FolderId, '', '', 0)
      .subscribe(
        result => {
          if (result.Status) {
            this.documents = [];
            this.documents = result.Data;
            this.documents.forEach(function (item) {
              item.FullName = item.Name[0].toUpperCase() + item.Name.slice(1);
              item.Name = item.Name[0].toUpperCase() + item.Name.slice(1);
              if (item.Name.length > 16) {
                item.Name = item.Name.substring(0, 15) + '...';
              }
              item.DateTimeString = new Date(item.UploadedDate).toLocaleString();
              item.ShowActions = true;
              item.Size = that.GetCorrectSize(item.SizeInKB);
              item.ExtensionImage = that.GetIcon(item.Extention);
            });
          } else {
            this.NotDocumentFound = true;
            this.documents = [];
          }
          this.ShowDocumentLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowDocumentLoader = false;
        });
  }

  private userDetail(UserId) {
    this.userService.userDetail(this.LoginUserId, UserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.DetailedUser = result.Data;
          } else {
            alert(result.Message);
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // load user dashboard data
  public loadUserDashboard() {
    this.ShowDashLoader = true;
    this.userService.getdashdata(this.LoginUserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.DashBoardData = result.Data;
          } else {
            alert(result.Message);
          }
          this.ShowDashLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowDashLoader = false;
        }
      );
  }
  // load all users
  public loadAllUsers(RoleId, OffSet) {
    this.ShowAvailAgentsLoader = true;
    const Serach = '';
    this.userService.getAll(RoleId, OffSet, this.AgencyId, this.LocationId, false, Serach, '', '')
      .subscribe(
        result => {
          if (result.Status) {
            this.users = result.Data;
          } else {
            this.toasting(result.Message, 2000, 'red');
          }
          this.ShowAvailAgentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAvailAgentsLoader = false;
        }
      );
  }

  public ClaimReferral(ReferralId, BankAccountId) {
    const that = this;
    this.userService.ClaimReferral(ReferralId, BankAccountId, this.StripeCustomerId, 1)
      .subscribe(
        result => {
          if (result.Status) {
            this.toasting('Cliam Approve Successfully', 2000, 'green');
            this.loadPendingReferral(that.LoginUserId, this.LoadMoreCounter);
          } else {
            this.toasting(`${result.Message}`, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // on scroll down pending referal
  public onScrollDown() {
    this.LoadMoreCounter = this.LoadMoreCounter + 2;
    this.loadPendingReferral(this.UserId, this.LoadMoreCounter);
  }
  // load pending referal
  public loadPendingReferral(UserId, OffSet) {
    const that = this;
    that.ShowPenRefLoader = true;
    that.userService.getAllPendingRef(UserId, OffSet)
      .subscribe(
        result => {
          if (result.Status) {
            if (OffSet === 0) {
              that.pendingReferrals = result.Data;
            } else {
              result.Data.forEach(function (item) {
                that.pendingReferrals.push(item);
              });
            }
            if (that.pendingReferrals.length > 0) { that.NoReferral = false; }
            that.pendingReferrals.forEach(function (item) {
              item.DateTimeString = new Date(item.CreatedDateTime).toLocaleString();
              item.FirstName = item.FirstName ? item.FirstName : '';
              item.LastName = item.LastName ? item.LastName : '';
              item.Name = `${item.FirstName} ${item.LastName}`;
              item.Initials = that.GetInitials(item.Name);
              item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
              item.StatusName = StatusMaster[item.RefStatusId].toString();
            });
          } else {
          }
          that.ShowPenRefLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          that.ShowPenRefLoader = false;
        }
      );
  }
  // get initial of user by name
  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }
  // get class user listing
  public GetClass() {
    if (this.Count === 1) {
      return 'iconUser noImg cyan lighten-3';
    } else if (this.Count === 2) {
      return 'iconUser noImg cyan lighten-5';
    } else if (this.Count === 3) {
      return 'iconUser noImg pink lighten-4';
    }
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }

  public StartCall(ToUserId) {
    // Generate new session and Token
    this.GenerateSessionIdAndTokenId(ToUserId);
  }

  public StartRandomCall() {
    // Get Agent UserId who need to be called as per random logic
    this.tokboxService.getrandomagentid(this.AgencyId, this.LocationId)
      .subscribe(
        result => {
          if (result.Status) {
            this.GenerateSessionIdAndTokenId(result.ToUserId);
          } else {
            alert(result.Message);
          }
        },
        error => {

        }
      );
  }

  public EndCall() {
    myExtObject.end();
  }

  public SendMessage() {
    myExtObject.sendmessage();
  }

  public LogoutTokbox() {
    myExtObject.logout();
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  public GenerateSessionIdAndTokenId(ToUserId) {
    const that = this;
    this.tokboxService.createsessionandtoken(this.LoginUserId, ToUserId)
      .subscribe(
        result => {
          if (result.Status) {
            myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2, ToUserId, result.currentSessionId);
          } else {
            that.toasting(result.Message, 2000, 'red');
          }
        },
        error => {

        }
      );
  }


  // sorting
  documentsort(property) {
    this.documentreverse = !this.documentreverse; // change the direction
    this.documentkey = property;
    const direction = this.documentreverse ? 1 : -1;

    this.documents.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }

  // sorting

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  OpenAgentView() {
    this.wasClicked = !this.wasClicked;
  }
  // scroll down load activities
  public onScrollDownActivities() {
    this.LoadMoreActivityCounter = this.LoadMoreActivityCounter + 50;
    this.GetUserActivities(false, this.LoadMoreActivityCounter);
  }
  // two decimal points payment
  GetFormatedDollor(dollor) {
    return Number(dollor).toFixed(2);
  }

  identifyActivity(index: number, item: Activity) {
    return item.Id;
  }
}
