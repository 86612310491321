import { ElementRef, Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Document } from '../../model/document';
import '../../../assets/tokbox/js/app.js';
import { DomSanitizer } from '@angular/platform-browser';
import { MaterializeAction, toast } from 'angular2-materialize';
import { environment } from '../../../environments/environment';
import { EventBus } from '../../shared/EventBus/EventBus';

declare var myExtObject: any;

@Component({
  templateUrl: './PendingDoc.component.html'
})

export class PendingDocComponent implements OnInit {
  previewimagemodal = new EventEmitter<string | MaterializeAction>();
  previewimagemodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  ShowAvailAgentsLoader: boolean;
  ShowDocumentLoader: boolean;
  wasClicked = false;
  users: User[] = [];
  ShowActionLoader: boolean;
  IsLoaded: boolean;
  documents: Document[] = [];
  Folders: any[];
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  NoDocument: boolean;
  LocationId: number;
  Count: number;
  ShowDocTable: boolean;
  NotDocumentFound: boolean;
  previewImageURL: any;
  IsDoc: boolean;
  AgencyId: number;
  documentkey: string;
  documentreverse: boolean;
  SortKey = 'UploadedDate';
  SortOrder = 'DESC';
  LoadMoreCounter = 0;

  ngOnInit() {
    if (this.userService.decodeToken().user.RoleId !== 3) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }

    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });
  }

  constructor(private sanitizer: DomSanitizer, private userService: UserService,
    private tokboxService: TokboxService, private elementRef: ElementRef,
    private route: ActivatedRoute, private _router: Router, private _EventBus: EventBus) {
    const that = this;
    this.documentkey = 'documentkey';
    this.documentreverse = true;
    this.Count = 1;
    // get agency/user detail fron detail
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LocationId = UserModel.user.LocationId;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.ShowDocTable = true;
      this.loadAllUsers(UserModel.user.RoleId);
      this.getUnsignedDocuments(this.LoginUserId);
    }

    if (this.LoginUserId && this.LoginUserId > 0) {
      this._EventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
    }
  }
  // download file
  public downloadFile(fileName, extention, url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = fileName + '.' + extention;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // delete file document
  public DeleteFile(Key, FolderID, index) {
    this.documents[index].ShowActions = false;
    const that = this;
    this.userService.AwsTempraryUploadDetails()
      .subscribe((response) => {
        if (response.Status && response.Data) {
          const AWSService = window.AWS;
          // AWSService.config.accessKeyId = environment.amazon_access_key;
          // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
          AWSService.config.update({
            region: 'us-west-2',
            credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
              response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
          });
          const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
          const params = { Key: Key };
          bucket.deleteObject(params, function (err, data) {
            if (data !== undefined) {
              that.userService.deleteDocument(Key)
                .subscribe(
                  result => {
                    that.ShowActionLoader = false;
                    if (result.Status) {
                      that.toasting('File deleted successfully', 2000, 'green');
                      that.getUnsignedDocuments(that.LoginUserId);
                      that.documents[index].ShowActions = false;
                    } else {
                      that.documents = [];
                    }
                  },
                  error => {
                    if (error.Message === 'Token expired') {
                      localStorage.removeItem('frontend-token');
                      localStorage.removeItem('StripeCust');
                      this._router.navigate(['/login']);
                    }
                  }
                );
            } else if (err !== undefined) {
              err.Status = false;
            }
          });
        }
      }, (error) => {
        this.toasting('Server Issue. Try again after sometime', 2000, 'red');
      });
  }

  public OnLoadComplete() {
    this.IsLoaded = true;
  }
  // get unsingned documents
  public getUnsignedDocuments(UserId) {
    if (this.LoadMoreCounter === 0) {
      this.ShowDocumentLoader = true;
    }
    const that = this;
    this.NotDocumentFound = false;
    this.userService.getUnsignedDocuments(UserId, false, this.SortKey, this.SortOrder, this.LoadMoreCounter)
      .subscribe(
        result => {
          if (result.Status) {
            if (this.LoadMoreCounter === 0) {
              this.documents = [];
              this.documents = result.Data;
            } else {
              if (result.Data.length) {
                result.Data.forEach((item) => {
                    this.documents.push(item);
                });
              }
            }
            this.documents.forEach(function (item) {
              item.Name = item.Name[0].toUpperCase() + item.Name.slice(1);
              item.DateTimeString = new Date(item.UploadedDate).toLocaleString();
              item.ShowActions = true;
              item.Size = that.GetCorrectSize(item.SizeInKB);
              item.ExtensionImage = that.GetIcon(item.Extention);
            });
          } else {
            if (this.LoadMoreCounter === 0 && this.documents.length) {
              this.NotDocumentFound = true;
              this.documents = [];
            } else {
              this.NotDocumentFound = false;
            }
          }
          this.ShowDocumentLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowDocumentLoader = false;
        });
  }
  // get icon by file type
  public GetIcon(extention) {
    if (extention.toLowerCase().match('pdf')) {
      return 'assets/images/pdf.png';
    } else if (extention.toLowerCase().match('doc') || extention.match('docx')) {
      return 'assets/images/do.png';
    } else if (extention.toLowerCase().match('jpg') || extention.match('png') || extention.match('jpeg')) {
      return 'assets/images/jpg.png';
    } else if (extention.toLowerCase().match('xlsx') || extention.match('xls')) {
      return 'assets/images/excelr_ic.png';
    } else if (extention.toLowerCase().match('pptx')) {
      return 'assets/images/powerpoint_ic.png';
    }
  }
  // user signature redirect documents
  public UserSignRedirect(documentKey, DocId) {
    const res: any = documentKey.split('.pdf');
    this._router.navigate(['user/usersign', res[0], DocId]);
  }
  // get all users
  public loadAllUsers(RoleId) {
    this.ShowAvailAgentsLoader = true;
    this.userService.getAll(RoleId, 0, this.AgencyId, this.LocationId, false, '', '', '')
      .subscribe(
        result => {
          if (result.Status) {
            this.users = result.Data;
          } else {
            alert(result.Message);
          }
          this.ShowAvailAgentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAvailAgentsLoader = false;
        }
      );
  }
  // get correct size
  public GetCorrectSize(FileSize) {
    if (FileSize < 1000) {
      return FileSize + ' Bytes';
    } else if (FileSize > 1000 && FileSize < 1000000) {
      return (FileSize / 1000).toFixed(2).toString() + ' KB';
    } else if (FileSize > 1000000 && FileSize < 1000000000) {
      return (FileSize / 1000000).toFixed(2).toString() + ' MB';
    } else if (FileSize > 1000000000) {
      return (FileSize / 1000000000).toFixed(2).toString() + ' GB';
    }
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }

  public LogoutTokbox() {
    myExtObject.logout();
  }

  OpenAgentView() {
    this.wasClicked = !this.wasClicked;
  }

  // sort documents server
  ServerSort(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKey === sortKey) {
      this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.SortKey = sortKey;
    } else {
      this.SortOrder = 'ASC';
      this.SortKey = sortKey;
    }
    this.getUnsignedDocuments(this.LoginUserId);
  }
  // scroll down load more documents
  public onScrollDown() {
    if (this.documents.length % 50 === 0) {
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.getUnsignedDocuments(this.LoginUserId);
    }
  }

  // close preview modal
  public closepreviewimageModal() {
      this.previewimagemodal.emit({ action: 'modal', params: ['close'] });
  }
}
