import { Component, EventEmitter, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction, toast } from 'angular2-materialize';
import { UserService } from '../../model/user.service';
import '../../../assets/tokbox/js/app.js';
import { Router } from '@angular/router';
import { EventBus } from '../EventBus/EventBus';

declare var myExtObject: any;
declare var $: any;
declare function CreateStripeSuperAdminInstance(token: string): void;

@Component({
  moduleId: module.id,
  selector: 'app-update-agency-card',
  templateUrl: 'update-agency-card.component.html'
})
export class UpdateAgencyCardComponent implements OnInit {
  stripetoken: any;
  model1Params: any;
  _chargeCustomer: FormGroup;
  ShowConfirmDeleteLoader: boolean;
  private _formErrors: any;
  private _errorMessage: string;
  SelectedCustomerId: string;
  SelectedCustomerCardId: string;
  UpdatingCard: boolean;

  modalActions = new EventEmitter<string | MaterializeAction>();
  deleteconfirmaction = new EventEmitter<string | MaterializeAction>();
  params = [];
  uploaddocmodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  ShowMe: boolean;
  UserId: number;
  AgencyId: number;
  LocationId: number;
  Name: string;
  StripeCustomerId: string;
  SelectedCardId: string;
  PaymentMethodList: any[];
  LoadMoreCounter: number;
  ShowLoader: boolean;
  ShowButtonLoader: boolean;
  ShowCreateChargeDiv: boolean;
  message: string;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private _router: Router,
    private _ngZone: NgZone,
    private _EventBus: EventBus
  ) {
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.UserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.Name = UserModel.user.Name;
      this.StripeCustomerId = UserModel.user.StripeCustomerId;
    }
    this.ShowButtonLoader = false;
    this.SelectedCardId = '';
    this.ShowCreateChargeDiv = false;
    this.LoadMoreCounter = 0;
    this._chargeCustomer = _formBuilder.group({
      amount: ['', Validators.compose([Validators.required])],
      description: ['']
    });
    this._chargeCustomer.valueChanges.subscribe(data => this.onValueChanged(data));
    window['updatecardref'] = {
      zone: this._ngZone,
      receivetoken: (value) => this.ReceiveTokenFromStripe(this.stripetoken),
      component: this,
    };
  }

  public Updatecard() {
    // this.UpdatingCard = true;
  }
  // receive token from stripe
  public ReceiveTokenFromStripe(token) {
    this.userService.UpdateDefaultCard(this.StripeCustomerId, token.id)
      .subscribe(
        result => {
          if (result.Status) {
            this._EventBus.emit(
              {
                Type: 'cardupdated',
                data: {}
              });
            this.UpdatingCard = false;
            this.closeAddCardModal();
          } else {
            this.toasting(result.Message, 3000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // valuve change reset errors
  public onValueChanged(data?: any) {
    if (!this._chargeCustomer) { return; }
    const form = this._chargeCustomer;
    for (const field in this._formErrors) {
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }

  ngOnInit() {
    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#updatecard').modal({ dismissible: false });
    }, 400);
  }

  private _chargeCustomercheckisValid(field): boolean {
    let isValid: boolean;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._chargeCustomer.controls[field].touched === false) {
      isValid = true;
    } else if (this._chargeCustomer.controls[field].touched === true && this._chargeCustomer.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }

  public ManageAddCard(value) {
    myExtObject.CreateCardInstance();
  }

  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // add card stripe
  public openAddCardModal(ShowAdd) {
    CreateStripeSuperAdminInstance('pk_test_K39xdSVefTwlDZqsQV5y1PbF');
    // CreateStripeSuperAdminInstance('pk_live_M8RwJmfz58HUA9SQ9HAWBINa');
    const that = this;
    setTimeout(function () {
      that.ManageAddCard(true);
      that.modalActions.emit({ action: 'modal', params: ['open'] });
    }, 1);
    this.ShowLoader = true;
    return false;
  }
  // close add card modal
  public closeAddCardModal() {
    const that = this;
    this.ShowMe = false;
    const millisecondsToWait = 1;
    setTimeout(function () {
      that.ShowCreateChargeDiv = false;
      that._chargeCustomer.reset();
      that.modalActions.emit({ action: 'modal', params: ['close'] });
    }, millisecondsToWait);
  }
}
