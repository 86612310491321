import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { UserService } from '../../model/user.service';
import { Router } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';
import { MaterializeAction } from 'angular2-materialize';
declare var $: any;
import { PlanService } from '../../model/plan';
import { toast } from 'angular2-materialize';

@Component({
    templateUrl: './agencyadmindashboardsubscriptions.component.html'
})

export class AgencyAdminDashboardSubscriptionsComponent implements OnInit, OnDestroy {

    public agents = [
        {
            'agent_group_id': 1,
            'agent_count': 4,
            'agent_price': 75,
            'selected': true
        },
        {
            'agent_group_id': 2,
            'agent_count': 8,
            'agent_price': 150,
            'selected': false
        },
        {
            'agent_group_id': 3,
            'agent_count': 12,
            'agent_price': 237,
            'selected': false
        },
        {
            'agent_group_id': 4,
            'agent_count': 16,
            'agent_price': 225,
            'selected': false
        },
        {
            'agent_group_id': 5,
            'agent_count': 20,
            'agent_price': 400,
            'selected': false
        },
        {
            'agent_group_id': 6,
            'agent_count': 24,
            'agent_price': 475,
            'selected': false
        },
        {
            'agent_group_id': 7,
            'agent_count': 28,
            'agent_price': 550,
            'selected': false
        }
    ];
    AddingAgents: boolean;
    SelectedAgentGroup: any;
    modalActions1 = new EventEmitter<string | MaterializeAction>();
    modalActions2 = new EventEmitter<string | MaterializeAction>();
    modalActions3 = new EventEmitter<string | MaterializeAction>();
    modalActions4 = new EventEmitter<string | MaterializeAction>();
    Plans: any[];
    PlanLoading: boolean;
    CancelLoading = false;
    ResumeLoading = false;
    AgencyId: string;
    StripeCustomerId: string;
    SubscriptionDetailLoading: boolean;
    CurrentSubsObj: any;
    Planid: any;
    PlanToBeSubscribe: any;
    AgencyEmail: string;
    selectedOptionName = 'Choose your option';
    CommentName: string;
    CancelReasonArray: any[];
    AdditionKeyAgent: string;
    model1Params: any;
    InvoiceLoading: boolean;
    Invoices: any;
    DefaultCard: any;
    DefaultCardLoading: boolean;
    CurentTimeSpan: number;
    public LoginUserId: number;

    constructor(private userService: UserService, private _router: Router,
        // private _planservice: PlanService,
        private _EventBus: EventBus) {
        setTimeout(function () {
            $('#thankyoumodal').modal({ dismissible: false });
            $('#upgrade_agents').modal({ dismissible: false });
        }, 400);
        this.CancelReason();
        const UserModel = this.userService.decodeToken();
        // get Agency Admin/Agency detail from token
        if (UserModel && UserModel.user) {
            this.AgencyId = UserModel.user.AgencyId;
            this.LoginUserId = UserModel.user.UserId;
            this.StripeCustomerId = UserModel.user.StripeCustomerId;
            this.AgencyEmail = UserModel.user.Email;
        }
        this.GetSubscriptionDetail();
        this.Getinvoices();
        this.GetDefaultCard();
        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'cardupdated') {
                this.GetDefaultCard();
            }
        });
    }
    // destroy variable when component destroys
    ngOnDestroy() {
        PlanService.SubscribedPlan = this.CurrentSubsObj;
        PlanService.PlanToBeSubscribe = this.PlanToBeSubscribe;
        PlanService.DefaultCard = this.DefaultCard;
    }
    // get Agency subscription detail
    public GetSubscriptionDetail() {
        this.SubscriptionDetailLoading = true;
        this.userService.GetSubscriptionDetail(this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.CurrentSubsObj = result.Data;
                        this.Planid = this.CurrentSubsObj.PlanTypeId;
                        this.GetPlan();
                        if (Number(this.CurrentSubsObj.SubscriptionPeriodEnd) < Math.floor(Date.now() / 1000)) {
                            this._router.navigate(['/agency/subscriptioncancel']);
                        } else {
                            this.ResetAddAgentsList();
                            this.CurrentSubsObj.SubscriptionCreatedAt = this.timeToHuman(this.CurrentSubsObj.SubscriptionCreatedAt);
                            this.CurrentSubsObj.SubscriptionPeriodStart = this.timeToHuman(this.CurrentSubsObj.SubscriptionPeriodStart);
                            this.CurrentSubsObj.SubscriptionPeriodEnd = this.timeToHuman(this.CurrentSubsObj.SubscriptionPeriodEnd);
                        }
                    } else {
                        this.toasting(result.Message, 2000, 'red');
                    }
                    this.SubscriptionDetailLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.SubscriptionDetailLoading = false;
                }
            );
    }

    ngOnInit() {
        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
    }
    // get plan list
    public GetPlan() {
        const that = this;
        that.PlanLoading = true;
        this.userService.GetPlanList(this.Planid, 'Month')
            .subscribe(
                result => {
                    if (result.Status) {
                        that.Plans = result.Data;
                    } else {
                        alert(result.Message);
                    }
                    this.PlanLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.PlanLoading = false;
                }
            );

    }
    // get agency invoices
    public Getinvoices() {
        this.InvoiceLoading = true;
        this.userService.GetAgencyInvoices(this.StripeCustomerId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.Invoices = result.Data;
                        this.Invoices.invoices.data.forEach(element => {
                            element.date = this.timeToHuman(element.date);
                            element.amount_paid = element.amount_paid / 100;
                        });
                        this.Invoices.upcoming.date = this.timeToHuman(this.Invoices.upcoming.date);
                        this.Invoices.upcoming.amount_due = this.Invoices.upcoming.amount_due / 100;
                    }
                    this.InvoiceLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.InvoiceLoading = false;
                }
            );

    }
    // show success/error message toast
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // get agency admin card detail
    public GetDefaultCard() {
        this.DefaultCardLoading = true;
        this.userService.GetDefaultCard(this.StripeCustomerId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.DefaultCard = result.Data;
                    } else {
                        alert(result.Message);
                    }
                    this.DefaultCardLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.DefaultCardLoading = false;
                }
            );
    }
    // resett agenct list object for next updation
    public ResetAddAgentsList() {
        if (!this.CurrentSubsObj.AdditionalAgentsCount) {
            this.CurrentSubsObj.AdditionalAgentsCount = 0;
        }
        let AddAgentStartNumber = (this.CurrentSubsObj.AdditionalAgentsCount) * 4;
        this.agents = [];
        for (let i = 0; i < 100; i++) {
            AddAgentStartNumber = AddAgentStartNumber + 4;
            if (this.CurrentSubsObj.PlanDuration === 'Month') {
                const AgenctObj = {
                    'agent_group_id': (i + 1),
                    'agent_count': AddAgentStartNumber,
                    'agent_price': (AddAgentStartNumber / 4) * 75,
                    'selected': false
                };
                this.agents.push(AgenctObj);
            } else {
                const AgenctObj = {
                    'agent_group_id': (i + 1),
                    'agent_count': AddAgentStartNumber,
                    'agent_price': (AddAgentStartNumber / 4) * 900,
                    'selected': false
                };
                this.agents.push(AgenctObj);
            }

        }
        this.agents[0].selected = true;
        this.SelectedAgentGroup = this.agents[0];
    }
    // assign selected agent plan quantity
    public onSelectionChange(selected) {
        this.agents.forEach(element => {
            if (selected.agent_group_id === element.agent_group_id) {
                element.selected = true;
                this.SelectedAgentGroup = selected;
            } else {
                element.selected = false;
            }
        });
    }
    // convert time to human understanding time
    public timeToHuman(timeStamp) {
        const theDate = new Date(timeStamp * 1000);
        const dateString = theDate.toLocaleDateString();
        return dateString;
    }
    // redirect user upgrade plan
    public UpgradePlan(plan) {
        this.PlanToBeSubscribe = plan;
        this._router.navigate(['/agency/upgrade_plan']);
    }
    public AddAgents(plan) {
        this.PlanToBeSubscribe = plan;
        this._router.navigate(['/agency/upgrade_plan']);
    }
    // Add more agents to agency plan
    public AddMoreAgents() {
        this.AddingAgents = true;
        let planquantity = 0;
        if (this.SelectedAgentGroup.agent_count === 4) {
            planquantity = 1;
        } else {
            planquantity = this.SelectedAgentGroup.agent_count / 4;
        }
        if (this.CurrentSubsObj.PlanTypeId === 3) {
            this.AdditionKeyAgent = 'plan_DcyIvrCFLyChsV';
        } else {
            this.AdditionKeyAgent = 'plan_DYV9pqeskfbjZk';
        }
        this.userService.AddMoreAgents(this.CurrentSubsObj.SubscriptionId, planquantity,
            this.AdditionKeyAgent, this.CurrentSubsObj.PlanDuration)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.modalActions1.emit({ action: 'modal', params: ['close'] });
                        this.Getinvoices();
                        this.thankYouMessage();
                    } else {
                        alert(result.Message);
                    }
                    this.AddingAgents = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.AddingAgents = false;
                }
            );
    }
    // open update subscription plan modal
    public upgradeSubscription() {
        this.modalActions1.emit({ action: 'modal', params: ['open'] });
    }
    // resume subscription
    public ResumeSubscription() {
        this.SubscriptionDetailLoading = true;
        this.ResumeLoading = true;
        this.userService.ResumeSubscription(this.CurrentSubsObj.SubscriptionId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.toasting('Your Subscription has been resumed', 2000, 'green');
                        this.Getinvoices();
                        this.GetSubscriptionDetail();
                        this.ResumeLoading = false;
                    } else {
                        alert(result.Message);
                        this.ResumeLoading = false;
                    }
                    this.SubscriptionDetailLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                        this.ResumeLoading = false;
                    }
                });
    }
    // open invoices modal
    public viewInvoices() {
        this.modalActions2.emit({ action: 'modal', params: ['open'] });
    }
    // open thank you modal
    public thankYouMessage() {
        this.modalActions3.emit({ action: 'modal', params: ['open'] });
    }
    // open cancel subscription modal
    public CancelSubscription() {
        this.modalActions4.emit({ action: 'modal', params: ['open'] });
    }
    // close cancel subsciption modal
    public CloseCancelSubscriptionModel() {
        this.selectedOptionName = 'Choose your option';
        this.CommentName = '';
        this.modalActions4.emit({ action: 'modal', params: ['close'] });
    }
    // get cancel reason
    public CancelReason() {
        this.userService.GetCancelReason()
            .subscribe(result => {
                if (result.Status) {
                    this.CancelReasonArray = [];
                    this.CancelReasonArray.push({ReasonId: 0, Name: 'Choose your option'});
                    if (result.Data && result.Data.length > 0 ) {
                        result.Data.forEach((item) => {
                            this.CancelReasonArray.push(item);
                        });
                    }
                }
            },
                error => { });
    }
    // cancel subscription of agency
    public CancelSubscriptionOfAgency(CommentCancel, Reason) {
        if (!CommentCancel) {
            CommentCancel = '';
        }
        this.CancelLoading = true;
        this.userService.CancelSubscription(this.CurrentSubsObj.SubscriptionId, Reason, CommentCancel, this.AgencyEmail)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.CloseCancelSubscriptionModel();
                        this.toasting('Your Subscription has been cancelled', 2000, 'green');
                        this.GetSubscriptionDetail();
                    } else {
                        this.toasting(result.Message, 2000, 'red');
                    }
                    this.CancelLoading = false;
                    this.SubscriptionDetailLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                        this.CancelLoading = false;
                    }
                    this.SubscriptionDetailLoading = false;
                }
            );
    }
    // refresh subscription after changes and close modal
    public RefreshSubscription() {
        this.GetSubscriptionDetail();
        this.modalActions3.emit({ action: 'modal', params: ['close'] });
    }
}
