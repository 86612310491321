import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { toast } from 'angular2-materialize';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';

declare var myExtObject: any;

@Component({
    templateUrl: './userproductdetail.component.html'
})

export class UserProductDetailComponent implements OnInit {
    LoginUserId: number;
    AgencyId: number;
    LocationId: number;
    ProductDetail: any;
    ShowProductDetailLoader: boolean;
    users: User[] = [];
    ShowAvailAgentsLoader: boolean;
    ngOnInit() {
        if (this.userService.decodeToken().user.RoleId !== 2) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
        }

        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._eventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });

    }
    constructor(private userService: UserService, private _router: Router,
        private tokboxService: TokboxService, private _eventBus: EventBus,
        private route: ActivatedRoute) {
        // get user detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            let ProductId = 0;
            this.route.params.forEach((params: Params) => {
                ProductId = +params['ProductId'];
            });
            this.GetProductDetail(ProductId);
            this.LoginUserId = UserModel.user.UserId;
            this.AgencyId = UserModel.user.AgencyId;
            this.LocationId = UserModel.user.LocationId;
            this.loadAllUsers(UserModel.user.RoleId, 0);
        } else {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
        }
    }
    // get product detail
    public GetProductDetail(productid) {
        this.ShowProductDetailLoader = true;
        this.userService.ProductDetail(productid)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.ProductDetail = result.Data[0];
                    } else {
                    }
                    this.ShowProductDetailLoader = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.ShowProductDetailLoader = false;
                }
            );
    }
    public loadAllUsers(RoleId, OffSet) {
        this.ShowAvailAgentsLoader = true;
        this.userService.getAll(RoleId, OffSet, this.AgencyId, this.LocationId, false, '', '', '')
            .subscribe(
                result => {
                    if (result.Status) {
                        this.users = result.Data;
                    } else {
                    }
                    this.ShowAvailAgentsLoader = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.ShowAvailAgentsLoader = false;
                }
            );
    }
    public StartCall(ToUserId) {
        this.GenerateSessionIdAndTokenId(ToUserId);
    }
    public GenerateSessionIdAndTokenId(ToUserId) {
        const that = this;
        this.tokboxService.createsessionandtoken(this.LoginUserId, ToUserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2, ToUserId, result.currentSessionId);
                    } else {
                        that.toasting(result.Message, 2000, 'red');
                    }
                },
                error => {
                }
            );
    }
    // show success/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
}
