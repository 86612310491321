import { Component, EventEmitter } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  templateUrl: './ui-kit.component.html'
})

export class UiKitComponent {

  modalActions1 = new EventEmitter<string | MaterializeAction>();
  showPassword: boolean;
  HasFilesToUpload: boolean;
  params = [];
  model1Params = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  printSomething() {
  }

  openModal1() {
    this.modalActions1.emit({ action: 'modal', params: ['open'] });
  }

  closeModal1() {
    this.modalActions1.emit({ action: 'modal', params: ['close'] });
  }

  fileEvent(data: any) {

  }

}

