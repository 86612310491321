 import { DocumentToSave } from '../../model/documenttosave';
import { Component, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../model/user.service';
import { EventBus } from '../../shared/EventBus/EventBus';
import { Router } from '@angular/router';
import { MaterializeAction, toast } from 'angular2-materialize';
import { environment } from '../../../environments/environment.prod';
import { AgencyData } from '../agency_data';
import { CustomValidators } from 'ng2-validation';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
declare var $: any;

@Component({
    templateUrl: './agencyadminprofile.component.html'
})

export class AgencyAdminProfileComponent implements OnInit {
    UserImageFilled: number;
    EditShow: boolean;
    model1Params: any;
    selectedCompanyLogo: boolean;
    CompanyLogoFilled: number;
    CompanyLogoKey: string;
    CompanyLogoUrl: string;
    selectedUserLogo: boolean;
    public file_srcs_companyLogo: string;
    validupload: boolean;
    HasFilesToUpload: boolean;
    filesTosaveDB: Array<DocumentToSave> = [];
    IsStrong: boolean;
    IsMatch: boolean;
    _signupForm: FormGroup;
    AgencyData = new AgencyData('', '', '', '', '', '');
    extens: String[];
    public file_srcs_userImage: string;
    modalActions1 = new EventEmitter<string | MaterializeAction>();
    modalActions2 = new EventEmitter<string | MaterializeAction>();
    ShareUrl: string;
    FiveDigitCode: string;
    _locations: any[];
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    AgencyLogo: string;
    AgencyPhone: string;
    AgencyAddress: string;
    MyAgencyLogo: string;
    AgencyId: number;
    public AgencyName: string;
    AgencyAddress1: string;
    AgencyAddress2: string;
    private _formErrors: any;
    AgencyCity: string;
    IsValidLength: boolean;
    AgencyState: string;
    LoggedInUserId: number;
    AgencyZipCode: string;
    SelectedLocationId: number;
    ShowLoader = false;
    ShowPageLoader = true;
    ShowPassLoader = false;
    ShowProfileLoader = false;
    showEditAgencyAddress = false;

    /*****Variable for Google Address API******/
    public city: string;
    public state: string;
    public zipcode: string;
    public lat: any;
    public long: any;
    public street_name: string;
    public stroute_flag: any;
    public stroute: string;

    strouteComponent = {
        'sublocality_level_1': 'long_name'
    };
    stateComponent = {
        administrative_area_level_1: 'long_name'
    };
    postalComponent = {
        postal_code: 'short_name'
    };
    cityComponent = {
        locality: 'long_name'
    };
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;

    ngOnInit() {
        setTimeout(function () {
            $(document).ready(function () { $('ul.tabs').tabs(); });
            // disable outside close modal
            $('#change_password').modal({ dismissible: false });
            $('#edit_agency_profile').modal({ dismissible: false });
        }, 400);
        // To show agency address data
        this.editAgencyProfile();
        this._userService.GetInitialSettings(this.LoggedInUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
    }
    constructor(
        private changeDetectorRef: ChangeDetectorRef, private _formBuilder: FormBuilder,
        private _router: Router, private _userService: UserService, public _EventBus: EventBus) {
        const UserModel = this._userService.decodeToken();
        this.SelectedLocationId = parseInt(this._userService.GetSelectedLocation(), null);
        // user data from token
        if (UserModel && UserModel.user) {
            this.ShowPageLoader = true;
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.AgencyLogo = UserModel.user.AgencyLogo;
            this.AgencyName = UserModel.user.AgencyName;
            this.AgencyData.AgencyName = this.AgencyName;
            this.AgencyId = UserModel.user.AgencyId;
            this.LoggedInUserId = UserModel.user.UserId;
        }
        this.EditShow = true;
        // get agency logo and address
        this.GetAgencyLogoAddress();
        this.extens = ['jpg', 'png', 'jpeg', 'gif'];
        const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
        const passwordConfirm = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));
        // change password form
        this._signupForm = this._formBuilder.group({
            oldpassword: ['', Validators.required],
            password: password,
            confirmpassword: passwordConfirm
        });
        this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
    }
    // change password value change listner
    public onValueChanged(data?: any) {
        if (!this._signupForm) { return; }
        const form = this._signupForm;
        for (const field in this._formErrors) {
            if (this._formErrors.hasOwnProperty(field)) {
                const control = form.get(field);
                if (control && control.dirty) {
                    this._formErrors[field].valid = true;
                    this._formErrors[field].message = '';
                }
            }
        }
    }
    /// get agency logo and address definition
    public GetAgencyLogoAddress() {
        this.ShowPageLoader = true;
        this._userService.GetAgencyLogoAndAddress(this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.MyAgencyLogo = result.Data.AgencyLogo;
                        this.AgencyPhone = result.Data.Phone;
                        this.AgencyAddress = result.Data.Address;
                        this.ShowPageLoader = false;
                    } else {
                        this.ShowPageLoader = false;
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this.ShowPageLoader = false;
                        this._router.navigate(['/login']);
                    }
                }
            );
    }
    //  Change password validate field validation error and message
    public _isValid(field): boolean {
        let isValid: boolean;
        if (this._signupForm.controls[field].touched === false) {
            isValid = true;
        } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
            isValid = true;
        }
        return isValid;
    }
    // set form error messages change password
    private _setFormErrors(errorFields: any): void {
        for (const key in errorFields) {
            if (!errorFields.hasOwnProperty(key)) { continue; }
            const message = errorFields[key];
            this._formErrors[key].valid = false;
            this._formErrors[key].message = message;
        }
    }
    // reset form error messages change password
    private _resetFormErrors(): void {
        this._formErrors = {
            oldpassword: { valid: true, message: '' },
            password: { valid: true, message: '' },
            confirmpassword: { valid: true, message: '' }
        };
    }
    // password and confirm password match
    CheckConform(Pass, ConfirmPass) {
        if (ConfirmPass.length > 0) {
            this.IsMatch = true;
            if (Pass !== ConfirmPass) {
                this.IsMatch = true;
            } else {
                this.IsMatch = false;
            }
        } else {
            this.IsMatch = false;
        }
    }
    // check password character validation check definition
    CheckPass(data) {
        if (data.value.length > 0) {
            if (data.value.length < 7) {
                this.IsValidLength = true;
            } else {
                this.IsValidLength = false;

                const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
                if (mediumRegex.test(data.value)) {
                    this.IsStrong = false;
                } else {
                    this.IsStrong = true;
                }
            }
        } else {
            this.IsValidLength = false;
            this.IsStrong = false;
        }
    }
    // change password submit data definition
    onPassSubmit(elementValues: any) {
        this.ShowPassLoader = true;
        const that = this;
        that._userService.ChangePassword(
            this.LoggedInUserId,
            elementValues.oldpassword,
            elementValues.password
        ).subscribe(
            result => {
                if (result.Status) {
                    that.toasting('Password changed successfully', 2000, 'red');
                    this._signupForm.reset();
                    that.ShowPassLoader = false;
                } else {
                    that.toasting(result.Message, 2000, 'red');
                    that.ShowPassLoader = false;
                }
            },
            error => {
                // Validation error
                if (error.status === 422) {
                    that._resetFormErrors();
                    that.ShowPassLoader = false;
                    const errorFields = JSON.parse(error.data.message);
                    that._setFormErrors(errorFields);
                } else {
                    that.ShowPassLoader = false;
                }
            }
        );
    }
    // get agency address detail by agency admin
    editAgencyProfile() {
        this.ShowLoader = true;
        this._userService.GetAgencyDetail(this.LoggedInUserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.AgencyData.AgencyAddress1 = result.Data[0].AddressOne;
                        const add1 = result.Data[0].AddressOne;
                        $('#search_places').val(add1);
                        this.AgencyData.AgencyAddress2 = result.Data[0].AddressTwo;
                        this.AgencyData.AgencyCity = result.Data[0].City;
                        this.AgencyData.AgencyState = result.Data[0].State;
                        this.AgencyData.AgencyZipCode = result.Data[0].Zip;
                        this.ShowLoader = false;
                    } else {

                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                        this.ShowLoader = false;
                    }
                }
            );
        this.modalActions1.emit({ action: 'modal', params: ['open'] });
    }
    // close edit profile section
    CloseEditProfile() {
        this.EditShow = true;
        this._signupForm.reset();
        this.showEditAgencyAddress = false;
    }
    // open change password modal
    changePassword() {
        this.modalActions2.emit({ action: 'modal', params: ['open'] });
    }
    // close change password modal
    ClosePassword() {
        this.modalActions2.emit({ action: 'modal', params: ['close'] });
    }
    // google auto complete address setup agency admin
    autoCompleteCallback1(selectedData: any) {
        const place = selectedData.data;
        const name = place.name;
        let stroute_flag = 0;
        let stroute = '';
        let city_flag = 0;
        let state_flag = 0;
        let zip_flag = 0;
        for (let i = 0; i < place.address_components.length; i++) {
            const addressType = place.address_components[i].types[0];
            if (this.strouteComponent[addressType]) {
                stroute_flag = 1;
                stroute = place.address_components[i][this.strouteComponent[addressType]];
            }
            if (this.cityComponent[addressType]) {
                city_flag = 1;
                this.city = place.address_components[i][this.cityComponent[addressType]];
            }
            if (this.stateComponent[addressType]) {
                state_flag = 1;
                this.state = place.address_components[i][this.stateComponent[addressType]];
            }
            if (this.postalComponent[addressType]) {
                zip_flag = 1;
                this.zipcode = place.address_components[i][this.postalComponent[addressType]];
            }
        }

        if (city_flag) {
            this.AgencyData.AgencyCity = this.city;
        } else {
            this.AgencyData.AgencyCity = '';
        }
        if (state_flag) { this.AgencyData.AgencyState = this.state; } else { this.AgencyData.AgencyState = ''; }
        if (zip_flag) { this.AgencyData.AgencyZipCode = this.zipcode; } else { this.AgencyData.AgencyZipCode = ''; }
        this.street_name = name;
        this.stroute_flag = stroute_flag;
        this.stroute = stroute;
        setTimeout(() => {
            $('#setPropAddresStreet').click();
        }, 500);
    }
    // set agency address in edit google ng4geo component
    setAddressStreet() {
        if (this.street_name !== '' || this.stroute_flag) {
            if (this.stroute_flag === 1) {
                this.AgencyData.AgencyAddress1 = this.street_name + ', ' + this.stroute;
            } else {
                this.AgencyData.AgencyAddress1 = this.street_name;
            }
            $('#search_places').val(this.AgencyData.AgencyAddress1);
        }
    }
    editAgencyAddress() {
        // this.ShowLoader = true;
        this.EditShow = false;
        this.showEditAgencyAddress = true;
        this.ShowProfileLoader = true;
        setTimeout(() => {
            $('#search_places').val(this.AgencyData.AgencyAddress1);
            this.ShowProfileLoader = false;
        }, 1000);
    }
    // update agency detail
    onSubUpdateAgencyDetailmit(elementValues: any) {
        this.ShowProfileLoader = true;
        const add1 = $('#search_places').val();
        this.ShowLoader = true;
        this._userService.UpdateAgencyDetail(
            this.AgencyId,
            this.AgencyData.AgencyName,
            add1,
            this.AgencyData.AgencyAddress2,
            this.AgencyData.AgencyCity,
            this.AgencyData.AgencyState,
            this.AgencyData.AgencyZipCode
        ).subscribe(
            result => {
                if (result.Status) {
                    this.toasting('Agency profile has been successfully updated.', 3000, 'green');
                    this.EditShow = true;
                    this.CloseEditProfile();
                    this.modalActions1.emit({ action: 'modal', params: ['close'] });
                    this._EventBus.emit({ Type: 'getAgencyData', data: { AgencyId: this.AgencyId } });
                    this.ShowProfileLoader = false;
                }
            },
            error => {
                // Validation error
                if (error.status === 422) {
                    this.ShowProfileLoader = false;
                } else {
                    this.ShowProfileLoader = false;
                }
            }
        );
    }
    // upload agency admin logo and profile
    public fileEvent(event) {
        this.ShowPageLoader = true;
        const files = event.target.files;
        const that = this;
        if (files.length > 0) {
            this.validupload = false;
            for (let i = 0; i < files.length; i++) {
                const documenttosave = new DocumentToSave();
                documenttosave.File = files[i];
                documenttosave.Name = files[i].name.split('.')[0].replace(/^.*[\\\/]/, '');
                documenttosave.Name = documenttosave.Name ? documenttosave.Name.replace(/\s/g, '') : documenttosave.Name;
                documenttosave.Extention = files[i].name.replace(/^.*\./, '');
                documenttosave.SizeInKB = files[i].size;
                documenttosave.Status = false;
                documenttosave.IsError = false;

                if (event.target.name === 'companylogo') {
                    documenttosave.FileType = 'companylogo';
                }

                if (event.target.name === 'userImage') {
                    documenttosave.FileType = 'userImage';
                }

                this.filesTosaveDB.push(documenttosave);
            }
            if (this.filesTosaveDB.length > 0) {
                this.HasFilesToUpload = true;
            }
            if (this.filesTosaveDB !== []) {
                this._userService.AwsTempraryUploadDetails()
                    .subscribe((response) => {
                        if (response.Status && response.Data) {
                            this.filesTosaveDB.forEach(function (item, index) {
                                if (that.CheckFileExtention(item.Extention)) {
                                    that.readFiles(event.target.files, 0, event.target.name);

                                    if (event.target.name === 'companylogo') {
                                        $('.companyLogoUploading').show();
                                        $('.companyLogo').removeClass('companyLogoUploaded');
                                    }

                                    if (event.target.name === 'userImage') {
                                        $('.userImageUploading').show();
                                        $('.userImage').removeClass('userImageUploaded');
                                    }

                                    if (!item.Status && !item.IsError) {
                                        const AWSService = window.AWS;
                                        // AWSService.config.accessKeyId = environment.amazon_access_key;
                                        // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                                        AWSService.config.update({
                                            region: 'us-west-2',
                                            credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                                                response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                                        });
                                        const unix = Math.round(+new Date() / 1000);
                                        item.Key = unix.toString() + '_' + item.Name;
                                        item.Progress = 0;
                                        const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                                        const params = { Key: 'images/' + item.Key, Body: item.File, ACL: 'public-read' };
                                        bucket.upload(params, function (err, data) {
                                            if (data !== undefined) {
                                                item.Status = true;
                                                item.Location = data.Location;
                                                that.validupload = true;
                                                that.filesTosaveDB.forEach(function (x) {
                                                    if (x.FileType === 'companylogo') {
                                                        that.CompanyLogoKey = x.Key;
                                                        that.CompanyLogoUrl = data.Location;
                                                        const companyLogo = that.CompanyLogoUrl;
                                                        let companyLogoThumbnail = that.CompanyLogoKey;
                                                        // AWSService = window.AWS;
                                                        // AWSService.config.accessKeyId = environment.amazon_access_key;
                                                        // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                                                        AWSService.config.update({
                                                            region: 'us-west-2',
                                                            credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                                                                response.Data.Credentials.SecretAccessKey,
                                                                response.Data.Credentials.SessionToken)
                                                        });
                                                        const s3 = new window.AWS.S3();

                                                        const paramsCompanyThumb = {
                                                            Bucket: response.Data.amazon_thumbnail_bucket_name,
                                                            Key: 'resized-images/' + that.CompanyLogoKey
                                                        };
                                                        companyLogoThumbnail = s3.getSignedUrl('getObject', paramsCompanyThumb);

                                                        const companyProfileData: any = {
                                                            AgencyLogo: companyLogo,
                                                            AgencyThumbnail: companyLogoThumbnail,
                                                            AgencyId: that.AgencyId
                                                        };
                                                        that._userService.AgencyLogoSubmit(
                                                            companyProfileData['AgencyLogo'],
                                                            companyProfileData['AgencyThumbnail'],
                                                            companyProfileData['AgencyId']
                                                        ).subscribe(
                                                            result => {
                                                                if (result.Status) {
                                                                    that.ShowPageLoader = false;
                                                                    that.toasting('Agency Logo updated successfully!', 2000, 'green');
                                                                    that._EventBus.emit({ Type: 'Get-Agency-Details', data: {} });
                                                                }
                                                            },
                                                            error => {
                                                                // Validation error
                                                                if (error.status === 422) {
                                                                    that.ShowPageLoader = false;
                                                                    that._resetFormErrors();
                                                                    const errorFields = JSON.parse(error.data.message);
                                                                    that._setFormErrors(errorFields);
                                                                }
                                                                that.ShowPageLoader = false;
                                                            }
                                                        );
                                                    }
                                                    if (!x.Status) {
                                                        that.validupload = false;
                                                    }
                                                });
                                            } else if (err !== undefined) {
                                                item.Status = false;
                                                item.IsError = true;
                                                that.validupload = true;
                                                that.filesTosaveDB.forEach(function (y) {
                                                    y.Key = y.Key;
                                                    if (!y.Status) {
                                                        that.validupload = false;
                                                    }
                                                });
                                            }
                                        }).on('httpUploadProgress', function (progress) {
                                            if (Math.round(progress.loaded / progress.total * 100).toString() === '100') {
                                                if (event.target.name === 'companylogo') {
                                                    setTimeout(() => {
                                                        $('.companyLogoUploading').hide();
                                                        $('.companyLogo').addClass('companyLogoUploaded');
                                                        $('.deleteCompanyLogoIcon').show();
                                                        that.CompanyLogoFilled = 1;
                                                    }, 400);
                                                }
                                                if (event.target.name === 'userImage') {
                                                    setTimeout(() => {
                                                        $('.userImageUploading').hide();
                                                        $('.userImage').addClass('userImageUploaded');
                                                        $('.deleteUserImageIcon').show();
                                                        that.UserImageFilled = 1;
                                                    }, 400);
                                                }
                                            }
                                            that.UpdateProgress(progress.key, Math.round(progress.loaded / progress.total * 100).toString());
                                        });
                                    }
                                } else {
                                    that.filesTosaveDB.splice(index, 1);
                                    that.toasting(item.Name + '.' + item.Extention + ' is not allowed!', 3000, 'red');
                                    if (event.target.name === 'companylogo') {
                                        that.CompanyLogoFilled = 0;
                                    }
                                    if (event.target.name === 'userImage') {
                                        that.UserImageFilled = 1;
                                    }
                                }
                            });
                        }
                    }, (error) => {
                      this.validupload = false;
                      this.toasting('Server Issue. Try again after sometime', 2000, 'red');
                    });
            } else { this.validupload = false; }
        }
    }
    // check file extension
    public CheckFileExtention(extention) {
        const exten = (extention) ? extention.toLowerCase() : '';
        if (this.extens.indexOf(exten) > -1) {
            return true;
        } else {
            return false;
        }
    }
    // show success/error message toast
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // read file and convert as url
    readFile(file: any, reader: any, callback: any) {
        // Set a callback funtion to fire after the file is fully loaded
        reader.onload = () => {
            // callback with the results
            callback(reader.result);
        };
        // Read the file
        reader.readAsDataURL(file);
    }
    // read multiple files
    readFiles(files: any, index = 0, fileInputName) {
        // Create the file reader
        const reader = new FileReader();
        // If there is a file
        if (index in files) {
            // Start reading this file
            this.readFile(files[index], reader, (result: any) => {
                // After the callback fires do:
                // if companyLogo selected
                if (fileInputName === 'companylogo') {
                    this.selectedCompanyLogo = true;
                    this.file_srcs_companyLogo = result;
                }
                // if userImage selected
                if (fileInputName === 'userImage') {
                    this.selectedUserLogo = true;
                    this.file_srcs_userImage = result;
                    setTimeout(function () {
                        $('.deleteUserImageIcon').hide();
                    }, 20);
                }
                this.readFiles(files, index + 1, fileInputName); // Read the next file;
            });
        } else {
            // When all files are done This forces a change detection
            this.changeDetectorRef.detectChanges();
        }
    }
    // show file upload status percentage
    public UpdateProgress(key, progress) {
        this.filesTosaveDB.forEach(function (item) {
            if (item.Key === key) {
                item.Progress = progress;
                return;
            }
        });
    }
}
