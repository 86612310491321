import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EventBus } from '../shared/EventBus/EventBus';

@Injectable()
export class UserService {
    private loggedIn = false;
    public redirectURL = '';
    public jwtHelper = new JwtHelperService();
    constructor(private titleService: Title, private location: Location,
        private _router: Router,
        private http: HttpClient,
        private eventbus: EventBus,
        private _http: Http) {
    }

    public login(email, password, AgencyId, SubDomain, IpAddress, LocationId) {
        return this._http
            .post(
                environment.api_url + '/account/Login',
                {
                    'LoginId': email,
                    'Password': password,
                    'AgencyId': AgencyId,
                    'SubDomain': SubDomain,
                    'IpAddress': IpAddress,
                    'LocationId': LocationId
                }
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    localStorage.setItem('frontend-token', response.Data.token);
                    this.loggedIn = true;
                } else {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public RegisterAgencyMatrix(AgencyName, OwnerFirstName, OwnerLastName, Email, Password,
        TokenId, StripePlanId, PlanName, PlanTypeId, PromoCode, DomainName,
        PapCustomData, ProcessingFee, TrialDays, AgencyStatus?: number, Phone = '', CountryCode = '',
        Address1 = '', City = '', State = '', ZipCode = '', Address1latitude = '', Address1longitude = '',
        Address1PlaceId = '') {
        return this._http.post(
            environment.api_url + '/account/RegisterAgencyMatrix',
            {
                'AgencyName': AgencyName,
                'OwnerFirstName': OwnerFirstName,
                'OwnerLastName': OwnerLastName,
                'Email': Email,
                'Phone': Phone,
                'PlanId': StripePlanId,
                'PlanTypeId': PlanTypeId,
                'PlanName': PlanName,
                'Password': Password,
                'TokenId': TokenId,
                'PromoCode': PromoCode,
                'DomainName': DomainName,
                'PapCustom': AgencyName,
                'ProcessingFee': ProcessingFee,
                'TrialDays': TrialDays,
                'AgencyStatus': AgencyStatus,
                'CountryCode': '',
                'Address1': Address1,
                'City': City,
                'State': State,
                'ZipCode': ZipCode,
                'Address1latitude': Address1latitude,
                'Address1longitude': Address1longitude,
                'Address1PlaceId': Address1PlaceId
            }
        ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    localStorage.setItem('frontend-token', response.Data.token);
                    this.loggedIn = true;
                } else {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public RegisterAgency(AgencyName, OwnerFirstName, OwnerLastName, Email, Password,
        TokenId, StripePlanId, PlanName, PlanTypeId, PromoCode, DomainName,
        PapCustomData, ProcessingFee, TrialDays, AgencyStatus?: number, Phone = '',
        CountryCode = '', Address1 = '', City = '', State = '', ZipCode = '', Address1latitude = '',
        Address1longitude = '', Address1PlaceId = '') {
        return this._http.post(
            environment.api_url + '/account/RegisterAgency',
            {
                'AgencyName': AgencyName,
                'OwnerFirstName': OwnerFirstName,
                'OwnerLastName': OwnerLastName,
                'Email': Email,
                'Phone': Phone,
                'PlanId': StripePlanId,
                'PlanTypeId': PlanTypeId,
                'PlanName': PlanName,
                'Password': Password,
                'TokenId': TokenId,
                'PromoCode': PromoCode,
                'DomainName': DomainName,
                'PapCustom': AgencyName,
                'ProcessingFee': ProcessingFee,
                'TrialDays': TrialDays,
                'AgencyStatus': AgencyStatus,
                'CountryCode': CountryCode,
                'Address1': Address1,
                'City': City,
                'State': State,
                'ZipCode': ZipCode,
                'Address1latitude': Address1latitude,
                'Address1longitude': Address1longitude,
                'Address1PlaceId': Address1PlaceId
            }
        ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    localStorage.setItem('frontend-token', response.Data.token);
                    this.loggedIn = true;
                } else {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public CheckPromoCode(PromoCode) {
        return this._http.post(
            environment.api_url + '/account/CheckPromoCode',
            {
                'PromoCode': PromoCode
            }
        ).map(response => response.json())
            .map((response) => {
                if (response.Status) {

                } else {

                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetCountDownTime() {
        return this._http.post(
            environment.api_url + '/subscription/GetSpecialOfferTime',
            {}
        ).map(response => response.json())
            .map((response) => {
                if (response.Status) {

                } else {

                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public UpdateCountDownTime(datestring) {
        return this._http.post(
            environment.api_url + '/subscription/UpdateOfferTime',
            {
                'DateString': datestring
            }
        ).map(response => response.json())
            .map((response) => {
                if (response.Status) {

                } else {

                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public Register(FirstName, LastName, email, selectedcountrycode, phone, password, refreralcode, agencyid,
        selectedlocationid, AgencyToken) {
        return this._http
            .post(
                environment.api_url + '/account/Register',
                {
                    'LastName': LastName,
                    'FirstName': FirstName,
                    'Email': email,
                    'SelectedCountryCode': selectedcountrycode,
                    'Phone': phone,
                    'Password': password,
                    'Picture': '',
                    'RefreralCode': refreralcode,
                    'AgencyId': agencyid,
                    'LocationId': selectedlocationid,
                    'AgencyTokenId': AgencyToken
                }
            )
            .map(response => response.json())
            .map((response) => {

                if (response.Status) {

                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    localStorage.setItem('frontend-token', response.Data.token);
                    this.loggedIn = true;
                } else {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public RegisterAgent(FirstName, LastName, email, selectedcountrycode, phone, password, agencyid, selectedlocationid, tokenId) {

        return this._http
            .post(
                environment.api_url + '/account/RegisterAgent',
                {
                    'FirstName': FirstName,
                    'LastName': LastName,
                    'Email': email,
                    'SelectedCountryCode': selectedcountrycode,
                    'Phone': phone,
                    'Password': password,
                    'AgencyId': agencyid,
                    'LocationId': selectedlocationid,
                    'TokenId': tokenId

                }
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    localStorage.setItem('frontend-token', response.Data.token);
                    this.loggedIn = true;
                } else {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgencyLocations() {
        return this._http
            .post(

                environment.api_url + '/agency/GetAgencyLocations',
                {}

            )
            .map(response => response.json())
            .map((response) => {
                // if (response.Status) {
                //     localStorage.removeItem('frontend-token');
                //     localStorage.setItem('frontend-token', response.Data.token);
                //     this.loggedIn = true;
                // } else {
                //     localStorage.removeItem('frontend-token');
                //     this.loggedIn = false;
                // }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    // public ForgetPassword(email, DomainName, SubDomainName) {
    public ForgetPassword(email) {
        return this._http
            .post(
                environment.api_url + '/account/ForgetPassword',
                {
                    'Email': email
                }
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ChangePassword(UserId, OldPass, NewPass) {
        return this._http
            .post(
                environment.api_url + '/account/ChangePassword',
                {
                    'UserId': UserId,
                    'OldPassword': OldPass,
                    'NewPassword': NewPass,
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public UpdateProfile(UserId, FirstName, LastName, Email, CountryCode, Phone, Picture, Description) {
        return this._http
            .post(
                environment.api_url + '/account/UpdateProfile',
                {
                    'UserId': UserId,
                    'FirstName': FirstName,
                    'LastName': LastName,
                    'Email': Email,
                    'CountryCode': CountryCode,
                    'Phone': Phone,
                    'Picture': Picture,
                    'Description': Description
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public logout(): void {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this.loggedIn = false;
    }

    public getAllForUsers(RoleId, OffSet, AgencyId, LocationId, UserNeeded) {
        return this._http
            .post(
                environment.api_url + '/User/ListAllForUser',
                {
                    'RoleId': RoleId,
                    'OffSet': OffSet,
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'UserNeeded': UserNeeded
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public GetAllDeletedUser(RoleId, OffSet, AgencyId, LocationId, UserNeeded) {
        return this._http
            .post(
                environment.api_url + '/User/ListAllDeletedUser',
                {
                    'RoleId': RoleId,
                    'OffSet': OffSet,
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'UserNeeded': UserNeeded
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAll(RoleId, OffSet, AgencyId, LocationId, UserNeeded, Search, SortKey, SortOrder) {
        return this._http
            .post(
                environment.api_url + '/User/ListAll',
                {
                    'RoleId': RoleId,
                    'OffSet': OffSet,
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'UserNeeded': UserNeeded,
                    'Search': Search,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public GetAgencyNewUsers(LoggedInUserRoleId, LoadMoreCounter, Startdate, Enddate, AgencyId) {

        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyNewUsers',
                {
                    'AgencyId': AgencyId,
                    'StartDate': Startdate,
                    'EndDate': Enddate,
                    'LocationId': LoggedInUserRoleId

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public GetAgencyCallData(LoggedInUserRoleId, LoadMoreCounter, Startdate, Enddate, AgencyId) {

        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyCallData',
                {
                    'AgencyId': AgencyId,
                    'StartDate': Startdate,
                    'EndDate': Enddate,
                    'LocationId': LoggedInUserRoleId

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgencyDashSignDocData(LoggedInUserRoleId, LoadMoreCounter, Startdate, Enddate, AgencyId) {

        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyDashSignDocData',
                {
                    'AgencyId': AgencyId,
                    'StartDate': Startdate,
                    'EndDate': Enddate,
                    'LocationId': LoggedInUserRoleId

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgencyDashPaymentData(LoggedInUserRoleId, LoadMoreCounter, Startdate, Enddate, AgencyId) {

        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyDashPaymentData',
                {
                    'AgencyId': AgencyId,
                    'StartDate': Startdate,
                    'EndDate': Enddate,
                    'LocationId': LoggedInUserRoleId

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getproducts(AgencyId, RequestFromAdmin) {
        return this._http
            .post(
                environment.api_url + '/product/GetProducts',
                {
                    'AgencyId': AgencyId,
                    'RequestFromAdmin': RequestFromAdmin
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ProductDetail(productid) {
        return this._http
            .post(
                environment.api_url + '/product/productdetail',
                {
                    'ProductId': productid
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    // public GetPlanDetail(StripePlanId) {
    //     return this._http
    //         .post(
    //             environment.api_url + '/subscription/PlanList',
    //             {
    //                 'StripePlanId': StripePlanId
    //             }
    //     ).map(response => response.json())
    //         .map((response) => {
    //             if (response.Status) {
    //             } else {
    //             }
    //             return response;
    //         })
    //         .catch((err) => {return this.handleError(err)});
    // }

    public getdashdata(UserId) {
        return this._http
            .post(
                environment.api_url + '/User/GetUserdashData',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAllRef(UserId, Offset, SortKey, SortOrder) {
        return this._http
            .post(
                environment.api_url + '/referral/GetReferralByUserId',
                {
                    'UserId': UserId,
                    'OffSet': Offset,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }
    public getAllRefForAgency(AgencyId, Offset, SortKey, SortOrder, LocationId) {
        return this._http
            .post(
                environment.api_url + '/referral/GetReferralByAgencyId',
                {
                    'AgencyId': AgencyId,
                    'OffSet': Offset,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder,
                    'LocationId': LocationId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ApprovClaim(AgencyId, ReferralId, BankAccountId, Amount) {
        return this._http
            .post(
                environment.api_url + '/referral/ApprovClaim',
                {
                    'AgencyId': AgencyId,
                    'ReferralId': ReferralId,
                    'BankAccountId': BankAccountId,
                    'Amount': Amount
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch(this.handleError);
    }


    public ClaimReferral(ReferralIds, BankAccountId, StripeCustomerId, TransferType) {
        return this._http
            .post(
                environment.api_url + '/referral/ClaimReferral',
                {
                    'ReferralIds': String(ReferralIds),
                    'BankAccountId': BankAccountId,
                    'StripeCustomerId': StripeCustomerId,
                    'TransferType': TransferType
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAllPendingRef(UserId, Offset) {
        return this._http
            .post(
                environment.api_url + '/Referral/GetPendingReferralByUserId',
                {
                    'UserId': UserId,
                    'OffSet': Offset
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public userDetail(ResponderId, UserId) {
        return this._http
            .post(
                environment.api_url + '/user/detail',
                {
                    'ResponderId': ResponderId,
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public GetAgencyReferalSettingDetail(AgencyId) {

        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyReferalSettingDetail',
                {
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAgencylogo(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/account/GetAgencyLogo',
                {
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public getuserprofile(UserId) {
        return this._http
            .post(
                environment.api_url + '/account/EditProfileDetail',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public GetUserLogoAndAddress(UserId) {
        return this._http
            .post(
                environment.api_url + '/account/GetUserLogoAndAddress',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgencyLogoAndAddress(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/account/GetAgencyLogoAndAddress',
                {
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public GetAgencyConversations(AgencyID, Offset, LocationId) {
        return this._http.post(
            environment.api_url + '/agency/GetAgencyConversations', {
                'AgencyId': AgencyID,
                'Offset': Offset,
                'LocationId': LocationId
            }, this.GetHeaders()

        ).map(response => response.json()).map((response) => {
            if (response.Status) {
                // localStorage.setItem('frontend-token', response.Data.token);
                // this.loggedIn = true;
            } else {
                // localStorage.removeItem('frontend-token');
                // this.loggedIn = false;
            }
            return response;
        }).catch((err) => this.handleError(err));
    }




    public GetAgentConversations(AgentId, Offset, LocationId) {
        return this._http.post(
            environment.api_url + '/agency/GetAgentConversations', {
                'AgentId': AgentId,
                'Offset': Offset,
                'LocationId': LocationId
            }, this.GetHeaders()

        ).map(response => response.json()).map((response) => {
            if (response.Status) {
                // localStorage.setItem('frontend-token', response.Data.token);
                // this.loggedIn = true;
            } else {
                // localStorage.removeItem('frontend-token');
                // this.loggedIn = false;
            }
            return response;
        }).catch((err) => this.handleError(err));
    }


    public GetOccurType() {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyReoccurType',
                {},
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));

    }

    public GetCancelReason() {
        return this._http
            .post(
                environment.api_url + '/agency/GetCancelReason',
                {},
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));

    }

    public GetReferlAMountType() {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyAmountType',
                {},
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));

    }

    public GetSiteAddress(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetSiteAddress',
                { 'AgencyId': AgencyId }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));

    }

    public SaveAgencyReferralSettings(AgencyId, SettingName, ReferalTypeID, ReoccureTypeId, ReferalAmountType,
        AmoutValue, IsDefault, IsExist) {

        return this._http
            .post(
                // environment.api_url + '/agency/SaveAgencyReferralSettings',
                environment.api_url + '/agency/SaveAgencyReferralSettings',
                {
                    'AgencyId': AgencyId,
                    'SettingName': SettingName,
                    'ReferalTypeID': ReferalTypeID,
                    'ReoccureTypeId': ReoccureTypeId,
                    'ReferalAmountType': ReferalAmountType,
                    'AmoutValue': AmoutValue,
                    'IsDefault': IsDefault,
                    'IsExist': IsExist,
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {

                } else {
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public DeleteReferralSettings(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/DeleteAgencyReferralSettings',
                {
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {

                } else {
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public getAgencyCallsDetails(AgencyId, LocationId, Offset, Search, SortKey, SortOrder) {
        return this._http.post(
            environment.api_url + '/agency/GetAgencyCallDetails',
            {
                'AgencyId': AgencyId,
                'Offset': Offset,
                'LocationId': LocationId,
                'Search': Search,
                'SortKey': SortKey,
                'SortOrder': SortOrder
            }, this.GetHeaders()
        ).map(response => response.json()).map((response) => {
            if (response.Status) {
                // localStorage.setItem('frontend-token', response.Data.token);
                // this.loggedIn = true;
            } else {
                // localStorage.removeItem('frontend-token');
                // this.loggedIn = false;
            }
            return response;
        }).catch((err) => this.handleError(err));
    }

    public getAgentCallsDetails(AgentId, LocationId, Offset, Search, SortKey, SortOrder) {
        return this._http.post(
            environment.api_url + '/agency/GetAgentCallDetails',
            {
                'AgentId': AgentId,
                'Offset': Offset,
                'LocationId': LocationId,
                'Search': Search,
                'SortKey': SortKey,
                'SortOrder': SortOrder
            }, this.GetHeaders()
        ).map(response => response.json()).map((response) => {
            if (response.Status) {
                // localStorage.setItem('frontend-token', response.Data.token);
                // this.loggedIn = true;
            } else {
                // localStorage.removeItem('frontend-token');
                // this.loggedIn = false;
            }
            return response;
        }).catch((err) => this.handleError(err));
    }

    public getCallsDetails(UserId, OffSet, SortKey, SortOrder) {
        return this._http
            .post(
                environment.api_url + '/tokbox/GetCallDetails',
                {
                    'UserId': UserId,
                    'OffSet': OffSet,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getChatHistory(SenderId, ReceiverId, OffSet) {
        return this._http
            .post(
                environment.api_url + '/tokbox/GetMessage',
                {
                    'SenderId': SenderId,
                    'ReceiverId': ReceiverId,
                    'Offset': OffSet
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getfolders(UserId) {
        return this._http
            .post(
                environment.api_url + '/document/getfolders',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getDocuments(UserId, FolderId, SortKey, SortOrder, Offset) {
        // if (FolderId !== 12) {
        return this._http
            .post(
                environment.api_url + '/document/GetDocuments',
                {
                    'UserId': UserId,
                    'FolderId': FolderId,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder,
                    'Offset': Offset,
                    // 'IsWeb' : 1
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    if (response.Data) {
                        // response.Data;
                    }
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false ;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
        // } else {
        //     return this._http
        //         .post(
        //             environment.api_url + '/document/GetUnsignedDocuments',
        //             {
        //                 'UserId': UserId,
        //                 'IsSigned': true,
        //                 'SortKey': SortKey,
        //                 'SortOrder': SortOrder,
        //                 'Offset': Offset
        //             }, this.GetHeaders()
        //         ).map(response => response.json())
        //         .map((response) => {
        //             if (response.Status) {
        //                 // localStorage.setItem('frontend-token', response.Data.token);
        //                 // this.loggedIn = true;
        //             } else {
        //                 // localStorage.removeItem('frontend-token');
        //                 // this.loggedIn = false ;
        //             }
        //             return response;
        //         })
        //         .catch((err) => this.handleError(err));
        // }
    }

    public getAnnotations(DocId) {
        return this._http
            .post(
                environment.api_url + '/PDF/GetAnnotation',
                {
                    'DocumentId': DocId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false ;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getUnsignedDocuments(UserId, IsSigned, SortKey, SortOrder, Offset) {
        return this._http
            .post(
                environment.api_url + '/document/GetUnsignedDocuments',
                {
                    'UserId': UserId,
                    'IsSigned': IsSigned,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder,
                    'Offset': Offset
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false ;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getUnsignedDocumentsUsers(AgencyId, LocationId, SortKey, SortOrder, Offset, Search) {
        return this._http
            .post(
                environment.api_url + '/document/getunsigneddocumentsusers',
                {
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder,
                    'Offset': Offset,
                    'Search': Search
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public uploadDoc(AgencyId, LocationId, key, name, extention, sizeInkb, folderId, documentUrl,
        ownerUserId, UploadedbyUserId, SignatureNotes = '') {
        return this._http
            .post(
                environment.api_url + '/document/UploadDocument',
                {
                    'Key': key,
                    'Name': name,
                    'Extention': extention,
                    'SizeInKB': sizeInkb,
                    'FolderId': folderId,
                    'DocumentUrl': documentUrl,
                    'OwnerUserId': ownerUserId,
                    'Notes': SignatureNotes,
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'UploadByUserId': UploadedbyUserId
                }, this.GetHeaders()
            ).map(response => response.json()).map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public makeFileRequest(formData) {
        return this._http
            .post(
                environment.api_url + '/PDF/UploadImageOnServer',
                formData, this.GetHeaders()
            ).map(response => response.json()).map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public SaveAnnotaion(DocumentAnnotation, DocumentId, AgentId, UserId, IpAddress, BrowserInfo, IsAgent, LocationId) {
        IpAddress = (IpAddress) ? IpAddress : '0.0.0.0';
        return this._http
            .post(
                environment.api_url + '/PDF/SaveAnnotation',
                {
                    'DocumentAnnotation': DocumentAnnotation,
                    'DocumentId': DocumentId,
                    'AgentId': AgentId,
                    'UserId': UserId,
                    'IpAddress': IpAddress,
                    'BrowserInfo': BrowserInfo,
                    'IsAgent': IsAgent,
                    'LocationId': LocationId
                }, this.GetHeaders()
            ).map(response => response.json()).map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public SaveUserSignature(DocumentId, PdfUrl, IpAddress, DeviceInfo) {
        return this._http
            .post(
                environment.api_url + '/PDF/SaveUserSignature',
                {
                    'DocumentId': DocumentId,
                    'PDFUrl': PdfUrl,
                    'IpAddress': IpAddress,
                    'DeviceInfo': DeviceInfo,
                    'IsMobile': false
                }, this.GetHeaders()
            ).map(response => response.json()).map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public deleteDocument(key) {
        return this._http
            .post(
                environment.api_url + '/document/DeleteDocument',
                {
                    'Key': key
                }, this.GetHeaders()
            ).map(response => response.json()).map((response) => {
                if (response.Status) {
                } else {
                }
                return response;
            }).catch((err) => this.handleError(err));
    }

    public AddPayMethod(UserId,
        StripeCustomerId,
        TokenId,
        AgencyId
    ) {
        return this._http
            .post(
                environment.api_url + '/payment/CreateCustomerCard',
                {
                    'UserId': UserId,
                    'AgencyId': AgencyId,
                    'StripeCustomerId': StripeCustomerId,
                    'TokenId': TokenId,

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public MakePayment(UserId, Amount, StripeCardId, StripeCustomerId,
        ProductName, AgencyId, LocationId, Email, UserName, AgencyName, AgencyEmail) {
        return this._http
            .post(
                environment.api_url + '/payment/CreateCharge',
                {
                    'UserId': UserId,
                    'Amount': Amount,
                    'StripeCardId': StripeCardId,
                    'StripeCustomerId': StripeCustomerId,
                    'ProductName': ProductName,
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'Email': Email,
                    'UserName': UserName,
                    'AgencyName': AgencyName,
                    'AgencyEmail': AgencyEmail
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public AddBankAccount(UserId, StripeCustomerId, TokenId, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/payment/CreateCustomerBankAccount',
                {
                    'UserId': UserId,
                    'StripeCustomerId': StripeCustomerId,
                    'AgencyId': AgencyId,
                    'TokenId': TokenId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public VerifyBankAccount(StripeCustomerId, StripeBankAccountId, FirstValue, SecondValue, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/payment/VerifyBankAccount',
                {
                    'AgencyId': AgencyId,
                    'StripeCustomerId': StripeCustomerId,
                    'StripeBankAccountId': StripeBankAccountId,
                    'FirstValue': FirstValue,
                    'SecondValue': SecondValue
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAllPaymentMethod(UserId) {
        return this._http
            .post(
                environment.api_url + '/payment/GetCustomerCard',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAgencyCardDetail(StripeCustomerId, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/payment/getagencycustomercarddetail',
                {
                    'StripeCustomerId': StripeCustomerId,
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAllBankAccount(UserId, IsVerified) {
        return this._http
            .post(
                environment.api_url + '/payment/GetCustomerBankAccount',
                {
                    'UserId': UserId,
                    'IsVerified': IsVerified
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetUserPayments(UserId, LocationId, AgencyId, Offset, Search, SortKey, SortOrder) {
        return this._http
            .post(
                environment.api_url + '/payment/GetUserPayment',
                {
                    'UserId': UserId,
                    'LocationId': LocationId,
                    'AgencyId': AgencyId,
                    'Offset': Offset,
                    'Search': Search,
                    'SortKey': SortKey,
                    'SortOrder': SortOrder
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public RemovePayMethod(StripeCustomerId, StripeCardId, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/payment/RemoveCard',
                {
                    'StripeCustomerId': StripeCustomerId,
                    'AgencyId': AgencyId,
                    'StripeCardId': StripeCardId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public RemoveBankAccount(StripeCustomerId, StripeBankAccountId, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/payment/RemoveBankAccount',
                {
                    'StripeCustomerId': StripeCustomerId,
                    'AgencyId': AgencyId,
                    'StripeBankAccountId': StripeBankAccountId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getToken(): any {
        return localStorage.getItem('frontend-token');
    }

    public decodeToken(): any {
        const token = localStorage.getItem('frontend-token');
        if (token) {
            return this.jwtHelper.decodeToken(token);
        } else {
            return [];
        }
    }

    public getCustId(): any {
        return localStorage.getItem('StripeCust');
    }

    public GetSelectedLocation(): any {
        const SelectedLocationId = localStorage.getItem('SelectedLocation');
        if (SelectedLocationId) {
            return SelectedLocationId;
        } else { return '0'; }
    }

    public checkToken(): any {
        return !!localStorage.getItem('frontend-token');
    }

    public unauthorizedAccess(error: any): void {
        this.logout();
        this._router.navigate(['/login']);
    }

    public isLoggedIn(): boolean {
        return this.jwtHelper.isTokenExpired(localStorage.getItem('frontend-token'));
    }

    public GetHeaders(): RequestOptions {
        const headers = new Headers({ 'Authorization': ' ' + this.getToken() });
        return new RequestOptions({ headers: headers });
    }

    public GetAgencyMatrixHeaders(): RequestOptions {
        // const Token = localStorage.getItem('agencymatrixtoken');
        const Token = '';
        const headers = new Headers(
            {
                'Authorization': 'Bearer ' + Token,
                'Content-Type': 'application/json'
            });
        return new RequestOptions({ headers: headers });
    }

    public RedirectAccToRole(RoleId) {
        if (parseInt(RoleId, null) === 1) {
            this._router.navigate(['login']);
        } else if (parseInt(RoleId, null) === 2) {
            this._router.navigate(['agent/dashboard']);
        } else if (parseInt(RoleId, null) === 6) {
            this._router.navigate(['agency/dashboard']);
        } else if (parseInt(RoleId, null) === 3) {
            this._router.navigate(['user/dashboard']);
        } else if (parseInt(RoleId, null) === 7) {
            this._router.navigate(['/']);
        }
    }

    public getSubdomain(): any {
        const domain = window.location.hostname;
        if (domain.indexOf('.') < 0 ||
            domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
            // if (domain.indexOf('.') < 0 ||
            //     domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh') { // on request of Punnet Sir
            return '';
        } else {
            return domain.split('.')[0];
        }
        // console.log('subdomain', domain.split('.')[0]);
    }

    public getDomain(): any {
        const domain = window.location.hostname;
        if (domain.indexOf('.') < 0 ||
            domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
            return '';
        } else {
            return domain.split('.')[1] + '.' + domain.split('.')[2];
        }
        // console.log('subdomain', domain.split('.')[0]);
    }

    public IsLocalHost(): boolean {
        const hostname = location.toString();
        if (hostname.indexOf('localhost') !== -1) { return true; } else { return false; }
    }

    // public GetCompanyDetail() {
    //     let hostname = location.toString();
    //     console.log(hostname);
    //     if (hostname.indexOf('localhost') === -1) {
    //         if (hostname.indexOf('login') !== -1) {
    //             console.log('Static url in env ' + environment.domain_name);
    //             hostname = hostname.replace(environment.domain_name + 'login', '');
    //             console.log(hostname);
    //             hostname = hostname.replace('https://', '');
    //             console.log(hostname);
    //         } else if (hostname.indexOf('signup') !== -1) {
    //             console.log('Static url in env ' + environment.domain_name);
    //             hostname = hostname.replace(environment.domain_name + 'signup', '');
    //             console.log(hostname);
    //             hostname = hostname.replace('https://', '');
    //             console.log(hostname);
    //         } else if (hostname.indexOf('agent/register') !== -1) {
    //             console.log(hostname);
    //             console.log('Static url in env ' + environment.domain_name);
    //             hostname = hostname.replace(environment.domain_name + 'agent/register?AgentId=462', '');
    //             console.log(hostname);
    //             hostname = hostname.replace('https://', '');
    //             console.log(hostname);
    //         } else {
    //             console.log(hostname);
    //             console.log('Static url in env ' + environment.domain_name);
    //             hostname = hostname.replace(environment.domain_name, '');
    //             console.log(hostname);
    //             hostname = hostname.replace('https://', '');
    //             console.log(hostname);
    //         }
    //         console.log(hostname);
    //         return hostname;
    //     } else {
    //         return '';
    //     }
    // }


    public AgencyStepDetail(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/AgencyStepDetail',
                {
                    'AgencyId': AgencyId

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public AddAgencyInCampaign(UserId: number, UserFirstName, UserLastName, Email, Phone, Role, Plan,
        CompanyName, SiteAddress, DomainName, ListID?: number) {
        return this._http
            .post(
                environment.api_url + '/agency/AddContactInActiveCampaign',
                {
                    'UserFirstName': UserFirstName,
                    'UserLastName': UserLastName,
                    'Email': Email,
                    'Phone': Phone,
                    'Role': Role,
                    'Plan': Plan,
                    'CompanyName': CompanyName,
                    'SiteAddress': SiteAddress,
                    'DomainName': DomainName,
                    'ListID': ListID,
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgencyDetailForCampaign(UserId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyDetailForCampaign',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public GetAgencyDetail(UserId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyDetail',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }





    public StripeAuth(UniqueCode, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/payment/StripeAuth',
                {
                    'AgencyId': AgencyId,
                    'UniqueCode': UniqueCode
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public companyProfileSubmit(SiteAddress, AgencyLogo, AgencyThumbnail, UserPicture, UserThumbnail, AgencyId, Domain, LocationId) {

        return this._http
            .post(
                environment.api_url + '/agency/UpdateAgencyProfile',
                {
                    'SiteAddress': SiteAddress,
                    'AgencyLogo': AgencyLogo,
                    'AgencyThumbnail': AgencyThumbnail,
                    'UserPicture': UserPicture,
                    'UserThumbnail': UserThumbnail,
                    'AgencyId': AgencyId,
                    'DomainName': Domain,
                    'LocationId': LocationId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public AgencyLogoSubmit(AgencyLogo, AgencyThumbnail, AgencyId) {

        return this._http
            .post(
                environment.api_url + '/agency/UpdateAgencyLogo',
                {
                    'AgencyId': AgencyId,
                    'AgencyLogo': AgencyLogo,
                    'AgencyThumbnail': AgencyThumbnail

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public UploadAgencyBanner(BannerUrl, BannerTumbnailUrl, AgencyId, LocationId) {
        return this._http
            .post(
                environment.api_url + '/agency/UpdateAgencyBanner',
                {
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'BannerUrl': BannerUrl,
                    'BannerTumbnailUrl': BannerTumbnailUrl
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {

                } else {
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ProfilePicSubmit(AgencyLogo, AgencyThumbnail, UserId) {

        return this._http
            .post(
                environment.api_url + '/agency/UpdateAgencyUserProfile',
                {
                    'UserId': UserId,
                    'ProfileLogo': AgencyLogo,
                    'ProfileThumbnail': AgencyThumbnail

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public AddOrUpdateProduct(ProductId, ProductName, ProductDescription, ProductImage, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/product/AddUpdateProduct',
                {
                    'ProductId': ProductId,
                    'ProductName': ProductName,
                    'ProductDescription': ProductDescription,
                    'ProductImage': ProductImage,
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ChaeckDomainName(agencyname, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/CheckDomain',
                {
                    'AgencyName': agencyname,
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public GetAgency(agencyname) {
        return this._http
            .post(
                environment.api_url + '/agency/getagency',
                {
                    'AgencyName': agencyname,
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public CheckAgencyReferralCode(SubDomainName, ReferlCode) {
        return this._http
            .post(
                environment.api_url + '/agency/CheckAgencyReferralCode',
                {
                    'SubDomain': SubDomainName,
                    'ReferralCode': ReferlCode
                }
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public CheckEmail(Email) {
        return this._http
            .post(
                environment.api_url + '/agency/CheckEmailExisteance',
                {
                    'Email': Email
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }




    public UpdateUser(Email, FirstName, LastName, UserId, AgencyId, Description, LocationIds) {
        return this._http
            .post(
                environment.api_url + '/agency/UpdateUser',
                {
                    'AgencyId': AgencyId,
                    'Email': Email,
                    'FirstName': FirstName,
                    'LastName': LastName,
                    'UserId': UserId,
                    'Description': Description,
                    'LocationIds': LocationIds
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public deleteUser(UserId) {
        return this._http
            .post(
                environment.api_url + '/agency/DeleteUser',
                {
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public UpdateUserAndResend(AgencyId, UserId, Token, SubDomainName, DomainName, Email, FirstName, LastName, Description, LocationIds) {

        return this._http
            .post(
                environment.api_url + '/agency/UpdateUserAndResendEmail',
                {
                    'AgencyId': AgencyId,
                    'UserId': UserId,
                    'Token': Token,
                    'SubDomainName': SubDomainName,
                    'DomainName': DomainName,
                    'Email': Email,
                    'FirstName': FirstName,
                    'LastName': LastName,
                    'Description': Description,
                    'LocationIds': LocationIds
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ResendEmail(AgencyName, Token, SubDomainName, DomainName, Email, FirstName, LastName) {
        return this._http
            .post(
                environment.api_url + '/agency/ResendEmail',
                {
                    'AgencyName': AgencyName,
                    'Token': Token,
                    'SubDomainName': SubDomainName,
                    'DomainName': DomainName,
                    'Email': Email,
                    'FirstName': FirstName,
                    'LastName': LastName,
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public setTitle(agencyname: string) {
        this.titleService.setTitle(agencyname + ' - AgentAlive');
    }

    public PaymentNeeded(): Observable<any> {
        this.eventbus.emit({ Type: 'IS_HIDE_CALL_DIV', data: true });
        this._router.navigate(['agency/subscriptioncancel']);
        // const usermodal = this.decodeToken();
        // if (usermodal.user.RoleId !== 6) {
        //     this._router.navigate(['agency/subscriptioncancel']);
        // } else {
        //     if (usermodal.user.SubscriptionPeriodEnd < Math.floor(Date.now() / 1000)) {
        //         this._router.navigate(['agency/subscriptioncancel']);
        //     }
        // }
        return Observable.empty();
    }

    private handleError(error: Response | any) {
        let errorMessage: any = {};
        // Connection error
        if (error.status === 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: 'Sorry, there was a connection error occurred. Please try again.'
            };
            return Observable.throw(errorMessage);
        } else if (error.status === 402) {
            return this.PaymentNeeded();
        } else {
            errorMessage = error.json();
            return Observable.throw(errorMessage);
        }
    }


    getIP(): Observable<any> {

        return this._http.get('https://ipinfo.io') // ...using get request
            .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
            .catch((error: any) => Observable.throw(error.json().error || 'Server error')); // ...errors if any
    }


    appcheckname(): Observable<any> {

        return this._http.get('./assets/urlcheck.json') // ...using get request
            .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
            .catch((error: any) => Observable.throw(error.json().error || 'Server error')); // ...errors if any


    }

    public InviteAgent(AgencyId, InviteData, SubDomain, LocationId, Domain, RoleId, LocationIds, RegisterInvite) {
        return this._http.post(environment.api_url + '/agency/InviteAgent', {
            'AgencyId': AgencyId,
            'AgentsDetail': InviteData,
            'SubDomainName': SubDomain,
            'LocationId': LocationId,
            'DomainName': Domain,
            'RoleId': RoleId,
            'LocationIds': LocationIds,
            'RegisterAgent': RegisterInvite
        }, this.GetHeaders()).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgentDetailFromToken(Token) {

        return this._http.post(environment.api_url + '/agency/GetAgentDetailFromToken', {
            'TokenId': Token
        }, this.GetHeaders()).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                } else {
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public GetAgentDetail(AgentId) {

        return this._http.post(environment.api_url + '/agency/GetAgentDetail', {
            'AgentId': AgentId

        }, this.GetHeaders()).map(response => response.json())
            .map((response) => {
                if (response.Status) {

                } else {
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetPlanList(PlanTypeId, PlanDureation) {
        return this._http
            .post(
                environment.api_url + '/subscription/PlanList',
                {
                    'PlanTypeId': PlanTypeId,
                    'PlanDuration': PlanDureation
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetSpecialOfferCoupneCode(key) {
        return this._http
            .post(
                environment.api_url + '/subscription/GetSpecialOfferCoupneCode',
                {
                    'SpecialOferKey': key
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetCoupneCode(CouponKey) {
        return this._http
            .post(
                environment.api_url + '/subscription/getcouponcode',
                {
                    'CouponKey': CouponKey
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetSpecialPlanPrice() {
        return this._http
            .post(
                environment.api_url + '/Agency/GetSpecialOfferPrice',
                {
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetSpecialPlanPriceActual() {
        return this._http
            .post(
                environment.api_url + '/Agency/GetSpecialOfferPriceActualValue',
                {
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetTrialDays(key: string) {
        return this._http
            .post(
                environment.api_url + '/Agency/gettrialdays',
                {
                    Key: key
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetPlanListForSpecialOffer(PlanTypeId) {
        return this._http
            .post(
                environment.api_url + '/subscription/PlanListForSpecialOffer',
                {
                    'PlanTypeId': PlanTypeId
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetPlanDetails(StripePlanId: string) {
        return this._http
            .post(
                environment.api_url + '/subscription/getplandetails',
                {
                    'StripePlanId': StripePlanId
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetUserConversations(UserId, Offset, LocationId) {
        return this._http.post(
            environment.api_url + '/agency/GetUserConversations', {
                'UserId': UserId,
                'Offset': Offset,
                'LocationId': LocationId
            }, this.GetHeaders()

        ).map(response => response.json()).map((response) => {
            if (response.Status) {
                // localStorage.setItem('frontend-token', response.Data.token);
                // this.loggedIn = true;
            } else {
                // localStorage.removeItem('frontend-token');
                // this.loggedIn = false;
            }
            return response;
        }).catch((err) => this.handleError(err));
    }
    public GetAgencyInvoices(StripeCustomerId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyInvoice',
                { 'StripeCustomerId': StripeCustomerId }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetDefaultCard(StripeCustomerId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyAdminDefaultCard',
                { 'StripeCustomerId': StripeCustomerId }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public UpdateDefaultCard(StripeCustomerId, CardToken) {
        return this._http
            .post(
                environment.api_url + '/agency/UpdateAgencyAdminDefaultCard',
                {
                    'StripeCustomerId': StripeCustomerId,
                    'CardToken': CardToken
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public AddMoreAgents(SubscriptionId, Quantity, PlanId, PlanDuration) {
        return this._http
            .post(
                environment.api_url + '/subscription/AddAgent',
                {
                    'SubscriptionId': SubscriptionId,
                    'Quantity': Quantity,
                    'PlanId': PlanId,
                    'PlanDuration': PlanDuration
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public CancelSubscription(SubscriptionId, Reason, CancelComment, Email) {
        return this._http
            .post(
                environment.api_url + '/subscription/CancelSubscription',
                {
                    'SubscriptionId': SubscriptionId,
                    'Email': Email,
                    'CancelReason': Reason,
                    'CancelComment': CancelComment
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ResumeSubscription(SubscriptionId) {
        return this._http
            .post(
                environment.api_url + '/subscription/ResumeSubscription',
                {
                    'SubscriptionId': SubscriptionId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetSubscriptionDetail(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencySubscription',
                { 'AgencyId': AgencyId }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public ReSubscribeAgencyPlan(AgencyId, LocationId, StripeCustomerId, MainPlanId, AddAgentPlanId, PlanQuantity, StripeToken) {
        if (!AddAgentPlanId) {
            AddAgentPlanId = '';
            PlanQuantity = 0;
        }
        return this._http
            .post(
                environment.api_url + '/account/ReSubscripbePlan',
                {
                    'AgencyId': AgencyId,
                    'LocationId': LocationId,
                    'StripeCustomerId': StripeCustomerId,
                    'MainPlanId': MainPlanId,
                    'AddAgentPlanId': AddAgentPlanId,
                    'PlanQuantity': PlanQuantity,
                    'StripeToken': StripeToken
                }
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public UpgradePlan(SubscriptionId, PlanId, PlanTypeId, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/subscription/UpgradePlan',
                {
                    'SubscriptionId': SubscriptionId,
                    'PlanId': PlanId,
                    'PlanTypeId': PlanTypeId,
                    'AgencyId' : AgencyId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public CheckStripeAccountStatus(AgencyID, UserId) {

        return this._http
            .post(
                environment.api_url + '/agency/CheckStripeAccountStatus',
                {
                    'AgencyId': AgencyID,
                    'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    public GetAgencyUrlAndCode(AgencyId, LocationId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyUrlAndCode',
                {
                    'AgencyId': AgencyId,
                    'LocationId': LocationId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }


    public UpdateAgencyDetail(UserId, AgencyName, Address1, Address2, City, State, ZipCode) {

        return this._http
            .post(
                environment.api_url + '/agency/UpdateAgencyDetail',
                {
                    'UserId': UserId,
                    'AgencyName': AgencyName,
                    'Address1': Address1,
                    'Address2': Address2,
                    'City': City,
                    'State': State,
                    'ZipCode': ZipCode

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }




    public UpdateUserDetail(UserId, FirstName, LastName, Email, Phone, Description = '') {
        return this._http
            .post(
                environment.api_url + '/agency/UpdateAdminDetail',
                {
                    'UserId': UserId,
                    'FirstName': FirstName,
                    'LastName': LastName,
                    'Email': Email,
                    'Phone': Phone,
                    'Description': Description
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetInitialSettings(userId: number) {
        return this._http.post(
            `${environment.api_url}/user/initialsettings`,
            { 'UserId': userId },
            this.GetHeaders()
        ).map((response) => response.json()).map((response) => {
            return response;
        }).catch((error) => this.handleError(error));
    }

    public GetSignedUnsignedDocuemntsCount(userId: number, IsSigned: boolean) {
        return this._http.post(
            `${environment.api_url}/document/GetsignedUnsignedDocumentCount`,
            {
                'UserId': userId,
                'IsSigned': IsSigned
            },
            this.GetHeaders()
        ).map((response) => response.json()).map((response) => {
            return response;
        }).catch((error) => this.handleError(error));
    }

    public GetAgencyInitialData(AgencyId) {

        return this._http
            .post(
                environment.api_url + '/agency/getagencyinitials',
                {
                    'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetVideoByArchiveId(ArchiveId) {

        return this._http
            .post(
                environment.api_url + '/agency/GetRecordingUrl',
                {
                    'ArchiveId': ArchiveId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }



    // public GetUserNotifications(userId: number) {
    //     return this._http.post(`${environment.api_url}/user/getusernotifications`,
    //         { 'UserId': userId },
    //         this.GetHeaders()
    //     ).map((response) => response.json()).map((response) => {
    //         return response;
    //     }).catch((error) => this.handleError(error));
    // }
    public GetPublicFacePageSettings(AgencyId, LocationId) {
        return this._http.post(`${environment.api_url}/agency/getpublicfacingpagesettings`,
            { 'AgencyId': AgencyId, 'LocationId': LocationId },
            this.GetHeaders()
        ).map((response) => response.json()).map((response) => {
            return response;
        }).catch((error) => this.handleError(error));
    }

    public AddLeads({ AgencyId, LocationId, FirstName, LastName, Email, Phone }) {
        return this._http.post(`${environment.api_url}/account/addleads`,
            { 'AgencyId': AgencyId, 'LocationId': LocationId, FirstName: FirstName, LastName: LastName, Email: Email, Phone: Phone },
            this.GetHeaders()
        ).map((response) => response.json()).map((response) => {
            return response;
        }).catch((error) => this.handleError(error));
    }


    public updatePublicPageSettings(pageData) {
        return this._http.post(`${environment.api_url}/agency/updatepublicfacingpage`,
            pageData,
            this.GetHeaders()
        ).map((response) => response.json()).map((response) => {
            return response;
        }).catch((error) => this.handleError(error));
    }

    public updateCustomerIds() {

        return this._http
            .post(
                environment.api_url + '/user/UserDetailWithAgencyName',
                {

                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch((err) => this.handleError(err));
    }
    public GetUserActivitiesForAgent(postDataObject: Object) {

        return this._http
            .post(
                environment.api_url + '/activity/getactivities',
                postDataObject, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            });
    }
    public MarkedActivitiesAsRead(postDataObject: Object) {

        return this._http
            .post(
                environment.api_url + '/activity/markasread',
                postDataObject, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            });
    }
    /** Agency matrix api started from here */
    public linkAgencyMatrixAccount(checkValue) {
        return this._http
            .post(
                environment.api_url + '/agencymatrix/linkaccount',
                checkValue, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetUserActivitiesForUser(postDataObject: Object) {

        return this._http
            .post(
                environment.api_url + '/activity/getuseractivities',
                postDataObject, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {

                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            });
    }

    public addAgencyMatrixAccount(responseData) {
        return this._http
            .post(
                environment.api_url + '/agencymatrix/addaccount',
                responseData, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public LinkAgencyMatrixStatus(responseData) {
        return this._http
            .post(
                environment.api_url + '/agencymatrix/linkstatus',
                responseData, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public getAgencyMatrixPopupStatus(requestData) {
        return this._http
            .post(
                environment.api_url + '/agencymatrix/getagencymatrixlinkpopupstatus',
                requestData, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public updateAgencyMatrixPopupStatus(requestData) {
        return this._http
            .post(
                environment.api_url + '/agencymatrix/updateagencymatrixlinkpopupstatus',
                requestData, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    /** Agency matrix api end here */

    public DeleteProduct(product: object) {
        return this._http
            .post(
                environment.api_url + '/product/deleteproduct',
                product, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAWSSettingKeys() {
        return this._http
            .post(
                environment.api_url + '/account/getawssettings',
                {}, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public AddGuestAccount(guestData: object) {
        return this._http
            .post(
                environment.api_url + '/account/addguest',
                guestData, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));

    }

    public GetEmailNotificationSettings(guestData: object) {
        return this._http
            .post(
                environment.api_url + '/user/get-user-config-setting',
                guestData, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));

    }

    public SetEmailNotificationSettings(guestData: object) {
        return this._http
            .post(
                environment.api_url + '/user/configuration-setting',
                guestData, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));

    }

    public UnlinkStripeAccount(AgencyId, Password, UserId) {
        return this._http
            .post(
                environment.api_url + '/agency/unlinkstripeaccount',
                {
                  'AgencyId': AgencyId,
                  'Password': Password,
                  'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public UploadDateImageAgent(base64Data: any, the_file: any, Key: string) {
        const formData: FormData = new FormData();
        formData.append('upload', the_file, Key);
        return this._http.post(environment.api_url + '/PDF/UploadImageOnServer', formData)
            .map((response: Response) => {
                return response;
            }).catch(this.handleError);
    }

    public UserSignatureIntialStatus(UserId, LocationId) {
        return this._http
            .post(
                environment.api_url + '/user/signatureintialstatus',
                {
                  'UserId': UserId,
                  'LocationId': LocationId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    } // ReportAdmin

    public ReportAdmin(AgencyId, StartDate, EndDate, ReportSeleted, AgencyStatus) {
        if (ReportSeleted === 1) {
            return this._http
            .post(
                environment.api_url + '/agency/reportadmin',
                {
                'AgencyId': AgencyId,
                'StartDate': StartDate,
                'EndDate': EndDate,
                'AgencyStatus': AgencyStatus
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
        } else {
            // StartDate = (moment(StartDate).valueOf()/1000);
            // EndDate = (moment(EndDate).valueOf()/1000);
            return this._http
            .post(
                environment.api_url + '/agency/reportadminpayment',
                {
                'AgencyId': AgencyId,
                'StartDate': StartDate,
                'EndDate': EndDate,
                'AgencyStatus': AgencyStatus
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
        }
    }

    public AddLocation(AgencyId, StripeCustomerId, Planid, locationName, address, city,
        state, zip, domainName, place_id, Address1latitude, Address1longitude, DomainHost, SubscriptionId, Quantity, PlanTypeId, UserId) {
        return this._http
            .post(
                environment.api_url + '/account/addlocation',
                {
                  'AgencyId': AgencyId,
                  'StripeCustomerId': StripeCustomerId,
                  'PlanId': Planid,
                  'LocationName': locationName,
                  'Address1': address,
                  'City': city,
                  'State': state,
                  'ZipCode': zip,
                  'SiteAddress': domainName,
                  'Address1PlaceId': place_id,
                  'Address1latitude': Address1latitude,
                  'Address1longitude': Address1longitude,
                  'DomainHost': DomainHost,
                  'SubscriptionId': SubscriptionId,
                  'Quantity': Quantity,
                  'PlanTypeId': PlanTypeId,
                  'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public EditLocation(AgencyId, EditLocationId, locationName, address, city,
        state, zip, place_id, Address1latitude, Address1longitude) {
        return this._http
            .post(
                environment.api_url + '/agency/updateeditlocation',
                {
                  'AgencyId': AgencyId,
                  'LocationName': locationName,
                  'Address1': address,
                  'City': city,
                  'State': state,
                  'ZipCode': zip,
                  'Address1PlaceId': place_id,
                  'Address1latitude': Address1latitude,
                  'Address1longitude': Address1longitude,
                  'LocationId': EditLocationId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgencyAllLocations(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/getallagencylocations',
                {
                  'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetEditLocationDetail(AgencyId, LocationId) {
        return this._http
            .post(
                environment.api_url + '/agency/getlocation',
                {
                  'AgencyId': AgencyId,
                  'LocationId': Number(LocationId)
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public DeleteLocation(AgencyId, LocationId, MoveLocation, MoveLocationId) {
        return this._http
            .post(
                environment.api_url + '/agency/deletelocation',
                {
                  'AgencyId': AgencyId,
                  'LocationId': LocationId,
                  'MoveLocation': (MoveLocation === '1') ? true : false,
                  'MoveLocationId': (MoveLocationId) ? MoveLocationId : 0
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    // GetAgentAllLocations
    public GetAgentAllLocations(AgencyId, UserId) {
        return this._http
            .post(
                environment.api_url + '/user/agent-all-locations',
                {
                  'AgencyId': AgencyId,
                  'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetAgencyDefaultDomain(AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetAgencyDefaultName',
                {
                  'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetUnseenActivityCount(UserId, RoleId, LocationId, AgencyId) {
        return this._http
            .post(
                environment.api_url + '/agency/GetUnseenActivityCount',
                {
                  'UserId': UserId,
                  'RoleId': RoleId,
                  'LocationId': LocationId,
                  'AgencyId': AgencyId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetUnreadMessageCount(UserId) {
        return this._http
            .post(
                environment.api_url + '/user/GetUnreadMessageCount',
                {
                  'UserId': UserId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public MarkasReadMessage(SenderId, ReceiverId) {
        return this._http
            .post(
                environment.api_url + '/user/markasreadmesssage',
                {
                  'SenderId': SenderId,
                  'ReceiverId': ReceiverId
                }, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public AwsTempraryUploadDetails() {
        return this._http
            .post(
                environment.api_url + '/account/getawstoken',
                {}, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }

    public GetKeysDetails() {
        return this._http
            .post(
                environment.api_url + '/account/getkeysdetails',
                {}, this.GetHeaders()
            ).map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch((err) => this.handleError(err));
    }
}
