import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { toast } from 'angular2-materialize';
const CountryCodes = require('../../helper/CountryCode.json');
import { AgentRegisterFields } from './agentRegisterFields';
import { EventBus } from '../../shared/EventBus/EventBus';
import { UserService } from '../../model/user.service';

@Component({
  templateUrl: './agentRegister.component.html'
})
export class AgentRegisterComponent implements OnInit {
  @ViewChild('video') private video: ElementRef;
  IsMute: boolean;
  ShowLoader: boolean;
  showPassword: boolean;
  showCPassword: boolean;
  _signupForm: FormGroup;
  private _formErrors: any;
  public SelectedCountryFlag: string;
  private _submitted: boolean;
  private _errorMessage: string;
  public _countryCode: any[];
  _agencylocations: any[];
  IsValidLength: boolean;
  IsStrong: boolean;
  IsMatch: boolean;
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private _agenctobj: any = new Object();
  IsLocalhost: boolean;
  locationId: any;
  agencyId: any;
  AgentId: any;
  Name: any;
  Email: any;
  FirstName: string;
  LastName: string;
  user = new AgentRegisterFields('', '');
  agentTokan: string;
  constructor(private _formBuilder: FormBuilder, private _userService: UserService,
    private _router: Router, private _eventBus: EventBus,
    private _route: ActivatedRoute) {
    this._agenctobj.Logo = '../assets/images/agent_alive_logo.png';
    this.IsLocalhost = _userService.IsLocalHost();
    if (!this.IsLocalhost) {
      this.CheckAgency(this._userService.getSubdomain());
    }
    this._countryCode = CountryCodes;
    this.SelectedCountryFlag = 'US.png';
    const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
    const passwordConfirm = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));
    // signup form register agent
    this._signupForm = _formBuilder.group({
      agencyname: ['Site Address', Validators.compose([Validators.required])],
      FirstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(50)])],
      LastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(50)])],
      email: ['', Validators.compose([Validators.required, CustomValidators.email])],
      SelectedCountryCode: ['US'],
      phone: ['', Validators.required],
      password: password,
      confirmpassword: passwordConfirm,
      selectedLocationId: ['1'],
      refreralcode: [''],
      isagreed: [false, Validators.required]
    });
    this._signupForm.controls['SelectedCountryCode'].setValue('US', { onlySelf: true });
    this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // get agency locations
  public GetAgencyLocation() {
    this._userService.GetAgencyLocations().subscribe(
      result => {
        if (result.Status) {
          result.Data.forEach(element => {
            if (element.AgencyId === this._agenctobj.Id) {
              this._agencylocations = element.AgencyLocations;
            }
          });
          this._userService.setTitle(this._agenctobj.AgencyName);
        } else {
          this._agenctobj.Logo = '../assets/images/referFriend.png';
          this._userService.setTitle('Agent Alive');
          this.toasting(result.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      },
      error => {
        // Validation error
        if (error.status === 422) {
          this._resetFormErrors();
          const errorFields = JSON.parse(error.data.message);
          this._setFormErrors(errorFields);
        } else {
          this._errorMessage = JSON.stringify(error.Data[0]);
        }
        this.ShowLoader = false;
      }
    );
  }
 // check agency by name
  public CheckAgency(agencyname) {
    this._userService.GetAgency(agencyname)
      .subscribe(
        result => {
          if (result.Status) {
            this._agenctobj = result.Data[0];
            this._userService.setTitle(this._agenctobj.AgencyName);
            this.GetAgencyLocation();
          } else {
            this._agenctobj.Logo = '../assets/images/agent_alive_logo.png';
            this._userService.setTitle('Agent Alive');
            this.toasting(result.Message, 2000, 'red');
          }
          this.ShowLoader = false;
        },
        error => {
          // Validation error
          if (error.status === 422) {
            this._resetFormErrors();
            const errorFields = JSON.parse(error.data.message);
            this._setFormErrors(errorFields);
          } else {
            this._errorMessage = JSON.stringify(error.Data[0]);
          }
          this.ShowLoader = false;
        }
      );
  }
  // set sever errors register
  private _setFormErrors(errorFields: any): void {
    for (const key in errorFields) {
      // skip loop if the property is from prototype
      if (!errorFields.hasOwnProperty(key)) { continue; }
      const message = errorFields[key];
      this._formErrors[key].valid = false;
      this._formErrors[key].message = message;
    }
  }
  // reset form errors server
  private _resetFormErrors(): void {
    this._formErrors = {
      name: { valid: true, message: '' },
      email: { valid: true, message: '' },
      phone: { valid: true, message: '' },
      refreralcode: { valid: true, message: '' },
      password: { valid: true, message: '' },
      confirmpassword: { valid: true, message: '' }
    };
  }
  // show logo on select country code
  public onChangeCode(event) {
    this.SelectedCountryFlag = event.value + '.png';
  }
  // on value change reset error
  public onValueChanged(data?: any) {
    if (!this._signupForm) { return; }
    const form = this._signupForm;
    for (const field in this._formErrors) {
      // clear previous error message (if any)
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }
  // check form field validation
  public _isValid(field): boolean {
    let isValid: boolean;
    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._signupForm.controls[field].touched === false) {
      isValid = true;
    } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // register agent api
  public onSubmit(elementValues: any) {
    this._countryCode.forEach(function (item) {
      if (item.code === elementValues.SelectedCountryCode) {
        elementValues.SelectedCountryCode = item.dial_code;
      }
    });
    elementValues.phone = elementValues.phone.replace(/\D+/g, '');
    if (elementValues.phone !== '') {
      this.ShowLoader = true;
      if (this.locationId !== '' && this.agencyId !== '') {
        this._userService.RegisterAgent(elementValues.FirstName, elementValues.LastName, elementValues.email,
          elementValues.SelectedCountryCode,
          elementValues.phone, elementValues.password, this.agencyId, parseInt(this.locationId, null), this.agentTokan)
          .subscribe(
            result => {
              if (result.Status) {
                this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
                this._router.navigate(['agent/dashboard']);
              } else {
                this.toasting(result.Message, 2000, 'red');
                this._errorMessage = result.Message;
              }
              this.ShowLoader = false;
            },
            error => {
              if (error.status === 422) {
                this._resetFormErrors();
                const errorFields = error.Data;
                this._setFormErrors(errorFields);
              } else {
                this._errorMessage = JSON.stringify(error.Data[0]);
              }
              this.ShowLoader = false;
            }
          );
      }
    } else {
      this._signupForm.controls['phone'].setValue('');
      this.toasting('Please enter your phone number', 2000, 'red');
    }
  }
  // conform password match
  CheckConform(Pass, ConfirmPass) {
    if (ConfirmPass.length > 0) {
      if (Pass !== ConfirmPass) {
        this.IsMatch = true;
      } else {
        this.IsMatch = false;
      }
    }
  }
  // check password regex pattern
  CheckPass(data) {
    if (data.value.length > 0) {
      if (data.value.length < 6) {
        this.IsValidLength = true;
      } else {
        this.IsValidLength = false;
        const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
        if (mediumRegex.test(data.value)) {
          this.IsStrong = false;
        } else {
          this.IsStrong = true;
        }
      }
    }
  }
  ngOnInit() {
    this._route.params.subscribe(params => {
      this.agentTokan = params['id'];
      // get agency detail from deeplink token
      this._userService.GetAgentDetailFromToken(this.agentTokan)
        .subscribe(
          result => {
            if (typeof (result.Data[0]) !== 'undefined' && result.Data[0] !== null) {
              this.agencyId = result.Data[0].AgencyId;
              this.locationId = result.Data[0].LocationId;
              this.FirstName = result.Data[0].FirstName;
              this.LastName = result.Data[0].LastName;
              this.Name = `${this.FirstName} ${this.LastName}`;
              this.Email = result.Data[0].Email;
              this.user.name = this.Name;
              this.user.email = this.Email;
              this._signupForm.controls['FirstName'].setValue(this.FirstName);
              this._signupForm.controls['LastName'].setValue(this.LastName);
            } else {
              this.toasting('Token not found.', 2000, 'red');
              this._router.navigate(['/']);
            }
          },
          error => {
            // Validation error
            if (error.status === 422) {
              this._resetFormErrors();
              const errorFields = error.Data;
              this._setFormErrors(errorFields);
            } else {
              this._errorMessage = JSON.stringify(error.Data[0]);
            }
            this.ShowLoader = false;
          }
        );
    });
  }
}
