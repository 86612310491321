import { Component } from '@angular/core';
import { Activity } from '../../model/activity';
import { UserService } from '../../model/user.service';
import { EventBus } from '../../shared/EventBus/EventBus';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent {
  Activity: Activity[];
  ActivityData: Activity;
  AllActivityCount = 0;
  SeenActivityCount = 0;
  UnSeenActivityCount = 0;
  IsNotificationloaded: boolean;

  LoggedInUserName: string;
  LoggedInUserRoleId: string;
  LoggedInUserRoleName: string;
  LoggedInUserPhone: string;
  LoggedInUserEmail: string;
  AgencyName: string;
  ShowRibbon: boolean;
  LoggedInUserImageUrl: string;
  AgencyLogo: string;
  AgencyId: number;
  LoginUserId: number;
  SelectedLocationId: number;
  filterId = 0;
  LoadMoreActivityCounter = 0;
  LocationId: number;
  constructor(private _userService: UserService, private _router: Router,
    private _EventBus: EventBus, private _domSanitizer: DomSanitizer) {
    const UserModel = this._userService.decodeToken();
    this.SelectedLocationId = parseInt(this._userService.GetSelectedLocation(), null);
    this.Activity = [];
    // get agency/user detail from token
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserRoleId = UserModel.user.RoleId.toString();
      this.AgencyLogo = UserModel.user.AgencyLogo;
      this.AgencyName = UserModel.user.AgencyName;
      this.AgencyId = UserModel.user.AgencyId;
      this.LoginUserId = UserModel.user.UserId;
      if (UserModel.user.SubscriptionStatusId !== 1) {
        this.ShowRibbon = true;
      }
      if (this._userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId) {
          this.LocationId = Number(this._userService.GetSelectedLocation());
      } else {
          this.LocationId = UserModel.user.LocationId;
      }
    }

    this._EventBus.on().subscribe(m => {
      if (m.filterBy.Type === 'locationChanged') {
          this.LocationId = Number(m.filterBy.data.LocationId);
          if (this.LoggedInUserRoleId === '3') {
            this.GetUserActivities(true);
          } else { this.GetAgencyActivities(true); }
      }
      if (m.filterBy.Type === 'GET_UPDATED_ACTIVITY_TRIGGERED') {
        this.Activity = m.filterBy.data.data;
        this.AllActivityCount = m.filterBy.data.AllActivityCount;
        this.SeenActivityCount = m.filterBy.data.SeenActivityCount;
        this.UnSeenActivityCount = m.filterBy.data.UnSeenActivityCount;
      }
    });

    if (this.LoggedInUserRoleId === '3') {
      this.GetUserActivities(true);
    } else { this.GetAgencyActivities(true); }
  }
  // get agency activities
  GetAgencyActivities(isResetArray?: boolean, offset = 0,
    activityModuleId = 0, activityType = 0, hideLoader = false) {
    const postData = {
      UserId: this.LoginUserId,
      OffSet: offset,
      ActivityModuleId: activityModuleId,
      ActivityType: activityType,
      AgencyId: this.AgencyId,
      LocationId: this.LocationId
    };

    if (hideLoader) {
      this.IsNotificationloaded = true;
    } else {
      this.IsNotificationloaded = false;
    }
    this._userService.GetUserActivitiesForAgent(postData).subscribe((respo: any) => {
      if (isResetArray) {
        this.Activity = [];
      }
      const activityData = respo && respo.Data ? respo.Data : [];
      activityData.forEach((element: Activity) => {
        this.Activity.push(element);
      });

      this.RefreshActivityCount();
      this.IsNotificationloaded = true;
    }, error => {
      this.IsNotificationloaded = true;
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }
  // get user activities
  GetUserActivities(isResetArray = false, offset = 0, activityModuleId = 0,
    activityType = 0, hideLoader = false) {
    const postData = {
      UserId: this.LoginUserId,
      OffSet: offset,
      ActivityModuleId: activityModuleId,
      ActivityType: activityType,
    };
    if (hideLoader) {
      this.IsNotificationloaded = true;
    } else {
      this.IsNotificationloaded = false;
    }
    this._userService.GetUserActivitiesForUser(postData).subscribe((respo: any) => {
      if (isResetArray) {
        this.Activity = [];
      }
      const activityData = respo && respo.Data ? respo.Data : [];
      activityData.forEach((element: Activity) => {
        this.Activity.push(element);
      });

      this.RefreshActivityCount();
      this._EventBus.emit({
        Type: 'GET_UPDATED_ACTIVITY_TRIGGERED', data:
        {
          data: this.Activity,
          AllActivityCount: this.AllActivityCount,
          SeenActivityCount: this.SeenActivityCount,
          UnSeenActivityCount: this.UnSeenActivityCount
        }
      });
      this.IsNotificationloaded = true;
    }, error => {
      this.IsNotificationloaded = false;
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }
  // formated date local date time
  public GetFormatedDate(date) {
    return new Date(date).toLocaleString();
  }
  // refresh activities count
  public RefreshActivityCount() {
    if (this.Activity && this.Activity.length > 0) {
      const acivity = this.Activity[0];
      this.AllActivityCount = acivity.TotalActivityCount;
      this.SeenActivityCount = acivity.SeenCount;
      this.UnSeenActivityCount = acivity.UnSeenCount;
    } else {
      this.AllActivityCount = 0;
      this.SeenActivityCount = 0;
      this.UnSeenActivityCount = 0;
    }
  }
  // filter activities by type
  filterActivities(filterId: number) {
    if (this.LoggedInUserRoleId === '3') {
      this.GetUserActivities(true, 0, filterId);
    } else {
      this.GetAgencyActivities(true, 0, filterId);
    }
    this.filterId = filterId;
  }
  // Scroll down load more actities
  public onScrollDownActivities() {
    this.LoadMoreActivityCounter = this.LoadMoreActivityCounter + 50;
    if (this.LoggedInUserRoleId === '3') {
      this.GetUserActivities(false, this.LoadMoreActivityCounter, this.filterId);
    } else {
      this.GetAgencyActivities(false, this.LoadMoreActivityCounter, this.filterId);
    }
  }
  // get acount fixed to two decimal
  GetFormatedDollor(dollor) {
    return Number(dollor).toFixed(2);
  }
  // indentify activity
  identifyActivity(index: number, item: Activity) {
    return item.Id;
  }
}
