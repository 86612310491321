import { Component, OnInit, NgZone } from '@angular/core';
import { UserService } from '../../model/user.service';
import { Router } from '@angular/router';
import { EventBus } from '../EventBus/EventBus';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-agent-agency-sidebar',
  templateUrl: 'agent-agency-sidebar.component.html'

})

export class AgentAgencySidebarComponent implements OnInit {
  AgencyId: number;
  hashes: any;
  vars: any = [];
  hash: any;
  hashUri: any;
  AgencyReferalSetingExist: boolean;
  AgencyLogo: string;
  ReferalPending: boolean;
  sidebarStatus: boolean;
  UnseenMessageCount = 0;
  LoginUserId: number;
  BlockMultipleRequest = true;
  constructor(private _EventBus: EventBus, private _userService: UserService,
    private _router: Router, private _ngZone: NgZone
    ) {
      // get agency/user detail from token
      const UserModel = this._userService.decodeToken();
      if (UserModel && UserModel.user) {
        this.AgencyId = UserModel.user.AgencyId;
        this.LoginUserId = UserModel.user.UserId;
        this.GetUnreadMessageCount();
      }
      window['angularComponentRefUserChatCount'] = {
        zone: this._ngZone,
        RefreshConverstation: (value) => this.RefreshConverstation(value)
      };
  }

  ngOnInit() {
    this._EventBus.on().subscribe((m: any) => {
      // Toggle left bar on mobile
      if (m.filterBy.Type === 'toggleressidebar') {
        if (m.filterBy.data.sidebarStatus) {
          this.sidebarStatus = true;
        }
      } else if (m.filterBy.Type === `header-left-statics`) {
        if (this.AgencyReferalSetingExist !== m.filterBy.data.IsReferralSettingOn) {
          this.GetAgencyReferalDetail();
        }
      } else if (m.filterBy.Type === 'MessageReceivedData' ) {
        this.RefreshConverstation(m.filterBy.data.count);
      }
    });
    this.hashUri = decodeURIComponent(window.location.href);
    this.hashes = this.hashUri.slice(this.hashUri.indexOf('?') + 1).split('&');
    if (this.hashes.length > 0) {
      for (let i = 0; i < this.hashes.length; i++) {
        this.hash = this.hashes[i].split('=');
        this.vars[this.hash[0]] = this.hash[1];
      }
    }
    this.GetAgencyReferalDetail();
  }
  // get agency referal exist
  public GetAgencyReferalDetail() {
    this._userService.GetAgencyInitialData(this.AgencyId).subscribe(result => {
      if (result.Status) {
        if (result.Data) {
          if (result.Data.IsReferralSettingOn) {
            this.AgencyReferalSetingExist = true;
          } else {
            this.AgencyReferalSetingExist = false;
          }
          if (result.Data.AgencyLogo) {
            this.AgencyLogo = result.Data.AgencyLogo;
          }
        }
      }
    }, error => {
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }
  public GetUnreadMessageCount() {
    if (this.BlockMultipleRequest) {
      this.BlockMultipleRequest = false;
      this._userService.GetUnreadMessageCount(this.LoginUserId)
        .subscribe((response) => {
            if (response.Status) {
              this._ngZone.run( () => {
                this.UnseenMessageCount = response.Data.MessageCount;
              });
            }
            this.BlockMultipleRequest = true;
        }, (error) => { this.BlockMultipleRequest = true; }
      );
    }
  }

  /**
   *
   * @param obj count unrread message user
   */
  public RefreshConverstation(obj) {
    if (obj && obj.hasOwnProperty('count')) {
      this.UnseenMessageCount = obj.count;
    } else {
      this.GetUnreadMessageCount();
    }
  }
}
