// class for save data in Agent Alive database
export class AgencyMatrixSaveData {
    AgencyId: number;
    UserId: number;
    AgencyName: string;
    Name: string;
    Email: string;
    Phone: string;
    Password: string;
    ConfirmPassword: string;
    AgencyGuid: string;
}

export class AgencyMatrixLinkData {
    AgencyId: number;
    UserId: number;
    Email: string;
    AgencyGuid: string;
    AgencyName: string;
}

export class LinkStatus {
    AgencyId: number;
}
