import { Component, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../model/user.service';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';
import { MaterializeAction, toast } from 'angular2-materialize';
import { environment } from '../../../environments/environment.prod';
import { DocumentToSave } from '../../model/documenttosave';
import { FormArray } from '@angular/forms';
declare var $: any;
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalService } from '../../model/global.service';

@Component({
    templateUrl: './agencyadmindashboardpaymentmethod.component.html'

})

export class AgencyAdminDashboardPaymentMethodComponent implements OnInit {
    ShowCallSideBar = 1;
    modalActions1 = new EventEmitter<string | MaterializeAction>();
    paymentMethod = false;
    LoginUserId: number;
    AgencyId: number;
    model1Params: any;
    LocationId: number;
    ShowLoader: number;

    stripeClientId: any;
    orderForm: FormGroup;
    items: FormArray;
    Fname: string;
    arr: any = [];
    SaveEmailArray: any[];
    currentDiv: any = 0;
    NotcheckedExist: boolean;
    checkedExist: boolean;
    checkedNotExist: boolean;
    IsExist: boolean;
    UnlinkStripeForm: FormGroup;

    /******************************/
    private _errorMessage: string;
    private _formErrors: any;
    stripeAccountConneted: number;
    _isDoller: boolean;
    SubdomainName: string;
    domainName: string;
    AhencyId: number;
    hostName: string;
    validupload: boolean;
    filesTosaveDB: Array<DocumentToSave> = [];
    HasFilesToUpload: boolean;
    SelectedFolderId: string;
    extens: String[];
    @ViewChild('uploadfileinput')
    public file_srcs_companyLogo: string;
    public file_srcs_userImage: string;
    selectedCompanyLogo: boolean;
    selectedUserLogo: boolean;
    companyLogoUploading: boolean;
    CompanyLogoKey: string;
    CompanyLogoUrl: string;
    UserImageKey: string;
    UserImageUrl: string;
    DomainNameFilled: number;
    CompanyLogoFilled: number;
    UserImageFilled: number;
    Amount: number;
    ShowDotLoader: boolean;
    modelUnlinkStripeParam: any;
    modalUnlinkActions = new EventEmitter<string | MaterializeAction>();
    StripeAccountUserID: string;
    hasError: boolean;
    UnlinkButton: boolean;
    errorMsg = '';
    /***************************** */
    Domain: any;
    constructor(private _userService: UserService, private location: Location,
        private _EventBus: EventBus, private _router: Router,
        private _formBuilder: FormBuilder, private _globalService: GlobalService) {
        this.NotcheckedExist = true;
        this._isDoller = true;
        this.Amount = 50;
        this.UnlinkStripeForm = this._formBuilder.group({
            'password': ['', Validators.required]
        });
        this.GetKeysDetails();
    }
    // redirect agency to stripe for link their account
    redirectToStripe() {
        console.log('this.stripeClientId------>', this.stripeClientId);
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let token = '';
        for (let i = 0; i < 20; i++) {
            token += chars[Math.floor(Math.random() * chars.length)];
        }
        const redirectToCompany = window.location.origin + '/agency/payment_methods';
        const decodeToken = btoa(token + '&' + this.AgencyId + '&' + redirectToCompany);
        window.location.href = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' +
            this.stripeClientId + '&scope=read_write&state=' + decodeToken;
    }


    ngOnInit(): void {
        let hashes: any;
        const vars: any = [];
        let hash: any;
        let hashUri: any;
        hashUri = decodeURIComponent(window.location.href);
        hashes = hashUri.slice(hashUri.indexOf('?') + 1).split('&');
        if (hashes.length > 0) {
            for (let i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars[hash[0]] = hash[1];
            }
        }
        // verify user by code after stripe response
        if (typeof (vars['code']) !== 'undefined' && vars['code'] !== null && vars['code'] !== '') {
            setTimeout(function () {
                $('.stripe_button_first').text('Stripe account connecting...');
            }, 150);

            $('.loader-outer').css('display', 'flex');
            const AgencyDetail = atob(vars['state']);
            const AgencyId = AgencyDetail.split('&')[1];
            const companyUrl = AgencyDetail.split('&')[2];
            this.AgencyId = parseInt(AgencyId, null);
            this.ShowCallSideBar = 0;
            this._userService.StripeAuth(vars['code'], this.AgencyId)
                .subscribe(
                    result => {
                        if (result.Status) {
                            this.paymentMethod = true;
                            // hide loader
                            this.ShowCallSideBar = 1;
                            window.location.href = companyUrl;
                            $('.loader-outer').css('display', 'none');
                        }
                    },
                    error => {
                        // Validation error
                        if (error.status === 422) {
                            this._resetFormErrors();
                            const errorFields = JSON.parse(error.data.message);
                            this._setFormErrors(errorFields);
                        } else {
                            this._errorMessage = JSON.stringify(error.Data[0]);
                        }
                        this.ShowCallSideBar = 1;
                        $('.loader-outer').css('display', 'none');
                    }
                );
        } else {
            // get agency/admin detail by token
            const UserModel = this._userService.decodeToken();
            if (UserModel && UserModel.user) {
                this.LoginUserId = UserModel.user.UserId;
                this.AgencyId = UserModel.user.AgencyId;
            }

            // this.stripeClientId = environment.stripe_test_client_id;
            this.ShowLoader = 1;
            this._userService.CheckStripeAccountStatus(this.AgencyId, this.LoginUserId)
                .subscribe(
                    result => {
                        if (result.Status === true) {
                            this.StripeAccountUserID = (result && result.Data.account_detail) ?
                                result.Data.account_detail.StripeUserAccountId : '';
                            this.paymentMethod = true;
                            this.ShowLoader = 0;
                        } else {
                            this.paymentMethod = false;
                            this.ShowLoader = 0;
                        }
                    },
                    error => {
                        // Validation error
                    }
                );
        }
        /****************************For stripe connectivity******************/
        this._userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
    }
    public GetKeysDetails() {
        this._userService.GetKeysDetails()
         .subscribe((response) => {
            if (response.Status) {
              const DecrytData = this._globalService.DecryptZohoKeys(response.Data);
              this.stripeClientId = DecrytData.stripe_client_id;
            }
         }, (error) => {});
    }

    // reset form error after response
    private _resetFormErrors(): void {
        this._formErrors = {
            email: { valid: true, message: '' },
            password: { valid: true, message: '' },
        };
    }
    // show success/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // set form error message by field validations
    private _setFormErrors(errorFields: any): void {
        for (const key in errorFields) {
            // skip loop if the property is from prototype
            if (!errorFields.hasOwnProperty(key)) { continue; }
            const message = errorFields[key];
            this._formErrors[key].valid = false;
            this._formErrors[key].message = message;
        }
    }
    // open add payment method modal
    addPaymentMethod() {
        this.modalActions1.emit({ action: 'modal', params: ['open'] });
    }
    // unlink stripe account agency
    public unlinkStripeAccount(password) {
        this.UnlinkButton = true;
        const Password = password.password;
        this.ShowLoader = 1;
        this.hasError = false;
        this.errorMsg = '';
        this._userService.UnlinkStripeAccount(this.AgencyId, Password, this.LoginUserId).subscribe(
            (response) => {
                if (response.Status) {
                    this.toasting('Your stripe account is successfully unlinked', 3000, 'green');
                    this._userService.CheckStripeAccountStatus(this.AgencyId, this.LoginUserId)
                        .subscribe(
                            result => {
                                if (result.Status === true) {
                                    this.paymentMethod = true;
                                    this.ShowLoader = 0;
                                } else {
                                    this.paymentMethod = false;
                                    this.ShowLoader = 0;
                                }
                            },
                            error => {
                            }
                        );
                    this.UnlinkStripeForm.reset();
                    this.modalUnlinkActions.emit({ action: 'modal', params: ['close'] });
                } else {
                    this.UnlinkStripeForm.reset();
                    this.UnlinkButton = false;
                    this.hasError = true;
                    this.ShowLoader = 0;
                    this.errorMsg = response.Message;
                }
            }, (error) => {
                this.UnlinkStripeForm.reset();
                const errmsg =  (error.Message ? error.Message : 'Some error occured');
                this.toasting(errmsg, 3000, 'red');
            }
        );
    }
    // cancel unlink stripe account modal
    public CancelUnlinkModel() {
        this.modalUnlinkActions.emit({ action: 'modal', params: ['close'] });
    }
    // open unlink stripe account
    public UnlinkStripeConfirm() {
        this.modalUnlinkActions.emit({ action: 'modal', params: ['open'] });
    }
    valuechange(event) {
        this.hasError = false;
        this.errorMsg = '';
    }
}
