import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from 'ng2-translate';
import { Intercom } from 'ng-intercom';
import { EventBus } from '../../shared/EventBus/EventBus';
import { UserService } from '../../model/user.service';
import { Router } from '@angular/router';
import { DocumentToSave } from '../../model/documenttosave';
import { toast } from 'angular2-materialize';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../model/global.service';
declare var $: any;

@Component({
  selector: 'app-public-page-setting',
  templateUrl: './public-page-setting.component.html',
  styleUrls: ['./public-page-setting.component.css']
})
export class PublicPageSettingComponent implements OnInit {

  public agencyLocationLogo: string;
  public agencyLocationAddress1: string;
  public SaveEmailArray: any = [];
  public agencyLocationAddress2: string;
  public agencyLocationBodyBannerThumbnailUrl: string;
  public agencyLocationBodyBannerUrl: string;
  public agencyLocationCity: string;
  public agencyLocationContactNumber: string;
  public agencyLocationLocationDescription: any;
  public agencyLocationLocationName: string;
  public agencyLocationLocationSiteAddress: string;
  public agencyLocationLocationStatusId: string;
  public agencyLocationState: string;
  public agencyLocationZipCode: string;
  public IsBodyBannerFileUploadFailed: boolean;
  public IsLogoImageFileUploadFailed: boolean;

  public ShowBannerLoadingLoader: boolean;
  public ShowLogoLoadingLoader: boolean;
  public isFileValid: boolean;
  public HasFilesToUpload: boolean;
  private filesTosaveDB: Array<DocumentToSave> = [];
  public extens: String[];
  public upLoadedFileUrl: string;
  public showSettingLoader: boolean;
  public agencyFormGroup: FormGroup;
  public submitted: boolean;

  LoggedInUserName: string;
  LoggedInUserRoleId: string;
  LoggedInUserRoleName: string;
  LoggedInUserPhone: string;
  LoggedInUserEmail: string;
  AgencyName: string;
  ShowRibbon: boolean;
  LoggedInUserImageUrl: string;
  AgencyLogo: string;
  AgencyId: number;
  LoggedInUserId: number;
  SelectedLocationId: number;

  public htmlContent: any;
  public IsInEditMode: boolean;
  public showSetingSaveLoader: boolean;
  isBannerLoaded: boolean;

  public city: string;
  public state: string;
  public zipcode: string;
  public lat: any;
  public long: any;
  public street_name: string;
  public stroute_flag: any;
  public stroute: string;
  public AddressDescription: any;
  public AgencyCity: any;
  public AgencyZipCode: any;
  public AgencyState: any;
  Address1latitude: any;
  Address1longitude: any;
  globleDataSource: any;

  strouteComponent = {
    'sublocality_level_1': 'long_name'
  };
  stateComponent = {
    administrative_area_level_1: 'long_name'
  };
  postalComponent = {
    postal_code: 'short_name'
  };
  cityComponent = {
    locality: 'long_name'
  };
  DescrptionTitle: any;
  ngDescrptionTitle: any;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _EventBus: EventBus,
    public intercom: Intercom,
    public translate: TranslateService, private _eventBus: EventBus, private globalService: GlobalService,
    private _domSanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {
    this.extens = ['jpg', 'png', 'jpeg', 'gif'];
    // get agency/user detail from token
    const UserModel = this._userService.decodeToken();
    this.SelectedLocationId = parseInt(this._userService.GetSelectedLocation(), null);
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
      this.AgencyLogo = UserModel.user.AgencyLogo;
      this.AgencyName = UserModel.user.AgencyName;
      this.AgencyId = UserModel.user.AgencyId;
      this.LoggedInUserId = UserModel.user.UserId;
      if (UserModel.user.SubscriptionStatusId !== 1) {
        this.ShowRibbon = true;
      }
      if (this._userService.GetSelectedLocation() !== 0) {
        this.SelectedLocationId = Number(this._userService.GetSelectedLocation());
      } else {
          this.SelectedLocationId = UserModel.user.LocationId;
      }
    }
    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'locationChanged') {
            this.SelectedLocationId = Number(m.filterBy.data.LocationId);
            this.getPublicFacePageSettings();
        }
    });

    this.globleDataSource = this.globalService.globleDataSource;
    // agency detail form
    this.agencyFormGroup = new FormGroup({
      AgencyId: new FormControl(this.globleDataSource['AgencyId']),
      LocationId: new FormControl(this.globleDataSource['LocationId']),
      LocationName: new FormControl(this._userService.getSubdomain()), // this.agencyLocationLocationName),

      LocationDescription: new FormControl(''),
      Address1: new FormControl('', [Validators.minLength(2), Validators.maxLength(20)]),
      Address2: new FormControl('', [Validators.minLength(2), Validators.maxLength(20)]),

      City: new FormControl('', [Validators.minLength(2), Validators.maxLength(20)]),
      State: new FormControl(''),
      ZipCode: new FormControl(''),

      ContactNumber: new FormControl('', [Validators.maxLength(15)]),
      BodyBanner: new FormControl(''),
      BodyBannerThumbnail: new FormControl(''),

      Address1latitude: new FormControl(''),
      Address1longitude: new FormControl(''),
      Address1PlaceId: new FormControl(''),

      Address2latitude: new FormControl(''),
      Address2longitude: new FormControl(''),

      Address2PlaceId: new FormControl(''),
      AgencyName: new FormControl(this.AgencyName, [Validators.required])
    });
  }

  ngOnInit() {
    this.getPublicFacePageSettings();
  }

  get f() { return this.agencyFormGroup.controls; }
  // get public face page data
  public getPublicFacePageSettings() {
    this.showSettingLoader = true;
    this._userService.GetPublicFacePageSettings(this.globleDataSource['AgencyId'],
      this.SelectedLocationId).subscribe(response => {
        if (response.Status) {
          const data = response.Data;
          this.agencyLocationLogo = data.LogoUrl;
          this.agencyLocationAddress1 = data.Address1;
          this.agencyLocationAddress2 = data.Address2;
          this.agencyLocationBodyBannerThumbnailUrl = data.BodyBannerThumbnailUrl;
          if (!data.BodyBannerUrl) {
            this.isBannerLoaded = true;
          }

          this.agencyLocationBodyBannerUrl = data.BodyBannerUrl;
          this.agencyLocationCity = data.City;
          this.agencyLocationContactNumber = data.ContactNumber;
          this.agencyLocationLocationDescription = this._domSanitizer.bypassSecurityTrustHtml(data.LocationDescription);
          this.agencyLocationLocationName = data.LocationName;
          this.agencyLocationLocationSiteAddress = data.LocationSiteAddress;
          this.agencyLocationState = data.State;
          this.agencyLocationZipCode = data.ZipCode;
          this.AgencyName = data.AgencyName;
          this.AgencyLogo = data.LogoUrl;
          this.Address1latitude = data.Address1latitude;
          this.Address1longitude = data.Address1longitude;
          this.DescrptionTitle = data.DescriptionTitle;
          this.AddressDescription = this.agencyLocationAddress1;

          this.lat = Number(this.Address1latitude);
          this.long = Number(this.Address1longitude);
        }
        this.showSettingLoader = false;
      }, error => {
        this.showSettingLoader = false;
      });

  }
  // file change event
  public fileEvent(event) {
    if (event.target.files && event.target.files.length) {
      this.uploadFile(event);
    }
  }
  // upload banner/logo file agency
  public uploadFile(event) {
    const files = event.target.files;
    const uploadFileTargetName: any = event.target.name;
    this.filesTosaveDB = [];
    if (files.length > 0) {
      this.isFileValid = false;
      for (let i = 0; i < files.length; i++) {
        const documenttosave = new DocumentToSave();
        documenttosave.File = files[i];
        documenttosave.Name = files[i].name.split('.')[0].replace(/^.*[\\\/]/, '');
        documenttosave.Name = documenttosave.Name ? documenttosave.Name.replace(/\s/g, '') : documenttosave.Name;
        documenttosave.Extention = files[i].name.replace(/^.*\./, '');
        documenttosave.SizeInKB = files[i].size;
        documenttosave.Status = false;
        documenttosave.IsError = false;
        this.filesTosaveDB.push(documenttosave);
      }
      if (this.filesTosaveDB.length > 0) {
        this.HasFilesToUpload = true;
      }
      if (this.filesTosaveDB !== []) {
        this._userService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
              this.filesTosaveDB.forEach((item, index) => {
                if (this.CheckFileExtention(item.Extention)) {
                  this.readFiles(event.target.files, 0, uploadFileTargetName);

                  if (uploadFileTargetName === 'BodyBanner') {
                    this.ShowBannerLoadingLoader = true;
                  }

                  if (uploadFileTargetName === 'Logo') {
                    this.ShowLogoLoadingLoader = true;
                  }

                  if (!item.Status && !item.IsError) {
                    const AWSService = window.AWS;
                    // AWSService.config.accessKeyId = environment.amazon_access_key;
                    // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                    AWSService.config.update({
                      region: 'us-west-2',
                      credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                        response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                    });
                    const unix = Math.round(+new Date() / 1000);
                    item.Key = unix.toString() + '_' + item.Name;
                    item.Progress = 0;
                    const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                    const params = { Key: 'images/' + item.Key, Body: item.File, ACL: 'public-read' };
                    bucket.upload(params, (err, data) => {
                      if (data !== undefined) {
                        item.Status = true;
                        item.Location = data.Location;
                        this.isFileValid = true;
                        this.filesTosaveDB.forEach((x) => {
                          let imageThumbnailUrl = x.Key; // CompanyLogoKey;
                          // AWSService.config.accessKeyId = environment.amazon_access_key;
                          // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                          AWSService.config.update({
                            region: 'us-west-2',
                            credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                              response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                          });
                          const s3 = new window.AWS.S3();

                          const paramsCompanyThumb = {
                            Bucket: response.Data.amazon_thumbnail_bucket_name,
                            Key: 'resized-images/' + x.Key // CompanyLogoKey
                          };
                          imageThumbnailUrl = s3.getSignedUrl('getObject', paramsCompanyThumb);

                          if (uploadFileTargetName === 'BodyBanner') {
                            this.ShowBannerLoadingLoader = false;
                            this.agencyFormGroup.controls['BodyBanner'].setValue(data.Location);
                            this.agencyFormGroup.controls['BodyBannerThumbnail'].setValue(imageThumbnailUrl);
                            this.agencyLocationBodyBannerThumbnailUrl = imageThumbnailUrl;
                            this.agencyLocationBodyBannerUrl = data.Location;
                            this.updateAgencyBanner(this.agencyLocationBodyBannerUrl, this.agencyLocationBodyBannerThumbnailUrl);
                          }

                          if (uploadFileTargetName === 'Logo') {
                            this.ShowLogoLoadingLoader = false;
                            this.AgencyLogo = data.Location;
                            this.updateAgencyLogo(this.AgencyLogo, imageThumbnailUrl);
                          }

                          if (!x.Status) {
                            this.isFileValid = false;
                          }
                        });
                      } else if (err !== undefined) {
                        item.Status = false;
                        item.IsError = true;
                        this.isFileValid = true;
                        this.filesTosaveDB.forEach(function (y) {
                          y.Key = y.Key;
                          if (!y.Status) {
                            this.isFileValid = false;
                          }
                        });
                      }
                    }).on('httpUploadProgress', function (progress) {
                      if (Math.round(progress.loaded / progress.total * 100).toString() === '100') {
                      }
                      this.UpdateProgress(progress.key, Math.round(progress.loaded / progress.total * 100).toString());
                    });
                  }
                } else {
                  this.filesTosaveDB.splice(index, 1);
                  this.toasting(item.Name + '.' + item.Extention + ' is not allowed!', 3000, 'red');
                  if (uploadFileTargetName === 'BodyBanner') {
                    this.IsLogoImageFileUploadFailed = true;
                    this.IsBodyBannerFileUploadFailed = false;
                  }
                  if (uploadFileTargetName === 'Logo') {
                    this.IsBodyBannerFileUploadFailed = true;
                    this.IsLogoImageFileUploadFailed = false;
                  }
                }
              });
            }
          }, (error) => {
            this.toasting('Server Issue. Try again after sometime', 2000, 'red');
          });
      } else { this.isFileValid = false; }
    }
  }
  // update file progress on bar
  public UpdateProgress(key, progress) {
    this.filesTosaveDB.forEach((item) => {
      if (item.Key === key) {
        item.Progress = progress;
        return;
      }
    });
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // read file upload
  readFiles(files: any, index = 0, fileInputName) {
    // Create the file reader
    const reader = new FileReader();
    // If there is a file
    if (index in files) {
      // Start reading this file
      this.readFile(files[index], reader, (result: any) => {
        this.upLoadedFileUrl = result;
        this.readFiles(files, index + 1, fileInputName); // Read the next file;
      });
    } else {
      // When all files are done This forces a change detection
      this.changeDetectorRef.detectChanges();
    }
  }
  // read single file
  readFile(file: any, reader: any, callback: any) {
    // Set a callback funtion to fire after the file is fully loaded
    reader.onload = () => {
      // callback with the results
      callback(reader.result);
    };
    // Read the file
    reader.readAsDataURL(file);
  }
  // check file extension
  public CheckFileExtention(extention) {
    const exten = (extention) ? extention.toLowerCase() : '';
    if (this.extens.indexOf(exten) > -1) {
      return true;
    } else {
      return false;
    }
  }
  // edit Location details
  public EditDetails() {
    this.submitted = false;
    this.agencyFormGroup = new FormGroup({
      AgencyId: new FormControl(this.globleDataSource['AgencyId'], [Validators.required]),
      LocationId: new FormControl(this.globleDataSource['LocationId'], [Validators.required]),
      LocationName: new FormControl(this.agencyLocationLocationName, [Validators.required]), // this.agencyLocationLocationName),

      LocationDescription: new FormControl(this.agencyLocationLocationDescription),
      Address1: new FormControl(this.agencyLocationAddress1, [Validators.required, Validators.minLength(2), Validators.maxLength(200)]),
      Address2: new FormControl(this.agencyLocationAddress2, [Validators.minLength(2), Validators.maxLength(200)]),

      City: new FormControl(this.agencyLocationCity, [Validators.minLength(2), Validators.maxLength(90)]),
      State: new FormControl(this.agencyLocationState),
      ZipCode: new FormControl(this.agencyLocationZipCode),

      ContactNumber: new FormControl(this.agencyLocationContactNumber, [Validators.maxLength(15)]),
      BodyBanner: new FormControl(this.agencyLocationBodyBannerUrl),
      BodyBannerThumbnail: new FormControl(this.agencyLocationBodyBannerThumbnailUrl),

      Address1latitude: new FormControl(this.Address1latitude),
      Address1longitude: new FormControl(this.Address1longitude),
      DescriptionTitle: new FormControl(this.DescrptionTitle, [Validators.required]),

      Address1PlaceId: new FormControl(''),
      Address2latitude: new FormControl(''),
      Address2longitude: new FormControl(''),

      Address2PlaceId: new FormControl(''),
      AgencyName: new FormControl(this.AgencyName, [Validators.required])
    });
    this.ngDescrptionTitle = this.DescrptionTitle;

    this.agencyLocationLocationDescription = typeof this.agencyLocationLocationDescription === 'object'
      && this.agencyLocationLocationDescription ?
      this.agencyLocationLocationDescription.changingThisBreaksApplicationSecurity : this.agencyLocationLocationDescription;

    this.htmlContent = this.agencyLocationLocationDescription;
    this.IsInEditMode = true;
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('search_places')).value = this.agencyLocationAddress1;
    }, 0);
  }
  // cancel edit
  public CancelEdit() {
    this.IsInEditMode = false;
    this.agencyFormGroup.reset();
  }
  // save location detail
  public SaveLocationDetails() {
    this.submitted = true;
    if (this.htmlContent && this.htmlContent.changingThisBreaksApplicationSecurity) {
      this.agencyFormGroup.controls['LocationDescription']
        .setValue(this.htmlContent.changingThisBreaksApplicationSecurity.changingThisBreaksApplicationSecurity);

    } else {
      this.agencyFormGroup.controls['LocationDescription']
        .setValue(this.htmlContent);
    }

    this.agencyFormGroup.controls['Address1'].setValue($('#search_places').val());
    this.agencyFormGroup.controls['DescriptionTitle'].setValue(this.ngDescrptionTitle);

    if ((<HTMLInputElement>document.getElementById('search_places')).value !== this.AddressDescription) {
      this.agencyFormGroup.controls['Address1latitude'].setValue('');
      this.agencyFormGroup.controls['Address1longitude'].setValue('');
    } else {
      this.agencyFormGroup.controls['Address1latitude'].setValue(this.Address1latitude);
      this.agencyFormGroup.controls['Address1longitude'].setValue(this.Address1longitude);
    }
    if (this.agencyFormGroup.valid) {
      this.showSetingSaveLoader = true;
      this._userService.updatePublicPageSettings(this.agencyFormGroup.value).subscribe(response => {
        if (response.Status) {
          this.IsInEditMode = false;
          this.getPublicFacePageSettings();
          this.toasting('Information saved', 2000, 'green');
          // redirect to dashboard
          this.IsInEditMode = false;
        } else {
          this.toasting(response.Message, 2000, 'red');
        }
        this.showSetingSaveLoader = false;
      }, error => {
        if (error.status === 422) {
        } else {
          this.toasting('Some Error occur while adding records ', 2000, 'red');
        }
        this.showSetingSaveLoader = false;
      });
    } else {
      this.toasting('Please fill required field.', 3000, 'red');
    }
  }
  // update agency logo db
  private updateAgencyLogo(AgencyLogoUrl, AgencyThumbnailUrl) {
    this.ShowLogoLoadingLoader = true;
    this._userService.AgencyLogoSubmit(
      AgencyLogoUrl,
      AgencyThumbnailUrl,
      this.globleDataSource['AgencyId']
    ).subscribe(
      result => {
        if (result.Status) {
          this.ShowLogoLoadingLoader = false;
          this.toasting('Agency Logo updated successfully!', 2000, 'green');
          this._EventBus.emit({ Type: 'update-banner', data: { logoUrl: AgencyLogoUrl } });
        }
      },
      error => {
        this.ShowLogoLoadingLoader = false;
      }
    );
  }
  // update agency banner db
  private updateAgencyBanner(AgencyBannerUrl, AgencyBannerThumbnailUrl) {
    this.ShowBannerLoadingLoader = true;
    this._userService.UploadAgencyBanner(
      AgencyBannerUrl,
      AgencyBannerThumbnailUrl,
      this.globleDataSource['AgencyId'],
      this.globleDataSource['LocationId']
    ).subscribe(
      result => {
        if (result.Status) {
          this.ShowBannerLoadingLoader = false;
          this.toasting('Agency Banner updated successfully!', 2000, 'green');
        }
      },
      error => {
        this.ShowBannerLoadingLoader = false;
      }
    );
  }
  // is banner loaded
  onBannerLoad() {
    this.isBannerLoaded = true;
  }
  // google auto complete address
  autoCompleteCallback1(selectedData: any) {
    if (selectedData.data) {
      const place = selectedData.data;
      const name = place.name;
      let stroute_flag = 0;
      let stroute = '';
      let city_flag = 0;
      let state_flag = 0;
      let zip_flag = 0;

      this.AddressDescription = place.description;
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (this.strouteComponent[addressType]) {
          stroute_flag = 1;
          stroute = place.address_components[i][this.strouteComponent[addressType]];
        }
        if (this.cityComponent[addressType]) {
          city_flag = 1;
          this.city = place.address_components[i][this.cityComponent[addressType]];
        }
        if (this.stateComponent[addressType]) {
          state_flag = 1;
          this.state = place.address_components[i][this.stateComponent[addressType]];
        }
        if (this.postalComponent[addressType]) {
          zip_flag = 1;
          this.zipcode = place.address_components[i][this.postalComponent[addressType]];
        }
      }

      if (city_flag) {
        this.AgencyCity = this.city;
      } else {
        this.AgencyCity = '';
      }
      if (state_flag) { this.AgencyState = this.state; } else { this.AgencyState = ''; }
      if (zip_flag) { this.AgencyZipCode = this.zipcode; } else { this.AgencyZipCode = ''; }
      this.street_name = name;
      this.stroute_flag = stroute_flag;
      this.stroute = stroute;

      this.agencyFormGroup.controls['City'].setValue(this.city);
      this.agencyFormGroup.controls['State'].setValue(this.state);
      this.agencyFormGroup.controls['ZipCode'].setValue(this.zipcode);

      if (place && place.geometry && place.geometry.location) {
        this.lat = Number(place.geometry.location.lat);
        this.long = Number(place.geometry.location.lng);

        this.Address1latitude = this.lat.toFixed(3);
        this.Address1longitude = this.long.toFixed(3);
      }
    } else {
      this.Address1latitude = '';
      this.Address1longitude = '';
    }
  }
  // edit banner image
  public editBanner() {
    const element: HTMLElement = document.getElementById('bannerRef') as HTMLElement;
    element.click();
  }
}
