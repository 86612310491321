import { Component, ViewChild, ElementRef } from '@angular/core';

import { toast } from 'angular2-materialize';

@Component({
  templateUrl: './privacypolicy.component.html'
})
export class PrivacyPolicyComponent {
  @ViewChild('video') private video: ElementRef;
  IsMute: boolean;
  constructor() {

  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  public muteVideo() {
    if (this.video.nativeElement.volume !== 0) {
      this.video.nativeElement.volume = 0;
      this.IsMute = true;
    } else {
      this.video.nativeElement.volume = 1;
      this.IsMute = false;
    }
  }
}
