import { Component, OnInit, EventEmitter, HostListener, Renderer2, OnDestroy, ViewChild } from '@angular/core';
import { TokboxService } from '../../model/tokbox.service';
import { TranslateService } from 'ng2-translate';
import { UserService } from '../../model/user.service';
import '../../../assets/tokbox/js/app.js';
import { Intercom } from 'ng-intercom';
import { EventBus } from '../EventBus/EventBus';
import { Router } from '@angular/router';
import { Activity } from '../../model/activity';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../../model/global.service';
import { CustomFormComponent } from '../add-card/add-card.component';

declare var myExtObject: any;
declare var $: any;

@Component({
  selector: 'app-ax-agent-header',
  templateUrl: './agent-header.component.html'
})

export class AgentHeaderComponent implements OnInit, OnDestroy {
  Count: number;
  SendClickNotif: EventEmitter<boolean>;
  LanguageFlag: string;
  LoggedInUserName: string;
  LoginUserId: number;
  AgencyLogo: string;
  AgencyId: number;
  LocationId: number;
  AgencyName: string;

  LoggedInUserRoleId: string;
  LoggedInUserRoleName: string;
  LoggedInUserPhone: string;
  LoggedInUserEmail: string;
  SubscriptionStatusId: number;
  public isCallButtonEnabled: boolean;
  public innerWidth: any;
  public openNotification: false;

  Activity: Activity[];
  ActivityData: Activity;
  AllActivityCount = 0;
  SeenActivityCount = 0;
  UnSeenActivityCount = 0;
  LoadMoreActivityCounter = 0;
  ShouldWellRing: boolean;
  @ViewChild(CustomFormComponent) CustomFormComponentViewChild: CustomFormComponent;
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.LogoutTokbox();
  }

  constructor(
    public intercom: Intercom,
    public translate: TranslateService,
    private tokboxService: TokboxService,
    private _userService: UserService,
    private _globalService: GlobalService,
    private _EventBus: EventBus,
    private renderer: Renderer2,
    private _router: Router, private _domSanitizer: DomSanitizer
  ) {
    this.isCallButtonEnabled = true;
    this.innerWidth = window.innerWidth;
    this.ChangeLanguage(this.translate.getDefaultLang());
    this.Count = 1;
    this.Activity = [];
    // get agency/user detail from token
    const UserModel = this._userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyLogo = UserModel.user.AgencyLogo;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.AgencyName = UserModel.user.AgencyName;
      this.SubscriptionStatusId = UserModel.user.SubscriptionStatusId;
      this.GetUserActivities(true);
    }
    this.intercom.shutdown();
    this.renderer.addClass(document.body, 'user_protal');
    this.GetAwsSeetingKeys();
  }

  ngOnInit() {
    myExtObject.initializeTokbox();

    this._EventBus.on().subscribe(m => {
      if (m.filterBy.Type === `header-left-statics`) {
        const userModel = m.filterBy.data;
        if (userModel) {
          if (userModel.AgencyLogo && this.AgencyLogo !== userModel.AgencyLogo) {
            this.AgencyLogo = userModel.AgencyLogo;
          }
          if (userModel.AgencyId && this.AgencyId !== userModel.AgencyId) {
            this.AgencyId = userModel.AgencyId;
          }
          if (userModel.AgencyName && this.AgencyName !== userModel.AgencyName) {
            this.AgencyName = userModel.AgencyName;
          }
          if (userModel.LoggedInUserName && this.LoggedInUserName !== userModel.LoggedInUserName) {
            this.LoggedInUserName = userModel.LoggedInUserName;
          }
          if (userModel.LoggedInUserId && this.LoginUserId !== userModel.LoggedInUserId) {
            this.LoginUserId = userModel.LoggedInUserId;
          }

          if (userModel.LoggedInUserRoleId && this.LoggedInUserRoleId !== userModel.LoggedInUserRoleId) {
            this.LoggedInUserRoleId = userModel.LoggedInUserRoleId;
          }
          this._EventBus.emit({ Type: `header-app-component`, data: userModel });
        }
      } else if (m.filterBy.Type === `Call-Agent-Button-Enable`) {
        this.isCallButtonEnabled = m.filterBy.data;
      } else if (m.filterBy.Type === 'update-banner') {
        if (m.filterBy.data && m.filterBy.data.logoUrl) {
          this.AgencyLogo = m.filterBy.data.logoUrl;
        }
      } else if (m.filterBy.Type === 'UPDATE_ACTIVITY_TRIGGERED') {
        const activityData = m.filterBy.data.activityData;
        this.ActivityData = JSON.parse(activityData);
        // this.UpdateActivityArray(this.ActivityData);
        this.GetUserActivities(true);
        this.ShouldWellRing = true;
        window.setTimeout(() => { this.ShouldWellRing = false; }, 30000);
      } else if (m.filterBy.Type === 'MARKED_AS_READ_ACTIVITIES') {
        this.MarkedActivitiesAsRead(m.filterBy.data);
      } else if (m.filterBy.Type === 'OPEN_PAYMENT_POPUP_USER_HEADER') {
        this.openAddCardModal();
      }

    });

    // Get device width
    this.innerWidth = window.innerWidth;
    $('.notification_dropdown').on('click', function (event) {
      event.stopPropagation();
    });

  }
  // open add card modal
  openAddCardModal() {
    this.CustomFormComponentViewChild.openAddCardModal();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'user_protal');
  }

  public LogoutTokbox() {
    myExtObject.logout(true);
  }
  // get aws keys for upload file to database
  private GetAwsSeetingKeys() {
    this._userService.GetAWSSettingKeys().subscribe((response) => {
      if (response.Status && response.Data) {

        const data = this._globalService.DecryptSetASEAWSKeys(response.Data);
        const globleDataSourceState = this._globalService.globleDataSource;
        globleDataSourceState['amazon_access_key'] = data.AWS_Key;
        globleDataSourceState['amazon_secret_access_key'] = data.AWS_Secret;
        globleDataSourceState['amazon_bucket_name'] = data.AWS_Bucket_Name;

        this._globalService.changeGlobleDataSourceState(globleDataSourceState);
      }

    }, error => {
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }

  public ChangeLanguage(value) {
    if (value === 'English') {
      this.LanguageFlag = 'assets/flags/US.png';
    } else {
      this.LanguageFlag = 'assets/flags/BL.png';
    }
    this.translate.use(value);
  }
  // start random call to agent
  public StartRandomCall(isCallButtonEnabled?: boolean) {
    // Get Agent UserId who need to be called as per random logic
    const connectDivEle = $('#connected_screen');
    const IsCallInProgress = connectDivEle.css('display') === 'none' ? false : true;
    if (isCallButtonEnabled && !IsCallInProgress) {
      this.isCallButtonEnabled = false;
      this.tokboxService.getrandomagentid(this.AgencyId, this.LocationId)
        .subscribe(
          result => {
            if (result.Status) {
              let responderName: string, responderImage: string, toInitial: string;
              if (result.Data) {
                responderName = `${result.Data[0].FirstName} ${result.Data[0].LastName}`;
                responderImage = result.Data[0].Picture;
                toInitial = this.GetInitials(responderName);
              }
              this.GenerateSessionIdAndTokenId(result.ToUserId, toInitial, responderImage, responderName);
            } else {
            }
          },
          error => {

          }
        );
    }
  }

  public GenerateSessionIdAndTokenId(ToUserId, toInitial, responderImage, responderName) {
    this.tokboxService.createsessionandtoken(this.LoginUserId, ToUserId)
      .subscribe(
        result => {
          if (result.Status) {
            myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2, ToUserId, result.currentSessionId,
              responderImage, toInitial, responderName);
          } else {
            alert(result.Message);
          }
        },
        error => {
        }
      );
  }

  public toggleNav() {
    this.SendClickNotif.emit(true);
  }

  public OpenSidebar() {
    this._EventBus.emit({ Type: 'toggleressidebar', data: { sidebarStatus: true } });
  }

  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

  }
  // get intial of users
  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/']);
  }
  // get user activities
  GetUserActivities(isResetArray = false, offset = 0, activityModuleId = 0, activityType = 0) {
    const postData = {
      UserId: this.LoginUserId,
      OffSet: offset,
      ActivityModuleId: activityModuleId,
      ActivityType: activityType,
    };
    this._userService.GetUserActivitiesForUser(postData).subscribe((respo: any) => {
      if (isResetArray) {
        this.Activity = [];
      }
      const activityData = respo && respo.Data ? respo.Data : [];
      activityData.forEach((element: Activity) => {
        this.Activity.push(element);
      });
      this.RefreshActivityCount();
      this._EventBus.emit({
        Type: 'GET_UPDATED_ACTIVITY_TRIGGERED', data:
        {
          data: this.Activity,
          AllActivityCount: this.AllActivityCount,
          SeenActivityCount: this.SeenActivityCount,
          UnSeenActivityCount: this.UnSeenActivityCount
        }
      });
    }, error => {
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }
  private UpdateActivityArray(activity: Activity) {
    if (activity) {
      activity.Id = Number(activity.Id);
      const index = this.Activity.findIndex(x => x.Id === activity.Id);
      if (index > -1) {
        this.Activity[index] = activity;
      } else {
        if (this.Activity) {
          this.Activity.unshift(activity);
        } else {
          this.Activity = [activity];
        }
        this.AllActivityCount = this.AllActivityCount + 1;
        this.UnSeenActivityCount = this.UnSeenActivityCount + 1;
      }
      this._EventBus.emit({
        Type: 'GET_UPDATED_ACTIVITY_TRIGGERED',
        data: {
          data: this.Activity,
          AllActivityCount: this.AllActivityCount,
          SeenActivityCount: this.SeenActivityCount,
          UnSeenActivityCount: this.UnSeenActivityCount
        }
      });
    }
  }
  // convert date to local date
  GetFormatedDate(date) {
    return new Date(date).toLocaleString();
  }
  // refresh activity count
  public RefreshActivityCount() {
    if (this.Activity && this.Activity.length > 0) {
      const acivity = this.Activity[0];
      this.AllActivityCount = acivity.TotalActivityCount;
      this.SeenActivityCount = acivity.SeenCount;
      this.UnSeenActivityCount = acivity.UnSeenCount;
    } else {
      this.AllActivityCount = 0;
      this.SeenActivityCount = 0;
      this.UnSeenActivityCount = 0;
    }
  }
  // mark activities as read
  MarkedActivitiesAsRead(shouldRedirect = false) {
    const UnReadActivitiesIds = [];
    this.openNotification = false;
    this.Activity.forEach((element: Activity) => {
      if (!element.ActivitySeenStatus) {
        UnReadActivitiesIds.push(element.Id);
      }
    });
    this._userService.MarkedActivitiesAsRead({
      UserId: this.LoginUserId,
      ActivitiesId: UnReadActivitiesIds
    })
      .subscribe((apiResponse) => {
        if (!shouldRedirect) {
          this.GetUserActivities(true);
        }
      },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        });
    if (shouldRedirect) {
      this._router.navigate(['/user/notifications']);
    }
  }
  // on scroll down load activities
  public onScrollDownActivities() {
    this.LoadMoreActivityCounter = this.LoadMoreActivityCounter + 50;
    this.GetUserActivities(false, this.LoadMoreActivityCounter);
  }
  // convert payment amount to two decimal
  GetFormatedDollor(dollor) {
    return Number(dollor).toFixed(2);
  }

  identifyActivity(index: number, item: Activity) {
    return item.Id;
  }
}
