import { Component, EventEmitter } from '@angular/core';
import { UserService } from '../../model/user.service';
import { EventBus } from '../../shared/EventBus/EventBus';
import { Router } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
declare var $: any;
import { AgentData } from '../agent_data';

@Component({
    templateUrl: './agencyagentprofile.component.html'
})

export class AgencyAgentProfileComponent {
    AgencyData = new AgentData('', '', '', '', '', 0);
    model1Params: any;
    modalActions1 = new EventEmitter<string | MaterializeAction>();
    modalActions2 = new EventEmitter<string | MaterializeAction>();
    ShareUrl: string;
    FiveDigitCode: string;
    _locations: any[];
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    AgencyLogo: string;
    AgencyId: number;
    AgencyName: string;
    AgencyAddress1: string;
    AgencyAddress2: string;
    AgencyCity: string;
    AgencyState: string;
    LoggedInUserId: number;
    AgencyZipCode: string;
    SelectedLocationId: number;
    city: string;
    state: string;
    zipcode: string;
    lat: any;
    long: any;
    street_name: string;
    stroute_flag: any;
    stroute: string;

    strouteComponent = {
       'sublocality_level_1': 'long_name'
    };
    stateComponent = {
       administrative_area_level_1: 'long_name'
    };
    postalComponent = {
        postal_code: 'short_name'
    };
    cityComponent = {
       locality: 'long_name'
    };

   constructor(private _router: Router, private _userService: UserService, private _EventBus: EventBus) {
        const UserModel = this._userService.decodeToken();
        this.SelectedLocationId = parseInt(this._userService.GetSelectedLocation(), null);
        // get agency/user data from token
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.AgencyLogo = UserModel.user.AgencyLogo;
            this.AgencyName = UserModel.user.AgencyName;
            this.AgencyData.AgencyName = this.AgencyName;
            this.AgencyId = UserModel.user.AgencyId;
            this.LoggedInUserId = UserModel.user.UserId;
        }
    }
    // open edit agency modal
    editAgencyProfile() {
        this.modalActions1.emit({ action: 'modal', params: ['open'] });
    }
    // open change password modal
    changePassword() {
        this.modalActions2.emit({ action: 'modal', params: ['open'] });
    }
    // Google auto complete address
    autoCompleteCallback1(selectedData: any) {
        const place = selectedData.data;
        const name = place.name;
        let stroute_flag = 0;
        let stroute = '';
        let city_flag = 0;
        let state_flag = 0;
        let zip_flag = 0;
        for (let i = 0; i < place.address_components.length; i++) {
            const addressType = place.address_components[i].types[0];
            if (this.strouteComponent[addressType]) {
                stroute_flag = 1;
                stroute = place.address_components[i][this.strouteComponent[addressType]];
            }
            if (this.cityComponent[addressType]) {
                city_flag = 1;
                this.city = place.address_components[i][this.cityComponent[addressType]];
            }
           if (this.stateComponent[addressType]) {
                state_flag = 1;
                this.state = place.address_components[i][this.stateComponent[addressType]];
           }
           if (this.postalComponent[addressType]) {
                zip_flag = 1;
                this.zipcode = place.address_components[i][this.postalComponent[addressType]];
            }
        }

        if (city_flag) {
           this.AgencyData.AgencyCity = this.city;
        } else {
            this.AgencyData.AgencyCity = '';
        }
        if (state_flag) {
            this.AgencyData.AgencyState = this.state;
        } else {
           this.AgencyData.AgencyState = '';
        }
        if (zip_flag) {
            this.AgencyData.AgencyZipCode = this.zipcode;
        } else {
            this.AgencyData.AgencyZipCode = '';
        }
        this.street_name = name;
        this.stroute_flag = stroute_flag;
        this.stroute = stroute;
        setTimeout(() => {
           $('#setPropAddresStreet').click();
        }, 500);
    }
    // set street address
    setAddressStreet() {
        if (this.street_name !== '' || this.stroute_flag) {
           if (this.stroute_flag === 1) {
                this.AgencyData.AgencyAddress1 = this.street_name + ', ' + this.stroute;
           } else {
                this.AgencyData.AgencyAddress1 = this.street_name;
           }
           $('#search_places').val(this.AgencyData.AgencyAddress1);
        }
    }
    onSubmit() {
        // alert('yess');
    }
}
