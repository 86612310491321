import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from 'ng2-translate';
import { Router, NavigationEnd } from '@angular/router';
import { EventBus } from './shared/EventBus/EventBus';
import { UserService } from './model/user.service';
import { PushNotificationsService } from './model/notification.service';
import { toast } from 'angular2-materialize';
declare var myExtObject: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  AgencyLogo: string;
  AgencyId: number;
  AgencyName: string;
  LoggedInUserName: string;
  LoggedInUserRoleId: string;
  LoggedInUserRoleName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserPhone: string;
  LoggedInUserEmail: string;
  LoggedInUserId: number;
  LoginRoleId: number;
  ShowHeader: boolean;
  ShowSideBar: boolean;
  ShowAgentList: boolean;
  constructor(private _notificationService: PushNotificationsService, public translate: TranslateService, private _EventBus: EventBus,
    private _userService: UserService, private _router: Router, private _ngZone: NgZone) {
    this._notificationService.requestPermission();
    window['angularComponentRef'] = {
      zone: this._ngZone,
      componentAddNewCardFn: (value) => this.addedNewCard(value),
      // componentAddAgencyMatrixFn: (value) => this.addAgencyMatrix(value),
      componentCallAgentButtonEnableFn: (value) => this.enableCallAgentButton(value),
      showNotification: (value) => this.CallNotification(value),
      closeNotification: (value) => this.EndNotification(),
      toast: (message, type, duration) => this.toasting(message, duration, type),
      updateActivityFn: (data) => this.updateActivitis(data),
      redirectMessagetab: () => this.redirectMessagetab(),
      component: this
    };

    translate.addLangs(['English', 'French']);
    translate.setDefaultLang('English');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
      // get agency/user detail from token
      const UserModel = this._userService.decodeToken();
      this.ShowHeader = true;
      this.ShowSideBar = true;
      this.ShowAgentList = true;
      if (UserModel && UserModel.user) {
      this.AgencyLogo = UserModel.user.AgencyLogo;
      this.AgencyId = UserModel.user.AgencyId;
      this.AgencyName = UserModel.user.AgencyName;
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserId = UserModel.user.UserId;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
      this.LoginRoleId =  UserModel.user.RoleId;
    }
    _router.events
    .filter(event => event instanceof NavigationEnd)
    .subscribe((event: NavigationEnd) => {
      // You only receive NavigationEnd events
      if (event.url !== 'user/messages' && event.url !== 'agency/messages' && event.url !== 'user/messages') {
        localStorage.removeItem('activeChatUserId');
      }
    });
    this._EventBus.on().subscribe((m) => {
      if (m.filterBy.Type === 'event-authorized') {
        const data = m.filterBy.data;
        const UserModelEvent = this._userService.decodeToken();
        if (UserModelEvent && UserModelEvent.user && data && data.IsAuthorized) {
          this.ShowHeader = true;
          this.ShowSideBar = true;
          this.ShowAgentList = true;
          this.AgencyLogo = UserModelEvent.user.AgencyLogo;
          this.AgencyId = UserModelEvent.user.AgencyId;
          this.AgencyName = UserModelEvent.user.AgencyName;
          this.LoggedInUserName = UserModelEvent.user.Name;
          this.LoggedInUserId = UserModelEvent.user.UserId;
          this.LoggedInUserRoleId = UserModelEvent.user.RoleId;
        } else {
          this.LoggedInUserRoleId = '-1';
          this.ShowHeader = false;
          this.ShowSideBar = false;
          this.ShowAgentList = false;
        }
      } else if (m.filterBy.Type === 'header-app-component') {
        const userModel = m.filterBy.data;
        this.ShowHeader = true;
        this.ShowSideBar = true;
        this.ShowAgentList = true;
        if (userModel) {
          if (userModel.AgencyLogo && this.AgencyLogo !== userModel.AgencyLogo) {
            this.AgencyLogo = userModel.AgencyLogo;
          }
          if (userModel.AgencyId && this.AgencyId !== userModel.AgencyId) { this.AgencyId = userModel.AgencyId; }

          if (userModel.AgencyName && this.AgencyName !== userModel.AgencyName) { this.AgencyName = userModel.AgencyName; }

          if (userModel.LoggedInUserName && this.LoggedInUserName !== userModel.LoggedInUserName) {
            this.LoggedInUserName = userModel.LoggedInUserName;
          }

          if (userModel.LoggedInUserId && this.LoggedInUserId !== userModel.LoggedInUserId) {
            this.LoggedInUserId = userModel.LoggedInUserId;
          }

          if (userModel.LoggedInUserImageUrl && this.LoggedInUserImageUrl !== userModel.LoggedInUserImageUrl) {
            this.LoggedInUserImageUrl = userModel.LoggedInUserImageUrl;
          }

          if (userModel.LoggedInUserRoleId && this.LoggedInUserRoleId !== userModel.LoggedInUserRoleId) {
            this.LoggedInUserRoleId = userModel.LoggedInUserRoleId;
          }
        }
      } else if (m.filterBy.Type === 'show-authorized-header') {
        if (m.filterBy.data) {
          this.ShowHeader = m.filterBy.data.ShowHeader;
          this.ShowSideBar = m.filterBy.data.ShowSideBar;
          this.ShowAgentList = m.filterBy.data.ShowAgentList;
        }
      } else if (m.filterBy.Type === 'IS_HIDE_CALL_DIV') {
        this.ShowAgentList = m.filterBy.data;
      } else if (m.filterBy.Type === 'SHOULD_AGENT_INVISIBLE') {
        localStorage.setItem('isagntavbl', JSON.stringify({ isTrue: m.filterBy.data }));
        this.ToggelAgentVisibility(m.filterBy.data);
      }
    });
  }

  updateActivitis(data) {
    if (data.data) {
      this._EventBus.emit({ Type: 'UPDATE_ACTIVITY_TRIGGERED', data: data.data });
    }
  }

  CallNotification(data) {
    this._notificationService.generateNotification(data);
  }
  EndNotification() {
    this._notificationService.close();
  }

  ngOnDestroy() {
    window['angularComponentRef'] = null;
  }

  addedNewCard(isAdded) {
    if (isAdded) { this._EventBus.emit({ Type: 'Payment-Card-Added', data: true }); }
  }

  enableCallAgentButton(shouldEnable: boolean) {
    this._EventBus.emit({ Type: `Call-Agent-Button-Enable`, data: shouldEnable });
  }

  onScrollDown() {
  }

  onScrollUp() {

  }

  ngOnInit() {
  }

  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  private ToggelAgentVisibility(IsVisible) {
    if (IsVisible) {
      myExtObject.initializeTokbox();
    } else {
      myExtObject.logout(false);
    }
  }
  redirectMessagetab() {
    const ActiveUrl = this._router.url;
    if (this.LoginRoleId === 2 && ActiveUrl.indexOf('/user/message') === -1) {
      this._router.navigate(['/user/message']);
    } else if (this.LoginRoleId === 3 && ActiveUrl.indexOf('/user/messages') === -1) {
      this._router.navigate(['/user/messages']);
    } else if (this.LoginRoleId === 6 && ActiveUrl.indexOf('/agency/messages')  === -1) {
      this._router.navigate(['/agency/messages']);
    }
  }
}
