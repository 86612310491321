import { NgModule } from '@angular/core';
import { ProductModalComponent } from './product-detail.component';
import { CustomCommonModule } from '../custom-common/custom-common.module';

@NgModule({
  imports: [
    CustomCommonModule
  ],
  declarations: [ProductModalComponent],
  exports: [ProductModalComponent]
})
export class ProductDetailModelModule { }
