import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, APP_INITIALIZER } from '@angular/core';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { IntercomModule } from 'ng-intercom';
import { JwtModule } from '@auth0/angular-jwt';
import { AgmCoreModule } from '@agm/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angular5-social-auth';
import { MaterializeModule } from 'angular2-materialize';

// import { AgmCoreModule } from '@agm/core';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AgencySignupComponent } from './signup/agencysignup.component';
import { StepFirstComponent } from './signup/step_1.component';
import { ForgetPasswordComponent } from './forgetpassword/forgetpassword.component';
import { AgentComponent } from './agent/agent.component';
import { AgencyAdminDahsboardComponent } from './agencyadmin/dashboard/agencyadmindashboard.component';
import { AgencyAdminProfileComponent } from './agencyadmin/agency-profile/agencyadminprofile.component';
import { AgencyAgentProfileComponent } from './agent/profile/agencyagentprofile.component';
import { AgencyAdminReferralComponent } from './agencyadmin/agency-referral/agencyadminreferral.component';
import { AgencyAdminUserProfileComponent } from './agencyadmin/admin-profile/agencyadminuserprofile.component';
import { AgencyAdminDashboardUserComponent } from './agencyadmin/dashboard-user/agencyadmindashboarduser.component';
import { AgencyAdminDashboardUserDetailComponent } from './agencyadmin/dashboard-user-detail/agencyadmindashboarduserdetail.component';
import { AgencyAdminDashboardAgentComponent } from './agencyadmin/dashboard-agent/agencyadmindashboardagent.component';
import { AgencyadminDashboardPaymentComponent } from './agencyadmin/dashboard-payment/agencyadmindashboardpayment.component';
import { AgencyAdminDashboardCallComponent } from './agencyadmin/dashboard-calls/agencyadmindashboardcall.component';
import { AgencyAdminDashboardProductComponent } from './agencyadmin/dashboard-product/agencyadmindashboardproduct.component';
import { AgencyPendingReferalComponent } from './agencyadmin/pending-referral/agencypendingreferal.component';
import { UserProductComponent } from './user/user-product/userproduct.component';
import { AgencyAdminDashboardSubscriptionsComponent } from './agencyadmin/agency-subscription/agencyadmindashboardsubscriptions.component';
import { AgencyUpdatePlanComponent } from './agencyadmin/upgrade-account/upgrade_account.component';
import { AgencyAdminSettingsComponent } from './agencyadmin/dashboard-setting/agencyadminsettings.component';



import { UserComponent } from './user/user.component';
import { UserCallComponent } from './user/user-call/usercall.component';
import { UserMessageComponent } from './user/user-message/usermessage.component';
import { UserDetailComponent } from './user/user-detail/userdetail.component';
import { ReferralComponent } from './agent/referral/referral.component';
import { AgencyLandingComponent } from './agencyadmin/landing/landing.component';
import { AgencyPricingComponent } from './agencyadmin/pricing/pricing.component';
import { AgencySubscriptionCancel } from './agencyadmin/subscription-cancel/agencysubscriptioncancel.component';
import { UiKitComponent } from './ui-kit/ui-kit.component';
import { InfiniteScrollModule, InfiniteScroll } from 'angular2-infinite-scroll';

import { TranslateModule, TranslateLoader, TranslateStaticLoader } from 'ng2-translate';

import { Location, LocationStrategy, PathLocationStrategy, CommonModule } from '@angular/common';

// Shared
import { AuthGuard } from './model/auth.guard';
import { AuthHomeGuard } from './model/authHome.guard';
import { TextMaskModule } from 'angular2-text-mask';
import { AuthStepperGuard } from './model/authStepper.guard';
import { AuthPricingGuard } from './model/authPricing.guard';
import { AnonymousGuard } from './model/anonymous.guard';


// Model & Services
import { UserDataService } from './model/user-data.service';
import { GlobalService } from './model/global.service';
import { UserService } from './model/user.service';
import { SettingDataService } from './model/setting-data.service';
import { NgGridModule } from 'angular2-grid';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { MomentModule } from 'angular2-moment';
// Import Opentok library

import { OpentokModule } from 'ng2-opentok/dist/opentok.module';
import { TokboxService } from './model/tokbox.service';

import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

import { AgentHeaderComponent } from './shared/agent-header/agent-header.component';
import { AgentSidebarComponent } from './shared/agent-sidebar/agent-sidebar.component';
import { AgentAgencyHeaderComponent } from './shared/agent-agency-header/agent-agency-header.component';
import { AgentAgencySidebarComponent } from './shared/agent-agency-sidebar/agent-agency-sidebar.component';
import { NoActiveCallsComponent } from './shared/noactivecalls/noactivecalls.component';
import { DocumentComponent } from './agent/document/document.component';
import { ScrollbarModule } from 'ngx-scrollbar';
import { UploadDocComponent } from './shared/UploadDocPopUp/UploadDoc.component';
import { EventBus } from './shared/EventBus/EventBus';
import { CustomFormComponent } from './shared/add-card/add-card.component';
// import { ProductModalComponent } from './shared/product-detail-modal/product-detail.component';

import { UserPaymentComponent } from './agent/user-payment/UserPayment.component';
import { UserProfileComponent } from './userprofile/UserProfile.component';
import { AddBankAccountComponent } from './shared/add-bank-account/add-bank-account.component';
import { ProductComponent } from './product/Product.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { ProductDetailComponent } from './product/product-detail/ProductDetail.component';
import { ProductDetailForAgencyComponent } from './product/product-detail-agency/ProductDetailForAgency.component';
import { UploadPenDocComponent } from './shared/UploadPendingDocPopUp/UploadPenDoc.component';
import { UsersignpopupComponent } from './shared/UserSignPopUp/userSignPopUp.component';
import { AgentSignComponent } from './agent/agent-sign/agentsign.component';
import { AgentSignHeaderComponent } from './shared/agent-sign-header/agent-sign-header.component';
import { UserSignHeaderComponent } from './shared/user-sign-header/user-sign-header.component';
import { PendingDocComponent } from './agent/pending-doc/PendingDoc.component';
import { UserSignComponent } from './user/user-sign/usersign.component';
import { AgencyAdminDashboardMessageComponent } from './agencyadmin/dashboard-messages/agencyadmindashboardmessage.component';
import { TimeAgoPipe } from 'time-ago-pipe';
import { Ng2stepperComponent } from './shared/ng2stepper/ng2stepper.component';
import { AgentListComponent } from './shared/agentlist/agent-list.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { NgxEditorModule } from 'ngx-editor';

import { ChartsModule } from 'ng2-charts';

import { AgencyCallsDataComponent } from './shared/AgencyDashboardGraphs/agency-call-data/agencyCallsData.component';
import { AgencyPaymentsDataComponent } from './shared/AgencyDashboardGraphs/agency-payment-data/agencyPaymentsData.component';
import { AgencySignedDocDataComponent } from './shared/AgencyDashboardGraphs/agency-signed-doc/agencySignedDocData.component';
import { ClipboardModule } from 'ng2-clipboard';
import { DatePipe } from './datepipe';
import { NumberOnlyDirective } from './number.directive';
import { SpecialOfferComponent } from './agencyadmin/special-offer/specialoffer.component';
import { UpdateAgencyCardComponent } from './shared/update-agency-card/update-agency-card';
import { ResubscriptionAgencyCardComponent } from './shared/resubscription-agency-card/resubscription-agency-card';
import { UserProductDetailComponent } from './user/product-detail/userproductdetail.component';
import { AgentUserProfileComponent } from './user/profile/agentuserprofile.component';
import { OfferFinishedComponent } from './agencyadmin/offer-finished/offerfinished.component';
import { UserPendingNotificationComponent } from './shared/UserNotifications/user-pending-doc-notification.component';
import { CountDownComponent } from './shared/custom-count-down/countdown';
import { ChatComponent } from './chat/chat.component';
import { DrawModalComponent } from './shared/DrawPopUp/DrawPopUp.component';
import { AgentSignModule } from './agent/agent-sign/agentsign.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { ManagelocationsComponent } from './agencyadmin/locations/managelocations/managelocations.component';
import { AddlocationComponent } from './agencyadmin/locations/addlocation/addlocation.component';
import { FrontFacingComponent } from './frontfacing/frontfacing.component';
import { PublicFacePageComponent } from './public-page/public-face-page.component';
import { environment } from '../environments/environment';
import { PushNotificationsService } from './model/notification.service';
import { PublicPageSettingComponent } from './agencyadmin/public-page-setting/public-page-setting.component';
import { AgentRegisterComponent } from './agent/agent-register/agentRegister.component';
import { AgencyAdminDashboardPaymentMethodComponent } from './agencyadmin/dashboard-payment-methods/agencyadmindashboardpaymentmethod.component';
import { ProductDetailModelModule } from './shared/product-detail-modal/product-detail-model.module';
import { IntegrationPopupComponent } from './agency-matrix-integration/integration-popup/integration-popup.component';
import { AgencyMatrixRegisterModule } from './agency-matrix-register/agency-matrix-register.module';
import { AgencyAgentDahsboardComponent } from './agent/dashboard/agencyagentdashboard.component';
import { ZohoComponent } from './zoho/zoho.component';
import { NotificationsComponent } from './agencyadmin/notifications/notifications.component';
import { AgencyMatrixSettingComponent } from './agencyadmin/agency-matrix-setting/agency-matrix-setting.component';
import { AccountNotExistSettingComponent } from './agencyadmin/agency-matrix-setting/account-not-exist/account-not-exist.component';
import { AccountExistSettingComponent } from './agencyadmin/agency-matrix-setting/account-exist/account-exist.component';
import { EmailNotificationSettingComponent } from './shared/email-notification-setting/email-notification-setting.component';
import { UserSignDocumentPreviewComponent } from './shared/user-sign-preview/usersignPreview.component';
import { UserSignPreviewModalComponent } from './shared/sign-document-preview/sign-document-preview.component';
import { AgencyMatrixRegisterComponent } from './agency-matrix-register/agency-matrix-register.component';
import { AgentSignatureComponent } from './shared/agent-signature/agent-signature.component';
import { EditlocationComponent } from './agencyadmin/locations/editlocation/editlocation.component';
import { PendingDocUsersComponent } from './shared/pending-doc/PendingDocUsers.component';
import { AgencyUserDashboardMessageComponent } from './user/dashboard-messages/agencyuserdashboardmessage.component';
import { ReportadminComponent } from './agencyadmin/reportadmin/reportadmin.component';
import { ChatPopupComponent } from './shared/chat-popup/chat-popup.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import {MatSelectModule} from '@angular/material/select';

declare var parseJwt: any;

function getSubdomain() {
  const domain = window.location.hostname;
  if (domain.indexOf('.') < 0 ||
    domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
    // if (domain.indexOf('.') < 0 ||
    //     domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh') { // on request of Punnet Sir
    return '';
  } else {
    return domain.split('.')[0];
  }
  // console.log('subdomain', domain.split('.')[0]);
}

function IsLocalHost() {
  const hostname = location.toString();
  if (hostname.indexOf('localhost') !== -1) { return true; } else { return false; }
}
const config = new AuthServiceConfig(
  [
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider('Your-Facebook-app-id')
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider('Your-Google-Client-Id')
    }
  ]);

export function getAuthServiceConfigs() {
  return config;
}

@Injectable()
export class AppLoadService {
  public initialized = false;
  constructor(private httpClient: HttpClient, private globalService: GlobalService) { }

  getSettings(): Promise<any> {
    let SubDomain = '';
    if (IsLocalHost()) {
      SubDomain = 'ash';
    } else {
      SubDomain = getSubdomain();
    }
    const promise = this.httpClient.post(`${environment.api_url}/agency/getagencylocationid`,
      { 'DomainName': SubDomain })
      .toPromise()
      .then((response: any) => {
        // set domain status if exist or not
        localStorage.setItem('domainStatus', JSON.stringify({ isExist: response.Status }));
        if (response.Status) {
          this.initialized = true;
          const globleDataSourceState = this.globalService.globleDataSource;
          globleDataSourceState['AgencyId'] = response.Data.AgencyId;
          globleDataSourceState['LocationId'] = response.Data.LocationId;
          globleDataSourceState['AgencyLogo'] = response.Data.AgencyLogo;
          globleDataSourceState['AgencyName'] = response.Data.AgencyName;
          globleDataSourceState['isAgencyExist'] = true;
          globleDataSourceState['SiteAddress'] = SubDomain;
          this.globalService.changeGlobleDataSourceState(globleDataSourceState);
          return true;
        } else {
          // window.location.href = `${getDomain}/404`;
          const globleDataSourceState = this.globalService.globleDataSource;
          globleDataSourceState['isAgencyExist'] = false;
          this.globalService.changeGlobleDataSourceState(globleDataSourceState);
          return true;
        }
      });
    return promise;
  }
}

export function get_settings(appLoadService: AppLoadService, appLoadServiceAnother: AppLoadService) {
  const usermodel = parseJwt(localStorage.getItem('frontend-token'));
  if (usermodel && usermodel.user && usermodel.user.IsLeadUser) {
    localStorage.removeItem('frontend-token');
  }
  return () => appLoadService.getSettings();
}


@NgModule({
  declarations: [
    FrontFacingComponent,
    CountDownComponent,
    // AngularMultiSelect,
    AgentUserProfileComponent,
    AppComponent,
    LoginComponent,
    SignupComponent,
    AgencySignupComponent,
    StepFirstComponent,
    ForgetPasswordComponent,
    AgentComponent,
    UserComponent,
    UserCallComponent,
    UserMessageComponent,
    UserDetailComponent,
    ReferralComponent,
    UiKitComponent,
    HeaderComponent,
    SidebarComponent,
    AgentHeaderComponent,
    AgentSidebarComponent,
    UploadDocComponent,
    UploadPenDocComponent,
    NoActiveCallsComponent,
    DocumentComponent,
    CustomFormComponent,
    UpdateAgencyCardComponent,
    ResubscriptionAgencyCardComponent,
    // ProductModalComponent,
    UserPaymentComponent,
    UserProfileComponent,
    AddBankAccountComponent,
    ProductComponent,
    PrivacyPolicyComponent,
    ProductDetailComponent,
    AgencySubscriptionCancel,
    ProductDetailForAgencyComponent,
    AgentSignComponent,
    AgentSignHeaderComponent,
    UserSignHeaderComponent,
    PendingDocComponent,
    UserSignComponent,
    UsersignpopupComponent,
    UserProductComponent,
    AgencyAdminDahsboardComponent,
    AgencyAdminProfileComponent,
    AgencyAdminSettingsComponent,
    AgencyAgentProfileComponent,
    AgencyAdminUserProfileComponent,
    AgencyAdminReferralComponent,
    AgencyAdminDashboardUserComponent,
    AgencyAdminDashboardUserDetailComponent,
    AgencyAdminDashboardAgentComponent,
    AgentAgencyHeaderComponent,
    AgentAgencySidebarComponent,
    AgencyadminDashboardPaymentComponent,
    AgencyAdminDashboardCallComponent,
    AgencyAdminDashboardProductComponent,
    AgencyPendingReferalComponent,
    AgencyAdminDashboardPaymentMethodComponent,
    AgencyAdminDashboardSubscriptionsComponent,
    AgencyUpdatePlanComponent,
    AgencyAdminDashboardMessageComponent,
    TimeAgoPipe,
    AgencyLandingComponent,
    SpecialOfferComponent,
    // SpecialOfferAgencyMatrixComponent,
    OfferFinishedComponent,
    AgencyPricingComponent,
    Ng2stepperComponent,
    AgentListComponent,
    AgentRegisterComponent,
    AgencyCallsDataComponent,
    AgencyPaymentsDataComponent,
    AgencySignedDocDataComponent,
    UserProductDetailComponent,
    DatePipe,
    NumberOnlyDirective,
    UserPendingNotificationComponent,

    ChatComponent,
    DrawModalComponent,
    // AgencyMatrixComponent,
    // AgencyMatrixThanksComponent,
    NotFoundComponent,
    ManagelocationsComponent,
    AddlocationComponent,
    PublicFacePageComponent,
    // AdminPublicFacePageComponent,
    PublicPageSettingComponent,
    IntegrationPopupComponent,
    AgencyAgentDahsboardComponent,
    ZohoComponent,
    NotificationsComponent,
    AgencyMatrixSettingComponent,
    AccountExistSettingComponent,
    AccountNotExistSettingComponent,
    EmailNotificationSettingComponent,
    UserSignDocumentPreviewComponent,
    UserSignPreviewModalComponent,
    AgentSignatureComponent,
    EditlocationComponent,
    AgencyUserDashboardMessageComponent,
    ReportadminComponent,
    PendingDocUsersComponent,
    ChatPopupComponent
  ],
  imports: [
    JwtModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBDrrZLCVynHi6uYdcgysHGut6ryDYdxnI'
    }),
    // AdminPublicFacePageModule,
    ProductDetailModelModule,
    SocialLoginModule,
    AgentSignModule,
    CommonModule,
    Ng4GeoautocompleteModule,
    MomentModule,
    ClipboardModule,
    HttpClientModule,
    NgxEditorModule,
    ChartsModule,
    BrowserModule,
    ScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgGridModule,
    MaterializeModule,
    TextMaskModule,
    InfiniteScrollModule,
    AgencyMatrixRegisterModule,
    InfiniteScrollModule,
    MatSelectModule,
    NoopAnimationsModule,
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: (http: Http) => new TranslateStaticLoader(http, '/assets/i18n', '.json'),
      deps: [Http]
    }),
    IntercomModule.forRoot({
      appId: 'qesi6r1a', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    NgxDaterangepickerMd.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    SelectDropDownModule,
    RouterModule.forRoot([
      { path: '', component: PublicFacePageComponent, canActivate: [AnonymousGuard] },
      { path: 'frontfacing', component: FrontFacingComponent, canActivate: [AuthHomeGuard] },
      { path: 'agp', component: AgencyAdminProfileComponent, canActivate: [AuthGuard] },
      {
        path: 'agency/page-setting',
        // component: AdminPublicFacePageComponent,
        loadChildren: './admin-public-face-page/admin-public-face-page.module#AdminPublicFacePageModule',
        canActivate: [AuthGuard]
      },
      { path: 'login', component: LoginComponent, canActivate: [AuthHomeGuard] },
      { path: 'signup/:id', component: SignupComponent, canActivate: [AnonymousGuard] },
      { path: 'signup', component: SignupComponent, canActivate: [AnonymousGuard] },
      { path: 'agency/step', component: StepFirstComponent, canActivate: [AuthStepperGuard] },
      { path: 'agency/matrix', component: AgencyMatrixRegisterComponent, canActivate: [AuthStepperGuard] },
      { path: 'forgotpassword', component: ForgetPasswordComponent, canActivate: [AnonymousGuard] },
      { path: 'user/dashboard', component: AgentComponent, canActivate: [AuthGuard] },
      { path: 'user/list', component: UserComponent, canActivate: [AuthGuard] },
      { path: 'user/call', component: UserCallComponent, canActivate: [AuthGuard] },
      { path: 'user/message', component: UserMessageComponent, canActivate: [AuthGuard] },
      { path: 'user/message/:UserId', component: UserMessageComponent, canActivate: [AuthGuard] },
      { path: 'user/detail/:UserId', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'user/referral', component: ReferralComponent, canActivate: [AuthGuard] },
      { path: 'user/document', component: DocumentComponent, canActivate: [AuthGuard] },
      { path: 'user/payment', component: UserPaymentComponent, canActivate: [AuthGuard] },
      { path: 'user/profile', component: UserProfileComponent, canActivate: [AuthGuard] },
      { path: 'ui-kit', component: UiKitComponent, canActivate: [AuthGuard] },
      { path: 'user/product', component: ProductComponent, canActivate: [AuthGuard] },
      { path: 'privacypolicy', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
      { path: 'product/detail/:ProductId', component: ProductDetailComponent, canActivate: [AuthGuard] },
      { path: 'productagency/detail/:ProductId', component: ProductDetailForAgencyComponent, canActivate: [AuthGuard] },
      { path: 'userproductdetail/detail/:ProductId', component: UserProductDetailComponent, canActivate: [AuthGuard] },
      { path: 'user/agentsign/:id/:id2/:id3', component: AgentSignComponent, canActivate: [AuthGuard] },
      { path: 'user/pendingdoc', component: PendingDocComponent, canActivate: [AuthGuard] },
      { path: 'user/usersign/:id/:docId', component: UserSignComponent, canActivate: [AuthGuard] },
      { path: 'user/usersign-preview/:id/:docId', component: UserSignDocumentPreviewComponent, canActivate: [AuthGuard] },
      { path: 'agency/usersign/:id/:docId', component: UserSignComponent, canActivate: [AuthGuard] },
      { path: 'agency/dashboard', component: AgencyAdminDahsboardComponent, canActivate: [AuthGuard] },
      { path: 'agency/users', component: AgencyAdminDashboardUserComponent, canActivate: [AuthGuard] },
      { path: 'agency/userdetail/:UserId', component: AgencyAdminDashboardUserDetailComponent, canActivate: [AuthGuard] },
      { path: 'agency/agents', component: AgencyAdminDashboardAgentComponent, canActivate: [AuthGuard] },
      { path: 'agency/locations', component: ManagelocationsComponent, canActivate: [AuthGuard] },
      { path: 'agency/addlocation', component: AddlocationComponent, canActivate: [AuthGuard] },
      { path: 'agency/editlocation/:id', component: EditlocationComponent, canActivate: [AuthGuard] },
      { path: 'report', component: ReportadminComponent, canActivate: [AuthPricingGuard] },
      { path: 'agency/payments', component: AgencyadminDashboardPaymentComponent, canActivate: [AuthGuard] },
      { path: 'agency/get-unsigned-documents-users', component: PendingDocUsersComponent, canActivate: [AuthGuard] },
      { path: 'user/payments', component: AgencyadminDashboardPaymentComponent, canActivate: [AuthGuard] },
      { path: 'agency/calls', component: AgencyAdminDashboardCallComponent, canActivate: [AuthGuard] },
      { path: 'agency/products', component: AgencyAdminDashboardProductComponent, canActivate: [AuthGuard] },
      { path: 'agency/pendingreferral', component: AgencyPendingReferalComponent, canActivate: [AuthGuard] },
      { path: 'agent/products', component: UserProductComponent, canActivate: [AuthGuard] },
      { path: 'agency/payment_methods', component: AgencyAdminDashboardPaymentMethodComponent },
      { path: 'agency/subscriptions', component: AgencyAdminDashboardSubscriptionsComponent, canActivate: [AuthGuard] },
      { path: 'agency/upgrade_plan', component: AgencyUpdatePlanComponent, canActivate: [AuthGuard] },
      { path: 'agency/messages', component: AgencyAdminDashboardMessageComponent, canActivate: [AuthGuard] },
      { path: 'agency/messages/:UserId', component: AgencyAdminDashboardMessageComponent, canActivate: [AuthGuard] },
      { path: 'agency/landing', component: AgencyLandingComponent, canActivate: [AuthHomeGuard] },
      { path: 'specialoffers', component: SpecialOfferComponent, canActivate: [AnonymousGuard] },
      // { path: 'specialoffersagencymatrix/:plan/:sub', component: SpecialOfferAgencyMatrixComponent, canActivate: [AnonymousGuard] },
      { path: 'specialofferfinished', component: OfferFinishedComponent, canActivate: [AuthPricingGuard] },
      { path: 'agent/register/:id', component: AgentRegisterComponent, canActivate: [AnonymousGuard] },
      { path: 'pricing', component: AgencyPricingComponent, canActivate: [AuthPricingGuard] },
      { path: 'agency/profile', component: PublicPageSettingComponent, canActivate: [AuthGuard] },
      { path: 'agency/my_profile', component: AgencyAdminUserProfileComponent, canActivate: [AuthGuard] },
      { path: 'agent/agentprofile', component: AgentUserProfileComponent, canActivate: [AuthGuard] },
      { path: 'agency/referral', component: AgencyAdminReferralComponent, canActivate: [AuthGuard] },
      { path: 'agent/profile', component: AgencyAgentProfileComponent, canActivate: [AuthGuard] },
      { path: 'agency/settings', component: AgencyAdminSettingsComponent, canActivate: [AuthGuard] },
      { path: 'agency/public', component: PublicPageSettingComponent, canActivate: [AuthGuard] },
      { path: 'agency/agency-matrix-setting', component: AgencyMatrixSettingComponent, canActivate: [AuthGuard] },
      { path: 'agency/agency-matrix-setting/link-account', component: AccountExistSettingComponent, canActivate: [AuthGuard] },
      { path: 'agency/agency-matrix-setting/add-account', component: AccountNotExistSettingComponent, canActivate: [AuthGuard] },
      // { path: 'agency/subscriptioncancel', component: AgencySubscriptionCancel, canActivate: [AuthGuard] }
      { path: 'agency/subscriptioncancel', component: AgencySubscriptionCancel, canActivate: [AuthGuard] },
      { path: 'agent/dashboard', component: AgencyAgentDahsboardComponent, canActivate: [AuthGuard] },
      { path: 'agency/notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
      { path: 'user/notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
      { path: 'agent/notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
      { path: 'agency/email-setting', component: EmailNotificationSettingComponent, canActivate: [AuthGuard] },
      { path: 'user/email-setting', component: EmailNotificationSettingComponent, canActivate: [AuthGuard] },
      { path: 'user/messages', component: AgencyUserDashboardMessageComponent, canActivate: [AuthGuard] },
      { path: 'user/messages/:UserId', component: AgencyUserDashboardMessageComponent, canActivate: [AuthGuard] },
      {
        path: 'presale',
        // component: AgencyMatrixComponent,
        loadChildren: './agency-matrix/agency-matrix.module#AgencyMatrixModule',
        canActivate: [AnonymousGuard]
      },
      // { path: 'presale/:plan/:sub', component: SpecialOfferAgencyMatrixComponent, canActivate: [AnonymousGuard] },
      // { path: 'agencymatrixthanks', component: AgencyMatrixThanksComponent, canActivate: [AnonymousGuard] },
      { path: '8012', component: ZohoComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent, canActivate: [AnonymousGuard] }

    ], { useHash: false }),
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    // Add you opentok module here with the api key for opentok
    OpentokModule.forRoot({ apiKey: '46028572' })
  ],
  providers: [
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
    UserService, GlobalService, AppLoadService,
    UserDataService, SettingDataService,
    TokboxService,
    PushNotificationsService,
    EventBus,
    AuthGuard, AuthPricingGuard, AuthHomeGuard, AuthStepperGuard,
    AnonymousGuard,
    { provide: AuthServiceConfig, useFactory: getAuthServiceConfigs },
    { provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppLoadService, GlobalService], multi: true }
    // Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
