import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyMatrixRegisterComponent } from './agency-matrix-register.component';
import { AccountExistComponent } from './account-exist/account-exist.component';
import { AccountNotExistComponent } from './account-not-exist/account-not-exist.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { MaterializeModule } from 'angular2-materialize';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MaterializeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    AgencyMatrixRegisterComponent,
    AccountExistComponent,
    AccountNotExistComponent,
    ThankYouComponent
  ],
  exports: [
    AgencyMatrixRegisterComponent,
    AccountExistComponent,
    AccountNotExistComponent,
    ThankYouComponent
  ]
})
export class AgencyMatrixRegisterModule { }
