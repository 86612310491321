import { Component, OnInit, EventEmitter } from '@angular/core';
import { UserService } from '../../model/user.service';
import { GlobalService } from '../../model/global.service';
import { toast, MaterializeAction } from 'angular2-materialize';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reportadmin',
  templateUrl: './reportadmin.component.html',
  styleUrls: ['./reportadmin.component.css']
})
export class ReportadminComponent {
  AgencyId = 0;
  form: FormGroup;
  SelectedDated: any = {};
  StartDate = '';
  EndDate = '';
  reportSelected = 1;
  ReportStatusTy = '';
  passwordmatched: boolean;
  disabledGenerateButton: boolean;
  minDate: moment.Moment = moment('2017-01-01');
  maxDate: moment.Moment = moment(new Date());
  reportTypes = [
    {
      value: 1,
      title: 'Agency Report'
    }, {
      value: 2,
      title: 'Agency Payment Report'
    }
  ];
  ReportStatus = [
    {
      value: 0,
      title: 'All'
    }, {
      value: 1,
      title: 'Active'
    }, {
      value: 3,
      title: 'Cancelled'
    }
  ];
  modelCheckPasswordParam: any;
  modalCheckPasswordActions = new EventEmitter<string | MaterializeAction>();
  CheckPasswordForm: FormGroup;

  constructor(private _userService: UserService, private _globalService: GlobalService,
    private fb: FormBuilder) {
    // get agency detail from token
    const UserModel = this._userService.decodeToken();
    this.disabledGenerateButton = true;
    if (UserModel && UserModel.user) {
      this.AgencyId = UserModel.user.AgencyId;
    }
    // form agency report
    this.form = this.fb.group({
      reportType: [this.reportTypes[0], Validators.required],
      reportStatusType: [this.ReportStatus[0], Validators.required],
      selected: [{
        startDate: moment('2018-01-01T00:00Z'),
        endDate: moment()
      }, Validators.required],
    });
    // Confirm Password form
    this.CheckPasswordForm = this.fb.group({
      'password': ['', Validators.required]
    });
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // genrate report based selected data
  public GenrateReport() {
    this.SelectedDated = this.form.value;
    this.reportSelected = this.SelectedDated.reportType.value;
    this.StartDate = this.SelectedDated.selected.startDate.format('YYYY-MM-DD 00:00');
    this.EndDate = this.SelectedDated.selected.endDate.format('YYYY-MM-DD 23:59');
    this.disabledGenerateButton = true;
    this.ReportStatusTy = this.SelectedDated.reportStatusType.value;
    this.toasting('Please wait for few seconds', 2000, 'green');
    this._userService.ReportAdmin(this.AgencyId, this.StartDate, this.EndDate, this.reportSelected, this.ReportStatusTy)
      .subscribe((response) => {
        this.disabledGenerateButton = false;
        if (response.Status) {
          const fileNameReport = (this.reportSelected === 1) ? 'Agency-Report' : 'Agency-Payment-Report';
          this._globalService.saveReportFile(response.Data.data, fileNameReport);
          this.toasting('You report start downloading within few seconds', 4000, 'green');
        } else {
          this.toasting(response.Message, 2000, 'red');
        }
      }, (error) => {
        this.disabledGenerateButton = false;
        this.toasting(error.Message, 2000, 'red');
      });
  }
  // open password confirm modal before show report generate options
  public openPasswordModal() {
    this.modalCheckPasswordActions.emit({ action: 'modal', params: ['open'] });
  }
  // check user password locally
  public CheckUser() {
    const value = this.CheckPasswordForm.value;
    if (value.password === 'Welcome@1') {
      this.passwordmatched = true;
      this.CheckPasswordForm.reset();
      this.disabledGenerateButton = false;
      this.modalCheckPasswordActions.emit({ action: 'modal', params: ['close'] });
    } else {
      this.CheckPasswordForm.reset();
      this.toasting('Password not matched.', 2000, 'red');
    }
  }
  // close confirm password modal
  public CancelModel() {
    this.modalCheckPasswordActions.emit({ action: 'modal', params: ['close'] });
  }
}
