import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
declare var $: any;

@Component({
  selector: 'app-sign-document-preview',
  templateUrl: './sign-document-preview.component.html',
  styleUrls: ['./sign-document-preview.component.css']
})
export class UserSignPreviewModalComponent implements OnInit {
  modalSignPreviewAction = new EventEmitter<string | MaterializeAction>();
  modelSignPreviewParams = [
    {
      dismissible: false
    }
  ];
  documentKey: string;
  documentId: number;
  showPopUp: boolean;
  DocumentUrl: string;
  constructor() { }

  ngOnInit() {
  }

  openSignPreview(documentKey, documentId) {
    this.documentKey = documentKey;
    this.documentId = documentId;
    if (this.documentKey && this.documentId) {
      this.DocumentUrl = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + this.documentKey + '.pdf';
      localStorage.setItem( this.DocumentUrl + '/tooltype', 'cursor');
      this.showPopUp = true;
      this.modalSignPreviewAction.emit({ action: 'modal', params: ['open'] });
    }
  }
  closeSignPreview() {
    this.showPopUp = false;
    $('script[src="/assets/pdf/shared/pdf.js"]').remove();
    $('script[src="/assets/pdf/shared/pdf_viewer.js"]').remove();
    $('script[src="/assets/pdf/index.js"]').remove();
    localStorage.removeItem(this.DocumentUrl);
    this.modalSignPreviewAction.emit({ action: 'modal', params: ['close'] });
  }
}
