import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from '../model/user.service';
import { GlobalService } from '../model/global.service';

@Component({
  selector: 'app-zoho',
  templateUrl: './zoho.component.html',
  styleUrls: ['./zoho.component.css']
})
export class ZohoComponent implements OnInit {
  public redirect_uri = '';
  public zohoUrl = ``;
  zoho_client_id = '';
  zoho_redirect_uri = '';
  zoho_scopes = '';
  constructor(private _userService: UserService, private _globalService: GlobalService) {
    this.GetZohoDetails();
  }

  ngOnInit() {
  }

  public GetZohoDetails() {
    this._userService.GetKeysDetails()
     .subscribe((response) => {
        if (response.Status) {
          const DecrytData = this._globalService.DecryptZohoKeys(response.Data);
          this.zoho_client_id = DecrytData.zoho_client_id;
          this.zoho_redirect_uri = DecrytData.zoho_redirect_uri;
          this.zoho_scopes = DecrytData.zoho_scopes;
          this.zohoUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=${DecrytData.zoho_scopes}&client_id=${DecrytData.zoho_client_id}
      &response_type=code&access_type=offline&redirect_uri=${DecrytData.zoho_redirect_uri}`;
        }
     }, (error) => {});
  }

}
