import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../model/user.service';
import { Router } from '@angular/router';
import { toast } from 'angular2-materialize';
import { CustomValidators } from '../helper/custom-validators';
import { GlobalService } from '../model/global.service';


@Component({
  templateUrl: './forgetpassword.component.html'
})
export class ForgetPasswordComponent {
  @ViewChild('video') private video: ElementRef;
  IsMute: boolean;
  ShowLoader: boolean;

  _forgetpasswordForm: FormGroup;
  private _formErrors: any;
  private _submitted: boolean;
  private _errorMessage: string;

  agencyName: string;
  agencyLogo: string;
  globleDataSource: any;
  constructor(private _formBuilder: FormBuilder, private _userService: UserService,
    private _router: Router, private globalService: GlobalService,
    private renderer: Renderer2) {
    // forget password form intialize
    this._forgetpasswordForm = _formBuilder.group({
      email: ['', Validators.compose([Validators.required, CustomValidators.email])]
    });
    this._forgetpasswordForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.globleDataSource = this.globalService.globleDataSource;
    this.agencyLogo = this.globleDataSource['AgencyLogo'];
    this.agencyName = this.globleDataSource['AgencyName'];
  }
  // get success/error message user
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // set form errors
  private _setFormErrors(errorFields: any): void {
    for (const key in errorFields) {
      // skip loop if the property is from prototype
      if (!errorFields.hasOwnProperty(key)) { continue; }
      const message = errorFields[key];
      this._formErrors[key].valid = false;
      this._formErrors[key].message = message;
    }
  }
  // reset form errors
  private _resetFormErrors(): void {
    this._formErrors = {
      email: { valid: true, message: '' }
    };
  }

  public muteVideo() {
    if (this.video.nativeElement.volume !== 0) {
      this.video.nativeElement.volume = 0;
      this.IsMute = true;
    } else {
      this.video.nativeElement.volume = 1;
      this.IsMute = false;
    }
  }
  // on change value reset validations
  public onValueChanged(data?: any) {
    if (!this._forgetpasswordForm) { return; }
    const form = this._forgetpasswordForm;
    for (const field in this._formErrors) {
      // clear previous error message (if any)
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }
  // set form field valid or not
  public _isValid(field): boolean {
    let isValid: boolean;
    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._forgetpasswordForm.controls[field].touched === false) {
      isValid = true;
    } else if (this._forgetpasswordForm.controls[field].touched === true && this._forgetpasswordForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // on submit form update password
  public onSubmit(elementValues: any) {
    this.ShowLoader = true;
    const DomainName = this._userService.getDomain();
    const SubDomain = this._userService.getSubdomain();
    this._userService.ForgetPassword(elementValues.email)
      .subscribe(
        result => {
          if (result.Status) {
            this.toasting('New Password sent successfully!', 2000, 'green');
            this._router.navigate(['login']);
          } else {
            this.toasting(result.Message, 2000, 'red');
          }
          this.ShowLoader = false;
        },
        error => {
          // Validation error
          if (error.status === 422) {
            this._resetFormErrors();
            const errorFields = JSON.parse(error.data.message);
            this._setFormErrors(errorFields);
          } else {
            this._errorMessage = JSON.stringify(error.Data[0]);
          }
          this.ShowLoader = false;
        }
      );
  }
}
