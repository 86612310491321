import { Component, NgZone, Inject, HostListener, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { User } from '../../model/user';
import { Router } from '@angular/router';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { toast } from 'angular2-materialize';

declare var $: any;

declare var myExtObject: any;

@Component({
  selector: 'app-ax-agent-list',
  templateUrl: './agent-list.component.html'
})

export class AgentListComponent implements OnInit {
  UserModel: User = new User();
  ShowAvailAgentsLoader: boolean;
  LoggedInUserName: string;
  LoggedInUserId: number;
  LoggedInUserRoleId: string;
  LoggedInUserImageUrl: string;
  LoggedInUserDescription: string;
  Count: number;
  AgencyId: number;
  LocationId: number;
  users: User[] = [];
  refereshUserId: any;
  refereshConnectionId: any;
  agentbarStatus: boolean;
  public innerWidth: any;
  public onlineAgentCount: number;
  public agent_fixed: boolean;
  public ArrayOnlineTokboxuser = [];
  IsConnected: boolean;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private _ngZone: NgZone,
    private tokboxService: TokboxService, private _router: Router,
    private _userservice: UserService
    // private _EventBus: EventBus
  ) {
    // get agency/user detail from token
    const UserModel = this._userservice.decodeToken();
    if (UserModel.user) {
      this.ArrayOnlineTokboxuser = [];
      this.LoggedInUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
      this.LoggedInUserDescription = UserModel.user.Description ? UserModel.user.Description : '';
      this.loadAgents(this.LoggedInUserRoleId, 0);
      this.Count = 1;

      window['userComponentRef'] = {
        zone: this._ngZone,
        componentFn: (userId, connectionId, isConnected) => this.RefreshList(userId, connectionId, isConnected),
        component: this,
      };
      this.onlineAgentCount = 0;
    }
  }

  ngOnInit(): void {
    this.agentbarStatus = false;
    this.innerWidth = window.innerWidth;
  }
  // refresh list online users
  public RefreshList(UserId, ConnectionId, IsConnected) {
    this.refereshUserId = UserId;
    this.refereshConnectionId = ConnectionId;
    const that = this;
    let activeAgent = 0;

    if (ConnectionId && IsConnected) {
      const isExists = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId) > -1;
      if (!isExists) {
        this.ArrayOnlineTokboxuser.push({ UserId: UserId, ConnectionId: ConnectionId });
      }
    } else {
      const index = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId);
      if (index > -1) {
        this.ArrayOnlineTokboxuser.splice(index, 1);
      }
    }

    that.users.forEach((item) => {
      const isAgentStillConnected = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == item.UserId) > -1;
      if (isAgentStillConnected) {// If USER IS ONLINE
        activeAgent++;
        item.ConnectionId = ConnectionId;
      } else {
        item.ConnectionId = '';
      }
    });

    that.onlineAgentCount = activeAgent;
  }
  // load agents agency for call
  public loadAgents(RoleId, OffSet) {
    const that = this;
    this.ShowAvailAgentsLoader = true;
    this._userservice.getAllForUsers(RoleId, OffSet, this.AgencyId, this.LocationId, false)
      .subscribe(
        result => {
          if (result.Status) {
            this.users = result.Data;
            that.onlineAgentCount = 0;
            this.users.forEach((item) => {
              item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
              item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
              item.Name = `${item.FirstName} ${item.LastName}`;
              item.Initials = that.GetInitials(item.Name);
              item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
              item.LoggedUserTitle = this.LoggedInUserDescription;
              if (item.ConnectionId) {
                that.onlineAgentCount++;
              }
            });
          } else {
          }
          this.ShowAvailAgentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAvailAgentsLoader = false;
        }
      );
  }
  // get intial user from name
  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }

  public GetClass() {
    if (this.Count === 1) {
      return 'iconUser noImg cyan lighten-3';
    } else if (this.Count === 2) {
      return 'iconUser noImg cyan lighten-5';
    } else if (this.Count === 3) {
      return 'iconUser noImg pink lighten-4';
    }
  }
  public StartCall(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio) {
    $('.loader-outer').css('display', 'inline-flex');
    this.GenerateSessionIdAndTokenId(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio);
    this.agentbarStatus = false;
  }

  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  public GenerateSessionIdAndTokenId(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio) {
    const that = this;
    this.tokboxService.createsessionandtoken(this.LoggedInUserId, ToUserId)
      .subscribe(
        result => {
          if (result.Status) {
            myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2, ToUserId,
              result.currentSessionId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio);
          } else {
            that.toasting(result.Message, 2000, 'red');
            $('.loader-outer').css('display', 'none');
          }
        },
        error => {

        }
      );
  }

  public LogoutTokbox() {
    myExtObject.logout(true);
  }

  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const num = this.doc.body.scrollTop;
    if (num > 50) {
      this.agent_fixed = true;
    } else if (this.agent_fixed && num < 5) {
      this.agent_fixed = false;
    }
  }
  public SendMessage(ToUserId) {
    this._router.navigate(['/user/messages', ToUserId]);
  }
}
