import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { EventBus } from '../../shared/EventBus/EventBus';

@Component({
    templateUrl: './offerfinished.component.html'
})

export class OfferFinishedComponent {
    constructor(private _router: Router, private _eventBus: EventBus) {
        this._eventBus.emit({ Type: `show-authorized-header`, data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
    }
}
