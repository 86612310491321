import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../model/user.service';
import { EventBus } from '../EventBus/EventBus';
import { ClipboardService } from 'ng2-clipboard/ng2-clipboard';
import { MaterializeAction } from 'angular2-materialize';
import { toast } from 'angular2-materialize';
import { Intercom } from 'ng-intercom';
import { Activity } from '../../model/activity';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../../model/global.service';
import { FormGroup } from '@angular/forms';
declare var $: any;
declare var myExtObject: any;
@Component({
    selector: 'app-agent-agency-header',
    templateUrl: 'agent-agency-header.component.html'
})

export class AgentAgencyHeaderComponent implements OnInit {
    innerWidth: any;
    model1Params: any;
    ShareUrl: string;
    FiveDigitCode: string;
    _locations: any[];
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserRoleName: string;
    LoggedInUserPhone: string;
    LoggedInUserEmail: string;
    AgencyName: string;
    ShowRibbon: boolean;
    LoggedInUserImageUrl: string;
    LocationId: number;
    AgencyLogo: string;
    AgencyId: number;
    LoginUserId: number;
    SelectedLocationId: number;
    sidebar: false;
    agentagencysidebarcomponent: any;
    public openNotification: false;
    modalActions1 = new EventEmitter<string | MaterializeAction>();
    modalActions2 = new EventEmitter<string | MaterializeAction>();
    params = [];
    Activity: Activity[];
    ActivityData: Activity;
    AllActivityCount = 0;
    SeenActivityCount = 0;
    UnSeenActivityCount = 0;
    LoadMoreActivityCounter = 0;
    ShouldWellRing: boolean;
    DeleteLocation: boolean;
    DeleteLocationId: number;
    public config = {
        displayKey: 'name',
        search: false,
        limitTo: 25
    };
    public singleSelect: any = [];
    options: any = [];
    toggleTitle(linkUrl) {
        linkUrl = linkUrl + '' + this.ShareUrl;
        const w = 900;
        const h = 500;
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ?
            document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ?
            document.documentElement.clientHeight : screen.height;

        const left = ((width / 2) - (width / 2)) + dualScreenLeft;
        const top = ((height / 2) - (height / 2)) + dualScreenTop;

        const newWindow = open(linkUrl, 'example',
            'toolbar=no, directories=no,location=no, status=yes, menubar=no, resizable=no, scrollbars=yes,width='
            + w + ', height=' + h + ', top=' + top + ', left=' + left);
        newWindow.onload = function () {
        };
    }

    constructor(public intercom: Intercom, private _router: Router, private _userService: UserService,
        private _EventBus: EventBus, private clipboard: ClipboardService, private _globalService: GlobalService,
        private _domSanitizer: DomSanitizer) {
        const UserModel = this._userService.decodeToken();
        this.innerWidth = window.innerWidth;
        this.Activity = [];
        // get agency/user details from token
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.AgencyLogo = UserModel.user.AgencyLogo;
            this.AgencyName = UserModel.user.AgencyName;
            this.AgencyId = UserModel.user.AgencyId;
            this.LoginUserId = UserModel.user.UserId;
            if (UserModel.user.SubscriptionStatusId !== 1) {
                this.ShowRibbon = true;
            }
            const LocationLocal = localStorage.getItem('SelectedLocation');
            if (LocationLocal) {
                this.LocationId = Number(LocationLocal);
            } else if (this._userService.GetSelectedLocation() !== 0 &&  UserModel.user.RoleId === 6) {
                this.LocationId = Number(this._userService.GetSelectedLocation());
            } else {
                this.LocationId = UserModel.user.LocationId;
            }
            this.GetAwsSeetingKeys();
        }

        this.GetLocations();
        this.GetUserImage();
        this.GetUserDetail();
        this.GetAgencyUrlAndCode(this.AgencyId, this.LocationId);
        this.GetUnssenActivityCount();
        this._EventBus.on().subscribe(m => {
            if (m.filterBy.Type === `header-left-statics`) {
                const userModel = m.filterBy.data;
                if (userModel) {
                    if (userModel.AgencyLogo && this.AgencyLogo !== userModel.AgencyLogo) {
                        this.AgencyLogo = userModel.AgencyLogo;
                    }
                    if (userModel.AgencyId && this.AgencyId !== userModel.AgencyId) {
                        this.AgencyId = userModel.AgencyId;
                    }
                    if (userModel.AgencyName && this.AgencyName !== userModel.AgencyName) {
                        this.AgencyName = userModel.AgencyName;
                    }
                    if (userModel.LoggedInUserName && this.LoggedInUserName !== userModel.LoggedInUserName) {
                        this.LoggedInUserName = userModel.LoggedInUserName;
                    }
                    if (userModel.LoginUserId && this.LoginUserId !== userModel.LoginUserId) {
                        this.LoginUserId = userModel.LoginUserId;
                    }
                    if (userModel.LoggedInUserImageUrl && this.LoggedInUserImageUrl !== userModel.LoggedInUserImageUrl) {
                        this.LoggedInUserImageUrl = userModel.LoggedInUserImageUrl;
                    }
                    if (userModel.LoggedInUserRoleId && this.LoggedInUserRoleId !== userModel.LoggedInUserRoleId) {
                        this.LoggedInUserRoleId = userModel.LoggedInUserRoleId;
                    }
                    this._EventBus.emit({ Type: `header-app-component`, data: userModel });
                }
            } else if (m.filterBy.Type === 'Get-Agency-Details') {
                this.GetUserImage();
            } else if (m.filterBy.Type === 'update-banner') {
                if (m.filterBy.data && m.filterBy.data.logoUrl) {
                    this.AgencyLogo = m.filterBy.data.logoUrl;
                }
            } else if (m.filterBy.Type === 'UPDATE_ACTIVITY_TRIGGERED') {
                const activityData = m.filterBy.data.activityData;
                this.ActivityData = JSON.parse(activityData);
                this.ShouldWellRing = true;
                window.setTimeout(() => { this.ShouldWellRing = false; }, 30000);
                this.GetAgencyActivities(true);
            } else if (m.filterBy.Type === 'getAgencyData') {
                this.AgencyId = m.filterBy.data.AgencyId;
            } else if (m.filterBy.Type === 'MARKED_AS_READ_ACTIVITIES') {
                this.MarkedActivitiesAsRead(m.filterBy.data);
            } else if (m.filterBy.Type === 'locationAddedDeleted') {
                if (m.filterBy.data.delete === true) {
                    this.DeleteLocation = true;
                    this.DeleteLocationId = Number(m.filterBy.data.LocationId);
                }
                this.GetLocations();
            }
        });
        this.GetAgencyActivities(true);
    }
    // get agency share code and link
    public GetAgencyUrlAndCode(AgencyId, LocationId) {
        this._userService.GetAgencyUrlAndCode(AgencyId, LocationId)
        .subscribe(
            result => {
                if (result.Status) {
                    this.ShareUrl = result.Data.AgencyUrl;
                    this.FiveDigitCode = result.Data.FiveDigitCode;
                } else {

                }
            },
            error => {
                // Validation error
                if (error.status === 422) {
                    const errorFields = JSON.parse(error.data.message);
                }
            }
        );
    }
    // get aws keys for upload files
    private GetAwsSeetingKeys() {
        this._userService.GetAWSSettingKeys().subscribe((response) => {
            if (response.Status && response.Data) {
                const data = this._globalService.DecryptSetASEAWSKeys(response.Data);
                const globleDataSourceState = this._globalService.globleDataSource;
                globleDataSourceState['amazon_access_key'] = data.AWS_Key;
                globleDataSourceState['amazon_secret_access_key'] = data.AWS_Secret;
                globleDataSourceState['amazon_bucket_name'] = data.AWS_Bucket_Name;

                this._globalService.changeGlobleDataSourceState(globleDataSourceState);
            }

        }, error => {
            if (error.Message === 'Token expired') {
                localStorage.removeItem('frontend-token');
                localStorage.removeItem('StripeCust');
                this._router.navigate(['/login']);
            }
        });
    }
    // get user detail
    public GetUserDetail() {
        this._userService.getAgencylogo(this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.AgencyLogo = result.Data.AgencyLogo;
                    } else {
                        alert(result.Message);
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }

    public GetUnssenActivityCount() {
        this._userService.GetUnseenActivityCount(this.LoginUserId, this.LoggedInUserRoleId, this.LocationId, this.AgencyId)
            .subscribe((response) => {
                if (response.Status) {
                    this.UnSeenActivityCount = response.Data.UnSeenCount;
                }
            }, (error) => {});
    }

    ngOnInit() {
        let subDomain = this._userService.getSubdomain();
        subDomain = subDomain ? subDomain.toLowerCase() : 'app';
        if (subDomain !== 'app') {
            myExtObject.initializeTokbox();
        }
        this.modalActions2.emit({ action: 'modal', params: ['open'] });
        this._userService.getuserprofile(this.LoginUserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.LoggedInUserRoleName = result.Data.RoleName;
                        this.LoggedInUserEmail = result.Data.Email;
                        this.LoggedInUserPhone = result.Data.Phone;
                        const UserName = this.LoggedInUserName;
                        const UserEmail = this.LoggedInUserEmail;
                        const Phone = this.LoggedInUserPhone;
                        const AgencyName = this.AgencyName;
                        const RoleName = this.LoggedInUserRoleName;
                        this.intercom.update({
                            app_id: 'qesi6r1a',
                            name: UserName, // Full name
                            email: UserEmail, // Email address
                            phone: Phone, // current_user_id
                            agency_name: AgencyName, // current_user_id
                            role: RoleName, // current_user_id
                            // Supports all optional configuration.
                            widget: {
                                'activator': '#intercom',
                            }
                        });
                    } else {
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );

        setTimeout(function () {
            $(document).ready(function () { $('ul.tabs').tabs(); });
            $('#share_company_url').modal({ dismissible: false });
        }, 400);

        $('.notifications .dropdown-button + .dropdown-content').on('click', function (event) {
            event.stopPropagation();
        });

    }
    // copy clipboard code
    copyToClipboard = (fivegigtcode) => { this.clipboard.copy(fivegigtcode); this.toasting('Copied Successfully', 2000, 'green'); };
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // open share company modal
    shareCompanyUrl() {
        this.modalActions1.emit({ action: 'modal', params: ['open'] });
    }
    // open call setting modal
    callsettings() {
        this.modalActions2.emit({ action: 'modal', params: ['open'] });
    }
    // get user image
    public GetUserImage() {
        this._userService.getuserprofile(this.LoginUserId)
            .subscribe(
                result => {
                    if (result.Status) {
                       this.LoggedInUserImageUrl = result.Data.Picture;
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }

    public LogoutTokbox() {
        myExtObject.logout(true);
    }
    // change location
    public ChangeLocation(event) {
        const that = this;
        localStorage.setItem('SelectedLocation', event.value._id);
        this.GetAgencyUrlAndCode(this.AgencyId, event.value._id);
        this.LocationId = Number(event.value._id);
        this.GetAgencyActivities(true);
        that._EventBus.emit({ Type: 'locationChanged', data: { LocationId: event.value._id } });
    }
    // get all location agency
    public GetLocations() {
        this._userService.GetAgencyAllLocations(this.AgencyId)
            .subscribe(result => {
                if (result.Status) {
                    if (result.Data) {
                        this._locations = result.Data;
                        const LocationsShown = [];
                        if (this._locations && this._locations.length > 0) {
                            this._locations.forEach((item, key) => {
                                LocationsShown.push({_id: item.LocationId, name: item.LocationName, index: key});
                            });
                        }
                        this.options = LocationsShown;
                        this.config.limitTo = this.options.length;
                        setTimeout(() => {
                            if (this.DeleteLocation === true) {
                                if (Number(localStorage.getItem('SelectedLocation')) === this.DeleteLocationId) {
                                    localStorage.setItem('SelectedLocation', this._locations[0].LocationId);
                                    this.DeleteLocation = false;
                                } else {
                                    this.LocationId = Number(localStorage.getItem('SelectedLocation'));
                                    const SelectedIndex = this.options.findIndex((loc) => loc._id === this.LocationId);
                                    this.singleSelect = this.options[SelectedIndex];
                                }
                                const LocationsShownAgency = [];
                                if (this._locations && this._locations.length > 0) {
                                    this._locations.forEach((item, key) => {
                                        if (item.LocationId !== this.DeleteLocationId) {
                                            LocationsShownAgency.push({_id: item.LocationId, name: item.LocationName, index: key});
                                        }
                                    });
                                }
                                this.options = LocationsShownAgency;
                                this.config.limitTo = this.options.length;

                            } else {
                                this.LocationId = Number(localStorage.getItem('SelectedLocation'));
                                const SelectedIndex = this.options.findIndex((loc) => loc._id === this.LocationId);
                                this.singleSelect = this.options[SelectedIndex];
                            }
                        }, 100);
                    }
                }
            },
            error => { });
    }
    // toggle sidebar left
    public OpenSidebar() {
        this._EventBus.emit({ Type: 'toggleressidebar', data: { sidebarStatus: true } });
    }
    // open call panel
    public OpenCallPanel() {
        this._EventBus.emit({ Type: 'toggleCallPanel', data: { callPanelStatus: true } });
    }

    public LogOut() {
        this.LogoutTokbox();
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/']);
        this.intercom.shutdown();
    }
    // get agency all activities
    GetAgencyActivities(isResetArray = false, offset = 0, activityModuleId = 0, activityType = 0) {
        const postData = {
            UserId: this.LoginUserId,
            OffSet: offset,
            ActivityModuleId: activityModuleId,
            ActivityType: activityType,
            AgencyId: this.AgencyId,
            LocationId: this.LocationId
        };
        this._userService.GetUserActivitiesForAgent(postData).subscribe((respo: any) => {
            if (isResetArray) {
                this.Activity = [];
            }
            const activityData = respo && respo.Data ? respo.Data : [];
            activityData.forEach((element: Activity) => {
                this.Activity.push(element);
            });
            this.RefreshActivityCount();
            this._EventBus.emit({
                Type: 'GET_UPDATED_ACTIVITY_TRIGGERED', data:
                {
                    data: this.Activity,
                    AllActivityCount: this.AllActivityCount,
                    SeenActivityCount: this.SeenActivityCount,
                    UnSeenActivityCount: this.UnSeenActivityCount
                }
            });
        }, error => {
            if (error.Message === 'Token expired') {
                localStorage.removeItem('frontend-token');
                localStorage.removeItem('StripeCust');
                this._router.navigate(['/login']);
            }
        });
    }
    // update activity
    private UpdateActivityArray(activity: Activity) {
        if (activity) {
            activity.Id = Number(activity.Id);
            const index = this.Activity.findIndex(x => x.Id === activity.Id);
            if (index > -1) {
                this.Activity[index] = activity;
            } else {
                if (this.Activity) {
                    this.Activity.unshift(activity);
                } else {
                    this.Activity = [activity];
                }
                this.AllActivityCount = this.AllActivityCount + 1;
                this.UnSeenActivityCount = this.UnSeenActivityCount + 1;
            }
            this._EventBus.emit({
                Type: 'GET_UPDATED_ACTIVITY_TRIGGERED',
                data: {
                    data: this.Activity,
                    AllActivityCount: this.AllActivityCount,
                    SeenActivityCount: this.SeenActivityCount,
                    UnSeenActivityCount: this.UnSeenActivityCount
                }
            });
        }
    }
    // convert date to local string
    public GetFormatedDate(date) {
        return new Date(date).toLocaleString();
    }
    // refresh activity count
    public RefreshActivityCount() {
        if (this.Activity && this.Activity.length > 0) {
            const acivity = this.Activity[0];
            this.AllActivityCount = acivity.TotalActivityCount;
            this.SeenActivityCount = acivity.SeenCount;
            this.UnSeenActivityCount = this.Activity[0].UnSeenCount;
        } else {
            this.AllActivityCount = 0;
            this.SeenActivityCount = 0;
            this.UnSeenActivityCount = 0;
        }
    }
    // mark activities as read
    MarkedActivitiesAsRead(shouldRedirect = false) {
        const UnReadActivitiesIds = [];
        this.Activity.forEach((element: Activity) => {
            if (!element.ActivitySeenStatus) {
                UnReadActivitiesIds.push(element.Id);
            }
        });
        this._userService.MarkedActivitiesAsRead({
            UserId: this.LoginUserId,
            ActivitiesId: UnReadActivitiesIds
        })
            .subscribe((apiResponse) => {
                if (!shouldRedirect) {
                    this.GetAgencyActivities(true);
                }
                this.UnSeenActivityCount = 0;
            },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                });
        if (shouldRedirect) {
            this._router.navigate(['/agency/notifications']);
        }
    }
    // on scroll down load more activities
    public onScrollDownActivities() {
        this.LoadMoreActivityCounter = this.LoadMoreActivityCounter + 50;
        this.GetAgencyActivities(false, this.LoadMoreActivityCounter);
    }
    // conver payment tp two decimal point
    GetFormatedDollor(dollor) {
        return Number(dollor).toFixed(2);
    }

    identifyActivity(index: number, item: Activity) {
        return item.Id;
    }
}
