import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction, toast } from 'angular2-materialize';
import { UserService } from '../../model/user.service';
import { EventBus } from '../EventBus/EventBus';
import { Router } from '@angular/router';

const StripeCountry = require('../../helper/StripeCountry.json');
declare var $: any;

declare var Stripe: any;

@Component({
  moduleId: module.id,
  selector: 'app-add-bank-account',
  templateUrl: 'add-bank-account.component.html'
})
export class AddBankAccountComponent implements OnInit {
  toverifyaccountid: string;
  model1Params: any;
  _countryCode: any[];
  bankaccounttype: any[];
  AddBankAccountForm: FormGroup;
  VerifyAccountForm: FormGroup;
  private _formErrors: any;
  private _errorMessage: string;

  modalActions = new EventEmitter<string | MaterializeAction>();
  verifyaccountmodalActions = new EventEmitter<string | MaterializeAction>();
  params = [];
  uploaddocmodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  UserId: number;
  Name: string;
  AgencyId: number;
  StripeCustomerId: string;
  SelectedBankAccountId: string;
  ReferralIds: string;
  BankAccountList: any[];

  ShowAddAccount: boolean;
  ShowButtonLoader: boolean;
  ShowLoader: boolean;
  ShowCreateChargeDiv: boolean;
  message: string;
  paymentMethodMessage: string;
  paymentMethodSetup: boolean;
  AgencyStripePublishableKey: string;
  ShouldhideBackButton: boolean;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private _EventBus: EventBus,
    private _router: Router
  ) {
    this.bankaccounttype = [{
      'name': 'Individual',
      'value': 'individual'
    },
    {
      'name': 'Company',
      'value': 'company'
    }];
    this.ShouldhideBackButton = false;
    this._countryCode = StripeCountry;
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.UserId = UserModel.user.UserId;
      this.Name = UserModel.user.Name;
      this.AgencyId = UserModel.user.AgencyId;
    }

    this.StripeCustomerId = this.userService.getCustId();
    this.ShowButtonLoader = false;
    this.SelectedBankAccountId = '';
    this.ShowCreateChargeDiv = false;
    // add bank account form
    this.AddBankAccountForm = _formBuilder.group({
      selectedcountrycode: ['US'],
      accountholdername: ['', Validators.compose([Validators.required])],
      accounttype: ['individual', Validators.compose([Validators.required])],
      currency: ['USD'],
      accountnumber: ['', Validators.compose([Validators.required])],
      routingnumber: ['', Validators.compose([Validators.required])]
    });
    this.AddBankAccountForm.valueChanges.subscribe(data => this.onValueChanged(data));
    // verify account form fields
    this.VerifyAccountForm = _formBuilder.group({
      firstvalue: ['', Validators.compose([Validators.required])],
      secondvalue: ['', Validators.compose([Validators.required])]
    });
    this.VerifyAccountForm.valueChanges.subscribe(data => this.onValueChanged(data));
  }
  ngOnInit() {

    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#addbankaccount').modal({ dismissible: false });
    }, 400);
  }
  // on change country code routing number validation
  public onChangeCountry(event) {
    const that = this;
    this._countryCode.forEach(function (item) {
      if (item.code === String(event)) {
        that.AddBankAccountForm.patchValue({
          currency: item.currency
        });
        if (item.routing_required) {
          that.AddBankAccountForm.controls['routingnumber'].setValidators(Validators.required);
        } else {
          that.AddBankAccountForm.controls['routingnumber'].clearValidators();
        }
        that.AddBankAccountForm.controls['routingnumber'].updateValueAndValidity();
      }
    });
  }
  // on value change form field set validation error/message
  public onValueChanged(data?: any) {
    if (!this.AddBankAccountForm) { return; }
    const form = this.AddBankAccountForm;
    for (const field in this._formErrors) {
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }
  // edit bank account
  public EditAccount(bankaccountobj) {
    this.AddBankAccountForm.setValue({
      selectedcountrycode: bankaccountobj.Country,
      accountholdername: bankaccountobj.AccountHolderName,
      accounttype: bankaccountobj.AccountHolderType,
      currency: bankaccountobj.currency,
      accountnumber: bankaccountobj.LastFourDigits,
      routingnumber: bankaccountobj.RoutingNumber
    });
    this.modalActions.emit({ action: 'modal', params: ['open'] });
  }

  private AddBankAccountFormcheckisValid(field): boolean {
    let isValid: boolean;
    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.AddBankAccountForm.controls[field].touched === false) {
      isValid = true;
    } else if (this.AddBankAccountForm.controls[field].touched === true && this.AddBankAccountForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }

  VerifyBankAccountFormcheckisValid(field): boolean {
    let isValid: boolean;
    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.VerifyAccountForm.controls[field].touched === false) {
      isValid = true;
    } else if (this.VerifyAccountForm.controls[field].touched === true && this.VerifyAccountForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // add bank account form submit
  public AddBankAccount(elementValues: any) {
    let bankAccountData = {};
    if (!elementValues.routingnumber) {
      bankAccountData = {
        country: elementValues.selectedcountrycode,
        currency: elementValues.currency,
        account_holder_name: elementValues.accountholdername,
        account_holder_type: elementValues.accounttype,
        account_number: String(elementValues.accountnumber)
      };
    } else {
      bankAccountData = {
        country: elementValues.selectedcountrycode,
        currency: elementValues.currency,
        account_holder_name: elementValues.accountholdername,
        account_holder_type: elementValues.accounttype,
        routing_number: String(elementValues.routingnumber),
        account_number: String(elementValues.accountnumber)
      };
    }
    this.ShowButtonLoader = true;
    const that = this;

    const stripe = Stripe(this.AgencyStripePublishableKey);

    stripe.createToken('bank_account', bankAccountData).then(function (result) {
      if (result.token) {
        that.userService.AddBankAccount(that.UserId, that.StripeCustomerId, result.token.id, that.AgencyId)
          .subscribe(
            dbresult => {
              if (dbresult.Status) {
                that.toasting('Bank Account added successfully!', 2000, 'green');
                that.ShowButtonLoader = false;
                that.getbankAccount();
                that._EventBus.emit({ Type: 'Load-Bank-Account-Event' });
                that.closeAddAccountModal();
              } else {
                that.ShowButtonLoader = false;
                that.toasting(dbresult.Message, 2000, 'red');
              }
            },
            error => {
              that.ShowButtonLoader = false;
              that.toasting(error.message, 2000, 'red');
            }
          );
      } else {
        that.ShowButtonLoader = false;
        that.toasting(result.error.message, 2000, 'red');
      }
    });
  }
  // verify bank account number
  public VerifyBankAccount(elementValues: any) {
    this.ShowButtonLoader = true;
    const that = this;
    that.userService.VerifyBankAccount(that.StripeCustomerId, String(that.toverifyaccountid), elementValues.firstvalue,
      elementValues.secondvalue, this.AgencyId)
      .subscribe(
        result => {
          if (result.Status) {
            that.toasting('Bank Account verified successfully!', 2000, 'green');
            that.ShowButtonLoader = false;
            that._EventBus.emit({ Type: 'Load-Bank-Account-Event' });
            that.closeVerifyAccountModal();
          } else {
            that.ShowButtonLoader = false;
            that.toasting(result.Message, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // on bank account selection
  public onBankAccountSelectionChange(selectedValue) {
    this.SelectedBankAccountId = selectedValue;
  }
  // get all bank accounts
  public getbankAccount() {
    const that = this;
    let count = 0;
    this.userService.getAllBankAccount(this.UserId, true)
      .subscribe(
        result => {
          that.BankAccountList = result.Data;
          if (result.Data.length === 0) { that.SelectedBankAccountId = ''; }
          that.BankAccountList.forEach(function (item) {
            if (count === 0) { that.SelectedBankAccountId = item.StripeBankAccountId; }
            item['ShowDeleteLoader'] = false;
            count++;
          });
          count = 0;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // manage bank account
  public ManageAddBankAccount(value) {
    this.AddBankAccountForm.reset();
    this.ShowAddAccount = value;
  }

  public ToggleCreateCharge() {
    this.ShowCreateChargeDiv = !this.ShowCreateChargeDiv;
  }
  // remove bank account
  public RemoveBankAccount(StripeCustomerId, StripeBankAccountId) {
    this.BankAccountList.forEach(function (item) {
      if (StripeBankAccountId === item.StripeCardId) {
        item['ShowDeleteLoader'] = true;
      }
    });
    const that = this;
    this.userService.RemoveBankAccount(StripeCustomerId, StripeBankAccountId, this.AgencyId)
      .subscribe(
        result => {
          if (result.Status) {
            that.toasting('Card removed successfully!', 2000, 'green');
            that.getbankAccount();
          } else {
            that.toasting(result.Message, 5000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // add bank account modal
  public openAddBankAccountModal(referralId, ShowAdd, ShouldhideBackButton = false) {

    this.ShowLoader = true;
    this.ShouldhideBackButton = ShouldhideBackButton;
    this.userService.CheckStripeAccountStatus(this.AgencyId, this.UserId)

      .subscribe(

        result => {
          // if company setup payment method
          if (result.Status === true) {
            // result.Data.account_detail.PublishableKey
            this.paymentMethodSetup = true;
            this.AgencyStripePublishableKey = result.Data.account_detail.PublishableKey;
            localStorage.removeItem('StripeCust');
            localStorage.setItem('StripeCust', result.Data.user_detail.StripeCustomerId);
            this.StripeCustomerId = result.Data.user_detail.StripeCustomerId;
            /*****************************open bank account modal******************************* */
            if (referralId) { this.ReferralIds = String(referralId); }
            if (ShowAdd) { this.ManageAddBankAccount(true); } else { this.ManageAddBankAccount(false); }
            this.AddBankAccountForm.patchValue({
              selectedcountrycode: 'US',
              currency: 'USD',
              accounttype: 'individual'
            });
            this.getbankAccount();
            this.ShowLoader = false;
            this.modalActions.emit({ action: 'modal', params: ['open'] });
            /*****************************open bank account modal******************************* */
          } else {
            this.paymentMethodSetup = false;
            this.paymentMethodMessage = result.Message;
            this.ShowLoader = false;
            $('#NoPaymentMothodSetup').modal('open');
            this.toasting(result.Message, 3000, 'red');
          }
        },
        error => {
          // Validation error
          if (error.status === 422) {
            this.ShowLoader = false;
          } else {
            this.ShowLoader = false;
          }
        });
  }
  // open bank verify modal
  public openbankverifyModal(accountId) {
    this.VerifyAccountForm.reset();
    this.toverifyaccountid = accountId;
    this.verifyaccountmodalActions.emit({ action: 'modal', params: ['open'] });
  }
  // close add account modal
  public closeAddAccountModal() {
    this.ShouldhideBackButton = false;
    this.modalActions.emit({ action: 'modal', params: ['close'] });
  }
  // close verify account modal
  public closeVerifyAccountModal() {
    this.verifyaccountmodalActions.emit({ action: 'modal', params: ['close'] });
  }
  // claim referal
  public ClaimReferral() {
    const that = this;
    this._EventBus.emit(
      {
        Type: 'claimreferral',
        data: { ReferralIds: that.ReferralIds, BankAccountId: that.SelectedBankAccountId }
      });
    this.closeAddAccountModal();
  }
}
