import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { User } from '../../model/user';
import { Router } from '@angular/router';
import { TokboxService } from '../../model/tokbox.service';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';
import { toast } from 'angular2-materialize';
declare var myExtObject: any;

@Component({
  templateUrl: './agencyadmindashboardproduct.component.html'
})

export class AgencyAdminDashboardProductComponent implements OnInit {
  Products: any[];
  LoginUserId: number;
  AgencyId: number;
  LocationId: number;
  ShowProductsLoader: boolean;
  users: User[] = [];
  ShowAvailAgentsLoader: boolean;
  searchText = '';
  ngOnInit() {
    if (this.userService.decodeToken().user.RoleId !== 6) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }

    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });
  }

  constructor(private userService: UserService, private _router: Router, private tokboxService: TokboxService,
    private _EventBus: EventBus) {
    // get agency/admin detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoginUserId = UserModel.user.UserId;
      this.LocationId = UserModel.user.LocationId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
    } 
    // get all products agency
    this.GetProducts();
  }

  getSubDescription(d) {
    return d.substring(0, 50);
  }
  // get products of agency
  public GetProducts() {
    this.ShowProductsLoader = true;
    this.userService.getproducts(this.AgencyId, false)
      .subscribe(
        result => {
          if (result.Status) {
            this.Products = result.Data;
          }
          this.ShowProductsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowProductsLoader = false;
        }
      );
  }
  // get product detail single product
  public GetProductDetail(product) {
    this._router.navigate(['productagency/detail', product]);
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
}
