import { UserService } from '../../model/user.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementRef, ViewChild, Component, OnInit, EventEmitter } from '@angular/core';
import { DocumentToSave } from '../../model/documenttosave';
import { AmazonS3 } from '../../model/amazon.upload';
import { CallDetail } from '../../model/callsdetail';
import { ChatHistory } from '../../model/chathistory';
import { DomSanitizer } from '@angular/platform-browser';
import { MaterializeAction, toast } from 'angular2-materialize';
import { TokboxService } from '../../model/tokbox.service';
import { EventBus } from '../../shared/EventBus/EventBus';

// const $: any;
@Component({
    templateUrl: './agencyadmindashboardcall.component.html',

})
export class AgencyAdminDashboardCallComponent implements OnInit {
    @ViewChild('scrollMe') public myScrollContainer: ElementRef;
    @ViewChild('videoPlayer') public videoplayer: ElementRef;
    @ViewChild('iframeelement') public iframe: ElementRef;
    UserDetailLoading: boolean;
    filter: any;
    previewimagemodalparam: any;
    uploaddocmodal = new EventEmitter<string | MaterializeAction>();
    params = [];
    uploaddocmodalparam = [
        {
            dismissible: false,
            complete: function () { }
        }
    ];
    previewimagemodal = new EventEmitter<string | MaterializeAction>();
    UserLoading: boolean;
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    TotalUsers: string;
    Count: number;
    LoginUserId: number;
    AgencyId: number;
    LocationId: number;
    LanguageFlag: string;
    LoadMoreLoader: boolean;
    LoadMoreCounter: number;
    extens: String[];
    IsLoaded: boolean;
    IsVideo: boolean;
    IsDoc: boolean;
    validupload: boolean;
    lastMessageDate: string;
    DetailedUser: User;
    ShowLoader: boolean;
    NoPaymentsFound: boolean;
    NotDocumentFound: boolean;
    NotCallsFound: boolean;
    NotChatFound: boolean;
    Folders: any[];
    Documents: Document[];
    Calls: CallDetail[];
    payments: any[];
    ChatHistory: ChatHistory[];
    CallCount: number;
    IsCalls: boolean;
    IsPayments: boolean;
    IsChatHistory: boolean;
    SelectedFolderId: string;
    LoggedInUserId: number;
    UserID: number;
    CurrentUserId: number;
    _amazon: AmazonS3;
    filesTosaveDB: Array<DocumentToSave> = [];
    HasFilesToUpload: boolean;
    ShowDocTable: boolean;
    ShowCallTable: boolean;
    ShowChatTable: boolean;
    ShowPaymentTable: boolean;
    previewImageURL: any;
    callCount: string;
    previousUrl: string;
    key: string;
    reverse: boolean;
    opensearch = false;
    searchText = '';
    SortKey = 'DateTimeString';
    SortOrder = 'DESC';
    LoadMoreLoaderEnable: boolean;
    LoadMoreCalls = true;
    ngOnInit() {
        if (this.userService.decodeToken().user.RoleId !== 6) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
        }

        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
    }

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _router: Router,
        public sanitizer: DomSanitizer,
        private tokboxService: TokboxService,
        private _EventBus: EventBus
    ) {

        this.key = 'InitiatedDateTime';
        this.reverse = true;
        this.LoadMoreCounter = 0;
        const UserModel = this.userService.decodeToken();
        // get Agency/Admin user detail token
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoginUserId = UserModel.user.UserId;
            this.AgencyId = UserModel.user.AgencyId;
            if (this.userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId === 6) {
                this.LocationId = Number(this.userService.GetSelectedLocation());
            } else {
                this.LocationId = UserModel.user.LocationId;
            }
        }
        this.LoadMoreCounter = 0;
        const UserId = 0;
        this.UserID = UserId;
        this.CurrentUserId = UserId;
        this.UserID = UserId;
        this.Count = 1;
        this.getAgencyCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'locationChanged') {
                this.LocationId = Number(m.filterBy.data.LocationId);
                this.getAgencyCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
            }
        });
    }
    // load call details users agency admin
    public onScrollDown() {
        if (this.Calls.length % 50 === 0 && this.LoadMoreCalls === true) {
            this.LoadMoreLoaderEnable = true;
            this.LoadMoreCounter = this.LoadMoreCounter + 50;
            this.getAgencyCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
        }
    }
    // get agency call details api request
    public getAgencyCallsDetails(Offset, Search, SortKey, SortOrder) {
        if (this.LoadMoreCalls === true || this.searchText !== Search) {
            this.LoadMoreCalls = false;
            this.NotDocumentFound = false;
            this.NotCallsFound = false;
            this.NotChatFound = false;
            this.ShowDocTable = false;
            this.ShowChatTable = false;
            this.ShowCallTable = true;
            this.IsCalls = true;
            const that = this;
            if (Offset === 0) { that.UserLoading = true; }
            this.userService.getAgencyCallsDetails(that.AgencyId, this.LocationId, Offset, Search, SortKey, SortOrder)
            .subscribe(
                result => {
                    that.ShowLoader = true;
                    if (result.Status) {
                        if (Offset === 0) {
                            that.Calls = [];
                            that.Calls = result.Data;
                        } else if (result.Data) {
                            result.Data.forEach(function (item) {
                                that.Calls.push(item);
                            });
                        }
                        that.Calls.forEach(function (item) {
                            item.CallerFirstName = item.CallerFirstName ?
                                item.CallerFirstName[0].toUpperCase() + item.CallerFirstName.slice(1) : '';
                            item.CallerLastName = item.CallerLastName ?
                                item.CallerLastName[0].toUpperCase() + item.CallerLastName.slice(1) : '';
                            item.CallerName = `${item.CallerFirstName} ${item.CallerLastName}`;
                            item.CallerInitial = that.GetInitials(item.CallerName);
                            item.CCssClass = item.CCssClass ? item.CCssClass : that.GetClass();
                            item.ResponderName = `${item.ResponderFirstName} ${item.ResponderLastName}`;
                            item.ReponderInitial = that.GetInitials(item.ResponderName);
                            item.RCssClass = item.RCssClass ? item.RCssClass : that.GetClass();
                            if (item.Url != null) {
                                const recordedUrl = item.Url.split('?');
                                item.Url = recordedUrl[0];
                                item.VideoExtention = 'mp4';
                            }
                            const dateTime = new Date(item.InitiatedDateTime).toLocaleString();
                            item.DateTimeString = dateTime;
                            let minutes = Math.floor(item.Duration / 60).toString();
                            if (minutes.toString().length === 1) { minutes = '0' + minutes; }
                            let seconds = (item.Duration % 60).toString();
                            if (seconds.toString().length === 1) { seconds = '0' + seconds; }
                            item.DurationInMinutes = minutes + ':' + seconds;
                            if (item.CallStatusId === 1) {
                                item.CallType = 'Missed';
                                item.TextClass = 'red-text';
                            } else if (item.callerId === that.LoggedInUserId) {
                                item.CallType = 'Incoming';
                                item.TextClass = 'green-text';
                            } else {
                                item.CallType = 'Outgoing';
                                item.TextClass = '';
                            }
                        });
                    } else {
                        if (Offset === 0) { that.NotCallsFound = true; }
                        that.Calls = [];
                    }
                    that.ShowLoader = false;
                    that.UserLoading = false;
                    that.LoadMoreLoader = false;
                    if (that.Calls.length > 0) {
                        that.callCount = that.Calls[0].CallCounts.toString();
                        that.NotCallsFound = false;
                    } else {
                        that.callCount = '0';
                        that.NotCallsFound = true;
                    }
                    this.LoadMoreLoaderEnable = false;
                    this.LoadMoreCalls = true;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        that._router.navigate(['/login']);
                    }
                    this.LoadMoreCalls = true;
                });
        }
    }
    // preview recording file
    public previewFile(url, Extention, index) {
        const that = this;
        if (Extention.toLowerCase() === 'jpg' || Extention.toLowerCase() === 'png' || Extention.toLowerCase() === 'jpeg') {
            if (this.previewImageURL !== url) { this.IsLoaded = false; }
            this.previewImageURL = url;
            this.IsDoc = false;
            this.IsVideo = false;
            this.openPreviewModal();
        } else if (Extention.toLowerCase() === 'mp4') {
            if (this.previewImageURL !== url) { this.IsLoaded = false; }
            this.IsLoaded = true;
            this.previewImageURL = url;
            this.IsDoc = false;
            this.IsVideo = true;
            const millisecondsToWait = 1;
            setTimeout(function () {
                that.openPreviewModal();
                that.videoplayer.nativeElement.load();
            }, millisecondsToWait);
        } else {
            this.IsDoc = true;
            this.IsVideo = false;
            const DocUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + url);
            this.previewImageURL = DocUrl;
            this.iframe.nativeElement.src = DocUrl;
            this.openPreviewModal();
        }
    }
    // open recording video play modal
    public openPreviewModal() {
        this.previewimagemodal.emit({ action: 'modal', params: ['open'] });
    }
    // get user detail
    userDetail(ResponderId, UserId) {
        this.UserDetailLoading = true;
        this.userService.userDetail(ResponderId, UserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.DetailedUser = result.Data;
                        this.CallCount = this.DetailedUser[0].TotalCalls;
                        const userName = this.DetailedUser ? `${this.DetailedUser[0].FirstName} ${this.DetailedUser[0].LastName}` : '';
                        this.DetailedUser[0].Initials = this.GetInitials(userName);
                    } else {
                        alert(result.Message);
                    }
                    this.UserDetailLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }
    // get intials by name
    public GetInitials(Name) {
        if (this.Count >= 3) {
            this.Count = 1;
        } else {
            this.Count = this.Count + 1;
        }
        const splittedName = Name.split(' ', 3);
        if (splittedName.length > 1) {
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
        } else {
            return splittedName[0].charAt(0);
        }
    }
    // get class call listing
    public GetClass() {
        if (this.Count === 1) {
            return 'iconUser noImg cyan lighten-3';
        } else if (this.Count === 2) {
            return 'iconUser noImg cyan lighten-5';
        } else if (this.Count === 3) {
            return 'iconUser noImg pink lighten-4';
        }
    }
    // show success/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // show video process for new calls
    showVideoInProgressMsg(archiveId: any) {
        this.toasting('Video is being proccessed. Please try after some time.', 3000, 'red');
        this.userService.GetVideoByArchiveId(archiveId).subscribe(result => {
            if (result.Status && result.Data) {
                const video = result.Data[0];
                if (video) {
                    const index: number = this.Calls.findIndex(x => x.ArchiveId === archiveId);
                    if (index >= 0) {
                        if (video.Url) {
                            const recordedUrl = video.Url.split('?');
                            this.Calls[index].Url = recordedUrl[0];
                            this.Calls[index].VideoExtention = 'mp4';
                        }
                    }
                }
            } else {
                this.toasting(result.Message, 3000, 'red');
            }
        },
            error => {
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this._router.navigate(['/login']);
                }
            });
    }
    // search records user calls
    SearchCalls() {
        if (this.searchText.length > 0) {
            this.LoadMoreCounter = 0;
        }
        this.getAgencyCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
    }
    // agency calls sorting by column
    ServerSort(sortKey: string) {
        this.LoadMoreCounter = 0;
        if (this.SortKey === sortKey) {
            this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
            this.SortKey = sortKey;
        } else {
            this.SortOrder = 'ASC';
            this.SortKey = sortKey;
        }
        this.getAgencyCallsDetails(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
    }

    // close preview modal
    public closepreviewimageModal() {
        this.previewimagemodal.emit({ action: 'modal', params: ['close'] });
    }
}
