import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../model/user.service';
import { MaterializeAction } from 'angular2-materialize';
import { toast } from 'angular2-materialize';
import { EventBus } from '../../../shared/EventBus/EventBus';
declare const $: any;
@Component({
  selector: 'app-addlocation',
  templateUrl: './addlocation.component.html',
  styleUrls: ['./addlocation.component.css']
})
export class AddlocationComponent implements OnInit {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('AddressDetail') AddressDetail: ElementRef;
  card: any;
  showCPassword: boolean;
  planOb: any[];
  model1Params: any;
  password: any;
  error: string;
  domainName: string;
  IsValidLength: boolean;
  IsFullNameValid: boolean;
  IsCompNameValid: boolean;
  IsEmailValid: boolean;
  IsStrong: boolean;
  IsMatch: boolean;
  PlanDetail: any;
  _isCardValid: boolean;
  _signupForm: FormGroup;
  _addForm: FormGroup;
  _chargeCustomer: FormGroup;
  private _formErrors: any;
  ShowLoader: boolean;
  ShowCouponLoader: boolean;
  PlanPrice: string;
  PlanName: string;
  Plan_img: string;
  Plan_for: string;
  VideoHour: string;
  RecordingHour: string;
  Agent: string;
  Location: string;
  ChatWithUsers: string;
  EmailSupport: string;
  PromoCode: string;
  discountamount: string;
  showsucesscoupon: boolean;
  neweffectiveamount: any;
  showinvalidcoupon: boolean;
  PapValue: string;
  StripePlanId: string;
  modalActions1 = new EventEmitter<string | MaterializeAction>();
  strouteComponent = {
    'sublocality_level_1': 'long_name'
  };
  stateComponent = {
    administrative_area_level_1: 'long_name'
  };
  postalComponent = {
    postal_code: 'short_name'
  };
  cityComponent = {
    locality: 'long_name'
  };
  public place_id: string;
  public city: string;
  public state: string;
  public zipcode: string;
  public lat: any;
  public long: any;
  public street_name: string;
  public stroute_flag: any;
  public stroute: string;
  public AddressDescription: any;
  public AgencyCity: any;
  public Address: string;
  public AgencyZipCode: any;
  public AgencyState: any;
  Address1latitude: any;
  Address1longitude: any;
  CurrentSubsObj: any;
  Planid: string;
  AgencyId: Number;
  rowIndex = 0;
  StripeCustomerId: string;
  ShowButtonLoader = false;
  SelectedCardId = '';
  ShowCreateChargeDiv = false;
  LoadMoreCounter = 0;
  PaymentMethodListCard: any = [];
  IsLoadingPaymentMethodList: boolean;
  UserId: number;
  UserName = '';
  MainDomain = '';
  NotcheckedExist: boolean;
  checkedExist = false;
  checkedNotExist: boolean;
  DomainNameFilled: number;
  IsDomainAvailble = true;
  IsLocationAdded = true;
  AgencySubscriptionId: string;
  Quantity = 1;
  PlanId: string;
  ShowLoaderAddLocation: boolean;
  LocationDomainDefault = false;
  ParentSiteAddress: string;
  locationInput: string;
  FirstLocationLoaded = false;
  ShowLoaderFormRight = false;
  AddressSettings: any = {
    inputPlaceholderText: 'Enter Address'
  };
  editStatus: boolean;
  ShowLoaderCenter: boolean;
  constructor(private _router: Router,
    private userService: UserService,
    private _formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private _userService: UserService,
    private _EventBus: EventBus,
    private route: ActivatedRoute) {
    this.NotcheckedExist = true;
    this._isCardValid = false;
    this.IsFullNameValid = true;
    this.IsCompNameValid = true;
    this.IsEmailValid = true;
    this.AddressSettings = Object.assign({}, this.AddressSettings);
    // get Agency/Admin detail fron token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.AgencyId = UserModel.user.AgencyId;
      this.UserId =  UserModel.user.UserId;
      this.StripeCustomerId = UserModel.user.StripeCustomerId;
      this.MainDomain = UserModel.user.SiteAddress;
      this.UserName = UserModel.user.Name;
      this.GetSubscriptionDetail();
      this.getAgencyCardDetail();
      this.getAgencyCardDetail();
      this.AgencyDefaultDomain();
    }
    this.rowIndex = 0;
    this.ShowButtonLoader = false;
    this.SelectedCardId = '';
    this.ShowCreateChargeDiv = false;
    this.LoadMoreCounter = 0;
    this._chargeCustomer = _formBuilder.group({
      amount: ['', Validators.compose([Validators.required])],
      SelectedCardId: ['', Validators.compose([Validators.required])],
      description: ['']
    });
  }
  // show Success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  ngOnInit() {
    // add location form
    this._signupForm = this._formBuilder.group({
      'locationName': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      'address': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      'state': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      'city': ['', [Validators.required]],
      'zip': ['', [Validators.required]],
      'domainName': ['', Validators.required]
    });
  }
  // check valid form field add location
  public _isValid(field): boolean {
    let isValid: boolean;
    if (this._signupForm.controls[field].touched === false) {
      isValid = true;
    } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // check domain exist or not
  checkDomain(data) {
        if (data.name === 'domain_name' && data.value !== '' && data.value !== this.ParentSiteAddress) {
            this.IsDomainAvailble = false;
            this.ShowLoader = true;
            this.ShowLoaderAddLocation = true;
            this.DomainNameFilled = 1;
            this._userService.ChaeckDomainName(data.value.trim(), this.AgencyId)
                .subscribe(
                    result => {
                        if (result.Status) {
                            this.IsDomainAvailble = false;
                            this.checkedExist = true;
                            this.NotcheckedExist = false;
                            this.checkedNotExist = false;
                            this.DomainNameFilled = 0;
                            this.ShowLoader = false;
                            this.ShowLoaderAddLocation = false;
                            return false;
                        } else {
                            this.IsDomainAvailble = true;
                            this.checkedExist = false;
                            this.NotcheckedExist = false;
                            this.checkedNotExist = true;
                            this.DomainNameFilled = 1;
                            this.ShowLoader = false;
                            this.ShowLoaderAddLocation = false;
                        }
                    },
                    error => {
                        if (error.status === 422) {
                            const errorFields = JSON.parse(error.data.message);
                            this.ShowLoader = false;
                        } else {
                            this.ShowLoader = false;
                            return false;
                        }
                        this.ShowLoaderAddLocation = false;
                    });

        } else if (data.name === 'domain_name' && data.value !== ''
        && this.LocationDomainDefault === true && data.value === this.ParentSiteAddress) {
          this.IsDomainAvailble = true;
          this.checkedExist = false;
          this.NotcheckedExist = true;
          this.checkedNotExist = false;
          this.DomainNameFilled = 0;
          this.ShowLoader = false;
          this._signupForm.controls['domainName'].setValue(this.ParentSiteAddress);
          this._signupForm.controls['domainName'].setErrors(null);
        } else {
            this.DomainNameFilled = 0;
            this.ShowLoader = false;
        }
    }
  // google auto complete search
  autoCompleteCallback1(selectedData: any) {
    if (selectedData.data) {
      const place = selectedData.data;
      const name = place.name;
      let stroute_flag = 0;
      let stroute = '';
      let city_flag = 0;
      let state_flag = 0;
      let zip_flag = 0;
      this.AddressDescription = place.description;
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (this.strouteComponent[addressType]) {
          stroute_flag = 1;
          stroute = place.address_components[i][this.strouteComponent[addressType]];
        }
        if (this.cityComponent[addressType]) {
          city_flag = 1;
          this.city = place.address_components[i][this.cityComponent[addressType]];
        }
        if (this.stateComponent[addressType]) {
          state_flag = 1;
          this.state = place.address_components[i][this.stateComponent[addressType]];
        }
        if (this.postalComponent[addressType]) {
          zip_flag = 1;
          this.zipcode = place.address_components[i][this.postalComponent[addressType]];
        }
      }
      this._signupForm.controls['address'].setValue(this.AddressDescription);
      this._signupForm.controls['address'].setErrors(null);
      this._signupForm.controls['city'].setValue(this.city);
      this._signupForm.controls['state'].setValue(this.state);
      this._signupForm.controls['zip'].setValue(this.zipcode);

      if (place && place.geometry && place.geometry.location) {
        this.place_id = place.place_id;
        this.lat = Number(place.geometry.location.lat);
        this.long = Number(place.geometry.location.lng);
        this.Address1latitude = this.lat.toFixed(3);
        this.Address1longitude = this.long.toFixed(3);
      }
    } else {
      this.Address1latitude = '';
      this.Address1longitude = '';
    }
  }

  get f() { return this._signupForm.controls; }
  // get agency subscription detail
  public GetSubscriptionDetail() {
    this.userService.GetSubscriptionDetail(this.AgencyId)
        .subscribe(
            result => {
                if (result.Status) {
                    this.CurrentSubsObj = result.Data;
                    this.Planid = this.CurrentSubsObj.PlanTypeId;
                    this.PlanId = this.CurrentSubsObj.PlanId;
                    if (this.CurrentSubsObj.Quantity) {
                      this.Quantity = this.CurrentSubsObj.Quantity;
                    } else {
                      this.Quantity = 0;
                    }
                    this.AgencySubscriptionId = this.CurrentSubsObj.SubscriptionId;
                } else {
                    this.toasting(result.Message, 2000, 'red');
                }
            },
            error => {
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this._router.navigate(['/login']);
                }
                this.ShowLoaderFormRight = false;
            }
        );
  }
  // get agency card detail for charge
  public getAgencyCardDetail() {
    const that = this;
    let count = 0;
    this.rowIndex = 0;
    this.IsLoadingPaymentMethodList = true;
    this.ShowLoaderFormRight = true;
    this.userService.getAgencyCardDetail(this.StripeCustomerId, this.AgencyId)
      .subscribe(
        result => {
          if (result.Status && result.Data) {
            this.IsLoadingPaymentMethodList = false;
            that.PaymentMethodListCard = result.Data.data ? result.Data.data : [];
            that.PaymentMethodListCard.forEach(function (item) {
              if (count === 0) { that.SelectedCardId = item.StripeCardId; }
              item['MethodType'] = 'Card';
              item['ShowDeleteLoader'] = false;
              count++;
            });
            this._chargeCustomer.controls['SelectedCardId'].setValue(that.SelectedCardId);
            count = 0;
          } else {
            that.SelectedCardId = '';
            this._chargeCustomer.controls['SelectedCardId'].setValue('');
            this.IsLoadingPaymentMethodList = false;
          }
          this.ShowLoaderFormRight = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowLoaderFormRight = false;
        }
      );
  }
  // add new location agency form submit
  locationFormSubmit(formData: any) {
    const FormDataLocation = formData.value;
    let DomainName = '';
    if (!this.userService.IsLocalHost()) {
      DomainName = this.userService.getDomain();
    } else {
      DomainName = 'innovativeinsuranceprofessional.com';
    }
    if (FormDataLocation.address && FormDataLocation.address.length > 2) {
      this.IsLocationAdded = false;
      this.ShowLoaderCenter = true;
      this.userService.AddLocation(this.AgencyId, this.StripeCustomerId, this.PlanId, FormDataLocation.locationName,
        FormDataLocation.address, FormDataLocation.city, FormDataLocation.state, FormDataLocation.zip,
        FormDataLocation.domainName, this.place_id, this.Address1latitude, this.Address1longitude, DomainName,
        this.AgencySubscriptionId, this.Quantity, this.Planid, this.UserId)
      .subscribe((response) => {
        if (response.Status) {
          this._EventBus.emit({ Type: 'locationAddedDeleted', data: { delete: false } });
          this.toasting('Location Added Successfully.', 2000, 'green');
          this.OnRedirect();
        } else {
          this.toasting(response.Message, 2000, 'red');
        }
        this.IsLocationAdded = true;
        this.ShowLoaderCenter = false;
      }, (error) => {
        this.toasting(error.Message, 2000, 'red');
        this.IsLocationAdded = true;
        this.ShowLoaderCenter = false;
      });
    } else {
      this.toasting('Please enter address.', 2000, 'red');
    }
  }
  // redirect user after add location
  OnRedirect() {
    this._router.navigate(['/agency/locations']);
  }
  // enable edit domain
  public editLocationName(event) {
    this.LocationDomainDefault = event;
    if (event === false) {
      this._signupForm.controls['domainName'].setValue(this.ParentSiteAddress);
      this._signupForm.controls['domainName'].setErrors(null);
      const DomainValue =  this._signupForm.controls['domainName'].value;
      if (DomainValue !== this.ParentSiteAddress) {
        this.IsDomainAvailble = false;
        this.checkedExist = false;
        this.NotcheckedExist = true;
        this.checkedNotExist = false;
        this.DomainNameFilled = 0;
        this.ShowLoader = false;
      } else {
        this.IsDomainAvailble = true;
      }
    } else {
      this._signupForm.controls['domainName'].setValue(this.ParentSiteAddress);
      this._signupForm.controls['domainName'].setErrors(null);
      this.IsDomainAvailble = true;
    }
  }
  // get agency default domian name
  public AgencyDefaultDomain() {
    this.userService.GetAgencyDefaultDomain(this.AgencyId).
    subscribe((response) => {
       if (response.Status) {
          this.ParentSiteAddress = response.Data.SiteAddress;
          this._signupForm.controls['domainName'].setValue(response.Data.SiteAddress);
          this._signupForm.controls['domainName'].setErrors(null);
        } else {
          this.toasting(response.Data.ExMessage, 2000, 'red');
       }
    }, (error) => {
       this.toasting(error.Message, 2000, 'red');
    });
  }
  // handle enter key press form submit
  public handleEnterKeyPress(event) {
    return false;
  }
  // change address google address auto complete
  public ChangeAddress(event) {
    const LocationAddress = $('#search_places').val();
    if (LocationAddress && LocationAddress.length > 2) {
      this._signupForm.controls['address'].setValue(LocationAddress);
      this._signupForm.controls['address'].setErrors(null);
    } else {
      this._signupForm.controls['address'].setValue('');
    }
  }
  // detech if domain change
  public DomainChanged(event) {
    if (event !== this.LocationDomainDefault) {
      if (this.FirstLocationLoaded) {
        this.IsDomainAvailble = false;
        this.checkedExist = false;
        this.NotcheckedExist = true;
        this.checkedNotExist = false;
        this.DomainNameFilled = 0;
        this.ShowLoader = false;
      }
      this.FirstLocationLoaded = true;
    } else {
      this.FirstLocationLoaded = true;
    }
  }
  public editLoc(value) {
    this.editStatus = value;
    if (value === false) {
      this._signupForm.controls['domainName'].setValue(this.ParentSiteAddress);
      this._signupForm.controls['domainName'].setErrors(null);
      this.IsDomainAvailble = true;
    }
  }
}
