// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // api_url: 'https://lowrate.softobiz.biz/api',
  // domain_name: '.symple.co.in/',
  // api_url: 'https://api.innovativeinsuranceprofessional.com/api',
  // api_url: 'http://localhost:8012/api',
  api_url: "https://agentapi.symple.co.in/api",
  // api_url: 'https://backendapi.lowrateinsurance.net/api',
  // api_url: 'https://api.lowrateinsurance.net/api',
  // api_url: 'http://35.169.234.141:81/api',
  // api_url: 'https://api.agentalive.tech/api',
  // amazon_access_key: 'AKIAIVAAH36LO263OCJA',
  // amazon_secret_access_key: '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',
  amazon_bucket_name: 'lowrate-insurance',
  amazon_thumbnail_bucket_name: 'lowrate-insurance-thumbnails',
  amazon_file_root_url: 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/',
  // stripe_test_client_id: 'ca_CzBpyrpDc17yK8SYlRPt5OsZYK9Q7wYj',
  // stripe_live_client_id: 'ca_CzBpoNxQxQaoyvnzmzTva1k4GdhQme6h',
  // zoho_redirect_uri: 'https://api.innovativeinsuranceprofessional.com/api/zoho/auth', // 'https://api.innovativeinsuranceprofessional.com',
  // zoho_client_id: '1000.XRZU3P6JBWM218360VWUVBF6SO0KOK',
  // zoho_client_secret: '5d14e5e3476aa3e5552f6104de8c7e13a79d171c32',
  // zoho_scopes: 'ZohoCRM.modules.all', // 'ZohoCRM.modules.leads.ALL,ZohoCRM.modules.accounts.ALL,ZohoCRM.modules.contacts.ALL,'
  AWSASE_KEY: 'mypass~!@#$%word',
  AWSASE_KEY_IV: '~!@#$()_+~%^&*!@'
};


