import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../../model/user.service';
import { toast } from 'angular2-materialize';
import { AgencyMatrixLinkData } from '../../../model/agencyMatrix';
import { Router } from '@angular/router';
@Component({
  selector: 'app-account-exist',
  templateUrl: './account-exist.component.html',
  styleUrls: ['./account-exist.component.css']
})
export class AccountExistSettingComponent implements OnInit {
  ShowLoader = false;
  AgencyId: number;
  UserId: number;
  AgencyName: string;
  accountExistForm: FormGroup;
  error = '';
  UserModel: any;
  ExistEmail: string;
  AgencyGuid: string;
  @Output() openParentModel = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private _userService: UserService, private router: Router) {
    this.UserModel = this._userService.decodeToken();
    // set data from token agency admin
    if (this.UserModel && this.UserModel.user) {
      this.AgencyId = this.UserModel.user.AgencyId;
      this.UserId = this.UserModel.user.UserId;
      this.AgencyGuid = this.UserModel.user.AgencyGuid;
      this.AgencyName = this.UserModel.user.AgencyName;
      // accout exists form
      this.accountExistForm = this.formBuilder.group({
        'owner_email': [this.UserModel.user.Email, [Validators.required, Validators.email]]
      });
    }
  }

  ngOnInit() {

  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // link agency matrix account with agent alive
  integrationDone(formValues) {
    this.ShowLoader = true;
    const requestData = new AgencyMatrixLinkData();
    requestData.AgencyId = this.AgencyId;
    requestData.UserId = this.UserId;
    requestData.Email = formValues.owner_email;
    requestData.AgencyGuid = this.AgencyGuid;
    requestData.AgencyName = this.AgencyName;
    this._userService.linkAgencyMatrixAccount(requestData).subscribe(
      (responseMatrix) => {
        if (responseMatrix.Status) {
          this.toasting('Your account is linked successfully', 2000, 'green');
          this.router.navigate(['/agency/agency-matrix-setting']);
        } else {
          this.toasting(responseMatrix.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      }, (errorMatrix) => {
        if (errorMatrix.Data) {
          const arrayError = [];
          errorMatrix.Data.forEach((element) => {
              if (element && element.length) {
                for (const propName in element) {
                  if (errorMatrix.Data.hasOwnProperty(propName)) {
                    arrayError.push(element[propName]);
                  }
                }
              }
          });
          if (arrayError) {
            this.toasting(arrayError.join(', ').replace(/"|_/g, ''), 2000, 'red');
          } else {
            this.toasting(errorMatrix.Message, 2000, 'red');
          }
        } else {
          this.toasting(errorMatrix.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      }
    );
  }
}
