import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import { toast } from 'angular2-materialize';
import { EventBus } from '../../shared/EventBus/EventBus';

declare var myExtObject: any;

@Component({
  templateUrl: './UserPayment.component.html'
})

export class UserPaymentComponent implements OnInit {
  ShowpaymentsLoader: boolean;
  ShowAvailAgentsLoader: boolean;
  users: User[] = [];
  payments: any[];
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LocationId: number;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  AgencyId: number;
  LoadMoreCounter: number;
  paymentkey: string;
  paymentreverse: boolean;
  wasClicked = false;
  NotPaymentFound = false;
  searchText = '';
  SortKey = 'CreatedDateTime';
  SortOrder = 'DESC';
  LoadMoreLoaderEnable: boolean;
  LoadMorePayment = true;
  ngOnInit() {
    if (this.userService.decodeToken().user.RoleId !== 3) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }

    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });
  }


  constructor(private userService: UserService, private tokboxService: TokboxService,
    private route: ActivatedRoute, private _EventBus: EventBus,
    private _router: Router) {
    const that = this;
    this.paymentkey = 'CreatedDateTime';
    this.paymentreverse = true;
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LocationId = UserModel.user.LocationId;
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.loadAllUsers(UserModel.user.RoleId);
    }
    this.LoadMoreCounter = 0;
    this.GetUserPayments(this.LoadMoreCounter, this.SortKey, this.SortOrder);
    this._EventBus.on().subscribe(m => {
      if (m.filterBy.Type === 'Get-User-Payment-Event') {
        let offSet = 0;
        if (m.filterBy.data) {
          offSet = m.filterBy.data.offSet;
        }
        this.GetUserPayments(offSet, this.SortKey, this.SortOrder);
      }
    });
  }
  // show success/error message user
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // scroll down load more payemmnts
  public onScrollDown() {
    if (this.payments.length % 50 === 0 && this.LoadMorePayment === true) {
      this.LoadMoreLoaderEnable = true;
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.GetUserPayments(this.LoadMoreCounter, this.SortKey, this.SortOrder);
    }
  }
  // load all users
  public loadAllUsers(RoleId) {
    this.ShowAvailAgentsLoader = true;
    this.userService.getAll(RoleId, 0, this.AgencyId, this.LocationId, false, '', '', '')
      .subscribe(
        result => {
          if (result.Status) {
            this.users = result.Data;
          } else {
            this.toasting(result.Message, 2000, 'red');
          }
          this.ShowAvailAgentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAvailAgentsLoader = false;
        }
      );
  }
  // get user payments
  public GetUserPayments(Offset, SortKey, SortOrder) {
    if (this.LoadMorePayment === true) {
      this.LoadMorePayment = false;
      this.NotPaymentFound = false;
      if (Offset === 0) { this.payments = []; this.ShowpaymentsLoader = true; }
      this.userService.GetUserPayments(this.LoginUserId, this.LocationId, this.AgencyId, Offset, '', SortKey, SortOrder)
        .subscribe(
          result => {
            if (result.Status) {
              if (Offset === 0) {
                this.payments = [];
                this.payments = result.Data;
              } else {
                if (result.Data) {
                  result.Data.forEach((item) => {
                    this.payments.push(item);
                  });
                }
              }
              this.payments.forEach((item) => {
                item.DateTimeString = new Date(item.CreatedDateTime).toLocaleString();
                item.Status = item.StatusId === 1 ? 'PAID' : item.StatusId === 2 ? 'Pending' : 'Failed';
              });
            } else {
              if (Offset === 0) { this.NotPaymentFound = true; }
            }
            this.NotPaymentFound = (this.payments.length > 0) ? false : true;
            this.ShowpaymentsLoader = false;
            this.LoadMoreLoaderEnable = false;
            this.LoadMorePayment = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.ShowpaymentsLoader = false;
            this.LoadMorePayment = true;
          }
        );
      }
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }

  public LogoutTokbox() {
    myExtObject.logout();
  }

  public SendMessage() {
    myExtObject.sendmessage();
  }
  OpenAgentView() {
    this.wasClicked = !this.wasClicked;
  }
  // payments sorting server
  ServerSort(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKey === sortKey) {
      this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.SortKey = sortKey;
    } else {
      this.SortOrder = 'ASC';
      this.SortKey = sortKey;
    }
    this.GetUserPayments(this.LoadMoreCounter, this.SortKey, this.SortOrder);
  }
  // add card modal
  openAddCardModal() {
    this._EventBus.emit({ Type: 'OPEN_PAYMENT_POPUP_USER_HEADER' });
  }

}
