navigator.sayswho = (function () {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();

var res = navigator.sayswho.split(" ");
var LastMessageTimeId = '';

var agentFeatures = [{
  crome: "Crome",
  cromeVersion: 43,
  features: [],
  os: ''
},
{
  crome: "Crome",
  cromeVersion: 43,
  features: [],
  os: ''
},
{
  crome: "Crome",
  cromeVersion: 43,
  features: [],
  os: ''
},
{
  crome: "Crome",
  cromeVersion: 43,
  features: [],
  os: ''
},
]

navigator.os = (function () {

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  if (navigator.appVersion.indexOf("Win") != -1) return "Windows";
  if (navigator.appVersion.indexOf("Mac") != -1) return "MacOS";
  if (navigator.appVersion.indexOf("X11") != -1) return "UNIX";
  if (navigator.appVersion.indexOf("Linux") != -1) return "Linux";

  //alert('Your OS: ' + OSName);
  return 'unknown';
})();

navigator.saywhat = (function () {
  var features = {};
  if (res && res.length) {
    var agent = res[0];
    var ver = Number(res[1]);
    features['bversion'] = ver;
    switch (agent) {
      case 'Chrome':
        {
          if (navigator.os == 'Windows' || navigator.os == 'Linux' || navigator.os == 'UNIX') {
            if (ver >= 49) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 49) // ScreenShare
            {
              features['ScreenShare'] = true;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 49) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 49) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 49) // Plugin
            {
              features['Plugin'] = true;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          } else if (navigator.os == 'MacOS') {
            if (ver >= 49) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 49) // ScreenShare
            {
              features['ScreenShare'] = true;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 49) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 49) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 49) // Plugin
            {
              features['Plugin'] = true;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          } else if (navigator.os == "iOS") {
            features['WebRTC'] = false;
            features['Plugin'] = false;
            features['Calling'] = false;
            features['ScreenShare'] = false;
            features['IsMobileOS'] = true;
          } else if (navigator.os == "Android") {
            if (ver >= 69) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 69) // ScreenShare
            {
              features['ScreenShare'] = false;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 69) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 69) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 69) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = true;
          }
          break;
        }
      case 'Firefox':
        {
          if (navigator.os == 'Windows' || navigator.os == 'Linux' || navigator.os == 'UNIX') {
            if (ver >= 62) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 62) // ScreenShare
            {
              features['ScreenShare'] = true;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 62) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 62) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 62) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          } else if (navigator.os == 'MacOS') {
            if (ver >= 49) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 49) // ScreenShare
            {
              features['ScreenShare'] = true;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 49) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 49) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 49) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          } else if (navigator.os == "iOS") {
            features['WebRTC'] = false;
            features['Plugin'] = false;
            features['Calling'] = false;
            features['ScreenShare'] = false;
            features['IsMobileOS'] = true;
          }
          break;
        }
      case 'Opera':
        {
          if (navigator.os == 'Windows' || navigator.os == 'Linux' || navigator.os == 'UNIX') {
            if (ver >= 56) // Opera Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 56) // ScreenShare
            {
              features['ScreenShare'] = false;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 56) // Calling
            {
              features['Calling'] = false;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 56) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 56) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          } else if (navigator.os == 'MacOS') {
            if (ver >= 56) // crome Web RTC
            {
              features['WebRTC'] = false;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 56) // ScreenShare
            {
              features['ScreenShare'] = false;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 56) // Calling
            {
              features['Calling'] = false;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 56) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 56) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          } else if (navigator.os == "iOS") {
            features['WebRTC'] = false;
            features['Plugin'] = false;
            features['Calling'] = false;
            features['ScreenShare'] = false;
            features['IsMobileOS'] = true;
          }
          break;
        }

      case 'Safari':
        {
          if (navigator.os == 'MacOS') {
            if (ver >= 11) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 11) // ScreenShare
            {
              features['ScreenShare'] = false;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 11) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 11) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = true;
            }
            if (ver >= 11) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          } else if (navigator.os == "iOS") {
            if (ver >= 11) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 11) // ScreenShare
            {
              features['ScreenShare'] = false;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 11) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 11) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = true;
            }
            if (ver >= 11) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = true;
          }
          break;
        }

      case 'IE':
        {
          if (navigator.os == 'Windows') {
            if (ver >= 10) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 10) // ScreenShare
            {
              features['ScreenShare'] = true;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 10) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 10) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 10) // Plugin
            {
              features['Plugin'] = false;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          }
          break;
        }

      case 'MSIE':
        {
          if (navigator.os == 'Windows') {
            if (ver >= 10) // crome Web RTC
            {
              features['WebRTC'] = true;
            } else {
              features['WebRTC'] = false;
            }
            if (ver >= 10) // ScreenShare
            {
              features['ScreenShare'] = true;
            } else {
              features['ScreenShare'] = false;
            }

            if (ver >= 10) // Calling
            {
              features['Calling'] = true;
            } else {
              features['Calling'] = false;
            }
            if (ver >= 10) // Angular
            {
              features['Angular'] = true;
            } else {
              features['Angular'] = false;
            }
            if (ver >= 10) // Plugin
            {
              features['Plugin'] = true;
            } else {
              features['Plugin'] = false;
            }
            features['IsMobileOS'] = false;
          }
          break;
        }

    }
    return features;
  } else return null;
})();

// Detect whether this browser is IE
var isNotIE = function isIE() {
  var userAgent = window.navigator.userAgent.toLowerCase(),
    appName = window.navigator.appName;

  return !(appName === 'Microsoft Internet Explorer' ||
    (appName === 'Netscape' && userAgent.indexOf('trident') > -1)); // IE >= 11
};

var tokboxcalldata = [];

var shownotifications = false;

var apiKey = "46439132";
var default_SessionId = "1_MX40NjQzOTEzMn5-MTU3MTIzMzQxMTE1MH42cUtCam9FMXlFQmdPRWxsVE5KdnpFV2t-fg";

// var serverURL = "https://api.lowrateinsurance.net/api/";
// var serverURL = "https://lowrate.softobiz.biz/api/";
// var serverURL = "https://api.innovativeinsuranceprofessional.com/api/";
// var serverURL = "https://backendapi.lowrateinsurance.net/api/";
// var serverURL = "http://localhost:8012/api/";
// var serverURL = "https://api.agentalive.tech/api/";
var serverURL = "https://agentapi.symple.co.in/api/";

// sheshank is here

var screenshareextensioncode = '';
if (serverURL === 'https://api.agentalive.tech/api/') {
  screenshareextensioncode = 'nhhfhhhndgppfbnamfkiehnajaalohkm';
} else {
  screenshareextensioncode = 'eecejlhjnhjbjijclloleehlijdgomlh';
}
var downloadextnlink = 'https://chrome.google.com/webstore/detail/' + screenshareextensioncode;
// });

var sessionId;
var token = "";
var session;
var tempSession;
var publisher;
var subscriber;
var receiverId;
var callerId;
var newCallSessionId = "";
var newCallTokenId = "";
var connectingTo;
var lastCallMessageData;
var calltype;
var Count = 1;
var lastArchiveId;

//Call connection details
var connectedToUserId;
var connectedToUsername;
var connectedToUserImage;
var connectedToUserEmail;
var timer = new Timer();
var ConversationType = {
  videoCall: "videoCall",
  audioCall: "audioCall",
  chatCall: "chatCall"
}

var stripe;
publisher;

function CreateStripeSuperAdminInstance(token) {
  stripe = Stripe(token);
}

function getStripePublishableKey(token) {
  stripe = Stripe(token);
}

// Handling all of our errors here by alerting them
function handleError(error) {
  if (error) {
    if (error.message.includes("Session.publish :: Publisher destroyed before it finished loading.")) {

    } else {
    }

    switch (error.code) {
      case 1004:
        break;

      case 1005:
        break;

      case 1006:
        break;

      case 1020:
        break;

      case 1030:
        break;

      case 1035:
        break;

      case 1010:
        break;

      case 1500:
        alertify.error("No Camera device is attached.")
        break;

      case 1601:
        break;

      case 1510:
        break;

      case 1600:
        break;

      case 1011:
        break;

      case 2000:
        break;


    }

  }
}

var myExtObject = (function () {
  return {
    CreateCardInstance: function () {
      var elements = stripe.elements();
      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      var style = {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };

      // Create an instance of the card Element.
      var card = elements.create('card', {
        style: style
      });

      // Add an instance of the card Element into the `card-element` <div>.
      card.mount('#card-element');

      // Handle real-time validation errors from the card Element.
      card.addEventListener('change', function (event) {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });

      // Handle form submission.
      var form = document.getElementById('payment-form');
      if (form) {
        form.addEventListener('submit', function (event) {
          showAddCardLoader();
          event.preventDefault();
          stripe.createToken(card).then(function (result) {
            if (result.error) {
              // Inform the user if there was an error.
              var errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
              hideAddCardLoader();
            } else {
              // Send the token to your server.
              stripeTokenHandler(result.token);
            }
          });
        });
      }

      // Handle update card submission.
      var form = document.getElementById('payment-form-update-card');
      if (form) {
        hideUpdateCardLoader();
        form.addEventListener('submit', function (event) {
          showUpdateCardLoader();
          event.preventDefault();
          stripe.createToken(card).then(function (result) {
            if (result.error) {
              // Inform the user if there was an error.
              var errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
              hideUpdateCardLoader();
            } else {
              // Send the token to your server.
              // stripeTokenHandler(result.token);
              if (window.updatecardref) {
                window.updatecardref.component.stripetoken = result.token;
                window.updatecardref.zone.run(function () {
                  window.updatecardref.receivetoken(result.token);
                });
              }
            }
          });
        });
      }

      // Handle resubscribe card submission.
      var form = document.getElementById('resubscribe-form-update-card');
      if (form) {
        hideSubscriptionCardLoader();
        form.addEventListener('submit', function (event) {
          showSubscriptionCardLoader();
          event.preventDefault();
          stripe.createToken(card).then(function (result) {
            if (result.error) {
              // Inform the user if there was an error.
              var errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
              hideSubscriptionCardLoader();
            } else {
              // Send the token to your server.
              if (window.resubscribecardref) {
                window.resubscribecardref.component.stripetoken = result.token;
                window.resubscribecardref.zone.run(function () {
                  window.resubscribecardref.receivetoken(result.token);
                });
              }
            }
          });
        });
      }
    },

    GetTokenForPlanPurchase: function (cardNumber, expiryMonth, expiryMonth, expiryYear) {
      var status = stripe.card.createToken({
        number: this.cardNumber,
        exp_month: this.expiryMonth,
        exp_year: this.expiryYear,
        cvc: this.cvv

      }).then(function (result) {
        if (result.error) {

        }

      });
    },

    ShowAddCard: function () {
      $('*[id*=AddCardContainer]').each(function () {
        $(this).show();
      });

      $('*[id*=paycontainer]').each(function () {
        $(this).hide();
      });
    },
    HideAddCard: function () {
      $('*[id*=AddCardContainer]').each(function () {
        $(this).hide();
      });

      $('*[id*=paycontainer]').each(function () {
        $(this).show();
      });
    },
    start: function (newSessionId, newTokenId, otherTokenId, ToUserId, currentSessionId, ToUserPic, ToInitial, ToName, description, IsAudio) {
      if (!description) {
        description = ''
      }

      tempConversationType = "";
      if (IsAudio) {
        IsAudio = true;
        tempConversationType = 'Voice Calling...'
      } else {
        IsAudio = false;
        tempConversationType = 'Video Calling...'
      }


      activitySession = newSessionId;
      $("#responder_name").text(ToName);
      $("#conversationTypeTag").text(tempConversationType);
      if (!navigator.saywhat || !navigator.saywhat.Calling) {
        $('.loader-outer').css('display', 'none');
        if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
          window['angularComponentRef'].zone.run(function () {
            window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
          });
        }

        if (navigator.saywhat.IsMobileOS) {
          // show popup to install mobile app
          showAppInstallPopup();
        } else {
          // show message for unsupported browser
          $("#calling_not_supported").addClass("open");
        }

        return;
      }
      // }
      //Restrict user from calling if already in a call
      if (document.getElementById('ongoingCall').classList.contains('active')) {
        alert('Cant make a call while a call is in-progress.');
        $('.loader-outer').css('display', 'none');
        if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
          window['angularComponentRef'].zone.run(function () {
            window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
          })
        }
        return;
      }
      if (ToUserId == "0") {
        // alert('Cant make a call while a call is in-progress.');
        alertify.error('No agent available! Please try again later');
        if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
          window['angularComponentRef'].zone.run(function () {
            window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
          })
        }
        return;
      }
      if (window['allowcall'] || (navigator.saywhat && navigator.saywhat.IsMobileOS)) {
        SendMessageCall(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio);
      } else {
        setPreSetAllowedDevices(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio);
      }
    },
    end: function () {
      EndSession();
    },
    sendmessage: function (msg) {
      //SendMessage(receiverId,msg);
    },
    startnewchat: function () {
      //Create new chat session
    },
    startConversation: function (SenderId, ReceiverId, TokboxSessionId, Message, AgencyId, LocationId, LoggedInUserName) {

      if (session) {
        var usermodel = parseJwt(localStorage.getItem('frontend-token'));
        var timeStamp = new Date().getTime();
        var messageData = {
          session_id: default_SessionId,
          token_id: "",
          caller_userID: usermodel.user.UserId,
          receiver_userID: ReceiverId,
          caller_Description: "",
          caller_name: LoggedInUserName,
          caller_pic: "",
          time: timeStamp,
          type: "message",
          textMessage: Message,
          status: "",
          conversationType: "chatCall"
        }
        session.signal({
          type: 'message',
          data: JSON.stringify(messageData)
        }, function (error) {
          if (error) {
          } else {
            dataObj = {};
          }
        });
        return true;
      } else {
        IsTokboxAvailable();
        return false;
      }
    },
    checkSession: function () {
      if (session) {
        return true;
      } else {
        return false;
      }
    },
    initializeTokbox: function () {
      setTimeout(function () {
        var getItem = localStorage.getItem('isagntavbl')
        if (!getItem || JSON.parse(getItem).isTrue) {
          IsTokboxAvailable();
        }
      }, 2000);
    },
    logout: function (shouldDeletefirebaseToken) {
      RemoveUnloadEvent();
      LoggedOut(shouldDeletefirebaseToken);
    },
    hideNoCall: function () {
      //Create new chat session
      HideNoCallDiv();
    }
  }
})(myExtObject || {});

/**
 * Save token 
 * @param {*} token 
 */
function stripeTokenHandler(token) {
  var sitetoken = localStorage.getItem('frontend-token').toString();

  var http = new XMLHttpRequest();
  var url = serverURL + "payment/CreateCustomerCard";
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var stripeCustId = localStorage.getItem('StripeCust');
  var params = "UserId=" + usermodel.user.UserId +
    "&StripeCustomerId=" + stripeCustId +
    "&TokenId=" + token.id +
    "&AgencyId=" + usermodel.user.AgencyId;
  http.open("POST", url, true);

  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", sitetoken);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      var response = JSON.parse(http.responseText);
      if (response.Status && response.Data != null) {
        alertify.success('Card added successfully!');
        hideAddCardLoader();
        myExtObject.HideAddCard();
        if (window['angularComponentRef']) {
          window['angularComponentRef'].zone.run(function () {
            window['angularComponentRef'].componentAddNewCardFn(true);
          })
        }
      } else {
        hideAddCardLoader();
        alertify.error(response.Message);
      }
    }
  }
  http.send(params);
}

function showAddCardLoader() {
  $('#AddCardButtons').hide();
  $('#AddCardLoader').show();
}

function hideAddCardLoader() {
  $('#AddCardButtons').show();
  $('#AddCardLoader').hide();
}

function showUpdateCardLoader() {
  $('#UpdateCardButtons').hide();
  $('#UpdateCardLoader').show();
}

function hideUpdateCardLoader() {
  $('#UpdateCardLoader').hide();
  $('#UpdateCardButtons').show();
}

function showSubscriptionCardLoader() {
  $('#SubscriptionCardButtons').hide();
  $('#SubscriptionCardLoader').show();
}

function hideSubscriptionCardLoader() {
  $('#SubscriptionCardButtons').show();
  $('#SubscriptionCardLoader').hide();
}


/**
 * Send Call Message Signal
 * @param {*} paramSessionId 
 * @param {*} newTokenId 
 * @param {*} otherTokenId 
 * @param {*} ToUserId 
 */
function SendMessageCall(paramSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio) {
  closeInitialCallingControlSetting();
  var conversationType = IsAudio ? ConversationType.audioCall : ConversationType.videoCall;
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  if (typeof description == undefined || description == 'undefined' || !description) {
    description = '';
  }

  if (ToUserPic && ToUserPic != "null" && ToUserPic.length > 0) {
    document.getElementById('headerCallerImageOutGoing').src = ToUserPic;
    var callerImg = document.getElementById("callerDivOutoing");
    callerImg.style.display = "block";

    var callerinitial = document.getElementById("callerInitialDivGoing");
    callerinitial.style.display = "none";
  } else {
    var callerImg = document.getElementById("callerDivOutoing");
    callerImg.style.display = "none";

    var callerinitial = document.getElementById("callerInitialDivGoing");
    callerinitial.style.display = "block";
    document.getElementById('CallerInitialOutGoing').innerHTML = ToInitial;

    var v = GetClass();
    document.getElementById('callerInitialDivGoing').className += v;
  }
  document.getElementById('callerDescritpion').innerHTML = description;

  document.getElementById('receiverisbusy').style.display = "none";
  if (!session) {
    alertify.error("Please try again later..Connecting to Tokbox.")
    $('.loader-outer').css('display', 'none');
    IsTokboxAvailable();
    return;
  }

  $('.loader-outer').css('display', 'none');

  calltype = 'outgoing';
  newCallSessionId = paramSessionId;
  newCallTokenId = newTokenId;
  var messageData = '{' +
    '"session_id":"' + paramSessionId + '",' +
    '"token_id":"' + otherTokenId + '",' +
    '"caller_userID":"' + usermodel.user.UserId + '",' +
    '"receiver_userID":"' + ToUserId + '",' +
    '"caller_Description":"' + description + '",' +
    '"caller_name":"' + usermodel.user.Name + '",' +
    '"caller_pic":"' + usermodel.user.Picture + '",' +
    '"type":"call",' +
    '"status":"",' +
    '"conversationType":"' + conversationType +
    '"}';
  lastCallMessageData = messageData;
  session.signal({
    type: 'call',
    data: messageData
    //connectionId: session.connection.connectionId
  }, function (error) {
    if (error) {
      $('.loader-outer').css('display', 'none');
    } else {
      ShowCallDiv(IsAudio);
      //Log call initiation
      LogCallInitiation(newCallSessionId, ToUserId, conversationType);
    }
  });
  //Send message as Push Notification as well
  SendMessageCallPush(paramSessionId, newTokenId, otherTokenId, ToUserId, description, conversationType)
  //Timeout for hiding dialing screen after 30 seconds
  //setTimeout(function() { EndDialing(); }, 30000);
}
/**
 * Send push Notification Signal
 * @param {*} paramSessionId 
 * @param {*} newTokenId 
 * @param {*} otherTokenId 
 * @param {*} ToUserId 
 */
function SendMessageCallPush(paramSessionId, newTokenId, otherTokenId, ToUserId, description, conversationType) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/generatepushforcall";
  var params = "CallerUserId=" + usermodel.user.UserId + "&Sessionid=" + paramSessionId + "&ReceiverUserId="
    + ToUserId + "&CallerName=" + usermodel.user.Name + "&CallerPic=" + usermodel.user.Picture + "&OtherTokenId="
    + otherTokenId + "&CallerFirstName=" + usermodel.user.FirstName +
    "&CallerLastName=" + usermodel.user.LastName + "&ConversationType=" + conversationType + "";
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      //alert(http.responseText);
    }
  }
  http.send(params);
}

/**
 * Call Cancelled Signal
 *  */
function SendMessageCallCancelled() {
  session.signal({
    type: 'call',
    data: lastCallMessageData.replace('"status":""', '"status":"cancelled"')
    //connectionId: session.connection.connectionId
  }, function (error) {
    if (error) {
    } else {
      if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
        window['angularComponentRef'].zone.run(function () {
          window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
        })
      }
    }
  });
}

/**
 * Play Incoming call audio
 */
function PlayCallIncoming() {
  var audioElement = document.getElementById('ringeraudio');
  audioElement.setAttribute('src', 'assets/tokbox/sound/ringer.mp3');
  audioElement.play();
}

/** 
 * Stop Incoming call audio
 */
function StopCallIncoming() {
  if (window['angularComponentRef'] && shownotifications) {
  }
  var audioElement = document.getElementById('ringeraudio');
  audioElement.pause();
}

/**
 * Play Incoming call audio
 */
function PlayTestTuneAudio() {
  var audioElement = document.getElementById('ringeraudioTest');
  audioElement.setAttribute('src', 'assets/tokbox/sound/ringer.mp3');
  audioElement.play();
}

/** 
 * Stop Incoming call audio
 */
function StopTestTuneAudio() {
  var audioElement = document.getElementById('ringeraudioTest');
  audioElement.pause();
}


/** 
 * Play call waiting audio
 */
function PlayCallWaiting() {
  var audioElement = document.getElementById('ringeraudio');
  audioElement.setAttribute('src', 'assets/tokbox/sound/Call_Waiting.mp3');
  audioElement.play();
}

/** 
 * Stop call waiting audio
 */
function StopCallWaiting() {
  var audioElement = document.getElementById('ringeraudio');
  audioElement.pause();
}

/**
 * Show Ringer dialog box
 * @param {*} messageData 
 */
function ShowRinger(dataa) {

  /*****************************notification js *********/
  var NotificationData = [];
  NotificationData.push({
    'title': 'Incoming Call',
    'alertContent': dataa.caller_name + ' is calling...',
    'image': 'assets/images/calling.png'
  });

  // console.log(document.hasFocus());
  Push.create("", {
    tag: dataa.caller_name,
    body: dataa.caller_name + ' is calling you on agentalive',
    icon: 'assets/images/calling.png',
    timeout: 4000000000000,
    onClick: function () {
      document.getElementById('callNotificationAllow').style = 'display:none';
      window.focus();
      this.close();
    }
  });

  /*****************************notification js *********/
  var IsAudio = dataa.conversationType === ConversationType.audioCall ? true : false;

  PlayCallIncoming();
  // check if user has no description comming then show null
  if (typeof dataa.caller_Description == undefined || dataa.caller_Description == 'undefined' || !dataa.caller_Description) {
    dataa.caller_Description = '';
  }
  document.getElementById('ringer').style.display = 'inline';
  document.getElementById('imgCallerName').innerHTML = dataa.caller_name;
  document.getElementById('callerDescritpion').innerHTML = (dataa.caller_Description) ? dataa.caller_Description : '';
  document.getElementById('imgCallerName').setAttribute('id-data', dataa.caller_userID);
  document.getElementById('callTypeTitle').innerText = IsAudio ? 'Incoming Voice Call' : 'Incoming Video Call';
  document.getElementById('iconCallType').innerText = IsAudio ? 'call' : 'videocam';

  if (dataa.caller_pic && dataa.caller_pic.length > 0) {
    document.getElementById('imgCallerImage').src = dataa.caller_pic;
    var callerImg = document.getElementById("imgCallerImageDiv");
    callerImg.style.display = "block";

    var callerinitial = document.getElementById("initialCallerImage");
    callerinitial.style.display = "none";
  } else {
    var callerImg = document.getElementById("initialCallerImage");
    callerImg.style.display = "block";

    var callerinitial = document.getElementById("imgCallerImageDiv");
    callerinitial.style.display = "none";

    document.getElementById('initialCallerImageIntial').innerHTML = GetInitials(dataa.caller_name);
  }

  document.getElementById('ringerpick').onclick = function () {
    StopCallIncoming();
    calltype = 'incoming';
    document.getElementById('ringer').style.display = 'none';
    document.getElementById('headerCallerName').innerHTML = dataa.caller_name;

    if (dataa.caller_pic && dataa.caller_pic.length > 0) {
      document.getElementById('headerCallerImage').src = dataa.caller_pic;
      var callerImg = document.getElementById("callerDiv");
      callerImg.style.display = "block";

      var callerinitial = document.getElementById("callerInitialDiv");
      callerinitial.style.display = "none";
    } else {
      var callerImg = document.getElementById("callerDiv");
      callerImg.style.display = "none";

      var callerinitial = document.getElementById("callerInitialDiv");
      callerinitial.style.display = "block";
      document.getElementById('CallerInitial').innerHTML = GetInitials(dataa.caller_name);

      var v = GetClass();
      document.getElementById('callerInitialDiv').className += v;
    }

    receiverId = dataa.caller_userID;
    callerId = dataa.receiver_userID;
    SendMessageCallApproved(dataa);
    ConnectToIncomingSession(dataa.session_id, dataa.token_id, dataa);
    //Log call connected
    LogCallConnected(dataa.session_id);
    GetChatHistory(dataa.receiver_userID, 0);
  };
  document.getElementById('ringerreject').onclick = function () {
    StopCallIncoming();
    document.getElementById('ringer').style.display = 'none';
    SendMessageCallRejection(dataa);
  };
  window.activeRingerData = dataa;
  //Timeout for hiding ringer div after 27 seconds
  //setTimeout(function() { HideRinger(); }, 27000);
}

/** 
 * Hide ringer dialog box
 */
function HideRinger() {
  StopCallIncoming();
  if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
    window['angularComponentRef'].zone.run(function () {
      window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
    })
  }
  document.getElementById('ringer').style.display = 'none';

}

/**
 * End user session
 */
function EndSession() {
  if (document.getElementById('iconRecord').checked && lastArchiveId) {
    StopRecording();
  }
  CallDisconnected();

  if (tempSession) {
    if (publisher)
      session.unpublish(publisher);
    if (subscriber)
      session.unsubscribe(subscriber);
    tempSession.disconnect();
    if (window.tempSession) {
      window.tempSession.disconnect();
    }
  }
  if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
    window['angularComponentRef'].zone.run(function () {
      window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
    })
  }
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds()
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
  return strTime;
}

/**
 * Connect User to default session
 * @param {*} tokenId 
 */
function ConnectToDefaultSession(tokenId) {
  var accessToken = localStorage.getItem('frontend-token')
  var usermodel = parseJwt(accessToken);
  if (usermodel && usermodel.user) {
    if (OT.checkSystemRequirements() == 1) {
      session = OT.initSession(apiKey, default_SessionId);
      session.on("connectionCreated", function (event) {
        if (event.connection.data) {
          var http = new XMLHttpRequest();
          var url = serverURL + "tokbox/sessionconnected";
          var params = "UserId=" + event.connection.data + "&SessionId=" + session.id +
            "&ConnectionId=" + event.connection.connectionId;
          http.open("POST", url, true);

          //Send the proper header information along with the request
          http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          http.setRequestHeader("Authorization", accessToken);

          http.onreadystatechange = function () { //Call a function when the state changes.
            if (http.readyState == 4 && http.status == 200) {
              var response = JSON.parse(http.responseText);
              if (response.Status) {
                if (window.userComponentRef && session && session.connection) {
                  window.userComponentRef.zone.run(function () {
                    window.userComponentRef.componentFn(event.connection.data, event.connection.connectionId, true);
                    if (window.userComponentRef.componentCallFn)
                      window.userComponentRef.componentCallFn();
                  });
                }

                if (window.userComponentRefChatOnly && session && session.connection) {
                  window.userComponentRefChatOnly.zone.run(function () {
                    window.userComponentRefChatOnly.componentFn(event.connection.data, event.connection.connectionId, true);
                    if (window.userComponentRefChatOnly.componentCallFn)
                      window.userComponentRefChatOnly.componentCallFn();
                  });
                }
              }
            }
          }
          http.send(params);
        }
      });
      session.on("connectionDestroyed", function (event) {
        if (event.connection.data) {
          var accessToken = localStorage.getItem('frontend-token');
          if (accessToken) {
            var http = new XMLHttpRequest();
            var url = serverURL + "tokbox/sessiondisconnected";
            var params = "UserId=" + event.connection.data + "&SessionId=" + session.id + "&ConnectionId=''";
            http.open("POST", url, true);
            //Send the proper header information along with the request
            http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http.setRequestHeader("Authorization", accessToken);

            http.onreadystatechange = function () { //Call a function when the state changes.
              if (http.readyState == 4 && http.status == 200) {
                if (window.userComponentRef && event.connection) {
                  window.userComponentRef.zone.run(function () {
                    window.userComponentRef.componentFn(event.connection.data, event.connection.connectionId, false);
                  });
                }

                if (window.userComponentRefChatOnly && session && session.connection) {
                  window.userComponentRefChatOnly.zone.run(function () {
                    window.userComponentRefChatOnly.componentFn(event.connection.data, event.connection.connectionId, false);
                    if (window.userComponentRefChatOnly.componentCallFn)
                      window.userComponentRefChatOnly.componentCallFn();
                  });
                }
              }
            }
            http.send(params);
          }
        }
      });
    } else {
      // The client does not support WebRTC.
      // You can display your own message.
      if ((res[0] === 'IE' || res[0] === 'Edge') && navigator.saywhat && navigator.saywhat.bversion >= 10) {
        if (getSubDomain() !== 'app') {
          OT.upgradeSystemRequirements();
        }
      } else {
        alert("This browser does not support WebRTC.");
        ShowBrowserNotSupported();
      }
      return;
    }

    session.on("sessionDisconnected", function (event) {
      //Store in db the disconnected datetime
      LogDefaultSessionDisconnected(default_SessionId);
    });
    session.on('signal:message', function (event) {
      var msg = document.createElement('p');
      var usermodel = parseJwt(localStorage.getItem('frontend-token'));
      let msgJSON = (event.data) ? JSON.parse(event.data) : {};
      if (msgJSON && usermodel.user.UserId === parseInt(msgJSON.receiver_userID)) {
        //insert in to smd (msgJsinf.getsmd get )
        LastMessageTimeId = msgJSON.time;
        let ActiveChatUserID = localStorage.getItem('activeChatUserId');
        if (localStorage.getItem('lastmsgtime') != msgJSON.time && parseInt(ActiveChatUserID) === parseInt(msgJSON.caller_userID)) {
          localStorage.setItem('lastmsgtime', msgJSON.time)
          if ($('#agency-user-chat').length > 0) {
            var CurrentTime = new Date();
            var messageHtml = '<li class="clearfix"><div class="received"><p>' + msgJSON.textMessage + '</p><span class="timeLine">' + formatAMPM(CurrentTime) + '</span></div></li>';
            $('#agency-user-chat').append(messageHtml);
            if (window['angularComponentRefUserChat'] && window['angularComponentRefUserChat'].MessageReceived) {
              window['angularComponentRefUserChat'].zone.run(function () {
                window['angularComponentRefUserChat'].MessageReceived(msgJSON);
              });
            }
          }
        } else if (localStorage.getItem('lastmsgtime') != msgJSON.time && msgJSON && usermodel.user.UserId !== parseInt(msgJSON.caller_userID)) {
          if ($('.user-msg-' + msgJSON.caller_userID).length === 0) {
            if (window['angularComponentRefUserList'] && window['angularComponentRefUserList'].refreshAgentList) {
              window['angularComponentRefUserList'].zone.run(function () {
                window['angularComponentRefUserList'].refreshAgentList();
              });
            }
            if (window['angularComponentRefUserChatCount'] && window['angularComponentRefUserChatCount'].RefreshConverstation) {
              window['angularComponentRefUserChatCount'].zone.run(function () {
                window['angularComponentRefUserChatCount'].RefreshConverstation(msgJSON);
              });
            }
          } else {
            if (window['angularComponentRefUserChat'] && window['angularComponentRefUserChat'].RefreshConverstation) {
              window['angularComponentRefUserChat'].zone.run(function () {
                window['angularComponentRefUserChat'].RefreshConverstation(msgJSON);
              });
            }
          }
          $('.user-msg-' + msgJSON.caller_userID).css('display', 'block');
        }
        Push.create("", {
          tag: 'Test',
          body: msgJSON.caller_name + ' is message you on agentalive',
          icon: 'assets/images/newmessage.png',
          timeout: 4000000000000,
          onClick: function () {
            window.focus();
            if (window['angularComponentRef'] && window['angularComponentRef'].redirectMessagetab) {
              window['angularComponentRef'].zone.run(function () {
                window['angularComponentRef'].redirectMessagetab();
              });
            }
            this.close();
          }
        });
      }

    });

    session.on('signal:call', function (event) {
      var usermodel = parseJwt(localStorage.getItem('frontend-token'));
      if (usermodel && event.data && JSON.parse(event.data) && JSON.parse(event.data).receiver_userID) {
        if (JSON.parse(event.data).receiver_userID == usermodel.user.UserId) {
          if (JSON.parse(event.data).status == "") {
            //Handle case if user has queue bag available or not, if not then show ringer else add to bag
            if (document.getElementById('ulWaitingCall').getElementsByTagName('li') && document.getElementById('ulWaitingCall').getElementsByTagName('li').length == 0 && !document.getElementById('ongoingCall').classList.contains('active')) {
              //if ringer is already showing, then send busy signal to other user
              if (document.getElementById('ringer').style.display == 'none') {
                ShowRinger(JSON.parse(event.data));
              } else {
                //Send busy signal
                SendMessageUserIsBusy(JSON.parse(event.data));
              }
            } else {
              PlayCallWaiting();
              if (tokboxcalldata.findIndex(function (x) {
                return x.caller_userID == JSON.parse(event.data).caller_userID;
              }) < 0)
                tokboxcalldata.push(JSON.parse(event.data));

              var existingli = document.getElementById("li" + JSON.parse(event.data).caller_userID);
              if (existingli) {
                existingli.parentNode.removeChild(existingli);
              }
              addCallInQueue(JSON.parse(event.data));
              //Send message to caller that call is in queue
              SendMessageCallInQueue(JSON.parse(event.data));
            }
          } else if (JSON.parse(event.data).status == "declined") //Rejected
          {
            if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
              window['angularComponentRef'].zone.run(function () {
                window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
              })
            }
            if (usermodel.user.RoleId == 2 && document.getElementById('iconRecord').checked) {
              StopRecording();
            }
            document.getElementById('busyText').innerHTML = "User has disconnected your call. Please try again later.";
            document.getElementById('receiverisbusy').style.display = "block";
            setTimeout(function () {
              //Hide the calling screen
              HideCallDiv();
              document.getElementById('receiverisbusy').style.display = "none";
            }, 3000);
            closeNotification(JSON.parse(event.data).caller_name);
          } else if (JSON.parse(event.data).status == "approved") //Approved
          {
            if (connectingTo && connectingTo == JSON.parse(event.data).session_id)
              return;
            //Connect to new session
            ConnectToNewSession(newCallSessionId, newCallTokenId, true, JSON.parse(event.data));
            connectingTo = JSON.parse(event.data).session_id;
            receiverId = JSON.parse(event.data).caller_userID;
            callerId = JSON.parse(event.data).receiver_userID;
            SetUserDetails(JSON.parse(event.data).caller_userID);
            GetChatHistory(JSON.parse(event.data).caller_userID, 0);
            closeNotification(JSON.parse(event.data).caller_name);
          } else if (JSON.parse(event.data).status == "cancelled") //Approved
          {
            //Hide the ringer
            HideRinger();
            //Hide the bag item
            RemoveCancelledQueueCall(JSON.parse(event.data));
            closeNotification(JSON.parse(event.data).caller_name);
            UpdateCallActivityStatus(JSON.parse(event.data).session_id, 0);
          } else if (JSON.parse(event.data).status == "queue") //Placed in queue
          {
            //Show message that user is in queue
            document.getElementById('busyText').innerHTML = "As user is busy, your call is placed in queue.";
            document.getElementById('receiverisbusy').style.display = "block";
            // setTimeout(function () { alert("As user is busy, your call is placed in queue."); }, 3000);

          } else if (JSON.parse(event.data).status == "queuemaincallapproved") //queue call approved as main call
          {
            //Show message that user is in queue
            document.getElementById('receiverisbusy').style.display = "none";
          } else if (JSON.parse(event.data).status == "busy") //User busy
          {
          } else if (JSON.parse(event.data).status == "recording_on") //User busy
          {
            //Show message that user is busy
            document.getElementById('userrecording').style.display = "block";
            document.getElementById('UserNotifyRecording').classList.remove('disable');
          } else if (JSON.parse(event.data).status == "recording_off") //User busy
          {
            //Show message that user is busy
            document.getElementById('userrecording').style.display = "none";
            document.getElementById('UserNotifyRecording').classList.add('disable');
          } else if (JSON.parse(event.data).status == "unqueue") //User busy
          {
            document.getElementById('receiverisbusy').style.display = "none";
          }
        }
      }
    });

    // Connect to the session
    session.connect(tokenId, function (error) {
      // If the connection is successful, initialize a publisher and publish to the session
      if (error) {
        alert('error connecting tokbox. Try reload screen.');
        handleError(error);
      } else {
        //Store in db the new connection details
        LogDefaultSessionConnected(default_SessionId, session.connection.connectionId);
      }
    });
  }
}

function bindClickEventHandler(data) {

  document.getElementById("Reject_" + data.caller_userID).onclick = function () {
    StopCallWaiting();
    RejectQueueCall(data)
  };

  document.getElementById("Accept_" + data.caller_userID).onclick = function () {
    if (window.tempSession && window.tempSession.currentState == 'connected' || window.tempSession.currentState == 'connecting') {
      var r = confirm("Your call is in progress, do you want to disconnect?");
      if (r == true) {
        StopCallWaiting();
        HideRinger();
        AcceptQueueCall(data);
      } else { }
    } else {
      var rengerCallerObj = $('#imgCallerName');
      if (tokboxcalldata.findIndex(function (x) {
        return x.caller_userID == activeRingerData.caller_userID;
      }) < 0) {
        tokboxcalldata.unshift(activeRingerData);
        var index = tokboxcalldata.findIndex(function (x) {
          return x.caller_userID == data.caller_userID;
        });
        tokboxcalldata.splice(index, 1);
        addCallInQueue(activeRingerData);
        SendMessageCallInQueue(activeRingerData);
        StopCallWaiting();
        HideRinger();
        AcceptQueueCall(data);
      }
    }
  };
}


function addCallInQueue(data) {
  var ul = document.getElementById("ulWaitingCall");
  var description = data.caller_Description ? data.caller_Description : ''
  var serializedData = JSON.stringify(data);
  var li = document.createElement("li");
  li.classList.add("waiting_wrapper");
  li.setAttribute("id", "li" + data.caller_userID);
  li.setAttribute("id-data", data.caller_userID);
  var innerHTML = '<div class="user_icon">' +
    '<span class="cyan lighten-5">' +
    '<img id="waitinguserpic" src="' + data.caller_pic + '"/>' +
    '</span>' +
    '</div>' +
    '<div class="call_name">' +
    '<p id="waitingusername">' + data.caller_name + '</p>' +
    '<span>' + description + '</span>' +
    '</div>' +
    '<div class="waiting_controls">' +
    '<a class="waves-effect waves-light raddius25 btn green pick" id="Accept_' + data.caller_userID + '">Accept</a>' +
    '<a class="waves-effect waves-light raddius25 btn red darken0 reject" id="Reject_' + data.caller_userID + '" >Reject</a>' +
    '</div>'

  li.innerHTML = innerHTML
  ul.appendChild(li);


  var length = document.getElementById("ulWaitingCall").getElementsByTagName("li").length;
  if (length && length != '' && length != 0) {
    document.getElementById("spanCallingNumbers").innerHTML = length;
    document.getElementById("spanWaitingCall").style.display = 'block';
  } else {
    //Hide the span
    document.getElementById("spanWaitingCall").style.display = 'none';
  }
  bindClickEventHandler(data)
}

/**
 * Connect user to new session
 * @param {*} newSessionId 
 * @param {*} newToken 
 * @param {*} IsOutgoing 
 */
function ConnectToNewSession(newSessionId, newToken, IsOutgoing, sessionData) {
  var IsAudio = sessionData.conversationType === ConversationType.audioCall ? true : false
  // GetDevices(); //Getting new attached devices
  HideMessageSection();
  HideSettingsSection();
  ResetControls();
  if (OT.checkSystemRequirements() == 1) { } else {
    alert("This browser does not support WebRTC.");
    return;
  }

  if (tempSession) {
    tempSession.disconnect();
  }
  // if (window.tempSession) {
  //   window.tempSession.disconnect();
  // }
  sessionId = newSessionId;
  if (!sessionId) {
    return;
  }
  tempSession = OT.initSession(apiKey, sessionId);
  tempSession.on('streamCreated', function (event) {
    audioVideoCallDivSettings(!event.stream.hasVideo, sessionData, true);
    subscriber = tempSession.subscribe(event.stream, 'subscriber', {
      insertMode: 'append', //insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false
    }, handleError);

    if (document.getElementById('iconSpeakerOnOff').innerHTML == 'volume_up') {
      subscriber.subscribeToAudio(true); // audio on
    } else {
      subscriber.subscribeToAudio(false); // audio off
    }
    subscriber.on("videoDisabled", function (event) {
      audioVideoCallDivSettings(true, sessionData, true);
      // You may want to hide the subscriber video element:
    });

    subscriber.on("videoEnabled", function (event) {
      // You may want to display the subscriber video element,
      // if it was hidden:
      audioVideoCallDivSettings(false, sessionData, true);
      // You may want to add or adjust other UI.
    });

  });

  tempSession.on('streamDestroyed', function (event) {
    audioVideoCallDivSettings(!event.stream.hasVideo, sessionData, true);
  });
  tempSession.on("sessionDisconnected", function (event) {
    LogSessionDisconnected(sessionId);
  });

  tempSession.on("connectionDestroyed", function (event) {
    EndSession();
    if (localStorage.getItem('frontend-token')) {
      //Store in db the session disconnection flag
      LogSessionDisconnected(sessionId);
    }
  });

  tempSession.on("connectionCreated", function (event) {
    OutgoingCallConnectionCreated();
  });

  tempSession.on('signal:chat', function (event) {
    if (event.data && JSON.parse(event.data) && JSON.parse(event.data).receiver_userID) {
      var li = document.createElement('li');
      li.className = "clearfix";
      li.innerHTML = "<div class='" + (event.from.connectionId === tempSession.connection.connectionId ? 'sent' : 'received') + "'><p>" + JSON.parse(event.data).message + "<small class='time'>just now</small></p></div>";
      document.getElementById('ulchat_container').appendChild(li);
      li.scrollIntoView();
      $('#NoMessageDiv').hide();
      NewMessageReceived(JSON.parse(event.data));
    }
  });

  var pubOptions = {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    showControls: false,
    usePreviousDeviceSelection: true
  };


  // Create a publisher
  publisher = OT.initPublisher('publisher', pubOptions, handleError);
  if (IsAudio) {
    ShowCallDivForAudioCall();
    publisher.publishVideo(false);
  } else {
    publisher.publishVideo(true);
  }

  // Connect to the session
  tempSession.connect(newToken, function (error) {
    // If the connection is successful, initialize a publisher and publish to the session
    if (error) {
      handleError(error);
    } else {
      ShowCallDiv(IsAudio);
      if (calltype == 'incoming') {
        IncomingCallConnectionCreated();
      }
      tempSession.publish(publisher, handleError);

      //Store in db the new connection details
      LogSessionConnected(sessionId, tempSession.connection.connectionId)
    }
  });
}

/**
 * Connect user to incoming session
 * @param {*} newSessionId 
 * @param {*} newToken 
 */
function ConnectToIncomingSession(newSessionId, newToken, sessionData) {
  var IsAudio = sessionData.conversationType === ConversationType.audioCall ? true : false;
  HideMessageSection();
  HideSettingsSection();
  ResetControls();
  if (OT.checkSystemRequirements() == 1) { } else {
    alert("This browser does not support WebRTC.");
    return;
  }

  // if (tempSession) {
  //   tempSession.disconnect();
  // }
  // if (window.tempSession) {
  //   window.tempSession.disconnect();
  // }
  sessionId = newSessionId;
  if (!sessionId) {
    return;
  }
  tempSession = OT.initSession(apiKey, sessionId);

  tempSession.on('streamCreated', function (event) {
    audioVideoCallDivSettings(!event.stream.hasVideo, sessionData, false);
    subscriber = tempSession.subscribe(event.stream, 'subscriber', {
      insertMode: 'append', //insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false
    }, handleError);
    if (document.getElementById('iconSpeakerOnOff').innerHTML == 'volume_up') {
      subscriber.subscribeToAudio(true); // audio on
    } else {
      subscriber.subscribeToAudio(false); // audio off
    }

    subscriber.on("videoDisabled", function (event) {
      audioVideoCallDivSettings(true, sessionData, false);
    });

    subscriber.on("videoEnabled", function (event) {
      // You may want to display the subscriber video element,
      // if it was hidden:
      audioVideoCallDivSettings(false, sessionData, false);
      // You may want to add or adjust other UI.
    });

  });

  tempSession.on('streamDestroyed', function (event) {
    audioVideoCallDivSettings(event.stream.hasVideo, sessionData, false);
  });


  tempSession.on("connectionDestroyed", function (event) {
    EndSession();
    if (localStorage.getItem('frontend-token')) {

      //Store in db the session disconnection flag
      LogSessionDisconnected(sessionId);
    }
  });

  tempSession.on("connectionCreated", function (event) {

  });

  tempSession.on("sessionDisconnected", function (event) {
    LogSessionDisconnected(sessionId);
  });

  tempSession.on('signal:chat', function (event) {
    if (event.data && JSON.parse(event.data) && JSON.parse(event.data).receiver_userID) {
      var li = document.createElement('li');
      li.className = "clearfix";
      li.innerHTML = "<div class='" + (event.from.connectionId === tempSession.connection.connectionId ? 'sent' : 'received') + "'><p>" + JSON.parse(event.data).message + "<small class='time'>just now</small></p></div>";
      document.getElementById('ulchat_container').appendChild(li);
      li.scrollIntoView();
      $('#NoMessageDiv').hide();
      NewMessageReceived(JSON.parse(event.data));
    }
  });

  var pubOptions = {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    showControls: false,
    usePreviousDeviceSelection: true
  };

  // Create a publisher
  publisher = OT.initPublisher('publisher', pubOptions, handleError);

  if (IsAudio) {
    ShowCallDivForAudioCall();
    publisher.publishVideo(false);
  } else {
    publisher.publishVideo(true);
  }
  // Connect to the session
  tempSession.connect(newToken, function (error) {
    // If the connection is successful, initialize a publisher and publish to the session
    if (error) {
      handleError(error);
    } else {
      ShowCallDiv(IsAudio);
      IncomingCallConnectionCreated();
      tempSession.publish(publisher, handleError);

      //Store in db the new connection details
      LogSessionConnected(sessionId, tempSession.connection.connectionId)
    }
  });
  window.tempSession = tempSession;
  document.getElementById('message-send').onclick = function () {
    var usermodel = parseJwt(localStorage.getItem('frontend-token'));
    var msg = document.getElementById('chatArea').value;
    msg = msg.trim();
    msg = msg.replace(/\n\r?/g, '<br />');
    if (msg) {
      document.getElementById('chatArea').value = '';
      var SessionLocaStrorage = localStorage.getItem('tokSessionId');
      var messageData = '{' +
        '"session_id" : "' + sessionId + '",' +
        '"token_id"  : "",' +
        '"caller_userID"  : "' + usermodel.user.UserId + '",' +
        '"receiver_userID"  : "' + receiverId + '",' +
        '"caller_name"  : "' + usermodel.user.Name + '",' +
        '"caller_pic"  : "' + usermodel.user.Picture + '",' +
        '"type"  : "chat",' +
        '"status" : "",' +
        '"message" : "' + msg + '"' +
        '}';
      chatSignal(tempSession, messageData);
    }
  }

  document.getElementById('iconVideoOnOff').onclick = function () {
    if (document.getElementById('iconVideoOnOff').innerHTML == 'videocam') {
      document.getElementById('iconVideoOnOff').classList.add('inactive');
      document.getElementById('iconVideoOnOff').innerHTML = 'videocam_off';
      publisher.publishVideo(false);
    } else {
      document.getElementById('iconVideoOnOff').innerHTML = 'videocam';
      document.getElementById('iconVideoOnOff').classList.remove('inactive');
      publisher.publishVideo(true);
    }
    document.getElementById('iconScreenShareOnOff').style.display = "block"
    document.getElementById('iconFullscreenOnOff').style.display = "block";
  };
  document.getElementById('iconMicOnOff').onclick = function () {
    if (document.getElementById('iconMicOnOff').innerHTML == 'mic') {
      document.getElementById('iconMicOnOff').classList.add('inactive');
      document.getElementById('iconMicOnOff').innerHTML = 'mic_off';
      publisher.publishAudio(false);
    } else {
      document.getElementById('iconMicOnOff').classList.remove('inactive');
      document.getElementById('iconMicOnOff').innerHTML = 'mic';
      publisher.publishAudio(true);
    }
  };
  document.getElementById('iconScreenShareOnOff').onclick = function () {
    if (IsAudio) {
      // Show popup message screen share can't be done duering audio call
      if (angularComponentRef && angularComponentRef.toast) {
        angularComponentRef.toast(`You can't share screen on audio call.`, 'red', 5000);
      }
      return;
    }
    if (!navigator.saywhat || !navigator.saywhat.ScreenShare) {
      if (navigator.saywhat.IsMobileOS) {
        showAppInstallPopup();
      } else {
        // show unsupported browser
        $('#browser_requirements_popup').addClass('open');
        $('#browser_popup_overlay').addClass('open_browser_bg');
      }
      return;
    }


    if (document.getElementById('iconScreenShareOnOff').innerHTML == 'stop_screen_share') {
      document.getElementById('iconScreenShareOnOff').classList.remove('inactive');
      document.getElementById('iconVideoOnOff').setAttribute('style', '');
      document.getElementById('iconScreenShareOnOff').innerHTML = 'screen_share';
      tempSession.unpublish(publisher);
      //Create a publisher
      var publishingVideo = wasPublishingVideo();
      publisher = OT.initPublisher('publisher', {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        publishAudio: document.getElementById('iconMicOnOff').innerHTML === "mic" ? true : false,
        publishVideo: publishingVideo,
        showControls: false,
        usePreviousDeviceSelection: true
      }, handleError);

      tempSession.publish(publisher, handleError);
      if (wasPublishingVideo()) {
        publisher.publishVideo(true);
      } else {
        publisher.publishVideo(false);
      }
    } else {
      screenshare();
    }
  }
}

/** 
 * Show call division
 */
function ShowCallDiv(IsAudio) {
  document.getElementById('vStreaming').classList.remove('hide');
  document.getElementById('ongoingCall').classList.add('active');
  document.getElementById('ongoing_videobg').classList.remove('hide');

  document.getElementById('iconMicOnOff').classList.remove('inactive');
  document.getElementById('iconSpeakerOnOff').classList.remove('inactive');
  document.getElementById('iconVideoOnOff').classList.remove('inactive');
  document.getElementById('iconScreenShareOnOff').classList.remove('inactive');
  ShowCallDivForAudioCall(IsAudio);
  AttachUnloadEvent();
}

function ShowCallDivForAudioCall(IsAudio) {
  if (IsAudio) {
    document.getElementById('iconVideoOnOff').classList.add('inactive');
    document.getElementById('iconVideoOnOff').innerHTML = 'videocam_off';
    document.getElementById('iconScreenShareOnOff').style.display = "none";
    document.getElementById('iconFullscreenOnOff').style.display = "none";
  }
}

/**
 * Hide call division
 */
function HideCallDiv() {
  if ($('#ulWaitingCall').children('li').length > 0) {
    StopCallWaiting();
    var tokbocnextcallData = tokboxcalldata && tokboxcalldata.length ? tokboxcalldata.shift() : null;
    if (tokbocnextcallData) {
      const data = tokbocnextcallData
      ClearQueueBagData(tokbocnextcallData);
      ShowRinger(tokbocnextcallData);
      var length = document.getElementById("ulWaitingCall").getElementsByTagName("li").length;
      if (length && length != '' && length != 0) {
        document.getElementById("spanCallingNumbers").innerHTML = length;
        document.getElementById("spanWaitingCall").style.display = 'block';
      }
      SendMessageCallUnQueue(data);
    }
  }

  document.getElementById('ulchat_container').innerHTML = '';
  document.getElementById('ongoingCall').classList.remove('active');
  document.getElementById('ongoingCall').classList.remove('full-width-call');
  document.getElementById('ongoing_videobg').classList.add('hide');
  document.getElementById('disconnected_screen').style.display = 'none';
  RemoveUnloadEvent();
}

function minimizeCall() {
  document.getElementById('ongoing_videobg').classList.add('hide');
  document.getElementById('ongoingCall').classList.remove('active');
  document.getElementById('ongoingCall').classList.add('call_minimized');

  if (window.innerWidth > 1366) {
    document.getElementById('ongoingCall').classList.add('active');
    document.getElementById('ongoingCall').classList.remove('call_minimized');
  } else {
    document.getElementById('ongoingCall').classList.remove('active');
    document.getElementById('ongoingCall').classList.add('call_minimized');
  }

}

function openCallPanel() {
  document.getElementById('ongoingCall').classList.add('active');
  document.getElementById('ongoingCall').classList.remove('call_minimized');
  document.getElementById('ongoing_videobg').classList.remove('hide');
}

// Drag calling circle



/**
 * Log in database when user is connected to default session
 * @param {*} paramSessionId 
 * @param {*} connectionId 
 */
function LogDefaultSessionConnected(paramSessionId, connectionId) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  if (session && session.connection) { } else {
    return;
  }
  if (usermodel && usermodel.user) {
    var http = new XMLHttpRequest();
    var url = serverURL + "tokbox/sessionconnected";
    var params = "UserId=" + usermodel.user.UserId + "&SessionId=" + paramSessionId + "&ConnectionId=" + session.connection.connectionId;
    http.open("POST", url, true);

    var token = localStorage.getItem('frontend-token').toString();
    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    http.setRequestHeader("Authorization", token);

    http.onreadystatechange = function () { //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
      }
    }
    http.send(params);
  }
}

/**
 * Log in database when user is connected to session
 * @param {*} sessionId 
 * @param {*} connectionId 
 */
function LogSessionConnected(sessionId, connectionId) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  return;
  if (tempSession && tempSession.connection) { } else {
    return;
  }

  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/sessionconnected";
  var params = "UserId=" + usermodel.user.UserId + "&SessionId=" + sessionId + "&ConnectionId=" + tempSession.connection.connectionId;
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      //alert(http.responseText);
    }
  }
  http.send(params);
}

/**
 * Log in database when session is disconnected
 * @param {*} sesionId 
 */
function LogSessionDisconnected(sesionId) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  if (localStorage.getItem('frontend-token')) {
    var http = new XMLHttpRequest();
    var url = serverURL + "tokbox/callended";
    var laid = localStorage.getItem('laid');
    laid = laid ? laid : 0;
    localStorage.removeItem('laid');
    var params = "ResponderId=" + usermodel.user.UserId + "&SessionId="
      + sesionId + "&ActivityId=" + laid;
    http.open("POST", url, true);

    var token = localStorage.getItem('frontend-token').toString();
    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    http.setRequestHeader("Authorization", token);

    http.onreadystatechange = function () { //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
      }
    }
    http.send(params);
  }
}

/**
 * Log in database when deafult session is disconnected
 * @param {*} sesionId 
 */
function LogDefaultSessionDisconnected(sesionId) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  if (localStorage.getItem('frontend-token')) {
    var http = new XMLHttpRequest();
    var url = serverURL + "tokbox/sessiondisconnected";
    var params = "UserId=" + usermodel.user.UserId + "&SessionId=" + sesionId + "&ConnectionId=";
    http.open("POST", url, true); // Change false to true as loutput functionality was working slow due to wait of this response
    var token = localStorage.getItem('frontend-token').toString();
    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    http.setRequestHeader("Authorization", token);
    http.send(params);
  }
}

/**
 * Log Call records in database on call initiation
 * @param {*} val 
 * @param {*} ToUserId 
 */
function LogCallInitiation(val, ToUserId, conversationType) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/callinitiated";
  var params;
  if (val && ToUserId) {
    params = "CallerId=" + usermodel.user.UserId + "&SessionId=" + val + "&ResponderId=" + ToUserId +
      "&AgencyId=" + usermodel.user.AgencyId + "&LocationId=" + usermodel.user.LocationId +
      "&CallerTokenId=" + "0" + "&ConversationType=" + conversationType;
  } else {
    params = "CallerId=" + usermodel.user.UserId + "&SessionId=" + sessionId + "&ResponderId=" + receiverId +
      "&AgencyId=" + usermodel.user.AgencyId + "&LocationId=" + usermodel.user.LocationId +
      "&CallerTokenId=" + "0" + "&ConversationType=" + conversationType;
  }

  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      laidId = http.responseText ? JSON.parse(http.responseText).Data.Id : '';
      localStorage.setItem('laid', laidId);
    }
  }
  http.send(params);
}

/**
 * Log when user connects the call
 * @param {*} sesionId 
 */
function LogCallConnected(sesionId) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/callconnected";
  var params = "ResponderId=" + usermodel.user.UserId + "&SessionId=" + sesionId;
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
    }
  }
  http.send(params);
}

/**
 * Get Token for default session
 * @param {*} paramsesionId 
 */
function GetTokenForSession(paramsesionId) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  if (localStorage.getItem('frontend-token') && usermodel.user.UserId) {
    var http = new XMLHttpRequest();
    var url = serverURL + "tokbox/createtoken";
    var params = "sessionId=" + paramsesionId + "&userId=" + usermodel.user.UserId;
    http.open("POST", url, true);
    var token = localStorage.getItem('frontend-token').toString();
    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    http.setRequestHeader("Authorization", token);

    http.onreadystatechange = function () { //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        if (http.responseText) {
          var tkn = JSON.parse(http.responseText).Data.tokenId;
          if (tkn) {
            ConnectToDefaultSession(tkn);
          }
        }
      }
    }
    http.send(params);
  }
}

/**
 * Video Show Hide during call
 */
function VideoOnOff() {
  if (document.getElementById('iconVideoOnOff').innerHTML == 'videocam') {
    document.getElementById('iconVideoOnOff').classList.add('inactive');
    document.getElementById('iconVideoOnOff').innerHTML = 'videocam_off';
    publisher.publishVideo(false);
  } else {
    document.getElementById('iconVideoOnOff').innerHTML = 'videocam';
    document.getElementById('iconVideoOnOff').classList.remove('inactive');
    publisher.publishVideo(true);
  }
  document.getElementById('iconScreenShareOnOff').style.display = "block"
  document.getElementById('iconFullscreenOnOff').style.display = "block";
}

/**
 * Audio Show Hide during call
 */
function AudioOnOff() {
  if (document.getElementById('iconAudioOnOff').innerHTML == 'videocam_on') {
    document.getElementById('iconAudioOnOff').classList.add('inactive');
    document.getElementById('iconAudioOnOff').innerHTML = 'videocam_off';
    publisher.publishAudio(false);
  } else {
    document.getElementById('iconAudioOnOff').classList.remove('inactive');
    document.getElementById('iconAudioOnOff').innerHTML = 'videocam_on';
    publisher.publishAudio(true);
  }
}

/**
 * Speaker on/off during call
 */
function SpeakerOnOff() {
  if (document.getElementById('iconSpeakerOnOff').innerHTML == 'volume_up') {
    document.getElementById('iconSpeakerOnOff').classList.add('inactive');
    document.getElementById('iconSpeakerOnOff').innerHTML = 'volume_off';
    subscriber.setAudioVolume(0);
  } else {
    document.getElementById('iconSpeakerOnOff').classList.remove('inactive');
    document.getElementById('iconSpeakerOnOff').innerHTML = 'volume_up';
    subscriber.setAudioVolume(100);
  }
}

/** 
 * Micon/off during call
 */
function MicOnOff() {
  if (document.getElementById('iconMicOnOff').innerHTML == 'mic') {
    document.getElementById('iconMicOnOff').classList.add('inactive');
    document.getElementById('iconMicOnOff').innerHTML = 'mic_off';
    publisher.publishAudio(false);
  } else {
    document.getElementById('iconMicOnOff').classList.remove('inactive');
    document.getElementById('iconMicOnOff').innerHTML = 'mic';
    publisher.publishAudio(true);
  }
}

/**
 * Screen share on/off during call
 */
function ScreenShareOnOff(IsAudio) {
  if (IsAudio) {
    // Show popup message screen share can't be done duering audio call
    console.log('first condition-->');
    if (angularComponentRef && angularComponentRef.toast) {
      angularComponentRef.toast(`You can't share screen on audio call.`, 'red', 5000);
    }
    return;
  }
  if (!navigator.saywhat || !navigator.saywhat.ScreenShare) {
    if (navigator.saywhat.IsMobileOS) {
      showAppInstallPopup();
    } else {
      // show unsupported browser
      $('#browser_requirements_popup').addClass('open');
      $('#browser_popup_overlay').addClass('open_browser_bg');
    }
    return;
  }


  if (document.getElementById('iconScreenShareOnOff').innerHTML == 'stop_screen_share') {
    document.getElementById('iconScreenShareOnOff').classList.remove('inactive');
    document.getElementById('iconVideoOnOff').setAttribute('style', '');
    document.getElementById('iconScreenShareOnOff').innerHTML = 'screen_share';
    tempSession.unpublish(publisher);
    //Create a publisher

    publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      publishAudio: document.getElementById('iconMicOnOff').innerHTML === "mic" ? true : false,
      publishAudio: wasPublishingVideo(),
      showControls: false,
      usePreviousDeviceSelection: true
    }, handleError);

    tempSession.publish(publisher, handleError);
    console.log('test it--->', document.getElementById('iconVideoOnOff').innerHTML);
    if (document.getElementById('iconVideoOnOff').innerHTML == 'videocam_off') {
      publisher.publishVideo(false);
    } else {
      publisher.publishVideo(true);
    }

  } else {
    screenshare();
  }
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function onResizeForScreenShare() {

  if (window.innerWidth < 992) {
    showAppInstallPopup();
  } else {
    screenshare();
  }
}


function showAppInstallPopup() {
  var operating_system = getMobileOperatingSystem();
  document.getElementById('install_mobile_app').style.display = 'block';
  if (operating_system == 'Android') {
    document.getElementById('download_ios_app').style.display = 'none';
    document.getElementById('download_android_app').style.display = 'block';
  } else if (operating_system == 'iOS') {
    document.getElementById('download_ios_app').style.display = 'block';
    document.getElementById('download_android_app').style.display = 'none';
  }
}

/**
 * Install chrome extension before screen share
 */
function ExtInstall() {
  CloseExtentionPopup()
  // document.getElementById("btninstallext").innerHTML = 'Installing<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>';
  // var downloadextnlink = 'https://chrome.google.com/webstore/detail/' + screenshareextensioncode;
  // chrome.webstore.install(downloadextnlink,
  //   successCallback, failureCallback);
  document.getElementById('InstallExtnBtn').setAttribute('href', downloadextnlink)
}

function successCallback() {
  CloseExtentionPopup();
}


function failureCallback(error, errorCode) {
  document.getElementById("btninstallext").innerHTML = "Add to Chrome";
  document.getElementById('install-button').style.display = 'block';
  if (errorCode == "notPermitted")
    alert(error + " Please install extention manually by clicking on this link. https://chrome.google.com/webstore/detail/lowrate-screen-sharing/" + screenshareextensioncode);
  else if (errorCode == "userCancelled")
    CloseExtentionPopup();
  else
    alert(error);
}

/** 
 * Message division show/hide
 */
function MessageOnOff() {
  if (document.getElementById('iconMessageOnOff').innerHTML == 'speaker_notes') {
    HideSettingsSection();
    document.getElementById('iconMessageOnOff').innerHTML = 'speaker_notes_off';
    document.getElementById('videos').classList.add('halfvideo');
    document.getElementById('divChat').classList.remove('hide');
    document.getElementById('divChatTextbox').classList.remove('hide');
    ClearNewMessageIcon();
  } else {
    HideMessageSection();
  }
  var ele = document.getElementsByClassName('chat_container')[0].lastElementChild;
  if (ele)
    ele.scrollIntoView();
}

/**
 * Full screen feature during call
 */
function FullscreenOnOff() {
  if (document.getElementById('iconFullscreenOnOff').innerHTML == 'fullscreen') {
    document.getElementById('ongoingCall').classList.add('full-width-call');
    document.getElementById('iconFullscreenOnOff').innerHTML = 'fullscreen_exit';

  } else {
    document.getElementById('iconFullscreenOnOff').innerHTML = 'fullscreen';
    document.getElementById('ongoingCall').classList.remove('full-width-call');
  }
}

/** 
 * Clear message history division
 */
function ClearMessageSectionText() {
  document.getElementById('ulchat_container').innerHTML = '';
}

/** 
 * Hide Message section
 */
function HideMessageSection() {
  document.getElementById('iconMessageOnOff').innerHTML = 'speaker_notes';
  document.getElementById('videos').classList.remove('halfvideo');
  document.getElementById('divChat').classList.add('hide');
  document.getElementById('divChatTextbox').classList.add('hide');
}

/**
 * Reset Controls
 */
function ResetControls() {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  document.getElementById('iconVideoOnOff').innerHTML = 'videocam';
  document.getElementById('iconMicOnOff').innerHTML = 'mic';
  document.getElementById('iconScreenShareOnOff').innerHTML = 'screen_share';
  document.getElementById('iconMessageOnOff').innerHTML = 'speaker_notes';
  document.getElementById('iconSpeakerOnOff').innerHTML = 'volume_up';
  document.getElementById('iconSettingsOnOff').innerHTML = 'settings';
  document.getElementById('iconFullscreenOnOff').innerHTML = 'fullscreen';
  document.getElementById('iconFullscreenOnOff').style.display = "block";
  document.getElementById('iconScreenShareOnOff').style.display = 'block';
  document.getElementById('receiverisbusy').style.display = "none";

  document.getElementById('agentrecording').style.display = 'none';
  document.getElementById('spn_timer').style.display = 'none';
  document.getElementById('iconRecord').checked = false;
  ClearNewMessageIcon();

  //Recording functionality according to role
  if (usermodel.user.RoleId == 2 || usermodel.user.RoleId == 6)
    document.getElementById('agentrecording').style.display = 'block';
  else if (usermodel.user.RoleId == 3)
    document.getElementById('userrecording').style.display = 'none';
}

/** 
 * Send message to user during call
 */
function SendMessage() {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var msg = document.getElementById('chatArea').value;
  msg = msg.trim();
  msg = msg.replace(/\n\r?/g, '<br />');
  if (msg) {
    document.getElementById('chatArea').value = '';
    var SessionLocaStrorage = localStorage.getItem('tokSessionId');
    var messageData = '{' +
      '"session_id" : "' + sessionId + '",' +
      '"token_id"  : "",' +
      '"caller_userID"  : "' + usermodel.user.UserId + '",' +
      '"receiver_userID"  : "' + receiverId + '",' +
      '"caller_name"  : "' + usermodel.user.Name + '",' +
      '"caller_pic"  : "' + usermodel.user.Picture + '",' +
      '"type"  : "chat",' +
      '"status" : "",' +
      '"message" : "' + msg + '"' +
      '}';
    chatSignal(tempSession, messageData);
    LogChat(receiverId, msg);
  }
}

function chatSignal(tempSession, messageData) {
  if (messageData) {
    tempSession.signal({
      type: 'chat',
      data: messageData
    }, function (error) {
      if (error) {
      } else { }
    });
  }
}

/** 
 * Get tokbox token if not available
 */
function IsTokboxAvailable() {
  if (session) { } else {
    //Connect to default session when user login
    GetTokenForSession(default_SessionId);
  }
}

/**
 * Save chat into database to mantain history 
 * @param {*} paramUserId 
 * @param {*} paramText 
 */
function LogChat(paramUserId, paramText) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/SaveMessage";
  var params = "SenderId=" + usermodel.user.UserId + "&ReceiverId=" + paramUserId + "&TokboxSessionId=" + sessionId + "&Message=" + paramText + "&AgencyId=" + usermodel.user.AgencyId + "&LocationId=" + usermodel.user.LocationId;
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
    }
  }
  http.send(params);
}

function HideNoCallDiv() {
}

function ShowNoCallDiv() {
}

/** 
 * Call disconnected by user
 */
function EndDialing() {
  HideCallDiv();
  SendMessageCallCancelled();
  UpdateCallActivityStatus(sessionId, 0)
}

/**
 * Send call rejection signal
 * @param {*} msgData 
 */
function SendMessageCallRejection(msgData) {
  msgData.status = "declined";
  //Revert the UserIds
  var callerId = msgData.caller_userID;
  msgData.caller_userID = msgData.receiver_userID;
  msgData.receiver_userID = callerId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData) //msgData.toJSON
  }, function (error) {
    if (error) {
    }
  });
}

/**
 * Send call approved signal
 * @param {*} msgData 
 */
function SendMessageCallApproved(msgData) {
  msgData.status = "approved";
  //Revert the UserIds
  var callerId = msgData.caller_userID;
  msgData.caller_userID = msgData.receiver_userID;
  msgData.receiver_userID = callerId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    }
  });
}

/**
 * Send main call approved after queue signal
 * @param {*} msgData 
 */
function SendQueueMainCallApproved(msgData) {
  msgData.status = "queuemaincallapproved";
  //Revert the UserIds
  var callerId = msgData.caller_userID;
  msgData.caller_userID = msgData.receiver_userID;
  msgData.receiver_userID = callerId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    }
  });
}

/**
 * Send signal when outgoing call is connected
 */
function OutgoingCallConnectionCreated() {

  document.getElementById('connected_screen').style.display = 'block';
  document.getElementsByClassName("video_Streaming")[0].className += " hide";

}

/** 
 * Send signal when incoming call connection is created
 */
function IncomingCallConnectionCreated() {

  document.getElementById('connected_screen').style.display = 'block';
  document.getElementsByClassName("video_Streaming")[0].className += " hide";
  document.getElementById('ongoingCall').classList.add('active');
}

/** 
 * Send signal when outgoing call is disconnected
 */
function OutgoingCallDisconnected() {

  document.getElementById('disconnected_screen').style.display = 'block';
  document.getElementById('connected_screen').style.display = 'none';
  setTimeout(function () {
    HideCallDiv();
  }, 3000);
}

/** Send signal when incoming call is disconnected*/
function IncomingCallDisconnected() {

  document.getElementById('disconnected_screen').style.display = 'block';
  document.getElementById('connected_screen').style.display = 'none';
  setTimeout(function () {
    HideCallDiv();
  }, 3000);
}


function CallDisconnected() {

  document.getElementById('disconnected_screen').style.display = 'block';
  document.getElementById('connected_screen').style.display = 'none';

  setTimeout(function () {
    HideCallDiv();
  }, 3000);
}

/**
 * Get chat history when new call is connected
 * @param {*} ReceiverId 
 * @param {*} Offset 
 */
function GetChatHistory(ReceiverId, Offset) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/GetMessage";
  var params = "SenderId=" + usermodel.user.UserId + "&ReceiverId=" + ReceiverId + "&Offset=" + Offset;
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      var response = JSON.parse(http.responseText);
      if (response.Status && response.Data != null) {
        response.Data.reverse();
        $.each(response.Data, function (index, item) {
          var dateTime = new Date(item.MessageDateTime).toLocaleString(); // had to remove the colon (:) after the T in order to make it work
          var date = dateTime.split(',');
          item.MessageDate = date[0];
          item.MessageTime = date[1];
          var li = document.createElement('li');
          li.className = "clearfix";
          if (index == 0) {
            li.innerHTML = "<div class='dated_record'><span>" + item.MessageDate + "</span></div><div class='" + (item.SenderId === usermodel.user.UserId ? 'sent' : 'received') + "'><p>" + item.Message + "<small class='time'>" + item.MessageTime + "</small></p></div>";
          } else {
            var lastRecordUTCDate = new Date(response.Data[index - 1].MessageDateTime).toLocaleString();
            var splittedDateTime = lastRecordUTCDate.split(',');
            var lastRecordDate = splittedDateTime[0];
            if (item.MessageDate == lastRecordDate) {
              li.innerHTML = "<div class='" + (item.SenderId === usermodel.user.UserId ? 'sent' : 'received') + "'><p>" + item.Message + "<small class='time'>" + item.MessageTime + "</small></p></div>";
            } else {
              li.innerHTML = "<div class='dated_record'><span>" + item.MessageDate + "</span></div><div class='" + (item.SenderId === usermodel.user.UserId ? 'sent' : 'received') + "'><p>" + item.Message + "<small class='time'>" + item.MessageTime + "</small></p></div>";
            }
          }
          document.getElementById('ulchat_container').appendChild(li);
          // li.scrollIntoView();
        });
      } else {
        $('#NoMessageDiv').show();
      }
    }
  }
  http.send(params);
}

/**
 * Get User's current session id
 * @param {*} userId 
 */
function GetUserCurrentSessionId(userId) {
  var returnValue = false;
  if (default_SessionId == sessionId) {
    returnValue = true;
  }
  return returnValue;
}

/**
 * Remove call from waiting call bag
 * @param {*} msg 
 */
function RemoveCancelledQueueCall(msg) {
  //Remove li
  var elem = document.getElementById('li' + msg.caller_userID);
  if (elem) {
    elem.parentNode.removeChild(elem);
    var length = document.getElementById("ulWaitingCall").getElementsByTagName("li").length;
    if (length && length != '' && length != 0) {
      document.getElementById("spanCallingNumbers").innerHTML = length;
    } else {
      //Hide the span
      document.getElementById("spanWaitingCall").style.display = 'none';
    }
  }
}

/**
 * Reject waiting call
 * @param {*} msg 
 */
function RejectQueueCall(msg) {
  //Remove li
  var elem = document.getElementById('li' + msg.caller_userID);
  if (elem) {
    var collerId = $(elem).attr('id-data');
    var collerObjIndex = tokboxcalldata.findIndex(function (x) {
      return x.caller_userID == collerId;
    });
    if (collerObjIndex >= 0 && tokboxcalldata)
      tokboxcalldata.splice(collerObjIndex, 1)
    elem.parentNode.removeChild(elem);
  }
  var length = document.getElementById("ulWaitingCall").getElementsByTagName("li").length;
  if (length && length != '' && length != 0) {
    document.getElementById("spanCallingNumbers").innerHTML = length;
  } else {
    //Hide the span
    document.getElementById("spanWaitingCall").style.display = 'none';
  }
  //Send message to the caller for rejection
  SendMessageCallRejectionQueue(msg);
}

/**
 * Accept waiting call
 * @param {*} msgData 
 */
function AcceptQueueCall(msgData) {
  //Remove li
  var elem = document.getElementById('li' + msgData.caller_userID);
  if (elem) {
    var collerId = $(elem).attr('id-data');
    var collerObjIndex = tokboxcalldata.findIndex(function (x) {
      x.caller_userID == collerId;
    });
    if (collerObjIndex >= 0 && tokboxcalldata)
      tokboxcalldata.splice(collerObjIndex, 1)

    elem.parentNode.removeChild(elem);
  }
  var length = document.getElementById("ulWaitingCall").getElementsByTagName("li").length;
  if (length && length != '' && length != 0) {
    document.getElementById("spanCallingNumbers").innerHTML = length;
  } else {
    //Hide the span
    document.getElementById("spanWaitingCall").style.display = 'none';
  }

  //ToDo: Disconnect the existing call

  calltype = 'incoming';
  receiverId = msgData.caller_userID;
  //Send message to the caller for approval
  SendMessageCallApprovedQueue(msgData);
}

/**
 * Send signal when wating call is rejected
 * @param {*} msgData 
 */
function SendMessageCallRejectionQueue(msgData) {
  msgData.status = "declined";
  //Revert the UserIds
  var callerId = msgData.caller_userID;
  msgData.caller_userID = msgData.receiver_userID;
  msgData.receiver_userID = callerId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    } else { }
  });
}

/**
 * Send signal when wating call is accepted
 * @param {*} msgData 
 */
function SendMessageCallApprovedQueue(msgData) {
  //Send message
  msgData.status = "approved";
  //Revert the UserIds
  var callerId = msgData.caller_userID;
  msgData.caller_userID = msgData.receiver_userID;
  msgData.receiver_userID = callerId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    } else {
      DisconnectTempSession(window.tempSession);
      //Connect to new call
      setTimeout(function () {
        ConnectToIncomingSession(msgData.session_id, msgData.token_id, msgData);
      }, 2500);

      //Log call connected
      LogCallConnected(msgData.session_id);
      //Get old chats
      SetUserDetails(msgData.receiver_userID);
      HideMessageSection();
      HideSettingsSection();
      ClearMessageSectionText();
      GetChatHistory(msgData.receiver_userID, 0);
    }
  });
}

/**
 * Check whether user is on another call
 * @param {*} userId 
 */
function CheckIfUserInCall(userId) {
  var returnvalue = false;
  //Check the call connected div Active class
  if (document.getElementById('ongoingCall').classList.contains('active') && connectedToUserId == userId) {
    //If active, then provide alert that call is already in progress
    returnvalue = true;
  }
  return returnvalue;
}

/**
 * Set caller or receiver details
 * @param {*} paramUserId 
 */
function SetUserDetails(paramUserId) {
  //Call to get user date
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/getconnectiondetails";
  var params = "UserId=" + paramUserId;
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      var response = JSON.parse(http.responseText);
      if (response.Status && response.Data != null) {
        //Set Userdetails

        SetConnectedUserDetails(response.Data);
      } else { }
    }
  }
  http.send(params);

}

/**
 * Set connected user details
 * @param {*} data 
 */
function SetConnectedUserDetails(data) {
  Count = 1;
  connectedToUserId = data[0].userid;
  connectedToUsername = data[0].name;
  connectedToUserEmail = data[0].email;
  connectedToUserImage = data[0].image;

  //update the connection data in call header
  document.getElementById('headerCallerName').innerHTML = connectedToUsername;

  if (connectedToUserImage && connectedToUserImage.length > 0) {
    document.getElementById('headerCallerImage').src = connectedToUserImage;
    var callerImg = document.getElementById("callerDiv");
    callerImg.style.display = "block";

    var callerinitial = document.getElementById("callerInitialDiv");
    callerinitial.style.display = "none";
  } else {
    var callerImg = document.getElementById("callerDiv");
    callerImg.style.display = "none";

    var callerinitial = document.getElementById("callerInitialDiv");
    callerinitial.style.display = "block";
    document.getElementById('CallerInitial').innerHTML = GetInitials(connectedToUsername);
    var v = GetClass();
    document.getElementById('callerInitialDiv').className += v;
  }
}


/** 
 * Get Initial for User
 * */
function GetInitials(Name) {
  if (Count >= 3) {
    Count = 1;
  } else {
    Count = this.Count + 1;
  }
  var splittedName = Name.split(' ', 3);
  if (splittedName.length > 1) {
    return splittedName[0].charAt(0) + splittedName[1].charAt(0);
  } else {
    return splittedName[0].charAt(0);
  }
}

/** 
 * Get Initial Class for User
 * */

function GetClass() {

  if (Count === 1) {
    return 'iconUser noImg cyan lighten-3';
  } else if (Count === 2) {
    return 'iconUser noImg cyan lighten-5';
  } else if (Count === 3) {
    return 'iconUser noImg pink lighten-4';
  }
}

/** 
 * Clear waiting bag
 * */
function ClearQueueBag() {
  document.getElementById("ulWaitingCall").innerHTML = "";
  document.getElementById('spanWaitingCall').style.display = 'none';
}

/** 
 * Clear waiting bag
 * */
function ClearQueueBagData(tokboxcall) {
  var ulObj = $('#ulWaitingCall');
  if (ulObj && ulObj.length > 0) {
    ulObj.children().each(function (index, ele) {
      if ($(ele).attr('id-data') === tokboxcall.caller_userID) {
        var a = $(this);
        ele.remove();
        if (ulObj.children().length == 0) {
          document.getElementById('spanWaitingCall').style.display = 'none';
        }
      }
    });
  } else {
    ClearQueueBag();
  }
}

/** 
 * Log out user
 */
function LoggedOut(shouldDeletefirebaseToken) {
  LogDefaultSessionDisconnected(default_SessionId);
  if (shouldDeletefirebaseToken) {
    deleteFireBaseToken();
  }
  //Disconnect main session
  if (session) {
    try {
      session.disconnect();
      session = "";
    } catch (e) {
      session = "";
    }
  }

  if (tempSession) {
    try {
      tempSession.disconnect();
      tempSession = "";
    } catch (e) {
      tempSession = "";
    }
  }
  if (window.tempSession) {
    window.tempSession.disconnect();
    window.tempSession = undefined;
  }
  //Hide the divs
  setTimeout(function () {
    HideCallDiv();
  }, 3000);
  //Hide the Queue Bag
  ClearQueueBag();
  HideRinger();
}

/** 
 * Show hide waiting bag
 */
function ShowHideQueueBag() {
  if (document.getElementById('iconWaitingCall').innerHTML == "keyboard_arrow_down") {
    document.getElementById('spanWaitingCall').style.height = 'auto';
    document.getElementById('iconWaitingCall').innerHTML = "keyboard_arrow_up";
  } else {
    document.getElementById('spanWaitingCall').style.height = '100px';
    document.getElementById('iconWaitingCall').innerHTML = "keyboard_arrow_down";
  }
}

/** 
 * Disconnect user from temp session
 */
function DisconnectTempSession(tempSession) {
  if (tempSession) {
    tempSession.disconnect();
  }

  if (window.tempSession) {
    window.tempSession.disconnect();
  }
}


function screenshare() {
  if (document.getElementById('btninstallext'))
    document.getElementById('btninstallext').href = downloadextnlink;
  var ffWhitelistVersion = '36';
  OT.registerScreenSharingExtension('chrome', screenshareextensioncode, 2);
  OT.checkScreenSharingCapability(function (response) {
    if (!response.supported || response.extensionRegistered === false) {
      CloseExtentionPopup();
      alert('This browser does not support screen sharing.');
    } else if (response.extensionInstalled === false &&
      (response.extensionRequired || !ffWhitelistVersion)) {
      document.getElementById('install-button').style.display = 'block';
    } else if (ffWhitelistVersion && navigator.userAgent.match(/Firefox/) &&
      navigator.userAgent.match(/Firefox\/(\d+)/)[1] < ffWhitelistVersion) {
      CloseExtentionPopup();
      alert('For screen sharing, please update your version of Firefox to ' +
        ffWhitelistVersion + '.');
    } else {
      CloseExtentionPopup();
      // Screen sharing is available. Publish the screen.
      tempSession.unpublish(publisher);

      var publish = Promise.all([
        OT.getUserMedia()
      ])
        .then(function (data) {
          var screenStream = data[0],
            micStream = data[1];
          return OT.initPublisher('publisher', {
            videoSource: 'screen',
            audioSource: screenStream.getAudioTracks()[0],
            publishAudio: document.getElementById('iconMicOnOff').innerHTML === "mic" ? true : false,
            insertMode: 'append',
            width: '100%',
            height: '100%',
            showControls: false,
            usePreviousDeviceSelection: true
          });
        });

      publish.then(function (publishstream) {
        publisher = publishstream;
        tempSession.publish(publisher, (handleError) => {
          if (handleError && handleError.code === 1500) {
            // console.log('handleError-->', handleError);
            var publishingVideo = wasPublishingVideo();
            publisher = OT.initPublisher('publisher', {
              insertMode: 'append',
              width: '100%',
              height: '100%',
              publishAudio: document.getElementById('iconMicOnOff').innerHTML === "mic" ? true : false,
              publishVideo: publishingVideo,
              showControls: false,
              usePreviousDeviceSelection: true
            }, handleError);

            tempSession.publish(publisher, handleError);
            if (wasPublishingVideo()) {
              publisher.publishVideo(true);
            } else {
              publisher.publishVideo(false);
            }
          } else {
            document.getElementById('iconScreenShareOnOff').classList.add('inactive');
            document.getElementById('iconVideoOnOff').setAttribute('style', 'pointer-events:none;');
            document.getElementById('iconScreenShareOnOff').innerHTML = 'stop_screen_share';
          }
        });

      }).catch(handleError);

    }
  });

  tempSession.on("streamPropertyChanged", function (event) {
    var subscribers = session.getSubscribersForStream(event.stream);
    for (var i = 0; i < subscribers.length; i++) {
      // You may want to display some UI text for each
    }
  })
}

function SendMessageCallRecordingOn() {
  //Show record timer
  document.getElementById('spn_timer').style.display = 'inline-block';
  timer.start();
  timer.addEventListener('secondsUpdated', function (e) {
    document.getElementById('spn_timer').innerHTML = timer.getTimeValues().toString();
  });

  var msgData = new Object;
  msgData.status = "recording_on";
  //Revert the UserIds
  msgData.caller_userID = callerId;
  msgData.receiver_userID = receiverId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    } else { }
  });
}

function SendMessageCallRecordingOff() {
  //Show record timer
  document.getElementById('spn_timer').style.display = 'none';
  timer.stop();
  document.getElementById('spn_timer').innerHTML = '00:00:00';
  // document.getElementById('spn_timer').innerHTML = '00:00:00';
  var msgData = new Object;
  msgData.status = "recording_off";
  //Revert the UserIds
  msgData.caller_userID = callerId;
  msgData.receiver_userID = receiverId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    } else { }
  });
}

function SendMessageCallInQueue(msgData) {
  msgData.status = "queue";
  //Revert the UserIds
  var callerId = msgData.caller_userID;
  msgData.caller_userID = msgData.receiver_userID;
  msgData.receiver_userID = callerId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    } else { }
  });
}

function SendMessageUserIsBusy(msgData) {
  msgData.status = "busy";
  //Revert the UserIds
  var callerId = msgData.caller_userID;
  msgData.caller_userID = msgData.receiver_userID;
  msgData.receiver_userID = callerId;

  session.signal({
    type: 'call',
    data: JSON.stringify(msgData)
  }, function (error) {
    if (error) {
    } else { }
  });
}

function SendMessageCallUnQueue(msgData) {
  var newVar = Object.assign({}, msgData);

  newVar['status'] = "unqueue";
  var callerId = msgData.caller_userID;
  newVar.caller_userID = msgData.receiver_userID;
  newVar.receiver_userID = callerId;
  //Revert the UserIds
  session.signal({
    type: 'call',
    data: JSON.stringify(newVar)
  }, function (error) {
    if (error) {
    } else { }
  });
}

function DetectBrowserExit() {
  alert('onbeforeunload fired');
}

function DetectBrowserExit2() {
  alert('onunload fired.');
}

function AttachUnloadEvent() {
  window.addEventListener("beforeunload", function (e) {
    DetectBrowserExit();

    (e || window.event).returnValue = null;
    return null;
  });

  window.addEventListener("unload", function (e) {
    DetectBrowserExit2();

    (e || window.event).returnValue = null;
    return null;
  });
}

function RemoveUnloadEvent() {
  window.removeEventListener("beforeunload", function (e) { });

  window.removeEventListener("unload", function (e) { });
}

/** 
 * Start recording
 */
function StartRecording() {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/startarchiving";
  var params = "userId=" + usermodel.user.UserId + "&sessionId=" + sessionId + "&hasAudio=" + true + "&hasVideo=" + true;
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      var response = JSON.parse(http.responseText);
      if (response.Status && response.Data != null) {
        lastArchiveId = response.Data.archiveId;
      }
    }
  }
  http.send(params);
  SendMessageCallRecordingOn();
}

/** 
 * Stop recording
 */
function StopRecording() {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  var http = new XMLHttpRequest();
  var url = serverURL + "tokbox/stoparchiving";
  var params = "userId=" + usermodel.user.UserId + "&archiveid=" + lastArchiveId;
  http.open("POST", url, true);

  var token = localStorage.getItem('frontend-token').toString();
  //Send the proper header information along with the request
  http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  http.setRequestHeader("Authorization", token);

  http.onreadystatechange = function () { //Call a function when the state changes.
    if (http.readyState == 4 && http.status == 200) {
      var response = JSON.parse(http.responseText);
      if (response.Status && response.Data != null) {
        //Download and store in db
        DownloadRecording(response.Data.id);
      }
    }
  }
  http.send(params);
  SendMessageCallRecordingOff();
}

/** 
 * Recording Switch to on / off
 */
function RecordingOnOff() {
  if (!document.getElementById('iconRecord').checked) {
    StopRecording();
  } else {
    StartRecording();
  }
}

/** 
 * Settings Switch to on / off
 */
function SettingsOnOff() {
  if (document.getElementById('divSettings').classList.contains("hide")) {
    HideMessageSection();
    document.getElementById('videos').classList.add('halfvideo');
    document.getElementById('divSettings').classList.remove('hide');
  } else {
    HideSettingsSection();
  }
}

/** 
 * Hide settings section
 */
function HideSettingsSection() {
  document.getElementById('videos').classList.remove('halfvideo');
  document.getElementById('divSettings').classList.add('hide');
}
var audioInputSelect = document.querySelector('select#audioSource');
var audioOutputSelect = document.querySelector('select#audioOutput');
var videoSelect = document.querySelector('select#videoSource');


var audioInputSelectPop = document.querySelector('select#audioInputPop');
var videoSelectPop = document.querySelector('select#videoInputPop');

var selectors = [audioInputSelect, audioOutputSelect, videoSelect];
var selectorsPop = [audioInputSelectPop, videoSelectPop];

audioOutputSelect.disabled = !('sinkId' in HTMLMediaElement.prototype);

/**
 * Get all attached devices
 * @param {*} deviceInfos 
 */
function gotDevices(deviceInfos) {
  // Handles being called several times to update labels. Preserve values.
  var values = selectors.map(function (select) {
    return select.value;
  });

  selectors.forEach(function (select) {
    while (select.firstChild) {
      select.removeChild(select.firstChild);
    }
  });


  for (var i = 0; i !== deviceInfos.length; ++i) {
    var deviceInfo = deviceInfos[i];
    if (deviceInfo.label != "Default" && deviceInfo.label != "Communications") {
      var option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label ||
          'microphone ' + (audioInputSelect.length + 1);
        audioInputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || 'speaker ' +
          (audioOutputSelect.length + 1);
        audioOutputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || 'camera ' + (videoSelect.length + 1);
        videoSelect.appendChild(option);
      }
    }
  }
  selectors.forEach(function (select, selectorIndex) {
    if (Array.prototype.slice.call(select.childNodes).some(function (n) {
      return n.value === values[selectorIndex];
    })) {
      select.value = values[selectorIndex];
    }
  });

}

function GetDevices() {
  if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
    navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleErrors);
  }
}

function InitializeControledevices(deviceInfos) {
  var valuesPop = selectorsPop.map(function (select) {
    return select.value;
  });

  selectorsPop.forEach(function (select) {
    while (select.firstChild) {
      select.removeChild(select.firstChild);
    }
  });

  for (var i = 0; i !== deviceInfos.length; ++i) {
    var deviceInfo = deviceInfos[i];
    if (deviceInfo.label != "Default" && deviceInfo.label != "Communications") {
      var option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label ||
          'microphone ' + (audioInputSelect.length + 1);
        audioInputSelectPop.appendChild(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || 'speaker ' +
          (audioOutputSelect.length + 1);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || 'camera ' + (videoSelect.length + 1);
        videoSelectPop.appendChild(option);
      }
    }
  }

  selectorsPop.forEach(function (select, selectorIndex) {
    if (Array.prototype.slice.call(select.childNodes).some(function (n) {
      return n.value === valuesPop[selectorIndex];
    })) {
      select.value = valuesPop[selectorIndex];
    }
  });
}

/** 
 * Change device
 */
function attachSinkId(element, sinkId) {
  if (typeof element.sinkId !== 'undefined') {
    element.setSinkId(sinkId)
      .then(function () {
      })
      .catch(function (error) {
        var errorMessage = error;
        if (error.name === 'SecurityError') {
          errorMessage = 'You need to use HTTPS for selecting audio output ' +
            'device: ' + error;
        }
        // Jump back to first output device in the list as it's the default.
        audioOutputSelect.selectedIndex = 0;
      });
  } else {
    console.warn('Browser does not support output device selection.');
  }
}

/** 
 * Change Audio output device
 */
function SwitchAudioOutput() {
  var videoElement = document.querySelector('video');
  var audioDestination = audioOutputSelect.value;
  attachSinkId(videoElement, audioDestination);
  updateAllowedDevices();
}

/** 
 * Change Audio/Video device
 */
function SwitchVideoInput() {
  tempSession.unpublish(publisher);
  var pubOptions = {
    audioSource: audioInputSelect.value,
    videoSource: videoSelect.value,
    insertMode: 'append',
    showControls: false,
    usePreviousDeviceSelection: true
  };
  publisher = OT.initPublisher('publisher', pubOptions, function (error) {
  });

  tempSession.publish(publisher, function (error) {
    if (error) {
      alert('Could not change the camera: ' + error.message);
    }
  });
  updateAllowedDevices();
}

/** 
 * Change Audio/Video device
 */
function SwitchAudioVideo() {
  tempSession.unpublish(publisher);
  var pubOptions = {
    audioSource: audioInputSelect.value,
    videoSource: videoSelect.value,
    insertMode: 'append',
    showControls: false,
    usePreviousDeviceSelection: true
  };
  publisher = OT.initPublisher('publisher', pubOptions, function (error) {
  });

  tempSession.publish(publisher, function (error) {
    if (error) {
      alert('Could not change the camera: ' + error.message);
    }
  });

  updateAllowedDevices();
}

function SwitchAudioInputPop(event) {
  audioInputSelect.value = event.target.value;
  updateAllowedDevices();
}


function SwitchVideoInputPop(event) {
  videoSelect.value = event.target.value;
  updateAllowedDevices();
  PlayCameraVideo();
}


/**
 * Handle errors
 * @param {*} error 
 */
function handleErrors(error) {
}

/** 
 * Clear new message Icon
 */
function ClearNewMessageIcon() {
  document.getElementById('iconNewMessage').style.display = 'none';
}

/** 
 * Show new Message Icon
 */
function ShowNewMessageIcon() {
  document.getElementById('iconNewMessage').style.display = 'block';
}

/** 
 * New message recived signal
 */
function NewMessageReceived(data) {
  if (document.getElementById('iconMessageOnOff').innerHTML == "speaker_notes") {
    var NotificationData = [];
    NotificationData.push({
      'title': 'New message received',
      'alertContent': data.caller_name + ' :' + data.message,
      'image': 'assets/images/newmessage.png'
    });

    if (window['angularComponentRef']) {

      Push.create("New message received!", {
        tag: data.caller_name,
        body: data.caller_name + ' :' + data.message,
        icon: 'assets/images/newmessage.png',
        timeout: 4000000000000,
        onClick: function () {
          document.getElementById('callNotificationAllow').style = 'display:none';
          window.focus();
          this.close();
        }
      });
    }
    ShowNewMessageIcon();
  }
}

/** 
 * Show browser is not supported division
 */
function ShowBrowserNotSupported() {
  document.getElementById('divBrowserNotSupported').style.display = 'block';
}

/** 
 * Hide browser is not supported division
 */
function HideBrowserNotSupported() {
  document.getElementById('divBrowserNotSupported').style.display = 'none';
}

/** 
 * Close Extension popup
 */
function CloseExtentionPopup() {
  document.getElementById('install-button').style.display = 'none';
}

/** 
 * Close App popup
 */
function CloseAppPopup() {
  document.getElementById('install_mobile_app').style.display = 'none';
}


/** 
 * Close Extension popup
 */
function CloseCallingNSPopup() {
  $('#calling_not_supported').removeClass('open');
}

/** 
 * Close App popup
 */
function ESignaturePopup() {
  document.getElementById('esignature_issue').style.display = 'none';
}



/** 
 * Decode JWT token
 */
function parseJwt(token) {
  if (token !== undefined && token !== null && token !== '') {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
};


function UpdatePostAffTracker(val) {
  if (val === '1') {
    //  PostAffTracker.writeCookieToCustomField('pap_tdx8v2s5a');
    //console.log(document.getElementById('pap_tdx8v2s5a').value);
  }
}


function updateAllowedDevices() {
  var selectedMic = audioInputSelect.value;
  var selectedAudioOut = audioOutputSelect.value;
  var selectedCam = videoSelect.value;
  var allowed_devices = {
    cam_id: selectedCam,
    mic_id: selectedMic,
    output_id: selectedAudioOut
  };
  window.localStorage.setItem('allowed_devices', JSON.stringify(allowed_devices));
}

function setPreSetAllowedDevices(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio) {
  var allowed_devices_string = window.localStorage.getItem('allowed_devices')
  if (allowed_devices_string && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
    // set devices
    var alloeddevice = JSON.parse(allowed_devices_string);
    // get allow connected devices
    navigator.mediaDevices.enumerateDevices().then(function (connectedDevices) {
      var audioInputPop = $("#audio-input-pop");
      // var audioOutPop = $("#audio-input-pop");
      var videoInputPop = $("#video-input-pop");
      var cam_id = null,
        mic_id = null;
      connectedDevices.map(function (device, index) {
        if (device && device.deviceId == alloeddevice.cam_id) {
          cam_id = alloeddevice.cam_id;
          videoInputPop.val(device.deviceId);
          videoSelect.value = device.deviceId;
        } else if (device && device.deviceId == alloeddevice.mic_id) {
          mic_id = alloeddevice.mic_id;
          audioInputPop.val(alloeddevice.mic_id);
          audioInputSelect.value = alloeddevice.mic_id;
        } else if (device && device.deviceId == alloeddevice.output_id) {
          audioOutputSelect.value = alloeddevice.output_id
        }
      })

      if (!mic_id || !cam_id) {
        // show setting popup
        updateAllowedDevices();
        showInitialCallingControlSetting(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio);
        // window['allowcall'] = false;
      } else {
        // hide popup
        closeInitialCallingControlSetting();
        window['allowcall'] = true;
        SendMessageCall(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio);
      }

    }).catch(function (err) { })
  } else {
    // show popup to select devices
    window['allowcall'] = true;
    showInitialCallingControlSetting(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio);
    // SendMessageCall(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial);
    updateAllowedDevices();
  }
}

function connectCallWithSettings() {

}

function PlayTestTune() {
  PlayTestTuneAudio();
  window.setTimeout(function () {
    StopTestTuneAudio();
  }, 5000);
}


function closeInitialCallingControlSetting() {
  $('.loader-outer').css('display', 'none');
  var callingControlPopEle = document.getElementById('choose_settings');
  callingControlPopEle.classList.remove('open');
  var video = document.querySelector("#videoElement");
  // video.style.display = "none";
  video.removeAttribute('srcObject'); //setAttribute("srcObject", null);
  if (window['angularComponentRef'] && window['angularComponentRef'].componentCallAgentButtonEnableFn) {
    window['angularComponentRef'].zone.run(function () {
      window['angularComponentRef'].componentCallAgentButtonEnableFn(true);
    });
  }
}

function showInitialCallingControlSetting(newSessionId, newTokenId, otherTokenId, ToUserId, ToUserPic, ToInitial, description, IsAudio) {
  if (document.getElementById('anchorConnectNow'))
    document.getElementById('callNowDivId').removeChild(document.getElementById('anchorConnectNow'));
  el = document.createElement('a');
  if (ToUserPic)
    el.setAttribute('onclick', "SendMessageCall('" + newSessionId + "', '" + newTokenId + "', '" +
      otherTokenId + "', '" + ToUserId + "', '" + ToUserPic + "','" + ToInitial + "','" + description + "'," + IsAudio + ")")
  else
    el.setAttribute('onclick', "SendMessageCall('" + newSessionId + "', '" + newTokenId + "', '" +
      otherTokenId + "', '" + ToUserId + "', '" + ToUserPic + "','" + ToInitial + "','" + description + "'," + IsAudio + ")")
  el.setAttribute('id', 'anchorConnectNow')
  el.setAttribute('href', 'javascript:void(0)');
  el.classList.add('waves-effect')
  el.classList.add('raddius25');
  el.classList.add('green')
  el.classList.add('lighten-5');
  el.classList.add('btn-flat');
  el.classList.add('btn-icon');
  el.classList.add('white-text');

  elText = document.createTextNode('Connect Now');
  el.appendChild(elText);
  document.getElementById('callNowDivId').appendChild(el);
  var callingControlPopEle = document.getElementById('choose_settings');
  callingControlPopEle.classList.add('open');
  PlayCameraVideo();
  updateAllowedDevices();

}

function PlayCameraVideo() {
  var video = document.querySelector("#videoElement");
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({
      video: true
    })
      .then(function (stream) {
        video.srcObject = stream;
      })
      .catch(function (err0r) {
      });
  }
}

function CloseBrowserRePop() {
  var BrowserRePop = document.getElementById('browser_requirements_popup');
  $('#browser_popup_overlay').removeClass('open_browser_bg');
  BrowserRePop.classList.remove('open');
}

document.onreadystatechange = function () {

  if (document.readyState === 'complete' && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {

    // document ready
    navigator.mediaDevices.enumerateDevices().then(InitializeControledevices).catch(handleErrors);
    // InitializeControledevices();
    if (window.localStorage.getItem('allowed_devices')) {
      window['shouldDevicesettingOpen'] = false;
      // setPreSetAllowedDevices();
    } else {
      // var callingControlPopEle = document.getElementById('choose_settings');
      // callingControlPopEle.classList.add('open');
      window['shouldDevicesettingOpen'] = false;
    }
  }

  if (document.readyState === 'complete') {
    GetDevices();
    const BrowserType = navigator.sayswho;
    var usermodel = parseJwt(localStorage.getItem('frontend-token'));
    if (BrowserType.indexOf('Chrome') != -1 && usermodel && usermodel.user.RoleId != 7 && usermodel.user.RoleId != 3 && getSubDomain() !== 'app') {
      document.getElementById('callNotificationAllow').style = 'display:block;';
    }
    if (!Push.Permission.has()) {
      Push.Permission.request(onNotificationGranted, onNotificationDenied);
    }

    if (firebase.messaging.isSupported()) {
      messaging = new firebase.messaging();
      messaging.usePublicVapidKey("BKSBB7sqn7a4iOPE-WL2vlPp96p_L51hFq8LfyMgIwx0ZJ64PM8993LMN3EII1vJwL4d5D6x7oG-s3k8SU-d7GU");

      messaging.onMessage(function (payload) {
        if (payload && payload.data && payload.data.type === "activity") {
          if (window['angularComponentRef']) {
            window['angularComponentRef'].zone.run(function () {
              window['angularComponentRef'].updateActivityFn(payload);
              if (payload.data && payload.data.type === 'activity') {
                var activityData = payload.notification;
                Push.create(activityData.title, {
                  body: activityData.body,
                  icon: activityData.icon,
                  timeout: 15000,
                  onClick: function () {
                    window.focus();
                    this.close();
                  }
                });
              }
            });
          }
        }
      });

      if (usermodel && usermodel.user && usermodel.user.RoleId !== 7) {
        if (!localStorage.getItem('fbt')) {
          getFirebaseToken()
        }
      }
    }
  }
};

function getFirebaseToken() {
  if (firebase.messaging.isSupported()) {
    messaging.getToken().then(function (currentToken) {
      if (currentToken) {
        // sendTokenToServer(currentToken);
        // updateUIForPushEnabled(currentToken);
        SaveFireBaseToken(currentToken);
      } else {
        // Show permission request.
        // Show permission UI.
        // updateUIForPushPermissionRequired();
        // setTokenSentToServer(false);
      }
    }).catch(function (err) {
      // showToken('Error retrieving Instance ID token. ', err);
      // setTokenSentToServer(false);
    });
  }
}


function deleteFireBaseToken() {
  var accessToken = localStorage.getItem("frontend-token");
  var usermodel = parseJwt(accessToken);
  if (usermodel && usermodel.user && usermodel.user.RoleId !== 7) {
    if (localStorage.getItem('fbt')) {
      var token = localStorage.getItem('fbt')

      var http = new XMLHttpRequest();
      var url = serverURL + "tokbox/deletefirebasewebtoken";

      var params = "userid=" + usermodel.user.UserId +
        "&token=" + token + "&accessToken=" + accessToken;
      http.open("POST", url, true);
      //Send the proper header information along with the request
      http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      http.setRequestHeader("Authorization", accessToken);

      http.onreadystatechange = function () { //Call a function when the state changes.
        if (http.readyState == 4 && http.status == 200) {
          localStorage.removeItem('fbt')
          if (firebase.messaging.isSupported()) {
            messaging.getToken().then(function (currentToken) {
              messaging.deleteToken(token).then(function () {
              }).catch(function (err) {
              });
            }).catch(function (err) {
            });
          }
        }
      }
      http.send(params);
    }
  }
}

function SaveFireBaseToken(token) {
  var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  if (usermodel && usermodel.user.RoleId !== 7) {
    var http = new XMLHttpRequest();
    var url = serverURL + "tokbox/addfirebasewebtoken";
    var acceesToken = localStorage.getItem('frontend-token').toString();

    var params = "userid=" + usermodel.user.UserId +
      "&token=" + token + "&os=Web" +
      "&deviceid=" + navigator.os + ":-" + navigator.userAgent + "&accessToken=" + acceesToken;
    http.open("POST", url, true);


    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    http.setRequestHeader("Authorization", acceesToken);

    http.onreadystatechange = function () { //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        //alert(http.responseText);
        localStorage.setItem('fbt', token);
      }
    }
    http.send(params);
  }
}


function UpdateCallActivityStatus(SessionId, activityStatus) {

  if (SessionId) {
    activitySession = SessionId;
  }

  if (activitySession) {
    var usermodel = parseJwt(localStorage.getItem('frontend-token'));
    var http = new XMLHttpRequest();
    var url = serverURL + "activity/updatecallactivitystatus";
    var acceesToken = localStorage.getItem('frontend-token').toString();

    var params = "SessionId=" + activitySession +
      "&ActivityStatus=" + activityStatus;
    http.open("POST", url, true);
    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    http.setRequestHeader("Authorization", acceesToken);

    http.onreadystatechange = function () { //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        //alert(http.responseText);
        //localStorage.setItem('fbt', token);
      }
    }
    http.send(params);
  }
}



function onNotificationGranted(data) {
}

function onNotificationDenied(data) {
}

function closeNotification(id) {
  if (id)
    Push.close(id)
}

function getSubDomain() {
  var full = window.location.host
  //window.location.host is subdomain.domain.com
  var parts = full.split('.')
  var sub = parts[0]
  var domain = parts[1]
  var type = parts[2]
  return sub;
}

document.addEventListener('visibilitychange', function () {
  if (document.hidden) {
    shownotifications = true;
  } else {
    shownotifications = false;
  }
}, false);


window.setInterval(function () {
  IsTokboxAvailable();
}, 60000)

window.addEventListener('online', function () {
  IsTokboxAvailable();

});
window.addEventListener('offline', function () {
});


function closeCallActive() {
  document.getElementById('callNotificationAllow').style = 'display:none';
}
document.addEventListener("click", function () {
  document.getElementById('callNotificationAllow').style = 'display:none';
});


function audioVideoCallDivSettings(isAudio, conversationData, IsCaller) {
  // var usermodel = parseJwt(localStorage.getItem('frontend-token'));
  // if (isAudio) {

  //   setTimeout(function () {
  //     if (conversationData) {
  //       if (usermodel && usermodel.user) {
  //         if (!IsCaller) {
  //           //var temp = conversationData.caller_pic ? conversationData.caller_pic : conversationData.caller_name;
  //           if (conversationData.caller_pic) {
  //             $('#subscriber .OT_video-poster').eq(0).css('background-image', 'url(' + conversationData.caller_pic + ')');
  //           }
  //           if (usermodel.user.Picture) {
  //             $('#publisher .OT_video-poster').eq(0).css('background-image', 'url(' + usermodel.user.Picture + ')')
  //           }
  //         } else {
  //           // var temp = conversationData.caller_pic ? conversationData.caller_pic : conversationData.caller_name;
  //           // $('#subscriber .OT_video-poster').eq(0).css('background-image', 'url(' + temp + ')')
  //           // if (usermodel.user.Picture) {
  //           //   $('#publisher .OT_video-poster').eq(0).css('background-image', 'url(' + usermodel.user.Picture + ')')
  //           // }
  //         }
  //       }
  //     } else {

  //     }
  //   }, 100);

  // } else {
  //   if (conversationData) {
  //     $('#subscriber .OT_video-poster').eq(0).css('background-image', 'none');
  //     $('publisher .OT_video-poster').eq(0).css('background-image', 'none')
  //   } else {
  //     $('#subscriber .OT_video-poster').eq(0).css('background-image', 'none');
  //     $('publisher .OT_video-poster').eq(0).css('background-image', 'none')
  //   }
  // }
}

function wasPublishingVideo() {
  return document.getElementById('iconVideoOnOff').innerHTML === 'videocam'
}

/****************************************************
 * Chat and Audio calling start
 ****************************************************/



/****************************************************
 * Chat and Audio calling end
 ****************************************************/
