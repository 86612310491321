export class AgentData {
    constructor(
        public AgencyName: any,
        public AgencyAddress1: string,
        public AgencyAddress2: string,
        public AgencyCity: string,
        public AgencyState: string,
        public AgencyZipCode: any
    ) {

    }
}
