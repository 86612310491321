import { Component, OnInit, NgZone, EventEmitter, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { UserService } from '../model/user.service';
import { User } from '../model/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MaterializeAction, toast } from 'angular2-materialize';
import { EventBus } from '../shared/EventBus/EventBus';
import { Router } from '@angular/router';
import { TokboxService } from '../model/tokbox.service';
import { GlobalService } from '../model/global.service';

declare var myExtObject: any;
declare var $: any;

@Component({
  selector: 'app-public-face-page',
  templateUrl: './public-face-page.component.html',
  styleUrls: ['./public-face-page.component.css'],
})
export class PublicFacePageComponent implements OnInit, OnDestroy, AfterViewInit {

  public agencyLocationLogo: string;
  public agencyLocationAddress1: string;
  public agencyLocationAddress2: string;
  public agencyLocationBodyBannerThumbnailUrl: string;
  public agencyLocationBodyBannerUrl: string;
  public agencyLocationCity: string;
  public agencyLocationContactNumber: string;
  public agencyLocationLocationDescription: any;
  public agencyLocationLocationName: string;
  public agencyLocationLocationSiteAddress: string;
  public agencyLocationLocationStatusId: string;
  public agencyLocationState: string;
  public agencyLocationZipCode: string;
  public showAvailAgentsLoader: boolean;
  public allUsers: User[] = [];
  public onlineUsers: User[] = [];
  public onlineAgentCount: number;
  private Count = 1;
  lat = 51.678418;
  long = 7.809007;
  public refereshUserId: any;
  public refereshConnectionId: any;
  public showProductsLoader: boolean;
  public products: any[];
  public getLeadForm: FormGroup;
  public submitted: boolean;
  private apiResponse: any;
  private shouldCallCallBack: boolean;
  public isCallButtonEnabled: boolean;
  ToUserId: number; ToUserPic: string; ToInitial: string; ToName: string; FromUserId: number; ToUserDescription: string;
  modalActions2 = new EventEmitter<string | MaterializeAction>();
  public showSettingLoader: boolean;
  isBannerLoaded: boolean;
  DescrptionTitle: any;
  Address1longitude: string;
  Address1latitude: string;
  showAgentSideBar: boolean;
  UserId: number;
  AgencyName: string;
  AgencyId: number;
  LocationId: number;
  Ip: any;
  GuestUserNameModel: string;
  ShuoldAskGuestName: boolean;
  globleDataSource: any;
  IsAudio: boolean;
  UserDetailModelParams: any = [
    {
      dismissible: false,
      complete: function () {  }
    }
  ];
  AddressStatus = false;
  public ArrayOnlineTokboxuser = [];
  public htmlContent: any;

  constructor(private _userService: UserService, private _ngZone: NgZone, private meta: Meta,
    private tokboxService: TokboxService,
    private _globalService: GlobalService,
    private _eventBus: EventBus, private _domSanitizer: DomSanitizer,
    private _router: Router, private renderer: Renderer2) {
    this.ArrayOnlineTokboxuser = [];
    this.globleDataSource = this._globalService.globleDataSource;
    this.meta.addTag({ property: 'og:title', content: this.globleDataSource['AgencyName'] });
    this.meta.addTag({ property: 'og:description', content: `${this.globleDataSource['AgencyName']} - Connect in our virtual office` });
    this.meta.addTag({ property: 'og:type', content: 'agency_page' });
    this.meta.addTag({ property: 'og:url', content: `https://${this.globleDataSource['SiteAddress']}.agentalive.tech/` });
    this.meta.addTag({ property: 'og:image', content: `${this.globleDataSource['AgencyLogo']}` });
    this.meta.addTag({ property: 'og:site_name', content: this.globleDataSource['AgencyName'] });

    this.renderer.addClass(document.body, 'public_face_bg');
    this.GuestUserNameModel = '';
    this._eventBus.emit({ Type: 'show-authorized-header', data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
    this.isCallButtonEnabled = true;
    this.getLeadForm = new FormGroup({
      FirstName: new FormControl(''),
      LastName: new FormControl(''),
      Email: new FormControl(''),
      Phone: new FormControl('')
    });
    this.ShuoldAskGuestName = true;
    this._eventBus.on().subscribe(m => {
      if (m.filterBy.Type === `Call-Agent-Button-Enable`) {
        this.isCallButtonEnabled = m.filterBy.data;
      }
    });

    if (!this.globleDataSource['AgencyId'] || !this.globleDataSource['LocationId']) {

      const subDomain = this._userService.getSubdomain();
      if (subDomain === 'app') {
        this._router.navigate(['pricing']);
      } else {
        this.toasting('Agency not found.', 3000, 'red');
        this._router.navigate(['pricing']);
      }
    }
  }

  ngAfterViewInit() {
    const token = localStorage.getItem('frontend-token');
    if (token) {
      // get agency/user detail from token
      const UserModel = this._userService.decodeToken();
      if (UserModel && UserModel.user) {
        this.UserId = UserModel.user.UserId;
        this.AgencyName = UserModel.user.AgencyName;
        this.AgencyId = UserModel.user.AgencyId;
        this.LocationId = UserModel.user.LocationId;
        myExtObject['initializeTokbox']();
      } else {
        this.AddGuestUser();
      }
    } else {
      // add new guest account
      this.AddGuestUser();
    }
  }

  ngOnInit() {
    window['userComponentRef'] = {
      zone: this._ngZone,
      componentFn: (userId, connectionId, isConnected) => this.RefreshList(userId, connectionId, isConnected),
      componentCallFn: () => this.ConnectedToDefaultSession(),
      component: this
    };
    if (this.globleDataSource['AgencyId'] && this.globleDataSource['LocationId']) {
      this.onlineAgentCount = 0;
      this.getPublicFacePageSettings();
      this.loadAgents(3, 0);
      this.GetProducts();
    } else {
      this._router.navigate(['pricing']);
    }
  }

  private AddGuestUser() {
    const UserModel = this._userService.decodeToken();
    if (UserModel.user) {
      this.UserId = UserModel.user.UserId;
      this.LocationId = UserModel.user.LocationId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
    }
    const FirstName = this.getLeadForm.controls['FirstName'].value;
    const LastName = this.getLeadForm.controls['LastName'].value;
    const postdata = {
      AgencyId: this.globleDataSource['AgencyId'],
      LocationId: this.globleDataSource['LocationId'],
      FirstName: FirstName ? FirstName : 'Guest',
      LastName: LastName ? LastName : 'User',
      Email: '',
      Phone: '',
      Description: 'Guest User',
      IpAddress: '', // data.ip
      UserId: this.UserId
    };
    this._userService.AddGuestAccount(postdata).subscribe(serverResp => {
      if (serverResp && serverResp.Data) {
        if (serverResp.Data.authtoken) {
          localStorage.setItem('frontend-token', serverResp.Data.authtoken);
        } else {
          localStorage.removeItem('frontend-token');
        }
        myExtObject['initializeTokbox']();
      }
    }, error => {
      localStorage.removeItem('frontend-token');
    });
  }
  // get public face page setting
  public getPublicFacePageSettings() {
    this.showSettingLoader = true;
    this._userService.GetPublicFacePageSettings(this.globleDataSource['AgencyId'],
      this.globleDataSource['LocationId']).subscribe(response => {
        if (response.Status) {
          const data = response.Data;
          this.agencyLocationLogo = data.LogoUrl;
          this.agencyLocationAddress1 = data.Address1;
          if (this.agencyLocationAddress1 && this.agencyLocationAddress1.length > 0) {
            this.AddressStatus = true;
          }
          this.agencyLocationAddress2 = data.Address2;
          this.agencyLocationBodyBannerThumbnailUrl = data.BodyBannerThumbnailUrl;
          this.agencyLocationBodyBannerUrl = data.BodyBannerUrl ? data.BodyBannerUrl : 'assets/images/agentalive-banner.png';
          this.agencyLocationCity = data.City;
          this.agencyLocationContactNumber = data.ContactNumber;
          this.agencyLocationLocationDescription = data.LocationDescription;
          this.htmlContent = this._domSanitizer.bypassSecurityTrustHtml(data.LocationDescription);
          this.agencyLocationLocationName = data.AgencyName;
          this.agencyLocationLocationSiteAddress = data.LocationSiteAddress;
          this.agencyLocationState = data.State;
          this.agencyLocationZipCode = data.ZipCode;
          this.DescrptionTitle = data.DescriptionTitle;

          this.Address1latitude = data.Address1latitude;
          this.Address1longitude = data.Address1longitude;
          this.lat = data.Address1latitude ? Number(data.Address1latitude) : this.lat;
          this.long = data.Address1longitude ? Number(data.Address1longitude) : this.long;
        }

        this.showSettingLoader = false;
      }, error => {
        this.showSettingLoader = false;
      });
  }

  ngOnDestroy() {
    const usermodel = this._userService.decodeToken();
    if (usermodel && usermodel.user && usermodel.user.IsLeadUser) {
      localStorage.removeItem('frontend-token');
    } else if (usermodel && usermodel.user && usermodel.user.RoleId === 7) {
      localStorage.removeItem('frontend-token');
    }
    this.renderer.removeClass(document.body, 'public_face_bg');
  }

  // convenience getter for easy access to form fields
  get f() { return this.getLeadForm.controls; }
  // submit guest user detail
  public onSubmitUserDetail(isCallButtonEnabled?: boolean) {
    this.submitted = true;
    if (this.getLeadForm.valid) {
      $('.loader-outer').css('display', 'inline-flex');
      const UserModel = this._userService.decodeToken();
      if (UserModel.user) {
        this.UserId = UserModel.user.UserId;
        this.LocationId = UserModel.user.LocationId;
        this.AgencyId = UserModel.user.AgencyId;
        this.LocationId = UserModel.user.LocationId;
      }
      const Names = this.GuestUserNameModel ? this.GuestUserNameModel.trim() : '';
      const spaceIndex = Names.indexOf(' ');
      const FirstName = spaceIndex !== -1 ? Names.substr(0, spaceIndex) : Names;
      const LastName = spaceIndex !== -1 ? Names.substr(spaceIndex + 1) : '';

      const postdata = {
        AgencyId: this.globleDataSource['AgencyId'],
        LocationId: this.globleDataSource['LocationId'],
        FirstName: Names ? FirstName : 'Guest',
        LastName: Names ? LastName : 'User',
        Email: '',
        Phone: '',
        Description: 'Guest User',
        IpAddress: '', // data.ip
        UserId: this.UserId
      };
      this._userService.AddGuestAccount(postdata).subscribe(serverResp => {
        if (serverResp && serverResp.Status) {
          this.ShuoldAskGuestName = false;
          this.closeUserDetail_Modal2();

          if (serverResp.Data.authtoken) {
            localStorage.setItem('frontend-token', serverResp.Data.authtoken);
          } else {
            localStorage.removeItem('frontend-token');
          }

          const IsCallInProgress = $('#ongoingCall').hasClass('active');
          if (isCallButtonEnabled && !IsCallInProgress) {
            this.isCallButtonEnabled = false;
            $('.loader-outer').css('display', 'inline-flex');
            this.GenerateSessionIdAndTokenId();
          } else {
            this.toasting('You cannot proceed while a call is in-progress', 5000, 'Red');
          }
        } else {
          $('.loader-outer').css('display', 'none');
          this.toasting(serverResp.Message, 3000, 'red');
        }
      }, error => {
        localStorage.removeItem('frontend-token');
        $('.loader-outer').css('display', 'none');
        this.toasting(error, 3000, 'red');
      });
    }
  }
  // get sessionid and token for tokbox
  public GenerateSessionIdAndTokenId() {
    this.tokboxService.createsessionandtoken(this.UserId, this.ToUserId)
      .subscribe(
        result => {
          if (result.Status) {
            myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2, this.ToUserId, result.currentSessionId,
              this.ToUserPic, this.ToInitial, this.ToName, 'Guest User', this.IsAudio);
          } else {
            alert(result.Message);
          }
        },
        error => {
        }
      );
  }
  // connect tokbox call
  private ConnectedToDefaultSession() {
    if (this.shouldCallCallBack) {
      this.shouldCallCallBack = false;
      myExtObject.start(this.apiResponse.Data.sessionId, this.apiResponse.Data.tokenId1, this.apiResponse.Data.tokenId2, this.ToUserId,
        this.apiResponse.Data.currentSessionId, this.ToUserPic, this.ToInitial, this.ToName, this.ToUserDescription);
    }
  }
  // start tokbox call guest user
  public StartCall(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio, isCallButtonEnabled?: boolean) {
    const IsCallInProgress = $('#ongoingCall').hasClass('active');
    if (isCallButtonEnabled && !IsCallInProgress) {
      this.ToInitial = ToInitial;
      this.ToName = ToName;
      this.ToUserId = ToUserId;
      this.ToUserPic = ToUserPic;
      this.ToUserDescription = ToDescription;
      const token = localStorage.getItem('frontend-token');
      this.IsAudio = IsAudio;
      if (token) {
        const UserModel = this._userService.decodeToken();
        if (UserModel && UserModel.user && !this.ShuoldAskGuestName) {
          this.isCallButtonEnabled = false;
          $('.loader-outer').css('display', 'inline-flex');
          this.GenerateSessionIdAndTokenId();
        } else {
          this.openUserDetail_Modal2();
        }
      } else {
        this.openUserDetail_Modal2();
      }
    } else {
      $('.loader-outer').css('display', 'none');
      this.toasting('You cannot proceed while a call is in-progress', 5000, 'Red');
    }
  }
  // show success/error messsage toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // open user detail modal
  openUserDetail_Modal2() {
    this.GuestUserNameModel = '';
    this.submitted = false;
    this.getLeadForm.reset();
    this.getLeadForm = new FormGroup({
      FirstName: new FormControl(''), // [Validators.required]),
      LastName: new FormControl(''), // [Validators.required]),
      Email: new FormControl(''), // [Validators.required, Validators.email]),
      Phone: new FormControl(''),
      AgencyId: new FormControl(this.AgencyId),
      LocationId: new FormControl(this.LocationId)
    });
    this.modalActions2.emit({ action: 'modal', params: ['open'] });
  }
  // close user detail modal
  closeUserDetail_Modal2() {
    this.getLeadForm.reset();
    this.modalActions2.emit({ action: 'modal', params: ['close'] });
  }
  // refresh tokbox online/offline users
  public RefreshList(UserId, ConnectionId, IsConnected) {
    this.refereshUserId = UserId;
    this.refereshConnectionId = ConnectionId;
    const that = this;
    let activeAgent = 0;

    if (ConnectionId && IsConnected) {
      const isExists = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId) > -1;
      if (!isExists) {
        this.ArrayOnlineTokboxuser.push({ UserId: UserId, ConnectionId: ConnectionId });
      }
    } else {
      const index = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId);
      if (index > -1) {
        this.ArrayOnlineTokboxuser.splice(index, 1);
      }
    }

    that.allUsers.forEach((item) => {
      const isAgentStillConnected = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == item.UserId) > -1;
      if (isAgentStillConnected) {// If USER IS ONLINE
        activeAgent++;
        item.ConnectionId = ConnectionId;
      } else {
        item.ConnectionId = '';
      }
    });
    this.onlineUsers = this.allUsers.filter(x => x.ConnectionId);
    that.onlineAgentCount = activeAgent;
  }
  // loads agents for call
  public loadAgents(RoleId, OffSet) {
    this.showAvailAgentsLoader = true;
    this._userService.getAllForUsers(RoleId, OffSet, this.globleDataSource['AgencyId'],
      this.globleDataSource['LocationId'], false)
      .subscribe(
        result => {
          if (result.Status) {
            this.allUsers = result.Data;
            this.onlineAgentCount = 0;
            this.allUsers.forEach((item) => {
              item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
              item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
              item.Name = `${item.FirstName} ${item.LastName}`;
              item.Initials = this.GetInitials(item.Name);
              item.CssClass = item.CssClass ? item.CssClass : this.GetClass();
              if (item.ConnectionId) {
                this.onlineAgentCount++;
              }
            });
            this.onlineUsers = this.allUsers.filter(x => x.ConnectionId);
          }
          this.showAvailAgentsLoader = false;
        },
        error => {
          this.showAvailAgentsLoader = false;
        }
      );

  }
  // get intial users
  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }
  // get active user class
  public GetClass() {
    if (this.Count === 1) {
      return 'iconUser noImg cyan lighten-3';
    } else if (this.Count === 2) {
      return 'iconUser noImg cyan lighten-5';
    } else if (this.Count === 3) {
      return 'iconUser noImg pink lighten-4';
    }
  }
  // get products of agency
  public GetProducts() {
    this.showProductsLoader = true;
    this._userService.getproducts(this.globleDataSource['AgencyId'], false)
      .subscribe(
        result => {
          if (result.Status) {
            this.products = result.Data;
          }
          this.showProductsLoader = false;
        },
        error => {
          this.showProductsLoader = false;
        }
      );
  }

  onBannerLoad() {
    this.isBannerLoaded = true;
  }

  hideAgentsBlock() {
    this.showAgentSideBar = false;
  }

  openAgentsBlock() {
    this.showAgentSideBar = true;
  }
  // check user call active status
  public LoginSignupClickHandler(route) {
    const IsCallInProgress = $('#ongoingCall').hasClass('active');
    if (IsCallInProgress) {
      // Cannot procced during the call
      this.toasting('You cannot proceed while a call is in-progress', 5000, 'Red');
    } else {
      this._router.navigate([route]);
    }
  }
}
