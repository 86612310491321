import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../model/user.service';
import { MaterializeAction } from 'angular2-materialize';
import { toast } from 'angular2-materialize';
declare const $: any;
@Component({
  selector: 'app-editlocation',
  templateUrl: './editlocation.component.html',
  styleUrls: ['./editlocation.component.css']
})
export class EditlocationComponent implements OnInit {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  _editForm: FormGroup;
  private _formErrors: any;
  modalActions1 = new EventEmitter<string | MaterializeAction>();
  strouteComponent = {
    'sublocality_level_1': 'long_name'
  };
  stateComponent = {
    administrative_area_level_1: 'long_name'
  };
  postalComponent = {
    postal_code: 'short_name'
  };
  cityComponent = {
    locality: 'long_name'
  };
  public place_id: string;
  public city: string;
  public state: string;
  public zipcode: string;
  public lat: any;
  public long: any;
  public street_name: string;
  public stroute_flag: any;
  public stroute: string;
  public AddressDescription: any;
  public AgencyCity: any;
  public Address: string;
  public AgencyZipCode: any;
  public AgencyState: any;
  Address1latitude: any;
  Address1longitude: any;
  CurrentSubsObj: any;
  Planid: string;
  AgencyId: Number;
  rowIndex = 0;
  UserId: number;
  UserName = '';
  MainDomain = '';
  NotcheckedExist: boolean;
  checkedExist = false;
  checkedNotExist: boolean;
  DomainNameFilled: number;
  IsDomainAvailble = false;
  IsLocationEdited = true;
  AgencySubscriptionId: string;
  Quantity = 1;
  PlanId: string;
  EditLocationId: number;
  ShowLoaderForm: boolean;
  AddressSettings: any = {
    inputPlaceholderText: 'Enter Address'
  };
  constructor(private _router: Router,
    private userService: UserService,
    private _formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private _userService: UserService,
    private route: ActivatedRoute) {
    this.NotcheckedExist = true;
    this.AddressSettings = Object.assign({}, this.AddressSettings);
    // get agency/admin details from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.AgencyId = UserModel.user.AgencyId;
      this.UserId =  UserModel.user.UserId;
    }
    this.route.params.subscribe(params => {
        if (params['id']) {
           this.EditLocationId = params['id'];
        }
    });
    this.rowIndex = 0;
    this.getEditLocationDetail();
  }
  // show success/error  message toasts
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  ngOnInit() {
    // edit location form
    this._editForm = this._formBuilder.group({
      'locationName': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      'address': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      'state': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      'city': ['', [Validators.required]],
      'zip': ['', [Validators.required]]
    });
  }
  // check form validation
  public _isValid(field): boolean {
    let isValid: boolean;
    if (this._editForm.controls[field].touched === false) {
      isValid = true;
    } else if (this._editForm.controls[field].touched === true && this._editForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }
  // get edit loation detail
  public getEditLocationDetail() {
    this.userService.GetEditLocationDetail(this.AgencyId, this.EditLocationId).
      subscribe(
        (response) => {
            if (response.Status && response.Data) {
              this._editForm.controls['address'].setValue(response.Data.Address1);
              this._editForm.controls['locationName'].setValue(response.Data.LocationName);
              this._editForm.controls['city'].setValue(response.Data.City);
              this._editForm.controls['state'].setValue(response.Data.State);
              this._editForm.controls['zip'].setValue(response.Data.ZipCode);
              this.place_id = response.Data.Address1PlaceId;
              this.Address1latitude = response.Data.Address1latitude;
              this.Address1longitude = response.Data.Address1longitude;
              setTimeout(() => {
                (<HTMLInputElement>document.getElementById('search_places')).value = response.Data.Address1;
                this._editForm.controls['address'].setErrors(null);
              }, 100);
            } else {
              this.toasting(response.Message, 2000, 'red');
            }
            this.ShowLoaderForm = true;
        }, (error) => {
          this.toasting(error.Message, 2000, 'red');
        });
  }
  // google auto complete  address
  autoCompleteCallback1(selectedData: any) {
    if (selectedData.data) {
      const place = selectedData.data;
      const name = place.name;
      let stroute_flag = 0;
      let stroute = '';
      let city_flag = 0;
      let state_flag = 0;
      let zip_flag = 0;
      this.AddressDescription = place.description;
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (this.strouteComponent[addressType]) {
          stroute_flag = 1;
          stroute = place.address_components[i][this.strouteComponent[addressType]];
        }
        if (this.cityComponent[addressType]) {
          city_flag = 1;
          this.city = place.address_components[i][this.cityComponent[addressType]];
        }
        if (this.stateComponent[addressType]) {
          state_flag = 1;
          this.state = place.address_components[i][this.stateComponent[addressType]];
        }
        if (this.postalComponent[addressType]) {
          zip_flag = 1;
          this.zipcode = place.address_components[i][this.postalComponent[addressType]];
        }
      }
      this._editForm.controls['address'].setValue(this.AddressDescription);
      this._editForm.controls['address'].setErrors(null);
      this._editForm.controls['city'].setValue(this.city);
      this._editForm.controls['state'].setValue(this.state);
      this._editForm.controls['zip'].setValue(this.zipcode);
      if (place && place.geometry && place.geometry.location) {
        this.place_id = place.place_id;
        this.lat = Number(place.geometry.location.lat);
        this.long = Number(place.geometry.location.lng);
        this.Address1latitude = this.lat.toFixed(3);
        this.Address1longitude = this.long.toFixed(3);
      }
    } else {
      this.Address1latitude = '';
      this.Address1longitude = '';
      this.toasting('Address not found. Please check your address.', 2000, 'red');
    }
  }

  get f() { return this._editForm.controls; }
  // update loation agency
  locationFormSubmit(formData: any) {
    const FormDataLocation = formData.value;
    let DomainName = '';
    if (!this.userService.IsLocalHost()) {
      DomainName = this.userService.getDomain();
    } else {
      DomainName = 'innovativeinsuranceprofessional.com';
    }
    this.IsLocationEdited = false;
    if (FormDataLocation.address && FormDataLocation.address !== '') {
      this.userService.EditLocation(this.AgencyId, this.EditLocationId, FormDataLocation.locationName,
        FormDataLocation.address, FormDataLocation.city, FormDataLocation.state, FormDataLocation.zip,
        this.place_id, this.Address1latitude, this.Address1longitude)
      .subscribe((response) => {
        if (response.Status) {
          this.toasting('Location Updated Successfully.', 2000, 'green');
          this.OnRedirect();
        } else {
          this.toasting(response.Message, 2000, 'red');
        }
        this.IsLocationEdited = true;
      }, (error) => {
        this.toasting(error.Message, 2000, 'red');
        this.IsLocationEdited = true;
      });
    } else {
      this.toasting('Please enter location address.', 2000, 'red');
    }
  }
  // redirect user after update location
  OnRedirect() {
    this._router.navigate(['/agency/locations']);
  }
  // stop form submit press enter
  public handleEnterKeyPress(event) {
    return false;
  }
  // detect address change
  public ChangeAddress(event) {
    const LocationAddress = $('#search_places').val();
    if (LocationAddress && LocationAddress.length > 2) {
      this._editForm.controls['address'].setValue(LocationAddress);
      this._editForm.controls['address'].setErrors(null);
    } else {
      this._editForm.controls['address'].setValue('');
    }
  }
}
