import { Component } from '@angular/core';
import { EventBus } from '../shared/EventBus/EventBus';

@Component({
  templateUrl: './step_1.component.html'
})
export class StepFirstComponent {
  constructor(private _eventBus: EventBus) {
    this._eventBus.emit({ Type: 'show-authorized-header', data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
  }
}
