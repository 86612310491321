import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { UserService } from '../../model/user.service';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';

@Component({
    templateUrl: './agencyadminsettings.component.html'

})

export class AgencyAdminSettingsComponent implements OnInit {
    @ViewChild('addagencymatrixagency') addagencymatrixagency: any;

    constructor(private _userService: UserService, private _eventBus: EventBus) {
    }

    ngOnInit(): void {
    }

}
