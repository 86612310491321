import { Component, OnInit, EventEmitter } from '@angular/core';
import { UserService } from '../../../model/user.service';
import { MaterializeAction, toast } from 'angular2-materialize';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventBus } from '../../../shared/EventBus/EventBus';
@Component({
  selector: 'app-managelocations',
  templateUrl: './managelocations.component.html',
  styleUrls: ['./managelocations.component.css']
})
export class ManagelocationsComponent implements OnInit {
  AllLocations = [];
  AgencyMainSiteAddress: string;
  Domain: string;
  SiteAddress: string;
  ShowLoaderForm = true;
  modelConfirmLocationParam: any;
  modalConfirmLocationActions = new EventEmitter<string | MaterializeAction>();
  ConfirmLocationForm: FormGroup;
  AgencyId = 0;
  DeletedLocationId: number;
  MovedLocationArray = [];
  IsLocationMoved = true;
  public config = {
      displayKey: 'name',
      search: false,
      limitTo: 3,
      height: 'auto',
      placeholder: 'Select Location'
  };
  public singleSelect: any = [];
  options: any = [];
  constructor(private _userService: UserService, private _formBuilder: FormBuilder,
              private _EventBus: EventBus) {
    // get agency/admin detail from token
    const UserModel = this._userService.decodeToken();
    if (UserModel && UserModel.user)  {
      this.AgencyMainSiteAddress = UserModel.user.SiteAddress;
      this.SiteAddress = UserModel.user.SiteAddress;
      this.AgencyId = UserModel.user.AgencyId;
      if (this._userService.IsLocalHost()) {
        this.Domain = 'innovativeinsuranceprofessional.com';
      } else {
        this.Domain = this._userService.getDomain();
      }
      this.GetAllAgencyLocations();
      this.ConfirmLocationForm = this._formBuilder.group({
          'moveLocation': ['0', Validators.required],
          'Location': ['']
      });
    }
  }
  ngOnInit() {
    this.setLocationValidators();
  }
  // get all location of agency
  public GetAllAgencyLocations() {
    this.ShowLoaderForm = false;
    this._userService.GetAgencyAllLocations(this.AgencyId)
    .subscribe((response) => {
        if (response.Status) {
          this.AllLocations = response.Data;
          const LocationsShown = [];
          if (this.AllLocations && this.AllLocations.length > 0) {
              this.AllLocations.forEach((item, key) => {
                  LocationsShown.push({_id: item.LocationId, name: item.LocationName, index: key});
              });
          }
          this.options = LocationsShown;
          this.config.limitTo = this.options.length;
          setTimeout(() => {
            this.singleSelect = this.options[0];
          }, 100);
        } else {
        this.toasting(response.Message, 2000, 'red');
        }
        this.ShowLoaderForm = true;
    }, (error) => {
      this.toasting(error.Message, 2000, 'red');
    });
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // close delete location confirmation modal
  public CancelConfirmLocationModel() {
      this.ConfirmLocationForm.reset();
      this.modalConfirmLocationActions.emit({ action: 'modal', params: ['close'] });
  }
  // set value delete loaction
  public DeleteConfirmModal(LocationId) {
    if (this.AllLocations.length > 1) {
      this.MovedLocationArray = this.AllLocations.filter((LocationObj) => LocationObj.LocationId !== LocationId);
      const LocationsShown = [];
      if (this.MovedLocationArray && this.MovedLocationArray.length > 0) {
          this.MovedLocationArray.forEach((item, key) => {
              LocationsShown.push({_id: item.LocationId, name: item.LocationName, index: key});
          });
      }
      this.options = LocationsShown;
      this.config.limitTo = this.options.length;
      this.DeletedLocationId = LocationId;
      this.modalConfirmLocationActions.emit({ action: 'modal', params: ['open'] });
    } else {
      this.toasting('You can not delete last location.', 2000, 'red');
    }
  }
  // delete location api
  public DeleteLocationAgency(formValue) {
    this.IsLocationMoved = false;
    this.ShowLoaderForm = false;
    if (formValue.moveLocation) {
      formValue.Location = formValue.Location._id;
    } else {
      formValue.Location = 0;
    }
    this._userService.DeleteLocation(this.AgencyId, this.DeletedLocationId, formValue.moveLocation, formValue.Location)
      .subscribe((response) => {
          if (response.Status) {
            this._EventBus.emit({ Type: 'locationAddedDeleted', data: { delete: true, LocationId : this.DeletedLocationId } });
            this.toasting('Location Deleted sucessfully!', 2000, 'green');
            this.GetAllAgencyLocations();
            this.CancelConfirmLocationModel();
          } else {
            this.toasting(response.Message, 2000, 'red');
          }
          this.IsLocationMoved = true;
          this.ShowLoaderForm = true;
      }, (error) => {
          this.toasting(error.Message, 2000, 'red');
          this.IsLocationMoved = true;
          this.ShowLoaderForm = true;
      });
  }
  // set loation validation based on Move Location yes/no
  setLocationValidators() {
    const LocationControl = this.ConfirmLocationForm.get('Location');
    this.ConfirmLocationForm.get('moveLocation').valueChanges
      .subscribe(moveLocation => {
        if (moveLocation === '0') {
          LocationControl.setValue(0);
          LocationControl.setValidators(null);
        } else if (moveLocation === '1') {
          LocationControl.setValidators([Validators.required]);
        }
        LocationControl.updateValueAndValidity();
      });
  }
  // change location set value
  public ChangeLocation(event) {
    this.ConfirmLocationForm.controls['Location'].setValue(event.value);
    this.ConfirmLocationForm.controls['Location'].setErrors(null);
  }
}
