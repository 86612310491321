import { Component, ElementRef, ViewChild, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../model/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { toast } from 'angular2-materialize';
import { EventBus } from '../shared/EventBus/EventBus';
import { GlobalService } from '../model/global.service';
import { Meta } from '@angular/platform-browser';

const CountryCodes = require('../helper/CountryCode.json');

declare var $: any;

@Component({
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit, OnDestroy {
  @ViewChild('video') private video: ElementRef;
  IsMute: boolean;
  ShowLoader: boolean;
  agencyToken: string;
  appCheckNames: string[];
  _signupForm: FormGroup;
  private _formErrors: any;
  public SelectedCountryFlag: string;
  private _submitted: boolean;
  private _errorMessage: string;
  public _countryCode: any[];
  _agencylocations: any[];
  public showPassword: boolean;
  public showCPassword: boolean;

  agencyName: string;
  agencyLogo: string;

  public IsValidLength: boolean;
  public IsStrong: boolean;
  IsValidCode: boolean;
  public IsMatch: boolean;
  private SubDomainValue: string;
  private ReferalBelongSameAgency = true;
  globleDataSource: any;
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private _agenctobj: any = new Object();
  IsLocalhost: boolean;

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params['id'] !== undefined) {
        this.agencyToken = params['id'];
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'agent_alive_bg');
  }

  constructor(private _formBuilder: FormBuilder, private _route: ActivatedRoute,
    private globalService: GlobalService, private meta: Meta,
    private _userService: UserService, private renderer: Renderer2, private _eventBus: EventBus,
    private _router: Router) {
    this._agenctobj.Logo = '../assets/images/agent_alive_logo.png';
    this.IsLocalhost = _userService.IsLocalHost();
    if (!this.IsLocalhost) {
      this.CheckAgency(this._userService.getSubdomain());
    }
    this.GetAgencyLocation();

    // this.renderer.addClass(document.body, 'agent_alive_bg');
    this.globleDataSource = this.globalService.globleDataSource;
    this.agencyLogo = this.globleDataSource['AgencyLogo'];
    this.agencyName = this.globleDataSource['AgencyName'];
    this.meta.addTag({ property: 'og:title', content: this.globleDataSource['AgencyName'] });
    this.meta.addTag({ property: 'og:description', content: `${this.globleDataSource['AgencyName']} - Connect in our virtual office` });
    this.meta.addTag({ property: 'og:type', content: 'agency_page' });
    this.meta.addTag({ property: 'og:url', content: `https://${this.globleDataSource['SiteAddress']}.agentalive.tech/` });
    this.meta.addTag({ property: 'og:image', content: `${this.globleDataSource['AgencyLogo']}` });
    this.meta.addTag({ property: 'og:site_name', content: this.globleDataSource['AgencyName'] });

    this._countryCode = CountryCodes;
    this.SelectedCountryFlag = 'US.png';
    const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
    const passwordConfirm = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));
    // signup form user
    this._signupForm = _formBuilder.group({
      agencyname: ['Site Address', Validators.compose([Validators.required])],
      FirstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
      LastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
      email: ['', Validators.compose([Validators.required, CustomValidators.email])],
      SelectedCountryCode: ['US'],
      phone: ['', Validators.max(15)],
      password: password,
      confirmpassword: passwordConfirm,
      selectedLocationId: ['1'],
      refreralcode: [''],
      isagreed: [false, Validators.required]
    });
    this._signupForm.controls['SelectedCountryCode'].setValue('US', { onlySelf: true });


    this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // get agency location
  public GetAgencyLocation() {
    this._userService.GetAgencyLocations().subscribe(
      result => {
        if (result.Status) {
          result.Data.forEach(element => {
            if (element.AgencyId === this._agenctobj.Id) {
              this._agencylocations = element.AgencyLocations;
            }
          });

          if (this._agencylocations && this._agencylocations[0]) {
            this._signupForm.controls['selectedLocationId'].setValue(this._agencylocations[0].LocationId);
          }
          this._userService.setTitle(this._agenctobj.AgencyName);
        } else {
          this._agenctobj.Logo = '../assets/images/referFriend.png';
          this._userService.setTitle('Agent Alive');
          this.toasting(result.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      },
      error => {
        // Validation error
        if (error.status === 422) {
          this._resetFormErrors();
          const errorFields = JSON.parse(error.data.message);
          this._setFormErrors(errorFields);
        } else {
          this._errorMessage = JSON.stringify(error.Data[0]);
        }
        this.ShowLoader = false;
      }
    );
  }
  // check referal code agency
  public CheckReferalCodeAgency(ReferalCodeValue) {
    if (ReferalCodeValue.trim().length === 0) {
      this.ShowLoader = false;
      this.IsValidCode = false;
      this.ReferalBelongSameAgency = true;
    } else {
      this.ShowLoader = true;
      if (!this.IsLocalhost) {
        this.SubDomainValue = this._userService.getSubdomain();
      }
      if (ReferalCodeValue) {
        if (this.SubDomainValue) {
          this._userService.CheckAgencyReferralCode(this.SubDomainValue, ReferalCodeValue)
            .subscribe(
              result => {
                if (result.Status === 1) {
                  this.IsValidCode = false;
                  this.ReferalBelongSameAgency = true;
                } else {
                  this.IsValidCode = true;
                  this.ReferalBelongSameAgency = false;
                }
                this.ShowLoader = false;
              },
              error => {
                // Validation error
                if (error.status === 422) {
                  this._resetFormErrors();
                  const errorFields = JSON.parse(error.data.message);
                  this._setFormErrors(errorFields);
                } else {
                  this._errorMessage = JSON.stringify(error.Data[0]);
                }
                this.ShowLoader = false;
              }
            );

        }
      }

    }
  }
  // check agency exists or not
  public CheckAgency(agencyname) {
    this.SubDomainValue = agencyname;
    this._userService.GetAgency(agencyname)
      .subscribe(
        result => {
          if (result.Status) {
            this._agenctobj = result.Data[0];
            this._userService.setTitle(this._agenctobj.AgencyName);

            this.GetAgencyLocation();
          } else {
            this._agenctobj.Logo = '../assets/images/referFriend.png';
            this._userService.setTitle('Agent Alive');
            this.toasting(result.Message, 2000, 'red');
          }
          this.ShowLoader = false;
        },
        error => {
          // Validation error
          if (error.status === 422) {
            this._resetFormErrors();
            const errorFields = JSON.parse(error.data.message);
            this._setFormErrors(errorFields);
          } else {
            this._errorMessage = JSON.stringify(error.Data[0]);
          }
          this.ShowLoader = false;
        }
      );
  }
  // set form errors
  private _setFormErrors(errorFields: any): void {
    for (const key in errorFields) {
      // skip loop if the property is from prototype
      if (!errorFields.hasOwnProperty(key)) { continue; }
      const message = errorFields[key];
      this._formErrors[key].valid = false;
      this._formErrors[key].message = message;
    }
  }
  // reset form errors
  private _resetFormErrors(): void {
    this._formErrors = {
      name: { valid: true, message: '' },
      email: { valid: true, message: '' },
      phone: { valid: true, message: '' },
      refreralcode: { valid: true, message: '' },
      password: { valid: true, message: '' },
      confirmpassword: { valid: true, message: '' }
    };
  }

  public onChangeCode(event) {
    this.SelectedCountryFlag = event.value + '.png';
  }
  // on value change reset form errors
  public onValueChanged(data?: any) {
    if (!this._signupForm) { return; }
    const form = this._signupForm;
    for (const field in this._formErrors) {
      // clear previous error message (if any)
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }
  // check if form valid or not
  public _isValid(field): boolean {
    let isValid: boolean;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._signupForm.controls[field].touched === false) {
      isValid = true;
    } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }

  public muteVideo() {
    if (this.video.nativeElement.volume !== 0) {
      this.video.nativeElement.volume = 0;
      this.IsMute = true;
    } else {
      this.video.nativeElement.volume = 1;
      this.IsMute = false;
    }
  }
  // register user agency
  public onSubmit(elementValues: any) {

    this.ShowLoader = true;
    if (this._agenctobj.Id !== undefined && this._agenctobj.Id !== 0) {
      this._countryCode.forEach(function (item) {
        if (item.code === elementValues.SelectedCountryCode) {
          elementValues.SelectedCountryCode = item.dial_code;
        }
      });
      elementValues.phone = elementValues.phone.replace(/\D+/g, '');
      if (!this.agencyToken) {
        this.agencyToken = '';
      }

      this._userService.Register(elementValues.FirstName, elementValues.LastName,
        elementValues.email, elementValues.SelectedCountryCode,
        elementValues.phone, elementValues.password,
        elementValues.refreralcode, this._agenctobj.Id,
        this._agenctobj.LocationId, this.agencyToken)
        .subscribe(
          result => {
            if (result.Status) {
              this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
              window['getFirebaseToken']();
              if (this.agencyToken) {
                this._router.navigate(['agent/dashboard']);
              } else {
                this._router.navigate(['user/dashboard']);
              }

            } else {
              this.toasting(result.Message, 2000, 'red');
              this._errorMessage = result.Message;
            }
            this.ShowLoader = false;
          },
          error => {
            // Validation error
            if (error.status === 422) {
              this._resetFormErrors();
              const errorFields = error.Data;
              this._setFormErrors(errorFields);
            } else {
              this._errorMessage = JSON.stringify(error.Data[0]);
            }
            this.ShowLoader = false;
          }
        );
    } else {
      this.ShowLoader = false;
      this.toasting('Company Not Found!', 2000, 'red');
    }
  }
  // match both password
  CheckConform(Pass, ConfirmPass) {
    if (ConfirmPass.length > 0) {
      if (Pass !== ConfirmPass) {
        this.IsMatch = true;
      } else {
        this.IsMatch = false;
      }
    }
  }
  // check password regex pattern
  CheckPass(data) {
    if (data.value.length > 0) {

      if (data.value.length < 6) {
        this.IsValidLength = true;
      } else {
        this.IsValidLength = false;
        const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
        if (mediumRegex.test(data.value)) {
          this.IsStrong = false;
        } else {
          this.IsStrong = true;
        }
      }
    }

  }
}
