import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from 'ng2-translate';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';
import { MaterializeAction, toast } from 'angular2-materialize';
import { CustomValidators } from '../../helper/custom-validators';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
declare var $: any;

@Component({
    templateUrl: './agencyadmindashboardagent.component.html'
})

export class AgencyAdminDashboardAgentComponent implements OnInit {
    SubdomainName: string;
    IsResendShow: boolean;
    _signupForm: FormGroup;
    _updateForm: FormGroup;
    IpAddress: any;
    _deleteForm: FormGroup;
    private _formErrors: any;
    private _UpdateformErrors: any;
    users: User[] = [];
    SaveEmailArray: any[] = [];
    _Locations: any = [];
    updateFirstName: string;
    updateLastName: string;
    updateDescription: string;
    updateEmail: string;
    deletefirstName: string;
    deletelastName: string;
    deleteemail: string;
    IsDisbaledDeleteBox: boolean;
    udpateUserId: number;
    errorMessage: any;
    deleteUserId: number;
    Token: string;
    filter: any;
    SubDomain: string;
    Domain: string;
    ShowLoader: boolean;
    ShowLoaderForm: boolean;
    IsEmailExist: boolean;
    UserLoading: boolean;
    LoggedInUserName: string;
    AgencyName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    TotalAgent: string;
    // filter: any;
    model1Params: any;
    Count: number;
    model3Params: any;
    model2Params: any;
    LoginUserId: number;
    AgencyId: number;
    LocationId: number;
    LanguageFlag: string;
    LoadMoreLoader: boolean;
    LoadMoreCounter: number;
    previousUrl: string;
    TimeZone: string;
    NotPaymentFound: boolean;
    AgentModel: any;
    opensearch = false;
    modalActions1 = new EventEmitter<string | MaterializeAction>();
    modalActions2 = new EventEmitter<string | MaterializeAction>();
    modalActions3 = new EventEmitter<string | MaterializeAction>();
    modalActions4 = new EventEmitter<string | MaterializeAction>();
    modalActions5 = new EventEmitter<string | MaterializeAction>();
    globalActions = new EventEmitter<string | MaterializeAction>();
    modalActionsAddProduct = new EventEmitter<string | MaterializeAction>();
    params = [];
    selectedLocations: any = [];
    key: string;
    reverse: boolean;
    CurrentSubsObj: any;
    searchText = '';
    SortKey = 'FirstName';
    SortOrder = 'ASC';
    noLocation: boolean;
    updateSelectedLocations: any[];
    noLocationUpdate: boolean;
    TotalAgentStatus: boolean;
    cities = [];
    selectedItems = [];
    dropdownSettings: any = {};
    LoadMoreAgents = true;
    ngOnInit(): void {
        this.key = 'Name';
        this.IsDisbaledDeleteBox = true;
        if (this.userService.decodeToken().user.RoleId !== 6) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
        }
        this.Domain = this._userService.getDomain();
        this.IsResendShow = false;
        this.selectedItems = [{ LocationId: 611, LocationName: 'ash' }];
        setTimeout(function () {
            $(document).ready(function () { $('ul.tabs').tabs(); });
            // disable outside close modal
            $('#inviteAgent').modal({ dismissible: false });
            $('#editAgent').modal({ dismissible: false });
            $('#inviteAgent').modal({ dismissible: false });
            $('#deleteAgent').modal({ dismissible: false });
        }, 400);
       this.dropdownSettings = {
            singleSelection: false,
            idField: 'LocationId',
            textField: 'LocationName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: false
        };
        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
        this.GetSubscriptionDetail();

    }

    constructor(private userService: UserService,
        private http: HttpClient,
        private _formBuilder: FormBuilder,
        private _userService: UserService,
        private tokboxService: TokboxService,
        private route: ActivatedRoute,
        private _router: Router,
        public translate: TranslateService,
        private _EventBus: EventBus) {
        this.LoadMoreCounter = 0;
        this.Count = 1;
        this.NotPaymentFound = false;
        // get Agency/Admin detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.AgencyName = UserModel.user.AgencyName;
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoginUserId = UserModel.user.UserId;
            this.AgencyId = UserModel.user.AgencyId;
            this.SubdomainName = this.userService.getSubdomain();
            if (this.userService.IsLocalHost()) {
                this.SubdomainName = UserModel.user.SiteAddress;
            }
            if (this._userService.GetSelectedLocation() !== 0) {
                this.LocationId = Number(this._userService.GetSelectedLocation());
            } else {
                this.LocationId = UserModel.user.LocationId;
            }
        }
        this.GetLocations();
        this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
        // invite agent form
        this._signupForm = _formBuilder.group({
            firstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            lastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            email: ['', Validators.compose([Validators.required, CustomValidators.email])],
            Description: ['', Validators.compose([Validators.maxLength(500)])],
            AgentLocations: ['', Validators.required]
        });
        this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
        // update agent form
        this._updateForm = _formBuilder.group({
            updatefirstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            updatelastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            updateemail: ['', Validators.compose([Validators.required, CustomValidators.email])],
            updateDescription: ['', Validators.compose([Validators.maxLength(500)])],
            updateAgentLocations: ['', Validators.required]
        });
        // delete agent form
        this._deleteForm = _formBuilder.group({
            deletefirstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            deletelastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            deleteemail: ['', Validators.compose([Validators.required, CustomValidators.email])],
            deleteDescription: ['', Validators.compose([Validators.maxLength(500)])]
        });

        this._updateForm.valueChanges.subscribe(data => this.onValueChangedUpdate(data));
        this._deleteForm.valueChanges.subscribe(data => this.onValueChangedUpdate(data));

        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'locationChanged') {
                this.TotalAgent = '0';
                this.LocationId = Number(m.filterBy.data.LocationId);
                this.loadAllUser(this.LoggedInUserRoleId, 0, this.searchText);
            }
        });

    }
    // get agency subscription detail
    public GetSubscriptionDetail() {
        this.userService.GetSubscriptionDetail(this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.CurrentSubsObj = result.Data;
                        if (this.CurrentSubsObj.SubscriptionPeriodEnd < Math.floor(Date.now() / 1000)) {
                            this._router.navigate(['/agency/subscriptioncancel']);
                        }
                    }
               },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }
    // value change subscriber function add error and message
    public onValueChanged(data?: any) {
        if (!this._signupForm) { return; }
        const form = this._signupForm;
        for (const field in this._formErrors) {
            // clear previous error message (if any)
            if (this._formErrors.hasOwnProperty(field)) {
                const control = form.get(field);
                if (control && control.dirty) {
                    this._formErrors[field].valid = true;
                    this._formErrors[field].message = '';
                }
            }
        }
    }
    // on value change subscriber function add error and messages
    public onValueChangedUpdate(data?: any) {
        if (!this._updateForm) { return; }
        const form = this._updateForm;
        for (const field in this._UpdateformErrors) {
            // clear previous error message (if any)
            if (this._UpdateformErrors.hasOwnProperty(field)) {
                const control = form.get(field);
                if (control && control.dirty) {
                    this._UpdateformErrors[field].valid = true;
                    this._UpdateformErrors[field].message = '';
                }
            }
        }
    }
    // server sort by column ASC/DESC
    ServerSort(sortKey: string) {
        this.LoadMoreCounter = 0;
        if (this.SortKey === sortKey) {
            this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
            this.SortKey = sortKey;
        } else {
            this.SortOrder = 'ASC';
            this.SortKey = sortKey;
        }
        this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
    }
    // check input valid and class accordingly invite agent
    public _isValid(field): boolean {
        let isValid: boolean;
        // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
        if (this._signupForm.controls[field].touched === false) {
            isValid = true;
        } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
            isValid = true;
        }
        return isValid;
    }
    // check input valid and class accordingly update agent
    public _isUpdateValid(field): boolean {
        let isValid: boolean;
        // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
        if (this._updateForm.controls[field].touched === false) {
            isValid = true;
        } else if (this._updateForm.controls[field].touched === true && this._updateForm.controls[field].valid === true) {
            isValid = true;
        }
        return isValid;
    }
    // show success/error message toast
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // invite agent api request
    public onSubmit(elementValues: any) {
        this.ShowLoader = true;
        const that = this;
        const isValid = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(elementValues.email);
        if (!isValid) {
            that.toasting('Please fill Valid Email Address', 2000, 'red');
            this.ShowLoader = false;
            this.IsEmailExist = true;
            event.preventDefault();
            return false;
        } else {
            this._userService.CheckEmail(elementValues.email.trim())
                .subscribe(
                    result => {
                        if (result.Data[0].ResStatus === 2) {
                            that.toasting('An account with this email already exists in our system', 2000, 'red');
                            that.ShowLoader = false;
                            event.preventDefault();
                            return false;
                        } else {
                            let MyFName: string;
                            let MyLName: string;
                            let MyEmail: string;
                            let MyDescription: string;
                            MyFName = elementValues.firstName;
                            MyLName = elementValues.lastName;
                            MyEmail = elementValues.email;
                            MyDescription = elementValues.Description;
                            const selectedLocationsInvite = [];
                            if (elementValues.AgentLocations && elementValues.AgentLocations.length > 0) {
                                elementValues.AgentLocations.forEach((item) => {
                                    selectedLocationsInvite.push(item.LocationId);
                                });
                            }
                            const itm = { 'Fname': MyFName, 'lname': MyLName, 'Email': MyEmail, 'Description': MyDescription };
                            that.SaveEmailArray.push(itm);
                            const dataAgent = JSON.stringify(that.SaveEmailArray);
                            let DomainName = this._userService.getDomain();
                            if (this._userService.IsLocalHost()) {
                                DomainName = 'innovativeinsuranceprofessional.com';
                            }
                            that._userService.InviteAgent(that.AgencyId, dataAgent,
                                that.SubdomainName, that.LocationId, DomainName, 2, selectedLocationsInvite, 1)
                                .subscribe(
                                    agentresult => {
                                        if (agentresult.Status) {
                                            that.SaveEmailArray = [];
                                            this.closeAddAgent();
                                            this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
                                            this.ShowLoader = false;
                                        } else {
                                            that.toasting('Some Error occur while adding records,Please try again ', 2000, 'red');
                                            that.SaveEmailArray = [];
                                            this.ShowLoader = false;
                                            return false;
                                        }
                                    },
                                    error => {
                                        if (error.status === 422) {
                                            const errorFields = JSON.parse(error.data.message);
                                            that.SaveEmailArray = [];
                                            this.ShowLoader = false;
                                        } else {

                                            that.toasting('Some Error occur while adding records ', 2000, 'red');
                                            this.ShowLoader = false;
                                            that.SaveEmailArray = [];
                                            return false;
                                        }
                                    });
                        }
                    },
                    error => {
                        if (error.status === 422) {
                            const errorFields = JSON.parse(error.data.message);
                            that.SaveEmailArray = [];
                        } else {
                            that.ShowLoader = false;
                            that.toasting('Some Error occur while adding records ', 2000, 'red');
                            that.SaveEmailArray = [];
                            return false;
                        }
                    });
        }
    }
    // on scroll load agents
    public onScrollDown() {
        if (this.users.length % 50 === 0 && this.LoadMoreAgents === true) {
            this.LoadMoreCounter = this.LoadMoreCounter + 50;
            this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
        }
    }
    // get ip address agency admin
    public getIP() {

        this.userService.getIP().subscribe(
            IPDetails => this.IpAddress = IPDetails.ip,
            error => this.errorMessage = <any>error
        );
    }
    // load all agents
    public loadAllUser(RoleId, OffSet, Search) {
        if (this.LoadMoreAgents === true || this.LoadMoreAgents !== Search) {
            this.LoadMoreAgents = false;
            const that = this;
            if (OffSet === 0) { that.UserLoading = true; }
            if (!this.LocationId) { this.LocationId = 0; }
            this.TotalAgentStatus = false;
            this.userService.getAll(RoleId, OffSet, this.AgencyId, this.LocationId, false, Search, this.SortKey, this.SortOrder)
            .subscribe(result => {
                that.LoadMoreLoader = true;
                if (result.Status) {
                    if (OffSet === 0) {
                        that.users = result.Data;
                        that.TotalAgentStatus = that.users.length > 0 ? false : true;
                    } else {
                        if (result.Data) {
                            result.Data.forEach(function (item) {
                                that.users.push(item);
                            });
                        }
                    }
                    that.users.forEach(function (item) {
                        item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
                        item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
                        item.Name = `${item.FirstName} ${item.LastName}`;
                        item.Initials = that.GetInitials(item.Name);
                        item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                        item.Description = (item.Description !== '') ? item.Description : '';
                        let localTime: any = moment.utc(item.ModifiedDateTime, 'DD-MM-YYYY HH:mm:ss [UTC]').toDate();
                        localTime = moment(localTime);
                        item.ModifiedDateTime = localTime;
                    });
                    if (that.users.length) {
                        that.TotalAgent = that.users[0].UserCounts.toString();
                    }
                } else {
                    if (OffSet === 0) { that.NotPaymentFound = true;  that.TotalAgentStatus = true; that.TotalAgent = '0'; }
                }
                that.UserLoading = false;
                that.LoadMoreLoader = false;
                that.LoadMoreAgents = true;
            }, error => {
                that.UserLoading = false;
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    that._router.navigate(['/login']);
                }
                that.LoadMoreAgents = true;
            });
        }
    }
    // get agent intial by their name
    public GetInitials(Name) {
        if (this.Count >= 3) {
            this.Count = 1;
        } else {
            this.Count = this.Count + 1;
        }
        const splittedName = Name.split(' ', 3);
        if (splittedName.length > 1) {
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
        } else {
            return splittedName[0].charAt(0);
        }
    }
    // get class for agent listing
    public GetClass() {
        if (this.Count === 1) {
            return 'iconUser noImg cyan lighten-3';
        } else if (this.Count === 2) {
            return 'iconUser noImg cyan lighten-5';
        } else if (this.Count === 3) {
            return 'iconUser noImg pink lighten-4';
        }
    }
    // open add agent modal
    openAddAgent() {
        if (this._Locations) {
            const DefaultLocation = [];
            DefaultLocation.push(this._Locations[0].LocationId);
            this.selectedLocations = DefaultLocation;
        }
        if (this.CurrentSubsObj.Agent > this.users.length) {
            this.modalActions1.emit({ action: 'modal', params: ['open'] });
        } else {
            // redirect to subscription
            this.modalActions5.emit({ action: 'modal', params: ['open'] });
        }
    }
    // open upgade plan page
    upgradePlan() {
        this.modalActions5.emit({ action: 'modal', params: ['close'] });
        this._router.navigate(['/agency/subscriptions']);
    }
    // close invite agent modal
    closeAddAgent() {
        this._signupForm.reset();
        this.modalActions1.emit({ action: 'modal', params: ['close'] });
    }
    // edit agent modal detail
    editAgent(AgentModel: any) {
        const FullName: any[] = AgentModel.Name.split(' ');
        this.updateFirstName = AgentModel.FirstName;
        this.updateLastName = AgentModel.LastName;
        this.updateEmail = AgentModel.Email;
        this.updateDescription = AgentModel.Description;
        this.udpateUserId = AgentModel.UserId;
        this.Token = AgentModel.Token;
        this.SubDomain = AgentModel.SubDomain;
        this.IsResendShow = AgentModel.IsActive === true ? false : true;
        this.modalActions2.emit({ action: 'modal', params: ['open'] });
        this._updateForm.controls['updateAgentLocations'].setValue(null);
        const updateSelectedLocations = (AgentModel.LocationIds) ? AgentModel.LocationIds.split(' ,') : [];
        const SelectedLocations = [];
        updateSelectedLocations.forEach((item) => {
            const SelectedLocationIndex = this._Locations.findIndex( loc => loc.LocationId === Number(item));
            SelectedLocations.push({LocationId: Number(item), LocationName: this._Locations[SelectedLocationIndex].LocationName});
        });
        this._updateForm.controls['updateAgentLocations'].setValue(SelectedLocations);
    }
    // delete agent modal user detail
    deleteAgent(AgentModel: any) {
        const FullName: any[] = AgentModel.Name.split(' ');
        this.deletefirstName = FullName[0];
        this.deletelastName = FullName[1];
        this.deleteemail = AgentModel.Email;
        this.deleteUserId = AgentModel.UserId;
        this.modalActions3.emit({ action: 'modal', params: ['open'] });
    }
    // delete agent agency
    deleteAgentData() {
        this.ShowLoaderForm = true;
        const that = this;
        this.userService.deleteUser(that.deleteUserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        setTimeout(() => {
                            this.modalActions3.emit({ action: 'modal', params: ['close'] });
                            this.toasting('Profile deleted successfully!', 2000, 'green');
                            this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
                            this.ShowLoaderForm = false;
                        }, 1000);
                    } else {
                        this.toasting(result.Message, 2000, 'red');
                        this.ShowLoaderForm = false;
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        this.ShowLoaderForm = false;
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }
    // update agent detail and resend invite
    UpdateAgent(elementValues: any, Status) {
        this.ShowLoaderForm = true;
        const that = this;
        const selectedLocationsUpdate = [];
        if (elementValues.updateAgentLocations && elementValues.updateAgentLocations.length > 0) {
            elementValues.updateAgentLocations.forEach((item) => {
                selectedLocationsUpdate.push(item.LocationId);
            });
        }
        if (Status === 'Update') {
            this.userService.UpdateUser(elementValues.updateemail.trim(),
                elementValues.updatefirstName.trim(), elementValues.updatelastName.trim(), that.udpateUserId, that.AgencyId,
                elementValues.updateDescription, selectedLocationsUpdate)
                .subscribe(
                    result => {
                        if (result.Status) {
                            setTimeout(() => {
                                this.modalActions2.emit({ action: 'modal', params: ['close'] });
                                this.toasting('Profile updated successfully!', 2000, 'green');
                                this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
                                this.ShowLoaderForm = false;
                            }, 1000);
                        } else {
                            this.toasting(result.Message, 2000, 'red');
                            this.ShowLoaderForm = false;
                        }
                    },
                    error => {
                        if (error.Message === 'Token expired') {
                            this.ShowLoaderForm = false;
                            localStorage.removeItem('frontend-token');
                            localStorage.removeItem('StripeCust');
                            this._router.navigate(['/login']);
                        }
                    }
                );
        } else {
            that.Domain = this._userService.getDomain();
            if (that.userService.IsLocalHost()) {
                that.Domain = 'innovativeinsuranceprofessional.com';
            }
            this.userService.UpdateUserAndResend(this.AgencyId, that.udpateUserId,
                that.Token, this.SubDomain, this.Domain, elementValues.updateemail.trim(),
                elementValues.updatefirstName.trim(), elementValues.updatelastName.trim(),
                elementValues.updateDescription.trim(), selectedLocationsUpdate)
                .subscribe(
                    result => {
                        if (result.Status) {
                            setTimeout(() => {
                                this.toasting('Profile updated successfully!', 2000, 'green');
                                this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
                                this.modalActions2.emit({ action: 'modal', params: ['close'] });
                                this.ShowLoaderForm = false;
                            }, 1000);
                        } else {
                            this.toasting(result.Message, 2000, 'red');
                            this.ShowLoaderForm = false;
                        }
                    },
                    error => {
                        if (error.Message === 'Token expired') {
                            this.ShowLoaderForm = false;
                            localStorage.removeItem('frontend-token');
                            localStorage.removeItem('StripeCust');
                            this._router.navigate(['/login']);
                        }
                    }
                );
        }
    }
    // close edit agent modal
    CancelModel() {
        this.modalActions2.emit({ action: 'modal', params: ['close'] });
    }
    // close delete agent modal
    CancelDeleteModel() {
        this.modalActions3.emit({ action: 'modal', params: ['close'] });
    }
    // resend agent invite
    resendInvite(AgentModel: any) {
        this.ShowLoader = true;
        const that = this;
        const FullName: any[] = AgentModel.Name.split(' ');
        this.updateFirstName = AgentModel.FirstName;
        this.updateLastName = AgentModel.LastName;
        this.updateEmail = AgentModel.Email;
        this.udpateUserId = AgentModel.UserId;
        this.Token = AgentModel.Token;
        this.SubDomain = AgentModel.SubDomain;
        that.Domain = this._userService.getDomain();
        if (this._userService.IsLocalHost()) {
            this.SubDomain = this.SubdomainName;
            that.Domain = 'innovativeinsuranceprofessional.com';
        }
        this.userService.ResendEmail(that.AgencyName, that.Token,
            that.SubDomain, that.Domain, this.updateEmail, this.updateFirstName.trim(), this.updateLastName.trim())
            .subscribe(
                result => {
                    if (result.Status) {
                        this.toasting('Invititation sent successfully.', 2000, 'green');
                        this.ShowLoader = false;
                    } else {
                        this.toasting(result.Message, 2000, 'red');
                        this.ShowLoader = false;
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        this.ShowLoader = false;
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }
    // search agent from database
    SearchAgents() {
        if (this.searchText.length > 0) {
            this.LoadMoreCounter = 0;
            this.users = [];
        }
        this.loadAllUser(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText);
    }
    // get all locations of agency
    public GetLocations() {
        this._userService.GetAgencyAllLocations(this.AgencyId)
            .subscribe(result => {
                if (result.Status) {
                    if (result.Data) {
                        this._Locations = result.Data;
                        setTimeout(() => {
                            const SelctedArray = [];
                            SelctedArray.push({LocationId: this._Locations[0].LocationId, LocationName: this._Locations[0].LocationName});
                            this.selectedLocations = SelctedArray;
                            this._signupForm.controls['AgentLocations'].setValue(SelctedArray);
                        }, 100);
                    }
                }
            },
            error => { });
    }
    // change agent location
    public ChangeAgentLocations(event) {
        const SelectedLocation = this._signupForm.get('AgentLocations').value;
        this.noLocation = (SelectedLocation && SelectedLocation.length > 0) ? false : true;
    }
    // update change agent location
    public UpdateChangeAgentLocations (event) {
        const SelectedLocation = this._updateForm.get('updateAgentLocations').value;
        this.noLocationUpdate = (SelectedLocation && SelectedLocation.length > 0) ? false : true;
    }
    // get deselect all locations at once
    public onDeSelect(event) {
        const selectedLocations = this._signupForm.controls['AgentLocations'].value;
        if (selectedLocations && selectedLocations.length === 0) {
            this.noLocation = true;
        }
    }
    // on every select location invite agent
    public onSelect(event) {
        this.noLocation = false;
    }
    // Update agent select location
    public onSelectUpdate(event) {
        this.noLocationUpdate = false;
    }
    // deselect agent location update
    public onDeSelectUpdate(event) {
        const selectedLocations = this._updateForm.controls['updateAgentLocations'].value;
        if (selectedLocations && selectedLocations.length === 0) {
            this.noLocationUpdate = true;
        }
    }
}
