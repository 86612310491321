import { Component, ElementRef, OnInit, OnDestroy, Renderer2, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../model/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from 'ng2-translate';
import { Location } from '@angular/common';
import { MaterializeAction } from 'angular2-materialize';


import {
  AuthService
} from 'angular5-social-auth';
import { EventBus } from '../shared/EventBus/EventBus';

@Component({
  templateUrl: './frontfacing.component.html'
})
export class FrontFacingComponent implements OnInit {
  public htmlContent: any;
  constructor(private loc: Location,
    private socialAuthService: AuthService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _router: Router,
    public translate: TranslateService, private _eventBus: EventBus,
    private renderer: Renderer2) { }



  modalActions1 = new EventEmitter<string | MaterializeAction>();
  modalActions2 = new EventEmitter<string | MaterializeAction>();
  modalActions3 = new EventEmitter<string | MaterializeAction>();
  modalActions4 = new EventEmitter<string | MaterializeAction>();
  modalActions5 = new EventEmitter<string | MaterializeAction>();
  globalActions = new EventEmitter<string | MaterializeAction>();
  params = [];

  model1Params = [
    {
      dismissible: false,
      complete: function () {  }
    }
  ];

  InviteAgents = [
    {
      dismissible: false,
      complete: function () {  }
    }
  ];

  ServiceOffered = [
    {
      dismissible: false,
      complete: function () {  }
    }
  ];

  StartupModal = [
    {
      dismissible: false,
      complete: function () {  }
    }
  ];

  AddInformation = [
    {
      dismissible: false,
      complete: function () {  }
    }
  ];

  printSomething() {
  }
  triggerToast() {
    this.globalActions.emit('toast');
  }

  openReview_Modal1() {
    this.modalActions1.emit({ action: 'modal', params: ['open'] });
  }
  closeReview_Modal1() {
    this.modalActions1.emit({ action: 'modal', params: ['close'] });
  }


  openInviteAgent_Modal2() {
    this.modalActions2.emit({ action: 'modal', params: ['open'] });
  }
  closeInviteAgentModal2() {
    this.modalActions2.emit({ action: 'modal', params: ['close'] });
  }

  openAddService() {
    this.modalActions3.emit({ action: 'modal', params: ['open'] });
  }
  closeAddService() {
    this.modalActions3.emit({ action: 'modal', params: ['close'] });
  }


  openAddInformation() {
    this.modalActions4.emit({ action: 'modal', params: ['open'] });
  }
  closeAddInformation() {
    this.modalActions4.emit({ action: 'modal', params: ['close'] });
  }

  openStartupModal() {
    this.modalActions5.emit({ action: 'modal', params: ['open'] });
  }
  closeStartupModal() {
    this.modalActions5.emit({ action: 'modal', params: ['close'] });
  }

  ngOnInit(): void {
  }

}
