import { Component, OnInit, EventEmitter } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { MaterializeAction, toast } from 'angular2-materialize';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Referral } from '../../model/referral';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';

declare var myExtObject: any;
enum StatusMaster {
  Claim = 1,
  Pending = 2,
  Paid = 3
}

@Component({
  templateUrl: './referral.component.html'
})

export class ReferralComponent implements OnInit {
  model1Params: any;
  ShowrefcountLoader: boolean;
  TotalEarning: number;
  PendingApprovals: number;
  TotalPaid: number;
  AddClass: boolean;
  users: User[] = [];
  referrals: Referral[] = [];
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  StripeCustomerId: string;
  NoReferral: boolean;
  LocationId: number;
  Count: number;
  AgencyId: number;
  ShowAvailAgentsLoader: boolean;
  key: string;
  reverse: boolean;
  wasClicked = false;
  AgencyReferalSetingExist: boolean;
  IsAgencyReferalSetingLoding: boolean;
  ReferalAmount: number;
  AmoutType: string;
  modalActions1 = new EventEmitter<string | MaterializeAction>();
  LoadMoreCounter = 0;
  SortKey = 'CreatedDateTime';
  SortOrder = 'DESC';
  LoadMoreReferal = true;
  ngOnInit() {

    this.GetAgencyReferalDetail();

    if (this.userService.decodeToken().user.RoleId !== 3) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }

    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });
  }

  constructor(private userService: UserService, private tokboxService: TokboxService,
    private route: ActivatedRoute, private _router: Router,
    private _EventBus: EventBus) {
    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'claimreferral') {
        if (this.StripeCustomerId) {
          this.ClaimReferral(m.filterBy.data.ReferralIds, m.filterBy.data.BankAccountId);
        }
      }
    });
    this.ShowrefcountLoader = false;
    this.TotalEarning = 0.0;
    this.PendingApprovals = 0;
    this.TotalPaid = 0;
    this.AddClass = false;
    this.Count = 1;
    this.key = 'CreatedDateTime';
    this.reverse = true;
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoginUserId = UserModel.user.UserId;
      this.StripeCustomerId = this.userService.getCustId();
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.loadAllUsers(UserModel.user.RoleId);
      this.loadAllReferral(UserModel.user.UserId);
    }
  }
  // load all users
  public loadAllUsers(RoleId) {
    this.ShowAvailAgentsLoader = true;
    this.userService.getAll(RoleId, 0, this.AgencyId, this.LocationId, false, '', '', '')
      .subscribe(
        result => {
          if (result.Status) {
            this.users = result.Data;
            const that = this;
            this.users.forEach(function (item) {
              item.FirstName = item.FirstName ? item.FirstName : '';
              item.LastName = item.LastName ? item.LastName : '';
              item.Name = `${item.FirstName} ${item.LastName}`;
              item.Initials = that.GetInitials(item.Name);
              item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
            });
          } else {
            this.toasting(result.Message, 2000, 'red');
          }
          this.ShowAvailAgentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAvailAgentsLoader = false;
        }
      );
  }
  // load all referal
  public loadAllReferral(UserId) {
    if (this.LoadMoreReferal === true) {
      this.LoadMoreReferal = false;
      if (this.LoadMoreCounter === 0) {
        this.referrals = [];
        this.ShowrefcountLoader = true;
      }
      this.userService.getAllRef(UserId, this.LoadMoreCounter, this.SortKey, this.SortOrder)
        .subscribe(
          result => {
            if (result.Data.length > 0) {
              if (result.Status) {
                this.NoReferral = false;
              } else {
                this.NoReferral = true;
              }
              if (this.referrals.length) {
                result.Data.forEach((item) => {
                  this.referrals.push(item);
                });
              } else {
                this.referrals = result.Data;
              }
              const that = this;
              this.referrals.forEach(function (item) {
                item.DateTimeString = new Date(item.CreatedDateTime).toLocaleString();
                item.FirstName = item.FirstName ? item.FirstName : '';
                item.LastName = item.LastName ? item.LastName : '';
                item.Name = `${item.FirstName} ${item.LastName}`;
                item.Initials = that.GetInitials(item.Name);
                item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                item.StatusName = StatusMaster[item.RefStatusId].toString();
                that.TotalEarning = item.AllBonus;
                that.PendingApprovals =  item.PendingApprovals;
                that.TotalPaid = item.TotalPaid;
              });
            } else {
              this.NoReferral = (this.referrals.length > 0) ? false : true;
            }
            this.ShowrefcountLoader = false;
            this.LoadMoreReferal = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.ShowrefcountLoader = false;
            this.LoadMoreReferal = true;
          }
        );
      }
  }
  // claim referal
  public ClaimReferral(ReferralIds, BankAccountId) {
    const that = this;
    this.userService.ClaimReferral(ReferralIds, BankAccountId, this.StripeCustomerId, 1)
      .subscribe(
        result => {
          if (result.Status) {
            this.toasting('Cliam Approve Successfully', 2000, 'green');
            this.loadAllReferral(that.LoginUserId);
            this._EventBus.emit(
              {
                Type: 'refreshpendingref',
                data: { ReferralIds: ReferralIds, BankAccountId: BankAccountId }
              });
          } else {
            this.toasting(`${result.Message}`, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // get agency referal detail
  public GetAgencyReferalDetail() {
    if (this.LoadMoreCounter === 0) {
      this.IsAgencyReferalSetingLoding = true;
    }
    this.userService.GetAgencyReferalSettingDetail(this.AgencyId)
      .subscribe(
        result => {
          this.IsAgencyReferalSetingLoding = false;
          if (result.Status) {
            this.AgencyReferalSetingExist = true;
            this.AmoutType = result.Data.AmoutType;
            this.ReferalAmount = result.Data.Value;
          } else {
            this.AgencyReferalSetingExist = false;
          }
        },
        error => {
          this.IsAgencyReferalSetingLoding = false;
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // get initial user
  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }
  // get class user list referal
  public GetClass() {
    if (this.Count === 1) {
      return 'iconUser noImg cyan lighten-3';
    } else if (this.Count === 2) {
      return 'iconUser noImg cyan lighten-5';
    } else if (this.Count === 3) {
      return 'iconUser noImg pink lighten-4';
    }
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }

  public LogoutTokbox() {
    myExtObject.logout();
  }
  // open referal share modal
  shareReferralCode() {
    this.modalActions1.emit({ action: 'modal', params: ['open'] });
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  OpenAgentView() {
    this.wasClicked = !this.wasClicked;
  }
  // on scroll down load more referal
  onScrollDown()  {
    if (this.referrals.length % 50 === 0 && this.LoadMoreReferal === true) {
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.loadAllReferral(this.LoginUserId);
    }
  }
  // sort referal from database
  ServerSort(sortKey: string) {
      this.LoadMoreCounter = 0;
      if (this.SortKey === sortKey) {
          this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
          this.SortKey = sortKey;
      } else {
          this.SortOrder = 'ASC';
          this.SortKey = sortKey;
      }
      this.loadAllReferral(this.LoginUserId);
  }
}

