import {
    Component,
    OnInit,
    Input,
    ChangeDetectorRef,
    ViewChild,
    OnDestroy
} from '@angular/core';
import { UserService } from '../../model/user.service';
import { environment } from '../../../environments/environment.prod';
import { DocumentToSave } from '../../model/documenttosave';
import { toast } from 'angular2-materialize';
import { Location } from '@angular/common';
import { CustomValidators } from 'ng2-validation';
declare var myExtObject: any;
declare var $: any;
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators,
    FormArray
} from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from '../../model/global.service';

@Component({
    selector: 'app-ng2stepper',
    templateUrl: 'ng2stepper.component.html',
    styleUrls: ['ng2stepper.component.css']
})
export class Ng2stepperComponent implements OnInit, OnDestroy {
    hashes: any;
    vars: any = [];
    hash: any;
    planOb: any[];
    hashUri: any;
    orderForm: FormGroup;
    items: FormArray;
    Fname: string;
    arr: any = [];
    SaveEmailArray: any[];
    currentDiv: any = 0;
    NotcheckedExist: boolean;
    checkedExist: boolean;
    IslastStepComp: boolean;
    checkedNotExist: boolean;
    ShowThankPage: boolean;
    IsExist: boolean;
    IsShowStepperDiv: boolean;
    @Input('elements') elements: number;
    @Input('active') active: number;
    @Input('size') size: string;
    @Input('color') color: string;
    @Input('content') content: string;


    private _errorMessage: string;
    private _formErrors: any;
    private AgencyId: number;
    private LocationId: number;
    private LoggedInUserId: number;
    ShowLoader: boolean;
    stripeAccountConneted: number;
    stripeClientId: any;
    _isDoller: boolean;

    SubdomainName: string;
    domainName: string;
    mainDomainName: string;
    domainValue: string;
    AhencyId: number;
    hostName: string;
    validupload: boolean;
    filesTosaveDB: Array<DocumentToSave> = [];
    HasFilesToUpload: boolean;
    SelectedFolderId: string;
    extens: String[];
    @ViewChild('uploadfileinput')
    public file_srcs_companyLogo: string;
    public file_srcs_userImage: string;
    selectedCompanyLogo: boolean;
    selectedUserLogo: boolean;
    companyLogoUploading: boolean;
    CompanyLogoKey: string;
    CompanyLogoUrl: string;
    UserImageKey: string;
    UserImageUrl: string;
    DomainNameFilled: number;
    CompanyLogoFilled: number;
    UserImageFilled: number;
    Amount: number;
    CookiesAfiliate: string;
    ShowDotLoader: boolean;
    AgecyLoginRef: string;
    selectedOption: number;
    IsLocalhost: boolean;
    private DisableEmailBtn = true;
    private fieldArray: Array<any> = [];
    private newAttribute: any = {};
    private reOccurArray: any[];
    private amountTypeArray: any[];
    private filter = false;
    _signupForm: FormGroup;
    isFormValid: boolean;
    controlCount: any;
    selectedOptionReOccure = 0;
    constructor(
        private _router: Router,
        private _userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private location: Location,
        private _formBuilder: FormBuilder,
        // private cookieService: CookieService
        private _globalService: GlobalService
    ) {
        this.DomainNameFilled = 0;
        this.CompanyLogoFilled = 0;
        this.UserImageFilled = 0;
        this.controlCount = 1;
        this.Amount = 50;
        this.selectedOption = 1;
        this.stripeAccountConneted = 0;
        this._isDoller = true;
        this.NotcheckedExist = true;
        this.IsShowStepperDiv = true;
        this.IslastStepComp = true;
        this.hostName = this._userService.getDomain();
        const UserModel = this._userService.decodeToken();
        // if(UserModel && UserModel.user) {
        this.AgencyId = UserModel.user.AgencyId;
        this.LocationId = UserModel.user.LocationId;
        this.LoggedInUserId = UserModel.user.UserId;
        // } else {
        // localStorage.removeItem('frontend-token');
        // localStorage.removeItem('StripeCust');
        // this._router.navigate(['/login']);
        // }

        this.extens = ['jpg', 'png', 'jpeg', 'gif'];
        this.IsLocalhost = this._userService.IsLocalHost();
        // this.domainValue = UserModel.user.AgencyName;
        this.domainValue = UserModel.user.SiteAddress;
        this.domainName = this.domainValue; // .replace(/[^a-zA-Z\s]/g, '').replace(/[\s]/g, '');
        this.DomainNameFilled = 1;
        this._signupForm = this._formBuilder.group({
            firstname: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            lastname: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
            email: ['', Validators.compose([Validators.required, CustomValidators.email])],
        });
        this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
        this.GetOccuranceType();
        this.GetAmountType();
        this.GetKeysDetails();
    }

    ngOnDestroy() {
        localStorage.setItem('custome-setting', JSON.stringify(true));
    }

    ngOnInit() {
        const PlanObj = localStorage.getItem('PlanPrice');
        const PlanNameObj = localStorage.getItem('PlanPrice');
        const PlanPrice = JSON.parse(PlanObj);
        const PlanName = JSON.parse(PlanNameObj);
        // PostAffTracker.setAccountId('default1');
        // PostAffTracker.writeCookieToCustomField(this.CookiesAfiliate);
        // const sale = PostAffTracker.createSale();
        // sale.setTotalCost(PlanPrice);
        // sale.setOrderID(PlanName);
        // sale.setProductID(PlanName);
        // PostAffTracker.register();

        this.mainDomainName = this._userService.getDomain();
        this.orderForm = this._formBuilder
            .group({
                fname: new FormControl('fname', Validators.minLength(2)),
                lname: new FormControl('lname', Validators.minLength(2)),
                email: new FormControl('email', [Validators.required]),
                items: this._formBuilder.array([this.createItem()])
            });

        /***********getting axisting wizard updation */

        $('.loader-outer').css('display', 'none');
        $('.companyLogoUploading').hide();
        $('.userImageUploading').hide();
        this._userService.AgencyStepDetail(this.AgencyId)
            .subscribe(
                result => {
                    this.calcNumber(result);
                    this.stripeAutheticationAccount();
                },
                error => {
                    if (error.status === 422) {
                        this._resetFormErrors();
                        // this._errorMessage = 'There was an error on submission. Please check again.';
                        const errorFields = JSON.parse(error.data.message);
                        this._setFormErrors(errorFields);
                    } else {
                        // this._errorMessage = error.Data[0];
                        this._errorMessage = JSON.stringify(error.Data[0]);
                    }
                    this.ShowLoader = false;
                }
            );


    }

    public GetKeysDetails() {
        this._userService.GetKeysDetails()
         .subscribe((response) => {
            if (response.Status) {
              const DecrytData = this._globalService.DecryptZohoKeys(response.Data);
              this.stripeClientId = DecrytData.stripe_client_id;
            }
         }, (error) => {});
    }

    stripeAutheticationAccount() {
        /********************************************* */


        // this.stripeClientId = environment.stripe_test_client_id;

        /****************************For stripe connectivity******************/
        this.hashUri = decodeURIComponent(window.location.href);
        this.hashes = this.hashUri.slice(this.hashUri.indexOf('?') + 1).split('&');
        if (this.hashes.length > 0) {
            for (let i = 0; i < this.hashes.length; i++) {
                this.hash = this.hashes[i].split('=');
                this.vars[this.hash[0]] = this.hash[1];
            }
        }
        if (typeof (this.vars['code']) !== 'undefined' && this.vars['code'] !== null && this.vars['code'] !== '') {
            setTimeout(function () {
                $('.stripe_button_first').text('Stripe account connecting...');
            }, 150);

            $('.loader-outer').css('display', 'inline-flex');
            this._userService.StripeAuth(this.vars['code'], this.AgencyId)
                .subscribe(
                    result => {
                        if (result.Status) {

                            this.arr[1]['IsStepComplete'] = true;
                            this.stripeAccountConneted = 1;
                            $('.loader-outer').css('display', 'none');
                            this.location.replaceState('/agency/step');
                        }
                    },
                    error => {
                        // Validation error
                        if (error.status === 422) {
                            this._resetFormErrors();
                            // this._errorMessage = 'There was an error on submission. Please check again.';
                            const errorFields = JSON.parse(error.data.message);
                            this._setFormErrors(errorFields);
                        } else {
                            // this._errorMessage = error.Data[0];
                            this._errorMessage = JSON.stringify(error.Data[0]);
                        }
                        this.ShowLoader = false;
                        $('.loader-outer').css('display', 'none');
                    }
                );
        }
        /****************************For stripe connectivity******************/
    }
    addOuter(event: any) {
        event.preventDefault();
        const element: HTMLElement = document.getElementById('InnerButton') as HTMLElement;
        element.click();
    }

    deleteFieldValue(index: number) {
        const control = this.orderForm.get('items') as FormArray;
        if (control.length > 1) {
            control.removeAt(index);
        } else {
            this.toasting('Atleast one agent record required to send invitation', 3000, 'red');
        }
        this.controlCount = control.length;
        this.DisableEmailBtn = !this.orderForm.valid;
    }
    checkValidationItem(email, i) {
        this.ShowLoader = true;
        const that = this;
        const isValid = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,4})$/.test(email);

        this.items = this.orderForm.get('items') as FormArray;
        const Fname = this.items.at(i).get('fname').value;
        const lname = this.items.at(i).get('lname').value;
        const Email = this.items.at(i).get('email').value;
        if (Fname.length === 0) {
            this.toasting('Please fill first name', 2000, 'red');
            this.items.at(i).get('fname').setValue('');
            // this.items.at(i).get('fname').setErrors;
            this.ShowLoader = false;
            this.DisableEmailBtn = !this.orderForm.valid;
            event.preventDefault();
            return false;
        }
        if (lname.length === 0) {
            this.toasting('Please fill Last name', 2000, 'red');
            this.items.at(i).get('lname').setValue('');
            // this.items.at(i).get('lname').setErrors;
            this.ShowLoader = false;
            this.DisableEmailBtn = !this.orderForm.valid;
            event.preventDefault();
            return false;
        }

        if (email.length !== 0) {
            if (!isValid) {
                that.toasting('Please fill Valid Email Address', 2000, 'red');
                this.items.at(i).get('email').setValue('');
                // this.items.at(i).get('email').setErrors;
                this.ShowLoader = false;
                this.DisableEmailBtn = !this.orderForm.valid;
                event.preventDefault();
                return false;
            }
            let num = 0;
            if (this.items.length !== 0) {
                for (let v = 0; v <= this.items.length - 1; v++) {
                    if (v !== i) {
                        if (this.items.at(v).get('email').value === Email) {
                            num = 1;
                        }
                    }
                }

                if (num === 1) {
                    this.toasting('Email Address already exist', 2000, 'red');
                    this.ShowLoader = false;
                    this.DisableEmailBtn = true;
                    this.items.at(i).get('email').setValue('');
                    // this.items.at(i).get('email').setErrors;
                    event.preventDefault();
                    return false;
                } else {
                    this._userService.CheckEmail(Email.trim())
                        .subscribe(
                            result => {
                                if (result.Data[0].ResStatus === 2) {
                                    this.toasting('An account with this email already exists in our system', 2000, 'red');
                                    this.ShowLoader = false;
                                    // this.items.at(i).get('email').setErrors;
                                    this.DisableEmailBtn = true;
                                    this.items.at(i).get('email').setValue('');
                                    event.preventDefault();
                                    return false;
                                } else {
                                    const itm = [{ 'Fname': Fname, 'lname': lname, 'Email': Email }];
                                    that.fieldArray.push(itm);
                                    this.DisableEmailBtn = false;
                                    this.ShowLoader = false;
                                }
                            },
                            error => {
                                if (error.status === 422) {
                                    const errorFields = JSON.parse(error.data.message);
                                    this._setFormErrors(errorFields);
                                } else {
                                    this.ShowLoader = false;
                                    this.toasting('Some Error occur while adding records ', 2000, 'red');
                                    return false;
                                }
                            });
                }
            } else {
                this._userService.CheckEmail(Email.trim())
                    .subscribe(
                        result => {
                            if (result.Data[0].ResStatus === 2) {
                                this.toasting('Email Address already exist', 2000, 'red');
                                this.ShowLoader = false;
                                this.items.at(i).get('email').setValue('');
                                // this.items.at(i).get('email').setErrors;
                                this.DisableEmailBtn = true;
                                event.preventDefault();
                                return false;
                            } else {
                                const itm = [{ 'Fname': Fname, 'lname': lname, 'Email': Email }];
                                this.DisableEmailBtn = false;
                                this.ShowLoader = false;
                                that.fieldArray.push(itm);
                            }
                        },
                        error => {
                            if (error.status === 422) {
                                const errorFields = JSON.parse(error.data.message);
                                this.ShowLoader = false;
                                this._setFormErrors(errorFields);
                            } else {
                                this.toasting('Some Error occur while adding records ', 2000, 'red');
                                this.ShowLoader = false;
                                return false;
                            }
                        });
            }
        } else {
            this.ShowLoader = false;
        }
    }

    addItem(fname, lname, email): void {

        if (!fname) {
            this.toasting('Please fill first name', 2000, 'red');
            event.preventDefault();
        } else if (!lname) {
            this.toasting('Please fill last name', 2000, 'red');
            event.preventDefault();
        } else if (!email) {
            this.toasting('Please fill Email Address', 2000, 'red');
            event.preventDefault();
        } else {
            this.items = this.orderForm.get('items') as FormArray;
            let valid = 0;
            for (let i = 0; i <= this.items.length - 1; i++) {
                const isValid = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.
                    test(this.items.at(i).get('email').value);
                if (!isValid) {
                    valid = 1;
                    break;
                }
            }
            if (valid === 1) {
                this.toasting('Please fill Valid Email Address', 2000, 'red');
                event.preventDefault();
            } else {
                this.items.push(this.createItem());
            }
        }
    }

    createItem(): FormGroup {
        return this._formBuilder.group({
            fname: new FormControl('', Validators.minLength(2)),
            lname: new FormControl('', Validators.minLength(2)),
            email: new FormControl('', [Validators.required, CustomValidators.email]),
        });
    }
    public ChangeLocation(event) {
        if (event.target.value === 1) {
            this._isDoller = true;
        } else {
            this._isDoller = false;
        }
    }

    public GetOccuranceType() {
        this._userService.GetOccurType()
            .subscribe(result => {
                if (result.Status) {
                    this.reOccurArray = [];
                    this.reOccurArray.push({OccurID: 0, OccurName: 'Choose your option'});
                    if (result.Data && result.Data.length) {
                        result.Data.forEach((item) => {
                            this.reOccurArray.push(item);
                        });
                    }
                }
            },
                error => { });
    }

    public GetAmountType() {
        this._userService.GetReferlAMountType()
            .subscribe(result => {
                if (result.Status) {
                    this.amountTypeArray = result.Data;
                    this.amountTypeArray.forEach(function (item) {
                        item.Text = item.AmountType === 'FixedAmount' ? '$' : '%';
                        item.Value = item.AmountID;
                    });
                } else {
                }
            },
                error => { });
    }

    private _resetFormErrors(): void {
        this._formErrors = {
            email: { valid: true, message: '' },
            password: { valid: true, message: '' },
        };
    }

    private _setFormErrors(errorFields: any): void {
        for (const key in errorFields) {
            if (!errorFields.hasOwnProperty(key)) { continue; }
            const message = errorFields[key];
            this._formErrors[key].valid = false;
            this._formErrors[key].message = message;
        }
    }

    private calcNumber(result) {
        for (let i = 0; i < this.elements; i++) {
            let divname = '';
            if (i === 0) {
                divname = 'Complete Profile';
            } else if (i === 1) {
                divname = 'Payment Setup';
            } else if (i === 2) {
                divname = 'Invite Agents';
            } else if (i === 3) {
                divname = 'Referrals Setup';
            }
            const obj = {
                div: i,
                class: 'content' + i,
                divName: divname,
                IsActive: parseInt(this.currentDiv, null) === i ? true : false,
                IsStepComplete: false
            };
            this.arr.push(obj);
        }
        this.updateWizardsOnload(result);
    }

    private updateWizardsOnload(result) {

        // company profile
        if (result.Data[0].Step1Complete === true) {
            this.arr[0]['IsStepComplete'] = true;
            this.arr[0]['IsActive'] = false;
            this.currentDiv = 1;
        }

        // payment setup
        if (result.Data[0].Step2Complete === true) {
            this.arr[1]['IsStepComplete'] = true;
            this.arr[1]['IsActive'] = false;
            this.currentDiv = 2;
        }

        // invite agents
        if (result.Data[0].Step3Complete === true) {
            this.arr[2]['IsStepComplete'] = true;
            this.arr[2]['IsActive'] = false;
            this.currentDiv = 3;
        }

        // referrals setup
        if (result.Data[0].Step4Complete === true) {
            this.arr[3]['IsStepComplete'] = false;
            this.arr[3]['IsActive'] = true;
        }
    }
    companyProfileSubmit(formData: any) {
        this.ControlStep(1);
        const domainName = formData.value.domain_name.trim();
        const companyLogo = this.CompanyLogoUrl;
        let companyLogoThumbnail = this.CompanyLogoKey;
        const userImage = this.UserImageKey;
        let userImageThumbnail = this.UserImageUrl;
        const AgencyId = this.AgencyId;

        let Doamin = this._userService.getDomain();
        if (Doamin === '' && this.IsLocalhost) {
            Doamin = 'innovativeinsuranceprofessional.com';
        }
        // const Doamin = 'innovativeinsuranceprofessional.com';
        this._userService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
                const AWSService = window.AWS;
                // AWSService.config.accessKeyId = environment.amazon_access_key;
                // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                AWSService.config.update({
                    region: 'us-west-2',
                    credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                        response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                });
                const s3 = new window.AWS.S3();
                if (this.UserImageUrl !== undefined) {
                    this.UserImageUrl = this.UserImageUrl;
                } else {
                    this.UserImageUrl = '';
                }
                if (this.CompanyLogoKey !== undefined) {
                    const paramsCompanyThumb = {
                        Bucket: response.Data.amazon_thumbnail_bucket_name,
                        Key: 'resized-images/' + this.CompanyLogoKey
                    };
                    companyLogoThumbnail = s3.getSignedUrl('getObject', paramsCompanyThumb);
                } else {
                    companyLogoThumbnail = '';
                }
                if (this.UserImageKey !== undefined) {
                    const paramsImageThumb = { 
                        Bucket: response.Data.amazon_thumbnail_bucket_name,
                        Key: 'resized-images/' + this.UserImageKey };
                    userImageThumbnail = s3.getSignedUrl('getObject', paramsImageThumb);
                } else {
                    userImageThumbnail = '';
                }
                const companyProfileData: any = {
                    SiteAddress: domainName,
                    AgencyLogo: companyLogo,
                    AgencyThumbnail: companyLogoThumbnail,
                    UserPicture: userImage,
                    UserThumbnail: userImageThumbnail,
                    AgencyId: AgencyId
                };
                this._userService.companyProfileSubmit(
                    companyProfileData['SiteAddress'],
                    companyProfileData['AgencyLogo'],
                    companyProfileData['AgencyThumbnail'],
                    this.UserImageUrl,
                    companyProfileData['UserThumbnail'],
                    companyProfileData['AgencyId'],
                    Doamin,
                    this.LocationId
                ).subscribe(
                    result => {
                        if (result.Status) {
                            // this.AddActiveCampaign();
                        }
                    },
                    error => {
                        // Validation error
                        if (error.status === 422) {
                            this._resetFormErrors();
                            // this._errorMessage = 'There was an error on submission. Please check again.';
                            const errorFields = JSON.parse(error.data.message);
                            this._setFormErrors(errorFields);
                        } else {
                            // this._errorMessage = error.Data[0];
                            this._errorMessage = JSON.stringify(error.Data[0]);
                        }
                        this.ShowLoader = false;
                    }
                );
            }
        }, (error) => {
        this.toasting('Server Issue. Try again after sometime', 2000, 'red');
        });
    }
    public AddActiveCampaign() {
        this._userService.GetAgencyDetailForCampaign(this.LoggedInUserId)
            .subscribe(
                results => {
                    if (results.Status) {
                        let Doamin = this._userService.getDomain();
                        if (this._userService.IsLocalHost()) {
                            Doamin = 'innovativeinsuranceprofessional.com';
                        } else {
                            Doamin = this._userService.getDomain();
                        }

                        this._userService.AddAgencyInCampaign(this.LoggedInUserId, results.Data[0].UserFirstName,
                            results.Data[0].UserLastName, results.Data[0].Email,
                            results.Data[0].Phone, results.Data[0].Role, results.Data[0].PlanName,
                            results.Data[0].CompanyName, results.Data[0].SiteAddress,
                            Doamin, 16)
                            .subscribe(
                                resultdata => {
                                    if (resultdata.Status) {
                                    } else {
                                    }
                                },
                                error => {
                                    if (error.Message === 'Token expired') {
                                        localStorage.removeItem('frontend-token');
                                        localStorage.removeItem('StripeCust');
                                        this._router.navigate(['/login']);
                                        this.ShowLoader = false;
                                    }
                                }
                            );
                    } else {

                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                        this.ShowLoader = false;
                    }
                }
            );
    }

    readFiles(files: any, index = 0, fileInputName) {
        const reader = new FileReader();
        // If there is a file
        if (index in files) {
            // Start reading this file
            this.readFile(files[index], reader, (result: any) => {
                // After the callback fires do:
                // if companyLogo selected
                if (fileInputName === 'companylogo') {
                    this.selectedCompanyLogo = true;
                    this.file_srcs_companyLogo = result;
                    setTimeout(function () {
                        $('.deleteCompanyLogoIcon').hide();
                    }, 20);
                }
                // if userImage selected
                if (fileInputName === 'userImage') {
                    this.selectedUserLogo = true;
                    this.file_srcs_userImage = result;
                    setTimeout(function () {
                        $('.deleteUserImageIcon').hide();
                    }, 20);
                }
                this.readFiles(files, index + 1, fileInputName); // Read the next file;
            });
        } else {
            this.changeDetectorRef.detectChanges();
        }
    }

    readFile(file: any, reader: any, callback: any) {
        // Set a callback funtion to fire after the file is fully loaded
        reader.onload = () => {
            // callback with the results
            callback(reader.result);
        };
        // Read the file
        reader.readAsDataURL(file);
    }

    public fileEvent(event) {
        const files = event.target.files;
        if (files.length > 0) {
            this.validupload = false;
            for (let i = 0; i < files.length; i++) {
                const documenttosave = new DocumentToSave();
                documenttosave.File = files[i];
                documenttosave.Name = files[i].name.split('.')[0].replace(/^.*[\\\/]/, '');
                documenttosave.Name = documenttosave.Name ? documenttosave.Name.replace(/\s/g, '') : documenttosave.Name;
                documenttosave.Extention = files[i].name.replace(/^.*\./, '');
                documenttosave.SizeInKB = files[i].size;
                documenttosave.Status = false;
                documenttosave.IsError = false;

                if (event.target.name === 'companylogo') {
                    documenttosave.FileType = 'companylogo';
                }
                if (event.target.name === 'userImage') {
                    documenttosave.FileType = 'userImage';
                }
                this.filesTosaveDB.push(documenttosave);
            }
            if (this.filesTosaveDB.length > 0) {
                this.HasFilesToUpload = true;
            }
            if (this.filesTosaveDB !== []) {
                const that = this;
                this._userService.AwsTempraryUploadDetails()
                .subscribe((response) => {
                    if (response.Status && response.Data) {
                        this.filesTosaveDB.forEach(function (item, index) {
                            if (that.CheckFileExtention(item.Extention)) {
                                that.readFiles(event.target.files, 0, event.target.name);
                                if (event.target.name === 'companylogo') {
                                    $('.companyLogoUploading').show();
                                    $('.companyLogo').removeClass('companyLogoUploaded');
                                }

                                if (event.target.name === 'userImage') {
                                    $('.userImageUploading').show();
                                    $('.userImage').removeClass('userImageUploaded');
                                }

                                if (!item.Status && !item.IsError) {
                                    const AWSService = window.AWS;
                                    // AWSService.config.accessKeyId = environment.amazon_access_key;
                                    // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                                    AWSService.config.update({
                                        region: 'us-west-2',
                                        credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                                            response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                                    });
                                    const unix = Math.round(+new Date() / 1000);
                                    item.Key = unix.toString() + '_' + item.Name;
                                    item.Progress = 0;
                                    const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                                    const params = { Key: 'images/' + item.Key, Body: item.File, ACL: 'public-read' };
                                    bucket.upload(params, function (err, data) {
                                        if (data !== undefined) {
                                            item.Status = true;
                                            item.Location = data.Location;
                                            that.validupload = true;
                                            that.filesTosaveDB.forEach(function (x) {
                                                if (event.target.name === 'companylogo') {
                                                    that.CompanyLogoKey = x.Key;
                                                    that.CompanyLogoUrl = data.Location;
                                                }
                                                if (event.target.name === 'userImage') {
                                                    that.UserImageKey = x.Key;
                                                    that.UserImageUrl = data.Location;
                                                }
                                                if (!x.Status) {
                                                    that.validupload = false;
                                                }
                                            });

                                        } else if (err !== undefined) {
                                            item.Status = false;
                                            item.IsError = true;

                                            that.validupload = true;
                                            that.filesTosaveDB.forEach(function (y) {
                                                y.Key = y.Key;
                                                if (!y.Status) {
                                                    that.validupload = false;
                                                }
                                            });
                                        }
                                    }).on('httpUploadProgress', function (progress) {

                                        if (Math.round(progress.loaded / progress.total * 100).toString() === '100') {
                                            if (event.target.name === 'companylogo') {
                                                setTimeout(() => {
                                                    $('.companyLogoUploading').hide();
                                                    $('.companyLogo').addClass('companyLogoUploaded');
                                                    $('.deleteCompanyLogoIcon').show();
                                                    that.CompanyLogoFilled = 1;
                                                }, 1700);
                                            }
                                            if (event.target.name === 'userImage') {
                                                setTimeout(() => {
                                                    $('.userImageUploading').hide();
                                                    $('.userImage').addClass('userImageUploaded');
                                                    $('.deleteUserImageIcon').show();
                                                    that.UserImageFilled = 1;
                                                }, 1700);
                                            }
                                        }
                                        that.UpdateProgress(progress.key, Math.round(progress.loaded / progress.total * 100).toString());
                                    });
                                }
                            } else {
                                that.filesTosaveDB.splice(index, 1);
                                that.toasting(item.Name + '.' + item.Extention + ' is not allowed!', 3000, 'red');

                                // change validation that company logo or userimage is empty
                                if (event.target.name === 'companylogo') {
                                    that.CompanyLogoFilled = 0;
                                }

                                if (event.target.name === 'userImage') {
                                    that.UserImageFilled = 0;
                                }
                            }
                        });
                    }
                }, (error) => {
                  this.validupload = false;
                  this.toasting('Server Issue. Try again after sometime', 2000, 'red');
                });
            } else { this.validupload = false; }
        } else {
        }
    }

    public deleteCompanyLogo(IsFromListing) {
        this.selectedCompanyLogo = false;
        const Key = this.CompanyLogoKey;
        const that = this;
        if (!IsFromListing) {  // deleting file from amazon only (During uploading)
            for (let i = 0; i < this.filesTosaveDB.length; i++) {
                if (this.filesTosaveDB[i].Key === Key) {
                    this._userService.AwsTempraryUploadDetails()
                    .subscribe((response) => {
                        if (response.Status && response.Data) {
                            const AWSService = window.AWS;
                            // AWSService.config.accessKeyId = environment.amazon_access_key;
                            // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                            AWSService.config.update({
                                region: 'us-west-2',
                                credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                                    response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                            });
                            const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                            const params = { Key: Key };
                            bucket.deleteObject(params, function (err, data) {

                                if (data !== undefined) {
                                    that.filesTosaveDB.splice(i, 1);
                                    if (that.filesTosaveDB.length === 0) {
                                        that.HasFilesToUpload = false;
                                        that.validupload = false;
                                    }
                                    that.CompanyLogoFilled = 0;
                                } else if (err !== undefined) {
                                    err.Status = false;
                                    alert(err);
                                }
                            });
                        }
                    }, (error) => {
                        this.validupload = false;
                        this.toasting('Server Issue. Try again after sometime', 2000, 'red');
                    });
                    return;
                }
            }
        }
    }

    deleteUserLogo(IsFromListing) {
        this.selectedUserLogo = false;
        const Key = this.UserImageKey;
        const that = this;
        if (!IsFromListing) {  // deleting file from amazon only (During uploading)
            for (let i = 0; i < this.filesTosaveDB.length; i++) {
                if (this.filesTosaveDB[i].Key === Key) {
                    this._userService.AwsTempraryUploadDetails()
                    .subscribe((response) => {
                        if (response.Status && response.Data) {
                            const AWSService = window.AWS;
                            // AWSService.config.accessKeyId = environment.amazon_access_key;
                            // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                            AWSService.config.update({
                                region: 'us-west-2',
                                credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                                    response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                            });
                            const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                            const params = { Key: Key };
                            bucket.deleteObject(params, function (err, data) {
                                if (data !== undefined) {
                                    that.filesTosaveDB.splice(i, 1);
                                    if (that.filesTosaveDB.length === 0) {
                                        that.HasFilesToUpload = false;
                                        that.validupload = false;
                                    }
                                    that.UserImageFilled = 0;
                                } else if (err !== undefined) {
                                    err.Status = false;
                                    alert(err);
                                }
                            });
                            }
                        }, (error) => {
                        this.validupload = false;
                        this.toasting('Server Issue. Try again after sometime', 2000, 'red');
                        });
                    return;
                }
            }
        }
    }


    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }


    public UpdateProgress(key, progress) {

        this.filesTosaveDB.forEach(function (item) {
            if (item.Key === key) {
                item.Progress = progress;
                return;
            }
        });
    }

    public CheckFileExtention(extention) {
        const exten = (extention) ? extention.toLowerCase() : '';
        if (this.extens.indexOf(exten) > -1) {
            return true;
        } else {
            return false;
        }
    }

    private ControlStep(item) {
        if (item === 1) {
            this.AddActiveCampaign();
        }
        this.currentDiv = item;
        this.arr.forEach(element => {
            if (element.div < this.currentDiv) {
                element.IsActive = false;
                element.IsStepComplete = true;
            } else if (element.div === this.currentDiv) {
                element.IsActive = true;
            } else {
                element.IsStepComplete = false;
            }
        });
    }

    isSpecialCharacter(event) {
        const regex = /[!@#$%^&*(),~`'.?":{}|;<>]/g;
        if (regex.exec(event.target.value) != null) {
            event.preventDefault();
        }
    }

    checkDomain(data) {

        if (data.name === 'domain_name' && data.value !== '') {
            this.ShowLoader = true;
            this.DomainNameFilled = 1;
            this._userService.ChaeckDomainName(data.value.trim(), this.AgencyId)
                .subscribe(
                    result => {
                        if (result.Status) {
                            this.checkedExist = true;
                            this.NotcheckedExist = false;
                            this.checkedNotExist = false;
                            this.DomainNameFilled = 0;
                            this.ShowLoader = false;
                            return false;
                        } else {
                            this.checkedExist = false;
                            this.NotcheckedExist = false;
                            this.checkedNotExist = true;
                            this.DomainNameFilled = 1;
                            this.ShowLoader = false;
                        }
                    },
                    error => {
                        if (error.status === 422) {
                            const errorFields = JSON.parse(error.data.message);
                            this._setFormErrors(errorFields);
                            this.ShowLoader = false;
                        } else {
                            this.toasting('Some Error occur', 2000, 'red');
                            this.ShowLoader = false;
                            return false;
                        }
                    });

        } else {
            this.DomainNameFilled = 0;
            this.ShowLoader = false;
        }
    }


    public onValueChanged(data?: any) {
        if (!this._signupForm) { return; }
        const form = this._signupForm;
        for (const field in this._formErrors) {
            // clear previous error message (if any)
            if (this._formErrors.hasOwnProperty(field)) {
                const control = form.get(field);
                if (control && control.dirty) {
                    this._formErrors[field].valid = true;
                    this._formErrors[field].message = '';
                }
            }
        }
    }

    public _isValid(field): boolean {
        if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
            this.isFormValid = true;
        } else {
            this.isFormValid = false;
        }
        return this.isFormValid;
    }

    addFieldValue(Fname, LName, emailAdd) {
        if (!Fname) {
            this.toasting('Please fill first name', 2000, 'red');
            event.preventDefault();
            return false;
        } else if (!LName) {
            this.toasting('Please fill last name', 2000, 'red');
            event.preventDefault();
            return false;
        } else if (!emailAdd) {
            this.toasting('Please fill Email Address', 2000, 'red');
            event.preventDefault();
            return false;
        } else {
            const isValid = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(emailAdd);
            if (!isValid) {
                this.toasting('Please fill Valid Email Address', 2000, 'red');
                event.preventDefault();
                return false;
            }
            let num = 0;
            if (this.fieldArray.length !== 0) {
                this.fieldArray.forEach(elementsField => {
                    if (elementsField.emailAdd === this.newAttribute.emailAdd) {
                        num = 1;
                        return false;
                    }
                });
                if (num === 1) {
                    this.toasting('Email Address already exist', 2000, 'red');
                    this.newAttribute.emailAdd = '';
                    event.preventDefault();
                    return false;
                } else {
                    this._userService.CheckEmail(emailAdd.trim())
                        .subscribe(
                            result => {
                                if (result.Data[0].ResStatus === 2) {
                                    this.toasting('Email Address already exist', 2000, 'red');
                                    this.newAttribute.emailAdd = '';
                                    event.preventDefault();
                                    return false;
                                } else {
                                    this.fieldArray.push(this.newAttribute);
                                    this.newAttribute = {};
                                }
                            },
                            error => {
                                if (error.status === 422) {
                                    const errorFields = JSON.parse(error.data.message);
                                    this._setFormErrors(errorFields);
                                } else {

                                    this.toasting('Some Error occur while adding records', 2000, 'red');
                                    return false;
                                }
                            });
                }
            } else {
                this.fieldArray.push(this.newAttribute);
                this.newAttribute = {};
            }
        }
    }

    public onFilterChange(event: any) {
        this.filter = !this.filter;
    }

    private ControlStepForInviteAgent(item) {
        this.SaveEmailArray = [];
        const that = this;
        that.items = this.orderForm.get('items') as FormArray;

        for (let i = 0; i <= that.items.length - 1; i++) {
            let MyFName = '';
            let MyLName = '';
            let MyEmail = '';
            MyFName = that.items.at(i).get('fname').value;
            MyLName = that.items.at(i).get('lname').value;
            MyEmail = that.items.at(i).get('email').value;
            if (!MyFName) {
                this.toasting('Please fill Valid First name', 2000, 'red');
                // that.items.at(i).get('fname').setErrors;
                event.preventDefault();
                return false;
            }
            if (!MyLName) {
                this.toasting('Please fill Valid Last name', 2000, 'red');
                event.preventDefault();
                return false;

            } if (!MyEmail) {
                this.toasting('Please fill Valid Email Address', 2000, 'red');
                event.preventDefault();
                return false;
            }
            const itm = { 'Fname': MyFName, 'lname': MyLName, 'Email': MyEmail };
            that.SaveEmailArray.push(itm);
        }
        if (that.SaveEmailArray.length > 0) {
            this.ShowDotLoader = true;
            const dataAgent = JSON.stringify(that.SaveEmailArray);
            let Doamin = this._userService.getDomain();
            if (this._userService.IsLocalHost()) {
                Doamin = 'innovativeinsuranceprofessional.com';
            } else {
                Doamin = this._userService.getDomain();
            }
            this._userService.GetSiteAddress(this.AgencyId)
                .subscribe(
                    result => {
                        if (result.Status) {
                            const NewSubdomainName = result.Data.AgencyName;
                            that._userService.InviteAgent(that.AgencyId, dataAgent, NewSubdomainName,
                                that.LocationId, Doamin, 2, [that.LocationId], 1)
                                .subscribe(
                                    dbresult => {
                                        if (dbresult.Status) {
                                            this.ShowDotLoader = false;
                                            that.currentDiv = item;
                                            that.arr.forEach(element => {
                                                if (element.div < that.currentDiv) {
                                                    element.IsActive = false;
                                                    element.IsStepComplete = true;
                                                } else if (element.div === that.currentDiv) {
                                                    element.IsActive = true;
                                                } else { element.IsStepComplete = false; }
                                            });
                                        } else {
                                            that.toasting('Some Error occur while adding records,Please try again', 2000, 'red');
                                            this.ShowDotLoader = false;
                                            return false;
                                        }
                                    },
                                    error => {
                                        if (error.status === 422) {
                                            const errorFields = JSON.parse(error.data.message);
                                            this.ShowDotLoader = false;
                                            that._setFormErrors(errorFields);
                                        } else {

                                            that.toasting('Some Error occur while adding records', 2000, 'red');
                                            this.ShowDotLoader = false;
                                            return false;
                                        }
                                    });
                        } else {
                            this.toasting('Some Error occur while adding records,Please try again', 2000, 'red');
                            return false;
                        }
                    }
                );
        } else {
            this.ShowDotLoader = false;
            that.currentDiv = item;
            that.arr.forEach(element => {
                if (element.div < that.currentDiv) {
                    element.IsActive = false;
                    element.IsStepComplete = true;
                } else if (element.div === that.currentDiv) {
                    element.IsActive = true;
                } else { element.IsStepComplete = false; }
            });
        }
    }

    private ControlStepForReferel(item, Amount, AmountType, ReOccur) {
        const that = this;
        that.ShowLoader = true;
        this._userService.GetSiteAddress(this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        that.SubdomainName = result.Data.AgencyName;
                        if (that.filter) {
                            if (!Amount) {
                                that.toasting('Please fill Amount ', 2000, 'red');
                                that.ShowLoader = false;
                                return false;
                            }
                            if (!AmountType) {
                                that.toasting('Please select Amount Type ', 2000, 'red');
                                that.ShowLoader = false;
                                return false;
                            } else if (!ReOccur) {
                                that.toasting('Please select bonus Offen', 2000, 'red');
                                that.ShowLoader = false;
                                return false;
                            } else {
                                that._userService.SaveAgencyReferralSettings(this.AgencyId, 'Payment1', 2, ReOccur,
                                    AmountType, Amount, true, 0)
                                    .subscribe(
                                        dbresult => {
                                            if (dbresult.Status) {
                                                that.currentDiv = item;
                                                that.arr.forEach(element => {
                                                    if (element.div < that.currentDiv) {
                                                        element.IsActive = false;
                                                        element.IsStepComplete = true;
                                                        this.IslastStepComp = false;
                                                    } else if (element.div === that.currentDiv) {
                                                        element.IsActive = true;
                                                    } else { element.IsStepComplete = false; }
                                                });
                                                this.ShowThankPage = true;
                                                this.IsShowStepperDiv = false;
                                                this.AgecyLoginRef = 'https://' + that.SubdomainName + '.' + that.hostName;
                                                event.preventDefault();
                                                return false;
                                            } else {
                                                that.toasting('Some Error occur while adding records,Please try again ', 2000, 'red');
                                                this.ShowLoader = false;
                                                return false;
                                            }
                                        },
                                        error => {
                                            if (error.status === 422) {
                                                const errorFields = JSON.parse(error.data.message);
                                                that._setFormErrors(errorFields);
                                            } else {
                                                that.toasting('Some Error occur while adding records ', 2000, 'red');
                                                this.ShowLoader = false;
                                                return false;
                                            }
                                        });
                            }
                            this.ShowLoader = false;
                        } else {
                            that.currentDiv = item;
                            that.arr.forEach(element => {
                                if (element.div < that.currentDiv) {
                                    element.IsActive = false;
                                    element.IsStepComplete = true;
                                    this.IslastStepComp = false;
                                } else if (element.div === that.currentDiv) {
                                    element.IsActive = true;
                                } else { element.IsStepComplete = false; }
                            });
                            this.ShowThankPage = true;
                            this.IsShowStepperDiv = false;
                            this.AgecyLoginRef = 'https://' + that.SubdomainName + '.' + that.hostName;
                            that.ShowLoader = false;
                        }
                    } else {
                        that.toasting('Some Error occur while adding records,Please try again ', 2000, 'red');
                        that.ShowLoader = false;
                        return false;
                    }
                },
                error => {
                    if (error.status === 422) {
                        const errorFields = JSON.parse(error.data.message);
                        that._setFormErrors(errorFields);
                    } else {
                        that.toasting('Some Error occur while adding records ', 2000, 'red');
                        that.ShowLoader = false;
                        return false;
                    }
                });
    }
    CheckModelValues() {
        return false;
    }

    gotoAgencySite(event) {
        if (event) {
            event.preventDefault();
        }
        this.LogoutTokbox();
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        window.location.href = this.AgecyLoginRef;
    }

    public LogoutTokbox() {
        myExtObject.logout();
    }

}
