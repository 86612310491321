import { Component, OnInit, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from '../../model/document';
import '../../../assets/tokbox/js/app.js';
import { DomSanitizer } from '@angular/platform-browser';
import { MaterializeAction, toast } from 'angular2-materialize';
import { environment } from '../../../environments/environment';
import { EventBus } from '../../shared/EventBus/EventBus';

declare var myExtObject: any;

@Component({
    templateUrl: './document.component.html'
})

export class DocumentComponent implements OnInit {
    @ViewChild('iframeelement') public iframe: ElementRef;
    previewimagemodal = new EventEmitter<string | MaterializeAction>();
    previewimagemodalparam = [
        {
            dismissible: false,
            complete: function () { }
        }
    ];
    ShowAvailAgentsLoader: boolean;
    ShowDocumentLoader: boolean;
    wasClicked = false;
    users: User[] = [];
    ShowActionLoader: boolean;
    IsLoaded: boolean;
    documents: Document[] = [];
    Folders: any[];
    LoggedInUserName: string;
    LoggedInUserImageUrl: string;
    LoggedInUserRefCode: string;
    LoginUserId: number;
    NoDocument: boolean;
    Count: number;
    LocationId: number;
    ShowDocTable: boolean;
    NotDocumentFound: boolean;
    previewImageURL: any;
    IsDoc: boolean;
    AgencyId: number;
    documentkey: string;
    documentreverse: boolean;
    public innerWidth: any;
    selectedFolderMob: any;
    SortKey = 'UploadedDate';
    SortOrder = 'DESC';
    ActiveFolderId = 5;
    LoadMoreCounter = 0;
    modelDeleteDocumentParam: any;
    modalDeleteActions = new EventEmitter<string | MaterializeAction>();
    DeleteDocumentKey: string;
    DeleteFolderID = 0;
    DeleteIndex = 0;
    LoadMoreLoaderEnable: boolean;
    LoadMoreRequest = true;
    ngOnInit() {
        if (this.userService.decodeToken().user.RoleId !== 3) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
        }

        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
        // Get device width
        this.innerWidth = window.innerWidth;
   }

    constructor(private sanitizer: DomSanitizer, private userService: UserService, private tokboxService: TokboxService,
        private route: ActivatedRoute, private _router: Router, private _EventBus: EventBus) {
        const that = this;
        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'refreshdocumentlist') {
                this.LoadMoreCounter = 0;
                this.GetFolders(m.filterBy.data.UserId);
            }
        });
        this.documentkey = 'UploadedDate';
        this.documentreverse = true;
        this.Count = 1;
        // get Agency/user detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoggedInUserRefCode = UserModel.user.ReferalCode;
            this.LoggedInUserRefCode = UserModel.user.ReferalCode;
            this.LoginUserId = UserModel.user.UserId;
            this.AgencyId = UserModel.user.AgencyId;
            this.LocationId = UserModel.user.LocationId;
            this.ShowDocTable = true;
            this.LocationId = UserModel.user.LocationId;
            this.GetFolders(this.LoginUserId);
            this.loadAllUsers(UserModel.user.RoleId);
        }
    }
    // download file
    public downloadFile(fileName, extention, url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = fileName + '.' + extention;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    // show success/error message toast
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // user sign redirect
    public UserSignRedirect(documentKey, DocId) {
        const res: any = documentKey.split('.pdf');
        this._router.navigate(['user/usersign', res[0], DocId]);
    }
    // refresh data after delete
    public RefreshDataAfterDelete() {
        this.GetFolders(this.LoginUserId);
    }
    // delete file databse/AWS
    public DeleteFile(Key, FolderID, index) {
        this.documents[index].ShowActions = false;
        const that = this;
        this.userService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
                const AWSService = window.AWS;
                // AWSService.config.accessKeyId = environment.amazon_access_key;
                // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                AWSService.config.update({
                    region: 'us-west-2',
                    credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                        response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                });
                const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                const params = { Key: Key };
                bucket.deleteObject(params, function (err, data) {
                    if (data !== undefined) {
                        that.userService.deleteDocument(Key)
                            .subscribe(
                                result => {
                                    that.ShowActionLoader = false;
                                    if (result.Status) {
                                        that.toasting('File deleted successfully', 2000, 'green');
                                        that.RefreshDataAfterDelete();
                                        that.documents[index].ShowActions = false;
                                    } else {
                                        that.documents = [];
                                    }
                                },
                                error => {
                                    if (error.Message === 'Token expired') {
                                        localStorage.removeItem('frontend-token');
                                        localStorage.removeItem('StripeCust');
                                        this._router.navigate(['/login']);
                                    }
                                }
                            );
                    } else if (err !== undefined) {
                        err.Status = false;
                        this.toasting(err, 2000, 'red');
                    }
                });
            }
        }, (error) => {
          this.toasting('Server Issue. Try again after sometime', 2000, 'red');
        });
    }
    // get all folders
    public GetFolders(UserId) {
        return this.userService.getfolders(UserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.Folders = result.Data;
                        const that = this;
                        that.Folders.forEach(function (item) {
                            if (item.Id === 5) {
                                item.Class = 'tab_btn btn-flat raddius25 btn-large der active';
                            }
                            if (item.Id === 6) {
                                item.Class = 'tab_btn btn-flat raddius25 btn-large grn';
                            }
                            if (item.Id === 7) {
                                item.Class = 'tab_btn btn-flat raddius25 btn-large blu';
                            }
                            if (item.Id === 8) {
                                item.Class = 'tab_btn btn-flat raddius25 btn-large pnk';
                            }
                        });
                        this.getDocuments(UserId, this.ActiveFolderId);
                    } else {
                        alert(result.Message);
                    }
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                });
    }
    // open image preview modal
    public openPreviewModal() {
        this.previewimagemodal.emit({ action: 'modal', params: ['open'] });
    }
    // close image preview modal
    public closepreviewimageModal() {
        this.iframe.nativeElement.src = '';
        this.previewimagemodal.emit({ action: 'modal', params: ['close'] });
    }
    // preview file by file type
    public previewFile(url, Extention, index) {
        if (Extention.toLowerCase() === 'jpg' || Extention.toLowerCase() === 'png' || Extention.toLowerCase() === 'jpeg') {
            if (this.previewImageURL !== url) { this.IsLoaded = false; }
            this.previewImageURL = url;
            this.IsDoc = false;
            this.openPreviewModal();
        } else {
            this.IsLoaded = true;
            this.IsDoc = true;
            const DocUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + url);
            this.previewImageURL = DocUrl;
            this.openPreviewModal();
        }
    }

    public OnLoadComplete() {
        this.IsLoaded = true;
    }
    // get documents by active folder
    public getDocuments(UserId, FolderId) {
        this.ShowDocumentLoader = true;
        if (FolderId !== this.ActiveFolderId) {
            this.LoadMoreCounter = 0;
            this.documents = [];
            this.LoadMoreRequest = true;
        }
        this.ActiveFolderId = FolderId;
        this.getDocumentsLists(UserId, FolderId, this.SortKey, this.SortOrder, this.LoadMoreCounter);
    }
    // get documents list
    public getDocumentsLists(UserId, FolderId, SortKey, SortOrder, Offset) {
        if (this.LoadMoreRequest === true) {
            this.LoadMoreRequest = false;
            this.NotDocumentFound = false;
            this.userService.getDocuments(UserId, FolderId, SortKey, SortOrder, Offset)
            .subscribe(
                result => {
                    if (result.Status) {
                        if (Offset === 0) {
                            this.documents = [];
                            this.documents = result.Data;
                        } else {
                            if (result.Data && result.Data.length > 0) {
                                result.Data.forEach((item) => {
                                    this.documents.push(item);
                                });
                            }
                        }
                        this.documents.forEach((item) => {
                            item.Name = item.Name[0].toUpperCase() + item.Name.slice(1);
                            item.DateTimeString = new Date(item.UploadedDate).toLocaleString();
                            item.ShowActions = true;
                            item.Size = this.GetCorrectSize(item.SizeInKB);
                            item.ExtensionImage = this.GetIcon(item.Extention);
                        });
                    } else {
                        if (Offset === 0) {
                            this.documents = [];
                        }
                        if (this.documents && this.documents.length) {
                            this.NotDocumentFound = false;
                        } else {
                            this.NotDocumentFound = true;
                        }
                    }
                    this.ShowDocumentLoader = false;
                    this.ActiveInactiveFolders(FolderId);
                    this.LoadMoreLoaderEnable = false;
                    this.LoadMoreRequest = true;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.ShowDocumentLoader = false;
                    this.LoadMoreRequest = true;
            });
        }
    }
    // get documents mobile
    public getDocumentsMobile(event) {
        this.LoadMoreCounter = 0;
        if (event && event.value) {
            this.getDocuments(this.LoginUserId, event.value);
            this.selectedFolderMob = 5;
        }
    }
    // set active/in-active folder
    public ActiveInactiveFolders(FolderId) {
        this.ShowDocTable = true;
        this.Folders.forEach(function (item) {
            if (item.Id === 5) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large der';
            }
            if (item.Id === 6) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large grn';
            }
            if (item.Id === 7) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large blu';
            }
            if (item.Id === 8) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large pnk';
            }
            if (item.Id === 12) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large prpl';
            }
        });
        this.Folders.forEach(function (item) {
            if (item.Id === FolderId && FolderId === 5) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large der active';
            } else if (item.Id === FolderId && FolderId === 6) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large grn active';
            } else if (item.Id === FolderId && FolderId === 7) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large blu active';
            } else if (item.Id === FolderId && FolderId === 8) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large pnk active';
            } else if (item.Id === FolderId && FolderId === 12) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large prpl active';
            }
        });
    }
    // get file icon by type
    public GetIcon(extention) {
        if (extention.toLowerCase().match('pdf')) {
            return 'assets/images/pdf.png';
        } else if (extention.toLowerCase().match('doc') || extention.match('docx')) {
            return 'assets/images/do.png';
        } else if (extention.toLowerCase().match('jpg') || extention.match('png') || extention.match('jpeg')) {
            return 'assets/images/jpg.png';
        } else if (extention.toLowerCase().match('xlsx') || extention.match('xls')) {
            return 'assets/images/excelr_ic.png';
        } else if (extention.toLowerCase().match('pptx')) {
            return 'assets/images/powerpoint_ic.png';
        }
    }
    // load all users
    public loadAllUsers(RoleId) {
        this.ShowAvailAgentsLoader = true;
        this.userService.getAll(RoleId, 0, this.AgencyId, this.LocationId, false, '', '', '')
            .subscribe(
                result => {
                    if (result.Status) {
                        this.users = result.Data;
                    } else {
                        alert(result.Message);
                    }
                    this.ShowAvailAgentsLoader = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.ShowAvailAgentsLoader = false;
                }
            );
    }
    // get documents size to display
    public GetCorrectSize(FileSize) {
        if (FileSize < 1000) {
            return FileSize + ' Bytes';
        } else if (FileSize > 1000 && FileSize < 1000000) {
            return (FileSize / 1000).toFixed(2).toString() + ' KB';
        } else if (FileSize > 1000000 && FileSize < 1000000000) {
            return (FileSize / 1000000).toFixed(2).toString() + ' MB';
        } else if (FileSize > 1000000000) {
            return (FileSize / 1000000000).toFixed(2).toString() + ' GB';
        }
    }

    public LogOut() {
        this.LogoutTokbox();
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
    }

    public StartCall(ToUserId) {
        // Generate new session and Token
        this.GenerateSessionIdAndTokenId(ToUserId);
    }

    public EndCall() {
        myExtObject.end();
    }

    public LogoutTokbox() {
        myExtObject.logout();
    }

    public SendMessage() {
        myExtObject.sendmessage();
    }

    public GenerateSessionIdAndTokenId(ToUserId) {
        this.tokboxService.createsessionandtoken(this.LoginUserId, ToUserId)
            .subscribe(
                result => {
                    if (result.Status) {
                        myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2, ToUserId, result.currentSessionId);
                    } else {
                        alert(result.Message);
                    }
                },
                error => {

                }
            );
    }
    // sorting documents
    documentsort(property) {
        this.documentreverse = !this.documentreverse; // change the direction
        this.documentkey = property;
        const direction = this.documentreverse ? 1 : -1;
        this.documents.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            } else if (a[property] > b[property]) {
                return 1 * direction;
            } else {
                return 0;
            }
        });
    }

    OpenAgentView() {
        this.wasClicked = !this.wasClicked;
    }

    // Check device width
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    // server sort documents
    ServerSort(sortKey: string) {
        this.LoadMoreCounter = 0;
        if (this.SortKey === sortKey) {
            this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
            this.SortKey = sortKey;
        } else {
            this.SortOrder = 'ASC';
            this.SortKey = sortKey;
        }
        this.getDocuments(this.LoginUserId, this.ActiveFolderId);
    }
    // on scroll down load documents
    public onScrollDown() {
        if (this.documents.length % 50 === 0 && this.LoadMoreRequest === true) {
            this.LoadMoreLoaderEnable = true;
            this.LoadMoreCounter = this.LoadMoreCounter + 50;
            this.getDocumentsLists(this.LoginUserId, this.ActiveFolderId, this.SortKey, this.SortOrder, this.LoadMoreCounter);
        }
    }
    // cancel delete modal
    public CancelDeleteModel() {
        this.modalDeleteActions.emit({ action: 'modal', params: ['close'] });
    }
    // delete document confirm
    public deleteDocumentConfirm(Key, FolderID, index) {
        this.DeleteDocumentKey = Key;
        this.DeleteFolderID = FolderID;
        this.DeleteIndex = index;
        this.modalDeleteActions.emit({ action: 'modal', params: ['open'] });
    }
    // delete document file
    public DeleteDocumentFile() {
        this.modalDeleteActions.emit({ action: 'modal', params: ['close'] });
        this.DeleteFile(this.DeleteDocumentKey, this.DeleteFolderID, this.DeleteIndex);
    }
}
