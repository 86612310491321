import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../model/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { toast } from 'angular2-materialize';
import { TranslateService } from 'ng2-translate';
import { CustomValidators } from '../helper/custom-validators';
import { Location } from '@angular/common';
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedinLoginProvider
} from 'angular5-social-auth';
import { EventBus } from '../shared/EventBus/EventBus';
import { Meta } from '@angular/platform-browser';
import { GlobalService } from '../model/global.service';

declare var myExtObject: any;

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('video') private video: ElementRef;
  IsMute: boolean;
  ShowLoader: boolean;
  IsLocalhost: boolean;
  AgencyName: string;

  _loginForm: FormGroup;
  private _formErrors: any;
  private _submitted: boolean;
  private _errorMessage: string;
  IsShowLogo: boolean;
  private check: any;
  private _agenctobj: any = new Object();
  CheckingAgency: boolean;
  Testing: any;

  agencyName: string;
  agencyLogo: string;
  globleDataSource: any;

  constructor(private loc: Location,
    private socialAuthService: AuthService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _router: Router, private meta: Meta,
    public translate: TranslateService, private _eventBus: EventBus,
    private globalService: GlobalService,
    private renderer: Renderer2) {
    this.check = this._userService.getSubdomain();
    this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: false } });
    this.globleDataSource = this.globalService.globleDataSource;
    this.agencyLogo = this.globleDataSource['AgencyLogo'];
    this.agencyName = this.globleDataSource['AgencyName'];

    this.meta.addTag({ property: 'og:title', content: this.globleDataSource['AgencyName'] });
    this.meta.addTag({ property: 'og:description', content: `${this.globleDataSource['AgencyName']} - Connect in our virtual office` });
    this.meta.addTag({ property: 'og:type', content: 'agency_page' });
    this.meta.addTag({ property: 'og:url', content: `https://${this.globleDataSource['SiteAddress']}.agentalive.tech/` });
    this.meta.addTag({ property: 'og:image', content: `${this.globleDataSource['AgencyLogo']}` });
    this.meta.addTag({ property: 'og:site_name', content: this.globleDataSource['AgencyName'] });
    this._agenctobj.Logo = '../../../assets/images/agent_alive_logo.png';

    this.IsLocalhost = _userService.IsLocalHost();

    if (this.check === 'app' || this.check === 'www') {
      window.location.href = 'https://app.' + this._userService.getDomain() + '/pricing';
    } else if (!this.IsLocalhost) {
      this.CheckAgency(this._userService.getSubdomain());
    }
    const UserModel = this._userService.decodeToken();
    if (UserModel.user) {
      this._userService.RedirectAccToRole(UserModel.user.RoleId);
    }
    // login form intialize
    this._loginForm = _formBuilder.group({
      agencyname: ['Site Address', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, CustomValidators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      rememberme: ['']
    });

    this._loginForm.valueChanges.subscribe(data => this.onValueChanged(data));
  }
  ngOnInit(): void {
    myExtObject.logout();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'agent_alive_bg');
  }

  // social sign in
  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'linkedin') {
      socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
      }
    );
  }
  // check agency exists or not
  public CheckAgency(agencyname) {
    this.CheckingAgency = true;
    this._userService.GetAgency(agencyname)
      .subscribe(
        result => {
          if (result.Status && result.Data) {
            this._agenctobj = result.Data[0];
            if (this._agenctobj.Logo) {
              this.IsShowLogo = true;
            } else {
              this.IsShowLogo = false;
              this.AgencyName = this._agenctobj.AgencyName;
            }
            this._userService.setTitle(this._agenctobj.AgencyName);
            this.CheckingAgency = false;
          } else {
            this._agenctobj.Logo = '../assets/images/agent_alive_logo.png';
            this._userService.setTitle('Agent Alive');
            this.toasting(result.Message, 2000, 'red');
            this.CheckingAgency = false;
          }
        },
        error => {
          // Validation error
          if (error.status === 422) {
            this._resetFormErrors();
            const errorFields = JSON.parse(error.data.message);
            this._setFormErrors(errorFields);
          } else {
            this._errorMessage = JSON.stringify(error.data && error.data[0]);
          }
          this.ShowLoader = false;
        }
      );
  }

  private newMethod(video: any) {
    video.pause();
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // set form error
  private _setFormErrors(errorFields: any): void {
    for (const key in errorFields) {
      if (!errorFields.hasOwnProperty(key)) { continue; }
      const message = errorFields[key];
      this._formErrors[key].valid = false;
      this._formErrors[key].message = message;
    }
  }
  // reset form errors
  private _resetFormErrors(): void {
    this._formErrors = {
      email: { valid: true, message: '' },
      password: { valid: true, message: '' },
    };
  }
  // on value change set for fields validations
  public onValueChanged(data?: any) {
    if (!this._loginForm) { return; }
    const form = this._loginForm;
    for (const field in this._formErrors) {
      // clear previous error message (if any)
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }
  // set validation class form field
  public _isValid(field): boolean {
    let isValid: boolean;
    if (this._loginForm.controls[field].touched === false) {
      isValid = true;
    } else if (this._loginForm.controls[field].touched === true && this._loginForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }

  public muteVideo() {
    if (this.video.nativeElement.volume !== 0) {
      this.video.nativeElement.volume = 0;
      this.IsMute = true;
    } else {
      this.video.nativeElement.volume = 1;
      this.IsMute = false;
    }
  }
  // login api request
  public onSubmit(elementValues: any) {
    this.ShowLoader = true;
    if (this._agenctobj.Id !== undefined && this._agenctobj.Id !== 0) {
      let SubDomain = this._userService.getSubdomain();
      if (this._userService.IsLocalHost()) {
        SubDomain = this._agenctobj.SiteAddress;
      }
      this._userService.login(elementValues.email, elementValues.password, this._agenctobj.Id,
        SubDomain, this.globleDataSource['Ip'], this._agenctobj.LocationId)
        .subscribe(
          result => {
            window['session'] = '';
            if (result.Status && result.Data) {
              const globleDataSourceState = this.globalService.globleDataSource;
              globleDataSourceState['AgencyId'] = result.Data.AgencyId;
              globleDataSourceState['LocationId'] = result.Data.LocationId;
              globleDataSourceState['AgencyLogo'] = result.Data.AgencyLogo;
              globleDataSourceState['AgencyName'] = result.Data.AgencyName;
              globleDataSourceState['isAgencyExist'] = true;
              globleDataSourceState['SiteAddress'] = result.Data.SiteAddress;

              globleDataSourceState['AgencyGuid'] = result.Data.AgencyGuid;
              globleDataSourceState['AgencyMatrixPopupStatus'] = result.Data.AgencyMatrixPopupStatus;
              globleDataSourceState['Email'] = result.Data.Email;
              globleDataSourceState['Phone'] = result.Data.Phone;
              globleDataSourceState['SubscriptionPeriodEnd'] = result.Data.SubscriptionPeriodEnd;
              globleDataSourceState['SubscriptionStatusId'] = result.Data.SubscriptionStatusId;
              this.globalService.changeGlobleDataSourceState(globleDataSourceState);
              window['getFirebaseToken']();
              localStorage.setItem('isagntavbl', JSON.stringify({ isTrue: true }));
              localStorage.setItem('SelectedLocation', result.Data.LocationId);
              if (elementValues.rememberme === true) { } else { }
              if (result.Data.RoleId === 3) {
                this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
                this._router.navigate(['user/dashboard']);
              } else if (result.Data.RoleId === 6) {
                if (!result.Data.AgencyMatrixPopupStatus) {
                  this._userService.getAgencyMatrixPopupStatus({ AgencyId: result.Data.AgencyId }).subscribe(
                    (response) => {
                      if (response.Status) {
                        if (!response.Data.AgencyMatrixPopupStatus) {
                          this._router.navigate(['agency/matrix']);
                        } else if (!result.Data.IsPageSet) {
                          localStorage.setItem('custome-setting', JSON.stringify(true));
                          this._router.navigate(['agency/page-setting']);
                        } else {
                          this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
                          this._router.navigate(['agency/dashboard']);
                        }
                      }
                    }, (error) => {
                    });
                } else {
                  if (!result.Data.IsPageSet) {
                    localStorage.setItem('custome-setting', JSON.stringify(true));
                    this._router.navigate(['agency/page-setting']);
                  } else {
                    this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
                    this._router.navigate(['agency/dashboard']);
                  }
                }
              } else {
                this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
                this._router.navigate(['agent/dasboard']);
              }
            } else {
              this.toasting(result.Message, 2000, 'red');
            }
            this.ShowLoader = false;
          },
          error => {
            // Validation error
            if (error.status === 422) {
              this._resetFormErrors();
              const errorFields = JSON.parse(error.data.message);
              this._setFormErrors(errorFields);
            } else {
              this._errorMessage = JSON.stringify(error.Data[0]);
            }
            this.ShowLoader = false;
          }
        );
    } else {
      this.ShowLoader = false;
      this.toasting('Company Not Found!', 2000, 'red');
    }
  }
}
