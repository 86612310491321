import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Referral } from '../../model/referral';
import '../../../assets/tokbox/js/app.js';
import { AddBankAccountComponent } from '../../shared/add-bank-account/add-bank-account.component';
import { EventBus } from '../../shared/EventBus/EventBus';
import { toast } from 'angular2-materialize';
declare var myExtObject: any;

enum StatusMaster {
  Requested = 1,
  Approved = 2,
  Processed = 3,
  NotClaimed = 4
}

@Component({
  templateUrl: './agencypendingreferal.component.html'
})

export class AgencyPendingReferalComponent implements OnInit {
  ShowrefcountLoader = false;
  TotalEarning: number;
  PendingApprovals: number;
  TotalPaid: number;
  AddClass: boolean;
  users: User[] = [];
  referrals: Referral[] = [];
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  StripeCustomerId: string;
  NoReferral: boolean;
  LoadMoreLoader: boolean;
  LoadMoreCounter: number;
  LocationId: number;
  Count: number;
  AgencyId: number;
  ShowAvailAgentsLoader: boolean;
  key: string;
  reverse: boolean;
  wasClicked = false;
  searchText = '';
  SortKey = 'CreatedDateTime';
  SortOrder = 'DESC';
  LoadMoreReferal = true;
  ngOnInit() {
    if (this.userService.decodeToken().user.RoleId !== 6) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }

    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });
  }

  constructor(private userService: UserService, private tokboxService: TokboxService,
    private route: ActivatedRoute, private _router: Router,
    private _EventBus: EventBus) {
    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'claimreferral') {
        if (this.StripeCustomerId) {
          this.ClaimReferral(m.filterBy.data.ReferralIds, m.filterBy.data.BankAccountId);
        }
      }
    });
    this.TotalEarning = 0;
    this.PendingApprovals = 0;
    this.TotalPaid = 0;
    this.AddClass = false;
    this.Count = 1;
    this.key = 'CreatedDateTime';
    this.reverse = true;
    // get agency/admin user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoginUserId = UserModel.user.UserId;
      this.StripeCustomerId = this.userService.getCustId();
      this.AgencyId = UserModel.user.AgencyId;
      if (this.userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId === 6) {
          this.LocationId = Number(this.userService.GetSelectedLocation());
      } else {
          this.LocationId = UserModel.user.LocationId;
      }
      this.LoadMoreCounter = 0;
      this.loadAllReferral(UserModel.user.AgencyId, this.LoadMoreCounter, this.LocationId);
    }
    this._EventBus.on().subscribe( (m: any) => {
        if (m.filterBy.Type === 'locationChanged') {
            this.LocationId = Number(m.filterBy.data.LocationId);
            this.loadAllReferral(this.AgencyId, this.LoadMoreCounter, this.LocationId);
        }
    });
  }
  // load all users
  public loadAllUsers(RoleId, Search) {
    this.ShowAvailAgentsLoader = true;
    this.userService.getAll(RoleId, 0, this.AgencyId, this.LocationId, false, Search, '', '')
      .subscribe(
        result => {
          if (result.Status) {
            this.users = result.Data;
            const that = this;
            this.users.forEach(function (item) {
              item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
              item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
              item.Name = `${item.FirstName} ${item.LastName}`;
              item.Initials = that.GetInitials(item.Name);
              item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
            });
          } else {
            alert(result.Message);
          }
          this.ShowAvailAgentsLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowAvailAgentsLoader = false;
        }
      );
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // load all referal request by users
  public loadAllReferral(AgencyId, OffSet, LocationId) {
    if (this.LoadMoreReferal) {
      this.LoadMoreReferal = false;
      if (OffSet === 0) {
        this.referrals = [];
        this.ShowrefcountLoader = true;
      }
      this.userService.getAllRefForAgency(AgencyId, OffSet, this.SortKey, this.SortOrder, LocationId)
        .subscribe(
          result => {
            if (result.Data.length > 0) {
              if (result.Status) {
                this.NoReferral = false;
              } else {
                this.NoReferral = true;
              }
              if (this.referrals.length) {
                result.Data.forEach((item) => {
                  this.referrals.push(item);
                });
              } else {
                this.referrals = result.Data;
              }
              const that = this;
              this.referrals.forEach((item) => {
                item.DateTimeString = new Date(item.CreatedDateTime).toLocaleString();
                item.FirstName = item.FirstName ? item.FirstName : '';
                item.LastName = item.LastName ? item.LastName : '';
                item.Name = `${item.FirstName} ${item.LastName}`;
                item.Initials = that.GetInitials(item.Name);
                item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                item.RefStatusId = item.RefStatusId;
                item.Id = item.Id;
                item.AmountPaid = item.AmountPaid;
                item.Picture = item.Picture ? item.Picture : null;
                item.StatusName = StatusMaster[item.RefStatusId].toString();
                item.Name = item.Name;
              });
            } else {
              this.NoReferral = (this.referrals.length > 0) ? false : true;
            }
            this.ShowrefcountLoader = false;
            this.LoadMoreReferal = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.ShowrefcountLoader = false;
            this.LoadMoreReferal = true;
          }
        );
      }
  }
  // approve claim user referal
  public ApprovClaim(ReferralId, BankAccountId, Amount) {
    this.ShowrefcountLoader = true;
    this.userService.ApprovClaim(this.AgencyId, ReferralId, BankAccountId, Amount)
      .subscribe(
        result => {
          if (result.Status) {
            this.toasting('Cliam Approve Successfully', 2000, 'green');
            this.loadAllReferral(this.AgencyId, this.LoadMoreCounter, this.LocationId);
            this.ShowrefcountLoader = false;
          } else {
            this.toasting(`${result.Message}`, 2000, 'red');
          }
          this.ShowrefcountLoader = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
          this.ShowrefcountLoader = false;
        }
      );
  }
  // scroll down referal from server
  public onScrollDown() {
    if (this.referrals.length % 50 === 0) {
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.loadAllReferral(this.AgencyId, this.LoadMoreCounter, this.LocationId);
    }
  }
  // sort referal data from server
  ServerSort(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKey === sortKey) {
      this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.SortKey = sortKey;
    } else {
      this.SortOrder = 'ASC';
      this.SortKey = sortKey;
    }
    this.loadAllReferral(this.AgencyId, this.LoadMoreCounter, this.LocationId);
  }
  // claim referal user
  public ClaimReferral(ReferralIds, BankAccountId) {
    const that = this;
    this.userService.ClaimReferral(ReferralIds, BankAccountId, this.StripeCustomerId, 1)
      .subscribe(
        result => {
          if (result.Status) {
            this.toasting('Cliam Approve Successfully', 2000, 'green');
            this.loadAllReferral(that.AgencyId, this.LoadMoreCounter, this.LocationId);
            this._EventBus.emit(
              {
                Type: 'refreshpendingref',
                data: { ReferralIds: ReferralIds, BankAccountId: BankAccountId }
              });
          } else {
            this.toasting(`${result.Message}`, 2000, 'red');
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // get initial user by name
  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }
  // get class for listing
  public GetClass() {
    if (this.Count === 1) {
      return 'iconUser noImg cyan lighten-3';
    } else if (this.Count === 2) {
      return 'iconUser noImg cyan lighten-5';
    } else if (this.Count === 3) {
      return 'iconUser noImg pink lighten-4';
    }
  }
  // logout user
  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }

  public LogoutTokbox() {
    myExtObject.logout();
  }
  // open agent view referal
  OpenAgentView() {
    this.wasClicked = !this.wasClicked;
  }
}

