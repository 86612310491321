import { Component, OnInit, EventEmitter, Output, ɵConsole } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../model/user.service';
import { toast } from 'angular2-materialize';
import { AgencyMatrixSaveData } from '../../model/agencyMatrix';
import { Router } from '@angular/router';
import { EventBus } from '../../shared/EventBus/EventBus';

@Component({
  selector: 'app-account-not-exist-register',
  templateUrl: './account-not-exist.component.html',
  styleUrls: ['./account-not-exist.component.css']
})
export class AccountNotExistComponent implements OnInit {
  ShowLoader = false;
  AgencyId: number;
  UserId: number;
  UserModel: any;
  AgencyMatrixToken: string;
  registerNewAccountMatrix: FormGroup;
  AgencyGuid: string;
  modelAgencyMatix3Params = [
    {
        dismissible: false
    }
  ];
  modalActions5 = new EventEmitter<string | MaterializeAction>();
  // output event to parent component
  @Output() openParentModel = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private _userService: UserService,
              private _router: Router, private _eventBus: EventBus) {
    this.UserModel = this._userService.decodeToken();
    // set value agency matrix register account
    if (this.UserModel && this.UserModel.user) {
      this.AgencyId = this.UserModel.user.AgencyId;
      this.UserId = this.UserModel.user.UserId;
      this.AgencyGuid = this.UserModel.user.AgencyGuid;
    } else {
      localStorage.removeItem('frontend-token');
      localStorage.removeItem('StripeCust');
      this._router.navigate(['/login']);
    }
  }

  ngOnInit() {
    // register agency matrix form
    this.registerNewAccountMatrix = this.formBuilder.group({
        'AgencyName': [this.UserModel.user.AgencyName, [Validators.required]],
        'Name': [this.UserModel.user.FirstName + ' ' + this.UserModel.user.LastName, [Validators.required]],
        'Email': [this.UserModel.user.Email, [Validators.required, Validators.email]],
        'Phone': [this.UserModel.user.Phone, [Validators.required]],
        'Password': ['', [Validators.required]],
        'ConfirmationPassword': ['', [Validators.required]]
      }, {
        validator: this.passwordConfirming
     });
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // open agency matrix link form
  openAgencyMatrixNotExist() {
    this.modalActions5.emit({ action: 'modal', params: ['open'] });
  }
  // open parent modal agency matrix description
  openParentModelClick() {
    this.modalActions5.emit({ action: 'modal', params: ['close'] });
    this.openParentModel.emit({'close': true, 'openNewAccount': false, 'openAleadyExist': false, 'thankyou': false});
  }
  // open link existing account modal
  linkExistingAccount() {
    this.openParentModel.emit({'close': false, 'openNewAccount': false, 'openAleadyExist': true, 'thankyou': false});
  }
  // close not exist modal
  closeNotExistModel() {
    this.modalActions5.emit({ action: 'modal', params: ['close'] });
  }
  // register new account with agency matrix
  integrationDone(formValues) {
    const RequestData = new AgencyMatrixSaveData();
    RequestData.AgencyName = formValues.AgencyName;
    RequestData.Name = formValues.Name;
    RequestData.Email = formValues.Email;
    RequestData.Phone = formValues.Phone;
    RequestData.Password = formValues.Password;
    RequestData.ConfirmPassword = formValues.ConfirmationPassword;
    RequestData.AgencyId = this.AgencyId;
    RequestData.UserId = this.UserId;
    RequestData.AgencyGuid = this.AgencyGuid;
    this.ShowLoader = true;
    this._userService.addAgencyMatrixAccount(RequestData).subscribe(
      (responseMatrix) => {
        if (responseMatrix.Status) {
          this.toasting('Your account is linked successfully', 2000, 'green');
          this.modalActions5.emit({ action: 'modal', params: ['close'] });
          if (this.UserModel.user.RoleId === 6) {
            if (!this.UserModel.user.IsPageSet) {
              localStorage.setItem('custome-setting', JSON.stringify(true));
              this._router.navigate(['/agency/page-setting']);
            } else {
              this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
              this._router.navigate(['/agency/dashboard']);
            }
          } else {
            this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
            this._router.navigate(['agent/dasboard']);
          }
        } else {
          this.toasting(responseMatrix.Message, 2000, 'red');
          if (responseMatrix.Message === 'Error occured during execution') {
            this.modalActions5.emit({ action: 'modal', params: ['close'] });
            if (this.UserModel.user.RoleId === 6) {
              if (!this.UserModel.user.IsPageSet) {
                localStorage.setItem('custome-setting', JSON.stringify(true));
                this._router.navigate(['agency/page-setting']);
              } else {
                this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
                this._router.navigate(['agency/dashboard']);
              }
            } else {
              this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
              this._router.navigate(['agent/dasboard']);
            }
          }
        }
        this.ShowLoader = false;
      }, (errorMatrix) => {
        if (errorMatrix.Data) {
          const arrayError = [];
          errorMatrix.Data.forEach((element) => {
              if (element && element.length) {
                for (const propName in element) {
                  if (errorMatrix.Data.hasOwnProperty(propName)) {
                    arrayError.push(element[propName]);
                  }
                }
              }
          });
          if (arrayError) {
            this.toasting(arrayError.join(', ').replace(/"|_/g,  ''), 2000, 'red');
          } else {
            this.toasting(errorMatrix.Message, 2000, 'red');
          }
        } else {
          this.toasting(errorMatrix.Message, 2000, 'red');
        }
        this.ShowLoader = false;
      }
    );
  }
  // custom validation for confirming password;
  passwordConfirming(c: AbstractControl): { ConfirmPassword: boolean } {
    if (c.get('Password').value !== c.get('ConfirmationPassword').value) {
      c.get('ConfirmationPassword').setErrors( {ConfirmPassword: true} );
    } else {
        return null;
    }
  }
}
