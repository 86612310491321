import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../model/user.service';
import { EventBus } from '../EventBus/EventBus';

declare var myExtObject: any;

@Component({
  selector: 'app-ax-sidebar',
  templateUrl: './sidebar.component.html'
})

export class SidebarComponent implements OnInit {
  LoggedInUserName: string;
  LoggedInUserRoleId: string;
  LoggedInUserImageUrl: string;
  vars: any = [];
  AgencyLogo: string;
  AgencyId: number;
  sidebarStatus: boolean;
  UnseenMessageCount = 0;
  LoginUserId: number;
  BlockMultipleRequest = true;
  constructor(private _EventBus: EventBus, private _router: Router,
    private _userService: UserService, private _ngZone: NgZone) {
    // get agency/user detail from token
    const UserModel = this._userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.AgencyId = UserModel.user.AgencyId;
      this.LoginUserId = UserModel.user.UserId;
      this.GetUnreadMessageCount();
    }

    window['angularComponentRefUserChatCount'] = {
      zone: this._ngZone,
      RefreshConverstation: (value) => this.RefreshConverstation(value)
    };
  }
  // get agency referal
  public GetAgencyReferalDetail() {
    this._userService.GetAgencyInitialData(this.AgencyId).subscribe(result => {
      if (result.Status) {
        if (result.Data) {
          if (result.Data.AgencyLogo) {
            this.AgencyLogo = result.Data.AgencyLogo;
          }
        }
      }
    }, error => {
      if (error.Message === 'Token expired') {
        localStorage.removeItem('frontend-token');
        localStorage.removeItem('StripeCust');
        this._router.navigate(['/login']);
      }
    });
  }

  ngOnInit() {
    this._EventBus.on().subscribe((m: any) => {
      // Toggle left bar on mobile
      if (m.filterBy.Type === 'toggleressidebar') {
        if (m.filterBy.data.sidebarStatus) {
          this.sidebarStatus = true;
        }
      } else if (m.filterBy.Type === 'MessageReceivedData' ) {
        this.RefreshConverstation(m.filterBy.data.count);
      }
    });
    this.GetAgencyReferalDetail();
  }

  public showlist() {
    this._router.navigate(['user/list']);
  }

  public showCall() {
    this._router.navigate(['user/call']);
  }

  public showMessage() {
    this._router.navigate(['user/message']);
  }

  public LogoutTokbox() {
    myExtObject.logout(true);
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }

  public GetUnreadMessageCount() {
    if (this.BlockMultipleRequest) {
      this.BlockMultipleRequest = false;
      this._userService.GetUnreadMessageCount(this.LoginUserId)
        .subscribe((response) => {
            if (response.Status) {
              this._ngZone.run( () => {
                this.UnseenMessageCount = response.Data.MessageCount;
              });
            }
            this.BlockMultipleRequest = true;
        }, (error) => { this.BlockMultipleRequest = true; }
      );
    }
  }

  public MessageReceived(value) {
    this.GetUnreadMessageCount();
  }

  public RefreshConverstation(obj) {
    if (obj && obj.hasOwnProperty('count')) {
      this.UnseenMessageCount = obj.count;
    } else {
      this.GetUnreadMessageCount();
    }
  }
}
