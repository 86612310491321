import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { EventBus } from '../../shared/EventBus/EventBus';
import { Router } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import { Activity } from '../../model/activity';
import { DomSanitizer } from '@angular/platform-browser';
declare var moment: any;
declare var $: any;
declare var myExtObject: any;

@Component({
    templateUrl: './agencyadmindashboard.component.html'
})

export class AgencyAdminDahsboardComponent implements OnInit, AfterViewInit {
    @ViewChild('DataCheck') DataCheck: any;
    @ViewChild('addagencymatrixagency') addagencymatrixagency: any;
    @ViewChild('signPreviewModal') signPreviewModal: any;
    public users = [];
    Activity: Activity[];
    ActivityData: Activity;
    UserLoading: boolean;
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    TotalUsers: string;
    Count: number;
    LoginUserId: number;
    AgencyId: number;
    LocationId: number;
    LanguageFlag: string;
    LoadMoreLoader: boolean;
    LoadMoreCounter: number;
    NotUserFound: boolean;
    CallCountSum: number;
    PaymentCountSum: number;
    SignedDocCountSum: number;
    AgencyCallData: any;
    AgencySignedDocData: any;
    AgencyPaymentsData: any;
    AllDataCount: number;
    AgencyName: string;
    AgencyNewUsersData: any;
    AgencyNewUsersCount: number;
    ShowLoader: number;
    FilterType: string;
    FilterTypeName: string;
    AllActivityCount = 0;
    SeenActivityCount = 0;
    UnSeenActivityCount = 0;
    LoadMoreActivityCounter = 0;
    ShowActivityLoader: boolean;
    FromDate: any;
    ToDate: any;
    NotDocumentFound: boolean;
    ShowDocumentLoader: boolean;
    documents = [];
    TotalPendingDocuments = 0;
    constructor(private _userService: UserService, private _EventBus: EventBus,
        private _router: Router, private _domSanitizer: DomSanitizer) {
        this.FilterType = 'week';
        this.FilterTypeName = 'week';
        const startDate = new Date('2018-6-01'); // YYYY-MM-DD
        const endDate = new Date('2018-6-07'); // YYYY-MM-DD
        this.FromDate = new Date('2018-6-01');
        this.ToDate = new Date('2018-6-07');
        const getDateArray = function (start, end) {
            const arr = new Array();
            const dt = new Date(start);
            while (dt <= end) {
                const dateCheck = new Date(dt);
                const date = dateCheck.getDate();
                const month = dateCheck.getMonth() + 1;
                const year = dateCheck.getFullYear();
                const f_date = year + '-' + month + '-' + date;
                arr.push(new Date(dt));
                dt.setDate(dt.getDate() + 1);
            }
            return arr;
        };

        this.Activity = [];
        this.AllActivityCount = 0;
        this.SeenActivityCount = 0;
        this.UnSeenActivityCount = 0;
        const dateArr = getDateArray(startDate, endDate);
        this.LoadMoreCounter = 0;
        const UserModel = this._userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoginUserId = UserModel.user.UserId;
            this.AgencyId = UserModel.user.AgencyId;
            this.AgencyName = UserModel.user.LocationName;
            if (this._userService.GetSelectedLocation() !== 0) {
                this.LocationId = Number(this._userService.GetSelectedLocation());
            } else {
                this.LocationId = UserModel.user.LocationId;
            }
        }

        this.Count = 1;
        this.NotUserFound = false;
        if (this.LoginUserId && this.LoginUserId > 0) {
            this._EventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
        }
        const { firstDay, lastDay } = GetMonthFirstAndLastDay();
        const StartDateOfMonth = firstDay;
        const EndDateOfMonth = lastDay;
        this.FromDate = firstDay;
        this.ToDate = lastDay;

        this.loadAllDashboardData(StartDateOfMonth, EndDateOfMonth);
        this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter);
        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'locationChanged') {
                this.AllDataCount = 0;
                this.LoadMoreCounter = 0;
                this.LocationId = Number(m.filterBy.data.LocationId);
                if (this._router.url === '/agency/dashboard') {
                    this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter);
                    this.loadAllDashboardData(StartDateOfMonth, EndDateOfMonth);
                    this.GetAgencyActivities(true, this.LoadMoreActivityCounter);
                    this.getUnsignedDocumentsUsers(this.AgencyId, this.LocationId);
                }
            } else if (m.filterBy.Type === 'GET_UPDATED_ACTIVITY_TRIGGERED') {
                this.Activity = m.filterBy.data.data;
                this.AllActivityCount = m.filterBy.data.AllActivityCount;
                this.SeenActivityCount = m.filterBy.data.SeenActivityCount;
                this.UnSeenActivityCount = m.filterBy.data.UnSeenActivityCount;
            }
        });
        this.GetAgencyActivities(true, this.LoadMoreActivityCounter);
        this.getUnsignedDocumentsUsers(this.AgencyId, this.LocationId);
        myExtObject.initializeTokbox();
    }

    ngAfterViewInit() {
    }

    public RefreshActivityCount() {
        if (this.Activity && this.Activity.length > 0) {
            const acivity = this.Activity[0];
            this.AllActivityCount = acivity.TotalActivityCount;
            this.UnSeenActivityCount = acivity.UnSeenCount;
            this.SeenActivityCount = acivity.SeenCount;

        } else {
            this.AllActivityCount = 0;
            this.SeenActivityCount = 0;
            this.UnSeenActivityCount = 0;
        }
    }

    public GetAgencyNewUsers(LocationId, LoadMoreCounter, StartDateOfWeek, EndDateOfWeek, AgencyId) {
        const StartMonth = ('0' + (new Date(StartDateOfWeek).getMonth() + 1)).slice(-2);
        let Startdate = new Date(StartDateOfWeek).getFullYear() + '-' + StartMonth + '-' + new Date(StartDateOfWeek).getDate();
        Startdate = new Date(StartDateOfWeek).getFullYear() + '-' + StartMonth + '-' +
            new Date(StartDateOfWeek).getDate() + ' 00:00:00.000';
        const EndMonth = ('0' + (new Date(EndDateOfWeek).getMonth() + 1)).slice(-2);
        const Enddate = new Date(EndDateOfWeek).getFullYear() + '-' + EndMonth + '-' + new Date(EndDateOfWeek).getDate() + ' 23:59:00.000';
        this._userService.GetAgencyNewUsers(LocationId, LoadMoreCounter, Startdate, Enddate, AgencyId)
            .subscribe(
                result => {
                    let UsersCountSum = 0;
                    if (result.Status) {
                        if (result.Data.length > 0) {
                            this.AllDataCount += 1;
                            this.AgencyNewUsersData = result.Data;
                            result.Data.forEach(function (item) {
                                UsersCountSum += item.UserCount;
                            });
                        }
                        this.AgencyNewUsersCount = UsersCountSum;
                    }
                },
                error => {}
            );

    }

    callDirectiveFunction() {

        this.DataCheck.callFromMainComponent();
    }


    loadAllDashboardData(StartDate, EndDate) {

        this.AllDataCount = 0;

        this.ShowLoader = 1;

        this.GetAgencyCallData(this.LocationId, this.LoadMoreCounter, StartDate, EndDate, this.AgencyId);

        this.GetAgencyDashSignDocData(this.LocationId, this.LoadMoreCounter, StartDate, EndDate, this.AgencyId);

        this.GetAgencyDashPaymentData(this.LocationId, this.LoadMoreCounter, StartDate, EndDate, this.AgencyId);

        this.GetAgencyNewUsers(this.LocationId, this.LoadMoreCounter, StartDate, EndDate, this.AgencyId);

    }

    GetDataByDateRange() {

        this.FilterType = 'date range';

        $('.weekActive').removeClass('active');
        $('.monthActive').removeClass('active');
        $('.yearActive').removeClass('active');
        $('.dateRangeActive').removeClass('active');
        $('.dateRangeActive').addClass('active');
    }

    GetWeekData() {
        $('#openDateRange').text('Date Range');
        $('.weekActive').addClass('active');
        $('.monthActive').removeClass('active');
        $('.yearActive').removeClass('active');
        $('.dateRangeActive').removeClass('active');
        $('.dateRangeActive').removeClass('active');

        this.FilterType = 'week';
        const { wkStart, wkEnd } = GetWeekStartAndEndDate();
        const StartDateOfWeek = wkStart;
        const EndDateOfWeek = wkEnd;
        this.FromDate = wkStart;
        this.ToDate = wkEnd;
        this.loadAllDashboardData(StartDateOfWeek, EndDateOfWeek);
        this.GetAgencyActivities(true, this.LoadMoreActivityCounter);
    }

    GetMonthData() {
        $('#openDateRange').text('Date Range');
        this.FilterType = 'month';
        const { firstDay, lastDay } = GetMonthFirstAndLastDay();
        const StartDateOfMonth = firstDay;
        const EndDateOfMonth = lastDay;
        this.FromDate = firstDay;
        this.ToDate = lastDay;
        this.loadAllDashboardData(StartDateOfMonth, EndDateOfMonth);
        $('.weekActive').removeClass('active');
        $('.monthActive').addClass('active');
        $('.yearActive').removeClass('active');
        $('.dateRangeActive').removeClass('active');
        $('.dateRangeActive').removeClass('active');
        this.GetAgencyActivities(true, this.LoadMoreActivityCounter);
    }

    GetYearData() {

        this.FilterType = 'year';
        $('#openDateRange').text('Date Range');
        $('.weekActive').removeClass('active');
        $('.monthActive').removeClass('active');
        $('.yearActive').addClass('active');
        $('.dateRangeActive').removeClass('active');
        $('.dateRangeActive').removeClass('active');
        const curr = new Date();
        const year = curr.getFullYear();
        const firstDateOfYear = new Date(year, 0, 1);
        const lastDateOfYear = new Date(new Date().getFullYear(), 11, 31);
        this.loadAllDashboardData(firstDateOfYear, lastDateOfYear);
        this.FromDate = firstDateOfYear;
        this.ToDate = lastDateOfYear;
        this.GetAgencyActivities(true, this.LoadMoreActivityCounter);
    }


    public GetAgencyCallData(LocationId, LoadMoreCounter, StartDateOfWeek, EndDateOfWeek, AgencyId) {
        const StartMonth = ('0' + (new Date(StartDateOfWeek).getMonth() + 1)).slice(-2);
        const Startdate = new Date(StartDateOfWeek).getFullYear() + '-' + StartMonth + '-' +
            new Date(StartDateOfWeek).getDate() + ' 00:00:00.000';
        const EndMonth = ('0' + (new Date(EndDateOfWeek).getMonth() + 1)).slice(-2);
        const Enddate = new Date(EndDateOfWeek).getFullYear() + '-' + EndMonth + '-' +
            new Date(EndDateOfWeek).getDate() + ' 23:59:00.000';
        this._userService.GetAgencyCallData(LocationId, LoadMoreCounter, Startdate, Enddate, AgencyId)
            .subscribe(
                result => {
                    let CallCountSum = 0;
                    if (result.Status) {
                        if (result.Data.length > 0) {
                            this.AllDataCount += 1;
                            this.AgencyCallData = result.Data;
                            result.Data.forEach(function (item) {
                                CallCountSum += item.CallCount;
                            });
                        }
                        this.CallCountSum = CallCountSum;
                    }
                },
                error => {}
            );
    }

    public GetAgencyDashSignDocData(LocationId, LoadMoreCounter, StartDateOfWeek, EndDateOfWeek, AgencyId) {
        const StartMonth = new Date(StartDateOfWeek).getMonth() + 1;
        const Startdate = new Date(StartDateOfWeek).getFullYear() + '-' + StartMonth + '-' +
            new Date(StartDateOfWeek).getDate() + ' 00:00:00.000';
        const EndMonth = new Date(EndDateOfWeek).getMonth() + 1;
        const Enddate = new Date(EndDateOfWeek).getFullYear() + '-' + EndMonth + '-' + new Date(EndDateOfWeek).getDate() + ' 23:59:00.000';
        this._userService.GetAgencyDashSignDocData(LocationId, LoadMoreCounter, Startdate, Enddate, AgencyId)
            .subscribe(
                result => {
                    let SignedDocCountSum = 0;
                    if (result.Status) {
                        if (result.Data.length > 0) {
                            this.AllDataCount += 1;
                            this.AgencySignedDocData = result.Data;
                            result.Data.forEach(function (item) {
                                SignedDocCountSum += item.DocCount;
                            });
                        }
                        this.SignedDocCountSum = SignedDocCountSum;
                    }
                },
                error => {}
            );
    }

    public GetAgencyDashPaymentData(LocationId, LoadMoreCounter, StartDateOfWeek, EndDateOfWeek, AgencyId) {
        const StartMonth = new Date(StartDateOfWeek).getMonth() + 1;
        const Startdate = new Date(StartDateOfWeek).getFullYear() + '-' + StartMonth + '-' +
            new Date(StartDateOfWeek).getDate() + ' 00:00:00.000';
        const EndMonth = new Date(EndDateOfWeek).getMonth() + 1;
        const Enddate = new Date(EndDateOfWeek).getFullYear() + '-' + EndMonth + '-' + new Date(EndDateOfWeek).getDate() + ' 23:59:00.000';
        this._userService.GetAgencyDashPaymentData(LocationId, LoadMoreCounter, Startdate, Enddate, AgencyId)
            .subscribe(
                result => {
                    let PaymentCountSum = 0;
                    if (result.Status) {
                        if (result.Data.length > 0) {
                            this.AllDataCount += 1;
                            this.AgencyPaymentsData = result.Data;
                            result.Data.forEach(function (item) {
                                PaymentCountSum += item.Revenue;
                            });
                        }
                        this.PaymentCountSum = Number(PaymentCountSum.toFixed(0));
                    }
                },
                error => {}
            );
    }

    public loadAllUsers(RoleId, OffSet) {
        const that = this;
        if (OffSet === 0) { that.UserLoading = true; }
        const Search = '';
        this._userService.getAll(RoleId, OffSet, this.AgencyId, this.LocationId, true, Search, '', '')
            .subscribe(
                result => {
                    that.LoadMoreLoader = true;
                    if (result.Status) {
                        if (result.Data.length === 0) {
                            this.NotUserFound = true;
                        }
                        if (OffSet === 0) {
                            this.users = result.Data;
                            const resultData: any = [];
                            const dayArray = [];
                            for (let i: any = 0; i < this.users.length; i++) {
                                const DateRegisteredDate = this.users[i].ModifiedDateTime.split(' ');
                                this.users[i].RegisteredDate = DateRegisteredDate[0];
                            }
                            const sortedJsObjects = this.users.sort(function (a, b) {
                                const dateForSorting: any = moment(a.RegisteredDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
                                const dateForSorting1: any = moment(b.RegisteredDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
                                const FirstMatch: any = new Date(dateForSorting1);
                                const SecondMatch: any = new Date(dateForSorting);
                                return FirstMatch - SecondMatch;
                            });
                            this.users = sortedJsObjects;
                            const count = 0;
                            const arr = this.users;
                            const filtered = arr.filter(function (d) {
                                return d.IsActive === true;
                            });
                            const dataUsersFinal = filtered.slice(0, 5);
                            this.users = dataUsersFinal;
                        } else {
                            if (result.Data) {
                                result.Data.forEach(function (item) {
                                    that.users.push(item);
                                });
                            }
                        }
                        this.users.forEach(function (item) {
                            item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
                            item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
                            item.Name = `${item.FirstName} ${item.LastName}`;
                            item.Initials = that.GetInitials(item.Name);
                            item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                        });
                        this.TotalUsers = this.users.length.toString();
                    } else {
                        if (OffSet === 0) { this.NotUserFound = true; }
                    }
                    this.UserLoading = false;
                    that.LoadMoreLoader = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }

    public GetInitials(Name) {
        if (this.Count >= 3) {
            this.Count = 1;
        } else {
            this.Count = this.Count + 1;
        }
        const splittedName = Name.split(' ', 3);
        if (splittedName.length > 1) {
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
        } else {
            return splittedName[0].charAt(0);
        }
    }

    public GetClass() {
        if (this.Count === 1) {
            return 'iconUser noImg cyan lighten-3';
        } else if (this.Count === 2) {
            return 'iconUser noImg cyan lighten-5';
        } else if (this.Count === 3) {
            return 'iconUser noImg pink lighten-4';
        }
    }


    ngOnInit(): void {
        // myExtObject.initializeTokbox();
        const that = this;
        setTimeout(function () {
            $(function () {
                $('#openDateRange').daterangepicker({
                    locale: {
                        format: 'YYYY-MM-DD'
                    },
                    opens: 'left'
                }, function (start, end, label) {
                    that.FromDate = start;
                    that.ToDate = end;
                    that.GetAgencyActivities(true, that.LoadMoreActivityCounter);
                    that.loadAllDashboardData(start, end);
                    const selectedDate = start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD');
                    $('#openDateRange').text(selectedDate);
                });
            });
        }, 400);

        let hashes: any;
        const vars: any = [];
        let hash: any;
        let hashUri: any;
        hashUri = decodeURIComponent(window.location.href);

        hashes = hashUri.slice(hashUri.indexOf('?') + 1).split('&');


        if (hashes.length > 0) {
            for (let i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars[hash[0]] = hash[1];
            }
        }

        if (typeof (vars['code']) !== 'undefined' && vars['code'] !== null && vars['code'] !== '') {
            const AgencyId = 21;
            this._userService.StripeAuth(vars['code'], AgencyId)
                .subscribe(
                    result => {
                        if (result.Status) {

                        }
                    },
                    error => {}
                );
        }
        this._userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
    }

    GetAgencyActivities(isResetArray = false, offset = 0, activityModuleId = 0, activityType = 0) {
        const StartMonth = new Date(this.FromDate).getMonth() + 1;
        const Startdate = new Date(this.FromDate).getFullYear() + '-' + StartMonth + '-' +
            new Date(this.FromDate).getDate() + ' 00:00:00.000';
        const EndMonth = new Date(this.ToDate).getMonth() + 1;
        const Enddate = new Date(this.ToDate).getFullYear() + '-' + EndMonth + '-' + new Date(this.ToDate).getDate() + ' 23:59:00.000';
        const postData = {
            UserId: this.LoginUserId,
            OffSet: offset,
            ActivityModuleId: activityModuleId,
            ActivityType: activityType,
            AgencyId: this.AgencyId,
            LocationId: this.LocationId,
            FromDate: Startdate,
            ToDate: Enddate
        };
        if (!this.ShowActivityLoader) {
            this.ShowActivityLoader = true;
            this._userService.GetUserActivitiesForAgent(postData).subscribe((respo: any) => {
                this.ShowActivityLoader = false;
                if (isResetArray) {
                    this.Activity = [];
                }
                const activityData = respo && respo.Data ? respo.Data : [];
                activityData.forEach((element: Activity) => {
                    this.Activity.push(element);
                });
                this.RefreshActivityCount();

                // this._EventBus.emit({ Type: 'GET_UPDATED_ACTIVITY_TRIGGERED', data: this.Activity });
            }, error => {
                this.ShowActivityLoader = false;
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    this._router.navigate(['/login']);
                }
            });
        }
    }

    public GetFormatedDate(date) {
        return new Date(date).toLocaleString();
    }

    MarkedActivitiesAsRead(shouldRedirect = false) {
        this._EventBus.emit({ Type: 'MARKED_AS_READ_ACTIVITIES', data: shouldRedirect });
    }

    public onScrollDownActivities() {
        this.LoadMoreActivityCounter = this.LoadMoreActivityCounter + 50;
        this.GetAgencyActivities(false, this.LoadMoreActivityCounter);
    }

    GetFormatedDollor(dollor) {
        return Number(dollor).toFixed(2);
    }

    identifyActivity(index: number, item: Activity) {
        return item.Id;
    }
    public getUnsignedDocumentsUsers(AgencyId, LocationId) {
        this.ShowDocumentLoader = true;
        const that = this;
        this.NotDocumentFound = false;
        this._userService.getUnsignedDocumentsUsers(AgencyId, LocationId, '', '', 0, '')
          .subscribe(
            result => {
              if (result.Status) {
                if (this.LoadMoreCounter === 0) {
                  this.documents = [];
                  let count1 = 0;
                  if (result.Data) {
                    this.TotalPendingDocuments = result.Data[0].TotalUnsignedDocuments;
                    result.Data.forEach((item) => {
                        if (count1 < 10) {
                            this.documents.push(item);
                        }
                        count1++;
                    });
                  } else {
                    this.NotDocumentFound = true;
                  }
                } else {
                  if (result.Data.length) {
                    let count2 = 0;
                    this.TotalPendingDocuments = result.Data[0].TotalUnsignedDocuments;
                    result.Data.forEach((item) => {
                        if (count2 < 10) {
                            this.documents.push(item);
                        }
                        count2++;
                    });
                  }
                }
                this.documents.forEach((item) => {
                    item.UserName = (item.FirstName[0].toUpperCase() +
                    item.FirstName.slice(1)) + ' ' + (item.LastName[0].toUpperCase() + item.LastName.slice(1));
                    item.Initials = (item.FirstName[0].toUpperCase() + item.LastName[0].toUpperCase());
                    item.Name = item.Name[0].toUpperCase() + item.Name.slice(1);
                    item.DateTimeString = new Date(item.UploadedDate).toLocaleString();
                    item.ShowActions = true;
                    item.Size = this.GetCorrectSize(item.SizeInKB);
                    item.ExtensionImage = this.GetIcon(item.Extention);
                });
              } else {
                if (this.LoadMoreCounter === 0 && this.documents.length) {
                  this.NotDocumentFound = false;
                  this.documents = [];
                  this.TotalPendingDocuments = 0;
                } else {
                  this.NotDocumentFound = true;
                }
              }
              this.ShowDocumentLoader = false;
            },
            error => {
              if (error.Message === 'Token expired') {
                localStorage.removeItem('frontend-token');
                localStorage.removeItem('StripeCust');
                this._router.navigate(['/login']);
              }
              this.ShowDocumentLoader = false;
            });
      }

      public GetIcon(extention) {
        if (extention.toLowerCase().match('pdf')) {
          return 'assets/images/pdf.png';
        } else if (extention.toLowerCase().match('doc') || extention.match('docx')) {
          return 'assets/images/do.png';
        } else if (extention.toLowerCase().match('jpg') || extention.match('png') || extention.match('jpeg')) {
          return 'assets/images/jpg.png';
        } else if (extention.toLowerCase().match('xlsx') || extention.match('xls')) {
          return 'assets/images/excelr_ic.png';
        } else if (extention.toLowerCase().match('pptx')) {
          return 'assets/images/powerpoint_ic.png';
        }
      }
      public UserSignRedirect(documentKey, DocId) {
        const res: any = documentKey.split('.pdf');
        this._router.navigate(['user/usersign', res[0], DocId]);
      }
      public GetCorrectSize(FileSize) {
        if (FileSize < 1000) {
          return FileSize + ' Bytes';
        } else if (FileSize > 1000 && FileSize < 1000000) {
          return (FileSize / 1000).toFixed(2).toString() + ' KB';
        } else if (FileSize > 1000000 && FileSize < 1000000000) {
          return (FileSize / 1000000).toFixed(2).toString() + ' MB';
        } else if (FileSize > 1000000000) {
          return (FileSize / 1000000000).toFixed(2).toString() + ' GB';
        }
      }
      public UserSignPreviewRedirect(documentKey, DocId) {
        const res: any = documentKey.split('.pdf');
        this.signPreviewModal.openSignPreview(res[0], DocId);
      }
}

function GetMonthFirstAndLastDay() {
    const date1 = new Date();
    const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toUTCString();
    const lastDay = new Date(date1.getFullYear(), date1.getMonth() + 1, 0).toUTCString();
    return { firstDay, lastDay };
}

function GetWeekStartAndEndDate() {
    const CurrentDate = new Date(); // current date of week
    const currentWeekDay = CurrentDate.getDay();
    const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
    const wkStart = new Date(new Date(CurrentDate).setDate(CurrentDate.getDate() - lessDays));
    const wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
    return { wkStart, wkEnd };
}
