import { ElementRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '../../model/user.service';
import { toast } from 'angular2-materialize';
import { EventBus } from '../../shared/EventBus/EventBus';
declare var $: any;
declare var d3: any;

declare function pdfAnnotateIni(): void;
declare function SaveImageOnserver(base64Data: any, typ: any): void;

@Component({
  templateUrl: './usersign.component.html'
})

export class UserSignComponent implements OnInit, AfterViewInit {
  documentKey = '';
  DocId = 0;
  DocData: any;
  dataAnnotation: any;

  dateToday: any = '';
  /***Signature variables */
  public typedSignature = '';
  public typedInitials = '';
  public signature = 'draw';
  public singleSelectionSettings: any = {};
  public singleSelectionselectedItems: any = [];
  public singleIniSelectionselectedItems: any = [];
  public singleSelectionList: any = {};
  public signatureFonts: any = [];
  public fontSelected: any = [];
  public selectFontClass = 'font1';
  public selectFontIntiClass = 'font1';
  public signatureImage = '';
  public signatueError = false;
  /******************* */
  public config = {
      displayKey: 'itemName',
      search: false,
      limitTo: 25
  };
  public singleSelect: any = [];
  options: any = [];

  checkTypedSig() {
    if (this.typedSignature !== '') {
      this.signatueError = false;
    }
  }

  ngOnInit() {


    if ($('#ongoingCall').hasClass('active') === true) {

      $('.pdfViewer').addClass('changePdfPos');

    } else {

      $('.pdfViewer').removeClass('changePdfPos');
    }

    this.singleSelectionSettings = {
      singleSelection: true,
      enableSearchFilter: false
    };

    this.singleSelectionList = [

      { id: 1, itemName: 'Great Vibes', class: 'font1', index: 0 },
      { id: 2, itemName: 'Eagle Lake', class: 'font2', index: 1 },
      { id: 3, itemName: 'Petit Formal Script', class: 'font3', index: 2 },
      { id: 4, itemName: 'Berkshire Swash', class: 'font4', index: 3 },
      { id: 5, itemName: 'Aladin', class: 'font5', index: 4 },
    ];
    const that = this;
  //   setTimeout(function () {
  //     $('.initials_select input').addClass(that.singleSelectionList[0].class);
  //     $('.signature_select input').addClass(that.singleSelectionList[0].class);

  //     $('.initials_select ul li:nth-child(1)').addClass(that.singleSelectionList[0].class);
  //     $('.initials_select ul li:nth-child(2)').addClass(that.singleSelectionList[1].class);
  //     $('.initials_select ul li:nth-child(3)').addClass(that.singleSelectionList[2].class);
  //     $('.initials_select ul li:nth-child(4)').addClass(that.singleSelectionList[3].class);
  //     $('.initials_select ul li:nth-child(5)').addClass(that.singleSelectionList[4].class);
  //     $('.initials_select ul li:nth-child(6)').addClass(that.singleSelectionList[5].class);

  //     $('.signature_select ul li:nth-child(1)').addClass(that.singleSelectionList[0].class);
  //     $('.signature_select ul li:nth-child(2)').addClass(that.singleSelectionList[1].class);
  //     $('.signature_select ul li:nth-child(3)').addClass(that.singleSelectionList[2].class);
  //     $('.signature_select ul li:nth-child(4)').addClass(that.singleSelectionList[3].class);
  //     $('.signature_select ul li:nth-child(5)').addClass(that.singleSelectionList[4].class);
  //     $('.signature_select ul li:nth-child(6)').addClass(that.singleSelectionList[5].class);
  // }, 100);

    $('#fontNameSign').val('Great Vibes');
    $('#fontNameIni').val('Great Vibes');
    setTimeout(() => {
      this.singleSelectionselectedItems = [
        { id: 1, itemName: 'Great Vibes', class: 'font1', index: 0 }
      ];

      this.singleIniSelectionselectedItems = [
        {id: 1, itemName: 'Great Vibes', class: 'font1', index: 0 }
      ];
    });
    /*******************************************************/
    setTimeout(() => {
      localStorage.setItem('signatureEditMode', 'false');
      pdfAnnotateIni();
      localStorage.setItem(this.documentKey + '/tooltype', 'cursor');
      this.dataAnnotation = [
        {
          'type': 'stamp_button',
          'x': 75.18796992481202,
          'y': 24.06015037593985,
          'width': 160,
          'height': 40,
          'border': '3px solid #fff',
          'borderRadius': '3px',
          'backgroundColor': '#f3f3f3',
          'boxShadow': '2px 2px 2px 2px rgba(0,0,0,0.2)',
          'pageNumber': 1,
          'class': 'Annotation',
          'uuid': 'cc7986c2-cbbd-4192-8114-b3a5fdb1545a',
          'page': 1
        },
        {
          'type': 'stamp_button',
          'x': 287.21804511278197,
          'y': 42.10526315789473,
          'width': 160,
          'height': 40,
          'border': '3px solid #fff',
          'borderRadius': '3px',
          'backgroundColor': '#f3f3f3',
          'boxShadow': '2px 2px 2px 2px rgba(0,0,0,0.2)',
          'pageNumber': 1,
          'class': 'Annotation',
          'uuid': 'eb9b0d5f-c87e-4712-a692-df69129f3fd3',
          'page': 1
        }
      ];
    }, 7000);
    this.GetAnnotations();

    /*******************************************************/


    $(function () {
      $('.modal').modal();
      $('.datepicker').pickadate({
        container: 'body',
        selectMonths: true, // Creates a dropdown to control month
        selectYears: 15, // Creates a dropdown of 15 years to control year
        showClearBtn: false
      });
    }); // end of document ready

    this.dateToday = '22 May, 2018';
    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#UserSignModal').modal({ dismissible: false });
      $('#UserInitialsModal').modal({ dismissible: false });
      $('#datePickerModal').modal({ dismissible: false });
    }, 400);

    setTimeout(function () {
      $('.signature-pad--body canvas').attr('width', '700');
      $('.signature-pad--body canvas').attr('height', '250');
    }, 3000);
  }

  constructor(private _router: Router, private userService: UserService, private elementRef: ElementRef,
    private route: ActivatedRoute, private _eventBus: EventBus) {
    this._eventBus.emit({ Type: `show-authorized-header`, data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
    this.route.params.forEach((params: Params) => {
      this.documentKey = params['id'];
      this.DocId = +params['docId'];
      setTimeout(() => {
        this.documentKey = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + this.documentKey + '.pdf';
        $('#pdfUrl').val(this.documentKey);
      }, 250);
    });
  }

  public GetAnnotations() {
    this.userService.getAnnotations(this.DocId)
      .subscribe(
      result => {
        if (result.Status) {
          this.DocData = result.Data[0];
          setTimeout(() => {
            localStorage.setItem(this.documentKey + '/annotations', JSON.stringify(this.DocData.PDFAnnotation));
          }, 300);
        } else {
          this.toasting(result.Message, 2000, 'red');
        }
      },
      error => {
        if (error.Message === 'Token expired') {
          localStorage.removeItem('frontend-token');
          localStorage.removeItem('StripeCust');
          this._router.navigate(['/login']);
        }
      }
      );
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  ngAfterViewInit() {
    const a = document.createElement('script');
    a.type = 'text/javascript';
    a.src = '/assets/pdf/shared/pdf.js';
    this.elementRef.nativeElement.appendChild(a);

    const b = document.createElement('script');
    b.type = 'text/javascript';
    b.src = '/assets/pdf/shared/pdf_viewer.js';
    this.elementRef.nativeElement.appendChild(b);

    const c = document.createElement('script');
    c.type = 'text/javascript';
    c.src = '/assets/pdf/index.js?' + Math.floor(Math.random() * Math.floor(5));
    this.elementRef.nativeElement.appendChild(c);
    const e = document.createElement('script');
    e.type = 'text/javascript';
    e.src = '/assets/signature_pad/app.js';
    this.elementRef.nativeElement.appendChild(e);
    /***************************************************/
  }

  closeModal() {
    $('#UserSignModal').modal('close');
    $('.annotationLayer').css('display', 'block');
  }


  putCusrsor() {
    setTimeout(function () {
      $('#type_signature').focus();
      $('#type_initials').focus();
    }, 20);
  }

  updateTypeSign() {
  }

  hideModal() {
    $('#UserInitialsModal').modal('close');
    $('#UserSignModal').modal('close');
    $('.annotationLayer').css('display', 'block');
  }

  submitDate() {
    /****************************************************************/
    const documentId: any = this.documentKey;
    const jsonObject = JSON.parse(localStorage.getItem(documentId + '/annotations'));
    const dateUpdatingOrNot = $('#updateOrNot_date').val();
    const current_annotation = $('#current_annotation').val();
    $.each(jsonObject, function (i, obj) {
      const tCtx = $('#textCanvas')[0].getContext('2d'),
        imageElem = $('image')[0];
      tCtx.canvas.width = 300;
      tCtx.canvas.height = 250;
      tCtx.font = '30px Arial';
      tCtx.fillText($('.datepicker').val(), 0, 25);
      const dataURL: any = tCtx.canvas.toDataURL('image/png', 1.0);
      const x = obj.x;
      let y = obj.y;
      const width = obj.width;
      const height = obj.height;
      const dataPdfAnnotateId = obj.uuid;
      const transform = 'scale(1.33) rotate(0) translate(0, 0)';
      let signatureHtml = '<foreignObject x="' + x + '" y="' + y + '" width="' + width + '" height="' + height + '" transform="' + transform + '"><div xmlns="http://www.w3.org/1999/xhtml" style="width: 100%;height: 100%;font-size: 12px;display: flex;align-items: center;justify-content: center;">Signature</div></foreignObject>';

      const id = Math.round(new Date().getTime() + (Math.random() * 100));

      /*******************************************************************************/
      /* Measure the width of each image. */
      if (obj.type === 'date_button') {
        signatureHtml = '<foreignObject x="' + x + '" y="' + y + '" width="' + width + '" height="' + height + '" transform="' + transform + '"><div xmlns="http://www.w3.org/1999/xhtml" style="width: 100%;height: 100%;font-size: 12px;display: flex;align-items: center;justify-content: center;">Signature</div></foreignObject>';
        if (dateUpdatingOrNot === '1' && dataPdfAnnotateId === current_annotation) {
          const varId = '#picDate' + id;
          // creating image
          const svg = d3.selectAll('svg')
            .append('defs')
            .append('pattern')
            .attr('id', 'picDate' + id)
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 150)
            .attr('height', 85)
            .append('image')
            .attr('xlink:href', dataURL)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 150)
            .attr('height', 85);
          y = y + 4;
          const transformImage = 'scale(1.33) rotate(0) translate(' + x + ', ' + y + ')';

          // Pattern use
          d3.select('rect[data-pdf-annotate-id="' + dataPdfAnnotateId + '"]')
            .attr('height', 85)
            .attr('width', 150)
            .style('stroke', '#fff')
            .attr('x', null)
            .attr('y', null)
            .attr('not_signed', 1)
            .attr('transform', transformImage)
            .style('fill', 'url(' + varId + ')');

          // remove singature text from annotation
          d3.selectAll('foreignObject[id="' + dataPdfAnnotateId + '"]').remove();


          $('#datePickerModal').modal('close');
          $('.annotationLayer').css('display', 'block');
          /******************************************/
          $('#first_time_annotated_date').val('0');

          if (i === jsonObject.length - 1) {

          }
          /******************** add background on image ***********************/
          const transformImageUpper = 'scale(1.33) rotate(0) translate(0, 0)';
          d3.select('svg[data-pdf-annotate-page="' + obj.pageNumber + '"]').append('rect')
            .attr('x', x)
            .attr('y', y)
            .attr('width', 160)
            .attr('height', 40)
            .attr('data-pdf-annotate-page', 2)
            .attr('data-pdf-annotate-id', dataPdfAnnotateId)
            .attr('class', dataPdfAnnotateId)
            .attr('data-pdf-annotate-type', 'date_button')
            .style('fill', 'transparent')
            .attr('transform', transformImageUpper);
          $('#first_time_annotated_date').val('0');

          /*********************************************************************/

          const checkPdfAnnoateFill = 0;
          const pendingSigned = $('rect[data="not_signed_check"]').length;
          const pendingSignedCheck = $('rect[not_signed="1"]').length;
          $('#datePickerModal').modal('close');
          $('.annotationLayer').css('display', 'block');
          const type: any = 'date';
          SaveImageOnserver(dataURL, type);
        }
      }
    });

    /****************************************************************/
  }


  cancelDate() {
    $('.annotationLayer').css('display', 'block');
    $('#datePickerModal').modal('close');
  }

  fontChanged(event: any) {
    const selectFontClass = this.singleIniSelectionselectedItems;

    this.selectFontIntiClass = this.singleIniSelectionselectedItems;
    $('#fontNameIni').val($('.initials_select input').val());
    const that = this;

    // setTimeout(function () {
    //   $('.initials_select input').addClass(selectFontClass);

    //   $('.initials_select ul li:nth-child(1)').addClass(that.singleSelectionList[0].class);
    //   $('.initials_select ul li:nth-child(2)').addClass(that.singleSelectionList[1].class);
    //   $('.initials_select ul li:nth-child(3)').addClass(that.singleSelectionList[2].class);
    //   $('.initials_select ul li:nth-child(4)').addClass(that.singleSelectionList[3].class);
    //   $('.initials_select ul li:nth-child(5)').addClass(that.singleSelectionList[4].class);
    //   $('.initials_select ul li:nth-child(6)').addClass(that.singleSelectionList[5].class);

    // }, 100);
  }


  fontChangedSign(event: any) {
    const selectFontClass = this.singleSelectionselectedItems.class;
    this.selectFontClass = this.singleSelectionselectedItems.class;
    // $('#fontNameSign').val($('.signature_select input').val());
    const fontName = this.singleSelectionselectedItems.itemName;
    $('#fontNameSign').val(fontName);
    $('#signature_area').addClass(selectFontClass);
    const that = this;
    // setTimeout(function () {
    //   $('.signature_select input').addClass(selectFontClass);

    //   $('.signature_select ul li:nth-child(1)').addClass(that.singleSelectionList[0].class);
    //   $('.signature_select ul li:nth-child(2)').addClass(that.singleSelectionList[1].class);
    //   $('.signature_select ul li:nth-child(3)').addClass(that.singleSelectionList[2].class);
    //   $('.signature_select ul li:nth-child(4)').addClass(that.singleSelectionList[3].class);
    //   $('.signature_select ul li:nth-child(5)').addClass(that.singleSelectionList[4].class);
    //   $('.signature_select ul li:nth-child(6)').addClass(that.singleSelectionList[5].class);
    // }, 100);
  }

  fontChangedIni(event: any) {
    this.selectFontIntiClass = event.value.class;
    $('#fontNameIni').val(event.value.itemName);
    $('#signature_area').addClass(event.value.class);
  }

  public openIntial() {
    const that = this;
    setTimeout(() => {
      $('.initials_select ul.selected-items li:nth-child(1)').addClass(this.singleIniSelectionselectedItems.class);
      $('.initials_select ul.available-items li').each(function()
        {
          const otherFontItem = $(this).text().trim();
          const indexClass = that.singleSelectionList.findIndex((itmFont) => itmFont.itemName === otherFontItem);
          const indexClassElement = indexClass;
          $(this).addClass(that.singleSelectionList[indexClass].class);
       });
    }, 10);
  }

  public openSign() {
    const that = this;
    setTimeout(() => {
      $('.signature_select ul.selected-items li:nth-child(1)').addClass(this.singleSelectionselectedItems.class);
      $('.signature_select ul.available-items li').each(function()
        {
          const otherFontItem = $(this).text().trim();
          const indexClass = that.singleSelectionList.findIndex((itmFont) => itmFont.itemName === otherFontItem);
          const indexClassElement = indexClass;
          $(this).addClass(that.singleSelectionList[indexClass].class);
       });
    }, 10);
  }
}
