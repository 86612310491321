import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { Router } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';
import { toast } from 'angular2-materialize';
import { PlanService } from '../../model/plan';
declare var $: any;

@Component({
    templateUrl: './agencysubscriptioncancel.component.html'

})

// tslint:disable-next-line:component-class-suffix
export class AgencySubscriptionCancel implements OnInit {
    RoleId: number;
    stripetoken: any;
    public LoginUserId: number;
    constructor(private userService: UserService, private _router: Router,
        // private _planservice: PlanService,
        private _EventBus: EventBus) {
        // get Agency/user detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.RoleId = UserModel.user.RoleId;
            this.AgencyId = UserModel.user.AgencyId;
            this.LocationId = UserModel.user.LocationId;
            this.LoginUserId = UserModel.user.UserId;
            this.StripeCustomerId = UserModel.user.StripeCustomerId;
        }
        this.GetDefaultCard();
        this.GetSubscriptionDetail();
        this._EventBus.on().subscribe((m: any) => {
            if (m.filterBy.Type === 'resubscribeagencyplan') {
                this.stripetoken = m.filterBy.data;
                this.GetSubscriptionDetail();
            }
            if (m.filterBy.Type === 'resubscribecardclose') {
                this.ReSubscribing = false;
            }
        });
    }
    DefaultCardLoading: boolean;
    StripeCustomerId: string;
    DefaultCard: any;
    SubscriptionDetailLoading: boolean;
    CurrentSubsObj: any;
    AgencyId: Number;
    LocationId: Number;
    ReSubscribing: boolean;
    ngOnInit(): void {
        this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
    }
    // get subscription details agency
    public GetSubscriptionDetail() {
        this.SubscriptionDetailLoading = true;
        this.userService.GetSubscriptionDetail(this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.CurrentSubsObj = result.Data;
                        if (Number(this.CurrentSubsObj.SubscriptionPeriodEnd) > Math.floor(Date.now() / 1000)) {
                            this._router.navigate(['/agency/subscriptions']);
                        }
                        this.CurrentSubsObj.SubscriptionCreatedAt = this.timeToHuman(this.CurrentSubsObj.SubscriptionCreatedAt);
                        this.CurrentSubsObj.SubscriptionPeriodStart = this.timeToHuman(this.CurrentSubsObj.SubscriptionPeriodStart);
                        this.CurrentSubsObj.SubscriptionPeriodEnd = this.timeToHuman(this.CurrentSubsObj.SubscriptionPeriodEnd);
                        if (this.stripetoken) {
                            this.ReSubscribeAgencyPlan();
                        }
                    } else {
                        this.toasting(result.Message, 2000, 'red');
                    }
                    this.SubscriptionDetailLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.SubscriptionDetailLoading = false;
                }
            );
    }
    // show success/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // resubscribe Agency Plan
    public ReSubscribeAgencyPlan() {
        this.ReSubscribing = true;
        this.userService.ReSubscribeAgencyPlan(this.AgencyId, this.LocationId, this.StripeCustomerId,
            this.CurrentSubsObj.PlanId, this.CurrentSubsObj.AddAgentPlanId, this.CurrentSubsObj.AdditionalAgentsCount,
            this.stripetoken.token)
            .subscribe(
                result => {
                    if (result.Status) {
                        this._router.navigate(['agency/dashboard']);
                    } else {
                        this.toasting(result.Message, 2000, 'red');
                    }
                    this.ReSubscribing = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.ReSubscribing = false;
                }
            );
    }
    // show loader
    public ShowLoader() {
        this.ReSubscribing = true;
    }
    // time stamp to local time
    public timeToHuman(timeStamp) {
        const theDate = new Date(timeStamp * 1000);
        const dateString = theDate.toLocaleDateString();
        return dateString;
    }
    // get default card agency
    public GetDefaultCard() {
        this.DefaultCardLoading = true;
        this.userService.GetDefaultCard(this.StripeCustomerId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.DefaultCard = result.Data;
                    } else {
                        this.toasting(result.Message, 2000, 'red');
                    }
                    this.DefaultCardLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.DefaultCardLoading = false;
                }
            );
    }
}
