import { AfterViewChecked, ElementRef, ViewChild, Component, OnInit, HostListener, Renderer, NgZone } from '@angular/core';
import { UserService } from '../../model/user.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import '../../../assets/tokbox/js/app.js';
import { ChatHistory } from '../../model/chathistory';
import { EventBus } from '../../shared/EventBus/EventBus';
import { TokboxService } from '../../model/tokbox.service';
import { toast } from 'angular2-materialize';
declare var $: any;
declare var myExtObject: any;

@Component({
    templateUrl: './agencyadmindashboardmessage.component.html'
})

export class AgencyAdminDashboardMessageComponent implements OnInit, AfterViewChecked {
    users: User[] = [];
    public ArrayOnlineTokboxuser = [];
    Conversastion: any[];
    filter: any;
    UserLoading: boolean;
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    activeUserObject: any;
    TotalUsers: string;
    Count: number;
    LoggedInUserId: number;
    AgencyId: number;
    LocationId: number;
    LanguageFlag: string;
    LoadMoreLoader: boolean;
    LoadMoreCounter: number;
    ShowMessageLoader: boolean;
    NotConversarionFound: boolean;
    TotalConersatstion: string;
    NotCallsFound: boolean;
    NotChatFound: boolean;
    ChatHistory: ChatHistory[];
    ShowChatTable: boolean;
    IsChatHistory: boolean;
    ShowLoader: boolean;
    lastMessageDate: string;
    currentReceiverId: number;
    currentSenderId: number;
    currentUserName: string;
    selectedIdx = 0;
    conversationlistloading: boolean;
    public innerWidth: any;
    openchat: boolean;
    textareaMessage = '';
    SendButton: boolean;
    ActiveUserId = 0;
    ScrollToBottom = true;
    @ViewChild('scrollMe') public myScrollContainer: ElementRef;
    @ViewChild('userChatDiv') public myUserChatDiv: ElementRef;
    @ViewChild('ulMessages') public d1: ElementRef;
    previousUrl: string;

    ngOnInit() {
        this.clickFirst();
        if (this.userService.decodeToken().user.RoleId !== 6) {
            this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
        }
        this.userService.GetInitialSettings(this.LoggedInUserId).subscribe((response) => {
            if (response && response.Data) {
                this._eventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
        // Get device width
        this.innerWidth = window.innerWidth;
    }
    ngAfterViewChecked() {
        this.clickFirst();
    }
    // show success/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // scroll user chat histroy to last message
    scrollToBottom() {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) { }
    }
    clickFirst(): void {
        try {
            const that = this;
            that.myUserChatDiv.nativeElement.getElementsByTagName('ul')[0].focus();
        } catch (err) { }
    }
    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _router: Router,
        private _eventBus: EventBus,
        private renderer: Renderer,
        private tokboxService: TokboxService,
        private _ngZone: NgZone
    ) {
        this.LoadMoreCounter = 0;
        // get agency/admin detail from token
        const UserModel = this.userService.decodeToken();
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.LoggedInUserImageUrl = UserModel.user.Picture;
            this.LoggedInUserId = UserModel.user.UserId;
            if (this.userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId) {
                this.LocationId = Number(this.userService.GetSelectedLocation());
            } else {
                this.LocationId = UserModel.user.LocationId;
            }
            this.AgencyId = UserModel.user.AgencyId;
        }
        this.selectedIdx = 0;
        this.Count = 1;
        this.ShowLoader = false;
        this.route.params.subscribe((params) => {
            if (params['UserId']) {
                this.ActiveUserId = Number(params['UserId']);
            }
        });
        // get all user converstation list
        this.GetConversastion(this.LoadMoreCounter);
        // tokbox user online/offline status
        window['userComponentRef'] = {
            zone: this._ngZone,
            componentFn: (userId, connectionId, isConnected) => this.RefreshList(userId, connectionId, isConnected)
        };
        // tokbox message received listner
        window['angularComponentRefUserChat'] = {
            zone: this._ngZone,
            MessageReceived: (value) => this.MessageReceived(value),
            RefreshConverstation: (value) => this.RefreshConverstation(value)
        };
    }
    // refresh list of conversation on basis online/offline
    public RefreshList(UserId, ConnectionId, IsConnected) {
        const that = this;
        if (ConnectionId && IsConnected) {
            const isExists = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId) > -1;
            if (!isExists) {
                this.ArrayOnlineTokboxuser.push({ UserId: UserId, ConnectionId: ConnectionId });
            }

        } else {
            const index = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId);
            if (index > -1) {
                this.ArrayOnlineTokboxuser.splice(index, 1);
            }
        }
        if (this.activeUserObject && UserId == this.activeUserObject.UserId) {
            this.activeUserObject.ConnectionId = ConnectionId;
        }
        that.Conversastion.forEach((item) => {
            const isAgentStillConnected = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == item.UserId) > -1;
            if (isAgentStillConnected) {// If USER IS ONLINE
                item.ConnectionId = ConnectionId;
            } else {
                item.ConnectionId = '';
            }

        });
        that.Conversastion.sort(function(a, b) {
            if ( a.ConnectionId > b.ConnectionId ) {
                return -1;
            }
            if ( a.ConnectionId < b.ConnectionId ) {
                return 1;
            }
            return 0;
        });
    }
    // refresh last message in leftside user converstaion
    public RefreshConverstation(Data) {
        if (Data) {
           const UserIndex =  this.Conversastion.findIndex( (userConver) => userConver.UserId == Data.caller_userID);
           if (UserIndex !== -1) {
                this.Conversastion[UserIndex].Message = Data.textMessage;
                this.Conversastion[UserIndex].MessageDateTime = new Date().toISOString();
                if (Data.caller_userID != this.ActiveUserId) {
                    this.Conversastion[UserIndex].IsRead = 'block';
                }
           }
           if (Data.caller_userID != this.ActiveUserId) {
            this._eventBus.emit({
                Type: 'MessageReceivedData',
                data: {}
            });
           }
        }
    }
    // message received scroll scroll bar to bottom
    public MessageReceived(data) {
        if (data) {
            try {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            } catch (err) { }
        }
    }
    // get conversation agency
    public GetConversastion(OffSet) {
        const that = this;
        if (OffSet === 0) { that.ShowLoader = true; }
        that.UserLoading = true;
        that.conversationlistloading = true;
        this.userService.GetAgencyConversations(this.AgencyId, OffSet, this.LocationId)
            .subscribe(
                result => {
                    that.LoadMoreLoader = true;
                    if (result.Status) {
                        if (OffSet === 0) {
                            this.Conversastion = result.Data;
                            this.NotChatFound = (this.Conversastion && this.Conversastion.length > 0) ? false : true;
                            if (this.innerWidth > 601) {
                                this.ActiveUserId = (this.ActiveUserId) ? this.ActiveUserId : (result.Data[0].UserId);
                                this.GetChatHistrory(this.ActiveUserId, that.LoggedInUserId, result.Data[0].Name, 0, result.Data[0]);
                            }
                        } else {
                            if (result.Data) {
                                this.NotChatFound = false;
                                if (this.innerWidth > 601) {
                                    this.ActiveUserId = (this.ActiveUserId) ? this.ActiveUserId : result.Data[0].UserId;
                                    this.GetChatHistrory(this.ActiveUserId, that.LoggedInUserId, result.Data[0].Name, 0, result.Data[0]);
                                }
                                result.Data.forEach((item) => {
                                    that.Conversastion.push(item);
                                });
                            }
                        }
                        this.Conversastion.forEach((item) => {
                            item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
                            item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
                            item.Name = `${item.FirstName} ${item.LastName}`;
                            item.Initials = that.GetInitials(item.Name);
                            item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                            item.SenderId = (item.SenderId) ? item.SenderId : item.UserId;
                            item.ReceiverId = (item.ReceiverId) ? item.ReceiverId : that.LoggedInUserId;
                            item.IsRead = (item.IsRead === null) ? true : item.IsRead;
                            item.IsRead = (item.IsRead) ? 'none' : 'block';
                            item.ConnectionId = (item.ConnectionId) ? item.ConnectionId : '';
                        });
                        this.TotalConersatstion = this.Conversastion.length.toString();
                    } else {
                        if (OffSet === 0) { that.NotChatFound = true; }
                        that.ShowLoader = false;
                        if (this.Conversastion && this.Conversastion.length > 0 && OffSet === 0) {
                            this.Conversastion = [];
                            that.NotChatFound = true;
                        }
                        this.NotChatFound = true;
                    }
                    that.conversationlistloading = false;
                    that.UserLoading = false;
                    that.LoadMoreLoader = false;
                },
                error => {
                    that.ShowLoader = false;
                    that.UserLoading = false;
                    that.NotChatFound = true;
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                }
            );
    }
    // track message listing user
    public trackByFn(index, item) {
        if (index === 0) { this.lastMessageDate = ''; }
        if (this.lastMessageDate === item.MessageDate) {
            item.ShowParentDate = false;
        } else {
            item.ShowParentDate = true;
        }
        this.lastMessageDate = item.MessageDate;
    }
    // load user old message scroll up
    public onScrollUp() {
        if (this.ChatHistory.length % 50 === 0) {
            this.ScrollToBottom = false;
            this.LoadMoreCounter = this.LoadMoreCounter + 50;
            this.GetChatHistrory(this.currentSenderId, this.currentReceiverId,
            this.currentUserName, this.selectedIdx, this.activeUserObject);
        }
    }
    // get user chat history of specific user
    public GetChatHistrory(SenderId, ReceiverId, Name, index, selectedObject, shouldResetChathistory?: boolean) {
        this.openchat = true;
        if (shouldResetChathistory) {
            this.ChatHistory = [];
            this.LoadMoreCounter = 0;
        }
        if (Number(this.currentReceiverId) && this.currentReceiverId !== SenderId) {
            this.MarkasRead(this.currentSenderId, this.currentReceiverId);
        }
        this.ShowLoader = true;
        this.currentReceiverId = ReceiverId;
        this.currentSenderId = SenderId;
        this.selectedIdx = index;
        this.currentUserName = Name;
        this.LoadMoreCounter = this.LoadMoreCounter;
        this.NotCallsFound = false;
        this.NotChatFound = false;
        this.ShowChatTable = true;
        this.IsChatHistory = true;
        this.activeUserObject = selectedObject;
        this.ActiveUserId = SenderId;
        if (this.Conversastion) {
            const indexChat = this.Conversastion.findIndex(x => x.UserId === this.ActiveUserId);
            if (indexChat > -1) {
                this.activeUserObject = this.Conversastion[indexChat];
            }
        }
        setTimeout(() => {
            $('.user-msg-' + SenderId).css('display', 'none');
        }, 2000);
        localStorage.setItem('activeChatUserId', SenderId);
        const that = this;
        that.userService.getChatHistory(SenderId.toString(), ReceiverId.toString(), this.LoadMoreCounter).subscribe(
            result => {
                if (result.Status) {

                    if (that.LoadMoreCounter === 0) {
                        this.textareaMessage = '';
                        if (result.Data != null) {
                            that.ChatHistory = result.Data.reverse();
                        } else {
                            that.ChatHistory = [];
                            that.ShowChatTable = false;
                        }
                    } else {
                        if (result.Data != null) {
                            result.Data.forEach(function (item) {
                                that.ChatHistory.unshift(item);
                            });
                        }
                    }
                    that.ChatHistory.forEach(function (item) {
                        const dateTime = new Date(item.MessageDateTime).toLocaleString();
                        const date = dateTime.split(',');
                        item.MessageDate = date[0];
                        item.MessageTime = date[1];
                        item.MessageDateTime = dateTime;
                    });
                    if (this.ScrollToBottom) {
                        setTimeout(() => {
                            that.scrollToBottom();
                        }, 200);
                    }
                } else {
                    that.ChatHistory = [];
                    that.ShowChatTable = false;
                }
                that.ScrollToBottom = true;
                that.ShowLoader = false;
                if (that.ChatHistory.length > 0 && that.LoadMoreCounter === 0) {
                    this._eventBus.emit({
                        Type: 'MessageReceivedData',
                        data: {count: that.ChatHistory[0].UnreadCount}
                    });
                }
            },
            error => {
                that.ShowLoader = false;
                that.ScrollToBottom = true;
                if (error.Message === 'Token expired') {
                    localStorage.removeItem('frontend-token');
                    localStorage.removeItem('StripeCust');
                    that._router.navigate(['/login']);
                }
            });
    }
    // get initial user converstations
    public GetInitials(Name) {
        if (this.Count >= 3) {
            this.Count = 1;
        } else {
            this.Count = this.Count + 1;
        }
        const splittedName = Name.split(' ', 3);
        if (splittedName.length > 1) {
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
        } else {
            return splittedName[0].charAt(0);
        }
    }
    // get class user list
    public GetClass() {
        if (this.Count === 1) {
            return 'iconUser noImg cyan lighten-3';
        } else if (this.Count === 2) {
            return 'iconUser noImg cyan lighten-5';
        } else if (this.Count === 3) {
            return 'iconUser noImg pink lighten-4';
        }
    }
   // Check device width
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        this.openchat = false;
    }
    // send message to user
    public SendMessage() {
        let UserOnline = false;
        UserOnline = this.activeUserObject.ConnectionId ? true : false;
        if (this.textareaMessage.length > 0) {
            const UserIndex =  this.Conversastion.findIndex( (userConver) => userConver.UserId === this.currentSenderId);
            if (UserIndex !== -1) {
                    this.Conversastion[UserIndex].Message = 'You: ' + this.textareaMessage;
                    this.Conversastion[UserIndex].MessageDateTime = new Date().toISOString();
            }
            const currentdate = new Date();
            const messageHtml = '<li class="clearfix"><div class="sent"><p>' + this.textareaMessage +
            '</p><span class="timeLine">' + this.formatAMPM(currentdate) + '</span></div></li>';
            this.renderer.invokeElementMethod(this.d1.nativeElement, 'insertAdjacentHTML', ['beforeend', messageHtml]);
            this.SendButton = false;
            myExtObject.startConversation(this.currentReceiverId, this.currentSenderId, 'chatonly',
            this.textareaMessage, this.AgencyId, this.LocationId, this.LoggedInUserName);
            // send message
            this.tokboxService.SaveMessage(this.currentReceiverId, this.currentSenderId, 'chatonly',
             this.textareaMessage, this.AgencyId, this.LocationId)
                .subscribe((response) => {
                }, (error) => {
                });
            this.scrollToBottom();
            this.textareaMessage = '';
        } else if (this.textareaMessage.length === 0) {
            this.SendButton = false;
        }
    }
    // enable/disable send button according message text
    public messageEnter() {
        if (this.textareaMessage) {
            this.SendButton = true;
        } else {
            this.SendButton = false;
        }
    }
    // convert time to local time 12 hour
    public formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        const strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
        return strTime;
    }

    public MarkasRead(SenderId, ReceiverId) {
        this.userService.MarkasReadMessage(SenderId, ReceiverId)
        .subscribe((response) => {}, (error) => {});
    }
}
