import {
    Injectable
} from '@angular/core';
import {
    Observable
} from 'rxjs/Observable';
import { toast } from 'angular2-materialize';

@Injectable()
export class PushNotificationsService {
    public permission: Permission;
    public _notify: any;
    constructor() {
        this.permission = this.isSupported() ? 'default' : 'denied';
    }
    public isSupported(): boolean {
        return 'Notification' in window;
    }
    requestPermission(): void {
        const self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function (status) {
                return self.permission = status;
            });
        }
    }

    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }

    create(title: string, options?: PushNotification): any {
        const self = this;
        return new Observable(function (obs) {
            if (!('Notification' in window)) {
                self.toasting('Notifications are not available in this environment', 3000, 'red');
                // console.log('Notifications are not available in this environment');
                obs.complete();
            }
            if (self.permission !== 'granted') {
                self.toasting(`The user hasn't granted you permission to send push notifications`, 3000, 'red');
                // console.log("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            self._notify = new Notification(title, options);
            // setTimeout(self._notify.close.bind(self._notify), 4000);
            self._notify.onshow = function (e) {
                return obs.next({
                    notification: self._notify,
                    event: e
                });
            };
            self._notify.onclick = function (e) {
                window.focus();
                // // return obs.next({
                // //     notification: self._notify,
                // //     event: e
                // // });
                return self._notify.close();
            };
            self._notify.onerror = function (e) {
                return obs.error({
                    notification: self._notify,
                    event: e
                });
            };
            self._notify.onclose = function () {
                return obs.complete();
            };
        });
    }
    close() {
        if (this._notify) { this._notify.close(); }
    }
    generateNotification(source: Array<any>): void {
        const self = this;
        source.forEach((item) => {
            const options = {
                body: item.alertContent,
                icon: item.image
            };
            const notify = self.create(item.title, options).subscribe();
        });
    }
}
export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: 'auto' | 'ltr' | 'rtl';
    lang?: string;
    vibrate?: number[];
}
