import { Component, OnInit, NgZone, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../model/user.service';
import { TokboxService } from '../model/tokbox.service';
import { User } from '../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from 'ng2-translate';
import '../../assets/tokbox/js/app.js';
import { MaterializeAction, toast } from 'angular2-materialize';
import { CustomValidators } from '../helper/custom-validators';
import { EventBus } from '../shared/EventBus/EventBus';
declare var myExtObject: any;
declare var $: any;

@Component({
  templateUrl: './user.component.html'
})

export class UserComponent implements OnInit {
  wasClicked = false;
  key = 'Name';
  reverse = false;
  _signupForm: FormGroup;
  modalActions1 = new EventEmitter<string | MaterializeAction>();
  users: User[] = [];
  UserLoading: boolean;
  LoggedInUserName: string;
  LoggedInUserRoleId: string;
  LoggedInUserImageUrl: string;
  LoggedInUserDescription: string;
  TotalUsers: string;
  Count: number;
  filter: any;
  LoginUserId: number;
  LocationId: number;
  AgencyId: number;
  LanguageFlag: string;
  model1Params: any;
  LoadMoreLoader: boolean;
  NotUserFound: boolean;
  LoadMoreCounter: number;
  previousUrl: string;
  opensearch: boolean;

  refereshUserId: any;
  refereshConnectionId: any;
  private _formErrors: any;

  SaveEmailArray: any[] = [];
  ShowLoader: boolean;
  ShowLoaderForm: boolean;
  IsEmailExist: boolean;
  SubdomainName: string;
  searchText = '';
  SortKey = 'FirstName';
  SortOrder = 'ASC';
  ArrayOnlineTokboxuser = [];
  LoadMoreLoaderEnable: boolean;
  LoadMoreUsers = true;
  ngOnInit() {
    if (this.userService.decodeToken().user.RoleId !== 2) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }
    this.SubdomainName = this.userService.getSubdomain();
    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._eventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });

    this.opensearch = false;

  }

  constructor(
    private userService: UserService,
    private tokboxService: TokboxService,
    private route: ActivatedRoute,
    private _router: Router,
    public translate: TranslateService,
    private _formBuilder: FormBuilder,
    private _ngZone: NgZone,
    private _eventBus: EventBus
  ) {
    this._signupForm = _formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
      email: ['', Validators.compose([Validators.required, CustomValidators.email])],
    });
    this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.LoadMoreLoader = false;
    this.LoadMoreCounter = 0;
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
      this.LoggedInUserDescription = UserModel.user.Description ? UserModel.user.Description : '';
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LocationId = UserModel.user.LocationId;
      this.ChangeLanguage(this.translate.getDefaultLang());
      if (this.userService.GetSelectedLocation() !== 0 && UserModel.user.RoleId === 2) {
        this.LocationId = Number(this.userService.GetSelectedLocation());
      } else {
          this.LocationId = UserModel.user.LocationId;
      }
    }
    this.Count = 1;

    if (this.LoginUserId && this.LoginUserId > 0) {
      this._eventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
    }

    this._eventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'locationChanged') {
        this.LocationId = Number(m.filterBy.data.LocationId);
        this.loadAllUsers(this.LoggedInUserRoleId, 0, this.searchText, this.SortKey, this.SortOrder, this.LocationId);
      }
    });

    this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder, this.LocationId);

    window['userComponentRef'] = {
      zone: _ngZone,
      componentFn: (userId, connectionId, isConnected) => this.RefreshList(userId, connectionId, isConnected),
      component: this,
    };
  }
  // show success/error message fronend
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  closeAddAgent() {
    this.modalActions1.emit({ action: 'modal', params: ['close'] });
  }
  public onSubmit(elementValues: any) {
    this.ShowLoader = true;
    const that = this;
    const isValid = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(elementValues.email);

    if (!isValid) {
      that.toasting('Please fill Valid Email Address', 2000, 'red');
      this.ShowLoader = false;
      this.IsEmailExist = true;
      event.preventDefault();
      return false;

    } else {

      this.userService.CheckEmail(elementValues.email.trim())
        .subscribe(
          result => {
            if (result.Data[0].ResStatus === 2) {
              that.toasting('An account with this email already exists in our system', 2000, 'red');
              that.ShowLoader = false;
              event.preventDefault();
              return false;
            } else {
              let MyFName: string;
              let MyLName: string;
              let MyEmail: string;
              MyFName = elementValues.firstName;
              MyLName = elementValues.lastName;
              MyEmail = elementValues.email;
              const itm = { 'Fname': MyFName, 'lname': MyLName, 'Email': MyEmail };
              that.SaveEmailArray.push(itm);
              const dataAgent = JSON.stringify(that.SaveEmailArray);
              const DomainName = that.userService.getDomain();

              that.userService.InviteAgent(that.AgencyId, dataAgent, that.SubdomainName,
                 that.LocationId, DomainName, 3, [that.LocationId], 0)
                .subscribe(
                  dbresult => {
                    if (dbresult.Status) {
                      that.SaveEmailArray = [];
                      this.closeAddAgent();
                      this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter,
                        this.searchText, this.SortKey, this.SortOrder, this.LocationId);
                      this.ShowLoader = false;
                    } else {
                      that.toasting('Some Error occur while adding records,Please try again', 2000, 'red');
                      that.SaveEmailArray = [];
                      this.ShowLoader = false;
                      return false;
                    }
                  },
                  error => {
                    if (error.status === 422) {
                      const errorFields = JSON.parse(error.data.message);
                      that.SaveEmailArray = [];
                      this.ShowLoader = false;
                    } else {
                      that.toasting('Some Error occur while adding records', 2000, 'red');
                      this.ShowLoader = false;
                      that.SaveEmailArray = [];
                      return false;
                    }
                  });
            }
          },
          error => {
            if (error.status === 422) {
              const errorFields = JSON.parse(error.data.message);
              that.SaveEmailArray = [];
            } else {
              that.ShowLoader = false;
              that.toasting('Some Error occur while adding records', 2000, 'red');
              that.SaveEmailArray = [];
              return false;
            }
          });
    }
  }

  private _resetFormErrors(): void {
    this._formErrors = {
      firstName: { valid: true, message: '' },
      lastName: { valid: true, message: '' },
      email: { valid: true, message: '' }
    };
  }

  public _isValid(field): boolean {
    let isValid: boolean;
    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._signupForm.controls[field].touched === false) {
      isValid = true;
    } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
      isValid = true;
    }
    return isValid;
  }

  public onValueChanged(data?: any) {
    if (!this._signupForm) { return; }
    const form = this._signupForm;
    for (const field in this._formErrors) {
      if (this._formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty) {
          this._formErrors[field].valid = true;
          this._formErrors[field].message = '';
        }
      }
    }
  }

  openAddAgent() {
    this.modalActions1.emit({ action: 'modal', params: ['open'] });
  }

  public ChangeLanguage(value) {
    if (value === 'English') {
      this.LanguageFlag = 'assets/flags/US.png';
    } else {
      this.LanguageFlag = 'assets/flags/BL.png';
    }
    this.translate.use(value);
  }

  public StartCall(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio) {
    $('.loader-outer').css('display', 'inline-flex');
    // Generate new session and Token
    this.GenerateSessionIdAndTokenId(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio);
  }

  public EndCall() {
    myExtObject.end();
  }

  public onScrollDown() {
    if (this.users.length % 50 === 0 && this.LoadMoreUsers === true) {
      this.LoadMoreLoaderEnable = true;
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder, this.LocationId);
    }
  }

  public LogoutTokbox() {
    myExtObject.logout();
  }

  public GenerateSessionIdAndTokenId(ToUserId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio) {
    this.tokboxService.createsessionandtoken(this.LoginUserId, ToUserId)
      .subscribe(
        result => {
          if (result.Status) {
            myExtObject.start(result.sessionId, result.TokenId1, result.TokenId2,
              ToUserId, result.currentSessionId, ToUserPic, ToInitial, ToName, ToDescription, IsAudio);
          } else {
            $('.loader-outer').css('display', 'none');
            alert(result.Message);
          }
        },
        error => {

        }
      );
  }

  public RefreshList(UserId, ConnectionId, IsConnected) {
    this.refereshUserId = UserId;
    this.refereshConnectionId = ConnectionId;

    if (ConnectionId && IsConnected) {
      const isExists = this.ArrayOnlineTokboxuser.
      findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId) > -1;
      if (!isExists) {
        this.ArrayOnlineTokboxuser.push({ UserId: UserId, ConnectionId: ConnectionId });
      }
    } else {
      const index = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == UserId && x.ConnectionId == ConnectionId);
      if (index > -1) {
        this.ArrayOnlineTokboxuser.splice(index, 1);
      }
    }

    this.users.forEach((item) => {
      const isAgentStillConnected = this.ArrayOnlineTokboxuser.findIndex(x => x.UserId == item.UserId) > -1;
      if (isAgentStillConnected) {// If USER IS ONLINE
        item.ConnectionId = ConnectionId;
      } else {
        item.ConnectionId = '';
      }
    });
  }

  public loadAllUsers(RoleId, OffSet, Search, sortKey, sortOrder, LocationId) {
    if (this.LoadMoreUsers === true || this.searchText !== Search) {
      this.LoadMoreUsers = false;
      const that = this;
      if (OffSet === 0) { that.UserLoading = true; }
      this.userService.getAll(RoleId, OffSet, this.AgencyId, LocationId, true, Search, sortKey, sortOrder)
        .subscribe(
          result => {
            that.LoadMoreLoader = true;
            if (result.Status) {
              if (OffSet === 0) {
                this.users = [];
                this.users = result.Data;
              } else {
                if (result.Data) {
                  result.Data.forEach(function (item) {
                    that.users.push(item);
                  });
                }
              }
              this.users.forEach((item) => {
                item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
                item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
                item.Name = `${item.FirstName} ${item.LastName}`;
                item.Initials = that.GetInitials(item.Name);
                item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
                item.Description = this.LoggedInUserDescription;
              });
              this.TotalUsers = this.users.length.toString();
              this.TotalUsers = this.users && this.users.length ? this.users[0].UserCounts.toString() : '0';
              if (this.users.length === 0) {
                this.NotUserFound = true;
              }
            } else {
              if (OffSet === 0) { this.NotUserFound = true; }
            }
            this.UserLoading = false;
            that.LoadMoreLoader = false;
            this.LoadMoreLoaderEnable = false;
            this.LoadMoreUsers = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.LoadMoreUsers = true;
          }
        );
    }
  }

  public showuserdetail(UserId) {
    this._router.navigate(['user/detail', UserId]);
  }

  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }

  public GetClass() {
    if (this.Count === 1) {
      return 'iconUser noImg cyan lighten-3';
    } else if (this.Count === 2) {
      return 'iconUser noImg cyan lighten-5';
    } else if (this.Count === 3) {
      return 'iconUser noImg pink lighten-4';
    }
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }

  sort(property) {
    this.reverse = !this.reverse; // change the direction
    this.key = property;
    const direction = this.reverse ? 1 : -1;

    this.users.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }

  OpenAgentView() {
    this.wasClicked = !this.wasClicked;
  }

  public userTrackBy(index: number, user: any) {
    return user.UserId;
  }

  SearchUsers() {
    if (this.searchText.length > 0) {
      this.LoadMoreCounter = 0;
    }
    this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder, this.LocationId);
  }

  ServerSort(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKey === sortKey) {
      this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.SortKey = sortKey;
    } else {
      this.SortOrder = 'ASC';
      this.SortKey = sortKey;
    }
    this.loadAllUsers(this.LoggedInUserRoleId, this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder, this.LocationId);
  }

  public SendMessage(ToUserId) {
      this._router.navigate(['/user/message', ToUserId]);
  }
}
