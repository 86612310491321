import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    CurrentSubsObj: any;
    constructor(private _userService: UserService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        if (this._userService.isLoggedIn() !== undefined) {
            if (this._userService.checkToken()) {
                const UserModel = this._userService.decodeToken();
                // if (UserModel.user.SubscriptionStatusId !== 1) {
                //     if (UserModel.user.SubscriptionPeriodEnd < Math.floor(Date.now() / 1000)) {
                //         this._router.navigate(['agency/SubscriptionCancel']);
                //         return false;
                //     } else {
                //         return true;
                //     }
                // }
                if (UserModel && UserModel.user && UserModel.user.RoleId === 7) {
                    this._router.navigate(['/login']);
                    return false;
                }
                return true;
            } else {
                localStorage.removeItem('frontend-token');
                this._router.navigate(['/login']);
                return false;
            }
        }
        this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return true;
    }

    public timeToHuman(timeStamp) {
        const theDate = new Date(timeStamp * 1000);
        // const dateString = theDate.toUTCString();
        const dateString = theDate.toLocaleDateString();
        return dateString;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {
        if (this._userService.isLoggedIn()) { return true; }

        // Store the attempted URL for redirecting
        this._userService.redirectURL = url;

        // Navigate to the login page with extras
        return false;
    }
}
