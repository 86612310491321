import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { GlobalService } from './global.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';


@Injectable()
export class TokboxService {
    private loggedIn = false;
    public redirectURL = '';
    public helper = new JwtHelperService();

    // public jwtHelper: JwtHelper = new JwtHelper();

    constructor(private _globalService: GlobalService,
        private _router: Router,
        // private _authHttp: AuthHttp,
        private _http: Http) {
        this.loggedIn = this.isLoggedIn();
    }

    public createsessionandtoken(userId, ToUserId) {
        return this._http
            .post(
                environment.api_url + '/tokbox/createsessionandtoken',
                {
                    'userId': userId,
                    'ToUserId': ToUserId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    response.TokenId1 = response.Data.tokenId1;
                    response.TokenId2 = response.Data.tokenId2;
                    response.sessionId = response.Data.sessionId;
                    response.ResponderConnectionId = response.Data.ConnectionId;
                    response.currentSessionId = response.Data.currentSessionId;
                } else {
                    // alert('Fail');
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch(this.handleError);
    }

    public createtoken(userId, defualtSessionId) {
        return this._http
            .post(
                environment.api_url + '/tokbox/createtoken',
                {
                    'userId': userId,
                    'sessionId': defualtSessionId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                return response;
            })
            .catch(this.handleError);
    }

    public callinitiated(CallerId, SessionId, ResponderId, CallerTokenId) {
        return this._http
            .post(
                environment.api_url + '/tokbox/callinitiated',
                {
                    'CallerId': CallerId,
                    'SessionId': SessionId,
                    'ResponderId': ResponderId,
                    'CallerTokenId': CallerTokenId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch(this.handleError);
    }

    public callconnected(SessionId, ResponderId, ResponderTokenId) {
        return this._http
            .post(
                environment.api_url + '/tokbox/callconnected',
                {
                    'SessionId': SessionId,
                    'ResponderId': ResponderId,
                    'ResponderTokenId': ResponderTokenId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch(this.handleError);
    }

    public callended(SessionId, ResponderId) {
        return this._http
            .post(
                environment.api_url + '/tokbox/callended',
                {
                    'SessionId': SessionId,
                    'ResponderId': ResponderId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            }).catch(this.handleError);
    }

    public getrandomagentid(AgencyId, LocationId) {
        return this._http
            .post(
                environment.api_url + '/tokbox/getrandomagentid',
                {
                    'AgencyId': AgencyId,
                    'LocationId': LocationId
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    response.ToUserId = response.Data[0].UserId;
                } else {
                    alert('Fail');
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            })
            .catch(this.handleError);
    }

    public getToken(): any {
        return localStorage.getItem('frontend-token');
    }

    private checkToken(): any {
        return !!localStorage.getItem('frontend-token');
    }

    public isLoggedIn(): boolean {
        return this.helper.isTokenExpired(localStorage.getItem('frontend-token'));
    }

    private handleError(error: Response | any) {

        let errorMessage: any = {};
        // Connection error
        if (error.status === 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: 'Sorry, there was a connection error occurred. Please try again.'
            };
        } else {
            errorMessage = error.json();
        }
        return Observable.throw(errorMessage);
    }
    public GetHeaders(): RequestOptions {
        const headers = new Headers({ 'Authorization': ' ' + this.getToken() });
        return new RequestOptions({ headers: headers });
    }

    public SaveMessage(SenderId,ReceiverId,TokboxSessionId,Message,AgencyId,LocationId) {
        return this._http
            .post(
                environment.api_url + '/tokbox/savemessage',
                {
                    'SenderId': SenderId, 
                    'ReceiverId': ReceiverId,
                    'TokboxSessionId': TokboxSessionId,
                    'Message': Message, 
                    'AgencyId': AgencyId, 
                    'LocationId': LocationId,
                    'IsRead': 0
                }, this.GetHeaders()
            )
            .map(response => response.json())
            .map((response) => {
                if (response.Status) {
                    // localStorage.setItem('frontend-token', response.Data.token);
                    // this.loggedIn = true;
                } else {
                    // localStorage.removeItem('frontend-token');
                    // this.loggedIn = false;
                }
                return response;
            }).catch(this.handleError);
    }
}
