import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MaterializeModule, ReactiveFormsModule, FormsModule
  ],
  declarations: [],
  exports: [CommonModule, ReactiveFormsModule, FormsModule, MaterializeModule]
})
export class CustomCommonModule { }
