export class DocumentToSave {
    Key: string;
    Name: string;
    Extention: string;
    ExtensionImage: string;
    Size: string;
    SizeInKB: number;
    UploadedDate: string;
    FolderId: number;
    DocumentUrl: string;
    OwnerUserId: number;
    Location: string;
    Status: boolean;
    Progress: number;
    IsError: boolean;
    File: any;
    FileType: any;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
