import { Component, OnInit, EventEmitter } from '@angular/core';
import { UserService } from '../../model/user.service';
import { EventBus } from '../../shared/EventBus/EventBus';
import { Router } from '@angular/router';
import { MaterializeAction, toast } from 'angular2-materialize';

@Component({
    templateUrl: './agencyadminreferral.component.html'
})

export class AgencyAdminReferralComponent implements OnInit {

    modalActions1 = new EventEmitter<string | MaterializeAction>();
    modalActions2 = new EventEmitter<string | MaterializeAction>();

    ShareUrl: string;
    FiveDigitCode: string;
    _locations: any[];
    LoggedInUserName: string;
    LoggedInUserRoleId: string;
    LoggedInUserImageUrl: string;
    AgencyLogo: string;
    AgencyId: number;
    AgencyName: string;
    LoggedInUserId: number;
    SelectedLocationId: number;
    ShowLoader: boolean;
    AgencyReferalSetingExist: boolean;
    ReferalAmountValue: number;
    ReoccurTypeId: number;
    ReferralAmountTypeId: number;
    selectedOptionReOccure: number;
    selectedOption: number;
    IsReferencrSettingExist: number;
    private filter = true;
    private reOccurArray: any[];
    private amountTypeArray: any[];
    LoadMoreCounter = 0;

    ngOnInit() {
        this.GetOccuranceType();
        this.GetAmountType();
        this.GetAgencyReferalDetail();

        this._userService.GetInitialSettings(this.LoggedInUserId).subscribe((response) => {
            if (response && response.Data) {
                this._EventBus.emit({
                    Type: 'header-left-statics',
                    data: response.Data
                });
            }
        }, (error) => {
        });
    }

    constructor(private _router: Router, private _userService: UserService, private _EventBus: EventBus) {
        const UserModel = this._userService.decodeToken();
        this.SelectedLocationId = parseInt(this._userService.GetSelectedLocation(), null);
        // get Agency/user detail from token
        if (UserModel && UserModel.user) {
            this.LoggedInUserName = UserModel.user.Name;
            this.LoggedInUserRoleId = UserModel.user.RoleId;
            this.AgencyLogo = UserModel.user.AgencyLogo;
            this.AgencyName = UserModel.user.AgencyName;
            this.AgencyId = UserModel.user.AgencyId;
            this.LoggedInUserId = UserModel.user.UserId;
        }
    }
    // get agency refereal detail definition
    public GetAgencyReferalDetail() {
        this.ShowLoader = true;
        this._userService.GetAgencyReferalSettingDetail(this.AgencyId)
            .subscribe(
                result => {
                    if (result.Status) {
                        this.filter = true;
                        this.IsReferencrSettingExist = 1;
                        this.selectedOptionReOccure = result.Data.ReoccurTypeId;
                        this.ReferalAmountValue = result.Data.Value;
                        this.selectedOption = result.Data.ReferralAmountTypeId;
                    } else {
                        this.filter = false;
                        this.IsReferencrSettingExist = 0;
                        this.selectedOption = 1;
                    }
                    this.ShowLoader = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        this._router.navigate(['/login']);
                        this.ShowLoader = false;
                    }
                }
            );
    }
    // get occurance of referal
    public GetOccuranceType() {
        this._userService.GetOccurType()
            .subscribe(result => {
                if (result.Status) {
                    this.reOccurArray = [];
                    this.reOccurArray.push({OccurID: 0, OccurName: 'Choose your option'});
                    if (result.Data && result.Data.length) {
                        result.Data.forEach((item) => {
                            this.reOccurArray.push(item);
                        });
                    }
                }
            }, error => { });
    }
    // get referal amount type
    public GetAmountType() {
        this._userService.GetReferlAMountType()
            .subscribe(result => {
                if (result.Status) {
                    this.amountTypeArray = result.Data;
                    this.amountTypeArray.forEach(function (item) {
                        item.Text = item.AmountType === 'FixedAmount' ? '$' : '%';
                        item.Value = item.AmountID;
                    });
                } else { }
            }, error => { });
    }

    public onFilterChange(event: any) {
        this.filter = !this.filter;
    }
    // show succes/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }

    private SaveReferalSetting(Amount, AmountType, ReOccur) {
        this.ShowLoader = true;
        const that = this;
        if (that.filter) {
            if (!Amount) {
                that.toasting('Please fill Amount', 2000, 'red');
                that.ShowLoader = false;
                return false;
            } else if (!AmountType) {
                that.toasting('Please select Amount Type', 2000, 'red');
                that.ShowLoader = false;
                return false;
            } else if (!ReOccur) {
                that.toasting('Please select bonus Offen', 2000, 'red');
                that.ShowLoader = false;
                return false;
            } else {
                that._userService.SaveAgencyReferralSettings(this.AgencyId, 'Payment1', 2,
                    ReOccur, AmountType, Amount, true, this.IsReferencrSettingExist)
                    .subscribe(
                        result => {
                            if (result.Status) {
                                event.preventDefault();
                                that.toasting('Referral Setting Saved Successfully', 2000, 'green');
                                that.ShowLoader = false;
                                return false;
                            } else {
                                that.toasting('Some Error occur while adding records,Please try again', 2000, 'red');
                                this.ShowLoader = false;
                                return false;
                            }
                        },
                        error => {
                            if (error.status === 422) {
                                this.ShowLoader = false;
                            } else {
                                that.toasting('Some Error occur while adding records', 2000, 'red');
                                this.ShowLoader = false;
                                return false;
                            }
                        });
            }
        } else {
            if (this.IsReferencrSettingExist) {
                that._userService.DeleteReferralSettings(this.AgencyId)
                    .subscribe(
                        result => {
                            if (result.Status) {
                                event.preventDefault();
                                that.toasting('Referral Setting Saved Successfully', 2000, 'green');
                                this.selectedOptionReOccure = 0;
                                this.ReferalAmountValue = 0;
                                this.ShowLoader = false;
                                return false;
                            } else {
                                that.toasting('Some Error occur while adding records,Please try again', 2000, 'red');
                                this.ShowLoader = false;
                                return false;
                            }
                        },
                        error => {
                            if (error.status === 422) {

                            } else {
                                that.toasting('Some Error occur while adding records', 2000, 'red');
                                this.ShowLoader = false;
                                return false;
                            }
                        });
            } else {
                that.toasting('Referral Setting Saved Successfully', 2000, 'green');
                this.selectedOptionReOccure = 0;
                this.ReferalAmountValue = 0;
                this.ShowLoader = false;
                return false;
            }
        }
    }
}
