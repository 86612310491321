import { Component, EventEmitter } from '@angular/core';
import { EventBus } from '../../shared/EventBus/EventBus';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent {
  modelAgencyMatixParams = [
    {
        dismissible: false
    }
  ];
  modalActions6 = new EventEmitter<string | MaterializeAction>();
  constructor(private _eventBus: EventBus) { }
  // open thank you modal after link/register agency matrix
  openthankYou() {
    this.modalActions6.emit({ action: 'modal', params: ['open'] });
  }
  // close thank you modal after link/register agency matrix
  closeThankYou() {
    this.modalActions6.emit({ action: 'modal', params: ['close'] });
  }

}
