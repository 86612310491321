import { ViewChild, Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MaterializeAction, toast } from 'angular2-materialize';
import { User } from '../../model/user.js';
import { AmazonS3 } from '../../model/amazon.upload';
import { DocumentToSave } from '../../model/documenttosave';
import { environment } from '../../../environments/environment.prod';
import { UserService } from '../../model/user.service';
import { Document } from '../../model/document';
import { EventBus } from '../EventBus/EventBus';

declare var $: any;
@Component({
  selector: 'app-uploadpendoc-component',
  templateUrl: './UploadPenDoc.component.html'
})

export class UploadPenDocComponent implements OnInit {
  modalActions = new EventEmitter<string | MaterializeAction>();
  params = [];
  model1Params: any;
  uploaddocmodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  @ViewChild('uploadfileinput')
  myInputVariable: any;
  IsAgent: boolean;
  IsUser: boolean;
  extens: String[];
  IsLoaded: boolean;
  IsVideo: boolean;
  IsDoc: boolean;
  validupload: boolean;
  lastMessageDate: string;
  DetailedUser: User;
  ShowLoader: boolean;
  NotDocumentFound: boolean;
  NotCallsFound: boolean;
  NotChatFound: boolean;
  Folders: any[];
  FoldersToUpload: Array<any> = [];
  Documents: Document[];
  CallCount: number;
  IsCalls: boolean;
  IsChatHistory: boolean;
  SelectedFolderId: string;
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserId: number;
  LoginUserId: number; UserID: number;
  CurrentUserId: number;
  LocationId: number;
  AgencyId: number;
  _amazon: AmazonS3;
  filesTosaveDB: Array<DocumentToSave> = [];
  HasFilesToUpload: boolean;
  ShowDocTable: boolean;
  ShowCallTable: boolean;
  ShowChatTable: boolean;
  previewImageURL: any;
  LoadMoreCounter: number;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private userService: UserService,
    private _EventBus: EventBus
  ) {
    this.extens = ['pdf'];
    this.LoadMoreCounter = 0;
    let UserId = 0;
    this.UserID = UserId;
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LocationId = UserModel.user.LocationId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserId = UserModel.user.UserId;
      if (Number(UserModel.user.RoleId) === 2 || Number(UserModel.user.RoleId) === 6) {
        this.SelectedFolderId = '12';
        this.IsAgent = true;
        this.IsUser = false;
        this.route.params.forEach((params: Params) => {
          UserId = +params['UserId'];
        });
        this.UserID = UserId;
      } else if (Number(UserModel.user.RoleId) === 3) {
        this.SelectedFolderId = '8';
        this.IsAgent = false;
        this.IsUser = true;
        this.UserID = this.LoggedInUserId;
      }
    } else {
      localStorage.removeItem('frontend-token');
      localStorage.removeItem('StripeCust');
      this._router.navigate(['/login']);
    }

    this.ShowDocTable = true;
    const res = this.GetFolders(this.UserID);
    this.CurrentUserId = UserId;
  }

  ngOnInit() {
    setTimeout(function () {
      $(document).ready(function () { $('ul.tabs').tabs(); });
      $('#uploadDoc').modal({ dismissible: false });
      $('.SignatureUploadModal').modal({ dismissible: false });
    }, 600);
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // upload progress file update
  public UpdateProgress(key, progress) {
    this.filesTosaveDB.forEach(function (item) {
      if (item.Key === key) {
        item.Progress = progress;
        return;
      }
    });
  }
  // delete file from AWS
  public DeleteFile(Key, IsFromListing, FolderID, index) {
    const that = this;
    if (!IsFromListing) {  // deleting file from amazon only (During uploading)
      for (let i = 0; i < this.filesTosaveDB.length; i++) {
        if (this.filesTosaveDB[i].Key === Key) {
          this.userService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
              const AWSService = window.AWS;
              // AWSService.config.accessKeyId = environment.amazon_access_key;
              // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
              AWSService.config.update({
                region: 'us-west-2',
                credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                  response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
              });
              const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
              const params = { Key: Key };
              bucket.deleteObject(params, function (err, data) {
                if (data !== undefined) {
                  that.filesTosaveDB.splice(i, 1);
                  if (that.filesTosaveDB.length === 0) {
                    that.HasFilesToUpload = false;
                    that.validupload = false;
                  }
                } else if (err !== undefined) {
                  err.Status = false;
                  alert(err);
                }
              });
            }
            return;
          }, (error) => {
            this.validupload = false;
            this.toasting('Server Issue. Try again after sometime', 2000, 'red');
            return;
          });
        }
      }
    }
  }
  // get folders
  public GetFolders(UserId) {
    return this.userService.getfolders(UserId)
      .subscribe(
        result => {
          const that = this;
          if (result.Status) {
            this.Folders = result.Data;
            if (this.IsUser) {
              this.Folders.forEach(function (item) {
                if (item.Id === 8) {
                  that.FoldersToUpload.push(item);
                }
              });
            }
            that.Folders.forEach(function (item) {
              if (item.Id === 5) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large der active';
              }
              if (item.Id === 6) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large grn';
              }
              if (item.Id === 7) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large blu';
              }
              if (item.Id === 8) {
                item.Class = 'tab_btn btn-flat raddius25 btn-large pnk';
              }
            });
          } else {
            alert(result.Message);
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            this._router.navigate(['/login']);
          }
        });
  }
  // open upload file modal
  public openuploaddocModal() {
    if (window.navigator['saywhat'] && !window.navigator['saywhat']['IsMobileOS']) {
      this.modalActions.emit({ action: 'modal', params: ['open'] });
    } else {
      $('#esignature_issue').show();
    }
  }
  // close upload doc modal
  public closeuploaddocModal() {
    if (this.IsAgent) {
      this.SelectedFolderId = '12';
    }
    if (this.IsUser) {
      this.SelectedFolderId = '8';
    }
    this.filesTosaveDB = [];
    this.filesTosaveDB = [];
    this.HasFilesToUpload = false;
    this.validupload = false;
    this.modalActions.emit({ action: 'modal', params: ['close'] });
  }

  public selectFolder(event) {
    this.SelectedFolderId = event;
  }

  public ActiveInactiveFolders(FolderId) {
    this.IsCalls = false;
    this.IsChatHistory = false;
    this.ShowCallTable = false;
    this.ShowChatTable = false;
    this.ShowDocTable = true;
    this.Folders.forEach(function (item) {
      if (item.Id === 5) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large der';
      }
      if (item.Id === 6) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large grn';
      }

      if (item.Id === 7) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large blu';
      }

      if (item.Id === 8) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large pnk';
      }

    });
    this.Folders.forEach(function (item) {
      if (item.Id === FolderId && FolderId === 5) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large der active';
      } else if (item.Id === FolderId && FolderId === 6) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large grn active';
      } else if (item.Id === FolderId && FolderId === 7) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large blu active';
      } else if (item.Id === FolderId && FolderId === 8) {
        item.Class = 'tab_btn btn-flat raddius25 btn-large pnk active';
      }
    });
  }

  public reset() {
    this.myInputVariable.nativeElement.value = '';
  }
  // upload file to server
  public uploadToServer(key, file) {
    const formData: any = new FormData();
    formData.append('upload', file, key);
    this.userService.makeFileRequest(formData).subscribe(
      result => {
        if (result.Status) {
        } else {
          this.toasting(result.Message, 2000, 'red');
        }
      },
      error => {
        if (error.Message === 'Token expired') {
          this._router.navigate(['/login']);
        }
      }
    );
  }

  public fileEvent(event) {
    const files = event.target.files;
    if (files.length > 0) {

      this.validupload = false;
      for (let i = 0; i < files.length; i++) {
        const fileObj = new File([files[i]], files[i].name.toLowerCase(), { type: files[i].type, lastModified: files[i].lastModified });
        const documenttosave = new DocumentToSave();
        documenttosave.File = fileObj;
        documenttosave.Name = fileObj.name.split('.')[0].replace(/^.*[\\\/]/, '');
        documenttosave.Extention = fileObj.name.replace(/^.*\./, '');
        documenttosave.SizeInKB = fileObj.size;
        documenttosave.Status = false;
        documenttosave.IsError = false;
        this.filesTosaveDB.push(documenttosave);
      }


      if (this.filesTosaveDB.length > 0) {
        this.HasFilesToUpload = true;
      }
      if (this.filesTosaveDB !== [] && this.SelectedFolderId !== undefined) {
        const that = this;
        this.userService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
              this.filesTosaveDB.forEach(function (item, index) {
                if (that.CheckFileExtention(item.Extention)) {
                  if (!item.Status && !item.IsError) {
                    const AWSService = window.AWS;
                    // AWSService.config.accessKeyId = environment.amazon_access_key;
                    // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                    AWSService.config.update({
                      region: 'us-west-2',
                      credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                        response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                    });
                    const unix = Math.round(+new Date() / 1000);
                    item.Key = unix.toString() + '_' + item.File.name;
                    item.Key = item.Key.replace(/\s/g, ''); // Removing Spaces
                    item.Key = item.Key.replace(/[()]/g, ''); // Removing parenthesis
                    item.Progress = 0;
                    const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                    const params = { Key: item.Key, Body: item.File, ACL: 'public-read' };
                    that.uploadToServer(item.Key, item.File);
                    bucket.upload(params, function (err, data) {
                      if (data !== undefined) {
                        item.Status = true;
                        item.Location = data.Location;
                        that.validupload = true;
                        that.filesTosaveDB.forEach(function (x) {
                          if (!x.Status) {
                            that.validupload = false;
                          }
                        });
                      } else if (err !== undefined) {
                        item.Status = false;
                        item.IsError = true;

                        that.validupload = true;
                        that.filesTosaveDB.forEach(function (x) {
                          x.Key = x.Key;
                          if (!x.Status) {
                            that.validupload = false;
                          }
                        });
                      }
                    }).on('httpUploadProgress', function (progress) {
                      that.UpdateProgress(progress.key, Math.round(progress.loaded / progress.total * 100).toString());
                    });
                  }
                } else {
                  that.filesTosaveDB.splice(index, 1);
                  that.toasting(item.Name + '.' + item.Extention + ' is not allowed!', 3000, 'red');
                }
              });
            }
          }, (error) => {
            this.validupload = false;
            this.toasting('Server Issue. Try again after sometime', 2000, 'red');
          });
      } else { this.validupload = false; }
    }
  }

  public CheckFileExtention(extention) {
    const exten = (extention) ? extention.toLowerCase() : '';
    if (this.extens.indexOf(exten) > -1) { return true; } else { return false; }
  }

  public UploadDoc(event, notes) {
    $('.loader-outer').css('display', 'inline-flex');
    let counter = 0;
    const that = this;
    const selectedFolder = parseInt(this.SelectedFolderId, null);
    this.filesTosaveDB.forEach(function (item) {
      if (item.Status) {
        that.userService.uploadDoc(that.AgencyId, that.LocationId, item.Key, item.Name, item.Extention,
          item.SizeInKB, selectedFolder, item.Location, that.UserID, that.LoggedInUserId, notes)
          .subscribe(
            result => {
              counter++;
              const str: any = item.Key;
              const res: any = str.split('.pdf');

              if (result.Status) {
                const documentId = result.Data.DocumentId;
                that._router.navigate(['/user/agentsign', res[0], that.UserID, documentId]);
              } else {
                that.toasting(result.Message, 2000, 'red');
              }
            },
            error => {
              if (error.Message === 'Token expired') {
                this._router.navigate(['/login']);
              }
            }
          );
      }
    });
    counter = 0;
    this.closeuploaddocModal();
  }
}
