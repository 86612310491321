export class PlanService {
    public static SubscribedPlan: any;
    public static PlanToBeSubscribe: any;
    public static DefaultCard: any;
}

export class Plan {
    Id: number;
    StripePlanId: string;
    PlanName: string;
    Price: number;
    IsActive: boolean;
    VideoHours: number;
    RecordingHours: number;
    Agents: number;
    ExtraAgent: number;
    locationId: number;
    ChatWithUsers: string;
    EmailSupport: string;
    PlanPicture: string;
    PlanDuration: string;
    IsRecommanded: boolean;
    PlanTypeId: number;
}
