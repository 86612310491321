import { Component, OnInit } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import '../../../assets/tokbox/js/app.js';
import { EventBus } from '../../shared/EventBus/EventBus';
@Component({
  templateUrl: './agencyadmindashboardpayment.component.html'

})

export class AgencyadminDashboardPaymentComponent implements OnInit {
  ShowpaymentsLoader: boolean;
  ShowAvailAgentsLoader: boolean;
  users: User[] = [];
  payments: any[];
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LocationId: number;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  UserLoading: boolean;
  AgencyId: number;
  TotalPayment: string;
  LoadMoreLoader: boolean;
  NotPaymentFound: boolean;
  LoadMoreCounter: number;
  LoginUserRoleId: number;

  paymentkey: string;
  paymentreverse: boolean;
  filter: any;
  opensearch = false;
  searchText = '';
  SortKey = 'CreatedDateTime';
  SortOrder = 'DESC';
  LoadMoreLoaderEnable: boolean;
  LoadMorePayments = true;
  ngOnInit() {
    this.paymentkey = 'CreatedDateTime';
    this.paymentreverse = true;
    if (this.LoginUserRoleId !== 6 && this.LoginUserRoleId !== 2) {
      this.userService.RedirectAccToRole(this.LoginUserRoleId);
    }

    this.userService.GetInitialSettings(this.LoginUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });
  }
  constructor(private userService: UserService, private tokboxService: TokboxService,
    private route: ActivatedRoute, private _router: Router, private _EventBus: EventBus) {
    const that = this;
    // get Agency/Admin detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      if (this.userService.GetSelectedLocation() !== 0) {
          this.LocationId = Number(this.userService.GetSelectedLocation());
      } else {
          this.LocationId = UserModel.user.LocationId;
      }
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LoginUserRoleId = UserModel.user.RoleId;
    }
    this.LoadMoreCounter = 0;
    this.NotPaymentFound = false;
    this.GetUserPayments(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);

    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'locationChanged') {
        this.LocationId = Number(m.filterBy.data.LocationId);
        this.GetUserPayments(0, this.searchText, this.SortKey, this.SortOrder);
      }
    });
  }
  // load more payments detail on scroll down
  public onScrollDown() {
    if (this.payments.length % 50 === 0 && this.LoadMorePayments === true) {
      this.LoadMoreLoaderEnable = true;
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.GetUserPayments(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
    }
  }
  // get user payments
  public GetUserPayments(Offset, Search, SortKey, SortOrder) {
    if (this.LoadMorePayments === true || this.searchText !== Search) {
      this.LoadMorePayments = false;
      this.ShowpaymentsLoader = true;
      if (Offset === 0) { this.payments = []; this.UserLoading = true; }
      this.userService.GetUserPayments(0, this.LocationId, this.AgencyId, Offset, Search, SortKey, SortOrder)
        .subscribe(
          result => {
            this.LoadMoreLoader = true;
            if (result.Status) {
              if (Offset === 0) {
                this.payments = [];
                this.payments = result.Data;
              } else {

                if (result.Data) {
                  result.Data.forEach((item) => {
                    this.payments.push(item);
                  });
                }
              }
              this.payments.forEach((item) => {
                item.DateTimeString = new Date(item.CreatedDateTime).toLocaleString();
                item.Status = item.StatusId === 1 ? 'PAID' : item.StatusId === 2 ? 'Pending' : 'Failed';
              });
            } else {
              if (Offset === 0) { this.NotPaymentFound = true; }
            }
            this.UserLoading = false;
            this.ShowpaymentsLoader = false;
            if (this.payments.length > 0) {
              this.TotalPayment = this.payments[0].PaymentCounts.toString();
            } else {
              this.TotalPayment = '0';
            }
            this.NotPaymentFound = (this.payments.length > 0) ? false : true;
            this.LoadMoreLoaderEnable = false;
            this.LoadMorePayments = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.ShowpaymentsLoader = false;
            this.LoadMoreLoader = false;
            this.UserLoading = false;
            this.LoadMorePayments = true;
          }
        );
      }
  }
  // search payment from server
  SearchPayments() {
    if (this.searchText.length > 0) {
      this.LoadMoreCounter = 0;
    }
    this.GetUserPayments(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
  }
  // sort payment detail from server
  ServerSort(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKey === sortKey) {
      this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.SortKey = sortKey;
    } else {
      this.SortOrder = 'ASC';
      this.SortKey = sortKey;
    }
    this.GetUserPayments(this.LoadMoreCounter, this.searchText, this.SortKey, this.SortOrder);
  }
}
