import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../model/user';
import { UserService } from '../../model/user.service';
import { EventBus } from '../EventBus/EventBus';

declare var myExtObject: any;

@Component({
  selector: 'app-ax-agent-sidebar',
  templateUrl: './agent-sidebar.component.html'
})

export class AgentSidebarComponent implements OnInit {
  UserModel: User = new User();
  LoggedInUserName: string;
  AgencyReferalSetingExist: boolean;
  LoggedInUserId: number;
  AgencyId: number;
  LoggedInUserRoleId: string;
  LoggedInUserImageUrl: string;
  sidebarStatus: boolean;
  AgencyLogo: any;
  UnseenMessageCount = 0;
  LoginUserId: number;
  BlockMultipleRequest = true;
  constructor(public _EventBus: EventBus, private _router: Router,
    private _userservice: UserService, private _ngZone: NgZone) {
    // get agency/user detail from token
    const UserModel = this._userservice.decodeToken();
    if (UserModel && UserModel.user) {
      this.AgencyLogo = UserModel.user.AgencyLogo;
      this.LoggedInUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LoginUserId = UserModel.user.UserId;
      this.GetUnreadMessageCount();
    }
    this._EventBus.on().subscribe(m => {
      if (m.filterBy.Type === 'Get-User-Detail-Event') {
        this.GetUserDetail();
      }
    });
    window['angularComponentRefUserChatCount'] = {
      zone: this._ngZone,
      RefreshConverstation: (value) => this.RefreshConverstation(value)
    };
  }

  ngOnInit() {
    this.GetAgencyReferalDetail();

    this._EventBus.on().subscribe((m: any) => {
      // Toggle left bar on mobile
      if (m.filterBy.Type === 'toggleressidebar') {
        if (m.filterBy.data.sidebarStatus) {
          this.sidebarStatus = true;
        }
      } else if (m.filterBy.Type === 'MessageReceivedData' ) {
        this.RefreshConverstation(m.filterBy.data.count);
      }
    });

  }
  // get agency referal
  public GetAgencyReferalDetail() {
    this._userservice.GetAgencyReferalSettingDetail(this.AgencyId)
      .subscribe(
        result => {
          if (result.Status) {
            this.AgencyReferalSetingExist = true;
          } else {
            this.AgencyReferalSetingExist = false;
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/']);
          }
        }
      );
  }
  // get user detail
  public GetUserDetail() {
    this._userservice.getuserprofile(this.LoggedInUserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.UserModel = result.Data;
          } else {
            alert(result.Message);
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }

  public LogoutTokbox() {
    myExtObject.logout(true);
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/']);
  }

  public GetUnreadMessageCount() {
    if (this.BlockMultipleRequest) {
      this.BlockMultipleRequest = false;
      this._userservice.GetUnreadMessageCount(this.LoginUserId)
        .subscribe((response) => {
            if (response.Status) {
              this._ngZone.run( () => {
                this.UnseenMessageCount = response.Data.MessageCount;
              });
            }
            this.BlockMultipleRequest = true;
        }, (error) => { this.BlockMultipleRequest = true; }
      );
    }
  }

  public RefreshConverstation(obj) {
    if (obj && obj.hasOwnProperty('count')) {
      this.UnseenMessageCount = obj.count;
    } else {
      this.GetUnreadMessageCount();
    }
  }
}
