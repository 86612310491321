import { ElementRef, Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Document } from '../../model/document';
import '../../../assets/tokbox/js/app.js';
import { DomSanitizer } from '@angular/platform-browser';
import { MaterializeAction, toast } from 'angular2-materialize';
import { EventBus } from '../EventBus/EventBus';
import { environment } from '../../../environments/environment';

declare var myExtObject: any;

@Component({
  templateUrl: './PendingDocUsers.component.html'
})

export class PendingDocUsersComponent implements OnInit {
  @ViewChild('signPreviewModal') signPreviewModal: any;
  ShowAvailAgentsLoader: boolean;
  ShowDocumentLoader: boolean;
  wasClicked = false;
  users: User[] = [];
  ShowActionLoader: boolean;
  IsLoaded: boolean;
  documents = [];
  Folders: any[];
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  NoDocument: boolean;
  LocationId: number;
  Count: number;
  ShowDocTable: boolean;
  NotDocumentFound: boolean;
  previewImageURL: any;
  IsDoc: boolean;
  AgencyId: number;
  documentkey: string;
  documentreverse: boolean;
  SortKey = 'UploadedDate';
  SortOrder = 'DESC';
  LoadMoreCounter = 0;
  LoginUserRoleId: number;
  TotalUnsignedDocuments = 0;
  searchText= '';
  DeleteDocumentKey: string;
  DeleteIsFromListing = true;
  DeleteFolderID = 0;
  DeleteIndex = 0;
  modelDeleteDocumentParam: any;
  modalDeleteActions = new EventEmitter<string | MaterializeAction>();
  LoadMoreLoaderEnable: boolean;
  LoadMorePendingSig = true;
  ngOnInit() {
  }

  constructor(private sanitizer: DomSanitizer, private userService: UserService,
    private tokboxService: TokboxService, private elementRef: ElementRef,
    private route: ActivatedRoute, private _router: Router, private _EventBus: EventBus) {
    const that = this;
    this.documentkey = 'documentkey';
    this.documentreverse = true;
    this.Count = 1;
    // if (this.auth_guard.checkLogin(this._router.url)) {
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LocationId = UserModel.user.LocationId;
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      if (this.userService.GetSelectedLocation() !== 0) {
          this.LocationId = Number(this.userService.GetSelectedLocation());
      } else {
          this.LocationId = UserModel.user.LocationId;
      }
      this.ShowDocTable = true;
      this.LoginUserRoleId = UserModel.user.RoleId;
      this.getUnsignedDocumentsUsers(this.AgencyId, this.LocationId);
    } else {
      localStorage.removeItem('frontend-token');
      localStorage.removeItem('StripeCust');
      this._router.navigate(['/login']);
    }
    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'locationChanged') {
          this.LocationId = Number(m.filterBy.data.LocationId);
          this.LoadMoreCounter = 0;
          this.documents = [];
          this.getUnsignedDocumentsUsers(this.AgencyId, this.LocationId);
        }
    });

    if (this.LoginUserId && this.LoginUserId > 0) {
      this._EventBus.emit({ Type: 'event-authorized', data: { IsAuthorized: true } });
    }
  }

  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  public OnLoadComplete() {
    this.IsLoaded = true;
  }

  public getUnsignedDocumentsUsers(AgencyId, LocationId) {
    if (this.LoadMorePendingSig === true) {
      this.LoadMorePendingSig = false;
      if (this.LoadMoreCounter === 0) {
        this.ShowDocumentLoader = true;
      }
      const that = this;
      this.NotDocumentFound = false;
      this.userService.getUnsignedDocumentsUsers(AgencyId, LocationId, this.SortKey, this.SortOrder, this.LoadMoreCounter, this.searchText)
        .subscribe(
          result => {
            if (result.Status) {
              if (this.LoadMoreCounter === 0) {
                this.documents = [];
                if (result.Data) {
                  this.documents = result.Data;
                  this.TotalUnsignedDocuments = (result.Data.length) ? result.Data[0].TotalUnsignedDocuments : 0;
                } else {
                  this.NotDocumentFound = true;
                }
              } else {
                if (result.Data.length) {
                  this.TotalUnsignedDocuments = result.Data[0].TotalUnsignedDocuments;
                  result.Data.forEach((item) => {
                      this.documents.push(item);
                  });
                }
              }
              this.documents.forEach((item) => {
                item.UserName = (item.FirstName[0].toUpperCase() + item.FirstName.slice(1))
                + ' ' + (item.LastName[0].toUpperCase() + item.LastName.slice(1));
                item.Initials = (item.FirstName[0].toUpperCase() + item.LastName[0].toUpperCase());
                item.Name = item.Name[0].toUpperCase() + item.Name.slice(1);
                item.DateTimeString = new Date(item.UploadedDate).toLocaleString();
                item.ShowActions = true;
                item.Size = this.GetCorrectSize(item.SizeInKB);
                item.ExtensionImage = this.GetIcon(item.Extention);
              });
            } else {
              if (this.LoadMoreCounter === 0 && this.documents.length) {
                this.NotDocumentFound = false;
                this.documents = [];
              } else if (this.documents.length === 0) {
                this.NotDocumentFound = true;
                this.TotalUnsignedDocuments = 0;
              } else if (this.LoadMoreCounter === 0) {
                this.NotDocumentFound = true;
              }
            }
            this.ShowDocumentLoader = false;
            this.LoadMoreLoaderEnable = false;
            this.LoadMorePendingSig = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.ShowDocumentLoader = false;
            this.LoadMorePendingSig = true;
          });
      }
  }

  public GetIcon(extention) {
    if (extention.toLowerCase().match('pdf')) {
      return 'assets/images/pdf.png';
    } else if (extention.toLowerCase().match('doc') || extention.match('docx')) {
      return 'assets/images/do.png';
    } else if (extention.toLowerCase().match('jpg') || extention.match('png') || extention.match('jpeg')) {
      return 'assets/images/jpg.png';
    } else if (extention.toLowerCase().match('xlsx') || extention.match('xls')) {
      return 'assets/images/excelr_ic.png';
    } else if (extention.toLowerCase().match('pptx')) {
      return 'assets/images/powerpoint_ic.png';
    }
  }

  public UserSignRedirect(documentKey, DocId) {
    const res: any = documentKey.split('.pdf');
    this._router.navigate(['user/usersign', res[0], DocId]);
  }

  public GetCorrectSize(FileSize) {
    if (FileSize < 1000) {
      return FileSize + ' Bytes';
    } else if (FileSize > 1000 && FileSize < 1000000) {
      return (FileSize / 1000).toFixed(2).toString() + ' KB';
    } else if (FileSize > 1000000 && FileSize < 1000000000) {
      return (FileSize / 1000000).toFixed(2).toString() + ' MB';
    } else if (FileSize > 1000000000) {
      return (FileSize / 1000000000).toFixed(2).toString() + ' GB';
    }
  }

  ServerSort(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKey === sortKey) {
      this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.SortKey = sortKey;
    } else {
      this.SortOrder = 'ASC';
      this.SortKey = sortKey;
    }
    this.getUnsignedDocumentsUsers(this.AgencyId, this.LocationId);
  }

  public onScrollDown() {
    if (this.documents.length % 50 === 0 && this.LoadMorePendingSig === true) {
      this.LoadMoreLoaderEnable = true;
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.getUnsignedDocumentsUsers(this.AgencyId, this.LocationId);
    }
  }
  public showuserdetail(UserId) {
      if (this.LoginUserRoleId === 6) {}
      this._router.navigate([(this.LoginUserRoleId === 6) ? 'agency/userdetail' : 'user/detail', UserId]);
  }

  public UserSignPreviewRedirect(documentKey, DocId) {
    const res: any = documentKey.split('.pdf');
    this.signPreviewModal.openSignPreview(res[0], DocId);
  }

  public SearchDocuments() {
      if (this.searchText.length > 0) {
          this.LoadMoreCounter = 0;
          this.documents = [];
      }
      setTimeout(() => {
        this.getUnsignedDocumentsUsers(this.AgencyId, this.LocationId);
      }, 2000);
  }

  public deleteDocumentConfirm(Key, IsFromListing, FolderID, index) {
    this.DeleteDocumentKey = Key;
    this.DeleteIsFromListing = IsFromListing;
    this.DeleteFolderID = FolderID;
    this.DeleteIndex = index;
    this.modalDeleteActions.emit({ action: 'modal', params: ['open'] });
  }
  public DeleteDocumentFile() {
    this.modalDeleteActions.emit({ action: 'modal', params: ['close'] });
    this.ShowDocumentLoader = true;
    this.DeleteFile(this.DeleteDocumentKey, this.DeleteIsFromListing, this.DeleteFolderID, this.DeleteIndex);
  }
  public DeleteFile(Key, IsFromListing, FolderID, index) {
      const that = this;
      this.userService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
              // deleting file from amazon and database (After uploading)
              this.documents[index].ShowActions = false;
              const AWSService = window.AWS;
              // AWSService.config.accessKeyId = environment.amazon_access_key;
              // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
              AWSService.config.update({
                region: 'us-west-2',
                credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                  response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
              });
              const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
              const params = { Key: Key };
              bucket.deleteObject(params, function (err, data) {
                if (data !== undefined) {
                  that.userService.deleteDocument(Key)
                    .subscribe(
                      result => {
                        if (result.Status) {
                          that.getUnsignedDocumentsUsers(that.AgencyId, that.LocationId);
                          that.toasting('File deleted successfully', 2000, 'green');
                        } else {
                          that.documents = [];
                          that.toasting(result.Message, 2000, 'red');
                        }
                      },
                      error => {
                        if (error.Message === 'Token expired') {
                          localStorage.removeItem('frontend-token');
                          localStorage.removeItem('StripeCust');
                          this._router.navigate(['/login']);
                        }
                      }
                    );
                } else if (err !== undefined) {
                  err.Status = false;
                  // alert(err);
                }
              });
            }
          }, (error) => {
            this.toasting('Server Issue. Try again after sometime', 2000, 'red');
          });
  }

  public CancelDeleteModel() {
    this.modalDeleteActions.emit({ action: 'modal', params: ['close'] });
  }
}
