import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthStepperGuard implements CanActivate, CanActivateChild {
    constructor(private _userService: UserService, private _router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const PlanObj = localStorage.getItem('PlanObj');
        const UserModel = this._userService.decodeToken();
        if (this._userService.getSubdomain() !== '' && this._userService.getSubdomain() === 'app') {
            if (UserModel === '') {
                this._router.navigate(['/pricing']);
            }
        } else if (this._userService.getSubdomain() !== '' && this._userService.getSubdomain() !== 'app') {
            const newURL = window.location.protocol + '://' + window.location.host + '/' + window.location.pathname;
            const pathArray = window.location.pathname.split('/');
            if (typeof (pathArray[2]) !== 'undefined' && pathArray[2] !== null) {
                if (pathArray[2] === 'landing' || pathArray[2] === 'step' && UserModel === '') {
                    window.location.href = 'https://app.' + this._userService.getDomain() + '/pricing';
                }
            }
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
