import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthPricingGuard implements CanActivate, CanActivateChild {
    constructor(private _userService: UserService, private _router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const userModel = this._userService.decodeToken();
        if (userModel && userModel.user && userModel.user.RoleId && userModel.user.RoleId !== 7) {
            this._userService.RedirectAccToRole(userModel.user.RoleId);
            return false;
        } else if (this._userService.getSubdomain() !== '' && this._userService.getSubdomain() !== 'app') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            const newURL = window.location.protocol + '://' + window.location.host + '/' + window.location.pathname;
            const pathArray = window.location.pathname.split('/');
            if (typeof (pathArray[1]) !== 'undefined' && pathArray[1] !== null) {
                if (pathArray[1] === 'pricing') {
                    window.location.href = 'https://app.' + this._userService.getDomain() + '/pricing';
                }
            }
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}