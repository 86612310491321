import { ElementRef, Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '../../model/user.service';
import { toast } from 'angular2-materialize';
import { EventBus } from '../EventBus/EventBus';
declare var $: any;
declare var d3: any;

declare function pdfAnnotateIni(): void;
declare function SaveImageOnserver(base64Data: any, typ: any): void;

@Component({
  selector: 'app-user-sign-preview',
  templateUrl: './usersignPreview.component.html'
})

export class UserSignDocumentPreviewComponent implements OnInit, AfterViewInit {
  @Input('documentKeyPreview') documentKeyPreview: string;
  @Input('documentIdPreview') documentIdPreview: number;
  documentKey = '';
  DocId = 0;
  DocData: any;
  dataAnnotation: any;

  dateToday: any = '';
  /***Signature variables */
  public typedSignature = '';
  public typedInitials = '';
  public signature = 'draw';
  public singleSelectionSettings: any = {};
  public singleSelectionselectedItems: any = {};
  public singleIniSelectionselectedItems: any = {};
  public singleSelectionList: any = {};
  public signatureFonts: any = [];
  public fontSelected: any = [];
  public selectFontClass = 'font1';
  public selectFontIntiClass = 'font1';
  public signatureImage = '';
  public signatueError = false;
  /******************* */
  checkTypedSig() {
    if (this.typedSignature !== '') {
      this.signatueError = false;
    }
  }

  ngOnInit() {
    if ($('#ongoingCall').hasClass('active') === true) {
      $('.pdfViewer').addClass('changePdfPos');
    } else {
      $('.pdfViewer').removeClass('changePdfPos');
    }

    this.singleSelectionSettings = {
      singleSelection: true,
      enableSearchFilter: false
    };

    this.singleSelectionList = [
      { id: 1, itemName: 'Great Vibes', class: 'font1' },
      { id: 2, itemName: 'Eagle Lake', class: 'font2' },
      { id: 3, itemName: 'Petit Formal Script', class: 'font3' },
      { id: 4, itemName: 'Berkshire Swash', class: 'font4' },
      { id: 5, itemName: 'Aladin', class: 'font5' },
      { id: 6, itemName: 'Eagle Lake', class: 'font6' }
    ];

    $('#fontNameSign').val('Great Vibes');
    $('#fontNameIni').val('Great Vibes');

    this.singleSelectionselectedItems = [
      { id: 1, itemName: 'Great Vibes', class: 'font1' }
    ];

    this.singleIniSelectionselectedItems = [
      { id: 1, itemName: 'Great Vibes', class: 'font1' }
    ];
    /*******************************************************/
    setTimeout(() => {
      localStorage.setItem('signatureEditMode', 'false');
      pdfAnnotateIni();
      $('#deactive-annotation-draw-preview').trigger('click');
    }, 7000);
    /*******************************************************/

    this.dateToday = '22 May, 2018';

    setTimeout(function () {
      $('.signature-pad--body canvas').attr('width', '700');
      $('.signature-pad--body canvas').attr('height', '250');
    }, 3000);
  }

  constructor(private _router: Router, private userService: UserService, private elementRef: ElementRef,
    private route: ActivatedRoute, private _eventBus: EventBus) {
    $('#pdfUrl').val('');
   }
  // get annotations document signature
   public GetAnnotations() {
    this.userService.getAnnotations(this.DocId)
      .subscribe(
      result => {
        if (result.Status) {
          this.DocData = result.Data[0];
          setTimeout(() => {
            localStorage.setItem(this.documentKey + '/annotations', JSON.stringify(this.DocData.PDFAnnotation));
          }, 300);
        } else {
          this.toasting(result.Message, 2000, 'red');
        }
      },
      error => {
        if (error.Message === 'Token expired') {
          localStorage.removeItem('frontend-token');
          localStorage.removeItem('StripeCust');
          this._router.navigate(['/login']);
        }
      }
      );
  }
  // show success/error message
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }

  ngAfterViewInit() {
    this.documentKey = this.documentKeyPreview;
    this.DocId = this.documentIdPreview;
    this.GetAnnotations();
    setTimeout(() => {
      this.documentKey = 'https://lowrate-insurance.s3.us-west-2.amazonaws.com/' + this.documentKey + '.pdf';
      $('#pdfUrl').val(this.documentKey);
    }, 250);
    const a = document.createElement('script');
    a.type = 'text/javascript';
    a.src = '/assets/pdf/shared/pdf.js';
    this.elementRef.nativeElement.appendChild(a);

    const b = document.createElement('script');
    b.type = 'text/javascript';
    b.src = '/assets/pdf/shared/pdf_viewer.js';
    this.elementRef.nativeElement.appendChild(b);

    const c = document.createElement('script');
    c.type = 'text/javascript';
    c.src = '/assets/pdf/index.js?' + Math.floor(Math.random() * Math.floor(5));
    this.elementRef.nativeElement.appendChild(c);
  }

  closeModal() {
    $('#UserSignModal').modal('close');
    $('.annotationLayer').css('display', 'block');
  }


  putCusrsor() {
    setTimeout(function () {
      $('#type_signature').focus();
      $('#type_initials').focus();
    }, 20);
  }

  updateTypeSign() {
  }

  hideModal() {
    $('#UserInitialsModal').modal('close');
    $('#UserSignModal').modal('close');
    $('.annotationLayer').css('display', 'block');
  }

  cancelDate() {
    $('.annotationLayer').css('display', 'block');
    $('#datePickerModal').modal('close');
  }

  fontChanged(event: any) {
    const selectFontClass = this.singleIniSelectionselectedItems;
    this.selectFontIntiClass = this.singleIniSelectionselectedItems;
    $('#fontNameIni').val($('.initials_select input').val());
    const that = this;
  }
}
