import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../model/user.service';
import { EventBus } from '../../shared/EventBus/EventBus';
import { Plan } from '../../model/plan';

@Component({
    templateUrl: './pricing.component.html'
})

export class AgencyPricingComponent {
    Plans: any[];
    PlanLoading: boolean;
    public PlanListMonthly: Plan[];
    public PlanListYearly: Plan[];
    public PlanList: Plan[];
    public SubscriptionType: number;
    public MonthlyOrYearly: string;
    public contactUsEmailHref: string;
    constructor(private userService: UserService, private _router: Router, private _eventBus: EventBus
    ) {
        this.contactUsEmailHref = `mailto:support@agentalive.tech?subject=Enterprise Monthly`;
        this.SubscriptionType = 1;
        this.MonthlyOrYearly = 'Month';
        this.GetPlanList();
        this._eventBus.emit({ Type: `show-authorized-header`, data: { ShowHeader: false, ShowSideBar: false, ShowAgentList: false } });
    }
    // get plan detail
    public GetPlanDetail(PlanObj) {
        localStorage.setItem('PlanObj', JSON.stringify(PlanObj));
        this._router.navigate(['agency/landing']);
    }
    // switch plan pricing monthly/yearly
    switchPlanForPricing(subscriptionType: string): void {
        switch (subscriptionType) {
            case 'Month': // monthly
                this.contactUsEmailHref = `mailto:support@agentalive.tech?subject=Enterprise Monthly`;
                this.MonthlyOrYearly = subscriptionType;
                this.SubscriptionType = 1;
                if (this.PlanListMonthly && this.PlanListMonthly.length) {
                    this.PlanList = this.PlanListMonthly.slice(0);
                } else {
                    this.GetPlanList();
                }
                break;
            case 'Year': // yearly
                this.contactUsEmailHref = `mailto:support@agentalive.tech?subject=Enterprise Annually`;
                this.SubscriptionType = 3;
                this.MonthlyOrYearly = subscriptionType;
                if (this.PlanListYearly && this.PlanListYearly.length) {
                    this.PlanList = this.PlanListYearly.slice(0);
                } else {
                    this.GetPlanList();
                }
                break;

            default: // do nothing
                break;
        }
    }
    // get plans for new agency signup
    public GetPlans() {
        const that = this;
        that.PlanLoading = true;
        this.userService.GetPlanList(this.SubscriptionType, this.MonthlyOrYearly)
            .subscribe(
                result => {
                    if (result.Status) {
                        that.Plans = result.Data;
                    } else {
                        alert(result.Message);
                    }
                    this.PlanLoading = false;
                },
                error => {
                    if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                    }
                    this.PlanLoading = false;
                }
            );
    }
    // get plan list for agency sign up
    public GetPlanList() {
        const that = this;
        that.PlanLoading = true;
        this.userService.GetPlanList(this.SubscriptionType, this.MonthlyOrYearly).subscribe(result => {
            this.PlanLoading = false;
            if (result.Status && result.Data) {
                this.PlanList = result.Data ? result.Data.sort((a: any, b: any) => a.PlanViewPosition - b.PlanViewPosition) : [];
                if (this.SubscriptionType === 1) {
                    this.PlanListMonthly = this.PlanList;
                } else if (this.SubscriptionType === 3) {
                    this.PlanListYearly = this.PlanList;
                }
            }
        }, error => { this.PlanLoading = false; });
    }
}
