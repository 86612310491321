import { Component, EventEmitter, Output, ChangeDetectorRef, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction, toast } from 'angular2-materialize';
import { environment } from '../../../environments/environment.prod';
import '../../../assets/tokbox/js/app.js';
import { DocumentToSave } from '../../model/documenttosave';
import { UserService } from '../../model/user.service';
import { Router } from '@angular/router';
declare var $: any;
declare function getStripePublishableKey(token: string): void;

@Component({
    selector: 'app-product-detail-editmodal',
    templateUrl: 'product-detail.component.html'
})
export class ProductModalComponent implements OnInit {
    _chargeCustomer: FormGroup;
    ShowConfirmDeleteLoader: boolean;
    private _formErrors: any;
    private _errorMessage: string;
    public file_srcs_companyLogo: string;
    public file_srcs_userImage: string;
    SelectedCustomerId: string;
    SelectedCustomerCardId: string;
    CompanyLogoKey: string;
    UserImageKey: string;
    UserImageUrl: string;
    model1Params: any;
    CompanyLogoUrl: string;
    CompanyLogoFilled = 0;
    UserImageFilled = 0;
    validupload: boolean;
    stripeClientId: any;
    selectedCompanyLogo: boolean;
    selectedUserLogo: boolean;
    companyLogoUploading: boolean;
    filesTosaveDB: Array<DocumentToSave> = [];
    DeletedProductObj: any;
    defaultProductImgUrl = 'assets/images/default_product.png';
    @Output() searchProduct = new EventEmitter();

    @ViewChild('uploadCompanyLogo')
    uploadCompanyLogo: ElementRef;

    modalTitle = 'Product details';
    modalOkText = 'Add';
    successMessage = 'Product detail added successfully';
    modalActionsAddProduct = new EventEmitter<string | MaterializeAction>();
    modalActions3 = new EventEmitter<string | MaterializeAction>();
    model3Params: any;
    params = [];
    uploaddocmodalparam = [
        {
            dismissible: false,
            complete: function () { }
        }
    ];
    ShowMe: boolean;
    extens: String[];
    UserId: number;
    AgencyId: number;
    _signupForm: FormGroup;
    ProductName: string;
    HasFilesToUpload: boolean;
    ProductDescription: string;
    ProductPic: string;
    ProductId: number;
    ShowLoader: boolean;
    ShowButtonLoader: boolean;
    ShowCreateChargeDiv: boolean;
    ProductLogoButtonEnable = 1;
    message: string;
    LoggedInRoleId: any;
    componentObject: any;
    IsDeleteting: boolean;
    constructor(private changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _cuserService: UserService,
        private _router: Router
    ) {
        this.componentObject = this;
        // get agency/user detail from token
        const UserModel = this._cuserService.decodeToken();
        if (UserModel && UserModel.user) {
            this.UserId = UserModel.user.UserId;
            this.AgencyId = UserModel.user.AgencyId;
            this.LoggedInRoleId = UserModel.user.RoleId;
        }

        this._signupForm = _formBuilder.group({
            productName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(40)])],
            productDescriptions: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(500)])],

        });
        this._signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
        this.extens = ['jpg', 'png', 'jpeg', 'gif'];
    }
    public CheckFileExtention(extention) {
        const exten = (extention) ? extention.toLowerCase() : '';
        if (this.extens.indexOf(exten) > -1) {
            return true;
        } else {
            return false;
        }
    }

    readFiles(files: any, index = 0, fileInputName) {
        const reader = new FileReader();
        // If there is a file
        if (index in files) {
            // Start reading this file
            this.readFile(files[index], reader, (result: any) => {
                // After the callback fires do:
                // if companyLogo selected
                if (fileInputName === 'companylogo') {
                    this.selectedCompanyLogo = true;
                    this.file_srcs_companyLogo = result;
                    setTimeout(function () {
                        $('.deleteCompanyLogoIcon').hide();
                    }, 20);
                }
                // if userImage selected
                if (fileInputName === 'userImage') {
                    this.selectedUserLogo = true;
                    this.file_srcs_userImage = result;
                    setTimeout(function () {
                        $('.deleteUserImageIcon').hide();
                    }, 20);
                }
                this.readFiles(files, index + 1, fileInputName); // Read the next file;
            });
        } else {
            // When all files are done This forces a change detection
            this.changeDetectorRef.detectChanges();
        }
    }
    // add/update product
    onSubmit(elementValues: any) {
        this.ShowLoader = true;
        this._cuserService.AwsTempraryUploadDetails()
          .subscribe((response) => {
            if (response.Status && response.Data) {
                if (this.CompanyLogoFilled === 1 && this.CompanyLogoUrl) {
                    let companyLogoThumbnail = this.CompanyLogoKey;
                    const AWSService = window.AWS;
                    // AWSService.config.accessKeyId = environment.amazon_access_key;
                    // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                    AWSService.config.update({
                        region: 'us-west-2',
                        credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                            response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                    });
                    const s3 = new window.AWS.S3();
                    const paramsCompanyThumb = {
                        Bucket: response.Data.amazon_thumbnail_bucket_name,
                        Key: 'resized-images/' + this.CompanyLogoKey
                    };
                    const paramsImageThumb = {
                        Bucket: response.Data.amazon_thumbnail_bucket_name,
                        Key: 'resized-images/' + this.UserImageKey
                    };
                    companyLogoThumbnail = s3.getSignedUrl('getObject', paramsCompanyThumb);
                }
                const AgencyId = this.AgencyId;
                if (!this.ProductId) {
                    this.ProductId = 0;
                }
                const companyProfileData: any = {
                    ProductId: this.ProductId,
                    ProductName: elementValues.productName,
                    ProductDescription: elementValues.productDescriptions,
                    ProductPicture: this.CompanyLogoFilled === 0 ? this.file_srcs_companyLogo : this.CompanyLogoUrl,
                    AgencyId: AgencyId
                };
                companyProfileData['ProductPicture'] = companyProfileData['ProductPicture'] ? companyProfileData['ProductPicture'] : '';

                this._cuserService.AddOrUpdateProduct(
                    companyProfileData['ProductId'],
                    companyProfileData['ProductName'],
                    companyProfileData['ProductDescription'],
                    companyProfileData['ProductPicture'],
                    companyProfileData['AgencyId']
                ).subscribe(
                    result => {
                        if (result.Status) {
                            this.toasting(`${this.successMessage}`, 3000, 'green');
                            this.CloseModel();
                            this.searchProduct.emit();
                            this.ShowLoader = false;
                        }
                    },
                    error => {
                        // Validation error
                        if (error.status === 422) {
                            this._resetFormErrors();
                            const errorFields = JSON.parse(error.data.message);
                            this._setFormErrors(errorFields);
                            this.ShowLoader = false;
                        } else {
                            this._errorMessage = JSON.stringify(error.Data[0]);
                        }
                        this.ShowLoader = false;
                    }
                );
            }
        }, (error) => {
          this.validupload = false;
          this.toasting('Server Issue. Try again after sometime', 2000, 'red');
        });
    }
    // set form errors server
    private _setFormErrors(errorFields: any): void {
        for (const key in errorFields) {
            // skip loop if the property is from prototype
            if (!errorFields.hasOwnProperty(key)) { continue; }
            const message = errorFields[key];
            this._formErrors[key].valid = false;
            this._formErrors[key].message = message;
        }
    }
    // read file
    readFile(file: any, reader: any, callback: any) {
        // Set a callback funtion to fire after the file is fully loaded
        reader.onload = () => {
            // callback with the results
            callback(reader.result);
        };
        // Read the file
        reader.readAsDataURL(file);
    }
    // detect change file event
    public fileEvent(event) {
        const files = event.target.files;
        this.ProductLogoButtonEnable = 0;
        if (files.length > 0) {
            this.validupload = false;
            for (let i = 0; i < files.length; i++) {
                const documenttosave = new DocumentToSave();
                documenttosave.File = files[i];
                documenttosave.Name = files[i].name.split('.')[0].replace(/^.*[\\\/]/, '');
                documenttosave.Name = documenttosave.Name ? documenttosave.Name.replace(/\s/g, '') : documenttosave.Name;
                documenttosave.Extention = files[i].name.replace(/^.*\./, '');
                documenttosave.SizeInKB = files[i].size;
                documenttosave.Status = false;
                documenttosave.IsError = false;
                if (event.target.name === 'companylogo') {
                    documenttosave.FileType = 'companylogo';
                }
                if (event.target.name === 'userImage') {
                    documenttosave.FileType = 'userImage';
                }
                this.filesTosaveDB.push(documenttosave);
            }
            if (this.filesTosaveDB.length > 0) {
                this.HasFilesToUpload = true;
            }
            if (this.filesTosaveDB !== []) {
                const that = this;
                this._cuserService.AwsTempraryUploadDetails()
                .subscribe((response) => {
                    if (response.Status && response.Data) {
                        this.filesTosaveDB.forEach(function (item, index) {
                            if (that.CheckFileExtention(item.Extention)) {
                                that.readFiles(event.target.files, 0, event.target.name);
                                if (event.target.name === 'companylogo') {
                                    $('.companyLogoUploading').show();
                                    $('.companyLogo').removeClass('companyLogoUploaded');
                                }

                                if (event.target.name === 'userImage') {
                                    $('.userImageUploading').show();
                                    $('.userImage').removeClass('userImageUploaded');
                                }

                                if (!item.Status && !item.IsError) {
                                    const AWSService = window.AWS;
                                    // AWSService.config.accessKeyId = environment.amazon_access_key;
                                    // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                                    AWSService.config.update({
                                        region: 'us-west-2',
                                        credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                                            response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                                    });
                                    const unix = Math.round(+new Date() / 1000);
                                    item.Key = unix.toString() + '_' + item.Name;
                                    item.Progress = 0;
                                    const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                                    // var params = { Key: item.Key, Body: item.File, ACL: 'public-read' };
                                    const params = { Key: 'images/' + item.Key, Body: item.File, ACL: 'public-read' };
                                    bucket.upload(params, function (err, data) {
                                        if (data !== undefined) {
                                            item.Status = true;
                                            item.Location = data.Location;
                                            that.validupload = true;
                                            that.filesTosaveDB.forEach(function (x) {
                                                if (x.FileType === 'companylogo') {
                                                    that.CompanyLogoKey = x.Key;
                                                    that.CompanyLogoUrl = data.Location;
                                                }
                                                if (x.FileType === 'userImage') {
                                                    that.UserImageKey = x.Key;
                                                    that.UserImageUrl = data.Location;
                                                }
                                                if (!x.Status) {
                                                    that.validupload = false;
                                                }
                                            });
                                        } else if (err !== undefined) {
                                            item.Status = false;
                                            item.IsError = true;

                                            that.validupload = true;
                                            that.filesTosaveDB.forEach(function (y) {
                                                y.Key = y.Key;
                                                if (!y.Status) {
                                                    that.validupload = false;
                                                }
                                            });
                                        }
                                    }).on('httpUploadProgress', function (progress) {

                                        if (Math.round(progress.loaded / progress.total * 100).toString() === '100') {

                                            if (event.target.name === 'companylogo') {
                                                setTimeout(() => {
                                                    $('.companyLogoUploading').hide();
                                                    $('.companyLogo').addClass('companyLogoUploaded');
                                                    $('.deleteCompanyLogoIcon').show();
                                                    that.CompanyLogoFilled = 1;
                                                    that.ProductLogoButtonEnable = 1;
                                                }, 400);
                                            }
                                            if (event.target.name === 'userImage') {
                                                setTimeout(() => {
                                                    $('.userImageUploading').hide();
                                                    $('.userImage').addClass('userImageUploaded');
                                                    $('.deleteUserImageIcon').show();
                                                    that.UserImageFilled = 1;
                                                }, 400);
                                            }
                                        }
                                        that.UpdateProgress(progress.key, Math.round(progress.loaded / progress.total * 100).toString());
                                    });
                                }
                            } else {
                                that.filesTosaveDB.splice(index, 1);
                                that.toasting(item.Name + '.' + item.Extention + ' is not allowed!', 3000, 'red');

                                // change validation that company logo or userimage is empty
                                if (event.target.name === 'companylogo') {
                                    that.CompanyLogoFilled = 0;
                                    that.ProductLogoButtonEnable = 0;
                                }
                                if (event.target.name === 'userImage') {
                                    that.UserImageFilled = 1;
                                }
                            }
                        });
                    }
                }, (error) => {
                  this.validupload = false;
                  this.toasting('Server Issue. Try again after sometime', 2000, 'red');
                });
            } else { this.validupload = false; }
        } else {
        }
    }
    public deleteCompanyLogo(IsFromListing) {
        this.selectedCompanyLogo = false;
        this.CompanyLogoFilled = 0;
        this.ProductLogoButtonEnable = 0;
        const Key = this.CompanyLogoKey;
        const that = this;
        if (!IsFromListing) {  // deleting file from amazon only (During uploading)

            if (this.filesTosaveDB && this.filesTosaveDB.length > 0) {
                this._cuserService.AwsTempraryUploadDetails()
                .subscribe((response) => {
                    if (response.Status && response.Data) {
                        for (let i = 0; i < this.filesTosaveDB.length; i++) {
                            if (this.filesTosaveDB[i].Key === Key) {
                                const AWSService = window.AWS;
                                // AWSService.config.accessKeyId = environment.amazon_access_key;
                                // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                                AWSService.config.update({
                                    region: 'us-west-2',
                                    credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                                        response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                                });
                                const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                                const params = { Key: Key };
                                bucket.deleteObject(params, function (err, data) {

                                    if (data !== undefined) {
                                        that.filesTosaveDB.splice(i, 1);
                                        if (that.filesTosaveDB.length === 0) {
                                            that.HasFilesToUpload = false;
                                            that.validupload = false;
                                        }
                                        that.CompanyLogoFilled = 1;
                                        that.CompanyLogoUrl = '';
                                        that.ProductLogoButtonEnable = 1;
                                    } else if (err !== undefined) {
                                        err.Status = false;
                                        alert(err);
                                    }
                                });
                                this.uploadCompanyLogo.nativeElement.value = '';
                                return;
                            }
                        }
                    }
                }, (error) => {
                  this.validupload = false;
                  this.toasting('Server Issue. Try again after sometime', 2000, 'red');
                });
                return;
            } else {
                that.CompanyLogoFilled = 1;
                that.CompanyLogoUrl = '';
                that.ProductLogoButtonEnable = 1;
                this.uploadCompanyLogo.nativeElement.value = '';
            }
        }
    }
    // show success/error message
    public toasting(text: string, duration: number = 3000, style: string) {
        toast(text, duration, style);
    }
    // update progress file upload
    public UpdateProgress(key, progress) {
        this.filesTosaveDB.forEach(function (item) {
            if (item.Key === key) {
                item.Progress = progress;
                return;
            }
        });
    }
    ngOnInit() {
        setTimeout(function () {
            $(document).ready(function () { $('ul.tabs').tabs(); });
            $('#addprodmodal').modal({ dismissible: false });
        }, 400);
        // this.stripeClientId = environment.stripe_test_client_id;
    }
    // reset form errors
    private _resetFormErrors(): void {
        this._formErrors = {
            productName: { valid: true, message: '' },
            productDescriptions: { valid: true, message: '' }
        };
    }
    // set validation on value change
    public onValueChanged(data?: any) {
        if (!this._signupForm) { return; }
        const form = this._signupForm;
        for (const field in this._formErrors) {
            // clear previous error message (if any)
            if (this._formErrors.hasOwnProperty(field)) {
                const control = form.get(field);
                if (control && control.dirty) {
                    this._formErrors[field].valid = true;
                    this._formErrors[field].message = '';
                }
            }
        }
    }
    // check enter data valid or not
    public _isValid(field): boolean {
        let isValid: boolean;
        // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
        if (this._signupForm.controls[field].touched === false) {
            isValid = true;
        } else if (this._signupForm.controls[field].touched === true && this._signupForm.controls[field].valid === true) {
            isValid = true;
        }
        return isValid;
    }
    // edit product
    public EditProductModal(ProductId, ProductName, ProductDescription, ProductPic) {
        this.modalOkText = 'Update';
        this.successMessage = 'Product detail updated successfully';
        this.ProductId = ProductId;
        this.ProductName = ProductName;
        this.ProductDescription = ProductDescription;
        this.file_srcs_companyLogo = ProductPic ? ProductPic : this.defaultProductImgUrl;
        this.selectedCompanyLogo = true;
        this.CompanyLogoFilled = 0;
        this.ProductLogoButtonEnable = 1;
        this.modalActionsAddProduct.emit({ action: 'modal', params: ['open'] });
        $('.companyLogoUploading').hide();
        $('.companyLogo').addClass('companyLogoUploaded');
    }
    // open add product modal
    public AddProductModel($event) {
        if ($event) {
            $event.preventDefault();
        }

        this.ProductLogoButtonEnable = 1;
        this.modalOkText = 'Add';
        this.successMessage = 'Product detail added successfully';
        this.modalActionsAddProduct.emit({ action: 'modal', params: ['open'] });
    }
    // delete product modal
    public DeleteProductModal(Product: any) {
        if (Product) {
            this.DeletedProductObj = Product;
            this.modalActions3.emit({ action: 'modal', params: ['open'] });
        }
    }
    // close delete modal
    CancelDeleteModel() {
        this.modalActions3.emit({ action: 'modal', params: ['close'] });
    }
    // delete product database
    DeleteProduct() {
        if (this.DeletedProductObj && this.LoggedInRoleId === 6) {
            this.IsDeleteting = true;
            this._cuserService.DeleteProduct({ ProductId: this.DeletedProductObj.Id })
                .subscribe((deleteResponse) => {
                    this.modalActions3.emit({ action: 'modal', params: ['close'] });
                    if (deleteResponse.Status) {
                        this.toasting(`Product has been deleted.`, 3000, 'green');
                        this.searchProduct.emit();
                    } else {
                        this.toasting(`${deleteResponse.Message}`, 3000, 'red');
                    }
                    this.IsDeleteting = false;

                }, error => {
                    this.IsDeleteting = false;
                    this.modalActions3.emit({ action: 'modal', params: ['close'] });
                    this.toasting(`${error}`, 3000, 'red');
                });
        }
    }
    // close add product modal
    public CloseModel() {
        this._signupForm.reset();
        this.ProductId = 0;
        this.ProductName = '';
        this.ProductDescription = '';
        this.file_srcs_companyLogo = '';
        this.selectedCompanyLogo = false;
        this.CompanyLogoFilled = 0;
        this.modalActionsAddProduct.emit({ action: 'modal', params: ['close'] });
    }
}
