export class User {
    UserId: number;
    FirstName: string;
    LastName: string;
    Name: string;
    Initials: string;
    Email: string;
    Phone: string;
    ModifiedDateTime: string;
    Picture: string;
    ReferalCode: string;
    CssClass: string;
    TotalDocuments: string;
    TotalCalls: string;
    ConnectionId: string;
    DocumentCount: number;
    StripeCustomerId: string;
    CountryCode: string;
    IsDeleted: number;
    Token: string;
    Description: string;
    LoggedUserTitle: string;
    UserCounts: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
