import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../model/user.service';
import { EventBus } from '../EventBus/EventBus';
import { EmailNotificationSettingModel } from './email-notification-setting.model';
import { toast } from 'angular2-materialize';

@Component({
  selector: 'app-email-notification-setting',
  templateUrl: './email-notification-setting.component.html',
  styleUrls: ['./email-notification-setting.component.css']
})
export class EmailNotificationSettingComponent {
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserRefCode: string;
  LoginUserId: number;
  LoggedInUserRoleId: number;
  AgencyId: number;
  LocationId: number;
  AgencyName: string;
  ShowEmailSettingSaveMessage: boolean;
  ShowSaveFromDestroyedMethod: boolean;
  EmailNotificationSettingObject: EmailNotificationSettingModel;
  ShowDisableBox: boolean;
  ShowLoader = {
    DocumentEmailNotification: false,
    LocationemailNotification: false,
    PaymentEmailNotification: false,
    SignedDocumentEmailNotification: false,
    NewUserSignupEmailNotification: false
  };
  constructor(private userService: UserService, private _EventBus: EventBus) {
    // get agency/user detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserRefCode = UserModel.user.ReferalCode;
      this.LoginUserId = UserModel.user.UserId;
      this.AgencyId = UserModel.user.AgencyId;
      if (this.userService.GetSelectedLocation() !== 0) {
          this.LocationId = Number(this.userService.GetSelectedLocation());
      } else {
          this.LocationId = UserModel.user.LocationId;
      }
      this.AgencyName = UserModel.user.AgencyName;
      this.LoggedInUserRoleId = UserModel.user.RoleId;
    }
    this.EmailNotificationSettingObject = new EmailNotificationSettingModel();
    this.GetUserEmailNotificationSetting();

    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'locationChanged') {
          this.LocationId = Number(m.filterBy.data.LocationId);
          this.GetUserEmailNotificationSetting();
      }
    });
  }
  // get user email notification setting
  private GetUserEmailNotificationSetting() {
    this.ShowLoader = {
      DocumentEmailNotification: true,
      LocationemailNotification: true,
      PaymentEmailNotification: true,
      SignedDocumentEmailNotification: true,
      NewUserSignupEmailNotification: true
    };
    this.userService.GetEmailNotificationSettings({ UserId: this.LoginUserId, LocationId: this.LocationId }).subscribe((dbresponse) => {
      if (dbresponse.Status) {
        const data = dbresponse.Data;
        this.EmailNotificationSettingObject.DocumentEmailNotification =
          typeof data.DocumentEmailNotification === 'object' ? true : data.DocumentEmailNotification;

        this.EmailNotificationSettingObject.LocationemailNotification =
          typeof data.LocationEmailNotification === 'object' ? true : data.LocationEmailNotification;

        this.EmailNotificationSettingObject.PaymentEmailNotification =
          typeof data.PaymentEmailNotification === 'object' ? true : data.PaymentEmailNotification;

        this.EmailNotificationSettingObject.SignedDocumentEmailNotification =
          typeof data.SignedDocumentEmailNotification === 'object' ? true : data.SignedDocumentEmailNotification;

        this.EmailNotificationSettingObject.NewUserSignupEmailNotification =
          typeof data.NewUserSignupEmailNotification === 'object' ? true : data.NewUserSignupEmailNotification;

        if (typeof data.SignedDocumentEmailNotification === 'object') {
          this.ShowSaveFromDestroyedMethod = true;
        } else {
          this.ShowSaveFromDestroyedMethod = false;
        }
      } else {
        this.toasting(dbresponse.Message, 5000, 'red');
      }
      this.ShowDisableBox = !this.EmailNotificationSettingObject.LocationemailNotification;
      this.ShowLoader = {
        DocumentEmailNotification: false,
        LocationemailNotification: false,
        PaymentEmailNotification: false,
        SignedDocumentEmailNotification: false,
        NewUserSignupEmailNotification: false
      };
    }, error => {
      this.ShowLoader = {
        DocumentEmailNotification: false,
        LocationemailNotification: false,
        PaymentEmailNotification: false,
        SignedDocumentEmailNotification: false,
        NewUserSignupEmailNotification: false
      };
      this.toasting(error.Message, 5000, 'red');
    });
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // on switch upload location
  OnSwitchChange($event) {
    switch ($event.currentTarget.name) {
      case 'PaymentEmailNotification':
        this.EmailNotificationSettingObject.PaymentEmailNotification = $event.currentTarget.checked;
        break;

      case 'DocumentEmailNotification':
        this.EmailNotificationSettingObject.DocumentEmailNotification = $event.currentTarget.checked;
        break;

      case 'SignedDocumentEmailNotification':
        this.EmailNotificationSettingObject.SignedDocumentEmailNotification = $event.currentTarget.checked;
        break;

      case 'NewUserSignupEmailNotification':
        this.EmailNotificationSettingObject.NewUserSignupEmailNotification = $event.currentTarget.checked;
        break;

      case 'LocationemailNotification':
        this.EmailNotificationSettingObject.LocationemailNotification = $event.currentTarget.checked;
        if (!this.EmailNotificationSettingObject.LocationemailNotification) {
          this.EmailNotificationSettingObject.PaymentEmailNotification = false;
          this.EmailNotificationSettingObject.DocumentEmailNotification = false;
          this.EmailNotificationSettingObject.SignedDocumentEmailNotification = false;
          this.EmailNotificationSettingObject.NewUserSignupEmailNotification = false;
          this.ShowDisableBox = true;
        } else {
          this.EmailNotificationSettingObject.PaymentEmailNotification = true;
          this.EmailNotificationSettingObject.DocumentEmailNotification = true;
          this.EmailNotificationSettingObject.SignedDocumentEmailNotification = true;
          this.EmailNotificationSettingObject.NewUserSignupEmailNotification = true;
          this.ShowDisableBox = false;
        }
        break;
    }
    this.SaveEmailNotificationSetting($event.currentTarget.name);
  }
  // save email notification database
  public SaveEmailNotificationSetting(loaderName?: string) {
    if (loaderName) {
      this.ShowLoader[loaderName] = true;
    }
    this.userService.SetEmailNotificationSettings({
      UserId: this.LoginUserId,
      LocationId: this.LocationId,
      PaymentEmailNotification: this.EmailNotificationSettingObject.PaymentEmailNotification,
      DocumentEmailNotification: this.EmailNotificationSettingObject.DocumentEmailNotification,
      SignedDocumentEmailNotification: this.EmailNotificationSettingObject.SignedDocumentEmailNotification,
      LocationEmailNotification: this.EmailNotificationSettingObject.LocationemailNotification,
      NewUserSignupEmailNotification: this.EmailNotificationSettingObject.NewUserSignupEmailNotification
    }).subscribe(dbResponse => {
      this.ShowLoader = {
        DocumentEmailNotification: false,
        LocationemailNotification: false,
        PaymentEmailNotification: false,
        SignedDocumentEmailNotification: false,
        NewUserSignupEmailNotification: false
      };
      if (dbResponse.Status) {
        this.toasting('Setting(s) saved.', 5000, 'green');
      } else {
        this.toasting(dbResponse.Message, 5000, 'green');
      }
    }, error => {
      this.ShowLoader = {
        DocumentEmailNotification: false,
        LocationemailNotification: false,
        PaymentEmailNotification: false,
        SignedDocumentEmailNotification: false,
        NewUserSignupEmailNotification: false
      };
      this.toasting(error.Message, 5000, 'red');
    });
  }
}
