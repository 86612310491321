import { ElementRef, ViewChild, Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { User } from '../../model/user.js';
import { AmazonS3 } from '../../model/amazon.upload';
import { DocumentToSave } from '../../model/documenttosave';
import { Document } from '../../model/document';

declare var $: any;

@Component({
  selector: 'app-user-sign-modal',
  templateUrl: './userSignPopUp.component.html'
})

export class UsersignpopupComponent implements OnInit, AfterViewInit {
  modalActions = new EventEmitter<string | MaterializeAction>();
  params = [];
  model1Params: any;
  uploaddocmodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  @ViewChild('uploadfileinput')
  myInputVariable: any;
  IsAgent: boolean;
  IsUser: boolean;
  extens: String[];
  IsLoaded: boolean;
  IsVideo: boolean;
  IsDoc: boolean;
  validupload: boolean;
  lastMessageDate: string;
  DetailedUser: User;
  ShowLoader: boolean;
  NotDocumentFound: boolean;
  NotCallsFound: boolean;
  NotChatFound: boolean;
  Folders: any[];
  FoldersToUpload: Array<any> = [];
  Documents: Document[];
  CallCount: number;
  IsCalls: boolean;
  IsChatHistory: boolean;
  SelectedFolderId: string;
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserId: number; LoginUserId: number; UserID: number;
  CurrentUserId: number;
  _amazon: AmazonS3;
  filesTosaveDB: Array<DocumentToSave> = [];
  HasFilesToUpload: boolean;
  ShowDocTable: boolean;
  ShowCallTable: boolean;
  ShowChatTable: boolean;
  previewImageURL: any;
  LoadMoreCounter: number;


  constructor(
    private elementRef: ElementRef
  ) {

  }

  ngOnInit(): void {
    $(document).ready(function () { $('ul.tabs').tabs(); });
    setTimeout(function () {
      $('.signature-pad--body canvas').attr('width', '700');
      $('.signature-pad--body canvas').attr('height', '250');
    }, 3000);
  }
  // open upload document modal
  public openuploaddocModal() {
    this.modalActions.emit({ action: 'modal', params: ['open'] });
  }
  // close upload document modal
  public closeuploaddocModal() {
    if (this.IsAgent) {
      this.SelectedFolderId = '5';
    }
    if (this.IsUser) {
      this.SelectedFolderId = '8';
    }
    this.filesTosaveDB = [];
    this.filesTosaveDB = [];
    this.HasFilesToUpload = false;
    this.validupload = false;
    this.modalActions.emit({ action: 'modal', params: ['close'] });
  }

  ngAfterViewInit() {
    $('.modal').modal({
      dismissible: true
    });

    const a = document.createElement('script');
    a.type = 'text/javascript';
    a.src = '/assets/signature_pad/signature_pad.umd.js';
    this.elementRef.nativeElement.appendChild(a);

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/signature_pad/app.js';
    this.elementRef.nativeElement.appendChild(s);
  }
  putCusrsor() {
    setTimeout(function () {
      $('#type_signature').focus();
    }, 20);
  }
}
