import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../../model/user.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-chat-popup',
  templateUrl: './chat-popup.component.html',
  styleUrls: ['./chat-popup.component.css']
})
export class ChatPopupComponent implements OnInit {
  openchat = true;
  ShowLoader = true;
  currentReceiverId: number;
  currentSenderId: number;
  selectedIdx: number;
  currentUserName: string;
  LoadMoreCounter = 0;
  NotChatFound: boolean;
  ShowChatTable: boolean;
  IsChatHistory: boolean;
  activeUserObject: any = {};
  ChatHistory: any = [];
  ActiveUserId: number;
  textareaMessage = '';
  @ViewChild('ulMessages')  MessageList: ElementRef;
  constructor(private _userService: UserService,
              private _router: Router) {
    this.GetChatHistrory(1713, 1715, 'Raj Kapoor', 0, {});
  }

  ngOnInit() {
  }

  scrollToBottom(): void {
    try {
        this.MessageList.nativeElement.scrollTop = this.MessageList.nativeElement.scrollHeight;
    } catch (err) { }
  }

  public GetChatHistrory(SenderId, ReceiverId, Name, index, selectedObject, shouldResetChathistory?: boolean) {
    this.openchat = true;
    this.ShowLoader = true;
    this.currentReceiverId = ReceiverId;
    this.currentSenderId = SenderId;
    this.selectedIdx = index;
    this.currentUserName = Name;
    this.LoadMoreCounter = this.LoadMoreCounter;
    this.NotChatFound = false;
    this.ShowChatTable = true;
    this.IsChatHistory = true;
    this.activeUserObject = selectedObject;
    this.ActiveUserId = SenderId;
    setTimeout(() => {
        $('.user-msg-' + SenderId).css('display', 'none');
    }, 2000);
    localStorage.setItem('activeChatUserId', SenderId);
    const that = this;
    that._userService.getChatHistory(SenderId.toString(), ReceiverId.toString(), this.LoadMoreCounter).subscribe(
        result => {
            if (result.Status) {

                if (that.LoadMoreCounter === 0) {
                    this.textareaMessage = '';
                    if (result.Data != null) {
                        that.ChatHistory = result.Data.reverse();
                        setTimeout(() => {
                            that.scrollToBottom();
                        }, 1000);
                    } else {
                        that.NotChatFound = true;
                        that.ChatHistory = [];
                        that.ShowChatTable = false;
                        setTimeout(() => {
                            that.scrollToBottom();
                        }, 1000);
                    }
                } else {
                    if (result.Data != null) {
                        // result.Data.reverse();
                        result.Data.forEach(function (item) {
                            that.ChatHistory.unshift(item);
                        });
                    } else {
                    }
                }
                // that.ChatHistory = result.Data;
                that.ChatHistory.forEach(function (item) {
                    const dateTime = new Date(item.MessageDateTime).toLocaleString();
                    const date = dateTime.split(',');
                    item.MessageDate = date[0];
                    item.MessageTime = date[1];
                    item.MessageDateTime = dateTime;
                });
                this.scrollToBottom();
            } else {
                that.NotChatFound = true;
                that.ChatHistory = [];
                that.ShowChatTable = false;
            }
            that.ShowLoader = false;
        },
        error => {
            that.ShowLoader = false;
            if (error.Message === 'Token expired') {
                localStorage.removeItem('frontend-token');
                localStorage.removeItem('StripeCust');
                that._router.navigate(['/login']);
            }
        });
  }
}
