import { UserService } from '../model/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
require('aws-sdk/dist/aws-sdk');

export class AmazonS3 {
    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _router: Router
    ) {
    }

    public UploadFile(file: any) {
        const that = this;
        return new Promise<any>((resolve, reject) => {
            const AWSService = window.AWS;
            this.userService.AwsTempraryUploadDetails()
            .subscribe((response) => {
                if (response.Status && response.Data) {
                    // AWSService.config.accessKeyId = environment.amazon_access_key;
                    // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                    AWSService.config.update({
                        region: 'us-west-2',
                        credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                            response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                    });
                    const unix = Math.round(+new Date() / 1000);
                    const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                    const params = { Key: unix.toString(), Body: file, ACL: 'public-read' };
                    bucket.upload(params, function (err, data) {
                        if (data !== undefined) {
                            data.Status = true;
                            return resolve(data);
                        } else if (err !== undefined) {
                            err.Status = false;
                            return reject(err);
                        }
                    }).on('httpUploadProgress', function (progress) {
                    });
                }
            }, (error) => {
                return reject(error);
            });
        });
    }
}
// testing