
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ElementRef, ViewChild, Component, OnInit, EventEmitter, HostListener } from '@angular/core';
import { UserService } from '../../model/user.service';
import { TokboxService } from '../../model/tokbox.service';
import { User } from '../../model/user';
import { CallDetail } from '../../model/callsdetail';
import { Document } from '../../model/document';
import { DocumentToSave } from '../../model/documenttosave';
import { AmazonS3 } from '../../model/amazon.upload';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MaterializeAction, toast } from 'angular2-materialize';
import '../../../assets/tokbox/js/app.js';
import { ChatHistory } from '../../model/chathistory';
import { EventBus } from '../../shared/EventBus/EventBus';
import { Number } from 'core-js';

declare var myExtObject: any;

@Component({
  templateUrl: './agencyadmindashboarduserdetail.component.html'
})

export class AgencyAdminDashboardUserDetailComponent implements OnInit {
  @ViewChild('scrollMe') public myScrollContainer: ElementRef;
  @ViewChild('videoPlayer') public videoplayer: ElementRef;
  @ViewChild('iframeelement') public iframe: ElementRef;
  @ViewChild('signPreviewModal') signPreviewModal: any;
  @ViewChild('ulMessages') public d1: ElementRef;
  UserDetailLoading: boolean;
  uploaddocmodal = new EventEmitter<string | MaterializeAction>();
  params = [];
  uploaddocmodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];

  previewimagemodal = new EventEmitter<string | MaterializeAction>();
  previewimagemodalparam = [
    {
      dismissible: false,
      complete: function () { }
    }
  ];
  extens: String[];
  IsLoaded: boolean;
  IsVideo: boolean;
  IsDoc: boolean;
  validupload: boolean;
  lastMessageDate: string;
  DetailedUser: User;
  ShowLoader: boolean;
  conversationlistloading: boolean;
  UserLoading: boolean;
  NoPaymentsFound: boolean;
  NotDocumentFound: boolean;
  NotCallsFound: boolean;
  NotChatFound: boolean;
  Count: number;
  Folders: any[];
  Documents: Document[];
  openchat: boolean;
  Calls: CallDetail[];
  payments: any[];
  ChatHistory: ChatHistory[];
  CallCount: number;
  IsCalls: boolean;
  IsPayments: boolean;
  IsChatHistory: boolean;
  SelectedFolderId: string;
  LoggedInUserName: string;
  LoggedInUserImageUrl: string;
  LoggedInUserId: number; LoginUserId: number; UserID: number;
  CurrentUserId: number;
  LocationId: number;
  AgencyId: number;
  _amazon: AmazonS3;
  Conversastion: any[];
  filesTosaveDB: Array<DocumentToSave> = [];
  HasFilesToUpload: boolean;
  ShowDocTable: boolean;
  ShowCallTable: boolean;
  ShowChatTable: boolean;
  currentReceiverId: number;
  currentSenderId: number;
  currentUserName: string;
  selectedIdx = 0;
  ShowPaymentTable: boolean;
  public innerWidth: any;
  LoadMoreLoader: boolean;
  previewImageURL: any;
  LoadMoreCounter: number;
  key: string;
  TotalConersatstion: string;
  reverse: boolean;
  documentkey: string;
  documentreverse: boolean;
  selectedFolderMob: any;
  LoadMoreCallCounter: number;
  UserCallDetailsLoading: boolean;
  CurrentLoadUserSignedDocumentCount = 0;
  CurrentLoadUserUnSignedDocumentCount = 0;
  SortKey = 'UploadedDate';
  SortOrder = 'DESC';
  ActiveFolderId = '5';
  SortKeyCalls = 'InitiatedDateTime';
  SortOrderCalls = 'DESC';
  SortKeyPayments = 'CreatedDateTime';
  SortOrderPayments = 'DESC';
  modelDeleteDocumentParam: any;
  modalDeleteActions = new EventEmitter<string | MaterializeAction>();
  DeleteDocumentKey: string;
  DeleteIsFromListing = true;
  DeleteFolderID = 0;
  DeleteIndex = 0;
  LoadMoreLoaderEnable: boolean;
  LoadMoreRequest = true;
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.userService.decodeToken().user.RoleId !== 6) {
      this.userService.RedirectAccToRole(this.userService.decodeToken().user.RoleId);
    }

    this.userService.GetInitialSettings(this.LoggedInUserId).subscribe((response) => {
      if (response && response.Data) {
        this._EventBus.emit({
          Type: 'header-left-statics',
          data: response.Data
        });
      }
    }, (error) => {
    });

    // Get device width
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private tokboxService: TokboxService,
    private route: ActivatedRoute,
    private _router: Router,
    private _EventBus: EventBus

  ) {
    this.UserCallDetailsLoading = false;
    this.documentkey = 'UploadedDate';
    this.documentreverse = true;
    this.LoadMoreCallCounter = 0;
    this.key = 'InitiatedDateTime';
    this.reverse = true;
    const that = this;
    this.Count = 1;
    this._EventBus.on().subscribe((m: any) => {
      if (m.filterBy.Type === 'refreshdocumentlist') {
        // this.getDocuments(m.filterBy.data.UserId, m.filterBy.data.SelectedFolder);
        this.LoadMoreCounter = 0;
        this.GetFolders(m.filterBy.data.UserId);
      }
    });
    this.extens = ['pdf', 'doc', 'docx', 'jpg', 'png', 'jpeg', 'xlsx', 'xls', 'pptx'];
    this.LoadMoreCounter = 0;
    this._amazon = new AmazonS3(userService, route, _router);
    this.SelectedFolderId = '5';  // default folder
    // get agency/admin detail from token
    const UserModel = this.userService.decodeToken();
    if (UserModel && UserModel.user) {
      this.LoggedInUserName = UserModel.user.Name;
      this.LocationId = UserModel.user.LocationId;
      this.AgencyId = UserModel.user.AgencyId;
      this.LoggedInUserImageUrl = UserModel.user.Picture;
      this.LoggedInUserId = UserModel.user.UserId;
    }
    this.route.params.subscribe((params) => {
      this.UserID = params['UserId'];
      this.ShowDocTable = true;
      this.GetFolders(this.UserID);
      this.CurrentUserId = this.UserID;
      this.userDetail(this.LoggedInUserId, this.UserID);
    });
    this.Count = 1;
  }
  // user chat history scroll to bottom
  public scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  // show success/error message toast
  public toasting(text: string, duration: number = 3000, style: string) {
    toast(text, duration, style);
  }
  // track list
  public trackByFn(index, item) {
    if (index === 0) { this.lastMessageDate = ''; }
    if (this.lastMessageDate === item.MessageDate) {
      item.ShowParentDate = false;
    } else {
      item.ShowParentDate = true;
    }
    this.lastMessageDate = item.MessageDate;
  }
  // refresh user data after delete
  public RefreshDataAfterDelete() {
    this.userDetail(this.LoggedInUserId, this.UserID);
    this.GetFolders(this.UserID);
  }
  // show file upload status
  public UpdateProgress(key, progress) {
    this.filesTosaveDB.forEach(function (item) {
      if (item.Key === key) {
        item.Progress = progress;
        return;
      }
    });
  }
  // delete file User
  public DeleteFile(Key, IsFromListing, FolderID, index) {
    const that = this;
    this.userService.AwsTempraryUploadDetails()
      .subscribe((response) => {
        if (response.Status && response.Data) {
          if (!IsFromListing) {  // deleting file from amazon only (During uploading)
            for (let i = 0; i < this.filesTosaveDB.length; i++) {
              if (this.filesTosaveDB[i].Key === Key) {
                const AWSService = window.AWS;
                // AWSService.config.accessKeyId = environment.amazon_access_key;
                // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                AWSService.config.update({
                  region: 'us-west-2',
                  credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                    response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                });
                const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                const params = { Key: Key };
                bucket.deleteObject(params, function (err, data) {
                  if (data !== undefined) {
                    that.filesTosaveDB.splice(i, 1);
                    if (that.filesTosaveDB.length === 0) {
                      that.HasFilesToUpload = false;
                      that.validupload = false;
                    }
                  } else if (err !== undefined) {
                    err.Status = false;
                    alert(err);
                  }
                });
                return;
              }
            }
          } else { // deleting file from amazon and database (After uploading)
            this.Documents[index].ShowActions = false;
            const AWSService = window.AWS;
            // AWSService.config.accessKeyId = environment.amazon_access_key;
            // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
            AWSService.config.update({
              region: 'us-west-2',
              credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
            });
            const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
            const params = { Key: Key };
            bucket.deleteObject(params, function (err, data) {
              if (data !== undefined) {
                that.userService.deleteDocument(Key)
                  .subscribe(
                    result => {
                      if (result.Status) {
                        that.toasting('File deleted successfully', 2000, 'green');
                        // that.DetailedUser[0].TotalDocuments--;
                        that.getDocuments(that.UserID, FolderID);
                        that.RefreshDataAfterDelete();
                      } else {
                        that.Documents = [];
                        that.toasting(result.Message, 2000, 'red');
                      }
                    },
                    error => {
                      if (error.Message === 'Token expired') {
                        localStorage.removeItem('frontend-token');
                        localStorage.removeItem('StripeCust');
                        this._router.navigate(['/login']);
                      }
                    }
                  );
              } else if (err !== undefined) {
                err.Status = false;
                alert(err);
              }
            });
          }
        }
      }, (error) => {
          this.toasting('Server Issue. Try again after sometime', 2000, 'red');
      });
  }
  // get all data features of user
  public GetFolders(UserId) {
    this.userService.getfolders(UserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.Folders = result.Data;
            this.Folders.push({Id: 'calls', DocCount: this.CallCount, Name: 'Calls'});
            this.Folders.push({Id: 'chat_history', DocCount: -1, Name: 'Chat History'});
            this.Folders.push({Id: 'payments', DocCount: -1, Name: 'Payments'});
            const that = this;
            that.Folders.forEach(function (item) {
              item.Id = item.Id.toString();
              if (item.Id === '5') {
                item.Class = 'tab_btn btn-flat raddius25 btn-large der active';
              }
              if (item.Id === '6') {
                item.Class = 'tab_btn btn-flat raddius25 btn-large grn';
              }
              if (item.Id === '7') {
                item.Class = 'tab_btn btn-flat raddius25 btn-large blu';
              }
              if (item.Id === '8') {
                item.Class = 'tab_btn btn-flat raddius25 btn-large pnk';
              }
            });
            this.getDocuments(UserId, this.ActiveFolderId);
          } else {
            //  alert(result.Message);
          }
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        });
  }
  // open upload doc modal
  public openModal1() {
    this.uploaddocmodal.emit({ action: 'modal', params: ['open'] });
  }
  // close upload doc modal
  public closeuploaddocModal() {
    this.SelectedFolderId = '5';
    this.filesTosaveDB = [];
    this.filesTosaveDB = [];
    this.HasFilesToUpload = false;
    this.validupload = false;
    this.uploaddocmodal.emit({ action: 'modal', params: ['close'] });
  }
  // open preview modal
  public openPreviewModal() {
    this.previewimagemodal.emit({ action: 'modal', params: ['open'] });
  }
  // close preview document modal
  public closepreviewimageModal() {
    this.iframe.nativeElement.src = '';
    this.videoplayer.nativeElement.pause();
    this.previewimagemodal.emit({ action: 'modal', params: ['close'] });
  }
  // get user detail
  private userDetail(ResponderId, UserId) {
    this.UserDetailLoading = true;
    this.userService.userDetail(ResponderId, UserId)
      .subscribe(
        result => {
          if (result.Status) {
            this.DetailedUser = result.Data;
            this.CallCount = this.DetailedUser[0].TotalCalls;
            if (this.Folders && this.Folders.length > 0) {
              const indexCall = this.Folders.indexOf((item) => item.Id === 'calls');
              if (indexCall !== -1) {
                this.Folders[indexCall].DocCount = this.CallCount;
              }
            }
            const userName = this.DetailedUser ? `${this.DetailedUser[0].FirstName} ${this.DetailedUser[0].LastName}` : '';
            this.DetailedUser[0].Name = userName;
            this.DetailedUser[0].Initials = this.GetInitials(userName);
          } else {
            alert(result.Message);
          }
          this.UserDetailLoading = false;
        },
        error => {
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // on scroll down load call records
  public onScrollDownCalls() {
    if (this.Calls.length % 50 === 0 && this.LoadMoreRequest === true) {
      this.LoadMoreLoaderEnable = true;
      this.LoadMoreCallCounter = this.LoadMoreCallCounter + 50;
      this.getCallsDetails(this.LoadMoreCallCounter);
    }
  }
  // get call details user
  public getCallsDetails(offSet) {
    if (this.LoadMoreRequest === true) {
      this.LoadMoreRequest = false;
      this.NotDocumentFound = false;
      this.NotCallsFound = false;
      this.NotChatFound = false;
      this.ActiveInactiveFolders('0');
      this.ShowDocTable = false;
      this.ShowChatTable = false;
      this.ShowCallTable = true;
      this.openchat = false;
      this.IsCalls = true;
      if (!offSet) {
        offSet = 0;
      }
      if (offSet === 0) { this.UserCallDetailsLoading = true; }
      this.userService.getCallsDetails(this.CurrentUserId, offSet, this.SortKeyCalls, this.SortOrderCalls)
        .subscribe(
          result => {
            this.ShowLoader = true;
            if (result.Status) {
              if (offSet === 0) {
                this.Calls = result.Data;
              } else {
                if (result.Data) {
                  result.Data.forEach((item) => {
                    this.Calls.push(item);
                  });
                }
              }
              this.Calls.forEach((item) => {
                item.CallerFirstName = item.CallerFirstName ?
                  item.CallerFirstName[0].toUpperCase() + item.CallerFirstName.slice(1) : '';
                item.CallerLastName = item.CallerLastName ?
                  item.CallerLastName[0].toUpperCase() + item.CallerLastName.slice(1) : '';
                item.CallerName = `${item.CallerFirstName} ${item.CallerLastName}`;
                item.CallerInitial = this.GetInitials(item.CallerName);
                item.CCssClass = item.CCssClass ? item.CCssClass : this.GetClass();
                item.ResponderName = `${item.ResponderFirstName} ${item.ResponderLastName}`;
                item.ReponderInitial = this.GetInitials(item.ResponderName);
                item.RCssClass = item.RCssClass ? item.RCssClass : this.GetClass();

                if (item.Url != null) {
                  const recordedUrl = item.Url.split('?');
                  item.Url = recordedUrl[0];
                  item.VideoExtention = 'mp4';
                }
                const dateTime = new Date(item.InitiatedDateTime).toLocaleString();
                item.DateTimeString = dateTime;
                let minutes = Math.floor(item.Duration / 60).toString();
                if (minutes.toString().length === 1) { minutes = '0' + minutes; }
                let seconds = (item.Duration % 60).toString();
                if (seconds.toString().length === 1) { seconds = '0' + seconds; }
                item.DurationInMinutes = minutes + ':' + seconds;
                if (item.CallStatusId === 1) {
                  item.CallType = 'Missed';
                } else if (item.callerId === this.LoggedInUserId) {
                  item.CallType = 'Incoming';
                } else {
                  item.CallType = 'Outgoing';
                }
              });
            } else {
              this.NotCallsFound = true;
              this.Calls = [];
            }
            this.ShowLoader = false;
            this.LoadMoreLoaderEnable = false;
            this.LoadMoreRequest = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.LoadMoreRequest = true;
          });
      }
  }
  // get payment detail user
  public getPaymentsDetail() {
    if (this.LoadMoreRequest === true) {
      this.LoadMoreRequest = false;
      this.NotDocumentFound = false;
      this.NotCallsFound = false;
      this.NotChatFound = false;
      this.ActiveInactiveFolders('0');
      this.ShowDocTable = false;
      this.ShowChatTable = false;
      this.ShowCallTable = false;
      this.IsCalls = false;
      this.ShowPaymentTable = true;
      this.IsPayments = true;
      this.openchat = false;

      const that = this;
      this.userService.GetUserPayments(that.CurrentUserId, this.LocationId,
        this.AgencyId, this.LoadMoreCounter, '', this.SortKeyPayments, this.SortOrderPayments)
        .subscribe(
          result => {
            that.ShowLoader = true;
            if (result.Status) {
              if (this.LoadMoreCounter === 0) {
                this.payments = [];
                this.payments = result.Data;
              } else {
                if (result.Data) {
                  result.Data.forEach((item) => {
                    this.payments.push(item);
                  });
                }
              }
              this.payments.forEach(function (item) {
                item.DateTimeString = new Date(item.CreatedDateTime).toLocaleString();
              });
            } else {
              this.NoPaymentsFound = (this.payments) ? false : true;
            }
            that.ShowLoader = false;
            this.LoadMoreLoaderEnable = false;
            this.LoadMoreRequest = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.LoadMoreRequest = true;
          });
      }
  }
  // load old message scroll up message list
  public onScrollUp() {
    this.LoadMoreCounter = this.LoadMoreCounter + 50;
    this.GetChatHistrory(this.currentSenderId, this.currentReceiverId, this.currentUserName, this.selectedIdx, {});
  }
  // get user conversation
  public GetConversastion(OffSet) {
    this.LoadMoreCounter = OffSet;
    this.NotDocumentFound = false;
    this.NotCallsFound = false;
    this.NoPaymentsFound = false;
    this.NotChatFound = false;
    this.ActiveInactiveFolders('0');
    this.ShowDocTable = false;
    this.ShowChatTable = true;
    this.ShowCallTable = false;
    this.ShowPaymentTable = false;
    this.IsChatHistory = true;
    this.openchat = true;
    const that = this;

    this.userService.GetUserConversations(this.UserID, OffSet, this.LocationId)
      .subscribe(
        result => {
          that.LoadMoreLoader = true;
          if (result.Status) {
            if (OffSet === 0) {
              this.Conversastion = result.Data;
              if (this.innerWidth > 601) {
                that.GetChatHistrory(result.Data[0].SenderId, result.Data[0].ReceiverId,
                  `${result.Data[0].FirstName} ${result.Data[0].LastName}`, 0, result.Data[0]);
              }
            } else {
              if (result.Data) {
                if (this.innerWidth > 601) {
                  this.GetChatHistrory(result.Data[0].SenderId, result.Data[0].ReceiverId,
                    `${result.Data[0].FirstName} ${result.Data[0].LastName}`, 0, result.Data[0]);
                }
                result.Data.forEach(function (item) {
                  that.Conversastion.push(item);
                });
              }
            }
            this.Conversastion.forEach((item) => {
              item.FirstName = item.FirstName ? item.FirstName[0].toUpperCase() + item.FirstName.slice(1) : '';
              item.LastName = item.LastName ? item.LastName[0].toUpperCase() + item.LastName.slice(1) : '';
              item.Name = `${item.FirstName} ${item.LastName}`;
              item.Initials = that.GetInitials(item.Name);
              item.CssClass = item.CssClass ? item.CssClass : that.GetClass();
            });
            this.TotalConersatstion = this.Conversastion.length.toString();
          } else {
            if (OffSet === 0) { that.NotChatFound = true; }
            that.ShowLoader = false;
          }
          that.conversationlistloading = false;
          that.UserLoading = false;
          that.LoadMoreLoader = false;
        },
        error => {
          that.ShowLoader = false;
          that.UserLoading = false;
          that.NotChatFound = true;
          if (error.Message === 'Token expired') {
            localStorage.removeItem('frontend-token');
            localStorage.removeItem('StripeCust');
            this._router.navigate(['/login']);
          }
        }
      );
  }
  // get chat history of user with specific agent
  public GetChatHistrory(SenderId, ReceiverId, Name, index, obj, shouldResetChathistory?: boolean) {
    SenderId = (SenderId) ? SenderId : obj.UserId;
    ReceiverId = (ReceiverId) ? ReceiverId : this.UserID;
    if (shouldResetChathistory) {
      this.ChatHistory = [];
      this.LoadMoreCounter = 0;
    }
    this.currentReceiverId = ReceiverId;
    this.openchat = true;
    this.ShowLoader = true;
    this.currentSenderId = SenderId;
    this.currentUserName = Name;
    this.selectedIdx = index;
    this.ShowChatTable = true;
    this.IsChatHistory = true;
    this.LoadMoreCounter = this.LoadMoreCounter;
    this.NotCallsFound = false;
    this.NotChatFound = false;
    this.ShowChatTable = true;
    this.IsChatHistory = true;
    const that = this;
    that.userService.getChatHistory(SenderId.toString(), ReceiverId.toString(), this.LoadMoreCounter).subscribe(
      result => {
        that.ShowLoader = true;
        if (result.Status) {

          if (this.LoadMoreCounter === 0) {
            if (result.Data != null) {
              that.ChatHistory = result.Data.reverse();
              this.scrollToBottom();
            } else {
              // that.NotChatFound = true;
              this.ChatHistory = [];
              // that.ShowChatTable = false;
              this.scrollToBottom();
            }
          } else {
            if (result.Data != null) {
              result.Data.forEach(function (item) {
                that.ChatHistory.unshift(item);
              });
            }
          }
          that.ChatHistory.forEach(function (item) {
            const dateTime = new Date(item.MessageDateTime).toLocaleString();
            const date = dateTime.split(',');
            item.MessageDate = date[0];
            item.MessageTime = date[1];
            item.MessageDateTime = dateTime;
          });
        } else {
          // that.NotChatFound = true;
          this.ChatHistory = [];
          // that.ShowChatTable = false;
        }
        that.ShowLoader = false;
      },
      error => {
        if (error.Message === 'Token expired') {
          localStorage.removeItem('frontend-token');
          localStorage.removeItem('StripeCust');
          this._router.navigate(['/login']);
        }
      });
  }
  // get class user listing
  public GetClass() {
    if (this.Count === 1) {
      return 'iconUser noImg cyan lighten-3';
    } else if (this.Count === 2) {
      return 'iconUser noImg cyan lighten-5';
    } else if (this.Count === 3) {
      return 'iconUser noImg pink lighten-4';
    }
  }
  // get documents of users
  public getDocuments(UserId, FolderId) {
    this.ShowLoader = true;
    this.NotDocumentFound = false;
    this.NotCallsFound = false;
    this.NotChatFound = false;
    this.NoPaymentsFound = false;
    this.ShowPaymentTable = false;
    this.openchat = false;
    if (FolderId !== this.ActiveFolderId) {
      this.LoadMoreCounter = 0;
      this.Documents = [];
      this.LoadMoreRequest = true;
    }
    this.ActiveFolderId = FolderId;
    this.getDocumentsLists(UserId, FolderId, this.SortKey, this.SortOrder, this.LoadMoreCounter);
  }
  // get documents list by folder type
  public getDocumentsLists(UserId, FolderId, SortKey, SortOrder, Offset) {
    if (this.LoadMoreRequest === true) {
      this.LoadMoreRequest = false;
      this.userService.getDocuments(UserId, FolderId, SortKey, SortOrder, Offset)
        .subscribe(
          result => {
            if (result.Status) {
              if (Offset === 0) {
                this.Documents = [];
                this.Documents = result.Data;
              } else {
                if (result.Data) {
                  result.Data.forEach((item) => {
                    this.Documents.push(item);
                  });
                }
              }
              this.Documents.forEach((item) => {
                item.Name = item.Name[0].toUpperCase() + item.Name.slice(1);
                item.DateTimeString = new Date(item.UploadedDate).toLocaleString();
                item.ShowActions = true;
                item.Size = this.GetCorrectSize(item.SizeInKB);
                item.ExtensionImage = this.GetIcon(item.Extention);
              });
            } else {
              if (this.Documents && this.Documents.length) {
                this.NotDocumentFound = false;
              } else {
                this.NotDocumentFound = true;
              }
              if (Offset === 0) {
                this.Documents = [];
              }
            }
            this.ShowLoader = false;
            this.ActiveInactiveFolders(FolderId);
            this.LoadMoreLoaderEnable = false;
            this.LoadMoreRequest = true;
          },
          error => {
            if (error.Message === 'Token expired') {
              localStorage.removeItem('frontend-token');
              localStorage.removeItem('StripeCust');
              this._router.navigate(['/login']);
            }
            this.ShowLoader = false;
            this.LoadMoreRequest = true;
          });
      }
  }
  // get documents on mobile
  public getDocumentsMobile(event) {
    if (event && event.value && event.value !== 'calls' && event.value !== 'chat_history' && event.value !== 'payments') {
      this.getDocuments(this.CurrentUserId, event.value);
    }
    this.ActiveFolderId = event.value;
    this.selectedFolderMob = '5';
    if (event.value === 'calls') {
      this.getCallsDetails(0);
    } else if (event.value === 'chat_history') {
      this.GetConversastion(0);
    } else if (event.value === 'payments') {
      this.getPaymentsDetail();
    }
  }
  // set active folder mobile
  public selectFolder(event) {
    this.SelectedFolderId = event;
  }

  public OnLoadComplete() {
    this.IsLoaded = true;
  }
  // preview file by modal
  public previewFile(url, Extention, index) {
    const that = this;
    if (Extention.toLowerCase() === 'jpg' || Extention.toLowerCase() === 'png' || Extention.toLowerCase() === 'jpeg') {
      if (this.previewImageURL !== url) { this.IsLoaded = false; }
      this.previewImageURL = url;
      this.IsDoc = false;
      this.IsVideo = false;
      this.openPreviewModal();
    } else if (Extention.toLowerCase() === 'mp4') {
      if (this.previewImageURL !== url) { this.IsLoaded = false; }
      this.IsLoaded = true;
      this.previewImageURL = url;
      this.IsDoc = false;
      this.IsVideo = true;
      const millisecondsToWait = 1;
      setTimeout(function () {
        that.openPreviewModal();
        that.videoplayer.nativeElement.load();
      }, millisecondsToWait);
    } else {
      this.IsDoc = true;
      this.IsVideo = false;
      const DocUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + url);
      this.previewImageURL = DocUrl;
      this.iframe.nativeElement.src = DocUrl;
      this.openPreviewModal();
    }
  }
  // donload user file
  public downloadFile(fileName, extention, url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = fileName + '.' + extention;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // set active/inactive folder by click
  public ActiveInactiveFolders(FolderId) {
    this.IsCalls = false;
    this.IsChatHistory = false;
    this.IsPayments = false;
    this.ShowCallTable = false;
    this.ShowChatTable = false;
    this.ShowPaymentTable = false;
    this.ShowDocTable = true;
    this.Folders.forEach(function (item) {
      if (item.Id === '5') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large der';
      }
      if (item.Id === '6') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large grn';
      }
      if (item.Id === '7') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large blu';
      }
      if (item.Id === '8') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large pnk';
      }
      if (item.Id === '12') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large prpl';
      }
      if (item.Id === '14') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large cyn';
      }
    });
    this.Folders.forEach(function (item) {
      if (item.Id === FolderId && FolderId === '5') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large der active';
      } else if (item.Id === FolderId && FolderId === '6') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large grn active';
      } else if (item.Id === FolderId && FolderId === '7') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large blu active';
      } else if (item.Id === FolderId && FolderId === '8') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large pnk active';
      } else if (item.Id === FolderId && FolderId === '12') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large prpl active';
      } else if (item.Id === FolderId && FolderId === '14') {
        item.Class = 'tab_btn btn-flat raddius25 btn-large cyn active';
      }
    });
  }
  // get intials of users
  public GetInitials(Name) {
    if (this.Count >= 3) {
      this.Count = 1;
    } else {
      this.Count = this.Count + 1;
    }
    const splittedName = Name.split(' ', 3);
    if (splittedName.length > 1) {
      return splittedName[0].charAt(0) + splittedName[1].charAt(0);
    } else {
      return splittedName[0].charAt(0);
    }
  }
  // get icon of file based on type
  public GetIcon(extention) {
    if (extention.toLowerCase().match('pdf')) { return 'assets/images/pdf.png'; } else
      if (extention.toLowerCase().match('doc') || extention.match('docx')) { return 'assets/images/do.png'; } else
        if (extention.toLowerCase().match('jpg') || extention.match('png') || extention.match('jpeg')) {
          return 'assets/images/jpg.png';
        } else
          if (extention.toLowerCase().match('xlsx') || extention.match('xls')) { return 'assets/images/excelr_ic.png'; } else
            if (extention.toLowerCase().match('pptx')) { return 'assets/images/powerpoint_ic.png'; }
  }
  // get size of file
  public GetCorrectSize(FileSize) {
    if (FileSize < 1000) { return FileSize + ' Bytes'; } else
      if (FileSize > 1000 && FileSize < 1000000) { return (FileSize / 1000).toFixed(2).toString() + ' KB'; } else
        if (FileSize > 1000000 && FileSize < 1000000000) { return (FileSize / 1000000).toFixed(2).toString() + ' MB'; } else
          if (FileSize > 1000000000) {
            { return (FileSize / 1000000000).toFixed(2).toString() + ' GB'; }
          }
  }
  // upload file function
  public fileEvent(event) {
    this.validupload = false;
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const documenttosave = new DocumentToSave();
      documenttosave.File = files[i];
      documenttosave.Name = files[i].name.split('.')[0].replace(/^.*[\\\/]/, '');
      documenttosave.Name = documenttosave.Name ? documenttosave.Name.replace(/\s/g, '') : documenttosave.Name;
      documenttosave.Extention = files[i].name.replace(/^.*\./, '');
      documenttosave.SizeInKB = files[i].size;
      documenttosave.Status = false;
      documenttosave.IsError = false;
      this.filesTosaveDB.push(documenttosave);
    }
    if (this.filesTosaveDB.length > 0) {
      this.HasFilesToUpload = true;
    }
    if (this.filesTosaveDB !== [] && this.SelectedFolderId !== undefined) {
      const that = this;
      this.userService.AwsTempraryUploadDetails()
        .subscribe((response) => {
          if (response.Status && response.Data) {
            this.filesTosaveDB.forEach(function (item, index) {
              if (that.CheckFileExtention(item.Extention)) {
                if (!item.Status && !item.IsError) {
                  const AWSService = window.AWS;
                  // AWSService.config.accessKeyId = environment.amazon_access_key;
                  // AWSService.config.secretAccessKey = environment.amazon_secret_access_key;
                  AWSService.config.update({
                    region: 'us-west-2',
                    credentials: new AWSService.Credentials(response.Data.Credentials.AccessKeyId,
                      response.Data.Credentials.SecretAccessKey, response.Data.Credentials.SessionToken)
                  });
                  const unix = Math.round(+new Date() / 1000);
                  item.Key = unix.toString() + '_' + item.Name;
                  item.Progress = 0;
                  const bucket = new AWSService.S3({ params: { Bucket: response.Data.amazon_bucket_name } });
                  const params = { Key: item.Key, Body: item.File, ACL: 'public-read' };
                  bucket.upload(params, function (err, data) {

                    if (data !== undefined) {
                      item.Status = true;
                      item.Location = data.Location;
                      that.validupload = true;
                      that.filesTosaveDB.forEach(function (x) {
                        if (!x.Status) {
                          that.validupload = false;
                        }
                      });
                    } else if (err !== undefined) {
                      item.Status = false;
                      item.IsError = true;
                      that.validupload = true;
                      that.filesTosaveDB.forEach(function (y) {
                        y.Key = y.Key;
                        if (!y.Status) {
                          that.validupload = false;
                        }
                      });
                    }
                  }).on('httpUploadProgress', function (progress) {
                    that.UpdateProgress(progress.key, Math.round(progress.loaded / progress.total * 100).toString());
                  });
                }
              } else {
                that.filesTosaveDB.splice(index, 1);
                that.toasting(item.Name + '.' + item.Extention + ' is not allowed!', 3000, 'red');
              }
            });
          }
        }, (error) => {
          this.validupload = false;
          this.toasting('Server Issue. Try again after sometime', 2000, 'red');
        });
    } else { this.validupload = false; }
  }
  // check file extension before uplaod
  public CheckFileExtention(extention) {
    const exten = (extention) ? extention.toLowerCase() : '';
    if (this.extens.indexOf(exten) > -1) { return true; } else { return false; }
  }
  // uplaod document function
  public UploadDoc(event) {
    let counter = 0;
    const that = this;
    const selectedFolder = parseInt(this.SelectedFolderId, null);
    this.filesTosaveDB.forEach(function (item) {
      if (item.Status) {
        that.userService.uploadDoc(that.AgencyId, that.LocationId, item.Key, item.Name, item.Extention,
          item.SizeInKB, selectedFolder, item.Location, that.DetailedUser[0].UserId, that.LoginUserId)
          .subscribe(
            result => {
              counter++;
              if (result.Status) {
                that.getDocuments(that.DetailedUser[0].UserId, selectedFolder);
                that.Folders.forEach(function (folder) {
                  if (folder.Id === selectedFolder) {
                    folder.DocCount++;
                    return;
                  }
                });
                that.DetailedUser[0].TotalDocuments++;
              } else {
                that.toasting(result.Message, 2000, 'red');
              }
            },
            error => {
              if (error.Message === 'Token expired') {
                localStorage.removeItem('frontend-token');
                localStorage.removeItem('StripeCust');
                this._router.navigate(['/login']);
              }
            });
      }
    });
    counter = 0;
    this.closeuploaddocModal();
  }
  // back to user listing
  public showlist() {
    this._router.navigate(['user/list']);
  }

  public LogoutTokbox() {
    myExtObject.logout();
  }

  public LogOut() {
    this.LogoutTokbox();
    localStorage.removeItem('frontend-token');
    localStorage.removeItem('StripeCust');
    this._router.navigate(['/login']);
  }
  // detect window resize
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.openchat = false;
  }
  // document server sort
  ServerSort(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKey === sortKey) {
      this.SortOrder = (this.SortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.SortKey = sortKey;
    } else {
      this.SortOrder = 'ASC';
      this.SortKey = sortKey;
    }
    this.getDocuments(this.UserID, this.ActiveFolderId);
  }
  // scroll down load documents lists
  public onScrollDown() {
    if (this.Documents.length % 50 === 0 && this.LoadMoreRequest === true) {
      this.LoadMoreLoaderEnable = true;
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.getDocumentsLists(this.UserID, this.ActiveFolderId, this.SortKey, this.SortOrder, this.LoadMoreCounter);
    }
  }
  // load payment on scroll down
  public onScrollDownPayments() {
    if (this.payments.length % 50 === 0 && this.LoadMoreRequest === true) {
      this.LoadMoreLoaderEnable = true;
      this.LoadMoreCounter = this.LoadMoreCounter + 50;
      this.getPaymentsDetail();
    }
  }
  // sort call history server
  public ServerSortCalls(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKeyCalls === sortKey) {
      this.SortOrderCalls = (this.SortOrderCalls === 'ASC') ? 'DESC' : 'ASC';
      this.SortKeyCalls = sortKey;
    } else {
      this.SortOrderCalls = 'ASC';
      this.SortKeyCalls = sortKey;
    }
    this.getCallsDetails(this.LoadMoreCounter);
  }
  // sort payment history on server
  public ServerSortPayments(sortKey: string) {
    this.LoadMoreCounter = 0;
    if (this.SortKeyPayments === sortKey) {
      this.SortOrderPayments = (this.SortOrderPayments === 'ASC') ? 'DESC' : 'ASC';
      this.SortKeyPayments = sortKey;
    } else {
      this.SortOrderPayments = 'ASC';
      this.SortKeyPayments = sortKey;
    }
    this.getPaymentsDetail();
  }
  // User Signatuer preview modal
  public UserSignPreviewRedirect(documentKey, DocId) {
    const res: any = documentKey.split('.pdf');
    this.signPreviewModal.openSignPreview(res[0], DocId);
  }
  // close delete modal
  public CancelDeleteModel() {
    this.modalDeleteActions.emit({ action: 'modal', params: ['close'] });
  }
  // delete document confirm before delete
  public deleteDocumentConfirm(Key, IsFromListing, FolderID, index) {
    this.DeleteDocumentKey = Key;
    this.DeleteIsFromListing = IsFromListing;
    this.DeleteFolderID = FolderID;
    this.DeleteIndex = index;
    this.modalDeleteActions.emit({ action: 'modal', params: ['open'] });
  }
  // delete document file
  public DeleteDocumentFile() {
    this.modalDeleteActions.emit({ action: 'modal', params: ['close'] });
    this.DeleteFile(this.DeleteDocumentKey, this.DeleteIsFromListing, this.DeleteFolderID, this.DeleteIndex);
  }

}


